import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import {
  Avatar,
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Menu,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from '@material-ui/core';
import { AccountCircle, ExitToApp, FilterNone, GroupOutlined, PlaylistAddCheck } from '@material-ui/icons';
import OrganizationMenu from '../OrganizationMenu';
import firebase from '../../Utils/firebaseConfig';

const MobileMenu = ({
  classes,
  handleLogout,
  handleMobileMenuClose,
  handleSubMenuActive,
  isMobileMenuOpen,
  loggedUserInfo,
  mobileMoreAnchorEl,
  openUsersRequest,
  pushSupportStatus,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [canRenderOrganizationOption, setCanRenderOrganizationOption] = useState(false);
  const { firebaseUid } = loggedUserInfo.user;

  useEffect(() => {
    if (firebaseUid) {
      const firestore = firebase.firestore();
      firestore
        .collection(`users`)
        .doc(firebaseUid)
        .get()
        .then(doc => {
          const userData = doc.data();
          const { organizations } = userData;
          if (organizations.length > 1) {
            setCanRenderOrganizationOption(true);
            return;
          }
          setCanRenderOrganizationOption(false);
        });
    }
  }, [firebaseUid]);

  return (
    <>
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: `top`, horizontal: `right` }}
        transformOrigin={{ vertical: `top`, horizontal: `right` }}
        open={isMobileMenuOpen}
        onClick={handleMobileMenuClose}
        onClose={handleMobileMenuClose}
        disableAutoFocusItem
        aria-label="Menu do usuário"
      >
        <MenuItem onClick={handleMobileMenuClose}>
          <NavLink
            to="/tasks"
            replace={window.location.pathname === `/tasks`}
            className={classes.navlinkMobile}
            onClick={event =>
              handleSubMenuActive(
                event,
                2,
                `${loggedUserInfo.user.firstName} ${loggedUserInfo.user.lastName}`,
                false,
                true,
                true,
              )
            }
          >
            <ListItemIcon>
              <Badge
                badgeContent={
                  `generalInfo` in loggedUserInfo && loggedUserInfo.generalInfo !== undefined
                    ? loggedUserInfo.generalInfo.userAsFollower
                    : 0
                }
                color="secondary"
              >
                <GroupOutlined />
              </Badge>
            </ListItemIcon>
            <ListItemText primary="Outros to-dos" />
          </NavLink>
        </MenuItem>
        <MenuItem onClick={handleMobileMenuClose}>
          <NavLink
            to="/tasks"
            replace={window.location.pathname === `/tasks`}
            className={classes.navlinkMobile}
            onClick={event =>
              handleSubMenuActive(
                event,
                2,
                `${loggedUserInfo.user.firstName} ${loggedUserInfo.user.lastName}`,
                true,
                false,
                true,
              )
            }
          >
            <ListItemIcon>
              <Badge
                badgeContent={
                  `generalInfo` in loggedUserInfo && loggedUserInfo.generalInfo !== undefined
                    ? loggedUserInfo.generalInfo.userTodos
                    : 0
                }
                color="secondary"
              >
                <PlaylistAddCheck />
              </Badge>
            </ListItemIcon>
            <ListItemText primary="Meus to-dos" />
          </NavLink>
        </MenuItem>
        <MenuItem onClick={pushSupportStatus().function}>{pushSupportStatus().secondElement}</MenuItem>
        {canRenderOrganizationOption && (
          <MenuItem onClick={() => setDialogOpen(true)}>
            <ListItemIcon>
              <FilterNone />
            </ListItemIcon>
            <ListItemText primary="Alterar organização" />
          </MenuItem>
        )}
        <MenuItem onClick={() => openUsersRequest({ id: loggedUserInfo.user.id })}>
          <ListItemIcon>
            {loggedUserInfo.user ? (
              <Avatar src={loggedUserInfo.user.avatarThumb} className={classes.avatar} />
            ) : (
              <AccountCircle className={classes.avatar} />
            )}
          </ListItemIcon>
          <ListItemText primary="Meu perfil" />
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </MenuItem>
      </Menu>
      {canRenderOrganizationOption && (
        <Dialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          aria-labelledby="change-organization-dialog-title"
        >
          <DialogTitle id="change-organization-dialog-title">Alterar organização</DialogTitle>
          <DialogContent>
            <OrganizationMenu closeMobileDialog={() => setDialogOpen(false)} />
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={() => setDialogOpen(false)}>
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

MobileMenu.defaultProps = {
  mobileMoreAnchorEl: null,
};

MobileMenu.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  handleLogout: PropTypes.func.isRequired,
  handleMobileMenuClose: PropTypes.func.isRequired,
  handleSubMenuActive: PropTypes.func.isRequired,
  isMobileMenuOpen: PropTypes.bool.isRequired,
  loggedUserInfo: PropTypes.shape({
    access: PropTypes.string,
    currentOrganization: PropTypes.shape({ id: PropTypes.string, displayName: PropTypes.string }),
    generalInfo: PropTypes.object,
    refresh: PropTypes.string,
    user: PropTypes.object,
  }).isRequired,
  mobileMoreAnchorEl: PropTypes.oneOfType([PropTypes.bool, PropTypes.element, PropTypes.node, PropTypes.object]),
  openUsersRequest: PropTypes.func.isRequired,
  pushSupportStatus: PropTypes.func.isRequired,
};

export default MobileMenu;
