import React, { ReactElement } from 'react';
import MaskedInput from 'react-text-mask';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const brazilianStates = [
  `AC`,
  `AL`,
  `AP`,
  `AM`,
  `BA`,
  `CE`,
  `DF`,
  `ES`,
  `GO`,
  `MA`,
  `MT`,
  `MS`,
  `MG`,
  `PA`,
  `PB`,
  `PR`,
  `PE`,
  `PI`,
  `RJ`,
  `RN`,
  `RS`,
  `RO`,
  `RR`,
  `SC`,
  `SP`,
  `SE`,
  `TO`,
];

interface CNPJMaskCustomProps {
  inputRef: (ref: HTMLElement | null) => void;
}

export function CNPJMask(props: CNPJMaskCustomProps): ReactElement {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}// eslint-disable-line
      ref={(ref: MaskedInput | null): void => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /[0-9]/,
        /[0-9]/,
        `.`,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        `.`,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        `/`,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        `-`,
        /[0-9]/,
        /[0-9]/,
      ]}
      placeholderChar={`\u2000`}
    />
  );
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionsRoot: {
      alignItems: `center`,
      display: `flex`,
      flex: `0 0 auto`,
      justifyContent: `flex-end`,
      padding: 24,
    },
    actionsSpacing: {
      '& > :not(:first-child)': {
        marginLeft: 16,
      },
    },
    boxWrapper: {
      alignContent: `center`,
      alignItems: `center`,
      display: `flex`,
      justifyContent: `flex-start`,
      marginBottom: theme.spacing(3),
    },
    button: {
      font: `Medium 14px/19px Hind`,
      letterSpacing: 1.25,
      opacity: 1,
    },
    dialogTitle: {
      padding: theme.spacing(3),
    },
    dialogContent: {
      padding: `${theme.spacing(0)}px ${theme.spacing(3)}px`,
    },
    disabledButton: {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: `${theme.palette.primary.contrastText} !important`,
    },
    helperTextHorizontalRect: {
      background: `#F5F5F5 0% 0% no-repeat padding-box`,
      borderRadius: theme.spacing(0.5),
      height: theme.spacing(0.25),
      marginRight: theme.spacing(0.25),
      marginTop: theme.spacing(1.125),
      width: theme.spacing(1),
    },
    helperTextRectangle: {
      background: `#F5F5F5 0% 0% no-repeat padding-box`,
      borderRadius: theme.spacing(0.5),
      height: theme.spacing(2),
      width: theme.spacing(6.5),
    },
    helperTextColumnRectWrapper: {
      alignContent: `flex-start`,
      alignItems: `flex-start`,
      display: `flex`,
      flexDirection: `column`,
      justifyContent: `center`,
      marginRight: theme.spacing(2),
    },
    helperTextRectWrapper: {
      alignContent: `center`,
      alignItems: `center`,
      display: `flex`,
      justifyContent: `flex-start`,
    },
    helperTextText: {
      color: `rgb(0, 0, 0, 0.3)`,
      marginRight: 16,
      maxWidth: 174,
      textAlign: `justify`,
    },
    helperTextVerticalRect: {
      background: `#F5F5F5 0% 0% no-repeat padding-box`,
      borderRadius: theme.spacing(0.5),
      height: theme.spacing(1.125),
      marginLeft: theme.spacing(0.75),
      width: theme.spacing(0.25),
    },
    helperTextWrapper: {
      alignContent: `center`,
      alignItems: `center`,
      display: `flex`,
      justifyContent: `flex-start`,
    },
    imagePickerLabel: {
      color: `#000000`,
      font: `normal 12px/16px Hind`,
      letterSpacing: 0.4,
      marginBottom: theme.spacing(1.25),
      opacity: 0.3,
    },
    imagePickerWrapper: {
      alignContent: `flex-start`,
      alignItems: `flex-start`,
      display: `flex`,
      flexDirection: `column`,
      justifyContent: `flex-start`,
    },
    lastTextField: {
      width: `100%`,
    },
    renderValueText: {
      overflowX: `hidden`,
      textOverflow: `ellipsis`,
    },
    renderValueWrapper: {
      flexWrap: `nowrap`,
    },
    selectAvatar: {
      height: 30,
      marginRight: theme.spacing(2),
      width: 30,
    },
    selectTextField: {
      padding: `${theme.spacing(1.625)}px ${theme.spacing(1.625)}px ${theme.spacing(1.625)}px ${theme.spacing(2)}px`,
    },
    textField: {
      marginBottom: theme.spacing(3),
      width: `100%`,
    },
    twoTextFieldsFirstWrapper: {
      paddingRight: theme.spacing(1.5),
    },
    twoTextFieldsSecondWrapper: {
      paddingLeft: theme.spacing(1.5),
    },
  }),
);
