import React, { useState, useEffect } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { useDialog } from 'muibox';
/**
 * Material UI - Core
 */
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
/**
 * Material UI - Icons
 */
import { Close, Delete, DevicesOther } from '@material-ui/icons';
/**
 * My components
 */
import Acessories from './Acessories';
// eslint-disable-next-line
import CallsHistory from './CallsHistory';
// eslint-disable-next-line
import InactivateForm from './InactivateForm';
import InventoryForm from './InventoryForm';
// eslint-disable-next-line
import PreventivesHistory from './PreventivesHistory';

const styles = () => ({
  dialogTitle: {
    display: `flex`,
    flexFlow: `row wrap`,
    alignConent: `center`,
    justifyContent: `flex-end`,
    width: `100%`,
    padding: 0,
  },
  dialogPaper: {
    minHeight: `70vh`,
    maxHeight: `70vh`,
  },
  titleText: {
    display: `flex`,
    margin: `auto auto auto 32px`,
  },
});

const DialogInvForm = ({
  addOrUpdateRequest,
  classes,
  formErrors,
  formInstance,
  fullscreen,
  handleClick,
  inactivateOpen,
  inactivateDialogClose,
  inactivateDialogOpen,
  isSaving,
  loggedUserInfo,
  open,
}) => {
  const dialog = useDialog();
  const { isSuperAdmin } = loggedUserInfo.data.user;
  const [tabIndex, setTabIndex] = useState(0);
  const [swipeableActions, setSwipeableActions] = useState(undefined);
  const id = (formInstance && formInstance.id) || null;
  const isAcessory = formInstance && formInstance.isAcessory;
  const isActive = formInstance && formInstance.isActive;
  const name = (formInstance && formInstance.name) || null;
  const tabs = [<Tab key={0} label="Ativo" />, <Tab key={1} label="Chamados" />, <Tab key={2} label="Preventivas" />];
  const tabsItems = [
    <DialogContent key={0} className={classes.dialogPaper}>
      <InventoryForm
        addOrUpdateRequest={addOrUpdateRequest}
        isSaving={isSaving}
        formErrors={formErrors}
        formInstance={formInstance}
      />
    </DialogContent>,
    <DialogContent key={1} className={classes.dialogPaper}>
      <CallsHistory inventoryId={id} tabIndex={tabIndex} />
    </DialogContent>,
    <DialogContent key={2} className={classes.dialogPaper}>
      <PreventivesHistory inventoryId={id} tabIndex={tabIndex} />
    </DialogContent>,
  ];
  if (!isAcessory && formInstance) {
    tabs.push(<Tab key={3} label="Acessórios" />);
    tabsItems.push(
      <DialogContent key={3} className={classes.dialogPaper}>
        <Acessories formInstance={formInstance} />
      </DialogContent>,
    );
  }

  useEffect(() => {
    if (swipeableActions !== undefined) swipeableActions.updateHeight();
  }, [swipeableActions]);

  return (
    <>
      {inactivateOpen && formInstance && (
        <InactivateForm
          addOrUpdateRequest={addOrUpdateRequest}
          closeDialog={() => inactivateDialogClose()}
          dialogOpen={inactivateOpen}
          isSaving={isSaving}
          formInstance={formInstance}
          loggedUserInfo={loggedUserInfo}
        />
      )}
      <Dialog
        fullScreen={fullscreen}
        open={open}
        onClose={handleClick}
        aria-labelledby="addInvForm"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle className={classes.dialogTitle} disableTypography>
          {isSuperAdmin && formInstance && !formInstance.isApproved && (
            <Button
              variant="outlined"
              style={{ margin: `auto auto auto 10px`, height: 32 }}
              onClick={() => {
                const data = { id, isApproved: true };
                addOrUpdateRequest({ data, dialog });
              }}
            >
              Aprovar
            </Button>
          )}
          {formInstance && (
            <Typography className={classes.titleText} variant="overline">
              <DevicesOther style={{ marginRight: 5 }} />
              {name}
              <Typography color="textSecondary" component="span" style={{ marginLeft: 5 }} variant="overline">
                #{id}
              </Typography>
            </Typography>
          )}
          {isActive && (
            <Tooltip title="Inativar">
              <IconButton aria-label="Inactivate" onClick={() => inactivateDialogOpen()}>
                <Delete />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Fechar">
            <IconButton aria-label="Close" onClick={handleClick}>
              <Close />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <Tabs
          value={tabIndex}
          onChange={(event, index) => setTabIndex(index)}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          {tabs}
        </Tabs>
        <SwipeableViews
          action={actions => {
            setSwipeableActions(actions);
          }}
          animateHeight
          index={tabIndex}
          onChangeIndex={index => setTabIndex(index)}
        >
          {tabsItems}
        </SwipeableViews>
        <DialogActions>
          <Button onClick={handleClick} color="secondary" style={{ marginRight: 5 }}>
            Cancelar
          </Button>
          <Button form="inventoryForm" type="submit" color="primary" variant="contained" disabled={isSaving}>
            Salvar {isSaving && <CircularProgress size={20} />}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withStyles(styles)(DialogInvForm);
