import React, { FC } from 'react';
import { Avatar, ListItemAvatar, ListItemIcon, ListItemText, Tooltip, Typography } from '@material-ui/core';

import activitiesUtil from '../../Utils/activities';
import genericAvatar from '../../Images/GenericAvatar/genericavatar.png';
import { User } from '../../ReduxFlow/Reducers/Auth/Types';
import { ActionNotification } from './types';

const { getActionElement, getModelIcon, getDateFromTimestamp } = activitiesUtil;

interface ActionNotificationsProps {
  notification: ActionNotification;
  users: User[];
}

const ActionNotificationCard: FC<ActionNotificationsProps> = ({ notification, users }) => {
  const { a, al, m, mn, p, ts, uid } = notification;
  const user = users.find(u => u.id === uid);
  const { avatarThumb, firstName, lastName } = user || { avatarThumb: ``, firstName: ``, lastName: `` };

  return (
    <>
      <ListItemAvatar>
        <Tooltip title={`${firstName} ${lastName}`}>
          <Avatar alt={`${firstName} ${lastName}`} src={avatarThumb || genericAvatar} />
        </Tooltip>
      </ListItemAvatar>
      <ListItemText
        primary={m}
        secondary={
          <>
            <Typography component="small" variant="body2" color="textSecondary">
              {p && p}
              {p && <br />}
              {getDateFromTimestamp(ts)}
            </Typography>
          </>
        }
      />
      <ListItemIcon style={{ display: `flex`, flexDirection: `column`, alignItems: `flex-end` }}>
        <>
          {getModelIcon(al, mn)}
          {getActionElement(a)}
        </>
      </ListItemIcon>
    </>
  );
};

export default ActionNotificationCard;
