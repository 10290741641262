import React, { FC } from 'react';
import { Avatar, ListItemAvatar, ListItemIcon, ListItemText, Tooltip, Typography } from '@material-ui/core';
import { Assessment } from '@material-ui/icons';

import activitiesUtil from '../../Utils/activities';
import genericAvatar from '../../Images/GenericAvatar/genericavatar.png';
import { User } from '../../ReduxFlow/Reducers/Auth/Types';
import { ActionNotification } from './types';

const { getModelIcon, getDateFromTimestamp } = activitiesUtil;

interface ReportReadyCardProps {
  notification: ActionNotification;
  users: User[];
}

const ReportReadyCard: FC<ReportReadyCardProps> = ({ notification, users }) => {
  // 't': 'report-ready',
  // 'm': message,
  // 'p': truncate_string(message),
  // 'uid': request_data['notification']['userId'],
  // 'ts': datetime.now().timestamp(),
  // 'al': request_data['notification']['appLabel'],
  // 'mn': request_data['notification']['modelName'],
  // 'r': False # read
  const { al, m, mn, p, ts, uid } = notification;
  const user = users.find(u => u.id === uid);
  const { avatarThumb, firstName, lastName } = user || { avatarThumb: ``, firstName: ``, lastName: `` };

  return (
    <>
      <ListItemAvatar>
        <Tooltip title={`${firstName} ${lastName}`}>
          <Avatar alt={`${firstName} ${lastName}`} src={avatarThumb || genericAvatar} />
        </Tooltip>
      </ListItemAvatar>
      <ListItemText
        primary={m}
        secondary={
          <>
            <Typography component="small" variant="body2" color="textSecondary">
              {p && p}
              {p && <br />}
              {getDateFromTimestamp(ts)}
            </Typography>
          </>
        }
      />
      <ListItemIcon style={{ display: `flex`, flexDirection: `column`, alignItems: `flex-end` }}>
        <>
          {getModelIcon(al, mn)}
          <Tooltip title="Relatório">
            <Assessment />
          </Tooltip>
        </>
      </ListItemIcon>
    </>
  );
};

export default ReportReadyCard;
