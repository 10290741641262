import React, { ReactNode, SFC } from 'react';
import { Avatar, Card, CardContent, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { hexToRgb } from 'Utils';

export enum HelperBoxVariantTypes {
  danger = 'danger', // eslint-disable-line
  info = 'info', // eslint-disable-line
  warning = 'warning', // eslint-disable-line
}

const useStyles = makeStyles(theme => {
  const dangerRGB = hexToRgb(theme.palette.secondary.main);
  const infoRGB = hexToRgb(theme.palette.primary.main);
  const warningRGB = hexToRgb(theme.palette.warning.main);
  return {
    boxCard: {
      borderRadius: theme.spacing(0.5),
      width: `100%`,
    },
    boxAvatar: {
      '&[data-variant|="danger"]': {
        background: `${theme.palette.secondary.main} 0% 0% no-repeat padding-box`,
      },
      '&[data-variant|="info"]': {
        background: `${theme.palette.primary.main} 0% 0% no-repeat padding-box`,
      },
      '&[data-variant|="warning"]': {
        background: `${theme.palette.warning.main} 0% 0% no-repeat padding-box`,
      },
    },
    boxContent: {
      '&[data-variant|="danger"]': {
        background: `rgba(${dangerRGB.r}, ${dangerRGB.g}, ${dangerRGB.b}, 0.1) 0% 0% no-repeat padding-box`,
      },
      '&[data-variant|="info"]': {
        background: `rgba(${infoRGB.r}, ${infoRGB.g}, ${infoRGB.b}, 0.1) 0% 0% no-repeat padding-box`,
      },
      '&[data-variant|="warning"]': {
        background: `rgba(${warningRGB.r}, ${warningRGB.g}, ${warningRGB.b}, 0.1) 0% 0% no-repeat padding-box`,
      },
    },
    boxContentCenteredAlign: {
      alignContent: `center`,
      alignItems: `center`,
      display: `flex`,
      padding: theme.spacing(1),
      '&:last-child': {
        paddingBottom: theme.spacing(1),
      },
    },
    boxContentTopAlign: {
      alignContent: `flex-start`,
      alignItems: `flex-start`,
      display: `flex`,
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      '&:last-child': {
        paddingBottom: theme.spacing(2),
      },
    },
    boxIcon: {
      marginRight: theme.spacing(2),
    },
    boxTypography: {
      '&[data-variant|="danger"]': {
        color: theme.palette.secondary.main,
      },
      '&[data-variant|="info"]': {
        color: theme.palette.primary.main,
      },
      '&[data-variant|="warning"]': {
        color: theme.palette.warning.main,
      },
    },
    withBorder: {
      border: `1px solid ${theme.palette.warning.main}`,
    },
  };
});

interface HelperBoxProps {
  centerContent?: boolean;
  icon?: ReactNode;
  text: ReactNode;
  variant: HelperBoxVariantTypes;
  withAvatarWrapper?: boolean;
  withBorder?: boolean;
}

const HelperBox: SFC<HelperBoxProps> = ({ centerContent, icon, text, variant, withAvatarWrapper, withBorder }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Card className={withBorder ? `${classes.boxCard} ${classes.withBorder}` : classes.boxCard} elevation={0}>
        <CardContent
          classes={{
            root: centerContent
              ? `${classes.boxContent} ${classes.boxContentCenteredAlign}`
              : `${classes.boxContent} ${classes.boxContentTopAlign}`,
          }}
          data-variant={variant}
        >
          {icon && !withAvatarWrapper && (
            <div className={`${classes.boxIcon} ${classes.boxTypography}`} data-variant={variant}>
              {icon}
            </div>
          )}
          {icon && withAvatarWrapper && (
            <Avatar className={`${classes.boxAvatar} ${classes.boxIcon}`} data-variant={variant}>
              {icon}
            </Avatar>
          )}
          <Typography
            align="justify"
            className={classes.boxTypography}
            data-variant={variant}
            display="inline"
            variant="subtitle2"
          >
            {text}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default HelperBox;
