import React, { ChangeEvent, FC, FocusEvent, useEffect, useRef, useState } from 'react';
import { TextField } from '@material-ui/core';
import { timestampToDaysHoursAndMinutes, timestampToDaysHoursAndMinutesString } from '../../Utils/categoriesUtils';

interface SLAFieldProps {
  error?: boolean;
  helperText?: string;
  label: string;
  onChange: (timestamp: number) => void;
  required?: boolean;
  value: number;
}

type StateType = { days: number; hours: number; minutes: number };

const SLAField: FC<SLAFieldProps> = ({ error, helperText, label, onChange, required, value }) => {
  const inputsRefs = useRef<HTMLInputElement[]>([]);
  const [maskedValue, setMaskedValue] = useState(``);
  const [toggleInputs, setToggleInputs] = useState(false);

  useEffect(() => {
    setMaskedValue(timestampToDaysHoursAndMinutesString(value, false, true));
  }, [value]);

  function handleFieldFocus(event: FocusEvent<HTMLInputElement>): void {
    setToggleInputs(true);
  }

  function handleFieldBlur(event: FocusEvent<HTMLInputElement>): void {
    setTimeout(() => {
      const toggle = inputsRefs.current.every(ref => ref !== document.activeElement);
      if (toggle) setToggleInputs(false);
    }, 50);
  }

  const { days, hours, minutes } = timestampToDaysHoursAndMinutes(value);

  const handleFieldChange = (name: string) => (event: ChangeEvent<HTMLInputElement>): void => {
    event.persist();
    const parsedValue = parseInt(event.target.value, 10) || 0;
    const newDays = name === `days` ? parsedValue : days;
    const newHours = name === `hours` && parsedValue < 24 ? parsedValue : hours;
    const newMinutes = name === `minutes` && parsedValue < 60 ? parsedValue : minutes;
    onChange((newDays * 86400 + newHours * 3600 + newMinutes * 60) * 1000);
  };

  return (
    <>
      {!toggleInputs && (
        <TextField
          error={error}
          helperText={helperText}
          label={label}
          margin="normal"
          onFocus={handleFieldFocus}
          required={required}
          style={{ width: `100%` }}
          variant="outlined"
          value={maskedValue}
        />
      )}
      {toggleInputs && (
        <>
          <TextField
            autoFocus
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              ref: (ref: HTMLInputElement): void => {
                inputsRefs.current[0] = ref;
              },
              style: {
                textAlign: `center`,
              },
            }}
            label="Dias"
            margin="normal"
            onBlur={handleFieldBlur}
            onChange={handleFieldChange(`days`)}
            style={{ width: `calc(100%/3)` }}
            type="number"
            value={days}
            variant="outlined"
          />
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              ref: (ref: HTMLInputElement): void => {
                inputsRefs.current[1] = ref;
              },
              style: {
                textAlign: `center`,
              },
            }}
            label="Horas"
            margin="normal"
            onBlur={handleFieldBlur}
            onChange={handleFieldChange(`hours`)}
            style={{ width: `calc(100%/3)` }}
            type="number"
            value={hours}
            variant="outlined"
          />
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              ref: (ref: HTMLInputElement): void => {
                inputsRefs.current[2] = ref;
              },
              style: {
                textAlign: `center`,
              },
            }}
            label="Minutos"
            margin="normal"
            onBlur={handleFieldBlur}
            onChange={handleFieldChange(`minutes`)}
            style={{ width: `calc(100%/3)` }}
            type="number"
            value={minutes}
            variant="outlined"
          />
        </>
      )}
    </>
  );
};

export default SLAField;
