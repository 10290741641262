import React, { FC } from 'react';
import { Avatar } from '@material-ui/core';
import { Layers as LayersIcon } from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AutoComment from '../../AutoComment';
import { AutoCommentCommonTypes } from '../..';
import { Comment } from '..';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      height: theme.spacing(3),
      width: theme.spacing(3),
    },
    highlight: {
      color: theme.palette.primary.dark,
      fontWeight: 500,
    },
    icon: {
      color: theme.palette.primary.dark,
      height: theme.spacing(2.25),
      width: theme.spacing(2.25),
    },
  }),
);

interface CategoryParsed {
  category: string;
  subcategory: string;
}

const UpdateCategoryAutoComment: FC<AutoCommentCommonTypes<Comment>> = ({
  comment,
  fromNow,
  genericAvatar,
  isFirstItem = false,
  isLastItem = false,
  nextIsAutoComment,
  previousIsAutoComment,
}) => {
  const classes = useStyles();
  const { userDetail } = comment;
  const { category, subcategory } = JSON.parse(comment.comment) as CategoryParsed;
  const headerActionText = (
    <>
      selecionou a categoria&nbsp;
      <span className={classes.highlight}>
        {category} &gt; {subcategory}
      </span>
    </>
  );

  return (
    <AutoComment
      avatar={<Avatar className={classes.avatar} src={userDetail.avatarThumb || genericAvatar} />}
      fromNow={fromNow}
      startAdornment={<LayersIcon className={classes.icon} fontSize="small" />}
      headerActionText={headerActionText}
      isFirstItem={isFirstItem}
      isLastItem={isLastItem}
      nextIsAutoComment={nextIsAutoComment}
      previousIsAutoComment={previousIsAutoComment}
      userName={userDetail.fullName}
    />
  );
};

export default UpdateCategoryAutoComment;
