import React, { ChangeEvent, FocusEvent, isValidElement, MouseEvent, ReactNode, SFC, useState } from 'react';
import {
  Avatar,
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
  Business as BusinessIcon,
  Search as SearchIcon,
} from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import { Institution } from 'ReduxFlow/Reducers/Institutions/Types';
import { MarginType } from 'Utils/globalTypes';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    animatedExpansion: {
      WebkitTransition: `max-height 0.3s, padding 0.3s`,
      MozTransition: `max-height 0.3s, padding 0.3s`,
      OTransition: `max-height 0.3s, padding 0.3s`,
      transition: `max-height 0.3s, padding 0.3s`,
    },
    avatar: {
      height: theme.spacing(2.375),
      width: theme.spacing(2.375),
      marginRight: theme.spacing(1),
    },
    avatarWrapper: {
      alignContent: `center`,
      alignItems: `center`,
      display: `flex`,
      justifyContent: `flex-start`,
    },
    dense: {
      marginBottom: theme.spacing(0.5),
      marginTop: theme.spacing(1),
    },
    falselySelected: {
      background: `rgba(0, 0, 0, 0.08) 0% 0% no-repeat padding-box !important`,
      overflowY: `hidden`,
    },
    inputBaseRoot: {
      minHeight: theme.spacing(6.75),
    },
    labelOutlinedEditing: {
      fontSize: theme.spacing(1.875),
      fontWeight: 600,
      transform: `translate(${theme.spacing(1.75)}px, 0) scale(0.75) !important`,
    },
    labelOutlinedNotEditing: {
      fontSize: theme.spacing(2.334625),
      fontWeight: 500,
      transform: `translate(${theme.spacing(1.75)}px, 0) scale(0.75) !important`,
    },
    labelRoot: {
      paddingTop: theme.spacing(2.334625),
    },
    menuItemText: {
      overflow: `hidden`,
      textOverflow: `ellipsis`,
      whiteSpace: `nowrap`,
    },
    menuPaper: {
      maxWidth: 400,
    },
    none: {
      marginBottom: 0,
      marginTop: 0,
    },
    normal: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(2),
    },
    notEditing: {
      background: `#fafafa 0% 0% no-repeat padding-box`,
      '& > div > fieldset': {
        border: `none`,
      },
      '& > div > svg': {
        color: `transparent !important`,
      },
    },
    renderValueWrapper: {
      alignContent: `center`,
      alignItems: `center`,
      display: `flex`,
      justifyContent: `flex-start`,
    },
    required: {
      color: theme.palette.secondary.main,
      marginRight: theme.spacing(0.5),
    },
    root: {
      '& > div > fieldset > legend': {
        maxWidth: 0,
      },
      '& > div': {
        '&.Mui-disabled': {
          border: `none`,
          opacity: 0.4,
          pointerEvents: `none`,
        },
      },
    },
    selectRoot: {
      padding: `${theme.spacing(3.9375)}px ${theme.spacing(1.75)}px ${theme.spacing(1.25)}px ${theme.spacing(1.75)}px`,
    },
    singleValueText: {
      font: `inherit`,
      overflow: `hidden`,
      textOverflow: `ellipsis`,
      whiteSpace: `nowrap`,
    },
    trulySelected: {
      background: `rgba(75, 194, 200, 0.1) 0% 0% no-repeat padding-box !important`,
      overflowY: `hidden`,
    },
  }),
);

interface InstitutionWithSelector extends Institution {
  disabled?: boolean;
}

export enum SelectionMode {
  single,
  group,
}

type InstitutionsSelectorProps = {
  disabled?: boolean;
  error?: boolean;
  hasInstance?: boolean;
  helperText?: string;
  id: string;
  isEditing?: boolean;
  inputProps?: {};
  institutions: InstitutionWithSelector[];
  margin: MarginType;
  multiple?: boolean;
  name: string;
  onBlur?: (event?: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onChange: (selectedValue: number[] | number) => void;
  onFocus?: (event?: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  required?: boolean;
  selectClass: string;
  selectionMode: SelectionMode;
  value?: number[] | number;
  variant: `outlined` | `filled`;
};

const InstitutionsSelector: SFC<InstitutionsSelectorProps> = ({
  disabled,
  error,
  hasInstance,
  helperText,
  id,
  isEditing,
  inputProps,
  institutions,
  margin = MarginType.none,
  multiple = false,
  name,
  onBlur,
  onChange,
  onFocus,
  required,
  selectClass,
  selectionMode = SelectionMode.group,
  value,
  variant,
}) => {
  const classes = useStyles();
  const org = institutions.find(inst => inst.isOrganization);
  const availableInstitutions = institutions.filter(inst => org && inst.id !== org.id);
  const [parentExpanded, setParentExpanded] = useState<{ [key: number]: number }>();
  const organizationSelected = org && (Array.isArray(value) ? value.indexOf(org.id) > -1 : value === org.id);

  function getTextFieldLabel(): ReactNode {
    if (hasInstance && !isEditing) return `Instituições`;
    return required ? (
      <>
        <span className={classes.required}>*</span>
        Instituições
      </>
    ) : (
      `Instituições (opcional)`
    );
  }

  return !org ? (
    <Skeleton className={`${selectClass} ${classes[margin]}`} height={56} variant="rect" />
  ) : (
    <FormControl
      classes={{
        root: hasInstance && !isEditing ? `${classes.root} ${classes.notEditing}` : classes.root,
      }}
      className={selectClass}
      error={error}
      margin={margin}
      variant={variant}
    >
      <InputLabel
        classes={{
          outlined: hasInstance && isEditing ? classes.labelOutlinedEditing : classes.labelOutlinedNotEditing,
          root: classes.labelRoot,
        }}
        id={`label_${name}`}
        shrink
      >
        {getTextFieldLabel()}
      </InputLabel>
      <Select
        classes={{
          root: hasInstance && !isEditing ? `${classes.selectRoot} ${classes.notEditing}` : classes.selectRoot,
        }}
        disabled={disabled}
        IconComponent={SearchIcon}
        id={id}
        inputProps={{
          ...inputProps,
        }}
        MenuProps={{
          anchorOrigin: {
            horizontal: `left`,
            vertical: `bottom`,
          },
          className: classes.menuPaper,
          getContentAnchorEl: undefined,
          transformOrigin: {
            horizontal: `left`,
            vertical: `top`,
          },
        }}
        multiple={multiple}
        name={name}
        onBlur={onBlur}
        onChange={(event: ChangeEvent<{ name?: string | undefined; value: unknown }>, child: ReactNode): void => {
          if (Array.isArray(event.target.value)) {
            // If selected value equals to the org unselect all the rest
            if (event.target.value.indexOf(org.id) > -1 && selectionMode === SelectionMode.group) {
              onChange([org.id]);
            } else if (isValidElement(child) && child.props && child.props.value) {
              if (selectionMode === SelectionMode.single) {
                onChange(event.target.value);
                return;
              }
              let branchOfficesIds: number[] = [];
              const selectedInstance = institutions.find(inst => inst.id === child.props.value);
              // Check if the selection was a branchOffice or a mainOffice
              if (selectedInstance && selectedInstance.parent !== org.id) {
                branchOfficesIds = institutions
                  .filter(insideChild => insideChild.parent === selectedInstance.parent)
                  .map(insideChild => insideChild.id);
              } else if (selectedInstance && selectedInstance.parent === org.id) {
                branchOfficesIds = institutions
                  .filter(insideChild => insideChild.parent === child.props.value)
                  .map(insideChild => insideChild.id);
              }
              const mainOffices = institutions.filter(inst => inst.parent === org.id);
              const allMainOfficesSelected =
                mainOffices.length > 1 &&
                mainOffices.every(
                  inst =>
                    inst.parent === org.id &&
                    Array.isArray(event.target.value) &&
                    event.target.value.indexOf(inst.id) > -1,
                );
              const allBranchOfficesSelected =
                branchOfficesIds.length > 1 &&
                branchOfficesIds.every(
                  insideChild => Array.isArray(event.target.value) && event.target.value.indexOf(insideChild) > -1,
                );
              // If all branch offices is going to be selected change to main office and unselect the other brachs
              if (allBranchOfficesSelected) {
                onChange([
                  ...event.target.value.filter(val => branchOfficesIds.indexOf(val) < 0),
                  selectedInstance && selectedInstance.parent !== org.id ? selectedInstance.parent : child.props.value,
                ]);
                // Check if main office was selected and remove all branchs from the selection
              } else if (selectedInstance && selectedInstance.parent === org.id && !allMainOfficesSelected) {
                onChange([...event.target.value.filter(val => branchOfficesIds.indexOf(val) < 0)]);
                // Check if main office is selected when a branch is going to be selected, maintain the state case true
              } else if (
                selectedInstance &&
                value &&
                Array.isArray(event.target.value) &&
                event.target.value.indexOf(selectedInstance.parent) > -1
              ) {
                onChange(value);
                // If all main offices selected change to the org
              } else if (allMainOfficesSelected) {
                onChange([org.id]);
              } else {
                onChange(event.target.value);
              }
            }
          } else if (typeof event.target.value === `number`) {
            onChange(event.target.value);
          }
        }}
        onFocus={onFocus}
        renderValue={(selected: unknown): ReactNode => {
          let children;
          let institutionName;
          if (Array.isArray(selected)) {
            const renderAvatars = institutions.filter(inst => selected.indexOf(inst.id) > -1);
            if (renderAvatars.length === 1) institutionName = renderAvatars[0].institutionName;
            children = renderAvatars.map((inst, idx) => {
              const renderParent = institutions.find(parent => inst.parent === parent.id);
              if (idx < 4)
                return (
                  <Tooltip key={inst.id} title={inst.institutionName}>
                    <Avatar
                      className={classes.avatar}
                      src={inst.institutionAvatar || (renderParent && renderParent.institutionAvatar)}
                    >
                      <BusinessIcon style={{ fontSize: `0.8rem` }} />
                    </Avatar>
                  </Tooltip>
                );
              return undefined;
            });
          } else {
            const renderInstitution = institutions.find(inst => inst.id === selected);
            const renderParent = institutions.find(
              parent => renderInstitution && renderInstitution.parent === parent.id,
            );
            if (renderInstitution) institutionName = renderInstitution.institutionName;
            children = renderInstitution && (
              <Tooltip key={renderInstitution.id} title={renderInstitution.institutionName}>
                <Avatar
                  className={classes.avatar}
                  src={
                    renderInstitution.institutionAvatar ||
                    (renderParent && renderParent.institutionAvatar) ||
                    org.institutionAvatar
                  }
                >
                  <BusinessIcon style={{ fontSize: `0.8rem` }} />
                </Avatar>
              </Tooltip>
            );
          }
          return (
            <Box className={classes.renderValueWrapper}>
              <Box className={classes.avatarWrapper}>{children}</Box>
              <Typography className={classes.singleValueText}>
                {Array.isArray(value) && value.length > 1 ? `${value.length} selecionadas` : institutionName}
              </Typography>
            </Box>
          );
        }}
        required={required}
        value={value || ``}
      >
        <MenuItem
          classes={{ selected: organizationSelected ? classes.trulySelected : undefined }}
          disabled={org.disabled}
          key={org.id}
          selected={organizationSelected}
          value={org.id}
        >
          <Checkbox
            checked={organizationSelected}
            color={organizationSelected ? `primary` : `default`}
            indeterminate={
              selectionMode === SelectionMode.group &&
              !organizationSelected &&
              ((Array.isArray(value) && !!value.length) || typeof value === `number`)
            }
          />
          <ListItemAvatar>
            <Avatar src={org.institutionAvatar}>
              <BusinessIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            classes={{ primary: classes.menuItemText, secondary: classes.menuItemText }}
            primary={org.institutionName}
          />
        </MenuItem>
        {availableInstitutions.map(inst => {
          const isSelected = Array.isArray(value) ? value.indexOf(inst.id) > -1 : value === inst.id;
          const branchOfficesIds = institutions.filter(child => child.parent === inst.id).map(child => child.id);
          const allBranchOfficesSelected =
            Array.isArray(value) && !!value.length && branchOfficesIds.every(child => value.indexOf(child) > -1);
          const someChildrenSelected = branchOfficesIds.some(child =>
            Array.isArray(value) ? value.indexOf(child) > -1 : value === child,
          );
          if (branchOfficesIds.length) {
            const checked =
              selectionMode === SelectionMode.single
                ? isSelected
                : organizationSelected || isSelected || someChildrenSelected;
            return (
              <MenuItem
                classes={{ selected: isSelected ? classes.trulySelected : classes.falselySelected }}
                className={classes.animatedExpansion}
                disabled={inst.disabled}
                key={inst.id}
                selected={checked}
                style={{ padding: `6px 16px 6px 40px` }}
                value={inst.id}
              >
                <IconButton
                  onClick={(event: MouseEvent<HTMLButtonElement>): void => {
                    event.preventDefault();
                    event.stopPropagation();
                    setParentExpanded(prevValue => ({
                      ...prevValue,
                      [inst.id]: prevValue && prevValue[inst.id] ? 0 : 1000,
                    }));
                  }}
                >
                  {parentExpanded && parentExpanded[inst.id] ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </IconButton>
                <Checkbox
                  checked={checked}
                  color={isSelected ? `primary` : `default`}
                  indeterminate={
                    selectionMode === SelectionMode.group &&
                    !organizationSelected &&
                    someChildrenSelected &&
                    !allBranchOfficesSelected
                  }
                />
                <ListItemAvatar>
                  <Avatar src={inst.institutionAvatar || org.institutionAvatar}>
                    <BusinessIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  classes={{ primary: classes.menuItemText, secondary: classes.menuItemText }}
                  primary={inst.institutionName}
                  secondary={inst.institutionCity}
                />
              </MenuItem>
            );
          }
          if (inst.parent !== org.id) {
            const parent = institutions.find(instParent => instParent.id === inst.parent);
            const checked =
              selectionMode === SelectionMode.single
                ? isSelected
                : organizationSelected ||
                  isSelected ||
                  (parent && Array.isArray(value) && value.indexOf(parent.id) > -1);
            return (
              <MenuItem
                classes={{ selected: isSelected ? classes.trulySelected : classes.falselySelected }}
                className={classes.animatedExpansion}
                disabled={inst.disabled}
                key={inst.id}
                selected={checked}
                style={{
                  maxHeight: parentExpanded && parent && parentExpanded[parent.id] ? parentExpanded[parent.id] : 0,
                  padding:
                    parentExpanded && parent && parentExpanded[parent.id] ? `6px 16px 6px 136px` : `0px 16px 0px 136px`,
                }}
                value={inst.id}
              >
                <Checkbox checked={checked} color={isSelected ? `primary` : `default`} />
                <ListItemAvatar>
                  <Avatar src={inst.institutionAvatar || (parent && parent.institutionAvatar) || org.institutionAvatar}>
                    <BusinessIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  classes={{ primary: classes.menuItemText, secondary: classes.menuItemText }}
                  primary={inst.institutionName}
                  secondary={inst.institutionCity}
                />
              </MenuItem>
            );
          }
          const checked = selectionMode === SelectionMode.single ? isSelected : organizationSelected || isSelected;
          return (
            <MenuItem
              classes={{ selected: isSelected ? classes.trulySelected : classes.falselySelected }}
              className={classes.animatedExpansion}
              disabled={inst.disabled}
              key={inst.id}
              selected={checked}
              style={{
                padding: `6px 16px 6px 88px`,
              }}
              value={inst.id}
            >
              <Checkbox checked={checked} color={isSelected ? `primary` : `default`} />
              <ListItemAvatar>
                <Avatar src={inst.institutionAvatar || org.institutionAvatar}>
                  <BusinessIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                classes={{ primary: classes.menuItemText, secondary: classes.menuItemText }}
                primary={inst.institutionName}
                secondary={inst.institutionCity}
              />
            </MenuItem>
          );
        })}
      </Select>
      {error && helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </FormControl>
  );
};

const InstitutionsSelectorTests = InstitutionsSelector;

export { InstitutionsSelectorTests };

export default InstitutionsSelector;
