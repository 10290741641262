import { action } from 'typesafe-actions';
import { TaskMarkersTypes, TaskMarker, TaskMarkerError } from './Types';

export const loadRequest = (token: string) => action(TaskMarkersTypes.LOAD_REQUEST, { token });
export const loadSuccess = (data: TaskMarker[]) => action(TaskMarkersTypes.LOAD_SUCCESS, { data });
export const loadFailure = () => action(TaskMarkersTypes.LOAD_FAILURE);
export const addRequest = (data = {}) => action(TaskMarkersTypes.ADD_REQUEST, data);
export const addSuccess = (data: TaskMarker) => action(TaskMarkersTypes.ADD_SUCCESS, { data });
export const addFailure = (errors: TaskMarkerError) =>
  action(TaskMarkersTypes.ADD_FAILURE, { errors });
export const updateRequest = (data: { id: number; data: {} }) =>
  action(TaskMarkersTypes.UPDATE_REQUEST, data);
export const updateSuccess = (data: TaskMarker) =>
  action(TaskMarkersTypes.UPDATE_SUCCESS, { data });
export const updateFailure = (errors: TaskMarkerError) =>
  action(TaskMarkersTypes.UPDATE_FAILURE, { errors });
