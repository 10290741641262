import React, { FC, FormEvent, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FileWithPath, useDropzone } from 'react-dropzone';
import { useDialog } from 'muibox';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  ListItemText,
  MenuItem,
  RootRef,
  Typography,
} from '@material-ui/core';
import {
  Close as CloseIcon,
  InfoOutlined as InfoOutlinedIcon,
  RecordVoiceOverOutlined as RecordVoiceOverOutlinedIcon,
} from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import * as OmbudsmansActions from 'ReduxFlow/Reducers/Ombudsmans/Actions';
import { ApplicationState } from 'ReduxFlow/Reducers';
import { Institution } from 'ReduxFlow/Reducers/Institutions/Types';
import { Sector } from 'ReduxFlow/Reducers/Sectors/Types';
import { OmbudsmanError, OmbudsmansState } from 'ReduxFlow/Reducers/Ombudsmans/Types';
import { OmbudsmanSource } from 'ReduxFlow/Reducers/OmbudsmanSources/Types';
import { TasksCategoryState } from 'ReduxFlow/Reducers/TasksCategories/Types';
import { User } from 'ReduxFlow/Reducers/Auth/Types';
import CRMSelector from 'Components/NewUIComponents/CRMSelect';
import TaskCategorySelect from 'Components/NewUIComponents/TaskCategorySelect';
import CustomDateTimePicker from 'Components/NewUIComponents/CustomDateTimePicker';
import CustomInstitutionsSelector, { SelectionMode } from 'Components/NewUIComponents/CustomInstitutionsSelector';
import CustomTextField from 'Components/NewUIComponents/CustomTextField';
import DropZoneIndicator from 'Components/NewUIComponents/DropZoneIndicator';
import UserSelect from 'Components/NewUIComponents/UserSelect';
import { MarginType } from 'Utils/globalTypes';
import HelperBox, { HelperBoxVariantTypes } from 'Components/NewUIComponents/HelperBox';
import TaskAttachsContainer from 'Components/NewUIComponents/TaskAttachsContainer';
import { OmbudsmanFields } from '..';
import { disableDates, getTaskCategories, handleChangeFields } from '../../../tasksCommonHandlers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionsRoot: {
      alignItems: `center`,
      display: `flex`,
      flex: `0 0 auto`,
      justifyContent: `flex-end`,
      padding: 24,
    },
    actionsSpacing: {
      '& > :not(:first-child)': {
        marginLeft: 16,
      },
    },
    button: {
      font: `600 14px/19px Hind`,
      letterSpacing: 1.25,
      minWidth: 105,
      opacity: 1,
    },
    cancelButton: {
      color: theme.palette.primary.dark,
    },
    customInstitutionsField: {
      width: `100%`,
      [theme.breakpoints.up(`sm`)]: {
        marginRight: theme.spacing(0.5),
        width: `calc(100% - ${theme.spacing(0.5)}px)`,
      },
    },
    customTextFieldLeft: {
      [theme.breakpoints.up(`sm`)]: {
        marginRight: theme.spacing(0.5),
        width: `calc(100% - ${theme.spacing(0.5)}px)`,
      },
    },
    customTextFieldRight: {
      [theme.breakpoints.up(`sm`)]: {
        marginLeft: theme.spacing(0.5),
        width: `calc(100% - ${theme.spacing(0.5)}px)`,
      },
    },
    dialogTitleBox: {
      '& > h6:first-child': {
        marginRight: theme.spacing(1.25),
      },
    },
    dialogTitleIcon: {
      color: theme.palette.primary.main,
    },
    dialogTitleRoot: {
      alignContent: `center`,
      alignItems: `center`,
      display: `flex`,
      justifyContent: `space-between`,
      padding: `${theme.spacing(3.75)}px ${theme.spacing(3.75)}px ${theme.spacing(2.5)}px ${theme.spacing(3.75)}px`,
    },
    disabledButton: {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: `${theme.palette.primary.contrastText} !important`,
    },
    formCategoryText: {
      font: `600 16px/22px Hind`,
      letterSpacing: -0.27,
      marginBottom: theme.spacing(1.25),
      marginTop: theme.spacing(2.5),
    },
  }),
);

const OmbudsmanCreateForm: FC<{ fullScreen: boolean }> = ({ fullScreen }) => {
  const classes = useStyles();
  const [attachs, setAttachs] = useState<FileWithPath[]>([]);
  const [ombudsmanFields, setOmbudsmanFields] = useState<OmbudsmanFields>({
    crm: undefined,
    description: ``,
    estimatedEndDate: null,
    institution: undefined,
    requester: undefined,
    requesterSector: ``,
    responsible: undefined,
    responsibleSector: ``,
    sector: [],
    source: ``,
    startDate: null,
    subcategory: undefined,
    title: ``,
    users: undefined,
  });

  const dialog = useRef(useDialog());
  const dispatch = useDispatch();
  const institutions = useSelector<ApplicationState, Institution[]>(
    state => state.Institutions.data,
    (previous, next) => JSON.stringify(previous) === JSON.stringify(next),
  );
  const sectors = useSelector<ApplicationState, Sector[]>(
    state => state.Sectors.data,
    (previous, next) => JSON.stringify(previous) === JSON.stringify(next),
  );
  const ombudsmansProps = useSelector<ApplicationState, OmbudsmansState>(
    state => state.Ombudsmans,
    (previous, next) => JSON.stringify(previous) === JSON.stringify(next),
  );
  const ombudsmanSources = useSelector<ApplicationState, OmbudsmanSource[]>(
    state => state.OmbudsmanSources.data,
    (previous, next) => JSON.stringify(previous) === JSON.stringify(next),
  );
  const tasksCategoriesState = useSelector<ApplicationState, TasksCategoryState>(
    state => state.TasksCategories,
    (previous, next) => JSON.stringify(previous) === JSON.stringify(next),
  );
  const loggedUser = useSelector<ApplicationState, User>(
    state => state.Auth.data.user,
    (previous, next) => JSON.stringify(previous) === JSON.stringify(next),
  );

  const {
    handleDateFieldChange,
    handleFieldChange,
    handleFieldValidate,
    handleFormValidate,
    handleInstitutionChange,
    handleReactSelectChange,
    handleSubCategoryChange,
  } = handleChangeFields<OmbudsmanFields>({
    errorSetter: (name, remove, formErrors) => {
      if (formErrors) {
        dispatch(OmbudsmansActions.ombudsmanFormErrors(formErrors));
        return;
      }
      if (remove) {
        const { [name as keyof OmbudsmanError]: removed, ...rest } = ombudsmansProps.formErrors || {
          [name as keyof OmbudsmanError]: `Removed`,
        };
        dispatch(OmbudsmansActions.ombudsmanFormErrors(rest));
        return;
      }
      dispatch(
        OmbudsmansActions.ombudsmanFormErrors({ ...ombudsmansProps.formErrors, [name]: `Esse campo é obrigatório` }),
      );
    },
    fieldsValues: ombudsmanFields,
    setter: setOmbudsmanFields,
    tasksCategoriesState,
  });

  const onDrop = useCallback((acceptedFiles: FileWithPath[]): void => {
    setAttachs(prevFiles => [
      ...prevFiles,
      ...acceptedFiles.filter(file => prevFiles.every(prev => prev.lastModified !== file.lastModified)),
    ]);
  }, []);

  const disableResponsible =
    !!ombudsmanFields.responsibleSector &&
    !loggedUser.sector.some(sec => sec.id === (ombudsmanFields.responsibleSector as number));

  const { dialogOpen } = ombudsmansProps;
  const { closeOmbudsmansRequest } = OmbudsmansActions;
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const { ref, ...rootProps } = getRootProps();

  const getNoOptionsMessage = (sectorField: `requesterSector` | `responsibleSector`): string | undefined => {
    if (!ombudsmanFields.institution) return `Selecione uma institutição`;
    if (!ombudsmanFields[sectorField])
      return `Selecione um setor ${sectorField === `requesterSector` ? `solicitante` : `responsável`}`;
    return undefined;
  };

  const noResponsibleObject = {
    ...loggedUser,
    avatarThumb: `Não encontrada`,
    firstName: `Sem`,
    id: -1,
    lastName: `Responsável`,
    userInstitution: [],
  };

  function handleCloseDialog(): void {
    dispatch(closeOmbudsmansRequest(dialog.current));
  }

  function handleFileDelete(id: number | string): () => void {
    return function fileDelete(): void {
      setAttachs(prevAttachs => [...prevAttachs.filter(attach => attach.lastModified !== id)]);
    };
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    if (
      handleFormValidate([
        `description`,
        `institution`,
        `requester`,
        `requesterSector`,
        `responsibleSector`,
        `source`,
        `title`,
      ])
    )
      return;
    const { crm, requester, responsible, users } = ombudsmanFields;
    const data = new FormData(event.currentTarget);
    data.delete(`attachs`);
    if (attachs.length) {
      attachs.forEach((attach: FileWithPath, idx: number): void => {
        data.append(`attachs[${idx}]`, attach);
      });
    } else {
      data.delete(`attachs`);
    }
    if (crm) data.set(`crm`, crm.toString());
    if (requester) data.set(`requester`, requester.toString());
    if (responsible) data.set(`responsible`, responsible.toString());
    if (users) data.set(`users`, users.toString());
    dispatch(OmbudsmansActions.ombudsmanAddOrUpdateRequest({ data, dialog: dialog.current }));
  }

  useEffect(() => {
    setOmbudsmanFields(prevFields => ({
      ...prevFields,
      institution: loggedUser.userInstitution.length === 1 ? loggedUser.userInstitution[0].id : undefined,
      requester: loggedUser.id,
      requesterSector: loggedUser.sector.length === 1 ? loggedUser.sector[0].id : ``,
    }));
  }, [loggedUser.id, loggedUser.sector, loggedUser.userInstitution]);

  const selectedInstitution = institutions.find(inst => inst.id === ombudsmanFields.institution);

  const responsibleInstitutions = selectedInstitution
    ? [
        ...institutions
          .filter(inst => inst.id === selectedInstitution.parent || inst.parent === selectedInstitution.id)
          .map(inst => inst.id),
        selectedInstitution.id,
      ]
    : undefined;

  return (
    <RootRef rootRef={ref}>
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        maxWidth="sm"
        onClose={handleCloseDialog}
        open={dialogOpen}
        {...rootProps} // eslint-disable-line
        onClick={undefined}
      >
        <DropZoneIndicator isDragActive={isDragActive} />
        <DialogTitle
          classes={{
            root: classes.dialogTitleRoot,
          }}
          disableTypography
        >
          <Box
            alignContent="center"
            alignItems="center"
            className={classes.dialogTitleBox}
            display="flex"
            justifyContent="space-between"
          >
            <Typography variant="h6">
              <RecordVoiceOverOutlinedIcon className={classes.dialogTitleIcon} fontSize="small" />
            </Typography>
            <Typography variant="h6">Criar nova ouvidoria</Typography>
          </Box>
          <IconButton
            onClick={(): void => {
              dispatch(closeOmbudsmansRequest(dialog.current));
            }}
            size="small"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form id="ombudsmanCreateForm" noValidate onSubmit={handleSubmit}>
            <Grid container>
              <Grid item xs={12}>
                <CustomTextField
                  error={!!ombudsmansProps.formErrors && !!ombudsmansProps.formErrors.title}
                  fullWidth
                  helperText={!!ombudsmansProps.formErrors && ombudsmansProps.formErrors.title}
                  id="id_title"
                  label="Título"
                  margin="dense"
                  name="title"
                  onBlur={(): void => handleFieldValidate(`title`)}
                  onChange={handleFieldChange(`title`)}
                  required
                  value={ombudsmanFields.title}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  error={!!ombudsmansProps.formErrors && !!ombudsmansProps.formErrors.description}
                  fullWidth
                  helperText={!!ombudsmansProps.formErrors && ombudsmansProps.formErrors.description}
                  id="id_description"
                  inputProps={{
                    style: {
                      minHeight: 80.5,
                    },
                  }}
                  label="Detalhamento"
                  margin="dense"
                  multiline
                  name="description"
                  onBlur={(): void => handleFieldValidate(`description`)}
                  onChange={handleFieldChange(`description`)}
                  required
                  value={ombudsmanFields.description}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <CustomTextField
                  className={classes.customTextFieldLeft}
                  error={!!ombudsmansProps.formErrors && !!ombudsmansProps.formErrors.source}
                  fullWidth
                  helperText={!!ombudsmansProps.formErrors && ombudsmansProps.formErrors.source}
                  id="id_ombSource"
                  label="Origem da ouvidoria"
                  margin="dense"
                  name="ombudsman.source"
                  onBlur={(): void => handleFieldValidate(`source`)}
                  onChange={handleFieldChange(`source`)}
                  required
                  select
                  value={ombudsmanFields.source}
                >
                  {ombudsmanSources.map(source => (
                    <MenuItem dense={false} key={source.id} value={source.id}>
                      {source.name}
                    </MenuItem>
                  ))}
                </CustomTextField>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TaskCategorySelect
                  categoriesAndSubCategories={getTaskCategories(
                    ombudsmanFields.responsibleSector as number,
                    tasksCategoriesState,
                  )}
                  formControlClass={classes.customTextFieldRight}
                  id="id_subcategory"
                  isLoading={tasksCategoriesState.loading}
                  label="Categorias"
                  margin={MarginType.dense}
                  name="subcategory"
                  onChange={handleSubCategoryChange}
                  value={ombudsmanFields.subcategory}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <CustomDateTimePicker
                  className={classes.customTextFieldLeft}
                  disabled={disableDates(tasksCategoriesState, ombudsmanFields.subcategory)}
                  label="Data de início prevista"
                  margin={MarginType.dense}
                  name="startDate"
                  onChange={handleDateFieldChange(`startDate`)}
                  value={ombudsmanFields.startDate}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <CustomDateTimePicker
                  className={classes.customTextFieldRight}
                  disabled={disableDates(tasksCategoriesState, ombudsmanFields.subcategory)}
                  label="Data de entrega esperada"
                  margin={MarginType.dense}
                  name="estimatedEndDate"
                  onChange={handleDateFieldChange(`estimatedEndDate`)}
                  value={ombudsmanFields.estimatedEndDate}
                />
              </Grid>
              {disableDates(tasksCategoriesState, ombudsmanFields.subcategory) && (
                <HelperBox
                  icon={<InfoOutlinedIcon color="inherit" />}
                  text={
                    <>
                      As datas são calculadas automaticamente de acordo com o <strong>TPR</strong> e
                      <strong>&nbsp;TR esperados da categoria selecionada</strong>
                    </>
                  }
                  variant={HelperBoxVariantTypes.info}
                />
              )}
              <Grid item xs={12}>
                <Typography className={classes.formCategoryText}>Contexto</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <CustomInstitutionsSelector
                  error={!!ombudsmansProps.formErrors && !!ombudsmansProps.formErrors.institution}
                  helperText={ombudsmansProps.formErrors ? ombudsmansProps.formErrors.institution : undefined}
                  id="id_institution"
                  institutions={institutions}
                  margin={MarginType.dense}
                  name="institution"
                  onBlur={(): void => handleFieldValidate(`institution`)}
                  onChange={handleInstitutionChange}
                  required
                  selectClass={classes.customInstitutionsField}
                  selectionMode={SelectionMode.single}
                  value={ombudsmanFields.institution}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <CRMSelector
                  inputClassName={classes.customTextFieldRight}
                  onChange={handleReactSelectChange(`crm`)}
                  value={ombudsmanFields.crm}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.formCategoryText}>Solicitante</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <CustomTextField
                  className={classes.customTextFieldLeft}
                  error={!!ombudsmansProps.formErrors && !!ombudsmansProps.formErrors.requesterSector}
                  helperText={!!ombudsmansProps.formErrors && ombudsmansProps.formErrors.requesterSector}
                  id="id_requesterSector"
                  label="Setor solicitante"
                  onBlur={(): void => handleFieldValidate(`requesterSector`)}
                  onChange={handleFieldChange(`requesterSector`)}
                  margin={MarginType.dense}
                  name="requesterSector"
                  required
                  SelectProps={{
                    renderValue(value: unknown): ReactNode {
                      const sector = sectors.find(sec => sec.id === value);
                      return sector ? sector.name : ``;
                    },
                  }}
                  select
                  value={ombudsmanFields.requesterSector}
                >
                  {sectors.map(
                    (sector): ReactNode => {
                      return (
                        <MenuItem dense={false} key={sector.id} value={sector.id}>
                          <ListItemText primary={sector.name} />
                        </MenuItem>
                      );
                    },
                  )}
                </CustomTextField>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <UserSelect
                  error={!!ombudsmansProps.formErrors && !!ombudsmansProps.formErrors.requester}
                  helperText={ombudsmansProps.formErrors ? ombudsmansProps.formErrors.requester : undefined}
                  inputClassName={classes.customTextFieldRight}
                  institutionIds={ombudsmanFields.institution ? [ombudsmanFields.institution] : undefined}
                  label="Relator"
                  name="requester"
                  noOptionsMessage={getNoOptionsMessage(`requesterSector`)}
                  onBlur={(): void => handleFieldValidate(`requester`)}
                  onChange={handleReactSelectChange(`requester`)}
                  preventSearch={!ombudsmanFields.institution || !ombudsmanFields.requesterSector}
                  required
                  sectorIds={`${ombudsmanFields.requesterSector}`}
                  value={ombudsmanFields.requester}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.formCategoryText}>Responsável</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <CustomTextField
                  className={classes.customTextFieldLeft}
                  error={!!ombudsmansProps.formErrors && !!ombudsmansProps.formErrors.responsibleSector}
                  helperText={!!ombudsmansProps.formErrors && ombudsmansProps.formErrors.responsibleSector}
                  id="id_responsibleSector"
                  label="Setor responsável"
                  onBlur={(): void => handleFieldValidate(`responsibleSector`)}
                  onChange={handleFieldChange(`responsibleSector`)}
                  margin={MarginType.dense}
                  name="responsibleSector"
                  required
                  SelectProps={{
                    renderValue(value: unknown): ReactNode {
                      const sector = sectors.find(sec => sec.id === value);
                      return sector ? sector.name : ``;
                    },
                  }}
                  select
                  value={ombudsmanFields.responsibleSector}
                >
                  {sectors.map(
                    (sector): ReactNode => {
                      return (
                        <MenuItem dense={false} key={sector.id} value={sector.id}>
                          <ListItemText primary={sector.name} />
                        </MenuItem>
                      );
                    },
                  )}
                </CustomTextField>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <UserSelect
                  defaultOptions={[
                    // {
                    //   ...loggedUser,
                    // },
                    noResponsibleObject,
                  ]}
                  disabled={disableResponsible}
                  inputClassName={classes.customTextFieldRight}
                  institutionIds={responsibleInstitutions}
                  label="Responsável"
                  name="responsible"
                  noOptionsMessage={getNoOptionsMessage(`responsibleSector`)}
                  onChange={handleReactSelectChange(`responsible`)}
                  preventSearch={!ombudsmanFields.institution || !ombudsmanFields.responsibleSector}
                  sectorIds={`${ombudsmanFields.responsibleSector}`}
                  value={ombudsmanFields.responsible}
                />
              </Grid>
              {disableResponsible && (
                <HelperBox
                  icon={<InfoOutlinedIcon color="inherit" />}
                  text="Só é possível atribuir uma pessoa responsável em setores aos quais você pertence. O administrador do setor responsável selecionado ficará encarregado de delegar esta tarefa para uma pessoa."
                  variant={HelperBoxVariantTypes.info}
                />
              )}
              <Grid item xs={12}>
                <Typography className={classes.formCategoryText}>Acompanhamento</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <CustomTextField
                  className={classes.customTextFieldLeft}
                  id="id_sector"
                  label="Setores seguidores"
                  onChange={handleFieldChange(`sector`)}
                  margin={MarginType.dense}
                  name="sector"
                  SelectProps={{
                    multiple: true,
                    renderValue(value: unknown): ReactNode {
                      const newValue = value as number[];
                      if (newValue.length === 1) {
                        const sector = sectors.find(sec => sec.id === newValue[0]);
                        return sector ? sector.name : ``;
                      }
                      if (newValue.length > 1) return `${newValue.length} setores`;
                      return ``;
                    },
                  }}
                  select
                  value={ombudsmanFields.sector}
                >
                  {sectors.map(
                    (sector): ReactNode => {
                      return (
                        <MenuItem dense={false} key={sector.id} value={sector.id}>
                          <ListItemText primary={sector.name} />
                        </MenuItem>
                      );
                    },
                  )}
                </CustomTextField>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <UserSelect
                  defaultOptions={[
                    {
                      ...loggedUser,
                    },
                  ]}
                  inputClassName={classes.customTextFieldRight}
                  label="Seguidores"
                  multiple
                  name="users"
                  onChange={handleReactSelectChange(`users`)}
                  value={ombudsmanFields.users}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.formCategoryText}>Anexos</Typography>
              </Grid>
              <Grid item xs={12}>
                <TaskAttachsContainer
                  attachs={attachs}
                  attachsInput={<input {...getInputProps()} id="id_attachs" name="attachs" />} // eslint-disable-line
                  deleteCallback={handleFileDelete}
                  disableTitle
                  loggedUserId={loggedUser.id}
                  reactDropzoneOnClick={rootProps.onClick}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions classes={{ root: classes.actionsRoot, spacing: classes.actionsSpacing }}>
          <Button
            className={`${classes.button} ${classes.cancelButton}`}
            disabled={ombudsmansProps.isSaving}
            onClick={handleCloseDialog}
          >
            Cancelar
          </Button>
          <Button
            classes={{ disabled: classes.disabledButton }}
            className={classes.button}
            color="primary"
            disabled={ombudsmansProps.isSaving}
            form="ombudsmanCreateForm"
            type="submit"
            variant="contained"
          >
            {ombudsmansProps.isSaving ? <CircularProgress color="inherit" size={24} /> : `Salvar`}
          </Button>
        </DialogActions>
      </Dialog>
    </RootRef>
  );
};

export default OmbudsmanCreateForm;
