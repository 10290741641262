import React, { useEffect, useRef, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { OptionProps, Props, ValueType } from 'react-select';
import { Avatar, FormHelperText, MenuItem, Tooltip, Typography } from '@material-ui/core';
import { makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import { CheckCircle, Search } from '@material-ui/icons';
import { AxiosResponse } from 'axios';
import { Pagination } from '../../ReduxFlow/Reducers/types';
import api from '../../Services/api';

const useStyles = makeStyles((theme: Theme) => ({
  avatarLabel: {
    height: 20,
    margin: theme.spacing(0.5),
    width: 20,
  },
  avatarUser: {
    height: 25,
    marginRight: theme.spacing(1),
    width: 25,
  },
  avatarInstitutionSingle: {
    height: 20,
    marginLeft: theme.spacing(0.625),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    width: 20,
  },
  avatarInstitutionMulti: {
    height: 20,
    marginLeft: `-10px`,
    marginRight: `0`,
    width: 20,
  },
  avatarInstitutionMultiContainer: {
    display: `flex`,
    marginTop: theme.spacing(0.5),
    '& > :first-child': {
      marginLeft: 0,
    },
    '& > :last-child': {
      marginRight: theme.spacing(1),
    },
  },
  dropDown: {
    zIndex: 5,
    position: `absolute`,
    width: `100%`,
  },
  formHelperText: {
    marginLeft: theme.spacing(1.75),
    marginRight: theme.spacing(1.75),
  },
  formHelperTextError: {
    color: theme.palette.secondary.main,
  },
  labelUsername: {
    alignItems: `center`,
    color: `#222222`,
    display: `flex`,
  },
  labelInstitution: {
    alignItems: `center`,
    color: `#767676`,
    display: `flex`,
  },
  dense: {
    margin: `${theme.spacing(1)}px 0px ${theme.spacing(0.5)}px 0px`,
  },
  none: {
    margin: `${theme.spacing(2)}px 0 ${theme.spacing(1)}px 0`,
  },
  normal: {
    margin: `${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1)}px`,
  },
  placeholder: {
    fontSize: 14,
    left: theme.spacing(2),
    position: `absolute`,
  },
  root: {
    flexGrow: 1,
    minHeight: 50,
    position: `relative`,
    width: `calc(100% - ${theme.spacing(1)}px)`,
    zIndex: 2,
  },
  selectorlabel: {
    alignContent: `center`,
    alignItems: `center`,
    display: `flex`,
    padding: `${theme.spacing(2.3125)}px ${theme.spacing(1.75)}px ${theme.spacing(2.3125)}px ${theme.spacing(1.75)}px`,
    width: `100%`,
    '& > :first-child': {
      marginRight: theme.spacing(0.75),
    },
  },
  selectorlabelMulti: {
    justifyContent: `space-between`,
  },
  toggleButton: {
    color: theme.palette.text.secondary,
    display: `flex`,
    justifyContent: `left`,
    maxHeight: theme.spacing(7),
    minHeight: theme.spacing(7),
    minWidth: theme.spacing(16),
    outline: `none`,
    width: `100%`,
  },
  toggleButtonLabel: {
    borderColor: `#f1f1f1`,
    borderImage: `initial`,
    borderRadius: theme.spacing(0.5),
    borderStyle: `solid`,
    borderWidth: 2,
    color: theme.palette.text.secondary,
    display: `flex`,
    fontFamily: `"Hind", "Helvetica", "Arial", sans-serif`,
    fontSize: `0.75em`,
    fontWeight: 400,
    height: `fit-content`,
    letterSpacing: `0.00938em`,
    lineHeight: 1,
    marginTop: -theme.spacing(0.625),
    maxHeight: theme.spacing(7.5),
    minHeight: theme.spacing(7.5),
    minWidth: theme.spacing(16),
    padding: `0 ${theme.spacing(1)}px`,
    position: `absolute`,
    width: `inherit`,
    '&:focus-within': {
      borderColor: `#2ec4b6`,
      color: `#2ec4b6`,
    },
    '&:hover:not(:focus-within)': {
      borderColor: `#b0b0b0`,
      color: `#b0b0b0`,
    },
    '& legend': {
      color: `inherit`,
      padding: `0 5px`,
    },
  },
  toggleButtonLabelError: {
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    pointerEvents: `none`,
  },
  toggleButtonLabelOpen: {
    borderColor: `#b0b0b0`,
  },
  tooltip: {
    backgroundColor: `#17181A`,
    fontSize: 14,
  },
  tooltipInstitution: {
    alignItems: `center`,
    color: `#8D8D8D`,
    display: `flex`,
  },
  tooltipUsername: {
    alignItems: `center`,
    color: `#FDFDFD`,
    display: `flex`,
  },
}));

type UserSelectorClasses = ReturnType<typeof useStyles>;

interface Institution {
  id: number;
  institutionName: string;
  institutionAvatar?: string;
}

interface User {
  avatarThumb: string;
  firstName: string;
  id: number;
  lastName: string;
  userInstitution: Institution[];
}

type UserPage = Pagination & {
  results: User[];
};

type OptionType = User | User[];

type MarginType = `dense` | `none` | `normal`;

type UserOptionProps = Partial<OptionProps<OptionType>>;

// eslint-disable-next-line quotes
type SelectComponents = Props<User>['components'];

interface UserSelectorProps {
  autofocus?: boolean;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  forceInstitution: boolean;
  institutionIds?: number[];
  label: string;
  loggedUserInfo: User;
  margin?: MarginType;
  multiple?: boolean;
  name: string;
  onChange: (value: number | number[] | undefined) => void;
  onClose: () => void;
  openUp: boolean;
  required: boolean;
  value?: number | number[];
}

const renderOptionInstitutionList = (institutions: Institution[], classes: UserSelectorClasses): JSX.Element => {
  const firstInstitutionName = institutions[0] && institutions[0].institutionName;
  const othersInstitutionsText =
    institutions.length === 2 ? `e ${institutions[1].institutionName}` : `e outras ${institutions.length - 1}`;
  return (
    <>
      <div className={classes.avatarInstitutionMultiContainer}>
        {institutions.slice(0, 3).map(i => (
          <Avatar src={i.institutionAvatar} key={i.id} className={classes.avatarInstitutionMulti} />
        ))}
      </div>
      <span
        style={{
          marginRight: 4,
          minWidth: institutions.length === 2 ? `fit-content` : undefined,
          overflow: `hidden`,
          textOverflow: institutions.length === 2 ? undefined : `ellipsis`,
          whiteSpace: `nowrap`,
        }}
      >{`${firstInstitutionName}`}</span>
      <span
        style={{
          minWidth: institutions.length === 2 ? undefined : `fit-content`,
          overflow: `hidden`,
          textOverflow: institutions.length === 2 ? `ellipsis` : undefined,
          whiteSpace: `nowrap`,
        }}
      >
        {othersInstitutionsText}
      </span>
    </>
  );
};

const renderUserOption = (
  classes: UserSelectorClasses,
  data: User,
  isMulti = false,
  isSelected = false,
  isTooltip = false,
): JSX.Element => (
  <div
    key={data.id}
    style={{ display: `flex`, justifyContent: `space-between`, padding: isTooltip ? `4px 8px` : `8px 16px` }}
  >
    <div style={{ overflow: `hidden` }}>
      <div className={classes.labelUsername}>
        <Avatar src={data.avatarThumb} className={classes.avatarUser} />
        <span style={{ overflow: `hidden`, textOverflow: `ellipsis`, whiteSpace: `nowrap` }}>
          {data.firstName} {data.lastName}
        </span>
      </div>
      <div className={classes.labelInstitution}>
        {!!data.userInstitution.length &&
          (data.userInstitution.length === 1 ? (
            <>
              <Avatar src={data.userInstitution[0].institutionAvatar} className={classes.avatarInstitutionSingle} />
              <span style={{ overflow: `hidden`, textOverflow: `ellipsis`, whiteSpace: `nowrap` }}>
                {data.userInstitution[0].institutionName}
              </span>
            </>
          ) : (
            renderOptionInstitutionList(data.userInstitution, classes)
          ))}
      </div>
    </div>
    <div style={{ display: isMulti && isSelected ? `flex` : `none`, alignItems: `center` }}>
      <CheckCircle style={{ color: `#3AACD7` }} />
    </div>
  </div>
);

const Option = (props: UserOptionProps): JSX.Element => {
  const { data, innerProps, innerRef, isDisabled, isFocused, isMulti, isSelected, selectProps } = props;
  return (
    <MenuItem
      buttonRef={innerRef}
      selected={isFocused}
      component="div"
      style={{
        backgroundColor: isMulti && isSelected ? `#E6F7FF` : undefined,
        color: isDisabled ? `#d3d3d3` : ``,
        display: `block`,
        fontWeight: isSelected ? 500 : 400,
        padding: 0,
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...innerProps}
    >
      {renderUserOption(selectProps ? selectProps.classes : {}, data, isMulti, isSelected)}
    </MenuItem>
  );
};

interface BlanketProps {
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const Blanket = ({ onClick }: BlanketProps): JSX.Element => (
  // eslint requires a "role" attribute, but none are appropriate
  // eslint-disable-next-line
  <div
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: `fixed`,
      zIndex: 1,
    }}
    onClick={onClick}
  />
);

const GenericMessage = (label: string) => ({ innerProps }: UserOptionProps): JSX.Element => (
  <div style={{ display: `flex` }}>
    <Typography
      color="textSecondary"
      style={{ marginRight: `auto`, marginLeft: `auto` }}
      variant="caption"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...innerProps}
    >
      {label}
    </Typography>
  </div>
);

// const NoOptionsMessage = GenericMessage(`Sem opções`);
const NoOptionsMessage = (noInstitution: boolean): ((props: UserOptionProps) => JSX.Element) =>
  GenericMessage(noInstitution ? `Selecione uma instituição` : `Sem opções`);

const LoadingMessage = GenericMessage(`Pesquisando...`);

interface DropdownProps extends UserOptionProps {
  classes: UserSelectorClasses;
  id: string;
  isOpen: boolean;
  onClose: (event: React.MouseEvent<HTMLDivElement>) => void;
  target: JSX.Element;
}

const Dropdown = ({ children, className, classes, id, isOpen, onClose, target }: DropdownProps): JSX.Element => (
  <div className={className} id={id} style={{ zIndex: isOpen ? 3 : undefined }}>
    {target}
    {isOpen ? (
      <>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <div className={classes.dropDown}>{children}</div>
        <Blanket onClick={onClose} />
      </>
    ) : null}
  </div>
);

interface SelectedLabelProps extends UserOptionProps {
  value: ValueType<OptionType>;
  classes: UserSelectorClasses;
}

const SelectedLabel = ({ value, classes, isMulti }: SelectedLabelProps): JSX.Element => {
  if (Array.isArray(value) && value.length) {
    const users = value.slice(0, 3);
    const more = value.length - 3;
    const label = value.length === 1 ? `${value[0].firstName} ${value[0].lastName}` : `${value.length} pessoas`;
    return (
      <div className={`${classes.selectorlabel} ${classes.selectorlabelMulti}`}>
        <div>{label}</div>
        <div style={{ alignContent: `center`, alignItems: `center`, display: `flex` }}>
          {users.map(u => {
            return (
              <div key={u.id}>
                <Avatar className={classes.avatarLabel} src={u.avatarThumb} />
              </div>
            );
          })}
          {more > 0 ? `+ ${more}` : ``}
        </div>
      </div>
    );
  }

  let name = `Nenhum`;
  let avatar = ``;
  if (!Array.isArray(value) && value) {
    const single = value as User;
    name = `${single.firstName} ${single.lastName}`;
    avatar = single.avatarThumb;
  }

  return (
    <div className={isMulti ? `${classes.selectorlabel} ${classes.selectorlabelMulti}` : classes.selectorlabel}>
      <Avatar src={avatar} className={classes.avatarLabel} />
      {name}
    </div>
  );
};

const DropdownIndicator = (): JSX.Element => <Search style={{ color: `#767676` }} />;

const UserSelector = ({
  autofocus = true,
  disabled = false,
  error = false,
  forceInstitution = false,
  helperText = ``,
  institutionIds,
  loggedUserInfo,
  label,
  margin = `none`,
  multiple,
  name,
  onChange,
  onClose,
  openUp,
  required = false,
  value: startingValue,
}: UserSelectorProps): React.ReactNode => {
  const theme = useTheme();
  const classes = useStyles();
  const dropDownRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState<ValueType<OptionType>>();
  const noResponsibleObject = {
    avatarThumb: `Não encontrada`,
    firstName: `Sem`,
    id: -1,
    lastName: `Responsável`,
    userInstitution: [],
  };
  const debounceApi = useRef(0);
  const valueAsArray = (value && (Array.isArray(value) ? (value as User[]) : [value as User])) || [];
  const hasStartingValue =
    (Array.isArray(startingValue) && startingValue.length > 0) || (!Array.isArray(startingValue) && startingValue);
  const shouldBlockSearchNoInstitution = forceInstitution && (!institutionIds || institutionIds.length === 0);
  const components: SelectComponents = {
    DropdownIndicator,
    IndicatorSeparator: null,
    // @ts-ignore
    LoadingMessage,
    // @ts-ignore
    NoOptionsMessage: NoOptionsMessage(shouldBlockSearchNoInstitution),
    // @ts-ignore
    Option,
  };
  if (shouldBlockSearchNoInstitution)
    // @ts-ignore
    components.Control = (): React.ReactElement => <div style={{ padding: `${theme.spacing(0.5)}px` }} />; // eslint-disable-line react/display-name

  const selectStyles = {
    container: (base: object): object => ({
      ...base,
      backgroundColor: `#fff`,
      borderRadius: `6px`,
      bottom: openUp ? theme.spacing(9) : `unset`,
      boxShadow: `0px 1px 4px #00000026`,
      display: `flex`,
      marginTop: theme.spacing(1),
      position: `absolute`,
      width: `100%`,
      flexDirection: openUp ? `column-reverse` : `column`,
    }),
    control: (base: object): object => ({
      ...base,
      backgroundColor: `#f5f5f5`,
      borderColor: `#fff`,
      borderRadius: `6px`,
      boxShadow: `none`,
      margin: theme.spacing(2),
      ':hover': {
        borderColor: `#fff`,
      },
    }),
    input: (base: object): object => ({
      ...base,
      color: theme.palette.text.primary,
    }),
    menu: (base: object): object => ({
      ...base,
      boxShadow: `none`,
      marginTop: 0,
      position: `unset`,
      zIndex: 3,
    }),
    menuList: (base: object): object => ({
      ...base,
      maxHeight: `150px`,
      paddingBottom: 0,
      paddingTop: 0,
    }),
  };

  const toggleSelector = (): void => {
    setIsOpen(!isOpen);
  };

  const onSelectChange = (selected: ValueType<OptionType>): void => {
    if (!multiple && selected && !Array.isArray(selected) && (selected as User).id === -1) {
      setValue(undefined);
      onChange(undefined);
      setIsOpen(false);
      return;
    }
    setValue(selected);
    if (onChange) {
      if (multiple) {
        onChange(selected ? (selected as User[]).map(u => u.id) : []);
      } else {
        onChange(selected ? (selected as User).id : undefined);
        setIsOpen(false);
      }
    }
  };

  const loadStartingValues = (): void => {
    if (!hasStartingValue) return;
    const ids = Array.isArray(startingValue) ? startingValue : [startingValue];
    api
      .get(`account/users/select/`, {
        params: {
          ids: ids.join(`...`),
          page: 1,
          length: ids.length,
        },
      })
      .then(response => {
        if (multiple) setValue(response.data.results);
        else setValue(response.data.results.length ? response.data.results[0] : undefined);
      })
      // eslint-disable-next-line no-console
      .catch(e => console.error(`error fetching users`, e));
  };

  const loadOptions = (search: string): Promise<User[]> => {
    return new Promise(resolve => {
      clearTimeout(debounceApi.current);
      if (shouldBlockSearchNoInstitution) return;
      debounceApi.current = window.setTimeout(async () => {
        try {
          const response: AxiosResponse<UserPage> = await api.get(`account/users/select/`, {
            params: {
              globalSearch: search,
              institutionIds: institutionIds ? institutionIds.join(`...`) : undefined,
              isActive: `Ativo`,
              page: 1,
              length: 10,
            },
          });
          if (multiple) resolve(response.data.results);
          else resolve([loggedUserInfo, ...response.data.results.filter(user => user.id !== loggedUserInfo.id)]);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(`error fetching users`, e);
          resolve([]); // Select does not handle rejection
        }
      }, 1000);
    });
  };

  const SelectTooltip = (): JSX.Element => (
    <>
      {valueAsArray.map(u =>
        renderUserOption(
          {
            ...classes,
            labelUsername: classes.tooltipUsername,
            labelInstitution: classes.tooltipInstitution,
          },
          u,
          false,
          false,
          true,
        ),
      )}
    </>
  );

  useEffect(loadStartingValues, [startingValue]);

  const defaultOptions = !required && !multiple ? [loggedUserInfo, noResponsibleObject, ...valueAsArray] : valueAsArray;

  return (
    <Dropdown
      className={classes.root}
      classes={classes}
      isOpen={isOpen}
      id={`${name}-dropdown`}
      onClose={toggleSelector}
      target={
        <>
          <Tooltip
            classes={{
              tooltip: classes.tooltip,
            }}
            disableFocusListener={!valueAsArray.length || error}
            disableHoverListener={!valueAsArray.length || error}
            disableTouchListener={!valueAsArray.length || error}
            PopperProps={{
              style: {
                boxSizing: `border-box`,
                maxWidth: dropDownRef.current ? dropDownRef.current.offsetWidth : undefined,
              },
            }}
            placement={multiple ? `bottom-end` : `bottom-start`}
            title={SelectTooltip()}
          >
            <div
              className={`${classes.toggleButton} ${classes[margin]}`}
              onClick={toggleSelector}
              role="button"
              tabIndex={0}
              onKeyPress={toggleSelector}
              ref={dropDownRef}
            >
              <SelectedLabel classes={classes} value={value} isMulti={multiple} />
              <fieldset
                className={[
                  classes.toggleButtonLabel,
                  isOpen && !error ? classes.toggleButtonLabelOpen : ``,
                  error ? classes.toggleButtonLabelError : ``,
                ].join(` `)}
              >
                <legend style={{ cursor: `default`, pointerEvents: `none` }}>
                  {label}
                  {required ? ` *` : ``}
                </legend>
              </fieldset>
            </div>
          </Tooltip>
          {helperText && (
            <FormHelperText className={[classes.formHelperText, error ? classes.formHelperTextError : ``].join(` `)}>
              {helperText}
            </FormHelperText>
          )}
        </>
      }
    >
      <AsyncSelect
        autoFocus={autofocus}
        backspaceRemovesValue={false}
        cacheOptions
        classes={classes}
        components={components}
        controlShouldRenderValue={false}
        defaultOptions={defaultOptions}
        isDisabled={disabled || shouldBlockSearchNoInstitution}
        getOptionValue={(u: User): string => `${u.id}`}
        hideSelectedOptions={!multiple}
        isClearable={false}
        isMulti={multiple}
        isSearchable={!shouldBlockSearchNoInstitution}
        loadOptions={loadOptions}
        menuIsOpen
        name={name}
        onChange={onSelectChange}
        onClose={onClose}
        placeholder="Pesquisar..."
        required={required}
        styles={selectStyles}
        value={valueAsArray}
      />
    </Dropdown>
  );
};

export default UserSelector;
