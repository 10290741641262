import React, { FC, ReactElement, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Hidden, LinearProgress } from '@material-ui/core';
import { RecordVoiceOverOutlined as RecordVoiceOverOutlinedIcon } from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import MUIDataTable, { MUIDataTableColumn } from 'mui-datatables';
import { useDialog } from 'muibox';
import TableInstitutions from 'Components/TableInstitutions';
import { TaskType } from 'Pages/Tasks/tasksCommonHandlers';
import CustomTableTitle from '../../../Components/CustomTableTitle';
import { buildKpiComponent } from '../DynamicTasksPage';
import { FilterValue } from '../../../Routes';
import { ModelNames, useBuildColumns, useBuildOptions } from '../tableConfigByModel';
import { ApplicationState } from '../../../ReduxFlow/Reducers';
import { AuthState } from '../../../ReduxFlow/Reducers/Auth/Types';
import { Institution } from '../../../ReduxFlow/Reducers/Institutions/Types';
import { Ombudsman, OmbudsmansState } from '../../../ReduxFlow/Reducers/Ombudsmans/Types';
import { Sector } from '../../../ReduxFlow/Reducers/Sectors/Types';
import * as OmbudsmansActions from '../../../ReduxFlow/Reducers/Ombudsmans/Actions';
import TasksMobile from './Mobile';
import OmbudsmanDialogForm from './DialogForms';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      position: `relative`,
      top: 0,
      left: 0,
    },
    cell: {
      border: `none`,
      overflow: `hidden`,
      paddingRight: theme.spacing(2),
    },
    headerCell: {
      color: theme.palette.text.secondary,
    },
    institutionAvatar: {
      transition: `transform .3s`,
      '&:hover': {
        transform: `scale(3)`,
        zIndex: 100,
      },
    },
    markerRoot: {
      fontSize: 12,
      height: 15,
    },
    markerChildren: {
      padding: `0 6px 0 6px`,
    },
    smRecipient: {
      height: `calc(100vh - 56px)`,
      overflowY: `auto`,
    },
    table: {
      width: `100%`,
    },
  }),
);

interface OmbudsmansProps {
  avatar: string;
  filterValue: FilterValue;
  handleFetchStatus: () => void;
}

const Ombudsmans: FC<OmbudsmansProps> = ({ avatar: _avatar, filterValue, handleFetchStatus }) => {
  const classes = useStyles();
  const dialog = useRef(useDialog());
  const dispatch = useDispatch();
  const urlParams = useParams<{ id: string; modelName: ModelNames }>();
  const loggedUserInfo = useSelector<ApplicationState, AuthState>(state => state.Auth, shallowEqual);
  const institutions = useSelector<ApplicationState, Institution[]>(state => state.Institutions.data, shallowEqual);
  const ombudsmansProps = useSelector<ApplicationState, OmbudsmansState>(
    state => state.Ombudsmans,
    (previous, next) => JSON.stringify(previous) === JSON.stringify(next),
  );
  const sectors = useSelector<ApplicationState, Sector[]>(state => state.Sectors.data, shallowEqual);
  const { id: urlId, modelName } = urlParams;
  const columns = useBuildColumns({
    classes,
    institutions,
    modelName,
    props: ombudsmansProps,
    sectors,
  });

  const options = useBuildOptions({
    changeFilters: OmbudsmansActions.changeOmbudsmansFilters,
    changePages: OmbudsmansActions.changeOmbudsmansPages,
    changeSort: OmbudsmansActions.changeOmbudsmansSort,
    columns: columns as MUIDataTableColumn[],
    count: ombudsmansProps.pagination.count,
    dialog: dialog.current,
    filterList: ombudsmansProps.filterList,
    loadReportRequest: OmbudsmansActions.loadOmbudsmanReportRequest,
    loadReportSuccess: OmbudsmansActions.loadOmbudsmanReportSuccessfull,
    openDialog: OmbudsmansActions.openOmbudsmanRequest,
    orphans: ombudsmansProps.orphans,
    page: ombudsmansProps.page,
    rowsPerPage: ombudsmansProps.rowsPerPage,
    searchText: ombudsmansProps.searchText,
    sector: loggedUserInfo.data.user.sector,
    sort: ombudsmansProps.sort,
    taskType: `ombudsman`,
  });

  useEffect(() => {
    const { isLoadingUI } = loggedUserInfo;
    const { currentOrganization } = loggedUserInfo.data;
    const localStorageOrg = window.localStorage.getItem(`currentOrganization`);
    if (localStorageOrg === JSON.stringify(currentOrganization) && !isLoadingUI) {
      if (filterValue.status) {
        dispatch(
          OmbudsmansActions.changeOmbudsmansFilters({
            dialog: dialog.current,
            filterList: {
              taskType: [`Ativo`],
              responsibleSector: loggedUserInfo.data.user.sector.map(sec => sec.name),
            },
            searchText: filterValue.value,
          }),
        );
        handleFetchStatus();
      }
      if (urlId) {
        dispatch(OmbudsmansActions.openOmbudsmanRequest({ dialog: dialog.current, id: urlId }));
      }
    }
  }, [
    dispatch,
    filterValue.status,
    filterValue.value,
    handleFetchStatus,
    loggedUserInfo,
    ombudsmansProps.dialogOpen,
    urlId,
  ]);

  return (
    <>
      <Hidden smDown>
        {ombudsmansProps.isLoading && <LinearProgress style={{ position: `relative` }} />}
        <MUIDataTable
          title={
            <CustomTableTitle title="Ouvidorias">
              {buildKpiComponent({ classes, props: { slaKpis: ombudsmansProps.slaKpis } })}
            </CustomTableTitle>
          }
          data={ombudsmansProps.data}
          columns={columns as MUIDataTableColumn[]}
          options={options}
        />
      </Hidden>
      <Hidden mdUp>
        <TasksMobile
          data={ombudsmansProps.data}
          changePages={OmbudsmansActions.changeOmbudsmansPages}
          elements={{
            itemIcon: <RecordVoiceOverOutlinedIcon />,
            key: (item): number | string => (item as Ombudsman).taskTitle.id,
            listItemSecondaryText: (item): string => `#${(item as Ombudsman).taskTitle.id}`,
            listItemSecondaryAction(item): ReactElement {
              return (
                <TableInstitutions
                  institutions={institutions}
                  selected={
                    Array.isArray((item as Ombudsman).institution)
                      ? (item as Ombudsman).institution
                      : [((item as Ombudsman).institution as unknown) as number]
                  }
                />
              );
            },
            listItemText: (item): string => (item as Ombudsman).taskTitle.title,
          }}
          isLoading={ombudsmansProps.isLoading}
          loadData={OmbudsmansActions.loadOmbudsmansRequest}
          onClick={item => (): void => {
            dispatch(
              OmbudsmansActions.openOmbudsmanRequest({ dialog: dialog.current, id: (item as Ombudsman).taskTitle.id }),
            );
          }}
          rowsPerPage={ombudsmansProps.rowsPerPage}
          tasksTotal={ombudsmansProps.pagination.count}
          taskType={TaskType.ombudsman}
        />
      </Hidden>
      {ombudsmansProps.dialogOpen && <OmbudsmanDialogForm formInstance={ombudsmansProps.formInstance} />}
    </>
  );
};

export default Ombudsmans;
