import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import ActionsCard from './ActionsCard';
import LegacyFilesUploadCard from './LegacyFilesUploadCard';
import LegacyFilesDownloadCard from './LegacyFilesDownloadCard';
import api from '../../../Services/api';

const Versions = ({
  canEdit,
  classes,
  commentCounts,
  formInstance,
  handleLogDownloadAction,
  handleLogReadAction,
  loggedUserInfo,
  versions,
}) => {
  const [downloadMenuAnchorEl, setDownloadMenuAnchorEl] = useState({});
  const [hasLegacyArchive, setHasLegacyArchive] = useState(formInstance && !!formInstance.hasLegacyArchive);
  const [showComments, setShowComments] = useState({});
  const commentCountByVersion = {};
  const [commentsByVersion, setCommentsByVersion] = useState({});

  commentCounts.forEach(data => {
    commentCountByVersion[data.id] = data.comments;
  });

  const handleCommentAction = versionId => {
    const isShowComments = !showComments[versionId];
    setShowComments({ ...showComments, [versionId]: isShowComments });
    if (isShowComments && !commentsByVersion[versionId]) {
      api
        .get(`/internaldocs/comments/?version=${versionId}`)
        .then(res => {
          setCommentsByVersion({ ...commentsByVersion, [versionId]: res.data });
        })
        .catch(e => {
          alert(`Erro ao buscar comentários`);
          console.error(`error fetchin comments`, e);
        });
    }
  };

  const archiveUploaded = () => {
    setHasLegacyArchive(true);
  };

  const archiveDeleted = () => {
    setHasLegacyArchive(false);
  };

  return (
    <div style={{ padding: 10 }}>
      {versions.length ? (
        versions.map(attach => {
          const { id, createdByName, createdAt } = attach;
          return (
            <ActionsCard
              canEdit={canEdit}
              classes={classes}
              commentCountByVersion={commentCountByVersion[id]}
              commentsByVersion={commentsByVersion[id]}
              downloadMenuAnchorEl={downloadMenuAnchorEl[id]}
              elaboratorUserName={`Criado por ${createdByName} em ${new Date(createdAt).toLocaleString(`pt-BR`)}`}
              handleCommentAction={() => handleCommentAction(id)}
              handleLogDownloadAction={handleLogDownloadAction}
              handleLogReadAction={handleLogReadAction}
              hasIntDocFormInstance
              intDocFormInstance={attach}
              key={id}
              loggedUserInfo={loggedUserInfo}
              openOrCloseDownloadMenu={event => {
                if (event && event.type && `click`.indexOf(event.type) > -1)
                  setDownloadMenuAnchorEl(prevState => ({
                    ...prevState,
                    [id]: prevState[id] ? null : event.currentTarget,
                  }));
              }}
              showComments={showComments[id]}
            />
          );
        })
      ) : (
        <Typography align="center">Esse documento não tem versões anteriores</Typography>
      )}

      {hasLegacyArchive ? (
        <LegacyFilesDownloadCard classes={classes} formInstance={formInstance} archiveDeleted={archiveDeleted} />
      ) : (
        <LegacyFilesUploadCard classes={classes} formInstance={formInstance} uploadCompleted={archiveUploaded} />
      )}
    </div>
  );
};

Versions.defaultProps = {
  versions: [],
};

Versions.propTypes = {
  versions: PropTypes.arrayOf(PropTypes.object),
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  handleLogReadAction: PropTypes.func.isRequired,
};

export default Versions;
