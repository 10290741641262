import React, { ChangeEventHandler, FC, FormEventHandler, ReactNode, useState } from 'react';
import { Grid, MenuItem, TextField } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { CRMError } from '../../../ReduxFlow/Reducers/CRMs/Types';
import { CRMData } from '.';
import CustomDatePicker from '../../../Components/NewUIComponents/CustomDatePicker';
import CustomTextField, { WrapperVariants } from '../../../Components/NewUIComponents/CustomTextField';
import { CPFMask, PassportMask } from '../../../Components/NewUIComponents/InputMasks';
import { validateCpf } from '../../../Utils/CpfAndCnpjValidator';
import { MarginType } from '../../../Utils/globalTypes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputRoot: {
      padding: `${theme.spacing(3.625)}px ${theme.spacing(1.75)}px ${theme.spacing(0.75)}px ${theme.spacing(1.75)}px`,
    },
    labelOutlined: {
      transform: `translate(${theme.spacing(1.75)}px, 0) scale(0.75) !important`,
    },
    labelRoot: {
      paddingTop: theme.spacing(1.25),
    },
    required: {
      color: theme.palette.secondary.main,
      marginRight: theme.spacing(0.5),
    },
    root: {
      '& > fieldset > legend': {
        maxWidth: 0,
      },
    },
    textFieldLeft: {
      margin: `${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(1)}px 0`,
      maxWidth: `calc(100% - 8px)`,
      [theme.breakpoints.down(`sm`)]: {
        margin: `${theme.spacing(2)}px 0`,
        minWidth: `100%`,
      },
    },
    textFieldRight: {
      margin: `${theme.spacing(2)}px 0 ${theme.spacing(1)}px ${theme.spacing(1)}px`,
      maxWidth: `calc(100% - 8px)`,
      [theme.breakpoints.down(`sm`)]: {
        margin: `${theme.spacing(2)}px 0`,
        minWidth: `100%`,
      },
    },
    twoTextFieldsLeft: {
      margin: `${theme.spacing(2)}px ${theme.spacing(0.5)}px ${theme.spacing(1)}px ${theme.spacing(1)}px`,
      maxWidth: `calc(100% - 12px)`,
      [theme.breakpoints.down(`sm`)]: {
        margin: `${theme.spacing(2)}px ${theme.spacing(0.5)}px ${theme.spacing(1)}px 0`,
        maxWidth: `calc(100% - 4px)`,
      },
    },
    twoTextFieldsRight: {
      margin: `${theme.spacing(2)}px 0 ${theme.spacing(1)}px ${theme.spacing(0.5)}px`,
      maxWidth: `calc(100% - 4px)`,
    },
  }),
);

interface CRMFormProps {
  crmData: CRMData;
  formErrors?: CRMError;
  handleChange: (name: string) => ChangeEventHandler;
  handleDateChange: (date: MaterialUiPickersDate) => void;
  handleSubmit: FormEventHandler;
}

const CRMForm: FC<CRMFormProps> = ({ crmData, formErrors, handleChange, handleDateChange, handleSubmit }) => {
  const classes = useStyles();
  const [validCpf, setValidCpf] = useState(true);

  function getTextFieldByDocumentType(): ReactNode {
    switch (crmData.documentTypeSelected) {
      case `CPF`:
        return (
          <CustomTextField
            className={classes.textFieldRight}
            error={!validCpf}
            fullWidth
            helperText={!validCpf ? `Esse não é um CPF válido!` : undefined}
            InputProps={{
              inputComponent: CPFMask,
            }}
            label="Número do documento"
            onBlur={(): void => setValidCpf(validateCpf(crmData.document))}
            onChange={handleChange(`document`)}
            required
            value={crmData.document}
            type="text"
          />
        );
      case `Passaporte`:
        return (
          <CustomTextField
            className={classes.textFieldRight}
            fullWidth
            InputProps={{
              inputComponent: PassportMask,
            }}
            label="Número do documento"
            onChange={handleChange(`document`)}
            required
            value={crmData.document}
            type="text"
          />
        );
      default:
        return (
          <Grid container>
            <Grid item xs={6}>
              <CustomTextField
                className={classes.twoTextFieldsLeft}
                fullWidth
                label="Documento"
                onChange={handleChange(`documentType`)}
                placeholder="Ex: RG, CNH"
                required
                value={crmData.documentType}
                type="text"
              />
            </Grid>
            <Grid item xs={6}>
              <CustomTextField
                className={classes.twoTextFieldsRight}
                fullWidth
                label="Número"
                onChange={handleChange(`document`)}
                required
                value={crmData.document}
                type="text"
              />
            </Grid>
          </Grid>
        );
    }
  }

  return (
    <form id="crmsForm" onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <CustomTextField
            className={classes.textFieldLeft}
            fullWidth
            label="Nome completo"
            onChange={handleChange(`fullName`)}
            required
            type="text"
            value={crmData.fullName}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomTextField
            className={classes.textFieldRight}
            fullWidth
            label="E-mail"
            onChange={handleChange(`email`)}
            type="email"
            value={crmData.email}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomDatePicker
            className={classes.textFieldLeft}
            fullWidth
            inputVariant={WrapperVariants.outlined}
            label="Data de nascimento"
            margin={MarginType.normal}
            onChange={handleDateChange}
            openTo="year"
            value={crmData.birthDate}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomTextField
            className={classes.textFieldRight}
            fullWidth
            label="ID Externo"
            onChange={handleChange(`externalId`)}
            placeholder="ID do cliente no RIS, LIS, PACS ou outro"
            type="text"
            value={crmData.externalId}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            className={classes.textFieldLeft}
            fullWidth
            InputProps={{ classes: { input: classes.inputRoot, root: classes.root } }}
            InputLabelProps={{
              classes: { outlined: classes.labelOutlined, root: classes.labelRoot },
              shrink: true,
            }}
            label="Documento"
            onChange={handleChange(`documentTypeSelected`)}
            select
            value={crmData.documentTypeSelected}
            variant="outlined"
          >
            {[`CPF`, `Passaporte`, `Outro`].map(
              (docOption: string): ReactNode => (
                <MenuItem key={docOption} value={docOption}>
                  {docOption}
                </MenuItem>
              ),
            )}
          </TextField>
        </Grid>
        {!!crmData.documentTypeSelected && (
          <Grid item xs={12} sm={6}>
            {getTextFieldByDocumentType()}
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default CRMForm;
