import React, { ReactNode, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Badge, Button, Chip, IconButton, Tooltip, Typography } from '@material-ui/core';
import {
  Add as AddIcon,
  CheckCircle as CheckCircleIcon,
  RecordVoiceOverOutlined as RecordVoiceOverOutlinedIcon,
} from '@material-ui/icons';
import { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { Dialog } from 'muibox';
import RangePicker from '../../Components/RangePicker';
import * as muiDatatableHelper from '../../Utils/muiDatatableHelper';
import SLATooltip from '../../Components/SLATooltip';
import { OmbudsmansState } from '../../ReduxFlow/Reducers/Ombudsmans/Types';
import { Institution } from '../../ReduxFlow/Reducers/Institutions/Types';
import { Sector } from '../../ReduxFlow/Reducers/Sectors/Types';
import { buildQueryStringFromObject } from '../../Utils/queryString';
import api from '../../Services/api';

export enum ModelNames {
  OMBUDSMAN = 'ombudsman', // eslint-disable-line
}

enum FilterTypes {
  CHECKBOX = 'checkbox', // eslint-disable-line
  CUSTOM = 'custom', // eslint-disable-line
  DROPDOWN = 'dropdown', // eslint-disable-line
  MULTISELECT = 'multiselect', // eslint-disable-line
  TEXTFIELD = 'textField', // eslint-disable-line
}

interface BuildOptionsParams {
  changeFilters: Function;
  changePages: Function;
  changeSort: Function;
  columns: MUIDataTableColumn[];
  count: number;
  dialog: Dialog;
  filterList: { [key: string]: string[] };
  loadReportRequest: Function;
  loadReportSuccess: Function;
  openDialog: Function;
  orphans: number;
  page: number;
  rowsPerPage: number;
  searchText?: string;
  sector: Sector[];
  sort: OmbudsmansState['sort']; // eslint-disable-line
  taskType: `tasks` | `ombudsman`;
}

const useBuildOptions = (data: BuildOptionsParams): MUIDataTableOptions => {
  const {
    changeFilters,
    changePages,
    changeSort,
    columns,
    count,
    dialog,
    filterList,
    loadReportRequest,
    loadReportSuccess,
    openDialog,
    orphans,
    page,
    rowsPerPage,
    searchText,
    sector,
    sort,
    taskType,
  } = data;
  const dispatch = useDispatch();
  const history = useHistory();

  return useMemo(
    (): MUIDataTableOptions => ({
      rowsPerPage,
      viewColumns: false,
      print: false,
      fixedHeader: true,
      selectableRows: `none`,
      filter: true,
      filterType: FilterTypes.TEXTFIELD,
      responsive: `stacked`,
      serverSide: true,
      count,
      page,
      searchText,
      customToolbar(): ReactNode {
        return (
          <>
            <Tooltip title="Sem responsável">
              <IconButton
                onClick={(): void =>
                  dispatch(
                    changeFilters({
                      page,
                      filterList: {
                        isOrphans: [`Sem responsável`],
                        sector: sector.map(sec => sec.name),
                        taskType: [`Abertas`],
                      },
                      searchText,
                    }),
                  )
                }
              >
                <Badge badgeContent={orphans} color="secondary" style={{ marginRight: 10 }}>
                  <RecordVoiceOverOutlinedIcon />
                </Badge>
              </IconButton>
            </Tooltip>
            <Tooltip title="Adicionar Ouvidoria">
              <Button
                aria-label="Adicionar"
                aria-owns="addMenu"
                variant="contained"
                color="primary"
                onClick={(): void => dispatch(openDialog({ dialog }))}
                startIcon={<AddIcon />}
              >
                Adicionar
              </Button>
            </Tooltip>
          </>
        );
      },
      textLabels: {
        body: {
          noMatch: `Desculpe, nenhum registro encontrado!`,
          toolTip: `Ordenar`,
        },
        pagination: {
          next: `Próxima página`,
          previous: `Págima anterior`,
          rowsPerPage: `Linhas por página:`,
          displayRows: `de`,
        },
        toolbar: {
          search: `Procurar`,
          downloadCsv: `Baixar relatório`,
          print: `Imprimir`,
          viewColumns: `Colunas visíveis`,
          filterTable: `Filtrar a tabela`,
        },
        filter: {
          all: `Todos`,
          title: `Filtros`,
          reset: `Resetar`,
        },
        viewColumns: {
          title: `Mostrar colunas`,
          titleAria: `Mostrar/Ocultar Colunas da Tabela`,
        },
        selectedRows: {
          text: `linha(s) selecionada(s)`,
          delete: `Deletar`,
          deleteAria: `Deletear linha(s) selecionada(s)`,
        },
      },
      onRowClick(rowData): void {
        history.push(`/tasks/${taskType}/${rowData[columns.findIndex(column => column.name === `id`)]}`);
      },
      onTableChange(action, tableState): void {
        const filters = tableState.filterList.reduce(
          (acc, v, i) => (v.length ? Object.assign(acc, { [columns[i].name]: v }) : acc),
          {},
        );
        switch (action) {
          case `sort`:
            dispatch(
              changeSort({
                activeColumn: tableState.activeColumn,
                dialog,
                sort: tableState.announceText && tableState.announceText.split(` : `)[1],
              }),
            );
            break;
          case `resetFilters`:
          case `filterChange`:
            dispatch(
              changeFilters({ dialog, filterList: filters, page: tableState.page, searchText: tableState.searchText }),
            );
            break;
          case `search`:
            dispatch(
              changeFilters({ dialog, filterList: filters, page: tableState.page, searchText: tableState.searchText }),
            );
            break;
          case `changeRowsPerPage`:
          case `changePage`:
            dispatch(changePages({ dialog, page: tableState.page, rowsPerPage: tableState.rowsPerPage }));
            break;
          default:
        }
      },
      onDownload(): string | boolean {
        dispatch(loadReportRequest(dialog));
        let url = `/tasks/${taskType}/getreport/?${buildQueryStringFromObject(filterList, searchText)}`;
        if (sort && Object.entries(sort).length !== 0) {
          url += `&sort=${Object.keys(sort)[0]},${sort[Object.keys(sort)[0]].order}`;
        }
        api
          .get(url)
          .then(() => {
            dispatch(loadReportSuccess(dialog));
            dialog.alert({
              title: `Baixar relatório`,
              message: `O relatório será processado e você receberá uma notificação assim que estiver pronto`,
              ok: { text: `Ok`, variant: `contained`, color: `primary` },
            });
          })
          .catch(error => {
            // eslint-disable-next-line
          console.error(error);
          });
        return false;
      },
    }),
    [
      changeFilters,
      changePages,
      changeSort,
      columns,
      count,
      dialog,
      dispatch,
      filterList,
      history,
      loadReportRequest,
      loadReportSuccess,
      openDialog,
      orphans,
      page,
      rowsPerPage,
      searchText,
      sector,
      sort,
      taskType,
    ],
  );
};

const useBuildColumns = (data: {
  classes: { [key: string]: string };
  institutions: Institution[];
  modelName: string;
  props: OmbudsmansState;
  sectors: Sector[];
}): MUIDataTableColumn[] | undefined => {
  const { classes, institutions, modelName, props, sectors } = data;
  const { filterList } = props;
  return useMemo((): MUIDataTableColumn[] | undefined => {
    let columns;

    switch (modelName) {
      case `ombudsman`:
        columns = [
          {
            label: `Status`,
            name: `taskType`,
            options: {
              filter: true,
              customFilterListRender(v: string): string {
                return `Status: ${v}`;
              },
              filterList: filterList.taskType,
              filterType: FilterTypes.DROPDOWN,
              filterOptions: {
                names: [`Fechadas`, `Abertas`],
              },
              customBodyRender(value: { isActive: string; taskType: string }): ReactNode {
                if (value.isActive === `Fechada`) {
                  return (
                    <Tooltip title={value.taskType}>
                      <CheckCircleIcon style={{ color: `rgb(29, 189, 29)` }} />
                    </Tooltip>
                  );
                }
                return (
                  <Tooltip title={value.taskType}>
                    <RecordVoiceOverOutlinedIcon style={{ color: `rgb(128, 0, 128)` }} />
                  </Tooltip>
                );
              },
            },
          },
          {
            label: `Título`,
            name: `taskTitle`,
            options: {
              customBodyRender(value: { id: number; title: string }): ReactNode {
                return (
                  <>
                    <Typography>{value.title}</Typography>
                    <Typography color="textSecondary">
                      <small>{`#${value.id}`}</small>
                    </Typography>
                  </>
                );
              },
              filter: true,
              customFilterListRender(v: string): string {
                return `Título: ${v}`;
              },
              filterList: filterList.taskTitle,
            },
          },
          muiDatatableHelper.buildInstitutionColumn(institutions, (filterList.institution as unknown) as number[], {
            label: `Instituições`,
          }) as MUIDataTableColumn,
          {
            label: `Datas`,
            name: `dates`,
            options: {
              filter: true,
              filterList: filterList.dates,
              filterType: FilterTypes.CUSTOM,
              customBodyRender(value: string[]): ReactNode {
                const start = value[0] ? new Date(value[0]).toLocaleDateString(`pt-br`) : null;
                const estimated = value[1] ? new Date(value[1]).toLocaleDateString(`pt-br`) : null;
                if (start && estimated) {
                  return <span>{`${start} - ${estimated}`}</span>;
                }
                return ``;
              },
              customFilterListRender(v: string): string {
                if (v.length) {
                  return `Datas: ${new Date(v[0]).toLocaleDateString(`pt-br`)} - ${new Date(v[1]).toLocaleDateString(
                    `pt-br`,
                  )}`;
                }
                return ``;
              },
              filterOptions: {
                names: [],
                display(
                  insideFilterList: string[][],
                  onChange: Function,
                  index: number,
                  column: MUIDataTableColumn,
                ): ReactNode {
                  return (
                    <RangePicker
                      startDate={insideFilterList[4].length ? new Date(insideFilterList[4][0]) : undefined}
                      endDate={insideFilterList[4].length ? new Date(insideFilterList[4][1]) : undefined}
                      label="Data inicial"
                      handleSelect={(event): void => {
                        if (event.eventType === 3) {
                          onChange([event.start.toISOString(), event.end.toISOString()], index, column);
                        }
                      }}
                    />
                  );
                },
              },
            },
          },
          {
            label: `Aberto por`,
            name: `taskStarted`,
            options: {
              filter: true,
              customFilterListRender(v: string): string {
                return `Aberto por: ${v}`;
              },
              filterList: filterList.taskStarted,
            },
          },
          {
            label: `Responsável`,
            name: `taskResponsible`,
            options: {
              filter: true,
              customFilterListRender(v: string): string {
                return `Responsável: ${v}`;
              },
              filterList: filterList.taskResponsible,
            },
          },
          {
            label: `Prioridade`,
            name: `priority`,
            options: {
              filter: true,
              customFilterListRender(v: string): string {
                return `Prioridade: ${v}`;
              },
              filterOptions: {
                names: [`Baixa`, `Normal`, `Alta`],
              },
              filterList: filterList.priority,
              filterType: FilterTypes.DROPDOWN,
              customBodyRender(value: string): ReactNode {
                return (
                  <div style={{ textAlign: `center` }}>
                    {value === `HG` && (
                      <Chip
                        label="Alta"
                        style={{ color: `#fff`, backgroundColor: `#dc3545` }}
                        className={classes.markerRoot}
                        classes={{ label: classes.markerChildren }}
                      />
                    )}
                    {value === `NM` && (
                      <Chip
                        label="Normal"
                        style={{ color: `#fff`, backgroundColor: `#6c757d` }}
                        className={classes.markerRoot}
                        classes={{ label: classes.markerChildren }}
                      />
                    )}
                    {value === `LW` && (
                      <Chip
                        label="Baixa"
                        style={{ color: `#fff`, backgroundColor: `#007bff` }}
                        className={classes.markerRoot}
                        classes={{ label: classes.markerChildren }}
                      />
                    )}
                  </div>
                );
              },
            },
          },
          {
            label: `Id`,
            name: `id`,
            options: {
              filter: false,
              display: `excluded`,
            },
          },
          {
            label: `Setor responsável`,
            name: `responsibleSector`,
            options: {
              filter: true,
              filterType: FilterTypes.MULTISELECT,
              customFilterListRender(v: string): string {
                return `Setor responsável: ${v}`;
              },
              filterList: filterList.responsibleSector,
              filterOptions: {
                names: sectors.map(sec => sec.name),
              },
              display: `excluded`,
            },
          },
          {
            label: `Seguidores`,
            name: `users`,
            options: {
              filter: true,
              customFilterListRender(v: string): string {
                return `Seguidores: ${v}`;
              },
              filterList: filterList.users,
              display: `excluded`,
            },
          },
          {
            label: `Excluída`,
            name: `isExcluded`,
            options: {
              filter: true,
              display: `excluded`,
              // customFilterListRender: v => `${v == }`,
              filterType: FilterTypes.DROPDOWN,
              filterList: filterList.isExcluded,
              filterOptions: {
                names: [`Excluídas`, `Ativas`],
              },
            },
          },
          {
            label: `Não atribuídas`,
            name: `isOrphans`,
            options: {
              filter: true,
              display: `excluded`,
              filterList: filterList.isOrphans,
              filterOptions: {
                names: [`Sem responsável`, `Com responsável`],
              },
              filterType: FilterTypes.DROPDOWN,
            },
          },
          {
            label: `TPR/TR`,
            name: `tprAndTr`,
            options: {
              filter: false,
              customBodyRender(tprAndTr: {
                tpr: {
                  sla: number;
                  value: number;
                };
                tr: {
                  sla: number;
                  value: number;
                };
              }): ReactNode {
                return <SLATooltip tprAndTr={tprAndTr} />;
              },
            },
          },
        ];
        // @ts-ignore
        return columns;
      default:
        return undefined;
    }
  }, [
    classes.markerChildren,
    classes.markerRoot,
    filterList.dates,
    filterList.institution,
    filterList.isExcluded,
    filterList.isOrphans,
    filterList.priority,
    filterList.responsibleSector,
    filterList.taskResponsible,
    filterList.taskStarted,
    filterList.taskTitle,
    filterList.taskType,
    filterList.users,
    institutions,
    modelName,
    sectors,
  ]);
};

export { useBuildColumns, useBuildOptions };
