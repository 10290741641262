import { action } from 'typesafe-actions';
import { SuppliersTypes, Supplier, SupplierError } from './Types';

export const loadRequest = (token: string) => action(SuppliersTypes.LOAD_REQUEST, { token });
export const loadSuccess = (data: Supplier[]) => action(SuppliersTypes.LOAD_SUCCESS, { data });
export const loadFailure = () => action(SuppliersTypes.LOAD_FAILURE);
export const addRequest = (data = {}) => action(SuppliersTypes.ADD_REQUEST, data);
export const addSuccess = (data: Supplier) => action(SuppliersTypes.ADD_SUCCESS, { data });
export const addFailure = (errors: SupplierError) => action(SuppliersTypes.ADD_FAILURE, { errors });
export const updateRequest = (data: { id: number; data: {} }) =>
  action(SuppliersTypes.UPDATE_REQUEST, data);
export const updateSuccess = (data: Supplier) => action(SuppliersTypes.UPDATE_SUCCESS, { data });
export const updateFailure = (errors: SupplierError) =>
  action(SuppliersTypes.UPDATE_FAILURE, { errors });
export const openSuppliersRequest = (data: { id: number }) =>
  action(SuppliersTypes.OPEN_REQUEST, data);
export const openSuppliersSuccess = (data: any[]) => action(SuppliersTypes.OPEN_SUCCESS, data);
export const closeSuppliersRequest = () => action(SuppliersTypes.CLOSE_REQUEST);
export const closeSuppliersSuccess = () => action(SuppliersTypes.CLOSE_SUCCESS);
export const changeSuppliersRangeRequest = () => action(SuppliersTypes.RANGE_REQUEST);
export const changeSuppliersRangeSuccess = () => action(SuppliersTypes.RANGE_SUCCESS);
