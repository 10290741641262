import { call, delay, put, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { Action } from 'redux';
import { Dialog } from 'muibox';
import { ApplicationState } from '../index';
import { UsersState, UsersTypes } from './Types';
import { TableSortOrder } from '../types';
import api from '../../../Services/api';
import { filterListToQueryObject } from '../../../Utils';
/**
 * Inventory actions
 */
import { userMobileInfiniteScrollSuccess, loadUsersSuccess } from './Actions';

interface LoadAction extends Action {
  payload: {
    dialog: Dialog;
    mobile?: boolean;
  };
}

interface OpenDialogAction extends Action {
  payload: {
    dialog: Dialog;
    id?: number | string;
  };
}
export function getSortValue(
  sort: { [key: string]: { order: TableSortOrder } } | undefined, // eslint-disable-line
): string | undefined {
  if (sort && Object.keys(sort).length) {
    const sortColumn = Object.entries(sort);
    const orderMap = {
      ascending: ``,
      descending: `-`,
    };
    return `${orderMap[sortColumn[0][1].order]}${sortColumn[0][0]}`;
  }
  return undefined;
}

export function* loadUsers(action: LoadAction): SagaIterator {
  try {
    yield delay(500);
    const usersState: UsersState = yield select((state: ApplicationState) => state.Users);
    const { filterList, page, rowsPerPage, searchText, sort } = usersState;
    const response = yield call(api.get, `/account/users/`, {
      params: {
        ...filterListToQueryObject(filterList),
        globalSearch: searchText,
        length: rowsPerPage,
        page: action.type === UsersTypes.CHANGE_FILTERS ? 1 : page + 1,
        sort: getSortValue(sort),
      },
    });
    if (action.payload.mobile) {
      yield put(userMobileInfiniteScrollSuccess(response.data));
    } else {
      yield put(loadUsersSuccess(response.data));
    }
  } catch (error) {
    const { dialog } = action.payload;
    dialog.alert(`Não foi possível connectar com o servidor!`);
    yield put({ type: UsersTypes.LOAD_ERRORS });
  }
}
