import { action, ActionType } from 'typesafe-actions';
import { Dialog } from 'muibox';
import { ExternalDocsCategoriesTypes, ExternalDocsCategoriesError, ExternalDocsCategory } from './Types';

export const loadRequest = (dialog: Dialog): ActionType<typeof action> =>
  action(ExternalDocsCategoriesTypes.LOAD_REQUEST, { dialog });
export const loadSuccess = (data: ExternalDocsCategory[]): ActionType<typeof action> =>
  action(ExternalDocsCategoriesTypes.LOAD_SUCCESS, { data });
export const loadFailure = (): ActionType<typeof action> => action(ExternalDocsCategoriesTypes.LOAD_FAILURE);
export const openExternalDocsCategoryRequest = (data: {
  dialog: Dialog;
  id?: number | string;
}): ActionType<typeof action> => action(ExternalDocsCategoriesTypes.OPEN_REQUEST, data);
export const openExternalDocsCategorySuccess = (data?: ExternalDocsCategory[]): ActionType<typeof action> =>
  action(ExternalDocsCategoriesTypes.OPEN_SUCCESS, data);
export const closeExternalDocsCategoryRequest = (): ActionType<typeof action> =>
  action(ExternalDocsCategoriesTypes.CLOSE_REQUEST);
export const closeExternalDocsCategorySuccess = (): ActionType<typeof action> =>
  action(ExternalDocsCategoriesTypes.CLOSE_SUCCESS);
export const addOrUpdateRequest = (data: {
  dialog: Dialog;
  postData: { description?: string; id: number | string; name: string };
}): ActionType<typeof action> => action(ExternalDocsCategoriesTypes.ADD_OR_UPDATE_REQUEST, data);
export const addOrUpdateFailure = (errors: ExternalDocsCategoriesError): ActionType<typeof action> =>
  action(ExternalDocsCategoriesTypes.ADD_OR_UPDATE_FAILURE, { errors });
export const addSuccess = (data: ExternalDocsCategory): ActionType<typeof action> =>
  action(ExternalDocsCategoriesTypes.ADD_SUCCESS, { data });
export const updateSuccess = (data: ExternalDocsCategory): ActionType<typeof action> =>
  action(ExternalDocsCategoriesTypes.UPDATE_SUCCESS, { data });
