import axios, { AxiosError, AxiosResponse } from 'axios';
import { setupCache } from 'axios-cache-adapter';
import firebase from '../Utils/firebaseConfig';
import { store } from '../ReduxFlow/ConfigureStore';
import * as Actions from '../ReduxFlow/Reducers/Auth/Actions';

export const cache = setupCache({
  exclude: {
    paths: [/auth/, /externaldocs/, /internaldocs/, /tasks/, /token/, /crm/, /inventory/],
  },
  maxAge: 4 * 60 * 1000,
});

const api = axios.create({
  adapter: cache.adapter,
  baseURL: process.env.REACT_APP_MAIN_URL,
});

const refreshFirebaseToken = async (): Promise<string> => {
  const user = firebase.auth().currentUser;
  if (!user) throw new Error(`Sem usuário Firebase`);
  const idToken = await user.getIdToken(true);
  store.dispatch(Actions.renewToken(idToken));
  return idToken;
};

const isAuthError = (error: AxiosError): boolean =>
  !!error.response &&
  (error.response.status === 401 ||
    error.response.status === 403 ||
    (error.response.data && error.response.data.code && error.response.data.code === `no_tenant`));

const tokenRefreshHandler = async (error: AxiosError): Promise<AxiosResponse> => {
  if (!isAuthError(error)) {
    return Promise.reject(error);
  }

  console.log(`refreshing token`);
  const token = refreshFirebaseToken();
  const newError = error;
  newError.config.headers.Authorization = `Bearer ${token}`;
  return api(newError.config); // retry previous request
};

api.interceptors.request.use(async config => {
  const { data, firebaseToken } = store.getState().Auth;
  const { currentOrganization } = data;
  const newConfig = config;
  newConfig.headers[`X-SIM-HOLDING-ID`] = currentOrganization.id;
  if (firebaseToken) newConfig.headers.Authorization = `Bearer ${firebaseToken}`;
  return newConfig;
});

api.interceptors.response.use(response => response, tokenRefreshHandler);

axios.defaults.headers.post[`Content-Type`] = `application/json;charset=utf-8`;
axios.defaults.headers.post[`Access-Control-Allow-Origin`] = `*`;
axios.defaults.headers.post[`Access-Control-Allow-Methods`] = `GET, POST, PUT, DELETE, PATCH, OPTIONS`;
axios.defaults.headers.post[`Access-Control-Allow-Headers`] = `X-Requested-With, content-type, Authorization`;

export default api;
