import { SimpleCategory, SimpleCategoryError, SimpleCategoryState } from '../types';
/**
 * Action types
 */
export enum InternalDocsCategoriesTypes {
  LOAD_REQUEST = '@InternalDocsCategories/LOAD_REQUEST', // eslint-disable-line
  LOAD_SUCCESS = '@InternalDocsCategories/LOAD_SUCCESS', // eslint-disable-line
  LOAD_FAILURE = '@InternalDocsCategories/LOAD_FAILURE', // eslint-disable-line
  OPEN_REQUEST = '@InternalDocsCategories/OPEN_REQUEST',   // eslint-disable-line
  OPEN_SUCCESS = '@InternalDocsCategories/OPEN_SUCCESS',   // eslint-disable-line
  CLOSE_REQUEST = '@InternalDocsCategories/CLOSE_REQUEST',   // eslint-disable-line
  CLOSE_SUCCESS = '@InternalDocsCategories/CLOSE_SUCCESS',   // eslint-disable-line
  LOAD_ERRORS = '@InternalDocsCategories/LOAD_ERRORS',   // eslint-disable-line
  ADD_OR_UPDATE_REQUEST = '@InternalDocsCategories/ADD_OR_UPDATE_REQUEST', // eslint-disable-line
  ADD_OR_UPDATE_FAILURE = '@InternalDocsCategories/ADD_OR_UPDATE_FAILURE', // eslint-disable-line
  ADD_SUCCESS = '@InternalDocsCategories/ADD_SUCCESS', // eslint-disable-line
  UPDATE_SUCCESS = '@InternalDocsCategories/UPDATE_SUCCESS', // eslint-disable-line
}
/**
 * Data types
 */

export type InternalDocsCategory = SimpleCategory;

export type InternalDocsCategoriesError = SimpleCategoryError;

/**
 * State types
 */
export type InternalDocsCategoriesState = SimpleCategoryState;
