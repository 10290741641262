import React from 'react';
/**
 * Redux dependencies
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
/**
 * Material UI - Core
 */
import { Checkbox, FormControlLabel, Grid, TextField, Typography, withStyles } from '@material-ui/core';

import * as SectorActions from '../../../ReduxFlow/Reducers/Sectors/Actions';
import WorkSchedulle, { validate as validateSchedulle } from '../../../Components/WorkSchedulle';

const styles = theme => ({
  container: {
    display: `flex`,
    flexWrap: `wrap`,
  },
  dense: {
    marginTop: 19,
  },
  marginNormal: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  rightIcon: {
    marginLeft: 5,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: `98%`,
  },
});

class SectorForm extends React.Component {
  state = {
    name: ``,
    workSchedulle: {},
    workSchedulleEnabled: false,
    workSchedulleValidation: null,
  };

  componentDidMount() {
    const { formInstance } = this.props;
    if (formInstance.length) {
      const { name, workSchedulle, workSchedulleEnabled } = formInstance[0];
      this.setState({ name, workSchedulle, workSchedulleEnabled });
    }
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleChangeSchedulle = value => {
    const validation = validateSchedulle(value);
    this.setState({ workSchedulleValidation: validation });
    if (validation.valid) this.setState({ workSchedulle: value });
  };

  handleCheckboxChange = name => event => {
    event.persist();
    this.setState({
      [name]: event.target.checked,
    });
  };

  handleSubmit = (id = null) => event => {
    event.preventDefault();
    const { addRequest, updateRequest } = this.props;
    const { name, workSchedulle, workSchedulleEnabled } = this.state;

    const workSchedulleValidation = validateSchedulle(workSchedulle); // can't reuse state because user may no have touched schedulle
    if (workSchedulleEnabled && !(workSchedulleValidation && workSchedulleValidation.valid)) return;

    const data = {
      name,
      workSchedulleEnabled,
      workSchedulle: workSchedulleEnabled ? workSchedulle : undefined,
    };

    if (id) {
      updateRequest({ id, data });
    } else {
      addRequest(data);
    }
  };

  render() {
    const { classes, formErrors, formInstance, loggedUserInfo } = this.props;
    const { name, workSchedulle, workSchedulleEnabled, workSchedulleValidation } = this.state;
    const { isSuperAdmin } = loggedUserInfo.user;

    return (
      <form
        id="sectorForm"
        className={classes.container}
        noValidate
        autoComplete="off"
        onSubmit={formInstance.length ? this.handleSubmit(formInstance[0].id) : this.handleSubmit()}
      >
        <Grid container spacing={0} style={{ width: `100%` }}>
          <Grid item xs={12}>
            <TextField
              className={classes.textField}
              disabled={!isSuperAdmin}
              error={`name` in formErrors}
              helperText={`name` in formErrors && `Esse campo é obrigatório!`}
              id="id_name"
              label="Nome"
              margin="normal"
              name="name"
              onChange={this.handleChange(`name`)}
              required
              value={name}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={workSchedulleEnabled}
                  onChange={this.handleCheckboxChange(`workSchedulleEnabled`)}
                  name="workSchedulleEnabled"
                  color="primary"
                />
              }
              label="Ativar horário de trabalho"
              className={classes.marginNormal}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" paragraph>
              Com o horário de trabalho configurado você poderá optar, ao criar ou editar Categorias* de to-dos, que
              seus seus SLAs considerem somente o período compreendido pelo horário de trabalho do setor na contagem das
              horas
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" paragraph>
              <strong>* Esta configuração só funciona em categorias com somente 1 setor configurado</strong>
            </Typography>
          </Grid>
          {workSchedulleEnabled && (
            <Grid item xs={12}>
              <WorkSchedulle
                value={workSchedulle}
                onChange={this.handleChangeSchedulle}
                validation={workSchedulleValidation}
              />
            </Grid>
          )}
        </Grid>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  loggedUserInfo: state.Auth.data,
});

const mapDispatchToProps = dispatch => bindActionCreators(SectorActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(SectorForm));
