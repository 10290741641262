import React, { ChangeEvent, forwardRef, MouseEvent, ReactNode, useState } from 'react';
import { Button, Grid, TextField, TextFieldProps } from '@material-ui/core';
import { Check as CheckIcon, Close as CloseIcon } from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { MarginType } from '../../../Utils/globalTypes';

export enum WrapperVariants {
  standard = "standard", //eslint-disable-line
  outlined = "outlined", //eslint-disable-line
  filled = "filled", //eslint-disable-line
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionButtons: {
      minHeight: theme.spacing(2.5),
      minWidth: theme.spacing(3.5),
      padding: theme.spacing(0.5),
    },
    adornedEnd: {
      cursor: `pointer`,
      paddingRight: theme.spacing(1.25),
    },
    adornedEndActions: {
      position: `absolute`,
      right: theme.spacing(1.5),
      top: `calc(100% - ${theme.spacing(5)}px)`,
      '& > button:first-child': {
        marginRight: theme.spacing(0.625),
      },
    },
    adornedEndWithActions: {
      cursor: `pointer`,
      paddingRight: 0,
    },
    disabled: {
      border: `none !important`,
      cursor: `not-allowed !important`,
      pointerEvents: `none`,
    },
    disabledWithInstance: {
      backgroundColor: `#f5f5f5`,
    },
    disabledNoInstance: {
      opacity: 0.4,
    },
    eventIcon: {
      color: `#767676`,
    },
    inputRoot: {
      cursor: `pointer`,
      padding: `${theme.spacing(4.3125)}px ${theme.spacing(1.75)}px ${theme.spacing(1.25)}px ${theme.spacing(1.75)}px`,
    },
    inputRootWithActions: {
      cursor: `pointer`,
      padding: `${theme.spacing(4.3125)}px ${theme.spacing(1.75)}px ${theme.spacing(7.25)}px ${theme.spacing(1.75)}px`,
    },
    inputRootTextArea: {
      padding: `${theme.spacing(2.5625)}px ${theme.spacing(1.25)}px 0px 0px`,
    },
    inputRootTextAreaWithActions: {
      padding: `${theme.spacing(2.5625)}px ${theme.spacing(1.25)}px ${theme.spacing(7.25)}px 0px`,
    },
    inputRootWithAdornment: {
      cursor: `pointer`,
      padding: `${theme.spacing(4.3125)}px ${theme.spacing(1.75)}px ${theme.spacing(1.25)}px 0`,
    },
    labelOutlinedEditing: {
      fontSize: theme.spacing(1.875),
      fontWeight: 600,
      transform: `translate(${theme.spacing(1.75)}px, 0) scale(0.75) !important`,
    },
    labelOutlinedNotEditing: {
      fontSize: theme.spacing(2.334625),
      fontWeight: 500,
      transform: `translate(${theme.spacing(1.75)}px, 0) scale(0.75) !important`,
    },
    labelRoot: {
      paddingTop: theme.spacing(2.334625),
    },
    labelRootTextArea: {
      paddingTop: theme.spacing(2.084625),
    },
    notEditing: {
      background: `#fafafa 0% 0% no-repeat padding-box`,
      '& > fieldset': {
        border: `none`,
      },
      '& > svg': {
        color: `transparent !important`,
      },
    },
    required: {
      color: theme.palette.secondary.main,
      marginRight: theme.spacing(0.5),
    },
    root: {
      '& > fieldset > legend': {
        maxWidth: 0,
      },
      '& .MuiFormLabel-root.Mui-disabled': {
        color: `rgba(0, 0, 0, 0.54)`,
      },
    },
  }),
);

export type CustomTextFieldProps = TextFieldProps & {
  cancelSaveAction?: () => void;
  hasInstance?: boolean;
  isEditing?: boolean;
  saveAction?: (value: string) => void;
};

const CustomTextField = forwardRef<HTMLDivElement, CustomTextFieldProps>(function CustomTextFieldWithRef(props, ref) {
  const classes = useStyles();
  const [value, setValue] = useState<string>(props.value as string);
  const {
    autoFocus,
    cancelSaveAction,
    children,
    disabled,
    error,
    fullWidth,
    hasInstance,
    helperText,
    isEditing,
    label,
    margin,
    name,
    placeholder,
    InputProps,
    InputLabelProps,
    onChange,
    required,
    saveAction,
    select,
    ...otherProps
  } = props;

  const hasActions = !!cancelSaveAction && !!saveAction;

  function getInputClass(): string {
    if (props.multiline && hasActions && isEditing) return classes.inputRootTextAreaWithActions;
    if (props.multiline) return classes.inputRootTextArea;
    if (hasActions && isEditing) return classes.inputRootWithActions;
    if (InputProps && InputProps.startAdornment) return classes.inputRootWithAdornment;
    return classes.inputRoot;
  }

  function getTextFieldLabel(): ReactNode {
    if (hasInstance && !isEditing) return label;
    return (
      <>
        {required ? (
          <>
            <span className={classes.required}>*</span>
            {label}
          </>
        ) : (
          `${label} (opcional)`
        )}
      </>
    );
  }

  return (
    <TextField
      // eslint-disable-next-line
      {...{ ...otherProps, required: false }}
      autoFocus={autoFocus}
      classes={{
        root: classes.root,
        ...otherProps.classes,
      }}
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      helperText={helperText}
      label={getTextFieldLabel()}
      margin={margin ? MarginType[margin] : undefined}
      name={name}
      onChange={
        !hasActions
          ? onChange
          : (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
              setValue(event.target.value);
            }
      }
      placeholder={placeholder}
      InputProps={{
        ...InputProps,
        classes: {
          adornedEnd: hasActions ? classes.adornedEndWithActions : classes.adornedEnd,
          disabled: hasInstance
            ? `${classes.disabled} ${classes.disabledWithInstance}`
            : `${classes.disabled} ${classes.disabledNoInstance}`,
          input: getInputClass(),
          root: hasInstance && !isEditing ? `${classes.root} ${classes.notEditing}` : classes.root,
        },
        endAdornment:
          cancelSaveAction && saveAction && isEditing ? (
            <Grid className={classes.adornedEndActions} container justify="flex-end">
              <Button
                className={classes.actionButtons}
                color="default"
                onClick={(event: MouseEvent<HTMLButtonElement>): void => {
                  event.stopPropagation();
                  setValue(props.value as string);
                  cancelSaveAction();
                }}
                size="small"
                variant="contained"
              >
                <CloseIcon fontSize="small" />
              </Button>
              <Button
                className={classes.actionButtons}
                color="primary"
                onClick={(event: MouseEvent<HTMLButtonElement>): void => {
                  event.stopPropagation();
                  saveAction(value);
                }}
                size="small"
                variant="contained"
              >
                <CheckIcon fontSize="small" />
              </Button>
            </Grid>
          ) : (
            InputProps && InputProps.endAdornment
          ),
        required,
      }}
      InputLabelProps={{
        ...InputLabelProps,
        classes: {
          outlined: hasInstance && isEditing ? classes.labelOutlinedEditing : classes.labelOutlinedNotEditing,
          root: otherProps.multiline ? classes.labelRootTextArea : classes.labelRoot,
        },
        shrink: true,
      }}
      ref={ref}
      SelectProps={{
        ...props.SelectProps,
        disabled,
      }}
      select={select}
      value={hasInstance && cancelSaveAction && saveAction ? value : props.value}
      variant={WrapperVariants.outlined}
    >
      {children}
    </TextField>
  );
});

export default CustomTextField;
