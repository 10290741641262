import React, {
  CSSProperties,
  FC,
  FocusEventHandler,
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import AsyncSelect from 'react-select/async';
import { OptionProps, Props, ValueType } from 'react-select';
import { NoticeProps } from 'react-select/src/components/Menu';
import { Tooltip, Typography } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import { makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import { AxiosResponse } from 'axios';
import { MarginType } from 'Utils/globalTypes';
import api from 'Services/api';
import { PaginationWithData } from 'ReduxFlow/Reducers/types';
import CustomTextField from '../CustomTextField';

export const stylesForCrmAndUserBuilder = makeStyles((theme: Theme) => ({
  listItemRootAdd: {
    marginLeft: -theme.spacing(0.5),
    marginRight: theme.spacing(1.125),
    marginTop: theme.spacing(0.125),
    maxHeight: theme.spacing(1.625),
    maxWidth: theme.spacing(3),
    minWidth: theme.spacing(3),
  },
  listItemRootOther: {
    marginRight: theme.spacing(1.125),
    marginTop: theme.spacing(0.625),
    minWidth: theme.spacing(2.5),
  },
  listItemRootPerson: {
    marginRight: theme.spacing(1.125),
    marginTop: theme.spacing(1),
    minWidth: theme.spacing(2.5),
  },
  startAdornment: {
    alignItems: `flex-start`,
  },
  startAdornmentIcon: {
    fontSize: 13,
    marginTop: theme.spacing(0.5),
  },
}));

const useStyles = makeStyles((theme: Theme) => ({
  dropDown: {
    zIndex: 5,
    position: `absolute`,
    width: `100%`,
  },
  inputFocused: {
    '& > fieldset': {
      border: `none`,
      borderRadius: theme.spacing(0.75),
      boxShadow: `0px 0px 0px 2px ${theme.palette.primary.main}`,
    },
  },
  labelFocused: {
    color: theme.palette.primary.main,
  },
  root: {
    flexGrow: 1,
    minHeight: 50,
    position: `relative`,
    width: `100%`,
    zIndex: 2,
  },
  tooltip: {
    backgroundColor: `#17181A`,
    fontSize: 14,
  },
}));

type CustomAutoCompleteClasses = ReturnType<typeof useStyles>;

export interface CustomSelectOptionProps<T> extends OptionProps<T> {
  data: T;
}

// eslint-disable-next-line quotes
type SelectComponents<T> = Props<T>['components'];

interface CustomAutocompleteProps<T> {
  autofocus?: boolean;
  createDialog?: ReactNode;
  customAddOption?: T;
  customSearchParams?: { [key: string]: string | undefined };
  defaultOptions?: T[];
  disabled?: boolean;
  error?: boolean;
  getOptionValue: (selectedData: ValueType<T>) => string;
  hasInstance?: boolean;
  helperText?: string;
  inputClassName?: string;
  inputHidden?: boolean;
  inputStyles?: CSSProperties;
  inputValue: (selectedData: ValueType<T>) => string;
  institutionIds?: number[];
  isEditing?: boolean;
  label: string;
  margin?: MarginType;
  mountTooltipTitle: (selectedData: ValueType<T>) => ReactElement;
  multiple: boolean;
  name: string;
  noOptionsMessage?: string;
  onBlur?: FocusEventHandler;
  onChange: (value: number | number[] | undefined) => void;
  onClose?: () => void;
  onFocus?: FocusEventHandler;
  openUp: boolean;
  Option?: FC<CustomSelectOptionProps<T>>;
  placeholder: string;
  preventSearch?: boolean;
  required?: boolean;
  searchUrl: string;
  startAdornment?: (selectedData: ValueType<T>) => ReactNode;
  value?: number | number[];
}

interface BlanketProps {
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const Blanket = ({ onClick }: BlanketProps): JSX.Element => (
  // eslint requires a "role" attribute, but none are appropriate
  // eslint-disable-next-line
  <div
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: `fixed`,
      zIndex: 1,
    }}
    onClick={onClick}
  />
);

function GenericMessage<T>(label: string): FC<NoticeProps<T>> {
  const Message: FC<NoticeProps<T>> = ({ innerProps }) => (
    <div style={{ display: `flex` }}>
      <Typography
        color="textSecondary"
        style={{ marginRight: `auto`, marginLeft: `auto` }}
        variant="caption"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...innerProps}
      >
        {label}
      </Typography>
    </div>
  );
  return Message;
}

interface DropdownProps<T> {
  children: ReactElement;
  className: string;
  classes: CustomAutoCompleteClasses;
  id: string;
  isOpen: boolean;
  onBlur?: FocusEventHandler;
  onClose: (event: React.MouseEvent<HTMLDivElement>) => void;
  onFocus?: FocusEventHandler;
  target: JSX.Element;
}

function Dropdown<T>(props: DropdownProps<T>): ReactElement {
  const { children, className, classes, id, isOpen, onBlur, onClose, onFocus, target } = props;
  return (
    <div className={className} id={id} onBlur={onBlur} onFocus={onFocus} style={{ zIndex: isOpen ? 3 : undefined }}>
      {target}
      {isOpen ? (
        <>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <div className={classes.dropDown}>{children}</div>
          <Blanket onClick={onClose} />
        </>
      ) : null}
    </div>
  );
}

const DropdownIndicator = (): JSX.Element => <SearchIcon style={{ color: `#767676` }} />;

function CustomAutoComplete<T>({
  autofocus = true,
  createDialog,
  customAddOption,
  customSearchParams,
  defaultOptions,
  disabled = false,
  error = false,
  getOptionValue,
  helperText = ``,
  hasInstance,
  inputClassName,
  inputHidden,
  inputStyles,
  inputValue,
  institutionIds,
  isEditing,
  label,
  margin = MarginType.normal,
  mountTooltipTitle,
  multiple = false,
  name,
  noOptionsMessage,
  onBlur,
  onChange,
  onClose,
  onFocus,
  openUp,
  Option,
  placeholder,
  preventSearch,
  required = false,
  searchUrl,
  startAdornment,
  value: startingValue,
}: CustomAutocompleteProps<T>): ReactElement {
  const theme = useTheme();
  const classes = useStyles();
  const dropDownRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState<ValueType<T>>();
  const debounceApi = useRef(0);
  const valueAsArray = ((value && (Array.isArray(value) ? value : [value])) || []) as ValueType<T>;
  const hasStartingValue =
    (Array.isArray(startingValue) && startingValue.length > 0) || (!Array.isArray(startingValue) && startingValue);
  const components: SelectComponents<T> = {
    DropdownIndicator,
    IndicatorSeparator: null,
    LoadingMessage: GenericMessage<T>(`Pesquisando...`),
    NoOptionsMessage: noOptionsMessage ? GenericMessage<T>(noOptionsMessage) : GenericMessage(`Sem opções`),
    Option,
  };

  const selectStyles = {
    container: (base: object): object => ({
      ...base,
      backgroundColor: `#fff`,
      borderRadius: `6px`,
      bottom: openUp ? theme.spacing(9) : `unset`,
      boxShadow: `0px 1px 4px #00000026`,
      display: `flex`,
      marginTop: theme.spacing(1),
      position: `absolute`,
      width: `100%`,
      flexDirection: openUp ? `column-reverse` : `column`,
    }),
    control: (base: object): object => ({
      ...base,
      backgroundColor: `#f5f5f5`,
      borderColor: `#fff`,
      borderRadius: `6px`,
      boxShadow: `none`,
      margin: theme.spacing(2),
      ':focus-within': {
        backgroundColor: `#fff`,
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: 6,
      },
      ':hover:not(:focus-within)': {
        border: `none`,
      },
    }),
    input: (base: object): object => ({
      ...base,
      border: `none`,
      color: theme.palette.text.primary,
    }),
    menu: (base: object): object => ({
      ...base,
      boxShadow: `none`,
      marginTop: 0,
      position: `unset`,
      zIndex: 3,
    }),
    menuList: (base: object): object => ({
      ...base,
      listStyle: `none`,
      maxHeight: `300px`,
    }),
  };

  const toggleSelector = (): void => {
    setIsOpen(!isOpen);
    if (inputRef.current) inputRef.current.focus();
  };

  const onSelectChange = (selected: ValueType<T>): void => {
    if (!multiple && selected && !Array.isArray(selected) && parseInt(getOptionValue(selected), 10) < 0) {
      setValue(undefined);
      onChange(undefined);
      setIsOpen(false);
      return;
    }
    setValue(selected);
    if (onChange) {
      if (multiple) {
        onChange(Array.isArray(selected) ? selected.map(u => parseInt(getOptionValue(u), 10)) : []);
      } else {
        onChange(selected ? parseInt(getOptionValue(selected), 10) : undefined);
        setIsOpen(false);
      }
    }
    if (inputRef.current) inputRef.current.focus();
  };

  const loadOptions = (search: string): Promise<T[]> => {
    return new Promise(resolve => {
      clearTimeout(debounceApi.current);
      debounceApi.current = window.setTimeout(async () => {
        if (!preventSearch) {
          try {
            const response: AxiosResponse<PaginationWithData<T>> = await api.get(searchUrl, {
              params: {
                ...customSearchParams,
                globalSearch: search,
                institutionIds: institutionIds ? institutionIds.join(`...`) : undefined,
                length: 10,
                page: 1,
              },
            });
            const options = customAddOption ? [...response.data.results, customAddOption] : response.data.results;
            if (multiple) resolve(options);
            else resolve(options);
          } catch (e) {
            // eslint-disable-next-line no-console
            console.error(`error fetching users`, e);
            resolve([]); // Select does not handle rejection
          }
        } else {
          resolve([]);
        }
      }, 1000);
    });
  };

  useEffect(() => {
    const loadStartingValues = (): void => {
      if (!hasStartingValue) return;
      const ids = Array.isArray(startingValue) ? startingValue : [startingValue];
      api
        .get(searchUrl, {
          params: {
            ids: ids.join(`...`),
            page: 1,
            length: ids.length,
          },
        })
        .then(response => {
          if (multiple) setValue(response.data.results);
          else setValue(response.data.results.length ? response.data.results[0] : undefined);
        })
        // eslint-disable-next-line no-console
        .catch(e => console.error(`error fetching users`, e));
    };
    loadStartingValues();
    setIsOpen(false);
  }, [hasStartingValue, multiple, searchUrl, startingValue]);

  function getDefaultOptions(): T[] | undefined {
    if (Array.isArray(defaultOptions) && Array.isArray(value)) {
      return defaultOptions.concat(value);
    }
    if (Array.isArray(defaultOptions) && !Array.isArray(value) && value) {
      return [...defaultOptions, value as T];
    }
    return value as T[] | undefined;
  }

  return (
    <Dropdown<T>
      className={classes.root}
      classes={classes}
      isOpen={isOpen}
      id={`${name}-dropdown`}
      onBlur={onBlur}
      onClose={toggleSelector}
      onFocus={onFocus}
      target={
        <>
          <Tooltip
            classes={{
              tooltip: classes.tooltip,
            }}
            disableFocusListener={(valueAsArray && Array.isArray(valueAsArray) && !valueAsArray.length) || error}
            disableHoverListener={(valueAsArray && Array.isArray(valueAsArray) && !valueAsArray.length) || error}
            disableTouchListener={(valueAsArray && Array.isArray(valueAsArray) && !valueAsArray.length) || error}
            PopperProps={{
              style: {
                boxSizing: `border-box`,
                maxWidth: dropDownRef.current ? dropDownRef.current.offsetWidth : undefined,
              },
            }}
            placement={multiple ? `bottom-end` : `bottom-start`}
            title={mountTooltipTitle(value)}
          >
            <CustomTextField
              className={inputClassName}
              disabled={disabled}
              error={error}
              hasInstance={hasInstance}
              helperText={helperText}
              InputLabelProps={{
                className: isOpen ? classes.labelFocused : undefined,
              }}
              InputProps={{
                className: isOpen ? classes.inputFocused : undefined,
                inputProps: {
                  ref: inputRef,
                  style: {
                    visibility: inputHidden ? `hidden` : undefined,
                  },
                },
                startAdornment: startAdornment ? startAdornment(value) : undefined,
                endAdornment:
                  hasInstance && isEditing ? <SearchIcon style={{ color: `rgba(0, 0, 0, 0.54)` }} /> : undefined,
              }}
              isEditing={isEditing}
              label={label}
              margin={margin}
              onClick={!disabled ? toggleSelector : undefined}
              onKeyPress={!disabled ? toggleSelector : undefined}
              placeholder={placeholder}
              ref={dropDownRef}
              required={required}
              style={inputStyles}
              value={inputValue(value) || ``}
            />
          </Tooltip>
          {createDialog}
        </>
      }
    >
      <AsyncSelect
        autoFocus={autofocus}
        backspaceRemovesValue={false}
        cacheOptions
        classes={classes}
        components={components}
        controlShouldRenderValue={false}
        defaultOptions={getDefaultOptions()}
        isDisabled={disabled}
        getOptionValue={getOptionValue}
        hideSelectedOptions={false}
        isClearable={false}
        isMulti={multiple}
        isSearchable
        loadOptions={loadOptions}
        menuIsOpen
        name={name}
        onBlur={onBlur}
        onChange={onSelectChange}
        onClose={onClose}
        placeholder="Pesquisar..."
        required={required}
        styles={selectStyles}
        value={valueAsArray}
      />
    </Dropdown>
  );
}

export default CustomAutoComplete;
