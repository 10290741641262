import React, { ChangeEvent, FocusEvent, MouseEvent, ReactNode, SFC, useRef, useState } from 'react';
import {
  FormControl,
  InputLabel,
  List,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Paper,
  Popover,
  Radio,
  Select,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { ChevronRight as ChevronRightIcon } from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import { TasksCategory } from '../../../ReduxFlow/Reducers/TasksCategories/Types';
import { MarginType } from '../../../Utils/globalTypes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dense: {
      marginBottom: theme.spacing(0.5),
      marginTop: theme.spacing(1),
    },
    inputBaseRoot: {
      minHeight: theme.spacing(6.75),
    },
    labelOutlinedEditing: {
      fontSize: theme.spacing(1.875),
      fontWeight: 600,
      transform: `translate(${theme.spacing(1.75)}px, 0) scale(0.75) !important`,
    },
    labelOutlinedNotEditing: {
      fontSize: theme.spacing(2.334625),
      fontWeight: 500,
      transform: `translate(${theme.spacing(1.75)}px, 0) scale(0.75) !important`,
    },
    labelRoot: {
      paddingTop: theme.spacing(2.334625),
    },
    list: {
      maxWidth: theme.spacing(40),
      overflowX: `hidden`,
    },
    listItemText: {
      alignContent: `center`,
      alignItems: `center`,
      display: `flex`,
      minHeight: 56,
      overflowX: `hidden`,
      textOverflow: `ellipsis`,
    },
    none: {
      marginBottom: 0,
      marginTop: 0,
    },
    normal: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(2),
    },
    notEditing: {
      background: `#fafafa 0% 0% no-repeat padding-box`,
      '& > div > fieldset': {
        border: `none`,
      },
      '& > div > svg': {
        color: `transparent !important`,
      },
    },
    required: {
      color: theme.palette.secondary.main,
      marginRight: theme.spacing(0.5),
    },
    rootNoInstance: {
      '& > fieldset > legend': {
        maxWidth: 0,
      },
      '& > div': {
        '&.Mui-disabled': {
          border: `none !important`,
          cursor: `not-allowed !important`,
          opacity: 0.4,
          pointerEvents: `none`,
        },
      },
    },
    rootWithInstance: {
      '& > fieldset > legend': {
        maxWidth: 0,
      },
      '& > div': {
        '&.Mui-disabled': {
          backgroundColor: `#f5f5f5`,
          border: `none !important`,
          cursor: `not-allowed !important`,
          pointerEvents: `none`,
        },
      },
    },
    secondaryAction: {
      top: `65%`,
    },
    selectRoot: {
      padding: `${theme.spacing(4.3125)}px ${theme.spacing(1.75)}px ${theme.spacing(1.25)}px ${theme.spacing(1.75)}px`,
    },
    subheaderHovered: {
      background: theme.palette.action.selected,
    },
    subheaderRoot: {
      color: theme.palette.text.primary,
    },
    subheaderSelected: {
      background: `rgba(76, 195, 198, 0.1) 0% 0% no-repeat padding-box !important`,
    },
  }),
);

type TaskCategorySelectProps = {
  categoriesAndSubCategories: TasksCategory[];
  disabled?: boolean;
  formControlClass: string;
  hasInstance?: boolean;
  id: string;
  isEditing?: boolean;
  isLoading: boolean;
  label: string;
  margin: MarginType;
  name: string;
  onBlur?: (event?: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onChange: (event: ChangeEvent<{ name?: string | undefined; value: unknown }>, child: ReactNode) => void;
  onFocus?: (event?: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  required?: boolean;
  value?: number;
};

const TaskCategorySelect: SFC<TaskCategorySelectProps> = ({
  categoriesAndSubCategories,
  disabled,
  formControlClass,
  hasInstance,
  id,
  isEditing,
  isLoading,
  label,
  margin = MarginType.none,
  name,
  onBlur,
  onChange,
  onFocus,
  required,
  value,
}) => {
  const classes = useStyles();
  const [anchors, setAnchors] = useState<{ [key: number]: HTMLLIElement | undefined }>({});
  const selectRef = useRef<HTMLDivElement>(null);
  const refsObject = useRef<HTMLLIElement[]>([]);
  const paperRefs = useRef<HTMLDivElement[]>([]);
  const isDisabled = !categoriesAndSubCategories.length || disabled;
  const activeCategoriesAndSubCategories = categoriesAndSubCategories
    .filter(c => c.isActive)
    .map(c => {
      return { ...c, subCategories: c.subCategories ? c.subCategories.filter(s => s.isActive) : [] };
    });

  function getTextFieldLabel(): ReactNode {
    if (hasInstance && !isEditing) return label;
    return (
      <>
        {required ? (
          <>
            <span className={classes.required}>*</span>
            {label}
          </>
        ) : (
          `${label} (opcional)`
        )}
      </>
    );
  }

  return isLoading ? (
    <Skeleton animation="wave" className={`${formControlClass} ${classes[margin]}`} height={56} variant="rect" />
  ) : (
    <FormControl
      classes={{
        root: hasInstance && !isEditing ? `${classes.rootWithInstance} ${classes.notEditing}` : classes.rootNoInstance,
      }}
      className={formControlClass}
      margin={margin}
      variant={isDisabled ? `filled` : `outlined`}
    >
      <InputLabel
        classes={{
          outlined: hasInstance && isEditing ? classes.labelOutlinedEditing : classes.labelOutlinedNotEditing,
          root: classes.labelRoot,
        }}
        id="categories-selector-label"
        shrink
      >
        {getTextFieldLabel()}
      </InputLabel>
      <Select
        classes={{
          root: hasInstance && !isEditing ? `${classes.selectRoot} ${classes.notEditing}` : classes.selectRoot,
        }}
        disabled={isDisabled}
        id={id}
        input={<OutlinedInput classes={{ root: classes.inputBaseRoot }} />}
        inputProps={{
          readOnly: isDisabled,
          classes: {
            root: hasInstance ? classes.rootWithInstance : classes.rootNoInstance,
          },
        }}
        label={label}
        labelId="categories-selector-label"
        MenuProps={{
          anchorOrigin: { horizontal: `left`, vertical: `bottom` },
          classes: { list: !!selectRef.current && selectRef.current.clientWidth <= 360 ? classes.list : undefined },
          getContentAnchorEl: undefined,
          transformOrigin: { horizontal: `left`, vertical: `top` },
        }}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        onClose={(): void => setAnchors({})}
        ref={selectRef}
        renderValue={(selectedValue: unknown): ReactNode => {
          let selectedSubCategory = `Nenhuma categoria encontrada`;
          if (typeof selectedValue === `number`) {
            activeCategoriesAndSubCategories.forEach(cat => {
              if (cat.subCategories) {
                cat.subCategories.forEach(subCat => {
                  if (subCat.id === selectedValue) {
                    selectedSubCategory = `${cat.name} > ${subCat.name}`;
                  }
                });
              }
            });
          }
          return selectedSubCategory;
        }}
        value={value || ``}
        variant={isDisabled ? `filled` : `outlined`}
      >
        {activeCategoriesAndSubCategories.map(cat => {
          const { subCategories } = cat;
          const selectedObject =
            !!subCategories && !!subCategories.length && subCategories.find(subCat => subCat.id === value);
          const itemsArray = [
            !!subCategories && !!subCategories.length ? (
              <ListSubheader
                classes={{
                  root: window.innerWidth > 600 ? classes.subheaderRoot : undefined,
                }}
                className={`${selectedObject ? classes.subheaderSelected : undefined} ${
                  anchors[cat.id] && !selectedObject ? classes.subheaderHovered : undefined
                }`}
                key={`cat-${cat.id}`}
                onMouseEnter={
                  window.innerWidth > 600
                    ? (event: MouseEvent<HTMLLIElement>): void => {
                        event.persist();
                        if (!anchors[cat.id]) {
                          setAnchors(prevAnchors => ({ ...prevAnchors, [cat.id]: event.currentTarget }));
                        }
                      }
                    : undefined
                }
                onMouseMove={
                  window.innerWidth > 600
                    ? (event: MouseEvent<HTMLLIElement>): void => {
                        event.persist();
                        if (!anchors[cat.id]) {
                          setAnchors(prevAnchors => ({ ...prevAnchors, [cat.id]: event.currentTarget }));
                        }
                      }
                    : undefined
                }
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={cat.name}
                  primaryTypographyProps={{ variant: `body1` }}
                />
                {window.innerWidth > 600 && (
                  <ListItemSecondaryAction className={classes.secondaryAction}>
                    <ChevronRightIcon />
                  </ListItemSecondaryAction>
                )}
              </ListSubheader>
            ) : (
              undefined
            ),
          ];
          if (!!subCategories && !!subCategories.length)
            subCategories.forEach(subCat => {
              itemsArray.push(
                <MenuItem
                  classes={{ selected: classes.subheaderSelected }}
                  key={subCat.id}
                  ref={(el: HTMLLIElement): void => {
                    refsObject.current[subCat.id] = el;
                  }}
                  style={{ maxHeight: 0, padding: window.innerWidth > 600 ? 0 : undefined }}
                  value={subCat.id}
                >
                  <Radio checked={value === subCat.id} color="primary" />
                  <ListItemText className={classes.listItemText} primary={subCat.name} />
                </MenuItem>,
              );
            });
          return itemsArray;
        })}
      </Select>
      {activeCategoriesAndSubCategories.map(cat => {
        const { subCategories } = cat;
        return (
          !!subCategories &&
          !!subCategories.length && (
            <Popover
              anchorEl={anchors[cat.id]}
              anchorOrigin={{ horizontal: `right`, vertical: `top` }}
              getContentAnchorEl={undefined}
              key={cat.id}
              onMouseMove={(event: MouseEvent<HTMLDivElement>): void => {
                event.persist();
                const fatherElement = anchors[cat.id];
                const paperElement = paperRefs.current[cat.id];
                if (fatherElement && paperElement) {
                  const { clientX, clientY } = event;
                  const {
                    bottom: bottomFather,
                    left: leftFather,
                    right: rightFather,
                    top: topFather,
                  } = fatherElement.getBoundingClientRect();
                  const { left: leftSubMenu, right: rightSubMenu } = paperElement.getBoundingClientRect();
                  if (
                    !(clientX >= leftSubMenu && clientX <= rightSubMenu) &&
                    clientX >= leftFather &&
                    clientX <= rightFather &&
                    (clientY > bottomFather || clientY < topFather)
                  ) {
                    setAnchors(prevAnchors => ({ ...prevAnchors, [cat.id]: undefined }));
                  }
                }
              }}
              open={!!anchors[cat.id]}
              transformOrigin={{ horizontal: `left`, vertical: `top` }}
            >
              <Paper
                onMouseLeave={
                  window.innerWidth > 600
                    ? (event: MouseEvent<HTMLDivElement>): void => {
                        event.persist();
                        setAnchors(prevAnchors => ({ ...prevAnchors, [cat.id]: undefined }));
                      }
                    : undefined
                }
                ref={(el: HTMLDivElement): void => {
                  paperRefs.current[cat.id] = el;
                }}
              >
                <List
                  className={classes.list}
                  onMouseLeave={(): void => setAnchors(prevAnchors => ({ ...prevAnchors, [cat.id]: undefined }))}
                  style={{
                    overflowY: `auto`,
                  }}
                >
                  {subCategories.map(subCat => {
                    const { description } = subCat;
                    return (
                      <Tooltip
                        key={subCat.id}
                        placement="right-start"
                        PopperProps={{
                          modifiers: {
                            flip: {
                              enabled: true,
                            },
                            preventOverflow: {
                              enabled: true,
                              boundariesElement: `viewport`,
                            },
                            arrow: {
                              enabled: true,
                            },
                          },
                        }}
                        title={
                          <>
                            <Typography>
                              <strong>Descrição da categoria:</strong>
                            </Typography>
                            <Typography>{cat.description}</Typography>
                            <Typography>
                              <strong>Descrição da subcategoria:</strong>
                            </Typography>
                            <Typography>{description}</Typography>
                          </>
                        }
                      >
                        <MenuItem
                          classes={{ selected: classes.subheaderSelected }}
                          onClick={(): void => {
                            setAnchors(prevAnchors => ({ ...prevAnchors, [cat.id]: undefined }));
                            refsObject.current[subCat.id].click();
                          }}
                          selected={value === subCat.id}
                          value={subCat.id}
                        >
                          <Radio checked={value === subCat.id} color="primary" />
                          <ListItemText className={classes.listItemText} primary={subCat.name} />
                        </MenuItem>
                      </Tooltip>
                    );
                  })}
                </List>
              </Paper>
            </Popover>
          )
        );
      })}
    </FormControl>
  );
};

export default TaskCategorySelect;
