import React from 'react';
import CreateUserForm from './CreateUserForm';
import UpdateUserForm from './UpdateUserForm';

function UserFormHandler({ closeCreateOrUpdateDialog, formInstanceId, fullscreen, genericAvatar, open }) {
  switch (!!formInstanceId) {
    case true:
      return (
        <UpdateUserForm
          genericAvatar={genericAvatar}
          closeCreateOrUpdateDialog={closeCreateOrUpdateDialog}
          formInstanceId={formInstanceId}
          fullscreen={fullscreen}
          open={open}
        />
      );
    default:
      return (
        <CreateUserForm closeCreateOrUpdateDialog={closeCreateOrUpdateDialog} fullscreen={fullscreen} open={open} />
      );
  }
}

export default UserFormHandler;
