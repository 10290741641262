import { Pagination, TableSortOrder } from '../types';
/**
 * Action types
 */
export enum CRMsTypes {
  REPORT_REQUEST = '@CRMs/REPORT_REQUEST', // eslint-disable-line
  REPORT_SUCCESSFULL = '@CRMs/REPORT_SUCCESSFULL', // eslint-disable-line
  LOAD_REQUEST = '@CRMs/LOAD_REQUEST', // eslint-disable-line
  LOAD_SUCCESS = '@CRMs/LOAD_SUCCESS', // eslint-disable-line
  LOAD_FAILURE = '@CRMs/LOAD_FAILURE', // eslint-disable-line
  OPEN_REQUEST = '@CRMs/OPEN_REQUEST', // eslint-disable-line
  OPEN_SUCCESS = '@CRMs/OPEN_SUCCESS', // eslint-disable-line
  CLOSE_REQUEST = '@CRMs/CLOSE_REQUEST', // eslint-disable-line
  CLOSE_SUCCESS = '@CRMs/CLOSE_SUCCESS', // eslint-disable-line
  CHANGE_SORT = '@CRMs/CHANGE_SORT', // eslint-disable-line
  CHANGE_FILTERS = '@CRMs/CHANGE_FILTERS', // eslint-disable-line
  CHANGE_PAGES = '@CRMs/CHANGE_PAGES', // eslint-disable-line
  MOBILE_INFINITE_SCROLL_REQUEST = '@CRMs/MOBILE_INFINITE_SCROLL_REQUEST', // eslint-disable-line
  MOBILE_INFINITE_SCROLL_SUCCESS = '@CRMs/MOBILE_INFINITE_SCROLL_SUCCESS', // eslint-disable-line
  LOAD_ERRORS = '@CRMs/LOAD_ERRORS', // eslint-disable-line
  ADD_OR_UPDATE_REQUEST = '@CRMs/ADD_OR_UPDATE_REQUEST', // eslint-disable-line
  ADD_OR_UPDATE_FAILURE = '@CRMs/ADD_OR_UPDATE_FAILURE', // eslint-disable-line
  ADD_SUCCESS = '@CRMs/ADD_SUCCESS', // eslint-disable-line
  UPDATE_SUCCESS = '@CRMs/UPDATE_SUCCESS', // eslint-disable-line
}
/**
 * Data types
 */
export interface CRM {
  birthDate?: string;
  documentInfo?: {
    document?: string;
    documentType?: string;
  };
  email?: string;
  externalId?: string;
  fullName: string;
  id: number;
  type: `INDIVIDUAL` | `CORPORATION`;
}
export interface FormInstance {
  birthDate?: string;
  document?: string;
  documentInfo?: {
    document?: string;
    documentType?: string;
  };
  documentType?: string;
  email?: string;
  externalId?: string;
  fullName: string;
  id: number;
  type?: `INDIVIDUAL` | `CORPORATION`;
}

export interface CRMError {
  birthDate?: string;
  document?: string;
  documentType?: string;
  email?: string;
  externalId?: string;
  fullName?: string;
}
/**
 * State types
 */
export interface CRMsState {
  readonly data: CRM[];
  readonly dialogOpen: boolean;
  readonly error: boolean;
  readonly filterList: { [key: string]: string[] };
  readonly formErrors?: CRMError;
  readonly formInstance?: FormInstance;
  readonly isLoading: boolean;
  readonly isSaving: boolean;
  readonly page: number;
  readonly pagination: Pagination;
  readonly rowsPerPage: number;
  readonly searchText?: string;
  readonly sort?: { [key: string]: { order: TableSortOrder } };
}
