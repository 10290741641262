import { Reducer } from 'redux';
import { OmbudsmansTypes, OmbudsmansState } from './Types';

export const INITIAL_STATE: OmbudsmansState = {
  data: [],
  dialogOpen: false,
  filterList: { taskType: [`Ativo`] },
  formInstance: undefined,
  isLoading: false,
  isSaving: false,
  orphans: 0,
  page: 0,
  pagination: {
    count: 0,
    next: undefined,
    previous: undefined,
  },
  rowsPerPage: (window as any).innerHeight > 760 ? 15 : 10,
  searchText: ``,
  slaKpis: undefined,
  sort: {},
};

const reducer: Reducer<OmbudsmansState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OmbudsmansTypes.REPORT_REQUEST:
      return { ...state, isLoading: true };
    case OmbudsmansTypes.REPORT_SUCCESSFULL:
      return { ...state, isLoading: false };
    case OmbudsmansTypes.LOAD_REQUEST:
      return { ...state, isLoading: true };
    case OmbudsmansTypes.LOAD_SUCCESS:
      return {
        ...state,
        data: action.payload.results,
        isLoading: false,
        orphans: action.payload.orphans || 0,
        pagination: {
          count: action.payload.count || 0,
          next: action.payload.next,
          previous: action.payload.previous,
        },
      };
    case OmbudsmansTypes.OPEN_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case OmbudsmansTypes.OPEN_SUCCESS:
      return { ...state, dialogOpen: true, formInstance: action.payload, isLoading: false };
    case OmbudsmansTypes.CLOSE_REQUEST:
      return {
        ...state,
        dialogOpen: false,
      };
    case OmbudsmansTypes.CLOSE_SUCCESS:
      return { ...state, dialogOpen: false, formErrors: undefined, formInstance: undefined };
    case OmbudsmansTypes.ADD_OR_UPDATE_REQUEST:
      return { ...state, isSaving: true };
    case OmbudsmansTypes.ADD_SUCCESS:
      return { ...state, dialogOpen: false, isSaving: false };
    case OmbudsmansTypes.UPDATE_SUCCESS:
      return { ...state, formInstance: { ...state.formInstance, ...action.payload.data.task }, isSaving: false };
    case OmbudsmansTypes.ADD_OR_UPDATE_ERROR:
      return { ...state, formErrors: { ...action.payload }, isSaving: false };
    case OmbudsmansTypes.CHANGE_SORT:
      return { ...state, sort: { [action.payload.activeColumn]: { order: action.payload.sort } }, isLoading: true };
    case OmbudsmansTypes.CHANGE_FILTERS:
      return {
        ...state,
        filterList: action.payload.filterList || state.filterList,
        isLoading: true,
        page: action.payload.page || 0,
        searchText: action.payload.searchText || ``,
        sort: action.payload.sort || {},
      };
    case OmbudsmansTypes.CHANGE_PAGES:
      return {
        ...state,
        isLoading: true,
        page: action.payload.page || 0,
        rowsPerPage: action.payload.rowsPerPage || state.rowsPerPage,
      };
    case OmbudsmansTypes.LOAD_ERRORS:
      return {
        ...state,
        isLoading: false,
      };
    case OmbudsmansTypes.LOAD_KPIS_SUSUCCESS:
      return {
        ...state,
        slaKpis: action.payload || undefined,
      };
    default:
      return state;
  }
};

export default reducer;
