import { Reducer } from 'redux';
import { InventoriesState, InventoriesTypes, Inventory } from './Types';

export const INITIAL_STATE: InventoriesState = {
  data: [],
  dialogOpen: false,
  error: false,
  filterList: { isActive: [`Ativo`] },
  formErrors: {},
  formInstance: undefined,
  inactivateOpen: false,
  isLoading: false,
  isSaving: false,
  page: 0,
  pagination: {
    count: 0,
    next: undefined,
    previous: undefined,
  },
  rowsPerPage: window.innerHeight > 760 || window.innerWidth < 600 ? 15 : 10,
  searchText: undefined,
  sort: {},
  toApprove: 0,
};

const reducer: Reducer<InventoriesState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case InventoriesTypes.LOAD_REQUEST:
      return { ...state, isLoading: true, error: false, isSaving: false, formErrors: {} };
    case InventoriesTypes.LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        isSaving: false,
        data: action.payload.data.results,
        pagination: {
          count: action.payload.data.count,
          next: action.payload.data.next,
          previous: action.payload.data.previous,
        },
        toApprove: action.payload.data.toApprove,
      };
    case InventoriesTypes.LOAD_FAILURE:
      return { ...state, isLoading: false, error: true, isSaving: false, data: [] };
    case InventoriesTypes.ADD_OR_UPDATE_REQUEST:
      return { ...state, loading: false, error: false, isSaving: true };
    case InventoriesTypes.ADD_OR_UPDATE_FAILURE:
      return {
        ...state,
        error: true,
        formErrors: action.payload.errors,
        isSaving: false,
        loading: false,
      };
    case InventoriesTypes.ADD_SUCCESS:
      return {
        ...state,
        error: false,
        isSaving: false,
        loading: false,
      };
    case InventoriesTypes.UPDATE_SUCCESS:
      return {
        ...state,
        data: state.data.map(
          (inv: Inventory): Inventory => {
            if (inv.id === action.payload.data.id) {
              const { id, institution, isActive, name, patrimony, type } = action.payload.data;
              return {
                ...inv,
                id,
                institution,
                isActive,
                name,
                patrimony,
                type,
              };
            }
            return inv;
          },
        ),
        error: false,
        isSaving: false,
        loading: false,
      };
    case InventoriesTypes.OPEN_REQUEST:
      return { ...state, isLoading: true, formErrors: {} };
    case InventoriesTypes.OPEN_SUCCESS:
      return { ...state, dialogOpen: true, formInstance: action.payload, isLoading: false };
    case InventoriesTypes.CLOSE_REQUEST:
      return { ...state, isLoading: true, dialogOpen: false };
    case InventoriesTypes.CLOSE_SUCCESS:
      return {
        ...state,
        dialogOpen: false,
        formInstance: undefined,
        isLoading: false,
        formErrors: {},
        inactivateModal: false,
      };
    case InventoriesTypes.CHANGE_SORT:
      return {
        ...state,
        sort: { [action.payload.activeColumn]: { order: action.payload.sort } },
        isLoading: true,
      };
    case InventoriesTypes.CHANGE_FILTERS:
      return {
        ...state,
        filterList: action.payload.filterList,
        searchText: action.payload.searchText,
        isLoading: true,
      };
    case InventoriesTypes.CHANGE_PAGES:
      return {
        ...state,
        page: action.payload.page,
        rowsPerPage: action.payload.rowsPerPage,
        isLoading: true,
      };
    case InventoriesTypes.MOBILE_INFINITE_SCROLL_REQUEST:
      return {
        ...state,
        page: state.page + 1,
        isLoading: true,
      };
    case InventoriesTypes.MOBILE_INFINITE_SCROLL_SUCCESS:
      return {
        ...state,
        pagination: {
          count: action.payload.count,
          next: action.payload.next,
          previous: action.payload.previous,
        },
        data: state.data
          .concat(action.payload.results)
          .filter((inv: Inventory, idx: number, array: Inventory[]) => array.indexOf(inv) === idx),
        isLoading: false,
      };
    case InventoriesTypes.INACTIVATE_OPEN_REQUEST:
      return {
        ...state,
        inactivateOpen: true,
      };
    case InventoriesTypes.INACTIVATE_CLOSE_REQUEST:
      return {
        ...state,
        inactivateOpen: false,
      };
    default:
      return state;
  }
};

export default reducer;
