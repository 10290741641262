import { ChangeEvent, ChangeEventHandler, Dispatch, MouseEvent, SetStateAction } from 'react';
import { Dialog } from 'muibox';
import { FileWithPath } from 'react-dropzone';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { TasksCategory, TasksCategoryState } from 'ReduxFlow/Reducers/TasksCategories/Types';
import { User } from 'ReduxFlow/Reducers/Auth/Types';
import { Attach } from 'Components/NewUIComponents/TaskAttachsContainer';
import { Comment, CommentType } from 'Components/NewUIComponents/CommentsContainer/Tasks';
import api from 'Services/api';
import { AxiosResponse } from 'axios';

export enum TaskType {
  call,
  nc,
  ombudsman,
  preventive,
  tasks,
}

export const taskTypeMap = {
  [TaskType.call]: `chamado`,
  [TaskType.nc]: `não conformidade`,
  [TaskType.ombudsman]: `ouvidoria`,
  [TaskType.preventive]: `preventiva`,
  [TaskType.tasks]: `tarefa`,
};
interface CommentsAndAttachsHandlers<T> {
  commentsControll: (total: number) => (event?: MouseEvent<HTMLButtonElement>) => void;
  endTask: (comment: string) => Promise<AxiosResponse<{ comments: Comment[]; task: T }>>;
  getAttachs: () => void;
  getComments: (id?: number) => void;
  handleCommentSubmit: (
    comment: string,
    id?: number | string,
  ) => (event: MouseEvent<HTMLButtonElement>) => Promise<void>;
  handleCommentDelete: (id: number | string) => (event?: MouseEvent<HTMLButtonElement>) => void;
  handleFileDelete: (id: number | string) => (event?: MouseEvent<HTMLButtonElement>) => void;
  reopenTask: (comment: string) => Promise<AxiosResponse<{ comments: Comment[]; task: T }>>;
  updateFormHandleFileSubmit: (files: FileWithPath[]) => Promise<void>;
}

export function commentsAndAttachsHandlers<T>(data: {
  attachs: Attach[];
  attachsSetter: Dispatch<SetStateAction<Attach[]>>;
  commentSetter: Dispatch<SetStateAction<string>>;
  comments: Comment[];
  commentsSetter: Dispatch<SetStateAction<Comment[]>>;
  dialog: Dialog;
  isGettingCommentsSetter: Dispatch<SetStateAction<boolean>>;
  isSavingSetter: Dispatch<SetStateAction<boolean>>;
  loggedUser: User;
  range: number;
  rangeSetter: Dispatch<SetStateAction<number>>;
  taskId: number;
  taskType: TaskType;
  temporaryAttachsSetter: Dispatch<SetStateAction<FileWithPath[]>>;
  temporaryAttachsHasErrorSetter: Dispatch<SetStateAction<{ [key: number]: boolean } | undefined>>;
  temporaryAttachsProgressSetter: Dispatch<SetStateAction<{ [key: number]: number } | undefined>>;
}): CommentsAndAttachsHandlers<T> {
  const {
    attachs,
    attachsSetter,
    commentSetter,
    comments,
    commentsSetter,
    dialog,
    isGettingCommentsSetter,
    isSavingSetter,
    loggedUser,
    range,
    rangeSetter,
    taskId,
    taskType,
    temporaryAttachsSetter,
    temporaryAttachsHasErrorSetter,
    temporaryAttachsProgressSetter,
  } = data;

  function getComments(id?: number): void {
    isGettingCommentsSetter(true);
    api
      .get<Comment[]>(`/tasks/comments/?task=${taskId}`, {
        params: { after: id },
      })
      .then(response => {
        commentsSetter([...comments, ...response.data]);
      })
      .catch(e => {
        dialog.alert({
          title: `Erro`,
          message: `Não foi possível realizar a busca de anexos, tente novamente mais tarde.`,
          ok: { color: `primary`, text: `Ok`, variant: `outlined` },
        });
      })
      .finally(() => {
        isGettingCommentsSetter(false);
      });
  }

  return {
    commentsControll(total) {
      return function controll(): void {
        if (total > range) {
          rangeSetter(total);
        }
      };
    },
    getAttachs(): void {
      api
        .get<Attach[]>(`/tasks/attachs/?task=${taskId}`)
        .then(response => {
          attachsSetter(response.data);
        })
        .catch(e => {
          dialog.alert({
            title: `Erro`,
            message: `Não foi possível realizar a busca de anexos, tente novamente mais tarde.`,
            ok: { color: `primary`, text: `Ok`, variant: `outlined` },
          });
        });
    },
    endTask: async function endTask(comment: string): Promise<AxiosResponse<{ comments: Comment[]; task: T }>> {
      try {
        const response = await api.patch(`/tasks/${TaskType[taskType]}/${taskId}/close-task/`, { comment });
        return response;
      } catch (e) {
        dialog.alert({
          title: e.response && e.response.status === 409 ? `Proibido` : `Erro`,
          message:
            e.response && e.response.status === 409
              ? `Você não tem permissão para finalizar essa ${taskTypeMap[taskType]}.`
              : `Não foi possível finalizar a ${taskTypeMap[taskType]}, tente novamente mais tarde.`,
          ok: { color: `primary`, text: `Ok`, variant: `outlined` },
        });
        return e;
      }
    },
    getComments,
    handleCommentSubmit(comment: string, id?: number | string) {
      return async function commentSubmit(event: MouseEvent<HTMLButtonElement>): Promise<void> {
        event.preventDefault();
        event.stopPropagation();
        isSavingSetter(true);
        const commentData = new FormData();
        commentData.append(`comment`, comment);
        commentData.append(`date`, new Date().toLocaleString(`pt-br`));
        commentData.append(`user`, loggedUser.id.toString());
        commentData.append(`task`, taskId.toString());

        try {
          let newStateComments: Comment[];
          if (id) {
            const updatePromise = await api.patch<Comment>(`/tasks/comments/${id}/`, { comment });
            newStateComments = comments.map(com => (com.id === id ? { ...updatePromise.data } : com));
          } else {
            const newCommentPromise = await api.post<{
              comment: Comment;
              frtComment?: Comment;
            }>(`/tasks/comments/`, commentData);
            newStateComments = [...comments, newCommentPromise.data.comment];
            if (newCommentPromise.data.frtComment) newStateComments.push(newCommentPromise.data.frtComment);
          }
          commentSetter(``);
          commentsSetter(newStateComments);
        } catch (e) {
          console.error(e);
        } finally {
          isSavingSetter(false);
        }
      };
    },
    handleCommentDelete(id: number | string): (event?: MouseEvent<HTMLButtonElement>) => void {
      return async function commentDelete(): Promise<void> {
        isSavingSetter(true);
        dialog
          .confirm({
            title: `Excluir comentário?`,
            message: `Tem certeza que deseja excluir este comentário? Esta ação é irreversível.`,
            ok: { text: `Excluir`, color: `secondary` },
            cancel: { text: `Cancelar`, color: `default` },
          })
          .then(async () => {
            try {
              await api.delete(`/tasks/comments/${id}/`);
              commentsSetter(
                comments.map(userComment => {
                  if (userComment.id === id) return { ...userComment, commentType: CommentType.TASK_DELETE_COMMENT };
                  return userComment;
                }),
              );
            } catch (e) {
              console.error(e);
            } finally {
              isSavingSetter(false);
            }
          })
          .catch(() => {
            isSavingSetter(false);
          });
      };
    },
    handleFileDelete(id: number | string): (event?: MouseEvent<HTMLButtonElement>) => void {
      return async function fileDelete(): Promise<void> {
        isSavingSetter(true);
        dialog
          .confirm({
            title: `Excluir anexo?`,
            message: `Tem certeza que deseja excluir este anexo? Esta ação é irreversível.`,
            ok: { text: `Excluir`, color: `secondary` },
            cancel: { text: `Cancelar`, color: `default` },
          })
          .then(async () => {
            try {
              const apiPromise = await api.delete(`/tasks/attachs/${id}/`);
              const newComments = comments.map(comment => {
                if (comment.attachment === id) return { ...comment, commentType: CommentType.TASK_ADD_ATTACH_DELETED };
                return comment;
              });
              attachsSetter([...attachs.filter(attach => attach.id !== id)]);
              commentsSetter([...newComments, ...apiPromise.data.comments]);
            } catch (e) {
              dialog.alert({
                title: e.response && e.response.status === 409 ? `Proibido` : `Erro`,
                message:
                  e.response && e.response.status === 409
                    ? `Você não tem permissão para exclusão desse anexo.`
                    : `Não foi possível excluir o anexo, tente novamente mais tarde.`,
                ok: { color: `primary`, text: `Ok`, variant: `outlined` },
              });
            } finally {
              isSavingSetter(false);
            }
          })
          .catch(() => {
            isSavingSetter(false);
          });
      };
    },
    reopenTask: async function reopenTask(comment: string): Promise<AxiosResponse<{ comments: Comment[]; task: T }>> {
      try {
        const response = await api.patch(`/tasks/${TaskType[taskType]}/${taskId}/reopen-task/`, { comment });
        return response;
      } catch (e) {
        dialog.alert({
          title: e.response && e.response.status === 409 ? `Proibido` : `Erro`,
          message:
            e.response && e.response.status === 409
              ? `Você não tem permissão para reabrir essa ${taskTypeMap[taskType]}.`
              : `Não foi possível reabrir a ${taskTypeMap[taskType]}, tente novamente mais tarde.`,
          ok: { color: `primary`, text: `Ok`, variant: `outlined` },
        });
        return e;
      }
    },
    updateFormHandleFileSubmit: async function updateFormHandleFileSubmit(files: FileWithPath[]): Promise<void> {
      let newAttachs = attachs;
      let newComments = comments;
      let newTemporaryAttachs = files;
      files.forEach(async file => {
        const attachData = new FormData();
        isSavingSetter(true);
        attachData.set(`attachment`, file);
        attachData.set(`task`, `${taskId}`);
        attachData.set(`user`, `${loggedUser.id}`);

        try {
          const apiPromise = await api.post(`/tasks/attachs/`, attachData, {
            onUploadProgress(progressEvent) {
              temporaryAttachsProgressSetter(prevProgress => ({
                ...prevProgress,
                [file.lastModified]: Math.round((progressEvent.loaded * 100) / progressEvent.total),
              }));
            },
          });
          newAttachs = [...newAttachs, apiPromise.data.attachment];
          newComments = [...newComments, ...apiPromise.data.comments];
          newTemporaryAttachs = newTemporaryAttachs.filter(prevAttach => prevAttach.lastModified !== file.lastModified);
          attachsSetter(newAttachs);
          commentsSetter(newComments);
          temporaryAttachsSetter(newTemporaryAttachs);
        } catch (e) {
          dialog.alert({
            title: `Erro`,
            message: `Não foi possível enviar o arquivo, tente novamente mais tarde.`,
            ok: { text: `Ok`, color: `secondary` },
          });
          temporaryAttachsHasErrorSetter(prevErrors => ({ ...prevErrors, [file.lastModified]: true }));
        } finally {
          isSavingSetter(false);
        }
      });
    },
  };
}

export function disableDates(tasksCategoriesState: TasksCategoryState, selectedCat?: number): boolean {
  return tasksCategoriesState.data.some(
    cat => cat.subCategories && cat.subCategories.some(subCat => subCat.id === selectedCat && subCat.hasRtAndFrt),
  );
}

export function getTaskCategories(
  responsibleSector: number,
  tasksCategoriesState: TasksCategoryState,
): TasksCategory[] {
  return tasksCategoriesState.data.filter(
    (category: TasksCategory) =>
      !category.sectors ||
      category.sectors.length === 0 ||
      (responsibleSector && category.sectors.indexOf(responsibleSector as number) > -1),
  );
}

interface HandleChangeFields<T> {
  handleDateFieldChange: (name: keyof T) => (event: MaterialUiPickersDate) => void;
  handleFieldChange: (name: keyof T) => ChangeEventHandler;
  handleFieldValidate: (name: keyof T) => void;
  handleFormValidate: (requiredFields: (keyof T)[]) => boolean;
  handleInstitutionChange: (value: number | number[]) => void;
  handleReactSelectChange: (name: keyof T) => (value?: number | number[]) => void;
  handleSubCategoryChange: (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => void;
}

export function handleChangeFields<T>(data: {
  afterChangeCallback?: (name: keyof T, value: string) => void;
  errorSetter: (name: keyof T, remove?: boolean, formErrors?: { [key in keyof T]: string }) => void;
  fieldsValues: T;
  setter: Dispatch<SetStateAction<T>>;
  tasksCategoriesState: TasksCategoryState;
}): HandleChangeFields<T> {
  const { afterChangeCallback, errorSetter, fieldsValues, setter, tasksCategoriesState } = data;
  return {
    handleDateFieldChange(name: keyof T): (event: MaterialUiPickersDate) => void {
      return function dateChange(event: MaterialUiPickersDate): void {
        setter(prevValues => ({
          ...prevValues,
          [name]: event ? event.toISOString() : ``,
        }));
        if (afterChangeCallback) afterChangeCallback(name, event ? event.toISOString() : ``);
      };
    },
    handleFieldChange(name: keyof T): ChangeEventHandler<HTMLInputElement> {
      return function changeField(event): void {
        event.persist();
        setter(prevValues => ({
          ...prevValues,
          [name]: event.target.value,
        }));
        if (afterChangeCallback) afterChangeCallback(name, event.target.value);
      };
    },
    handleFieldValidate(name: keyof T): void {
      if (name && !fieldsValues[name]) {
        errorSetter(name);
        return;
      }
      if (name && fieldsValues[name]) {
        errorSetter(name, true);
      }
    },
    handleFormValidate(requiredFields: (keyof T)[]): boolean {
      const formErrors = Object.keys(fieldsValues).reduce((acc: { [key: string]: string }, current: string): {
        [key: string]: string;
      } => {
        if (requiredFields.indexOf(current as keyof T) > -1 && !fieldsValues[current as keyof T])
          return { ...acc, [current]: `Esse campo é obrigatório` };
        if (requiredFields.indexOf(current as keyof T) > -1 && fieldsValues[current as keyof T] && acc[current]) {
          const { [current]: removed, ...rest } = acc;
          return { ...rest };
        }
        return { ...acc };
      }, {});
      errorSetter(`allFields` as keyof T, false, formErrors as { [key in keyof T]: string });
      if (Object.keys(formErrors).length > 0) return true;
      return false;
    },
    handleInstitutionChange(value: number | number[]): void {
      if (!Array.isArray(value))
        setter(prevValues => ({
          ...prevValues,
          institution: value,
        }));
      if (afterChangeCallback) afterChangeCallback(`institution` as keyof T, value.toString());
    },
    handleReactSelectChange(name: keyof T): (value: number | number[] | undefined) => void {
      return function reactSelectChange(id?: number | number[]): void {
        if (id) {
          setter(prevValues => ({
            ...prevValues,
            [name]: id,
          }));
          if (afterChangeCallback) afterChangeCallback(name, id.toString());
        }
      };
    },
    handleSubCategoryChange(
      event: ChangeEvent<{
        name?: string | undefined;
        value: unknown;
      }>,
    ): void {
      let estimatedEndDate: string | null;
      let startDate: string | null;
      if (
        !Array.isArray(event.target.value) &&
        disableDates(tasksCategoriesState, parseInt(event.target.value as string, 10))
      ) {
        startDate = null;
        estimatedEndDate = null;
      }
      setter(prevValues => ({
        ...prevValues,
        estimatedEndDate,
        subcategory: event.target.value as number,
        startDate,
      }));
      if (afterChangeCallback)
        afterChangeCallback(`subcategory` as keyof T, (event.target.value as (string | number | number[])).toString());
    },
  };
}
