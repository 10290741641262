import React, { useState } from 'react';
/**
 * Redux dependencies
 */
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Grid, MenuItem, Paper, Typography } from '@material-ui/core';
import { withDialog } from 'muibox';
import * as InventoryActions from '../../../ReduxFlow/Reducers/Inventories/Actions';
import NewAutoComplete from '../../../Components/NewAutoComplete';
import api from '../../../Services/api';
import trimAndNormalizeString from '../../../Utils/trimAndNormalizeString';

const Acessories = ({ dialog, formInstance, inventoryCategories, propsInventories, updateSuccess }) => {
  const [value, setValue] = useState(null);
  const { id, institution } = formInstance;
  const inventoriesCanBeAnAcessory = propsInventories.data.filter(
    inv => inv.isAcessory && inv.institution === institution && !inv.inventoryFather && inv.isActive,
  );
  const thisInventoryAcessories = propsInventories.data.filter(inv => inv.inventoryFather === id && inv.isActive);

  const handleChange = event => {
    dialog
      .confirm({
        title: `Adicionar acessório`,
        message: `Essa ação irá cadastrar o ativo selecionado como acessório de ${formInstance.name}, tem certeza?`,
        ok: { text: `Confirmar`, variant: `contained`, color: `primary` },
        cancel: { text: `Cancelar`, variant: `outlined`, color: `secondary` },
      })
      .then(() => {
        setValue({ value: event.value, label: event.label });
        const data = new FormData();
        data.append(`inventoryFather`, id);
        api
          .patch(`/inventory/inventory/${event.value}/`, data)
          .then(res => {
            updateSuccess(res.data);
            setValue(null);
          })
          .catch(e => {
            console.error(e);
            dialog
              .alert({
                message: `Não foi possível salvar a alteração, tente novamente mais tarde!`,
                ok: { text: `Ok`, variant: `contained`, color: `primary` },
              })
              .then(() => setValue(null));
          });
      });
  };

  const getSubCategoryName = () => {
    let subCategoryName;
    inventoryCategories.forEach(cat => {
      if (cat.subCategories) {
        cat.subCategories.forEach(subCat => {
          if (subCat.id === formInstance.type) {
            subCategoryName = subCat.name;
          }
        });
      }
    });
    return subCategoryName || `Tipo de inventário não encontrado`;
  };

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        style={{
          display: `flex`,
          flexFlow: `row wrap`,
          alignItems: `center`,
          alignContent: `center`,
        }}
      >
        <NewAutoComplete
          isClearable
          id="id_inventoryFather"
          style={{ marginTop: `auto` }}
          suggestions={inventoriesCanBeAnAcessory.map(val => ({
            label: val.name,
            value: val.id,
            location: val.location,
            patrimony: val.patrimony,
          }))}
          formInstance={formInstance.length ? formInstance.inventory : false}
          handleChange={handleChange}
          name="inventoryFather"
          label="Acessório"
          placeholder="Selecione o acessório"
          nooptionsmessage="Sem opções"
          required
          single={value}
          autofocus={false}
          filterOption={(candidate, input) => {
            const text = trimAndNormalizeString(input);
            return (
              trimAndNormalizeString(candidate.data.location).includes(text) ||
              trimAndNormalizeString(candidate.data.patrimony).includes(text) ||
              trimAndNormalizeString(candidate.label).includes(text)
            );
          }}
          OptionComponent={({ children, innerProps, innerRef, isDisabled, isFocused, isSelected, data }) => {
            return (
              <MenuItem
                key={data.value}
                buttonRef={innerRef}
                selected={isFocused}
                component="div"
                style={{
                  fontWeight: isSelected ? 500 : 400,
                  color: isDisabled ? `#d3d3d3` : null,
                }}
                {...innerProps}
              >
                <Grid container spacing={0} style={{ width: `100%` }}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography>{children}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography color="textSecondary">
                      <small>
                        {data.location}
                        {data.location && data.patrimony && <br />}
                        {data.patrimony}
                      </small>
                    </Typography>
                  </Grid>
                </Grid>
              </MenuItem>
            );
          }}
        />
      </Grid>
      <Grid item xs={12}>
        {thisInventoryAcessories.map(inv => (
          <Paper key={inv.id} style={{ padding: 10, marginBottom: 5 }}>
            <Grid container alignItems="center">
              <Grid item xs={6}>
                <Typography>{inv.name}</Typography>
                <Typography component="div">
                  <small>{`#${inv.id}`}</small>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography align="right">{inv.patrimony}</Typography>
                <Typography align="right">{getSubCategoryName()}</Typography>
              </Grid>
            </Grid>
          </Paper>
        ))}
      </Grid>
    </Grid>
  );
};

const mapStateToProps = state => ({
  loggedUserInfo: state.Auth.data,
  propsInventories: state.Inventories,
  inventoryCategories: state.InventoryCategories.data,
});

const mapDispatchToProps = dispatch => bindActionCreators(InventoryActions, dispatch);

export default withDialog()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Acessories),
);
