import React, { MouseEvent, SFC, useState } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import { History } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import fileDownload from 'js-file-download';
import { useDialog } from 'muibox';

import api from '../../../Services/api';

interface LegacyFilesDownloadCardProps {
  classes: {
    input: string;
    cardHeaderAction: string;
    cardHeaderRoot: string;
    textField: string;
  };
  archiveDeleted: () => void;
  formInstance: {
    id: number;
    legacyArchive: string;
    code: string;
    title: string;
  };
}

const LegacyFilesDownloadCard: SFC<LegacyFilesDownloadCardProps> = ({ classes, formInstance, archiveDeleted }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dialog = useDialog();
  const [isSaving, setIsSaving] = useState(false);

  const handleFileDownload = async (event: MouseEvent<HTMLInputElement>): Promise<void> => {
    event.preventDefault();
    try {
      setIsSaving(true);
      const response = await api.get(`/internaldocs/documentinfo/${formInstance.id}/legacy-archive/`);
      const { url } = response.data;
      const docRes = await fetch(url);
      const blob = await docRes.blob();
      const ext = [`.zip`].find(s => url.indexOf(s) > -1);
      fileDownload(blob, `${formInstance.code} - ${formInstance.title} - legado${ext}`);
    } catch (e) {
      console.error(e, `Não foi possível encontrar o arquivo!`); // eslint-disable-line
      enqueueSnackbar(`Não foi possível conectar com o servidor e buscar o arquivo.`, { variant: `error` });
    } finally {
      setIsSaving(false);
    }
  };

  const handleFileDelete = async (event: MouseEvent<HTMLInputElement>): Promise<void> => {
    event.preventDefault();
    try {
      // @ts-ignore
      await dialog.confirm({
        title: `Remover arquivo com versões anteriores?`,
        message: `Esta ação removerá o arquivo .zip enviado com as versões deste arquivo anteriores ao SIM. Ao remover, será possível enviar um novo arquivo.`,// @ts-igonre
        ok: { text: `Remover arquivo`, variant: `contained`, color: `primary` },
        cancel: { text: `Cancelar`, color: `default` },
      });
    } catch (e) {
      // User pressed 'Cancel' (stupid API :P)
      return;
    }

    try {
      setIsSaving(true);
      await api.delete(`/internaldocs/documentinfo/${formInstance.id}/delete-legacy-archive/`);
      archiveDeleted();
      enqueueSnackbar(`Arquivo removido`);
    } catch (e) {
      console.error(e, `Não foi possível encontrar o documento!`); // eslint-disable-line
      enqueueSnackbar(`Não foi possível conectar com o servidor e apagar o arquivo.`, { variant: `error` });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 15 }}>
      <Card elevation={0} style={{ boxShadow: `0px 1px 3px #00000033` }}>
        <CardHeader
          avatar={<History />}
          classes={{ action: classes.cardHeaderAction, root: classes.cardHeaderRoot }}
          title={
            <>
              <Typography display="inline" style={{ fontWeight: 400, marginRight: 16 }}>
                Versões anteriores ao SIM
              </Typography>
            </>
          }
        />
        <Divider />
        <CardContent>
          <Typography className={classes.textField} style={{ color: `#00000099` }}>
            <small>
              Aqui estão armazenadas todas as versões deste documento anteriores ao uso do SIM para o gerenciamento de
              documentos. Faça download do arquivo .zip e visualize todas as versões.
            </small>
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            component="span"
            disabled={isSaving}
            color="primary"
            className={classes.textField}
            style={{ marginTop: 10 }}
            onClick={handleFileDownload}
          >
            Dowload {isSaving && <CircularProgress size={20} />}
          </Button>
          <Button
            component="span"
            color="default"
            className={classes.textField}
            style={{ marginTop: 10 }}
            onClick={handleFileDelete}
          >
            Remover arquivo
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default LegacyFilesDownloadCard;
