import React from 'react';
/**
 * Material UI - Core
 */
import { CircularProgress, Fab, Grid, Paper, Slide, Typography, withStyles } from '@material-ui/core';
/**
 * Material UI - Icons
 */
import { Add, Clear, Done } from '@material-ui/icons';
import TableInstitutions from '../../Components/TableInstitutions';

const styles = theme => ({
  avatar: {
    margin: 10,
    width: 50,
    height: 50,
  },
  instAvatar: {
    maxWidth: 40,
  },
  userText: {
    wordWrap: `break-word`,
  },
  userWrapper: {
    padding: 10,
    margin: `5px auto 5px auto`,
    maxWidth: `98%`,
  },
  fab: {
    position: `absolute`,
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  loader: {
    position: `fixed`,
    bottom: 30,
    left: `45%`,
    zIndex: 999,
  },
  footer: {
    width: `100vw`,
    height: `56px`,
    position: `absolute`,
    bottom: 0,
    left: 0,
  },
});

const MobileExternalDocs = ({ data, classes, institutions, isLoading, handleOpen }) => {
  return (
    <>
      {isLoading && <CircularProgress className={classes.loader} />}
      {Boolean(data.length) &&
        data.map(value => {
          return (
            <Slide key={value.tableTitle.id} in direction="up" mountOnEnter unmountOnExit>
              <Paper className={classes.userWrapper} onClick={() => handleOpen({ id: value.tableTitle.id })}>
                <Grid container alignItems="center">
                  <Grid item xs={2}>
                    {value.isActive ? <Done /> : <Clear />}
                  </Grid>
                  <Grid item xs={8} className={classes.userText}>
                    <Typography>{value.tableTitle.title}</Typography>
                    <Typography color="textSecondary">
                      <small>{`#${value.tableTitle.id}`}</small>
                    </Typography>
                    {value.cost ? (
                      <Typography>
                        {parseInt(value.cost, 10).toLocaleString(`pt-br`, {
                          style: `currency`,
                          currency: `BRL`,
                        })}
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item container xs={2} alignItems="center" alignContent="center" justify="center">
                    {value.validity ? (
                      <Typography>
                        <small style={{ position: `relative`, top: 0, right: -5, fontSize: 9 }}>
                          {new Date(`${value.validity}T00:01`).toLocaleString([`pt-br`], {
                            year: `numeric`,
                            month: `2-digit`,
                            day: `2-digit`,
                          })}
                        </small>
                      </Typography>
                    ) : (
                      <Typography>
                        <small style={{ position: `relative`, top: 0, right: -5, fontSize: 9 }}>Indeterminado</small>
                      </Typography>
                    )}
                    <TableInstitutions institutions={institutions} selected={[value.institution]} />
                  </Grid>
                </Grid>
              </Paper>
            </Slide>
          );
        })}
      <Fab className={classes.fab} color="primary" onClick={handleOpen}>
        <Add />
      </Fab>
    </>
  );
};

export default withStyles(styles)(MobileExternalDocs);
