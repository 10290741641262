import React, { FC } from 'react';
import { WatchLater as WatchLaterIcon } from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { timestampToDaysHoursAndMinutesString } from '../../../../../Utils/categoriesUtils';
import AutoComment from '../../AutoComment';
import { AutoCommentCommonTypes } from '../..';
import { Comment, CommentType } from '..';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    children: {
      marginBottom: theme.spacing(1.5),
    },
    padding: {
      display: `block`,
      paddingBottom: theme.spacing(2),
    },
    highlight: {
      color: theme.palette.primary.dark,
      fontWeight: 500,
    },
    icon: {
      color: theme.palette.primary.dark,
      height: theme.spacing(1.875),
      width: theme.spacing(1.875),
    },
  }),
);

interface SLAParsed {
  frt: number;
  rt: number;
}

type SLAAutoCommentProps = Omit<AutoCommentCommonTypes<Comment>, 'genericAvatar'>;// eslint-disable-line

const SLAAutoComment: FC<SLAAutoCommentProps> = ({
  comment,
  fromNow,
  isFirstItem = false,
  isLastItem = false,
  nextIsAutoComment,
  previousIsAutoComment,
}) => {
  const classes = useStyles();
  const slaInfo = JSON.parse(comment.comment || `{"frt":0,"rt":0}`) as SLAParsed;
  const headerActiontext =
    comment.commentType === CommentType.TASK_SLA_DATA ? (
      <span className={classes.padding}>
        O <span className={classes.highlight}>TPR</span> esperado da categoria é de&nbsp;
        <span className={classes.highlight}>{timestampToDaysHoursAndMinutesString(slaInfo.frt, true)}</span>
        <br />O <span className={classes.highlight}>TR</span> esperado da categoria é de&nbsp;
        <span className={classes.highlight}>{timestampToDaysHoursAndMinutesString(slaInfo.rt, true)}</span>
      </span>
    ) : (
      <span>
        O TPR esperado foi desativado O TR esperado foi desativado
        <br />
      </span>
    );

  return (
    <AutoComment
      avatar={<WatchLaterIcon className={classes.icon} />}
      componentClasses={{
        children: classes.children,
      }}
      fromNow={fromNow}
      headerActionText={headerActiontext}
      isFirstItem={isFirstItem}
      isLastItem={isLastItem}
      nextIsAutoComment={nextIsAutoComment}
      previousIsAutoComment={previousIsAutoComment}
    />
  );
};

export default SLAAutoComment;
