import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const auth = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
});

const quality = firebase.initializeApp(
  {
    apiKey: process.env.REACT_APP_FIREBASE_QUALITY_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_QUALITY_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_QUALITY_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_QUALITY_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_QUALITY_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_QUALITY_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_QUALITY_APP_ID,
  },
  `quality`,
);

export { quality };
export default auth;
