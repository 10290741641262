import React, { FC } from 'react';
import { Tooltip, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Dot, { DotTypeEnum } from './Dot';
import { timestampToDaysHoursAndMinutesString } from '../../Utils/categoriesUtils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tooltip: {
      backgroundColor: theme.palette.common.black,
      padding: theme.spacing(1.5),
      maxWidth: `fit-content`,
    },
    typography: {
      cursor: `pointer`,
      '&:not(:first-child)': {
        marginTop: theme.spacing(0.5),
      },
    },
  }),
);

interface SLATooltipProps {
  isForm?: boolean;
  tprAndTr?: {
    tpr: {
      sla: number;
      value: number;
    };
    tr: {
      sla: number;
      value: number;
    };
  };
}

const SLATooltip: FC<SLATooltipProps> = ({ isForm, tprAndTr }) => {
  const classes = useStyles();
  const hasTpr = !!tprAndTr && Number.isInteger(tprAndTr.tpr.value);
  const hasTr = !!tprAndTr && Number.isInteger(tprAndTr.tr.value);
  const tprExpired = !!tprAndTr && tprAndTr.tpr.value / tprAndTr.tpr.sla > 1;
  const trExpired = !!tprAndTr && tprAndTr.tr.value / tprAndTr.tr.sla > 1;

  return !!tprAndTr && (hasTpr || hasTr) ? (
    <Tooltip
      classes={{ tooltip: classes.tooltip }}
      disableFocusListener={!tprAndTr}
      disableHoverListener={!tprAndTr}
      disableTouchListener={!tprAndTr}
      placement="bottom"
      title={
        <div>
          {hasTpr && (
            <Typography
              className={classes.typography}
              display="initial"
              noWrap
              style={{ fontWeight: `bold` }}
              variant="body2"
            >
              <Dot dotType={tprExpired ? DotTypeEnum.ERROR : DotTypeEnum.SUCCESS} marginRight={8} />
              {`TPR: ${timestampToDaysHoursAndMinutesString(tprAndTr.tpr.value, true)} `}
              <Typography component="span" style={{ color: `#8D8D8D` }} variant="body2">
                {tprExpired ? `Fora` : `Dentro`} do esperado de
              </Typography>
              <Typography component="span" style={{ fontWeight: `bold` }} variant="body2">
                {timestampToDaysHoursAndMinutesString(tprAndTr.tpr.sla, true)}
              </Typography>
            </Typography>
          )}
          {hasTr && (
            <Typography
              className={classes.typography}
              display="initial"
              noWrap
              style={{ fontWeight: `bold` }}
              variant="body2"
            >
              <Dot dotType={trExpired ? DotTypeEnum.ERROR : DotTypeEnum.SUCCESS} marginRight={8} />
              {`TR: ${timestampToDaysHoursAndMinutesString(tprAndTr.tr.value, true)} `}
              <Typography component="span" style={{ color: `#8D8D8D` }} variant="body2">
                {trExpired ? `Fora` : `Dentro`} do esperado de
              </Typography>
              <Typography component="span" style={{ fontWeight: `bold` }} variant="body2">
                {timestampToDaysHoursAndMinutesString(tprAndTr.tr.sla, true)}
              </Typography>
            </Typography>
          )}
        </div>
      }
    >
      <div>
        {hasTpr && (
          <Typography
            align="right"
            display={isForm ? `inline` : undefined}
            style={{ marginRight: isForm ? 16 : undefined }}
            variant="body2"
          >
            TPR: {timestampToDaysHoursAndMinutesString(tprAndTr.tpr.value, true)}
            <Dot dotType={tprExpired ? DotTypeEnum.ERROR : DotTypeEnum.SUCCESS} marginLeft={8} />
          </Typography>
        )}
        {hasTr && (
          <Typography
            align="right"
            display={isForm ? `inline` : undefined}
            style={{ marginRight: isForm ? 16 : undefined }}
            variant="body2"
          >
            TR: {timestampToDaysHoursAndMinutesString(tprAndTr.tr.value, true)}
            <Dot dotType={trExpired ? DotTypeEnum.ERROR : DotTypeEnum.SUCCESS} marginLeft={8} />
          </Typography>
        )}
      </div>
    </Tooltip>
  ) : (
    <></>
  );
};

export default SLATooltip;

export { Dot, DotTypeEnum };
