import React from 'react';
/**
 * Redux dependencies
 */
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
/**
 * Material UI - Core
 */
import { Button, Hidden, LinearProgress, Menu, MenuItem, Tooltip, Typography, withStyles } from '@material-ui/core';
/**
 * Material UI - Icons
 */
import { Add, Clear, Done } from '@material-ui/icons';
/**
 * MUI-Datatable
 */
import MUIDataTable from 'mui-datatables';
/**
 * QualityControls actions
 */
import * as qualityControlsActions from '../../ReduxFlow/Reducers/QualityControls/Actions';
/**
 * My components
 */
import api from '../../Services/api';
import DialogQCForm from './DialogQCForm';
import MobileQC from './MobileQC';
import RangePicker from '../../Components/RangePicker';
import { saveFile } from '../../Utils';
import { buildQueryStringFromArray } from '../../Utils/queryString';
import * as muiDatatableHelper from '../../Utils/muiDatatableHelper';

const styles = theme => ({
  smRecipient: {
    height: `calc(100vh - 56px)`,
    overflowY: `auto`,
  },
  institutionAvatar: {
    transition: `transform .3s`,
    '&:hover': {
      transform: `scale(3)`,
      zIndex: 100,
    },
  },
});

class QualityControl extends React.PureComponent {
  state = {
    addMenuSelector: null,
  };

  componentDidMount() {
    const { history, loadQualityControlsRequest, loggedUserInfo, openQualityControlsRequest } = this.props;
    const { isLoadingUI } = loggedUserInfo;
    const { currentOrganization } = loggedUserInfo.data;
    const localStorageOrg = window.localStorage.getItem(`currentOrganization`);
    if (localStorageOrg === JSON.stringify(currentOrganization) && !isLoadingUI) {
      loadQualityControlsRequest();
      const parameters = new URLSearchParams(history.location.search);
      if (parameters.get(`id`) && parameters.get(`dbname`)) {
        history.replace(history.location.pathname);
        openQualityControlsRequest({ dbname: parameters.get(`dbname`), id: parameters.get(`id`) });
      }
    }
  }

  componentDidUpdate() {
    const { changeQualityControlsFilters, filterValue, handleFetchStatus } = this.props;
    clearTimeout(this.externalFilterTimeout);
    this.externalFilterTimeout = setTimeout(() => {
      if (filterValue.status) {
        changeQualityControlsFilters({
          filterList: [[], [], [], [], []],
          searchText: filterValue.value,
        });
        handleFetchStatus();
      }
    }, 1000);
  }

  handleScroll = event => {
    event.stopPropagation();
    const { changeQualityControlsPages, QualityControls } = this.props;
    clearTimeout(this.scrollTimeout);
    if (
      QualityControls.data.length < QualityControls.count &&
      event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight
    ) {
      this.scrollTimeout = setTimeout(() => {
        changeQualityControlsPages({ rowsPerPage: QualityControls.rowsPerPage + 15, page: 0 });
      }, 200);
    }
  };

  handleAnchorOpen = name => event => {
    this.setState({
      [name]: event.currentTarget,
    });
  };

  handleAnchorClose = name => event => {
    this.setState({
      [name]: null,
    });
  };

  render() {
    const {
      changeQualityControlsFilters,
      changeQualityControlsPages,
      changeQualityControlsSort,
      classes,
      closeQualityControlsRequest,
      Institutions,
      QualityControls,
      openQualityControlsRequest,
    } = this.props;
    const { addMenuSelector } = this.state;

    const columns = [
      {
        label: `Conforme`,
        name: `according`,
        options: {
          filter: true,
          customFilterListRender: v => `Conforme: ${v}`,
          filterList: QualityControls.filterList[0].length ? QualityControls.filterList[0] : null,
          filterType: `dropdown`,
          filterOptions: {
            names: [`Conforme`, `Não conforme`],
          },
          customBodyRender: (value, tableMeta, updateValue) => {
            return value ? <Done /> : <Clear />;
          },
        },
      },
      {
        label: `Teste`,
        name: `tableName`,
        options: {
          filter: true,
          customFilterListRender: v => `Teste: ${v}`,
          filterList: QualityControls.filterList[1].length ? QualityControls.filterList[1] : null,
          customBodyRender: value => {
            return (
              <>
                <Typography>{value.name}</Typography>
                <Typography color="textSecondary" style={{ marginRight: 5 }}>
                  <small>{`#${value.id}`}</small>
                </Typography>
              </>
            );
          },
        },
      },
      muiDatatableHelper.buildInstitutionColumn(
        Institutions,
        QualityControls.filterList[2].length ? QualityControls.filterList[2] : [],
      ),
      {
        label: `Ativo`,
        name: `tableInventory`,
        options: {
          filter: true,
          customFilterListRender: v => `Ativo: ${v}`,
          filterList: QualityControls.filterList[3].length ? QualityControls.filterList[3] : null,
          customBodyRender: (value, tableMeta, updateValue) => {
            return value.name;
          },
        },
      },
      {
        label: `Data de execução`,
        name: `executed`,
        options: {
          filter: true,
          filterList: QualityControls.filterList[4] ? QualityControls.filterList[4] : null,
          filterType: `custom`,
          customBodyRender: (value, tableMeta, updateValue) => {
            return new Date(`${value}T12:00`).toLocaleDateString(`pt-br`);
          },
          customFilterListRender: v => {
            if (v.length) {
              return `Datas: ${v[0].toLocaleDateString(`pt-br`)} - ${v[1].toLocaleDateString(`pt-br`)}`;
            }
            return false;
          },
          filterOptions: {
            names: [],
            display: (filterList, onChange, index, column) => {
              return (
                <RangePicker
                  startDate={filterList[4].length ? filterList[4][0] : undefined}
                  endDate={filterList[4].length ? filterList[4][1] : undefined}
                  label="Data de execução"
                  handleSelect={event => {
                    if (event.eventType === 3) {
                      onChange([event.start, event.end], index, column);
                    }
                  }}
                />
              );
            },
          },
        },
      },
      {
        label: `Validade`,
        name: `tableShelflife`,
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            const shelflife = new Date(`${value}T12:00`).toLocaleDateString(`pt-br`);
            return shelflife;
          },
        },
      },
      {
        label: `DB Name`,
        name: `tableQctype`,
        options: {
          filter: false,
          display: `excluded`,
        },
      },
      {
        label: `Id`,
        name: `tableName.id`,
        options: {
          filter: false,
          display: `excluded`,
        },
      },
    ];
    const options = {
      rowsPerPage: QualityControls.rowsPerPage,
      viewColumns: false,
      print: false,
      fixedHeader: true,
      selectableRows: `none`,
      filter: true,
      filterType: `textField`,
      responsive: `stacked`,
      serverSide: true,
      count: QualityControls.count,
      page: QualityControls.page,
      searchText: QualityControls.searchText,
      customToolbar: () => {
        return (
          <>
            <Tooltip title="Adicionar QC">
              <Button
                aria-label="Adicionar"
                aria-owns="addMenu"
                variant="contained"
                color="primary"
                onClick={this.handleAnchorOpen(`addMenuSelector`)}
              >
                <Add /> Adicionar
              </Button>
            </Tooltip>
            <Menu
              id="addMenu"
              anchorEl={addMenuSelector}
              open={Boolean(addMenuSelector)}
              anchorOrigin={{ vertical: `bottom`, horizontal: `center` }}
              transformOrigin={{ vertical: `top`, horizontal: `center` }}
              getContentAnchorEl={null}
              onClose={this.handleAnchorClose(`addMenuSelector`)}
            >
              {[
                { dbName: `qcserver`, name: `CQ Servidor`, id: 0 },
                { dbName: `qctemperature`, name: `CQ Temperatura`, id: 1 },
                { dbName: `qctempctmr`, name: `CQ Temperatura TC-RM`, id: 2 },
                { dbName: `qcquench`, name: `CQ Quench`, id: 3 },
                { dbName: `qcphysics`, name: `CQ Física`, id: 4 },
              ].map(val => (
                <MenuItem key={val.id} onClick={event => openQualityControlsRequest({ dbname: val.dbName, id: null })}>
                  {val.name}
                </MenuItem>
              ))}
            </Menu>
          </>
        );
      },
      textLabels: {
        body: {
          noMatch: `Desculpe, nenhum registro encontrado!`,
          toolTip: `Ordenar`,
        },
        pagination: {
          next: `Próxima página`,
          previous: `Págima anterior`,
          rowsPerPage: `Linhas por página:`,
          displayRows: `de`,
        },
        toolbar: {
          search: `Procurar`,
          downloadCsv: `Download CSV`,
          print: `Imprimir`,
          viewColumns: `Colunas visíveis`,
          filterTable: `Filtrar a tabela`,
        },
        filter: {
          title: `Filtros`,
          reset: `Resetar`,
        },
        viewColumns: {
          title: `Mostrar colunas`,
          titleAria: `Mostrar/Ocultar Colunas da Tabela`,
        },
        selectedRows: {
          text: `linha(s) selecionada(s)`,
          delete: `Deletar`,
          deleteAria: `Deletear linha(s) selecionada(s)`,
        },
      },
      onRowClick: rowData => {
        openQualityControlsRequest({ dbname: rowData[6], id: rowData[7] });
      },
      onTableChange: (action, tableState) => {
        const { filterList, page, rowsPerPage, searchText } = tableState;
        clearTimeout(this.searchTimeout);
        switch (action) {
          case `sort`:
            changeQualityControlsSort({
              activeColumn: tableState.activeColumn,
              sort: tableState.announceText.split(` : `)[1],
            });
            break;
          case `resetFilters`:
          case `filterChange`:
            changeQualityControlsFilters({ page, filterList, searchText });
            break;
          case `search`:
            clearTimeout(this.searchTimeout);
            this.searchTimeout = setTimeout(() => {
              changeQualityControlsFilters({ page, filterList, searchText });
            }, 500);
            break;
          case `changeRowsPerPage`:
          case `changePage`:
            changeQualityControlsPages({ page, rowsPerPage });
            break;
          default:
        }
      },
      onDownload: () => {
        let url = `/qualitycontrol/qualitycontrol/getreport/?page=${QualityControls.page + 1}&length=${
          QualityControls.rowsPerPage
        }&${buildQueryStringFromArray(QualityControls.filterList, QualityControls.searchText)}`;
        if (Object.entries(QualityControls.sort).length !== 0) {
          url += `&sort=${Object.keys(QualityControls.sort)[0]},${
            QualityControls.sort[Object.keys(QualityControls.sort)[0]].order
          }`;
        }
        api
          .get(url, { responseType: `blob` })
          .then(res => saveFile(res.data, `report.xlsx`))
          .catch(error => {
            // eslint-disable-next-line
            console.error(error);
          });
        return false;
      },
    };
    return (
      <>
        <Hidden only={[`md`, `lg`, `xl`]}>
          <div className={classes.smRecipient} onScroll={this.handleScroll}>
            <MobileQC
              addmenuselector={addMenuSelector}
              close={this.handleAnchorClose(`addMenuSelector`)}
              data={QualityControls.data}
              handleOpen={openQualityControlsRequest}
              isLoading={QualityControls.isLoading}
              institutions={Institutions}
              open={this.handleAnchorOpen(`addMenuSelector`)}
            />
          </div>
          <DialogQCForm
            fullscreen
            open={QualityControls.modalOpen}
            handleClose={closeQualityControlsRequest}
            formInstance={QualityControls.formInstance}
            dbname={QualityControls.dbname}
          />
        </Hidden>
        <Hidden only={[`xs`, `sm`]}>
          {QualityControls.isLoading && <LinearProgress style={{ position: `relative` }} />}
          <MUIDataTable
            title="Controles de qualidade"
            data={QualityControls.data}
            columns={columns}
            options={options}
          />
          <DialogQCForm
            open={QualityControls.modalOpen}
            handleClose={closeQualityControlsRequest}
            formInstance={QualityControls.formInstance}
            dbname={QualityControls.dbname}
          />
        </Hidden>
      </>
    );
  }
}

const mapStateToProps = state => ({
  QualityControls: state.QualityControls,
  loggedUserInfo: state.Auth,
  Institutions: state.Institutions.data,
});

const mapDispatchToProps = dispatch => bindActionCreators(qualityControlsActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(withRouter(QualityControl)));
