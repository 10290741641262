import {
  CompoundCategory,
  CompoundCategoryError,
  CompoundCategoryState,
  SimpleCategory,
  SimpleCategoryError,
} from '../types';
import { Sector } from '../Sectors/Types';

export enum TasksCategoryActionTypes {
  LOAD_REQUEST = '@TasksCategories/LOAD_REQUEST', // eslint-disable-line
  LOAD_SUCCESS = '@TasksCategories/LOAD_SUCCESS', // eslint-disable-line
  LOAD_FAILURE = '@TasksCategories/LOAD_FAILURE', // eslint-disable-line
  OPEN_REQUEST = '@TasksCategories/OPEN_REQUEST',   // eslint-disable-line
  OPEN_SUCCESS = '@TasksCategories/OPEN_SUCCESS',   // eslint-disable-line
  CLOSE_REQUEST = '@TasksCategories/CLOSE_REQUEST',   // eslint-disable-line
  CLOSE_SUCCESS = '@TasksCategories/CLOSE_SUCCESS',   // eslint-disable-line
  LOAD_ERRORS = '@TasksCategories/LOAD_ERRORS',   // eslint-disable-line
  ADD_OR_UPDATE_REQUEST = '@TasksCategories/ADD_OR_UPDATE_REQUEST', // eslint-disable-line
  ADD_OR_UPDATE_FAILURE = '@TasksCategories/ADD_OR_UPDATE_FAILURE', // eslint-disable-line
  ADD_SUCCESS = '@TasksCategories/ADD_SUCCESS', // eslint-disable-line
  UPDATE_SUCCESS = '@TasksCategories/UPDATE_SUCCESS', // eslint-disable-line

  ADD_OR_UPDATE_SUBCATEGORY_REQUEST = '@TasksCategories/ADD_OR_UPDATE_SUBCATEGORY_REQUEST', // eslint-disable-line
  ADD_SUBCATEGORY_SUCCESS = '@TasksCategories/ADD_SUBCATEGORY_SUCCESS', // eslint-disable-line
  UPDATE_SUBCATEGORY_SUCCESS = '@TasksCategories/UPDATE_SUBCATEGORY_SUCCESS', // eslint-disable-line
  OPEN_SUBCATEGORY_SUCCESS = '@TasksCategories/OPEN_SUBCATEGORY_SUCCESS',   // eslint-disable-line
  CLOSE_SUBCATEGORY_REQUEST = '@TasksCategories/CLOSE_SUBCATEGORY_REQUEST',   // eslint-disable-line

  LOAD_TYPES_REQUEST = '@TasksCategories/LOAD_TYPES_REQUEST', // eslint-disable-line
  LOAD_TYPES_SUCCESS = '@TasksCategories/LOAD_TYPES_SUCCESS', // eslint-disable-line
}

export interface TasksCategoryTypes {
  id: number;
  name: string;
}

export interface TasksSubcategory extends SimpleCategory {
  category?: number;
  firstResponseTime?: number;
  hasRtAndFrt?: boolean;
  hasFeedbackSurvey?: boolean;
  resolutionTime?: number;
  sectorWorkSchedulleEnabled?: boolean;
}

export type TasksSubcategoryDialog = Omit<TasksSubcategory, 'id'> & { id?: number } // eslint-disable-line
export type TasksSubcategoryErrors = SimpleCategoryError & {
  firstResponseTime?: string;
  resolutionTime?: string;
};

export interface TasksCategoryError extends CompoundCategoryError {
  readonly sectors?: Sector[];
  readonly types?: TasksCategoryTypes;
}

export interface TasksCategory extends CompoundCategory {
  subCategories?: TasksSubcategory[];
  sectors: number[];
  types: number[];
}

export type TasksCategoryDialog = Omit<TasksCategory, 'id'> & { id?: number } // eslint-disable-line

export interface TasksCategoryState extends CompoundCategoryState {
  data: TasksCategory[];
  errors?: TasksSubcategoryErrors;
  formInstance?: TasksCategory;
  selectedCategory?: TasksCategory;
  subCategoryFormInstance?: TasksSubcategory;
  types: TasksCategoryTypes[];
}
