import { all, call, delay, put, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { Action } from 'redux';
import { Dialog } from 'muibox';
import { AxiosResponse } from 'axios';
import { ApplicationState } from '../index';
import { FormInstance, InventoriesState, InventoriesTypes } from './Types';
import { TableSortOrder } from '../types';
import api from '../../../Services/api';
import { filterListToQueryObject } from '../../../Utils';
/**
 * Inventory actions
 */
import {
  addOrUpdateFailure,
  addSuccess,
  closeInactivateInventoryRequest,
  closeInventoryRequest,
  closeInventorySuccess,
  inventoryMobileInfiniteScrollSuccess,
  loadInventoriesSuccess,
  loadRequest,
  openInventorySuccess,
  SagaFormInstance,
  updateSuccess,
} from './Actions';

interface AddOrUpdateAction extends Action {
  payload: {
    data: SagaFormInstance;
    dialog: Dialog;
  };
}

interface LoadAction extends Action {
  payload: {
    dialog: Dialog;
    mobile?: boolean;
  };
}

interface OpenDialogAction extends Action {
  payload: {
    dialog: Dialog;
    id?: number | string;
  };
}
export function getSortValue(
  sort: { [key: string]: { order: TableSortOrder } } | undefined, // eslint-disable-line
): string | undefined {
  if (sort && Object.keys(sort).length) {
    const sortColumn = Object.entries(sort);
    const orderMap = {
      ascending: ``,
      descending: `-`,
    };
    return `${orderMap[sortColumn[0][1].order]}${sortColumn[0][0]}`;
  }
  return undefined;
}

export function* loadInventories(action: LoadAction): SagaIterator {
  try {
    yield delay(500);
    const inventoriesState: InventoriesState = yield select((state: ApplicationState) => state.Inventories);
    const { filterList, page, rowsPerPage, searchText, sort } = inventoriesState;
    const response = yield call(api.get, `/inventory/inventory/`, {
      params: {
        ...filterListToQueryObject(filterList),
        globalSearch: searchText,
        length: rowsPerPage,
        page: page + 1,
        sort: getSortValue(sort),
      },
    });
    if (action.payload.mobile) {
      yield put(inventoryMobileInfiniteScrollSuccess(response.data));
    } else {
      yield put(loadInventoriesSuccess(response.data));
    }
  } catch (error) {
    const { dialog } = action.payload;
    dialog.alert(`Não foi possível connectar com o servidor!`);
    yield put({ type: InventoriesTypes.LOAD_ERRORS });
  }
}

export function* addOrUpdateInventory(action: AddOrUpdateAction): SagaIterator {
  const { dialog } = action.payload;
  try {
    const { files, id } = action.payload.data;
    const { data } = action.payload;
    delete data.files;
    delete data.id;
    let response: AxiosResponse<FormInstance>;
    if (Object.keys(data).length) {
      if (id) {
        response = yield call(api.patch, `/inventory/inventory/${id}/`, data);
        yield put(updateSuccess(response.data));
        yield put(closeInventoryRequest());
        yield put(closeInactivateInventoryRequest());
      } else {
        response = yield call(api.post, `/inventory/inventory/`, data);
        if (!!files && !!files.length) {
          yield all(
            files.map(file => {
              const attachData = new FormData();
              attachData.append(`attachment`, file.attachment);
              attachData.append(`attachType`, file.attachType);
              attachData.append(`inventory`, JSON.stringify(response.data.id));
              return call(api.post, `/inventory/attachs/`, attachData);
            }),
          );
        }
        yield put(addSuccess());
        yield put(closeInventoryRequest());
      }
    }
    yield put(loadRequest(dialog));
  } catch (error) {
    yield put(addOrUpdateFailure(error.response.data));
  }
}

export function* openInventoriesDialog(action: OpenDialogAction): SagaIterator {
  try {
    const { id } = action.payload;
    if (id) {
      const response = yield call(api.get, `/inventory/inventory/${id}/`);
      yield put(openInventorySuccess(response.data));
    } else {
      yield put(openInventorySuccess());
    }
  } catch (error) {
    const { dialog } = action.payload;
    dialog.alert(`Não foi possível connectar com o servidor!`);
    yield put({ type: InventoriesTypes.LOAD_ERRORS });
  }
}

export function* closeInventoriesDialog(): SagaIterator {
  yield put(closeInventorySuccess());
}
