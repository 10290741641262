
let handleChangeTimeout;

export const delayExecution = (timeout, context, callable, args) => {
  clearTimeout(handleChangeTimeout);

    handleChangeTimeout = setTimeout(() => {
      callable.call(context, ...args);
    }, timeout);
}
