import React, { useEffect, useState } from 'react';
import { Paper, Typography } from '@material-ui/core';
/**
 * Moment JS
 */
import Moment from 'moment';
import 'moment/locale/pt-br';
import api from '../../../Services/api';
import activitiesUtil from '../../../Utils/activities';

const ReadLogs = ({ attachs }) => {
  const [logs, setLogs] = useState([]);
  useEffect(() => {
    const attachsIds = attachs.map(val => val.id).join(`&versions=`);
    api.get(`/logs/activities/internaldocs/${attachs[0].documentInfo}/?versions=${attachsIds}`).then(response => {
      setLogs(response.data);
    });
  }, [attachs]);

  return (
    <div elevation={1} style={{ padding: 20 }}>
      {logs && logs.length ? (
        logs.map(val => {
          const actionText = activitiesUtil.getActionTextNoun(val.action);
          const datetime =
            Moment().diff(Moment(val.timestamp), `days`) < 1
              ? Moment(val.timestamp)
                  .fromNow()
                  .charAt(0)
                  .toUpperCase() +
                Moment(val.timestamp)
                  .fromNow()
                  .slice(1)
              : Moment(val.timestamp).format(`DD/MM/YYYY HH:MM`);
          const { user } = val;
          return (
            <Paper key={val.id} elavation={1} style={{ marginBottom: 5, padding: 10 }}>
              <Typography align="right" color="textSecondary">
                <small>{datetime}</small>
              </Typography>
              <Typography align="justify">
                {[null, undefined].indexOf(val.instance.version) < 0
                  ? `Registro de ${actionText} da versão ${val.instance.version} pelo usuário ${user.fullName}.`
                  : `Registro de ${actionText} pelo usuário ${user.fullName}.`}
              </Typography>
            </Paper>
          );
        })
      ) : (
        <Typography align="center">Não existem registros de leitura.</Typography>
      )}
    </div>
  );
};

export default ReadLogs;
