import { all, call, put } from 'redux-saga/effects';
import { Dialog } from 'muibox';
import { Action } from 'redux';
import { SagaIterator } from 'redux-saga';
import api from '../../../Services/api';
import { Institution } from './Types';

import {
  addOrUpdateFailure,
  changeInstitutionsRangeSuccess,
  closeInstitutionsRequest,
  closeInstitutionsSuccess,
  loadFailure,
  loadRequest,
  loadSuccess,
  openInstitutionsSuccess,
} from './Actions';

interface LoadActionType extends Action {
  payload: {
    dialog: Dialog;
  };
}

interface OpenOrCloseDialogType extends Action {
  payload: {
    dialog: Dialog;
    id?: number | string;
  };
}

interface AddOrUpdateActionType extends Action {
  payload: {
    dialog: Dialog;
    id?: number | string;
    postData: Institution;
  };
}

export function* loadInstitutions(action: LoadActionType): SagaIterator {
  const { dialog } = action.payload;
  try {
    const response = yield call(api.get, `/account/institutions/`);
    yield put(loadSuccess(response.data));
  } catch (err) {
    dialog.alert(
      `Não foi possível contatar o servidor, tente atualizar o navegador e caso o problema não seja resolido entrar em contato com nosso suporte!`,
    );
    yield put(loadFailure());
  }
}

export function* openInstitutionsDialog(action: OpenOrCloseDialogType): SagaIterator {
  const { dialog } = action.payload;
  try {
    if (action.payload.id) {
      const response = yield call(api.get, `/account/institutions/${action.payload.id}/`);
      yield put(openInstitutionsSuccess(response.data));
    } else {
      yield put(openInstitutionsSuccess(undefined));
    }
  } catch (error) {
    dialog.alert(
      `Não foi possível realizar essa ação, por favor realize um refresh no navegador e caso o problema não seja resolvido entre em contato com nosso suporte!`,
    );
  }
}

export function* closeInstitutionsDialog(): SagaIterator {
  try {
    yield put(closeInstitutionsSuccess());
  } catch (error) {
    console.log(`Erro ao fechar a caixa de diálogo!`); // eslint-disable-line
  }
}

export function* addOrUpdateInstitutions(action: AddOrUpdateActionType): SagaIterator {
  const { dialog, id, postData } = action.payload;
  try {
    if (id) {
      yield call(api.patch, `/account/institutions/${id}/`, postData);
      yield yield all([put(loadRequest(dialog)), put(closeInstitutionsRequest())]);
    } else {
      yield call(api.post, `/account/institutions/`, postData);
      yield yield all([put(loadRequest(dialog)), put(closeInstitutionsRequest())]);
    }
  } catch (error) {
    dialog.alert(
      `Não foi possível realizar essa ação, por favor realize um refresh no navegador e caso o problema não seja resolvido entre em contato com nosso suporte!`,
    );
    yield put(addOrUpdateFailure(error.response.data));
  }
}

export function* changeInstitutionsRange(): SagaIterator {
  try {
    yield put(changeInstitutionsRangeSuccess());
  } catch (error) {
    console.error(error);
    alert(`Não foi possível realizar essa ação, tente mais tarde!`); // eslint-disable-line
  }
}
