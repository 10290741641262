import React, { FC, MouseEvent } from 'react';
import { Avatar, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AutoComment from '../../AutoComment';
import { AutoCommentCommonTypes } from '../..';
import { Comment, CommentType } from '..';
import AttachWrapper from '../../../TaskAttachsContainer/AttachWrapper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      height: theme.spacing(3),
      width: theme.spacing(3),
    },
    customAttach: {
      margin: `0 0 0 0 !important`,
      padding: `0 ${theme.spacing(0.75)}px !important`,
      minHeight: `0 !important`,
    },
    deleted: {
      textDecoration: `line-through`,
    },
  }),
);

type AttachAutoCommentProps = AutoCommentCommonTypes<Comment> & {
  deleteAttachCallback?: (id: number | string) => (event?: MouseEvent<HTMLButtonElement>) => void;
  loggedUserId: number;
};

const AttachAutoComment: FC<AttachAutoCommentProps> = ({
  comment,
  deleteAttachCallback,
  fromNow,
  genericAvatar,
  isFirstItem = false,
  isLastItem = false,
  loggedUserId,
  nextIsAutoComment,
  previousIsAutoComment,
}) => {
  const classes = useStyles();
  const { userDetail } = comment;
  const attachInfo = {
    attachment: comment.comment,
    id: comment.attachment as number,
    task: comment.task,
    user: comment.user,
  };

  const isDeleted =
    [CommentType.TASK_ADD_ATTACH_DELETED, CommentType.TASK_DELETE_ATTACH].indexOf(comment.commentType) > -1;

  const description = comment.commentType === CommentType.TASK_DELETE_ATTACH ? `removeu` : `anexou`;

  return (
    <AutoComment
      avatar={<Avatar className={classes.avatar} src={userDetail.avatarThumb || genericAvatar} />}
      componentClasses={{ header: isDeleted ? classes.deleted : `` }}
      endAdornment={
        <>
          <AttachWrapper
            attach={attachInfo}
            autoCommentType={comment.commentType}
            className={classes.customAttach}
            deleteCallback={!isDeleted ? deleteAttachCallback : undefined}
            loggedUserId={loggedUserId}
          />
          {isDeleted && (
            <Typography color="textSecondary" component="span" display="inline" variant="body2">
              (Removido)
            </Typography>
          )}
        </>
      }
      fromNow={fromNow}
      headerActionText={`${description} o arquivo`}
      isFirstItem={isFirstItem}
      isLastItem={isLastItem}
      nextIsAutoComment={nextIsAutoComment}
      previousIsAutoComment={previousIsAutoComment}
      userName={userDetail.fullName}
    />
  );
};

export default AttachAutoComment;
