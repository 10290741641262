import React, { FC, useEffect } from 'react';
/**
 * Redux dependencies
 */
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
/**
 * Score actions
 */
/**
 * Material UI - Core
 */
import { Avatar, Grid, Hidden, Paper, Typography, Tooltip } from '@material-ui/core';
/**
 * Material UI - Icons
 */
import {
  Add,
  CheckCircleOutlined,
  Done,
  DeleteOutlined,
  EditOutlined,
  HighlightOffOutlined,
  LockOpen,
  Star,
} from '@material-ui/icons';
import * as ActivitiesActions from '../../ReduxFlow/Reducers/Activities/Actions';
/**
 * Type interfaces
 */
import { ActivitiesState, Score } from '../../ReduxFlow/Reducers/Activities/Types';
import { ApplicationState } from '../../ReduxFlow/Reducers';

type StoreProps = {
  Activities: ActivitiesState;
};

type DispatchProps = {
  loadScoreRequest: typeof ActivitiesActions.loadScoreRequest;
};

interface Props {
  avatar: string;
}

interface UserScore {
  actions: number[];
  user: {
    avatarThumb: string;
    fullName: string;
    id?: string;
  };
}

const Scores: FC<Props & StoreProps & DispatchProps> = ({ Activities, avatar, loadScoreRequest }) => {
  useEffect((): void => {
    loadScoreRequest();
  }, [loadScoreRequest]);
  const scores =
    Activities.score && Object.entries(Activities.score).length
      ? Object.entries(Activities.score)
          .map(
            (item: [string, Score]): UserScore => {
              return {
                user: { ...item[1].user, id: item[0] },
                actions: [item[1].C, item[1].U, item[1].X, item[1].R, item[1].D, item[1].I, item[1].A, item[1].T],
              };
            },
          )
          .sort((a: UserScore, b: UserScore): 1 | -1 | 0 => {
            if (a.actions[7] > b.actions[7]) return -1;
            if (a.actions[7] < b.actions[7]) return 1;
            return 0;
          })
      : [];

  return (
    <>
      {scores.map(
        (score): JSX.Element => {
          const { user } = score;
          return (
            <Paper key={user.id} style={{ margin: 5, padding: 5 }}>
              <Grid container alignItems="center" alignContent="center" justify="center">
                <Grid item xs={2} sm={1}>
                  <Avatar src={user.avatarThumb || avatar} />
                </Grid>
                <Hidden xsDown>
                  <Grid item sm={2}>
                    <Typography>{user.fullName}</Typography>
                  </Grid>
                </Hidden>
                <Grid item xs={1}>
                  <Typography align="center">
                    <Tooltip title="Criar">
                      <Add />
                    </Tooltip>
                  </Typography>
                  <Typography align="center">{score.actions[0]}</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography align="center">
                    <Tooltip title="Editar">
                      <EditOutlined />
                    </Tooltip>
                  </Typography>
                  <Typography align="center">{score.actions[1]}</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography align="center">
                    <Tooltip title="Fechar">
                      <Done />
                    </Tooltip>
                  </Typography>
                  <Typography align="center">{score.actions[2]}</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography align="center">
                    <Tooltip title="Reabrir">
                      <LockOpen />
                    </Tooltip>
                  </Typography>
                  <Typography align="center">{score.actions[3]}</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography align="center">
                    <Tooltip title="Deletar">
                      <DeleteOutlined />
                    </Tooltip>
                  </Typography>
                  <Typography align="center">{score.actions[4]}</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography align="center">
                    <Tooltip title="Inativar">
                      <HighlightOffOutlined />
                    </Tooltip>
                  </Typography>
                  <Typography align="center">{score.actions[5]}</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography align="center">
                    <Tooltip title="Ativar">
                      <CheckCircleOutlined />
                    </Tooltip>
                  </Typography>
                  <Typography align="center">{score.actions[6]}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography align="center">
                    <Tooltip title="Total">
                      <Star />
                    </Tooltip>
                  </Typography>
                  <Typography align="center">{score.actions[7]}</Typography>
                </Grid>
              </Grid>
            </Paper>
          );
        },
      )}
    </>
  );
};

const mapStateToProps = (state: ApplicationState): StoreProps => ({
  Activities: state.Activities,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators({ loadScoreRequest: ActivitiesActions.loadScoreRequest }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Scores);
