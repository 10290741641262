import React, { ComponentType, FC, MouseEvent, ReactElement, useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions/'; // eslint-disable-line
import { AxiosResponse } from 'axios';
import api from '../../../Services/api';
import { FormInstance } from '../../../ReduxFlow/Reducers/Inventories/Types';

type InventoryInfoDialogProps = {
  classes: {
    observationWrapper: string;
    [key: string]: string;
  };
  inventory: number;
  inventoryInfo: boolean;
  onClose: (event: MouseEvent<HTMLDivElement>) => void;
  slideTransition: ComponentType<TransitionProps & { children?: ReactElement<any, any> }>;
};

const InventoryInfoDialog: FC<InventoryInfoDialogProps> = ({
  classes,
  inventory,
  inventoryInfo,
  onClose,
  slideTransition,
}) => {
  const [inventoryObject, setInventoryObject] = useState<FormInstance>();
  useEffect(() => {
    api
      .get(`inventory/inventory/${inventory}`)
      .then((response: AxiosResponse<FormInstance>) => {
        setInventoryObject(response.data);
      })
      .catch(err => {
        console.error(err);
      });
  }, [inventory]);
  return (
    <Dialog
      aria-labelledby="inventory-info-title"
      aria-describedby="inventory-info-description"
      onClose={onClose}
      open={inventoryInfo}
      TransitionComponent={slideTransition}
    >
      <DialogTitle id="inventory-info-title">{inventoryObject ? inventoryObject.name : `Não encontrado`}</DialogTitle>
      <DialogContent>
        <DialogContentText id="inventory-info-serial-number">
          Número de série: {inventoryObject ? inventoryObject.serialnumber : `Não encontrado`}
        </DialogContentText>
        <DialogContentText id="inventory-info-observations-title">Observações:</DialogContentText>
        <DialogContentText id="inventory-info-observations-description" className={classes.observationWrapper}>
          {inventoryObject ? inventoryObject.observations : `Não encontrado`}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default InventoryInfoDialog;
