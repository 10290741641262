import React, { FC } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { RecordVoiceOverOutlined as RecordVoiceOverOutlinedIcon } from '@material-ui/icons';
import { SavingInfoProps } from '../tasks-forms.interfaces';

const SavingInfo: FC<SavingInfoProps> = ({ isMobile, isSaving, lastSaveDate, taskId, taskType }) => {
  const savingTypography = (
    <Typography color="textSecondary" component={isMobile ? `p` : `span`} variant="body2">
      {isSaving && `Salvando alterações...`}
      {!isSaving &&
        lastSaveDate &&
        `Últimas alterações salvas em ${new Date(lastSaveDate).toLocaleDateString(`pt-BR`)}`}
    </Typography>
  );
  return (
    <Grid item style={{ display: isMobile ? undefined : `flex`, width: `100%` }} xs={12}>
      {isMobile ? (
        <>
          <Grid style={{ display: isMobile ? `flex` : undefined }}>
            <RecordVoiceOverOutlinedIcon color="primary" style={{ fontSize: 15 }} />
            <Typography color="textSecondary" variant="body2">
              &nbsp;{taskType} <span style={{ color: `#00BFFF` }}>#{taskId}</span>
            </Typography>
          </Grid>
          {savingTypography}
        </>
      ) : (
        <>
          <RecordVoiceOverOutlinedIcon color="primary" style={{ fontSize: 15 }} />
          <Typography color="textSecondary" component="span" display="inline" variant="body2">
            &nbsp;Ouvidoria <span style={{ color: `#00BFFF` }}>#{taskId}</span>
            &nbsp;•&nbsp;
            {savingTypography}
          </Typography>
        </>
      )}
    </Grid>
  );
};

export default SavingInfo;
