import { User } from '../Users/Types';
/**
 * Action types
 */
export enum ActivitiesTypes {
  LOAD_REQUEST = '@activities/LOAD_REQUEST', // eslint-disable-line
  LOAD_SUCCESS = '@activities/LOAD_SUCCESS', // eslint-disable-line
  LOAD_FAILURE = '@activities/LOAD_FAILURE', // eslint-disable-line
  SCORE_REQUEST = '@activities/SCORE_REQUEST', // eslint-disable-line
  SCORE_SUCCESS = '@activities/SCORE_SUCCESS', // eslint-disable-line
  SCORE_FAILURE = '@activities/SCORE_FAILURE', // eslint-disable-line
  CHANGE_FILTERS = '@activities/CHANGE_FILTERS', // eslint-disable-line
}
/**
 * Data types
 */
export interface Activity {
  id: number;
  contentType: string;
  instance: { id: number; name: string };
  timestamp: string;
  action: string;
  changedFields: string;
  objectId: number;
  user: User;
}

export interface Score {
  user: {
    avatarThumb: string;
    fullName: string;
  };
  A: number;
  C: number;
  D: number;
  I: number;
  R: number;
  T: number;
  U: number;
  V: number;
  X: number;
}
/**
 * State types
 */
export interface ActivitiesState {
  readonly activitiesPage: number;
  readonly count: number;
  readonly data: Activity[];
  readonly error: boolean;
  readonly loading: boolean;
  readonly searchText: string;
  readonly score: { [key: number]: Score };
}
