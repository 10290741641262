import React from 'react';
/**
 * Material UI - Core
 */
import { Button, Tooltip } from '@material-ui/core';
/**
 * Material UI - Icons
 */
import { Add } from '@material-ui/icons';

const CustomToolbar = ({ tooltip, handleModalState }) => (
  <>
    <Tooltip title={tooltip}>
      <Button variant="contained" color="primary" onClick={handleModalState}>
        <Add /> Adicionar
      </Button>
    </Tooltip>
  </>
);

export default CustomToolbar;
