import React, { ChangeEvent, isValidElement, MouseEvent, ReactNode, SFC, useState } from 'react';
import {
  Avatar,
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
  Business as BusinessIcon,
} from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import { Institution } from '../../ReduxFlow/Reducers/Institutions/Types';
import { User } from '../../ReduxFlow/Reducers/Auth/Types';
import { MarginType } from '../../Utils/globalTypes';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    animatedExpansion: {
      WebkitTransition: `max-height 0.3s, padding 0.3s`,
      MozTransition: `max-height 0.3s, padding 0.3s`,
      OTransition: `max-height 0.3s, padding 0.3s`,
      transition: `max-height 0.3s, padding 0.3s`,
    },
    avatar: {
      height: theme.spacing(2.375),
      width: theme.spacing(2.375),
      marginRight: theme.spacing(1),
      '&:last-child': {
        marginRight: theme.spacing(2),
      },
    },
    avatarWrapper: {
      alignContent: `center`,
      alignItems: `center`,
      display: `flex`,
      justifyContent: `flex-start`,
    },
    dense: {
      marginBottom: theme.spacing(0.5),
      marginTop: theme.spacing(1),
    },
    falselySelected: {
      background: `rgba(0, 0, 0, 0.08) 0% 0% no-repeat padding-box !important`,
    },
    menuItemText: {
      overflow: `hidden`,
      textOverflow: `ellipsis`,
      whiteSpace: `nowrap`,
    },
    menuPaper: {
      maxWidth: 400,
    },
    none: {
      marginBottom: 0,
      marginTop: 0,
    },
    normal: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(2),
    },
    renderValueWrapper: {
      alignContent: `center`,
      alignItems: `center`,
      display: `flex`,
      justifyContent: `space-between`,
    },
    singleValueText: {
      font: `inherit`,
      overflow: `hidden`,
      textOverflow: `ellipsis`,
      whiteSpace: `nowrap`,
    },
    trulySelected: {
      background: `rgba(75, 194, 200, 0.1) 0% 0% no-repeat padding-box !important`,
    },
  }),
);

interface InstitutionWithSelector extends Institution {
  disabled?: boolean;
}

export enum SelectionMode {
  single,
  group,
}

export const filterUserInstitutions = (
  user: User,
  institutions: InstitutionWithSelector[],
): InstitutionWithSelector[] => {
  if (!user || !institutions || !institutions.length) return [];
  return user.isSuperAdmin || user.isHoldingUser
    ? institutions
    : institutions.map(inst => {
        if (user.userInstitution.some(val => [val.id, val.parent].indexOf(inst.id) > -1 || val.id === inst.parent)) {
          return inst;
        }
        return { ...inst, disabled: true };
      });
};

type InstitutionsSelectorProps = {
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  id: string;
  inputProps?: {};
  institutions: InstitutionWithSelector[];
  margin: MarginType;
  multiple?: boolean;
  name: string;
  onChange: (selectedValue: number[] | number) => void;
  required?: boolean;
  selectClass: string;
  selectionMode: SelectionMode;
  value: number[] | number;
  variant: `outlined` | `filled`;
};

const InstitutionsSelector: SFC<InstitutionsSelectorProps> = ({
  disabled,
  error,
  helperText,
  id,
  inputProps,
  institutions,
  margin = MarginType.none,
  multiple = false,
  name,
  onChange,
  required,
  selectClass,
  selectionMode = SelectionMode.group,
  value,
  variant,
}) => {
  const classes = useStyles();
  const org = institutions.find(inst => inst.isOrganization);
  const availableInstitutions = institutions.filter(inst => org && inst.id !== org.id);
  const [parentExpanded, setParentExpanded] = useState<{ [key: number]: number }>();
  const organizationSelected = org && (Array.isArray(value) ? value.indexOf(org.id) > -1 : value === org.id);

  return org ? (
    <FormControl className={selectClass} error={error} margin={margin} required={required} variant={variant}>
      <InputLabel id={`label_${name}`}>Instituições</InputLabel>
      <Select
        disabled={disabled}
        id={id}
        inputProps={inputProps}
        labelId={`label_${name}`}
        labelWidth={required ? 95 : 84.5}
        MenuProps={{
          anchorOrigin: {
            horizontal: `left`,
            vertical: `bottom`,
          },
          className: classes.menuPaper,
          getContentAnchorEl: undefined,
          transformOrigin: {
            horizontal: `left`,
            vertical: `top`,
          },
        }}
        multiple={multiple}
        name={name}
        onChange={(event: ChangeEvent<{ name?: string | undefined; value: unknown }>, child: ReactNode): void => {
          if (Array.isArray(event.target.value)) {
            // If selected value equals to the org unselect all the rest
            if (event.target.value.indexOf(org.id) > -1 && selectionMode === SelectionMode.group) {
              onChange([org.id]);
            } else if (isValidElement(child) && child.props && child.props.value) {
              if (selectionMode === SelectionMode.single) {
                onChange(event.target.value);
                return;
              }
              let branchOfficesIds: number[] = [];
              const selectedInstance = institutions.find(inst => inst.id === child.props.value);
              // Check if the selection was a branchOffice or a mainOffice
              if (selectedInstance && selectedInstance.parent !== org.id) {
                branchOfficesIds = institutions
                  .filter(insideChild => insideChild.parent === selectedInstance.parent)
                  .map(insideChild => insideChild.id);
              } else if (selectedInstance && selectedInstance.parent === org.id) {
                branchOfficesIds = institutions
                  .filter(insideChild => insideChild.parent === child.props.value)
                  .map(insideChild => insideChild.id);
              }
              const mainOffices = institutions.filter(inst => inst.parent === org.id);
              const allMainOfficesSelected =
                mainOffices.length > 1 &&
                mainOffices.every(
                  inst =>
                    inst.parent === org.id &&
                    Array.isArray(event.target.value) &&
                    event.target.value.indexOf(inst.id) > -1,
                );
              const allBranchOfficesSelected =
                branchOfficesIds.length > 1 &&
                branchOfficesIds.every(
                  insideChild => Array.isArray(event.target.value) && event.target.value.indexOf(insideChild) > -1,
                );
              // If all branch offices is going to be selected change to main office and unselect the other brachs
              if (allBranchOfficesSelected) {
                onChange([
                  ...event.target.value.filter(val => branchOfficesIds.indexOf(val) < 0),
                  selectedInstance && selectedInstance.parent !== org.id ? selectedInstance.parent : child.props.value,
                ]);
                // Check if main office was selected and remove all branchs from the selection
              } else if (selectedInstance && selectedInstance.parent === org.id && !allMainOfficesSelected) {
                onChange([...event.target.value.filter(val => branchOfficesIds.indexOf(val) < 0)]);
                // Check if main office is selected when a branch is going to be selected, maintain the state case true
              } else if (
                selectedInstance &&
                Array.isArray(event.target.value) &&
                event.target.value.indexOf(selectedInstance.parent) > -1
              ) {
                onChange(value);
                // If all main offices selected change to the org
              } else if (allMainOfficesSelected) {
                onChange([org.id]);
              } else {
                onChange(event.target.value);
              }
            }
          } else if (typeof event.target.value === `number`) {
            onChange(event.target.value);
          }
        }}
        renderValue={(selected: unknown): ReactNode => {
          let children;
          let institutionName;
          if (Array.isArray(selected)) {
            const renderAvatars = institutions.filter(inst => selected.indexOf(inst.id) > -1);
            if (renderAvatars.length === 1) institutionName = renderAvatars[0].institutionName;
            children = renderAvatars.map((inst, idx) => {
              const renderParent = institutions.find(parent => inst.parent === parent.id);
              if (idx < 4)
                return (
                  <Tooltip key={inst.id} title={inst.institutionName}>
                    <Avatar
                      className={classes.avatar}
                      src={inst.institutionAvatar || (renderParent && renderParent.institutionAvatar)}
                    >
                      <BusinessIcon style={{ fontSize: `0.8rem` }} />
                    </Avatar>
                  </Tooltip>
                );
              return undefined;
            });
          } else {
            const renderInstitution = institutions.find(inst => inst.id === selected);
            const renderParent = institutions.find(
              parent => renderInstitution && renderInstitution.parent === parent.id,
            );
            if (renderInstitution) institutionName = renderInstitution.institutionName;
            children = renderInstitution && (
              <Tooltip key={renderInstitution.id} title={renderInstitution.institutionName}>
                <Avatar
                  className={classes.avatar}
                  src={
                    renderInstitution.institutionAvatar ||
                    (renderParent && renderParent.institutionAvatar) ||
                    org.institutionAvatar
                  }
                >
                  <BusinessIcon style={{ fontSize: `0.8rem` }} />
                </Avatar>
              </Tooltip>
            );
          }
          return (
            <Box className={classes.renderValueWrapper}>
              <Typography className={classes.singleValueText}>
                {Array.isArray(value) && value.length > 1 ? `${value.length} selecionadas` : institutionName}
              </Typography>
              <Box className={classes.avatarWrapper}>{children}</Box>
            </Box>
          );
        }}
        value={value}
      >
        <MenuItem
          classes={{ selected: organizationSelected ? classes.trulySelected : undefined }}
          disabled={org.disabled}
          key={org.id}
          selected={organizationSelected}
          value={org.id}
        >
          <Checkbox
            checked={organizationSelected}
            color={organizationSelected ? `primary` : `default`}
            indeterminate={
              selectionMode === SelectionMode.group &&
              !organizationSelected &&
              ((Array.isArray(value) && !!value.length) || typeof value === `number`)
            }
          />
          <ListItemAvatar>
            <Avatar src={org.institutionAvatar}>
              <BusinessIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText className={classes.menuItemText} primary={org.institutionName} />
        </MenuItem>
        {availableInstitutions.map(inst => {
          const isSelected = Array.isArray(value) ? value.indexOf(inst.id) > -1 : value === inst.id;
          const branchOfficesIds = institutions.filter(child => child.parent === inst.id).map(child => child.id);
          const allBranchOfficesSelected =
            Array.isArray(value) && !!value.length && branchOfficesIds.every(child => value.indexOf(child) > -1);
          const someChildrenSelected = branchOfficesIds.some(child =>
            Array.isArray(value) ? value.indexOf(child) > -1 : value === child,
          );
          if (branchOfficesIds.length) {
            const checked =
              selectionMode === SelectionMode.single
                ? isSelected
                : organizationSelected || isSelected || someChildrenSelected;
            return (
              <MenuItem
                classes={{ selected: isSelected ? classes.trulySelected : classes.falselySelected }}
                className={classes.animatedExpansion}
                disabled={inst.disabled}
                key={inst.id}
                selected={checked}
                style={{ padding: `6px 16px 6px 40px` }}
                value={inst.id}
              >
                <IconButton
                  onClick={(event: MouseEvent<HTMLButtonElement>): void => {
                    event.preventDefault();
                    event.stopPropagation();
                    setParentExpanded(prevValue => ({
                      ...prevValue,
                      [inst.id]: prevValue && prevValue[inst.id] ? 0 : 1000,
                    }));
                  }}
                >
                  {parentExpanded && parentExpanded[inst.id] ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </IconButton>
                <Checkbox
                  checked={checked}
                  color={isSelected ? `primary` : `default`}
                  indeterminate={
                    selectionMode === SelectionMode.group &&
                    !organizationSelected &&
                    someChildrenSelected &&
                    !allBranchOfficesSelected
                  }
                />
                <ListItemAvatar>
                  <Avatar src={inst.institutionAvatar || org.institutionAvatar}>
                    <BusinessIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  className={classes.menuItemText}
                  primary={inst.institutionName}
                  secondary={inst.institutionCity}
                />
              </MenuItem>
            );
          }
          if (inst.parent !== org.id) {
            const parent = institutions.find(instParent => instParent.id === inst.parent);
            const checked =
              selectionMode === SelectionMode.single
                ? isSelected
                : organizationSelected ||
                  isSelected ||
                  (parent && Array.isArray(value) && value.indexOf(parent.id) > -1);
            return (
              <MenuItem
                classes={{ selected: isSelected ? classes.trulySelected : classes.falselySelected }}
                className={classes.animatedExpansion}
                disabled={inst.disabled}
                key={inst.id}
                selected={checked}
                style={{
                  maxHeight: parentExpanded && parent && parentExpanded[parent.id] ? parentExpanded[parent.id] : 0,
                  padding:
                    parentExpanded && parent && parentExpanded[parent.id] ? `6px 16px 6px 136px` : `0px 16px 0px 136px`,
                }}
                value={inst.id}
              >
                <Checkbox checked={checked} color={isSelected ? `primary` : `default`} />
                <ListItemAvatar>
                  <Avatar src={inst.institutionAvatar || (parent && parent.institutionAvatar) || org.institutionAvatar}>
                    <BusinessIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  className={classes.menuItemText}
                  primary={inst.institutionName}
                  secondary={inst.institutionCity}
                />
              </MenuItem>
            );
          }
          const checked = selectionMode === SelectionMode.single ? isSelected : organizationSelected || isSelected;
          return (
            <MenuItem
              classes={{ selected: isSelected ? classes.trulySelected : classes.falselySelected }}
              className={classes.animatedExpansion}
              disabled={inst.disabled}
              key={inst.id}
              selected={checked}
              style={{
                padding: `6px 16px 6px 88px`,
              }}
              value={inst.id}
            >
              <Checkbox checked={checked} color={isSelected ? `primary` : `default`} />
              <ListItemAvatar>
                <Avatar src={inst.institutionAvatar || org.institutionAvatar}>
                  <BusinessIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                className={classes.menuItemText}
                primary={inst.institutionName}
                secondary={inst.institutionCity}
              />
            </MenuItem>
          );
        })}
      </Select>
      {error && helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </FormControl>
  ) : (
    <Skeleton className={`${selectClass} ${classes[margin]}`} height={56} variant="rect" />
  );
};

const InstitutionsSelectorTests = InstitutionsSelector;

export { InstitutionsSelectorTests };

export default InstitutionsSelector;
