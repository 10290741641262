/**
 * Action types
 */
export enum GroupsTypes {
  LOAD_REQUEST = '@groups/LOAD_REQUEST', // eslint-disable-line
  LOAD_SUCCESS = '@groups/LOAD_SUCCESS', // eslint-disable-line
  LOAD_FAILURE = '@groups/LOAD_FAILURE', // eslint-disable-line
}
/**
 * Data types
 */
export interface Group {
  id: number;
  name: string;
  permissions: number[];
}
/**
 * State types
 */
export interface GroupsState {
  readonly data: Group[];
  readonly loading: boolean;
  readonly error: boolean;
}
