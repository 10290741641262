import { Reducer } from 'redux';
import { ActivitiesState, ActivitiesTypes } from './Types';

export const INITIAL_STATE: ActivitiesState = {
  activitiesPage: 0,
  count: 0,
  data: [],
  error: false,
  loading: false,
  score: {},
  searchText: ``,
};

const reducer: Reducer<ActivitiesState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActivitiesTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case ActivitiesTypes.LOAD_SUCCESS:
      return {
        ...state,
        activitiesPage: action.payload.data.next ? state.activitiesPage + 1 : state.activitiesPage,
        count: action.payload.data.count,
        data: state.data.length
          ? Array.from(
              new Set(state.data.concat(action.payload.data.results).map(activity => JSON.stringify(activity))),
            ).map(activity => JSON.parse(activity))
          : action.payload.data.results,
        error: false,
        loading: false,
      };
    case ActivitiesTypes.LOAD_FAILURE:
      return { ...state, loading: false, error: true };
    case ActivitiesTypes.SCORE_REQUEST:
      return { ...state, loading: true };
    case ActivitiesTypes.SCORE_SUCCESS:
      return { ...state, loading: false, error: false, score: action.payload.data };
    case ActivitiesTypes.SCORE_FAILURE:
      return { ...state, loading: false, error: true, score: {} };
    case ActivitiesTypes.CHANGE_FILTERS:
      return { ...state, loading: true, searchText: action.payload.searchText };
    default:
      return state;
  }
};

export default reducer;
