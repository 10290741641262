import { action, ActionType } from 'typesafe-actions';
import { Dialog } from 'muibox';
import { FormInstance, InventoriesTypes, Inventory, InventoryError } from './Types';

export type SagaFormInstance = Omit<FormInstance, 'id'> & { id?: number; files?: { attachment: Blob; attachType: string }[] }; // eslint-disable-line

export const loadRequest = (dialog: Dialog): ActionType<typeof action> =>
  action(InventoriesTypes.LOAD_REQUEST, { dialog });
export const loadInventoriesSuccess = (data: Inventory[]): ActionType<typeof action> =>
  action(InventoriesTypes.LOAD_SUCCESS, { data });
export const loadFailure = (): ActionType<typeof action> => action(InventoriesTypes.LOAD_FAILURE);
export const openInventoryRequest = (data: { dialog: Dialog; id?: number | string }): ActionType<typeof action> =>
  action(InventoriesTypes.OPEN_REQUEST, data);
export const openInventorySuccess = (data?: FormInstance): ActionType<typeof action> =>
  action(InventoriesTypes.OPEN_SUCCESS, data);
export const closeInventoryRequest = (): ActionType<typeof action> => action(InventoriesTypes.CLOSE_REQUEST);
export const closeInventorySuccess = (): ActionType<typeof action> => action(InventoriesTypes.CLOSE_SUCCESS);
export const addOrUpdateRequest = (data: { dialog: Dialog; data: SagaFormInstance }): ActionType<typeof action> =>
  action(InventoriesTypes.ADD_OR_UPDATE_REQUEST, data);
export const addOrUpdateFailure = (errors: InventoryError): ActionType<typeof action> =>
  action(InventoriesTypes.ADD_OR_UPDATE_FAILURE, { errors });
export const addSuccess = (): ActionType<typeof action> => action(InventoriesTypes.ADD_SUCCESS);
export const updateSuccess = (data: FormInstance): ActionType<typeof action> =>
  action(InventoriesTypes.UPDATE_SUCCESS, { data });
export const changeInventorySort = (data: {
  activeColumn: string;
  dialog: Dialog;
  sort: string;
}): ActionType<typeof action> => action(InventoriesTypes.CHANGE_SORT, data);
export const changeInventoryFilters = (data: {
  dialog: Dialog;
  filterList: { [key: string]: string[] };
  searchText?: string;
}): ActionType<typeof action> => action(InventoriesTypes.CHANGE_FILTERS, data);
export const changeInventoryPages = (data: {
  dialog: Dialog;
  page: number;
  rowsPerPage: number;
}): ActionType<typeof action> => action(InventoriesTypes.CHANGE_PAGES, data);
export const inventoryMobileInfiniteScrollRequest = (data: { dialog: Dialog }): ActionType<typeof action> =>
  action(InventoriesTypes.MOBILE_INFINITE_SCROLL_REQUEST, { ...data, mobile: true });
export const inventoryMobileInfiniteScrollSuccess = (data: Inventory[]): ActionType<typeof action> =>
  action(InventoriesTypes.MOBILE_INFINITE_SCROLL_SUCCESS, data);
export const openInactivateInventoryRequest = (): ActionType<typeof action> =>
  action(InventoriesTypes.INACTIVATE_OPEN_REQUEST);
export const closeInactivateInventoryRequest = (): ActionType<typeof action> =>
  action(InventoriesTypes.INACTIVATE_OPEN_REQUEST);
