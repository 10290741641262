import { Reducer } from 'redux';
import { QualityControlsTypes, QualityControlsState } from './Types';

export const INITIAL_STATE: QualityControlsState = {
  modalOpen: false,
  formInstance: [],
  isLoading: false,
  dbname: ``,
  page: 0,
  count: 0,
  data: [],
  searchText: ``,
  rowsPerPage: (window as any).innerHeight > 760 ? 15 : 10,
  filterList: [[], [], [], [], []],
  sort: {},
};

const reducer: Reducer<QualityControlsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case QualityControlsTypes.LOAD_REQUEST:
      return { ...state, isLoading: true };
    case QualityControlsTypes.LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.results,
        count: action.payload.count,
        sort: {},
      };
    case QualityControlsTypes.OPEN_REQUEST:
      return {
        ...state,
        dbname: action.payload.dbname ? action.payload.dbname : ``,
        isLoading: true,
      };
    case QualityControlsTypes.OPEN_SUCCESS:
      return { ...state, modalOpen: true, formInstance: action.payload, isLoading: false };
    case QualityControlsTypes.CLOSE_REQUEST:
      return { ...state, isLoading: true, modalOpen: false };
    case QualityControlsTypes.CLOSE_SUCCESS:
      return { ...state, modalOpen: false, dbname: '', formInstance: [], isLoading: false }
    case QualityControlsTypes.CHANGE_SORT:
      return {
        ...state,
        sort: { [action.payload.activeColumn]: { order: action.payload.sort } },
        isLoading: true,
      };
    case QualityControlsTypes.CHANGE_FILTERS:
      return {
        ...state,
        filterList: action.payload.filterList,
        searchText: action.payload.searchText,
        isLoading: true,
      };
    case QualityControlsTypes.CHANGE_PAGES:
      return {
        ...state,
        page: action.payload.page,
        rowsPerPage: action.payload.rowsPerPage,
        isLoading: true,
      };
    default:
      return state;
  }
};

export default reducer;
