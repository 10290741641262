import React, { ReactNode } from 'react';
import { MUIDataTableColumn, MUIDataTableColumnOptions } from 'mui-datatables';
import InstitutionsSelector, { SelectionMode } from '../Components/InstitutionsSelector';
import TableInstitutions from '../Components/TableInstitutions';
import { Institution } from '../ReduxFlow/Reducers/Institutions/Types';
import { MarginType } from './globalTypes';

type InstitutionColumnOptions = Omit<MUIDataTableColumnOptions, 'filterList'> & { filterList: number[] }; // eslint-disable-line
export type InstitutionColumnProps = Omit<MUIDataTableColumn, 'options'> & { options: InstitutionColumnOptions; filterName: string }; // eslint-disable-line

function deepMerge<T>(target: T, source?: object): T {
  if (!source) return target;
  const assignee = { ...target };
  Object.entries(source).forEach(e => {
    const [k, v] = e;
    if (typeof v === `object`) {
      // eslint-disable-next-line
      // @ts-ignore
      assignee[k] = deepMerge(target[k] || {}, v);
    } else {
      // eslint-disable-next-line
      // @ts-ignore
      assignee[k] = v;
    }
  });
  return assignee;
}

export const buildInstitutionColumn = (
  institutions: Institution[],
  filterList: number[],
  overrides?: object,
): InstitutionColumnProps => {
  const defaultInstitutionColumn: InstitutionColumnProps = {
    filterName: `institutions`,
    label: `Instituição`,
    name: `institution`,
    options: {
      filter: true,
      filterList,
      filterType: `custom`,
      filterOptions: {
        names: institutions.map(inst => inst.institutionName),
        display(displayFilterList: any, onChange, index, column): ReactNode {
          return (
            <InstitutionsSelector
              id="id_institution"
              margin={MarginType.none}
              selectClass=""
              institutions={institutions}
              multiple
              selectionMode={SelectionMode.single}
              name="institution"
              onChange={(values): void => onChange(values, index, column)}
              value={institutions.filter(i => displayFilterList[index].indexOf(i.id) !== -1).map(i => i.id)}
              variant="outlined"
            />
          );
        },
      },
      customFilterListRender: (v): string => {
        if (!v.length) return ``;
        const names = institutions
          .filter(i => v.indexOf(i.id) !== -1)
          .map(i => i.institutionName)
          .join(`,`);
        return `Instituições: ${names}`;
      },
      customBodyRender(value): ReactNode {
        return <TableInstitutions institutions={institutions} selected={Array.isArray(value) ? value : [value]} />;
      },
    },
  };

  return deepMerge(defaultInstitutionColumn, overrides);
};

export const localInstitutionColumnFilter = (institutions: Institution[], org?: Institution): Function => (
  values: number[],
  filter: number[],
): boolean => {
  if (!filter.length || !values.length) return false;
  return !values.find(v => filter.indexOf(v) !== -1);
};

type TableRow = {
  data: any[];
};

function defaultSort(col: number, order: string): (a: TableRow, b: TableRow) => number {
  return (a, b): number => {
    const aData = a.data[col] === null || typeof a.data[col] === `undefined` ? `` : a.data[col];
    const bData = b.data[col] === null || typeof b.data[col] === `undefined` ? `` : b.data[col];
    return (
      (typeof aData.localeCompare === `function` ? aData.localeCompare(bData) : aData - bData) *
      (order === `asc` ? 1 : -1)
    );
  };
}

function institutionSort(
  institutions: Institution[],
  col: number,
  order: string,
): (a: TableRow, b: TableRow) => number {
  return (a, b): number => {
    const aInstNames = a.data[col]
      ? institutions
          .filter(i => a.data[col].indexOf(i.id) !== -1)
          .map(i => i.institutionName)
          .join(`...`)
      : ``;
    const bInstNames = b.data[col]
      ? institutions
          .filter(i => b.data[col].indexOf(i.id) !== -1)
          .map(i => i.institutionName)
          .join(`...`)
      : ``;
    return aInstNames.localeCompare(bInstNames) * (order === `asc` ? 1 : -1);
  };
}

export function localInstitutionColumnSort(institutions: Institution[], column: number): Function {
  return (data: TableRow[], colIndex: number, order: string): TableRow[] => {
    if (column !== colIndex) {
      return data.sort(defaultSort(colIndex, order));
    }

    return data.sort(institutionSort(institutions, colIndex, order));
  };
}

export const buildInstitutionColumnForLocalTable = (
  institutions: Institution[],
  filterList: number[],
  overrides?: InstitutionColumnProps,
): InstitutionColumnProps => {
  return buildInstitutionColumn(
    institutions,
    filterList,
    deepMerge(
      {
        label: `Instituições`,
        options: {
          filterOptions: {
            logic: localInstitutionColumnFilter(institutions, institutions.find(i => i.isOrganization)),
          },
        },
      },
      overrides,
    ),
  );
};
