import React from 'react';
import { Tooltip } from '@material-ui/core';
import {
  Add,
  Build,
  CheckCircleOutlined,
  CloudDownload,
  CloudDownloadOutlined,
  CommentOutlined,
  DeleteOutlined,
  DeleteSweep,
  DescriptionOutlined,
  DeviceUnknown,
  Done,
  DoneAll,
  EditOutlined,
  EventAvailableOutlined,
  HighlightOffOutlined,
  LockOpen,
  Notes,
  PictureAsPdfOutlined,
  RateReviewOutlined,
  RecordVoiceOverOutlined,
  ReportProblem,
} from '@material-ui/icons';
import Moment from 'moment';
import 'moment/locale/pt-br';

import { capitalize } from './trimAndNormalizeString';

const getActionTextNoun = actionCode => {
  switch (actionCode) {
    case `A`:
      return `ativação`;
    case `C`:
      return `criação`;
    case `D`:
      return `remoção`;
    case `I`:
      return `inativação`;
    case `L`:
      return `download do documento em pdf`;
    case `Y`:
      return `download do documento original`;
    case `P`:
      return `aprovação`;
    case `R`:
      return `reabertura`;
    case `U`:
      return `edição`;
    case `V`:
      return `visualização`;
    case `W`:
      return `revisão`;
    case `X`:
      return `fechamento`;
    case `S`:
      return `remoção de  arquivo legado`;
    case `Q`:
      return `download de  arquivo legado`;
    default:
      return `desconhecido`;
  }
};

const getModelIcon = (appLabel, modelName) => {
  switch (true) {
    case (appLabel === `tasks` && modelName === `comments`) || (appLabel === `internaldocs` && modelName === `comment`):
      return (
        <Tooltip title={`Comentário ${appLabel === `tasks` ? `na tarefa` : `no documento interno`}`}>
          <CommentOutlined />
        </Tooltip>
      );
    case appLabel === `tasks` && modelName === `calls`:
      return (
        <Tooltip title="Chamado">
          <Build />
        </Tooltip>
      );
    case appLabel === `tasks` && modelName === `ombudsmans`:
      return (
        <Tooltip title="Ouvidoria">
          <RecordVoiceOverOutlined />
        </Tooltip>
      );
    case appLabel === `tasks` && modelName === `ncs`:
      return (
        <Tooltip title="Não conformidade">
          <ReportProblem />
        </Tooltip>
      );
    case appLabel === `tasks` && modelName === `preventives`:
      return (
        <Tooltip title="Manutenção preventiva">
          <EventAvailableOutlined />
        </Tooltip>
      );
    case appLabel === `tasks` && modelName === `tasks`:
      return (
        <Tooltip title="Tarefa">
          <Notes />
        </Tooltip>
      );
    case (appLabel === `internaldocs` && modelName === `internaldoc`) ||
      (appLabel === `internaldocs` && modelName === `documentinfo`):
      return (
        <Tooltip title="Documento interno">
          <DescriptionOutlined />
        </Tooltip>
      );
    case appLabel === `externaldocs`:
      return (
        <Tooltip title="Documento externo">
          <PictureAsPdfOutlined />
        </Tooltip>
      );
    default:
      return (
        <Tooltip title="Tipo de notificação desconhecida">
          <DeviceUnknown />
        </Tooltip>
      );
  }
};

const getActionElement = actionCode => {
  const title = capitalize(getActionTextNoun(actionCode));
  switch (true) {
    case actionCode === `C` || actionCode === `M`:
      return (
        <Tooltip title={title}>
          <Add />
        </Tooltip>
      );
    case actionCode === `U`:
      return (
        <Tooltip title={title}>
          <EditOutlined />
        </Tooltip>
      );
    case actionCode === `X`:
      return (
        <Tooltip title={title}>
          <Done />
        </Tooltip>
      );
    case actionCode === `R`:
      return (
        <Tooltip title={title}>
          <LockOpen />
        </Tooltip>
      );
    case actionCode === `D`:
      return (
        <Tooltip title={title}>
          <DeleteOutlined />
        </Tooltip>
      );
    case actionCode === `I`:
      return (
        <Tooltip title={title}>
          <HighlightOffOutlined />
        </Tooltip>
      );
    case actionCode === `A`:
      return (
        <Tooltip title={title}>
          <CheckCircleOutlined />
        </Tooltip>
      );
    case actionCode === `L`:
      return (
        <Tooltip title={title}>
          <CloudDownloadOutlined />
        </Tooltip>
      );
    case actionCode === `W`:
      return (
        <Tooltip title={title}>
          <RateReviewOutlined />
        </Tooltip>
      );
    case actionCode === `P`:
      return (
        <Tooltip title={title}>
          <CheckCircleOutlined />
        </Tooltip>
      );
    case actionCode === `V`:
      return (
        <Tooltip title={title}>
          <DoneAll />
        </Tooltip>
      );
    case actionCode === `S`:
      return (
        <Tooltip title={title}>
          <DeleteSweep />
        </Tooltip>
      );
    case actionCode === `Q`:
      return (
        <Tooltip title={title}>
          <CloudDownload />
        </Tooltip>
      );
    default:
      return (
        <Tooltip title={title}>
          <DeviceUnknown />
        </Tooltip>
      );
  }
};

const getDateFromTimestamp = timestamp => {
  return Moment().diff(Moment.unix(timestamp), `days`) < 1
    ? Moment.unix(timestamp)
        .fromNow()
        .charAt(0)
        .toUpperCase() +
        Moment.unix(timestamp)
          .fromNow()
          .slice(1)
    : Moment.unix(timestamp).format(`DD/MM/YYYY HH:MM`);
};

export default { getActionElement, getActionTextNoun, getModelIcon, getDateFromTimestamp };
