import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
/**
 * Redux dependencies
 */
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
/**
 * Material-UI Core
 */
import { Button, Chip, LinearProgress, Link, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
/**
 * Tasks actions
 */
import * as tasksActions from '../../../ReduxFlow/Reducers/Tasks/Actions';

const useStyles = makeStyles({
  markerRoot: {
    fontSize: 12,
    height: 15,
  },
  markerChildren: {
    padding: `0 6px 0 6px`,
  },
});

const PreventivesHistory = ({ changeTasksFilters, changeTasksPages, inventoryId, tabIndex, Tasks }) => {
  const classes = useStyles();
  const [statusFilter, setStatusFilter] = useState(``);
  let timer = null;
  const { count, data, isLoading, rowsPerPage } = Tasks;

  useEffect(() => {
    const sort = {};
    if (statusFilter === `Abertas`) {
      sort[4] = { order: `ascending` };
    }
    if (inventoryId && tabIndex === 2) {
      changeTasksFilters({
        page: 0,
        filterList: [[statusFilter], [], [], [], [], [], [], [], [], [`Preventiva`], [], [], [], [], []],
        searchText: ``,
        sort,
        invId: inventoryId,
      });
    }
  }, [changeTasksFilters, inventoryId, statusFilter, tabIndex]);

  const handleScroll = event => {
    event.stopPropagation();
    clearTimeout(timer);
    if (data.length < count && event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight) {
      timer = setTimeout(() => {
        changeTasksPages({ rowsPerPage: rowsPerPage + 15, page: 0, invId: inventoryId });
      }, 200);
    }
  };

  return (
    <div id="mainDiv" style={{ maxHeight: `100%`, padding: 16, overflowY: `auto` }} onScroll={handleScroll}>
      <Grid container justify="space-between" style={{ marginBottom: 10 }}>
        <Button color="primary" onClick={() => setStatusFilter(`Abertas`)} variant="contained">
          Filtrar por previstas
        </Button>
        {statusFilter !== `` && (
          <Button color="primary" onClick={() => setStatusFilter(``)} variant="contained">
            Limpar filtros
          </Button>
        )}
        <Button color="primary" onClick={() => setStatusFilter(`Fechadas`)} variant="contained">
          Filtrar por realizadas
        </Button>
      </Grid>
      {isLoading && <LinearProgress />}
      {!!data.length &&
        data.map(preventive => {
          const { dates, equipment, id, location, taskTitle, taskType } = preventive;
          const { id: taskTitleId, title } = taskTitle;
          const { isActive } = taskType;
          if (!equipment) {
            return undefined;
          }
          const url = `tasks/?id=${id}&type=Preventiva`;

          return (
            <Paper key={id} style={{ padding: 10, marginBottom: 5 }}>
              <Link href={url} color="inherit" underline="none">
                <Grid container alignItems="center">
                  <Grid item xs={6}>
                    <Typography>{title}</Typography>
                    <Typography component="div">
                      <small>{`#${taskTitleId}${location ? ` - ${location}` : ` - Local não informado`}`}</small>
                    </Typography>
                    <Chip
                      label={isActive === `Aberta` ? `Prevista` : `Realizada`}
                      style={{ color: `#fff`, backgroundColor: `rgb(33, 117, 33)` }}
                      className={classes.markerRoot}
                      classes={{ label: classes.markerChildren }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography align="right">
                      <small>Data inicial: </small>
                      <small>{dates[0] ? new Date(dates[0]).toLocaleString(`pt-BR`) : `Não informada`}</small>
                    </Typography>
                    <Typography align="right">
                      <small>Data final: </small>
                      <small>{dates[1] ? new Date(dates[1]).toLocaleString(`pt-BR`) : `Não informada`}</small>
                    </Typography>
                  </Grid>
                </Grid>
              </Link>
            </Paper>
          );
        })}
      {!data.length && <Typography align="center">Nenhum histórico de preventivas.</Typography>}
    </div>
  );
};

PreventivesHistory.defaultProps = {
  inventoryId: null,
};

PreventivesHistory.propTypes = {
  inventoryId: PropTypes.number,
  changeTasksFilters: PropTypes.func.isRequired,
  changeTasksPages: PropTypes.func.isRequired,
  tabIndex: PropTypes.number.isRequired,
  Tasks: PropTypes.shape({
    modalOpen: PropTypes.bool,
    formInstance: PropTypes.arrayOf(PropTypes.object),
    isLoading: PropTypes.bool,
    menuType: PropTypes.string,
    page: PropTypes.number,
    count: PropTypes.number,
    data: PropTypes.arrayOf(PropTypes.object),
    searchText: PropTypes.string,
    rowsPerPage: PropTypes.number,
    filterList: PropTypes.arrayOf(PropTypes.array),
    sort: PropTypes.object,
  }).isRequired,
};

/* istanbul ignore next */
const mapStateToProps = state => ({
  Tasks: state.Tasks,
});

/* istanbul ignore next */
const mapDispatchToProps = dispatch => bindActionCreators(tasksActions, dispatch);

/* istanbul ignore next */
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PreventivesHistory);

export { PreventivesHistory };
