import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, DialogTitle, IconButton, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Close } from '@material-ui/icons';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ImagePicker from '../ImagePicker';
import InactivationConfirmationDialog from './InactivationConfirmationDialog';
import ReactivationConfirmationDialog from './ReactivationConfirmationDialog';

const useStyles = theme =>
  makeStyles({
    activationButton: {
      color: theme.palette.grey[400],
    },
    avatar: {
      height: 120,
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(2),
      width: 120,
    },
    avatarButton: {
      height: 120,
      left: 32,
      position: `absolute`,
      top: 32,
      width: 120,
      zIndex: 1,
    },
    avatarEditting: {
      background: `#00000099 0% 0% no-repeat padding-box`,
    },
    avatarIcon: {
      color: `#fff`,
      fontSize: `2em`,
    },
    closeIconButton: {
      marginTop: theme.spacing(0.5),
    },
    dialogTitle: {
      padding: `${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(2)}px`,
    },
    fullNameSpacing: {
      marginLeft: theme.spacing(1),
    },
    fullNameContainer: {
      marginTop: theme.spacing(2),
    },
    leftComponent: {
      flexWrap: `nowrap`,
    },
    updateDialogTitle: {
      display: `flex`,
      justifyContent: `space-between`,
    },
  });

const UpdateUserTitle = ({
  avatar,
  canEdit,
  changePassword,
  closeCreateOrUpdateDialog,
  fullName,
  genericAvatar,
  handleUserInactivation,
  handleUserReactivation,
  isActiveUser,
  isFakeEmail,
  isLoading,
  isSubmitting,
  isProfile,
  isViewing,
  openAvatarDialog,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme)();
  const [inactivationDialogopen, setInactivationDialogopen] = useState(false);
  const [reactivationDialogopen, setReactivationDialogopen] = useState(false);

  const checkUserInactivation = data => {
    setInactivationDialogopen(false);
    if (!data) return;
    handleUserInactivation(data);
  };

  const checkUserReactivation = data => {
    setReactivationDialogopen(false);
    if (!data) return;
    handleUserReactivation(data);
  };

  return (
    <DialogTitle className={classes.dialogTitle} disableTypography>
      <InactivationConfirmationDialog
        open={inactivationDialogopen}
        userName={fullName}
        onClose={checkUserInactivation}
      />
      <ReactivationConfirmationDialog
        open={reactivationDialogopen}
        userName={fullName}
        onClose={checkUserReactivation}
      />
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" width="80%">
          <Box display="flex">
            <ImagePicker
              isLoading={isLoading}
              loadedImage={avatar}
              isPicking={isProfile && !isViewing}
              genericImage={genericAvatar}
              onClick={openAvatarDialog}
              sizeAndFormat={{
                height: 120,
                width: 120,
              }}
            />
          </Box>
          <Box
            alignItems="flex-start"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            marginTop={2}
            width="100%"
          >
            {isLoading ? (
              <Skeleton height={31} variant="text" width="100%" />
            ) : (
              <Typography className={classes.fullNameSpacing} variant="h5">
                {fullName}
              </Typography>
            )}
            <Box display="flex" flexDirection="row" justifyContent="flex-start" marginTop={2} width="100%">
              {isLoading ? (
                <Skeleton height={40} variant="rect" width={65} />
              ) : (
                isViewing && (
                  <>
                    {isActiveUser && (
                      <Button color="primary" onClick={canEdit}>
                        Editar
                      </Button>
                    )}
                    {!isProfile && !isActiveUser && (
                      <Button
                        classes={{ label: classes.activationButton }}
                        disabled={isSubmitting}
                        onClick={() => setReactivationDialogopen(true)}
                      >
                        Reativar usuário
                      </Button>
                    )}
                    {!isProfile && isActiveUser && (
                      <Button
                        classes={{ label: classes.activationButton }}
                        disabled={isSubmitting}
                        onClick={() => setInactivationDialogopen(true)}
                      >
                        Desativar usuário
                      </Button>
                    )}
                  </>
                )
              )}
              {isLoading && (isProfile || isFakeEmail) ? (
                <Skeleton height={40} variant="rect" width={65} />
              ) : (
                isViewing &&
                (isProfile || isFakeEmail) && (
                  <Button color="default" onClick={changePassword}>
                    Alterar senha
                  </Button>
                )
              )}
            </Box>
          </Box>
        </Box>
        <Box alignItems="flex-start" display="flex" justifyContent="flex-end" width="20%">
          <IconButton className={classes.closeIconButton} onClick={closeCreateOrUpdateDialog}>
            <Close />
          </IconButton>
        </Box>
      </Box>
    </DialogTitle>
  );
};

UpdateUserTitle.defaultProps = {
  avatar: null,
  changePassword: null,
  isActiveUser: false,
  openAvatarDialog: null,
};

UpdateUserTitle.propTypes = {
  avatar: PropTypes.string,
  canEdit: PropTypes.func.isRequired,
  changePassword: PropTypes.func,
  closeCreateOrUpdateDialog: PropTypes.func.isRequired,
  fullName: PropTypes.string.isRequired,
  isActiveUser: PropTypes.bool,
  isFakeEmail: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isViewing: PropTypes.bool.isRequired,
  genericAvatar: PropTypes.string.isRequired,
  openAvatarDialog: PropTypes.func,
};

export const TestUpdateUserTitle = UpdateUserTitle;

export default UpdateUserTitle;
