import { action, ActionType } from 'typesafe-actions';
import { Dialog } from 'muibox';
import {
  TasksCategoryActionTypes,
  TasksCategoryError,
  TasksCategory,
  TasksCategoryTypes,
  TasksSubcategory,
} from './Types';

export const loadRequest = (data: { dialog: Dialog; taskType?: string }): ActionType<typeof action> =>
  action(TasksCategoryActionTypes.LOAD_REQUEST, data);
export const loadSuccess = (data: TasksCategory[]): ActionType<typeof action> =>
  action(TasksCategoryActionTypes.LOAD_SUCCESS, { data });
export const loadFailure = (): ActionType<typeof action> => action(TasksCategoryActionTypes.LOAD_FAILURE);
export const openTasksCategoryRequest = (data: { dialog: Dialog; id?: number | string }): ActionType<typeof action> =>
  action(TasksCategoryActionTypes.OPEN_REQUEST, data);
export const openTasksCategorySuccess = (data?: TasksCategory[]): ActionType<typeof action> =>
  action(TasksCategoryActionTypes.OPEN_SUCCESS, data);
export const closeTasksCategoryRequest = (): ActionType<typeof action> =>
  action(TasksCategoryActionTypes.CLOSE_REQUEST);
export const closeTasksCategorySuccess = (): ActionType<typeof action> =>
  action(TasksCategoryActionTypes.CLOSE_SUCCESS);
export const addOrUpdateRequest = (data: {
  dialog: Dialog;
  postData: { description?: string; id: number | string; name: string };
}): ActionType<typeof action> => action(TasksCategoryActionTypes.ADD_OR_UPDATE_REQUEST, data);
export const addOrUpdateFailure = (errors: TasksCategoryError): ActionType<typeof action> =>
  action(TasksCategoryActionTypes.ADD_OR_UPDATE_FAILURE, { errors });
export const addSuccess = (data: TasksCategory): ActionType<typeof action> =>
  action(TasksCategoryActionTypes.ADD_SUCCESS, { data });
export const updateSuccess = (data: TasksCategory): ActionType<typeof action> =>
  action(TasksCategoryActionTypes.UPDATE_SUCCESS, { data });
export const addOrUpdateSubCategoryRequest = (data: {
  dialog: Dialog;
  postData: { description?: string; id: number | string; name: string; type: string };
}): ActionType<typeof action> => action(TasksCategoryActionTypes.ADD_OR_UPDATE_SUBCATEGORY_REQUEST, data);
export const loadTypesRequest = (dialog: Dialog): ActionType<typeof action> =>
  action(TasksCategoryActionTypes.LOAD_TYPES_REQUEST, { dialog });
export const loadTypesSuccess = (data: TasksCategoryTypes[]): ActionType<typeof action> =>
  action(TasksCategoryActionTypes.LOAD_TYPES_SUCCESS, { data });
export const openTasksSubCategoryRequest = (data: {
  dialog: Dialog;
  category: TasksCategory;
  subcategory: TasksSubcategory;
}): ActionType<typeof action> => action(TasksCategoryActionTypes.OPEN_SUBCATEGORY_SUCCESS, data);
export const openTasksSubCategorySuccess = (data?: TasksCategory[]): ActionType<typeof action> =>
  action(TasksCategoryActionTypes.OPEN_SUBCATEGORY_SUCCESS, data);
export const closeTasksSubCategoryRequest = (): ActionType<typeof action> =>
  action(TasksCategoryActionTypes.CLOSE_SUBCATEGORY_REQUEST);
