import React, { SFC, useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import {
  Button,
  CircularProgress,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  InputLabel,
  FormControl,
  Select,
} from '@material-ui/core';

import MoodPicker from '../MoodPicker';
import api from '../../Services/api';

const useStyles = makeStyles(() =>
  createStyles({
    actionsRoot: {
      alignItems: `center`,
      display: `flex`,
      flex: `0 0 auto`,
      justifyContent: `flex-end`,
      padding: 24,
    },
    actionsSpacing: {
      '& > :not(:first-child)': {
        marginLeft: 16,
      },
    },
  }),
);

interface FeedbackDialogProps {
  closeDialog: () => void;
  isOpen: boolean;
  theme: Theme;
}

const FeedbackDialog: SFC<FeedbackDialogProps> = ({ closeDialog, isOpen, theme }) => {
  const classes = useStyles();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [title, setTitle] = useState(``);
  const [mood, setMood] = useState(``);
  const [description, setDescription] = useState(``);
  const { enqueueSnackbar } = useSnackbar();

  useEffect((): void => {
    if (!isOpen) {
      setDescription(``);
      setMood(``);
      setTitle(``);
    }
  }, [isOpen]);

  const createFeedback = async (event: React.FormEvent): Promise<void> => {
    try {
      if (event.preventDefault) event.preventDefault();
      setIsSubmitting(true);
      await api.post(`tasks/tasks/create-feedback/`, { mood, description, title });
      setIsSubmitting(false);
      closeDialog();
    } catch (e) {
      setIsSubmitting(false);
      enqueueSnackbar(`Não foi possível enviar o feedback`, { variant: `error` });
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={closeDialog}
      aria-labelledby="feedback-dialog-title"
      aria-describedby="feedback-subject-label"
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle id="feedback-dialog-title">Dar feedback</DialogTitle>
      <DialogContent style={{ overflowY: `hidden`, paddingBottom: 0 }}>
        <form id="feedbackForm" name="feedbackForm" autoComplete="off" onSubmit={createFeedback}>
          <MoodPicker label="Como você se sente em relação ao SIM?" onChange={setMood} error={!mood} />
          <FormControl variant="outlined" style={{ width: `100%`, marginTop: theme.spacing(2) }}>
            <InputLabel id="feedback-subject-label">Sobre o que você quer falar?</InputLabel>
            <Select
              labelId="feedback-subject-label"
              id="feedback-subject"
              style={{ width: `100%` }}
              label="Sobre o que você quer falar?"
              name="title"
              onChange={(event): void => setTitle(`${event.target.value}`)}
              value={title}
              error={!title}
              required
            >
              <MenuItem value="bug">Reportar bug</MenuItem>
              <MenuItem value="feature_request">Solicitar funcionalidade</MenuItem>
              <MenuItem value="feedback">Feedback</MenuItem>
            </Select>
          </FormControl>
          <TextField
            id="feedback-message"
            label="Nos conte mais sobre sua experiência"
            multiline
            rows="3"
            variant="outlined"
            name="description"
            onChange={(event): void => setDescription(`${event.target.value}`)}
            style={{ width: `100%`, marginTop: theme.spacing(2) }}
          />
        </form>
      </DialogContent>
      <DialogActions classes={{ root: classes.actionsRoot, spacing: classes.actionsSpacing }}>
        <Button onClick={closeDialog} color="secondary">
          Cancelar
        </Button>
        <Button color="primary" variant="contained" type="submit" form="feedbackForm" disabled={!mood || !title}>
          {isSubmitting ? <CircularProgress color="inherit" size={24} /> : `Enviar feedback`}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FeedbackDialog;
