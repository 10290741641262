import { action } from 'typesafe-actions';
import { SectorsTypes, Sector, SectorError } from './Types';

export const loadRequest = (token: string) => action(SectorsTypes.LOAD_REQUEST, { token });
export const loadSuccess = (data: Sector[]) => action(SectorsTypes.LOAD_SUCCESS, { data });
export const loadFailure = () => action(SectorsTypes.LOAD_FAILURE);
export const addRequest = (data = {}) => action(SectorsTypes.ADD_REQUEST, data);
export const addSuccess = (data: Sector) => action(SectorsTypes.ADD_SUCCESS, { data });
export const addFailure = (errors: SectorError) => action(SectorsTypes.ADD_FAILURE, { errors });
export const updateRequest = (data: { id: number; data: {} }) =>
  action(SectorsTypes.UPDATE_REQUEST, data);
export const updateSuccess = (data: Sector) => action(SectorsTypes.UPDATE_SUCCESS, { data });
export const updateFailure = (errors: SectorError) =>
  action(SectorsTypes.UPDATE_FAILURE, { errors });
export const openSectorsRequest = (data: { id: number }) => action(SectorsTypes.OPEN_REQUEST, data);
export const openSectorsSuccess = (data: any[]) => action(SectorsTypes.OPEN_SUCCESS, data);
export const closeSectorsRequest = () => action(SectorsTypes.CLOSE_REQUEST);
export const closeSectorsSuccess = () => action(SectorsTypes.CLOSE_SUCCESS);
export const changeSectorsRangeRequest = () => action(SectorsTypes.RANGE_REQUEST);
export const changeSectorsRangeSuccess = () => action(SectorsTypes.RANGE_SUCCESS);
