import { Reducer } from 'redux';
import { AuthState, AuthTypes } from './Types';

export const INITIAL_STATE: AuthState = {
  data: {
    access: ``,
    currentOrganization: { id: `init-state`, displayName: `No local storage` },
    generalInfo: {
      tasks: 0,
      calls: 0,
      ombudsmans: 0,
      ncs: 0,
      userTodos: 0,
      userAsFollower: 0,
      stoppedCalls: 0,
      partialCalls: 0,
      Todos: 0,
      qcs: 0,
      inventories: 0,
      institutions: 0,
      users: 0,
    },
    refresh: ``,
    user: {
      id: 0,
      userInstitution: [
        {
          id: 0,
          institutionName: ``,
          institutionCity: ``,
          institutionState: ``,
          institutionAddress: ``,
          institutionCnpj: ``,
          institutionAvatar: ``,
          institutionLogo: ``,
          isOrganization: true,
          parent: null,
        },
      ],
      password: ``,
      lastLogin: ``,
      isHoldingUser: true,
      isAdminOrSuperAdmin: true,
      isSuperAdmin: true,
      isSuperuser: false,
      username: ``,
      firebaseUid: ``,
      firstName: ``,
      lastName: ``,
      email: ``,
      avatar: ``,
      avatarThumb: ``,
      isStaff: false,
      isActive: false,
      dateJoined: ``,
      groups: [0],
      userPermissions: [0],
      sector: [],
    },
  },
  error: false,
  firebaseToken: undefined,
  isAuthenticated: false,
  isLoadingUI: true,
  logAction: false,
};

const reducer: Reducer<AuthState> = (state = INITIAL_STATE, action) => {
  const currentOrganization = JSON.parse(
    window.localStorage.getItem(`currentOrganization`) || `{ "id": "init-state", "displayName": "No local storage" }`,
  );
  switch (action.type) {
    case AuthTypes.RENEW_TOKEN:
      return {
        ...state,
        data: { ...state.data, currentOrganization },
        error: false,
        firebaseToken: action.payload,
        isAuthenticated: true,
        logAction: false,
      };
    case AuthTypes.RENEW_ERROR:
      return {
        ...state,
        data: { ...INITIAL_STATE.data, currentOrganization },
        error: true,
        firebaseToken: undefined,
        isAuthenticated: false,
        logAction: false,
      };
    case AuthTypes.LOGIN_REQUEST:
      return { ...state, isLoadingUI: true, logAction: true };
    case AuthTypes.LOGIN_SUCCESSFULL:
      return {
        ...state,
        data: { ...action.payload.data, currentOrganization },
        error: false,
        isAuthenticated: true,
        isLoadingUI: false,
        logAction: false,
      };
    case AuthTypes.LOGIN_ERROR:
      return {
        ...state,
        data: { ...INITIAL_STATE.data, currentOrganization },
        error: true,
        isAuthenticated: false,
        isLoadingUI: false,
        logAction: false,
      };
    case AuthTypes.LOGOUT_REQUEST:
      return { ...state, logAction: true, error: false };
    case AuthTypes.LOGOUT_SUCCESSFULL:
      return {
        ...state,
        data: { ...INITIAL_STATE.data, currentOrganization },
        error: false,
        firebaseToken: undefined,
        isAuthenticated: false,
        isLoadingUI: true,
        logAction: false,
      };
    case AuthTypes.LOGOUT_ERROR:
      return { ...state, logAction: false, error: true };
    case AuthTypes.CHANGE_CURRENT_ORGANIZATION:
      return {
        ...state,
        data: {
          ...state.data,
          currentOrganization: action.payload.currentOrganization,
          user: { ...state.data.user, isAdminOrSuperAdmin: true, isHoldingUser: true, isSuperAdmin: true },
        },
        isLoadingUI: true,
      };
    case AuthTypes.UPDATE_AUTH_USER_INFO:
      return { ...state, data: { ...state.data, user: { ...state.data.user, ...action.payload } } };
    default:
      return state;
  }
};

export default reducer;
