import { SimpleCategory } from '../../../ReduxFlow/Reducers/types';

export enum SortDirection {
  asc = "asc",  // eslint-disable-line
  desc = "desc",  // eslint-disable-line
}

export type Column = {
  customRender?: Function;
  label: string;
  name: string | string[];
  sort?: SortDirection;
};

export type SortParams = {
  sortedData: TableData[];
  sort: {
    column: string;
    direction: SortDirection;
  };
};

export interface TableData extends SimpleCategory {
  [key: string]: any;
}
