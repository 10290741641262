import { action, ActionType } from 'typesafe-actions';
import { QualityControl, QualityControlsTypes } from './Types';

export const loadQualityControlsRequest = (): ActionType<typeof action> => action(QualityControlsTypes.LOAD_REQUEST);
export const loadQualityControlsSuccess = (data: {
  count: number;
  next?: string;
  previous?: string;
  results: QualityControl[];
}): ActionType<typeof action> => action(QualityControlsTypes.LOAD_SUCCESS, data);
export const openQualityControlsRequest = (data: {
  id: number | string;
  dbname?: string;
  searchText?: string;
  filterList?: string[][];
}): ActionType<typeof action> => action(QualityControlsTypes.OPEN_REQUEST, data);
export const openQualityControlsSuccess = (data: any[]): ActionType<typeof action> =>
  action(QualityControlsTypes.OPEN_SUCCESS, data);
export const closeQualityControlsRequest = (): ActionType<typeof action> => action(QualityControlsTypes.CLOSE_REQUEST);
export const closeQualityControlsSuccess = (): ActionType<typeof action> => action(QualityControlsTypes.CLOSE_SUCCESS);
export const changeQualityControlsSort = (data: { activeColumn: number; sort: string }): ActionType<typeof action> =>
  action(QualityControlsTypes.CHANGE_SORT, data);
export const changeQualityControlsFilters = (data: {
  filterList: string[][];
  searchText: string;
}): ActionType<typeof action> => action(QualityControlsTypes.CHANGE_FILTERS, data);
export const changeQualityControlsPages = (data: { rowsPerPage: number; page: number }): ActionType<typeof action> =>
  action(QualityControlsTypes.CHANGE_PAGES, data);
