import { Reducer } from 'redux';
import { ExternalDocsCategoriesState, ExternalDocsCategoriesTypes, ExternalDocsCategory } from './Types';

export const INITIAL_STATE: ExternalDocsCategoriesState = {
  data: [],
  dialogOpen: false,
  error: false,
  errors: {},
  formInstance: undefined,
  isSaving: false,
  loading: false,
};

const reducer: Reducer<ExternalDocsCategoriesState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ExternalDocsCategoriesTypes.LOAD_REQUEST:
      return { ...state, loading: true, error: false, isSaving: false };
    case ExternalDocsCategoriesTypes.LOAD_SUCCESS:
      return { ...state, loading: false, error: false, isSaving: false, data: action.payload.data };
    case ExternalDocsCategoriesTypes.LOAD_FAILURE:
      return { ...state, loading: false, error: true, isSaving: false, data: [] };
    case ExternalDocsCategoriesTypes.OPEN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ExternalDocsCategoriesTypes.OPEN_SUCCESS:
      return { ...state, dialogOpen: true, formInstance: action.payload, loading: false };
    case ExternalDocsCategoriesTypes.CLOSE_REQUEST:
      return { ...state, isConvertingTaskToCall: false, loading: true, dialogOpen: false };
    case ExternalDocsCategoriesTypes.CLOSE_SUCCESS:
      return { ...state, loading: false, formInstance: undefined, menuType: ``, dialogOpen: false };
    case ExternalDocsCategoriesTypes.ADD_OR_UPDATE_REQUEST:
      return { ...state, loading: false, error: false, isSaving: true };
    case ExternalDocsCategoriesTypes.ADD_OR_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        isSaving: false,
        errors: action.payload.errors,
      };
    case ExternalDocsCategoriesTypes.LOAD_ERRORS:
      return { ...state, loading: false, error: true, isSaving: false };
    case ExternalDocsCategoriesTypes.ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        isSaving: false,
        data: [action.payload.data, ...state.data],
      };
    case ExternalDocsCategoriesTypes.UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        isSaving: false,
        data: state.data.map((category: ExternalDocsCategory) =>
          category.id === action.payload.data.id ? action.payload.data : category,
        ),
      };
    default:
      return state;
  }
};

export default reducer;
