import React, { FC } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import ptLocale from 'date-fns/locale/pt-BR';
import { InputAdornment } from '@material-ui/core';
import { Event as EventIcon } from '@material-ui/icons';
import { DateTimePicker, DateTimePickerProps, MuiPickersUtilsProvider } from '@material-ui/pickers';
import CustomTextField from '../CustomTextField';
import { MarginType } from '../../../Utils/globalTypes';

interface CustomDateTimePicker extends DateTimePickerProps {
  hasInstance?: boolean;
  isEditing?: boolean;
  margin?: MarginType;
}

const CustomDateTimePicker: FC<CustomDateTimePicker> = props => {
  const {
    animateYearScrolling,
    autoFocus,
    autoOk,
    className,
    disabled,
    error,
    fullWidth,
    hasInstance,
    helperText,
    inputVariant,
    InputProps,
    isEditing,
    label,
    margin,
    minDate,
    name,
    onChange,
    onClose,
    openTo,
    placeholder,
    required,
    value,
  } = props;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
      {/*
      // @ts-ignore */}
      <DateTimePicker
        animateYearScrolling={animateYearScrolling}
        autoFocus={autoFocus}
        autoOk={autoOk}
        cancelLabel="Cancelar"
        className={className}
        clearable
        clearLabel="Limpar"
        disabled={disabled}
        error={error}
        format="dd/MM/yyyy HH:mm"
        fullWidth={fullWidth}
        helperText={helperText}
        // @ts-ignore
        hasInstance={hasInstance}
        inputVariant={inputVariant}
        InputLabelProps={{
          shrink: placeholder ? true : undefined,
        }}
        InputProps={{
          ...InputProps,
          endAdornment: (
            <InputAdornment position="end" style={{ visibility: hasInstance && !isEditing ? `hidden` : undefined }}>
              <EventIcon fontSize="small" style={{ color: `#767676` }} />
            </InputAdornment>
          ),
        }}
        // @ts-ignore
        isEditing={isEditing}
        label={label}
        margin={margin}
        minDate={minDate}
        name={name}
        onChange={onChange}
        onClose={onClose}
        openTo={openTo}
        placeholder={placeholder}
        required={required}
        // @ts-ignore
        TextFieldComponent={CustomTextField}
        value={value}
      />
    </MuiPickersUtilsProvider>
  );
};

export default CustomDateTimePicker;
