import { Institution } from '../Institutions/Types';
import { Sector } from '../Sectors/Types';

/**
 * Action types
 */
export enum AuthTypes {
  LOGIN_REQUEST = '@auth/LOGIN_REQUEST',  // eslint-disable-line
  LOGIN_SUCCESSFULL = '@auth/LOGIN_SUCCESSFULL', // eslint-disable-line
  LOGIN_ERROR = '@auth/LOGIN_ERROR', // eslint-disable-line
  LOGOUT_REQUEST = '@auth/LOGOUT_REQUEST', // eslint-disable-line
  LOGOUT_SUCCESSFULL = '@auth/LOGOUT_SUCCESSFULL', // eslint-disable-line
  LOGOUT_ERROR = '@auth/LOGOUT_ERROR', // eslint-disable-line
  CHECK_TOKEN = '@auth/CHECK_TOKEN', // eslint-disable-line
  RENEW_TOKEN = '@auth/RENEW_TOKEN', // eslint-disable-line
  RENEW_ERROR = '@auth/RENEW_ERROR', // eslint-disable-line
  RESET_STATE = '@auth/RESET_STATE', // eslint-disable-line
  CHANGE_CURRENT_ORGANIZATION = '@auth/CHANGE_CURRENT_ORGANIZATION', // eslint-disable-line
  UPDATE_AUTH_USER_INFO = '@auth/UPDATE_AUTH_USER_INFO' // eslint-disable-line
}
/**
 * Data types
 */
export interface User {
  avatar: string;
  avatarThumb: string;
  dateJoined: string;
  email: string;
  firebaseUid: string;
  firstName: string;
  groups: number[];
  id: number;
  isActive: boolean;
  isAdminOrSuperAdmin: boolean;
  isHoldingUser: boolean;
  isStaff: boolean;
  isSuperAdmin: boolean;
  isSuperuser: boolean;
  lastLogin: string;
  lastName: string;
  password: string;
  sector: Sector[];
  userInstitution: Institution[];
  username: string;
  userPermissions: number[];
}
interface GeneralInfo {
  tasks: number;
  calls: number;
  ombudsmans: number;
  ncs: number;
  userTodos: number;
  userAsFollower: number;
  stoppedCalls: number;
  partialCalls: number;
  Todos: number;
  qcs: number;
  inventories: number;
  institutions: number;
  users: number;
}

export interface CurrentOrganization {
  id: string;
  displayName: string;
}

export interface Auth {
  access: string;
  currentOrganization: CurrentOrganization;
  generalInfo: GeneralInfo;
  refresh: string;
  user: User;
}
/**
 * Sate types
 */
export interface AuthState {
  readonly data: Auth;
  readonly error: boolean;
  readonly firebaseToken: string | undefined;
  readonly isAuthenticated: boolean;
  readonly isLoadingUI: boolean;
  readonly logAction: boolean;
}
