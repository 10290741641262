import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withDialog } from 'muibox';
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Fade,
  Grid,
  Hidden,
  Typography,
  withStyles,
} from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';

import api from '../../Services/api';
import { saveFile, stringToBlob } from '../../Utils';
import trimAndNormalizeString from '../../Utils/trimAndNormalizeString';

const styles = theme => ({
  closeButton: {
    position: `absolute`,
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  container: {
    display: `flex`,
    flexWrap: `wrap`,
  },
  customUnder: {
    [`&:before`]: {
      borderBottom: `0px solid rgba(0, 0, 0, 0.42)`,
    },
  },
  dialogContent: {
    maxHeight: `70vh`,
  },
  dialogTitle: {
    display: `flex`,
    flexFlow: `row wrap`,
    alignConent: `center`,
    justifyContent: `center`,
    width: `100%`,
  },
  input: {
    display: `none`,
  },
  rightIcon: {
    marginLeft: 5,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: `100%`,
  },
});

const DialogCsvImporter = ({ classes, dialog, fullscreen, handleClose, open }) => {
  const [srcFile, setSrcFile] = useState(``);
  const [isSaving, setIsSaving] = useState(false);

  const getEmailSugestions = (fullFirstName, fullLastName) => {
    const firstName = trimAndNormalizeString(fullFirstName.toLowerCase()).replace(/\s/g, ``);
    const lastName = trimAndNormalizeString(fullLastName.toLowerCase()).replace(/\s/g, ``);
    return [
      `${firstName}.${lastName}@s.im`,
      `${firstName}${lastName}@s.im`,
      `${firstName}@s.im`,
      `${lastName}@s.im`,
      `${firstName[0]}${lastName}@s.im`,
      `${firstName[0]}.${lastName}@s.im`,
      `${firstName}${lastName[0]}@s.im`,
      `${firstName}.${lastName[0]}@s.im`,
      `${firstName[0]}${lastName[0]}@s.im`,
      `${firstName[0]}.${lastName[0]}@s.im`,
    ];
  };

  const buildUserForm = cells => {
    let [firstName, lastName, cpf, email, sectors, institutions, groups, sex, birthDate, admissionDate] = cells;
    if (!sex) sex = `O`;
    else if (sex.toLowerCase().startsWith(`m`)) sex = `M`;
    else if (sex.toLowerCase().startsWith(`f`)) sex = `F`;
    else sex = `O`;
    if (cpf) cpf = cpf.replace(/\D/g, ``);

    const data = {
      password: `${Math.random().toString(36)}00000000000000000`.slice(2, 8),
      firstName,
      lastName,
      cpf,
      email: email || getEmailSugestions(firstName, lastName)[0],
      userInstitution: institutions.split(`;`).join(`,`),
      sector: sectors.split(`;`).join(`,`),
      groups: groups ? groups.split(`;`).join(`,`) : [3],
      sex,
      birthDate,
      admissionDate,
      isActive: true,
      enableEmailNotifications: !!email,
      isFakeEmail: !email,
    };
    return data;
  };

  const buildUserFormFromExistingUser = (existingUser, newUserForm) => {
    let birthDate;
    if (existingUser.birthDate) {
      const dateParts = existingUser.birthDate.split(`-`);
      birthDate = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
    }

    const data = {
      firstName: existingUser.firstName,
      lastName: existingUser.lastName,
      email: existingUser.email,
      cpf: existingUser.cpf || newUserForm.cpf,
      userInstitution: newUserForm.userInstitution,
      sector: newUserForm.sector,
      groups: newUserForm.groups,
      sex: existingUser.sex || newUserForm.sex,
      birthDate: birthDate || newUserForm.birthDate,
      admissionDate: newUserForm.admissionDate,
      isActive: true,
      firebaseUid: existingUser.firebaseUid,
    };
    return data;
  };

  const getUserImportErrorDetail = error => {
    if (!error.response) {
      return JSON.stringify(error)
    }

    return JSON.stringify(error.response.data).replace(/,/g, `;`);
  };

  const checkEmail = async email => {
    try {
      const response = await api.get(`/account/check-email/?email=${encodeURIComponent(email)}`);
      return response.data;
    } catch (error) {
      if (error.response.status === 404) return false;
      throw error;
    }
  };

  const getNewFakeEmail = async userForm => {
    const sugestions = getEmailSugestions(userForm.firstName, userForm.lastName);
    for (let i = 1; i < sugestions.length; i++) {
      const email = sugestions[i];
      const emailExists = await checkEmail(email);
      if (!emailExists) {
        userForm.email = email;
        return;
      }
    }

    throw new Error(`Nenhuma sugestão de email aceita: ${sugestions}`)
  };

  const importUser = async userData => {
    const createUserData = buildUserForm(userData);
    const report = {};
    try {
      let response;
      const existingUser = await checkEmail(createUserData.email);
      if (!existingUser) {
        response = await api.post(`/account/users/`, createUserData);
        report.data = userData.concat(`CREATED`, response.data.id, createUserData.email, createUserData.password);
      } else if (!existingUser.inCurrentOrganization) {
        if (createUserData.isFakeEmail) {
          await getNewFakeEmail(createUserData);
          response = await api.post(`/account/users/`, createUserData);
          report.data = userData.concat(`CREATED`, response.data.id, createUserData.email, createUserData.password);
        } else {
          const addUserData = buildUserFormFromExistingUser(existingUser, createUserData);
          response = await api.post(`/account/users/add-to-organization/`, addUserData);
          report.data = userData.concat(`ADDED`, response.data.id, addUserData.email, ``);
        }
      } else {
        report.data = userData.concat(`EXISTS`, existingUser.id, existingUser.email, ``);
      }
      report.data[6] = createUserData.groups; // this may be overwritten if empty
      report.error = false;
    } catch (error) {
      console.error(`Error importing user:`, error.response || error);
      report.data = userData.concat(
        `ERROR`,
        ``,
        createUserData.email,
        createUserData.password,
        getUserImportErrorDetail(error),
      );
      report.error = true;
    }
    return report;
  };

  const saveImportReport = data => {
    console.log(`user import result:`, data);
    const reportHeader = `nome, sobrenome, cpf, email (entrada), setores, instituições, grupos, sexo, nascimento, admissão, status, ID, email (saída), senha\n`;
    const report = reportHeader + data.join(`\n`);
    saveFile(stringToBlob(report, `text/csv`), `importedUserData.csv`);
    setIsSaving(false);
    handleClose();
  };

  const importUsers = async text => {
    const table = text;
    const rows = table.split(`\n`);
    const importedUserData = [];
    let hasErrors = false;

    // ignore table header
    try {
      for (let i = 1; i < rows.length; i++) {
        const row = rows[i].trim();
        if (!row || row.startsWith(`#`)) continue;
        const cells = row.split(`,`).map(v => v.trim());
        const userReport = await importUser(cells);
        if (userReport.error) {
          hasErrors = true;
        }
        importedUserData.push(userReport.data.join(`,`));
      }
    } catch (error) {
      console.error(`Error importing users:`, error);
      dialog.alert({
        message: `Ocorreu um erro inesperado ao importar os usuários, por favor verifique o formato do arquivo de entrada ou contate o suporte\n\n${error}`,
        ok: { text: `Ok`, variant: `contained`, color: `primary` },
      });

      saveImportReport(importedUserData);
      return;
    }

    dialog.alert({
      message: `Importação concluída ${hasErrors ? `com` : `sem`} erros.`,
      ok: { text: `Ok`, variant: `contained`, color: `primary` },
    });

    saveImportReport(importedUserData);
  };

  const parseFile = () => {
    if (!srcFile) return;

    setIsSaving(true);
    const reader = new FileReader();

    reader.onload = event => importUsers(event.target.result);
    reader.addEventListener(`error`, () => {
      setIsSaving(false);
      dialog.alert({
        message: `Não foi possível abrir o arquivo ${srcFile.name}`,
        ok: { text: `Ok`, variant: `contained`, color: `primary` },
      });
    });

    reader.readAsText(srcFile);
  };

  return (
    <Dialog fullScreen={fullscreen} open={open} onClose={handleClose} aria-labelledby="addTaskForm" fullWidth>
      <DialogTitle className={classes.dialogTitle}>
        <Grid container justify="space-between" alignItems="stretch">
          <Grid container item alignContent="center">
            <Typography variant="h6">Importação de usuários</Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid container spacing={0} style={{ width: `100%` }}>
          <Typography>
            Selecione um arquivo para importar.
            <br />
            O arquivo deve ser no formato CSV com cabeçalho, e pode conter linhas em branco e comentários (linhas
            começando com <strong>&quot;#&quot;</strong>).
            <br />
            Campos com múltiplos valores (como <strong>&quot;Setores&quot;</strong>) devem ser separados por <strong>&quot;;&quot;</strong>.
            <br />
            Se o campo email for omitido, será criado um email falso (<strong>@s.im</strong>).
            <br />
            O CPF pode ser formatado ou não (<strong>01234567890</strong> ou <strong>012.345.678-90</strong>), e
            as datas devem ser no padrão pt-BR (<strong>D/M/YYYY</strong>). A coluna Grupo é opcional, e deve
            ser <strong>1</strong> (super-admin), <strong>2</strong> (admin) ou <strong>3</strong> (normal, padrão). A
            coluna Sexo não diferencia maiúsculas e minúsculas, e será interpretada como masculino se começar
            com <strong>M</strong>, feminino se começar com <strong>F</strong> ou "outro" caso contrário.
            <br />
            <br />
            Exemplo:
            <br />
            nome, sobrenome, cpf, email, setores, instituições, grupo, sexo, nascimento, admissão
            <br />
            Fulano, de Tal, 01234567890, , 1, 1;3, 3, masc, 30/3/1999, 1/2/2000
          </Typography>
        </Grid>
        <Grid container spacing={0} style={{ width: `100%` }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <label htmlFor="id_attachment">
              <Button
                variant="contained"
                color="default"
                component="span"
                className={classes.textField}
                style={{ marginTop: 10 }}
                disabled={isSaving}
              >
                Upload
                <CloudUpload className={classes.rightIcon} />
              </Button>
              <input
                className={classes.input}
                id="id_attachment"
                name="attachment"
                type="file"
                accept=".csv, text/csv, text/plain"
                onChange={event => setSrcFile(event.target.files[0])}
                disabled={isSaving}
              />
            </label>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {isSaving && (
          <Hidden only={[`xs`, `sm`]}>
            <Fade in style={{ transitionDelay: `800ms`, position: `absolute`, bottom: 15 }} unmountOnExit>
              <CircularProgress />
            </Fade>
          </Hidden>
        )}
        <>
          <Button size="small" color="secondary" disabled={isSaving} onClick={handleClose} style={{ marginRight: 10 }}>
            Cancelar
          </Button>
          <Button size="small" color="primary" disabled={isSaving} onClick={parseFile} variant="contained">
            Importar
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
};

DialogCsvImporter.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default withDialog()(withStyles(styles)(DialogCsvImporter));
