import React from 'react';
/**
 * Material UI - Core
 */
import { Dialog } from '@material-ui/core';
/**
 * My components
 */
import QCForm from './QCForm';

const DialogQCForm = ({ open, formInstance, dbname, handleClose, fullscreen }) => {
  return (
    <>
      <Dialog
        fullScreen={fullscreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="addQCForm"
        fullWidth
        maxWidth="md"
      >
        <QCForm formInstance={formInstance} dbname={dbname} handleClick={handleClose} />
      </Dialog>
    </>
  );
};

export default DialogQCForm;
