import React from 'react';
/**
 * Redux dependencies
 */
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
/**
 * Material UI - Core
 */
import { Button, CircularProgress, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
/**
 * Exif handler
 */
import * as loadImage from 'blueimp-load-image';
/**
 * Image Cropper
 */
import ReactCrop from 'react-image-crop';
import * as UserActions from '../../ReduxFlow/Reducers/Auth/Actions';
import api from '../../Services/api';

class AvatarDialog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      src: null,
      crop: {
        aspect: 1,
        width: 200,
        x: 0,
        y: 0,
      },
      isLoading: false,
      isSubmitting: false,
    };
    this.inputFileRef = React.createRef();
  }

  componentDidMount() {
    this.inputFileRef.current.click();
  }

  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      this.setState({ isLoading: true });
      const reader = new FileReader();
      reader.addEventListener(`load`, () =>
        loadImage(
          reader.result,
          canvas => {
            const image = canvas.toDataURL();
            this.setState({ src: image, isLoading: false });
          },
          { orientation: true },
        ),
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  onImageLoaded = (image, crop) => {
    this.imageRef = image;
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  onCropChange = crop => {
    this.setState({ crop });
  };

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement(`canvas`);
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext(`2d`);

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          // reject(new Error('Canvas is empty'))
          console.error(`Canvas is empty`); // eslint-disable-line
          return;
        }
        blob.name = fileName; // eslint-disable-line no-param-reassign
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(blob);
      }, `png`);
    });
  }

  handleSubmit = async event => {
    const { enqueueSnackbar, handleClose, user, updateAuthUserInfo } = this.props;
    try {
      this.setState({ isSubmitting: true });
      const { croppedImageUrl } = this.state;
      const { loggedUserInfo } = this.props;
      event.preventDefault();
      const data = new FormData();
      data.set(`avatar`, croppedImageUrl);
      data.set(`firebaseUid`, loggedUserInfo.user.firebaseUid);
      const response = await api.patch(`/account/users/${user}/change-avatar/`, data);
      enqueueSnackbar(`Avatar alterado com sucesso, talvez seja necessário recarregar a página para atualizá-lo`);
      this.setState({ isSubmitting: false });
      updateAuthUserInfo(response.data);
      handleClose();
    } catch (error) {
      console.error(error);
      this.setState({ isSubmitting: false });
      enqueueSnackbar(`Não foi possível salvar o seu avatar.`);
    }
  };

  async makeClientCrop(crop) {
    const { user } = this.props;
    if (this.imageRef && crop.width && crop.height) {
      const blob = await this.getCroppedImg(this.imageRef, crop, `newFile.jpeg`);
      const croppedImageUrl = new File([blob], `${user.firstName}.jpeg`);
      this.setState({ croppedImageUrl });
    }
  }

  render() {
    const { handleClose } = this.props;
    const { crop, src, isLoading, isSubmitting } = this.state;

    return (
      <>
        <input
          accept="image/*"
          multiple={false}
          onChange={this.onSelectFile}
          ref={this.inputFileRef}
          style={{ display: `none` }}
          type="file"
        />
        <DialogTitle id="avatar-dialog-title">Recorte da imagem</DialogTitle>
        <DialogContent style={{ textAlign: `center` }}>
          {isLoading && <CircularProgress style={{ position: `absolute`, top: `35%`, right: `40%` }} />}
          {src && (
            <ReactCrop
              src={src}
              crop={crop}
              onImageLoaded={this.onImageLoaded}
              onComplete={this.onCropComplete}
              onChange={this.onCropChange}
              imageStyle={{ maxHeight: `60vh`, margin: `auto` }}
              keepSelection
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button color="secondary" disabled={isLoading || isSubmitting} onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="contained" onClick={this.handleSubmit} disabled={isLoading || isSubmitting} color="primary">
            OK {(isLoading || isSubmitting) && <CircularProgress size={20} />}
          </Button>
        </DialogActions>
      </>
    );
  }
}

const mapStateToProps = state => ({
  loggedUserInfo: state.Auth.data,
});

const mapDispatchToProps = dispatch => bindActionCreators(UserActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withSnackbar(AvatarDialog));
