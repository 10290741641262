import React, { FC } from 'react';
import { Button, Grid, Hidden, IconButton, Tooltip, Typography } from '@material-ui/core';
import { ChevronLeft as ChevronLeftIcon, Close as CloseIcon } from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CustomTextField from 'Components/NewUIComponents/CustomTextField';
import SavingInfo from 'Components/NewUIComponents/TasksForms/SavingInfo';
import { TaskTypes, FormHeaderProps } from '../tasks-forms.interfaces';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonDark: {
      backgroundColor: theme.palette.primary.dark,
    },
    closeButton: {
      marginLeft: theme.spacing(2.5),
    },
    mobileCloseContainer: {
      minHeight: theme.spacing(11),
    },
    taskActions: {
      textAlign: `right`,
    },
    titleContainer: {
      width: `100%`,
    },
    titleTypography: {
      wordBreak: `break-all`,
      fontWeight: 600,
      letterSpacing: -0.475,
      lineHeight: 1.4,
      marginTop: 5,
    },
  }),
);

const FormHeader: FC<FormHeaderProps> = ({
  disableAllFields,
  disableCloseReopenButton,
  formHeaderFields,
  formHeaderTasksProps,
  fullScreen,
  handleCloseDialog,
  handleSaveEditing,
  handleToggleEditing,
  isEditingFields,
  setCloseReopenDialog,
}) => {
  const classes = useStyles();
  const { formInstance } = formHeaderTasksProps;

  function getReopenTooltipText(): string {
    if (formInstance.feedbackTime) return `Essa ouvidoria é somente leitura, pois já possui uma resposta de feedback`;
    return `Somente administradores, solicitantes e responsáveis podem reabrir a ouvidoria`;
  }

  return (
    <Grid container justify="space-between">
      <Grid item xs={12} md={7}>
        <Grid container direction="column">
          <Hidden smUp>
            <Grid
              className={classes.mobileCloseContainer}
              container
              alignContent="center"
              alignItems="center"
              justify="flex-start"
            >
              <ChevronLeftIcon fontSize="large" onClick={handleCloseDialog} />
            </Grid>
          </Hidden>
          <SavingInfo
            isMobile={fullScreen}
            isSaving={formHeaderTasksProps.isSaving}
            lastSaveDate={formInstance.updatedAt}
            taskId={formInstance.id}
            taskType={TaskTypes.OMBUDSMAN}
          />
          <Grid className={classes.titleContainer} item xs={12}>
            {isEditingFields.title ? (
              <CustomTextField
                cancelSaveAction={handleToggleEditing(`title`)}
                disabled={disableAllFields}
                error={!!formHeaderTasksProps.formErrors && !!formHeaderTasksProps.formErrors.title}
                fullWidth
                hasInstance
                helperText={!!formHeaderTasksProps.formErrors && formHeaderTasksProps.formErrors.title}
                id="id_title"
                isEditing={isEditingFields.title && !disableAllFields}
                label="Título"
                margin="dense"
                multiline
                name="title"
                onClick={!isEditingFields.title && !disableAllFields ? handleToggleEditing(`title`) : undefined}
                required
                saveAction={handleSaveEditing(`title`)}
                value={formHeaderFields.title}
              />
            ) : (
              <Typography className={classes.titleTypography} onClick={handleToggleEditing(`title`)} variant="h6">
                {formHeaderFields.title}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Hidden mdDown>
        <Grid className={classes.taskActions} item xs={12} md={5}>
          <Tooltip
            disableFocusListener={formInstance.isActive}
            disableHoverListener={formInstance.isActive}
            disableTouchListener={formInstance.isActive}
            title={getReopenTooltipText()}
          >
            <Button
              className={formInstance.isActive ? undefined : classes.buttonDark}
              color="primary"
              disabled={disableCloseReopenButton}
              onClick={(): void => setCloseReopenDialog(true)}
              variant="contained"
            >
              {formInstance.isActive ? `Marcar como concluída` : `Reabrir`}
            </Button>
          </Tooltip>
          <IconButton className={classes.closeButton} onClick={handleCloseDialog} size="small">
            <CloseIcon fontSize="small" />
          </IconButton>
        </Grid>
      </Hidden>
    </Grid>
  );
};

export default FormHeader;
