import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from '@material-ui/core';
import { VisibilityOutlined } from '@material-ui/icons';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import api from '../../Services/api';
import firebase from '../../Utils/firebaseConfig';

const useStyles = theme =>
  makeStyles({
    actionsRoot: {
      alignItems: `center`,
      display: `flex`,
      flex: `0 0 auto`,
      justifyContent: `flex-end`,
      padding: `16px 48px 48px 40px`,
    },
    actionsSpacing: {
      '& > :not(:first-child)': {
        marginLeft: 16,
      },
    },
    button: {
      font: `Medium 14px/19px Hind`,
      letterSpacing: 1.25,
      minWidth: 105,
      opacity: 1,
    },
    buttonText: {
      whiteSpace: `nowrap`,
    },
    cancelButton: {
      color: `#767676`,
    },
    dialogActions: {
      padding: `16px 48px 48px 40px`,
    },
    dialogContent: {
      padding: `0px 48px 0px 40px`,
      overflowY: `hidden`,
    },
    dialogTitle: {
      padding: `40px 48px 32px 40px`,
    },
    disabledButton: {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: `${theme.palette.primary.contrastText} !important`,
    },
    textField: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
      width: `100%`,
    },
  });

const ChangePassword = ({
  adminEditing,
  closePasswordDialog,
  enqueueSnackbar,
  firebaseUid,
  formInstanceId,
  passwordOpen,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme)();
  const [errors, setErrors] = useState({
    currentPassword: null,
    newPassword: null,
    repeatNewPassword: null,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [passwords, setPasswords] = useState({
    currentPassword: ``,
    newPassword: ``,
    repeatNewPassword: ``,
  });
  const [showPassword, setShowPassword] = useState({
    currentPassword: false,
    passwords: false,
  });

  const { currentPassword, newPassword, repeatNewPassword } = passwords;
  const {
    currentPassword: currentPasswordError,
    newPassword: newPasswordError,
    repeatNewPassword: repeatNewPasswordError,
  } = errors;

  const handleChange = name => event => {
    event.persist();
    const { value } = event.target;
    setPasswords(prevPasswords => ({ ...prevPasswords, [name]: value }));
    switch (true) {
      case name === `newPassword` && repeatNewPassword !== value:
        setErrors(prevErrors => ({
          ...prevErrors,
          newPassword: `Senhas não conferem`,
        }));
        break;
      case name === `repeatNewPassword` && newPassword !== value:
        setErrors(prevErrors => ({
          ...prevErrors,
          repeatNewPassword: `Senhas não conferem`,
        }));
        break;
      default:
        setErrors({
          currentPassword: null,
          newPassword: null,
          repeatNewPassword: null,
        });
        break;
    }
  };

  async function patchUserPassword() {
    try {
      await api.patch(`/account/users/${formInstanceId}/change-password/`, {
        firebaseUid,
        password: newPassword,
      });
      setIsSubmitting(false);
      enqueueSnackbar(`Senha alterada com sucesso`);
      closePasswordDialog();
    } catch (error) {
      console.error(error);
      enqueueSnackbar(`Não foi possível alterar a senha`);
      setIsSubmitting(false);
    }
  }

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const user = firebase.auth().currentUser;
    if (!adminEditing) {
      firebase
        .auth()
        .signInWithEmailAndPassword(user.email, currentPassword)
        .then(async () => {
          await patchUserPassword();
        })
        .catch(error => {
          console.error(error);
          setIsSubmitting(false);
          setErrors(prevErrors => ({
            ...prevErrors,
            currentPassword: `Senha incorreta`,
          }));
        });
    } else {
      await patchUserPassword();
    }
  };

  const currentInputType = showPassword.currentPassword ? `text` : `password`;
  const currentIconColor = showPassword.currentPassword ? `primary` : `inherit`;
  const passwordsInputType = showPassword.passwords ? `text` : `password`;
  const passwordsIconColor = showPassword.passwords ? `primary` : `inherit`;
  const changeInputType = name => () => setShowPassword(prevState => ({ ...prevState, [name]: !prevState[name] }));

  return (
    <Dialog aria-labelledby="changePassword" open={passwordOpen} onClose={closePasswordDialog} fullWidth maxWidth="xs">
      <DialogTitle className={classes.dialogTitle}>Alterar senha</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {!adminEditing && (
          <TextField
            autoComplete="off"
            className={classes.textField}
            error={!!currentPasswordError}
            id="id_currentPassword"
            InputProps={{
              endAdornment: (
                <IconButton onClick={changeInputType(`currentPassword`)}>
                  <VisibilityOutlined color={currentIconColor} />
                </IconButton>
              ),
            }}
            helperText={currentPasswordError || null}
            label="Senha atual"
            onChange={handleChange(`currentPassword`)}
            required
            type={currentInputType}
            value={currentPassword}
            variant="outlined"
            name="currentPassword"
          />
        )}
        <TextField
          autoComplete="off"
          className={classes.textField}
          error={!!newPasswordError}
          id="id_newPassword"
          InputProps={{
            endAdornment: (
              <IconButton onClick={changeInputType(`passwords`)}>
                <VisibilityOutlined color={passwordsIconColor} />
              </IconButton>
            ),
          }}
          helperText={newPasswordError || null}
          label="Nova senha"
          onChange={handleChange(`newPassword`)}
          required
          type={passwordsInputType}
          value={newPassword}
          variant="outlined"
          name="newPassword"
        />
        <TextField
          autoComplete="off"
          className={classes.textField}
          error={!!repeatNewPasswordError}
          id="id_repeatNewPassword"
          InputProps={{
            endAdornment: (
              <IconButton onClick={changeInputType(`passwords`)}>
                <VisibilityOutlined color={passwordsIconColor} />
              </IconButton>
            ),
          }}
          helperText={repeatNewPasswordError || null}
          label="Confirme a nova senha"
          onChange={handleChange(`repeatNewPassword`)}
          required
          type={passwordsInputType}
          value={repeatNewPassword}
          variant="outlined"
          name="repeatNewPassword"
        />
      </DialogContent>
      <DialogActions
        classes={{ root: classes.actionsRoot, spacing: classes.actionsSpacing }}
        className={classes.dialogActions}
      >
        <Button
          className={`${classes.button} ${classes.cancelButton}`}
          disabled={isSubmitting}
          onClick={closePasswordDialog}
        >
          Cancelar
        </Button>
        <Button
          classes={{ disabled: classes.disabledButton }}
          className={classes.button}
          color="primary"
          disabled={isSubmitting}
          onClick={handleSubmit}
          variant="contained"
        >
          {isSubmitting ? <CircularProgress color="inherit" size={24} /> : `Salvar`}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ChangePassword.defaultProps = {
  adminEditing: false,
};

ChangePassword.propTypes = {
  adminEditing: PropTypes.bool,
  closePasswordDialog: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  firebaseUid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  formInstanceId: PropTypes.number.isRequired,
  passwordOpen: PropTypes.bool.isRequired,
};

export default withSnackbar(ChangePassword);
