import React from 'react';
import { ListItem } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const DrawerSkeleton = () => {
  return (
    <>
      {[...Array(12).keys()].map(val => (
        <ListItem key={val}>
          <Skeleton animation="wave" height={48} variant="rect" width={240} />
        </ListItem>
      ))}
    </>
  );
};

export default DrawerSkeleton;
