import React, { useState } from 'react';
/**
 * SwipeableViews dependency
 */
import SwipeableViews from 'react-swipeable-views';
/**
 * Material UI - Core
 */
import { BottomNavigation, BottomNavigationAction, Hidden, Tab, Tabs } from '@material-ui/core';
/**
 * Material UI - Icons
 */
import { Timeline, VideogameAsset } from '@material-ui/icons';
/**
 * My components
 */
import Activities from './Activities';
import Score from './Score';

const MainActivities = props => {
  const { avatar, filterValue, handleFetchStatus, handleMenuActive, loadRequest } = props;
  const [value, setValue] = useState(0);

  return (
    <>
      <Hidden xsDown>
        <Tabs
          value={value}
          onChange={(event, val) => {
            setValue(val);
          }}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="Atividades" icon={<Timeline />} />
          <Tab label="Score" icon={<VideogameAsset />} />
        </Tabs>
      </Hidden>
      <SwipeableViews
        containerStyle={
          window.innerWidth < 600
            ? { height: `calc(100vh - 112px)` }
            : { height: `calc(100vh - 184px)` }
        }
        index={value}
        onChangeIndex={index => {
          setValue(index);
        }}
      >
        <Activities
          avatar={avatar}
          handleMenuActive={handleMenuActive}
          handleLoadRequest={loadRequest}
          filterValue={filterValue}
          handleFetchStatus={handleFetchStatus}
        />
        <Score avatar={avatar} />
      </SwipeableViews>
      <Hidden smUp>
        <BottomNavigation
          value={value}
          onChange={(event, val) => {
            setValue(val);
          }}
          showLabels
        >
          <BottomNavigationAction label="Atividades" icon={<Timeline />} />
          <BottomNavigationAction label="Score" icon={<VideogameAsset />} />
        </BottomNavigation>
      </Hidden>
    </>
  );
};

export default MainActivities;
