import React, { FormEvent, SFC } from 'react';
import { TextField } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { SimpleCategoryDialog } from '../../ReduxFlow/Reducers/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      marginBottom: theme.spacing(3.25),
      width: `98%`,
    },
  }),
);

type SimpleCategoryFormProps = {
  categoryData: SimpleCategoryDialog;
  handleFieldChange: Function;
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
};

const SimpleCategoryForm: SFC<SimpleCategoryFormProps> = ({ categoryData, handleFieldChange, onSubmit }) => {
  const classes = useStyles();
  const { name, description } = categoryData;

  return (
    <form id="docsCategoriesForm" onSubmit={onSubmit}>
      <TextField
        autoFocus
        className={classes.textField}
        id="id_name"
        label="Nome da categoria"
        name="name"
        onChange={handleFieldChange(`name`)}
        value={name}
        variant="outlined"
      />
      <TextField
        className={classes.textField}
        helperText="Dica: Descreva as características dos itens desta categoria"
        id="id_description"
        label="Descrição da categoria"
        name="description"
        onChange={handleFieldChange(`description`)}
        value={description}
        variant="outlined"
      />
    </form>
  );
};

const DocsCategoriesFormTests = SimpleCategoryForm;

export { DocsCategoriesFormTests };

export default SimpleCategoryForm;
