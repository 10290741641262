import { CallAndPreventiveBase, CommonTaskInstance, CommonTaskTable, SlaKpis, SituationTypes } from '../types';
/**
 * Action types
 */
export enum TasksTypes {
  REPORT_REQUEST = '@Tasks/REPORT_REQUEST',   // eslint-disable-line
  REPORT_SUCCESSFULL = '@Tasks/REPORT_SUCCESSFULL',   // eslint-disable-line
  LOAD_REQUEST = '@Tasks/LOAD_REQUEST',   // eslint-disable-line
  LOAD_SUCCESS = '@Tasks/LOAD_SUCCESS',   // eslint-disable-line
  LOAD_FAILURE = '@Tasks/LOAD_FAILURE',   // eslint-disable-line
  OPEN_REQUEST = '@Tasks/OPEN_REQUEST',   // eslint-disable-line
  OPEN_SUCCESS = '@Tasks/OPEN_SUCCESS',   // eslint-disable-line
  CLOSE_REQUEST = '@Tasks/CLOSE_REQUEST',   // eslint-disable-line
  CLOSE_SUCCESS = '@Tasks/CLOSE_SUCCESS',   // eslint-disable-line
  CONVERT_TASK_TO_CALL = '@Tasks/CONVERT_TASK_TO_CALL',   // eslint-disable-line
  CONVERT_CALL_TO_TASK = '@Tasks/CONVERT_CALL_TO_TASK',   // eslint-disable-line
  CHANGE_SORT = '@Tasks/CHANGE_SORT',   // eslint-disable-line
  CHANGE_FILTERS = '@Tasks/CHANGE_FILTERS',   // eslint-disable-line
  CHANGE_PAGES = '@Tasks/CHANGE_PAGES',   // eslint-disable-line
  LOAD_ERRORS = '@Tasks/LOAD_ERRORS',   // eslint-disable-line
  LOAD_KPIS_SUSUCCESS = '@Tasks/LOAD_KPIS_SUSUCCESS',   // eslint-disable-line
}
/**
 * Data types
 */
export type Task = CommonTaskInstance &
  CommonTaskTable & {
    call?: CallAndPreventiveBase & {
      situation: SituationTypes;
    };
    nc?: {
      id: number;
      ncAction: string;
      ncCause: string;
      ncCorrectiveAction: string;
      ncDescription: string;
      ncEfficiency: string;
      ncObservations: string;
      ncType: string;
    };
    preventive?: CallAndPreventiveBase & {
      location?: string;
    };
  };
/**
 * State types
 */
export interface TasksState {
  readonly count: number;
  readonly data: Task[];
  readonly filterList: string[][];
  readonly formInstance: any[];
  readonly isLoading: boolean;
  readonly isConvertingTaskToCall: boolean;
  readonly menuType?: string;
  readonly modalOpen: boolean;
  readonly orphans: number;
  readonly page: number;
  readonly rowsPerPage: number;
  readonly searchText?: string;
  readonly slaKpis?: SlaKpis;
  readonly sort?: { [key: number]: { order: string }; [key: string]: { order: string } };
}
