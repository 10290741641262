import React, { FC, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button, Grid, Typography } from '@material-ui/core';
import { FileCopy } from '@material-ui/icons';

import { MyTimePickerStyles } from '../MyDatePicker';
import { SchedulleInterval, WorkSchedulle } from '../../ReduxFlow/Reducers/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    copyButton: {
      marginLeft: `auto`,
    },
    dayPickerLine: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    daySchedulleLine: {
      alignItems: `center`,
      display: `flex`,
    },
    weekdayButton: {
      borderRadius: theme.spacing(3),
      height: theme.spacing(4),
      margin: theme.spacing(0.5),
      minHeight: theme.spacing(4),
      minWidth: theme.spacing(4),
      width: theme.spacing(4),
    },
  }),
);

interface SchedulleError {
  end?: boolean;
  start?: boolean;
}

export interface ValidationResult {
  valid: boolean;
  weekdays: {
    0?: SchedulleError;
    1?: SchedulleError;
    2?: SchedulleError;
    3?: SchedulleError;
    4?: SchedulleError;
    5?: SchedulleError;
    6?: SchedulleError;
  };
}

type WeekdayValues = `6` | `0` | `1` | `2` | `3` | `4` | `5`;

const weekdays = [
  { key: `6`, buttonLabel: `D`, inputLabel: `Domingo` },
  { key: `0`, buttonLabel: `S`, inputLabel: `Segunda-feira` },
  { key: `1`, buttonLabel: `T`, inputLabel: `Terça-feira` },
  { key: `2`, buttonLabel: `Q`, inputLabel: `Quarta-feira` },
  { key: `3`, buttonLabel: `Q`, inputLabel: `Quinta-feira` },
  { key: `4`, buttonLabel: `S`, inputLabel: `Sexta-feira` },
  { key: `5`, buttonLabel: `S`, inputLabel: `Sabado` },
];

const timeStringToDate = (timeString: string): Date | null => {
  if (!timeString) return null;
  const today = new Date();
  return new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate(),
    parseInt(timeString.slice(0, 2), 10),
    parseInt(timeString.slice(3, 5), 10),
    0,
  );
};

const dateToTimeString = (date?: Date): string =>
  date ? date.toLocaleTimeString(`en-US`, { hour12: false, hour: `numeric`, minute: `numeric` }) : ``;

const validateSchedulle = (
  key: string,
  value: SchedulleInterval | undefined,
): { [key: string]: SchedulleError } | undefined => {
  if (!value) return undefined;
  const validation: SchedulleError = {};
  const start = timeStringToDate(value.start);
  const end = timeStringToDate(value.end);
  // eslint-disable-next-line no-restricted-globals
  if (!start || isNaN(start.getTime())) validation.start = true;
  // eslint-disable-next-line no-restricted-globals
  if (!end || isNaN(end.getTime())) validation.end = true;
  if (!validation.end && !validation.start) {
    if (end && start && end <= start) {
      validation.end = true;
      validation.start = true;
    } else {
      return undefined;
    }
  }
  return { [key]: validation };
};

export const validate = (value: WorkSchedulle): ValidationResult => {
  const emptyValidation: ValidationResult = { weekdays: {}, valid: true };
  if (!value || !value.weekdays) {
    return { ...emptyValidation, valid: false };
  }

  const validation: ValidationResult = Object.entries(value.weekdays)
    .map(([day, schedulle]: [string, SchedulleInterval | undefined]) => validateSchedulle(day, schedulle))
    .reduce((acc, v) => {
      acc.weekdays = Object.assign(acc.weekdays, v);
      return acc;
    }, emptyValidation);
  validation.valid = Object.keys(validation.weekdays).length === 0;
  return validation;
};

interface WorkSchedullePickerProps {
  onChange: (value: WorkSchedulle) => void;
  validation: ValidationResult;
  value?: WorkSchedulle;
}

const WorkSchedullePicker: FC<WorkSchedullePickerProps> = ({ onChange, validation, value: startingValue }) => {
  const [value, setValue] = useState(startingValue && startingValue.weekdays ? startingValue.weekdays : {});
  const classes = useStyles();
  const enabledDays = weekdays.filter(({ key }) => value[key as WeekdayValues]);

  const setValueAndNotifyChange = (newValue: typeof value): void => {
    setValue(newValue);
    onChange({ weekdays: newValue });
  };

  const toggleDay = (day: WeekdayValues) => (): void => {
    if (value[day]) {
      const clone = { ...value };
      delete clone[day];
      setValueAndNotifyChange(clone);
    } else {
      setValueAndNotifyChange({ ...value, [day]: { start: ``, end: `` } });
    }
  };

  const setStartTime = (day: WeekdayValues) => (date: Date): void => {
    const newDay = { ...value[day], start: dateToTimeString(date) };
    setValueAndNotifyChange({ ...value, [day]: newDay });
  };

  const setEndTime = (day: WeekdayValues) => (date: Date): void => {
    const newDay = { ...value[day], end: dateToTimeString(date) };
    setValueAndNotifyChange({ ...value, [day]: newDay });
  };

  const copySchedule = (): void => {
    const daySchedulle = value[enabledDays[0].key as WeekdayValues];
    if (!daySchedulle) return;
    const { end, start } = daySchedulle;
    const newValue: typeof value = {};
    // eslint-disable-next-line no-return-assign
    enabledDays.forEach(v => (newValue[v.key as WeekdayValues] = { end, start }));
    setValueAndNotifyChange(newValue);
  };

  const getDayButtonColor = (day: WeekdayValues): `primary` | `default` => {
    if (value[day]) return `primary`;
    return `default`;
  };

  const isInvalidTime = (day: WeekdayValues, type: `end` | `start`): boolean | undefined => {
    const schedulle = validation && validation.weekdays[day];
    return schedulle && schedulle[type];
  };

  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Typography variant="body2">Selecione os dias de trabalho</Typography>
      </Grid>
      <Grid item xs={12}>
        {weekdays.map(({ buttonLabel, key }) => (
          <Button
            className={classes.weekdayButton}
            color={getDayButtonColor(key as WeekdayValues)}
            disableElevation
            key={key}
            onClick={toggleDay(key as WeekdayValues)}
            size="small"
            variant="contained"
          >
            {buttonLabel}
          </Button>
        ))}
      </Grid>
      {enabledDays.map(({ inputLabel, key }, index) => {
        const day = key as WeekdayValues;
        const daySchedulle = value[day];
        if (!daySchedulle) return <></>;
        const endTime = daySchedulle.end;
        const startTime = daySchedulle.start;
        return (
          <Grid item xs={12} key={key}>
            <Grid container direction="row" className={classes.daySchedulleLine}>
              <Grid item xs={4}>
                <Typography>{inputLabel}</Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography align="center">das</Typography>
              </Grid>
              <Grid item xs={3}>
                <MyTimePickerStyles
                  width=""
                  autoFocus={undefined}
                  autoOk={undefined}
                  disabled={false}
                  onClose={undefined}
                  minDate={undefined}
                  error={isInvalidTime(day, `start`)}
                  minDateMessage=""
                  placeholder=""
                  required
                  handleDateChange={setStartTime(day)}
                  keyboard
                  label=""
                  margin="normal"
                  name="startTime"
                  value={timeStringToDate(startTime)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={1}>
                <Typography align="center">às</Typography>
              </Grid>
              <Grid item xs={3}>
                <MyTimePickerStyles
                  width=""
                  autoFocus={undefined}
                  autoOk={undefined}
                  disabled={false}
                  onClose={undefined}
                  minDate={undefined}
                  error={isInvalidTime(day, `end`)}
                  minDateMessage=""
                  placeholder=""
                  required
                  handleDateChange={setEndTime(day)}
                  keyboard
                  label=""
                  margin="normal"
                  name="endTime"
                  value={timeStringToDate(endTime)}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            {index === 0 && enabledDays.length > 1 && (
              <div style={{ display: `flex` }}>
                <Button color="primary" onClick={copySchedule} startIcon={<FileCopy />} className={classes.copyButton}>
                  Copiar para Todos
                </Button>
              </div>
            )}
          </Grid>
        );
      })}
    </Grid>
  );
};

export default WorkSchedullePicker;
