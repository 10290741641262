import React from 'react';
import PropTypes from 'prop-types';
import { DialogContent, TextField, Typography } from '@material-ui/core';

const FirstStep = ({ classes, email, emailError, onChange }) => {
  const hasError = !!emailError;

  return (
    <DialogContent>
      <Typography align="justify" className={classes.emailText}>
        Informe o e-mail do usuário a ser criado. Caso o usuário não possua um, deixe o campo em branco e clique em
        <strong> Avançar</strong>.
      </Typography>
      <TextField
        error={hasError}
        fullWidth
        helperText={hasError ? emailError : null}
        id="id_email"
        label="E-mail"
        name="email"
        onChange={onChange}
        type="email"
        value={email}
        variant="outlined"
      />
    </DialogContent>
  );
};

FirstStep.defaultProps = {
  email: ``,
  emailError: ``,
};

FirstStep.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  email: PropTypes.string,
  emailError: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export const TestFirstStep = FirstStep;

export default FirstStep;
