import React, { FC } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export enum DotTypeEnum {
  SUCCESS = 'green', // eslint-disable-line
  ERROR = 'red', // eslint-disable-line
  WARNING = 'yellow',  // eslint-disable-line
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dot: {
      borderRadius: `50%`,
      display: `inline-block`,
    },
    green: {
      backgroundColor: theme.palette.success.main,
    },
    red: {
      backgroundColor: theme.palette.error.main,
    },
    yellow: {
      backgroundColor: theme.palette.warning.main,
    },
  }),
);

interface DotProps {
  dotType: DotTypeEnum;
  marginLeft?: number;
  marginRight?: number;
  sizes?: {
    height: number;
    width: number;
  };
}

const Dot: FC<DotProps> = ({ dotType, marginLeft, marginRight, sizes = { height: 12, width: 12 } }) => {
  const classes = useStyles();

  return <span className={`${classes.dot} ${classes[dotType]}`} style={{ marginLeft, marginRight, ...sizes }} />;
};

export default Dot;
