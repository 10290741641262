import React from 'react';
/**
 * Redux dependencies
 */
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
/**
 * Material UI - Core
 */
import {
  Badge,
  Box,
  Button,
  Chip,
  Hidden,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
/**
 * Material UI - Icons
 */
import { Add, Check, Error as ErrorIcon, RateReview } from '@material-ui/icons';
// import { ErrorIcon } from '@material-ui/icons/Error';
/**
 * MUI-Datatable
 */
import MUIDataTable from 'mui-datatables';
/**
 * InternalDocs actions
 */
import * as internalDocsActions from '../../ReduxFlow/Reducers/InternalDocs/Actions';
/**
 * My components
 */
import api from '../../Services/api';
import DialogIntDocsForm from './DialogIntDocsForm';
import MobileInternalDocs from './MobileInternalDocs';
import { buildQueryStringFromObject, saveFile } from '../../Utils';
import * as muiDatatableHelper from '../../Utils/muiDatatableHelper';
import RangePicker from '../../Components/RangePicker';

const styles = theme => ({
  smRecipient: {
    height: `calc(100vh - 56px)`,
    overflowY: `auto`,
  },
  button: {
    position: `relative`,
    top: 0,
    left: 0,
  },
  institutionAvatar: {
    borderRadius: `50%`,
    transition: `transform .3s`,
    '&:hover': {
      transform: `scale(3)`,
    },
  },
  markerRoot: {
    fontSize: 12,
    height: 15,
  },
  markerChildren: {
    padding: `0 6px 0 6px`,
  },
});

class InternalDocs extends React.PureComponent {
  componentDidMount() {
    const {
      changeInternalDocsFilters,
      filterValue,
      handleFetchStatus,
      history,
      loadInternalDocsRequest,
      loggedUserInfo,
      openInternalDocsRequest,
    } = this.props;
    const { isLoadingUI } = loggedUserInfo;
    const { currentOrganization } = loggedUserInfo.data;
    const localStorageOrg = window.localStorage.getItem(`currentOrganization`);
    if (localStorageOrg === JSON.stringify(currentOrganization) && !isLoadingUI) {
      if (filterValue && filterValue.status) {
        changeInternalDocsFilters({
          filterList: { isActive: [`Ativo`] },
          searchText: filterValue.value,
        });
        handleFetchStatus();
      } else {
        loadInternalDocsRequest();
      }
      const parameters = new URLSearchParams(history.location.search);
      if (parameters.get(`id`)) {
        history.replace(history.location.pathname);
        openInternalDocsRequest({ id: parameters.get(`id`) });
      }
    }
  }

  componentDidUpdate() {
    const { changeInternalDocsFilters, filterValue, handleFetchStatus } = this.props;
    if (filterValue.status) {
      changeInternalDocsFilters({
        filterList: { isActive: [`Ativo`] },
        searchText: filterValue.value,
      });
      handleFetchStatus();
    }
  }

  handleScroll = event => {
    event.stopPropagation();
    const { changeInternalDocsFilters, changeInternalDocsPages, propsInternalDocs, filterValue } = this.props;
    clearTimeout(this.scrollTimeout);
    if (
      propsInternalDocs.data.length < propsInternalDocs.count &&
      event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight
    ) {
      this.scrollTimeout = setTimeout(() => {
        if (filterValue.value) {
          changeInternalDocsFilters({
            filterList: { isActive: [`Ativo`] },
            searchText: filterValue.value,
          });
        } else {
          changeInternalDocsPages({ rowsPerPage: InternalDocs.rowsPerPage + 15, page: 0 });
        }
      }, 200);
    }
  };

  render() {
    const {
      avatar,
      changeInternalDocsFilters,
      changeInternalDocsPages,
      changeInternalDocsSort,
      classes,
      closeInternalDocsRequest,
      closeInternalDocsSuccess,
      propsInternalDocs,
      Institutions,
      loadInternalDocsRequest,
      Sectors,
      openInternalDocsRequest,
    } = this.props;

    const columns = [
      {
        filterName: `title`,
        label: `Título`,
        name: `tableTitle`,
        options: {
          filter: true,
          filterList: propsInternalDocs.filterList.title,
          customFilterListRender: v => `Tíutlo: ${v}`,
          customBodyRender: value => {
            const { id, title, status } = value;
            let chipStyle;
            switch (status) {
              case `Pendente de aprovação`:
              case `Pendente de elaboração`:
              case `Pendente de revisão`:
                chipStyle = { color: `#fff`, backgroundColor: `#ffc107` };
                break;
              case `Vencido`:
                chipStyle = { color: `inherit`, backgroundColor: `#e0e0e0` };
                break;
              default:
                chipStyle = { color: `#fff`, backgroundColor: `#28a745` };
            }
            return (
              <>
                <Typography>{title}</Typography>
                <Box alignItems="center" display="flex" flexDirection="row" justifyContent="space-between">
                  <Typography color="textSecondary" style={{ marginRight: 5 }}>
                    <small>{`#${id}`}</small>
                  </Typography>
                  <Chip
                    classes={{ label: classes.markerChildren }}
                    className={classes.markerRoot}
                    component="span"
                    label={status}
                    style={chipStyle}
                    // style={{ color: val[2], backgroundColor: val[3] }}
                  />
                </Box>
              </>
            );
          },
        },
      },
      muiDatatableHelper.buildInstitutionColumn(Institutions, propsInternalDocs.filterList.institutions),
      {
        filterName: `category`,
        label: `Categoria`,
        name: `tableCategory`,
        options: {
          filter: true,
          filterList: propsInternalDocs.filterList.category,
          customFilterListRender: v => `Categoria: ${v}`,
        },
      },
      {
        filterName: `validity`,
        label: `Validade`,
        name: `validity`,
        options: {
          filter: true,
          filterList: propsInternalDocs.filterList.validity,
          filterType: `custom`,
          customBodyRender: value => {
            return value
              ? new Date(`${value}T00:01`).toLocaleString([`pt-br`], {
                  year: `numeric`,
                  month: `2-digit`,
                  day: `2-digit`,
                })
              : `Indeterminado`;
          },
          customFilterListRender: v => {
            if (v.length) {
              return `Datas: ${v[0].toLocaleDateString(`pt-br`)} - ${v[1].toLocaleDateString(`pt-br`)}`;
            }
            return false;
          },
          filterOptions: {
            names: [],
            display: (filterList, onChange, index, column) => {
              return (
                <RangePicker
                  startDate={filterList[index].length ? filterList[index][0] : undefined}
                  endDate={filterList[index].length ? filterList[index][1] : undefined}
                  label="Validade"
                  handleSelect={event => {
                    if (event.eventType === 3) {
                      onChange([event.start, event.end], index, column);
                    }
                  }}
                />
              );
            },
          },
        },
      },
      {
        label: `Versão`,
        name: `tableTitle.version`,
        options: {
          filter: false,
          filterList: propsInternalDocs.filterList.version,
        },
      },
      {
        filterName: `owner`,
        label: `Elaborador`,
        name: `tableOwner`,
        options: {
          filter: true,
          filterList: propsInternalDocs.filterList.owner,
          customFilterListRender: v => `Elaborador: ${v}`,
        },
      },
      {
        label: `Id`,
        name: `tableTitle.id`,
        options: {
          filter: false,
          display: `excluded`,
        },
      },
      {
        filterName: `proccess`,
        label: `Processo`,
        name: `proccess`,
        options: {
          display: `excluded`,
          filter: true,
          filterList: propsInternalDocs.filterList.proccess,
          filterType: `multiselect`,
          filterOptions: {
            names: Sectors.map(sector => sector.name),
          },
          customFilterListRender: v => `Processo: ${v}`,
        },
      },
      {
        filterName: `documentStatus`,
        label: `Situação`,
        name: `tableTitle.status`,
        options: {
          display: `excluded`,
          filter: true,
          filterList: propsInternalDocs.filterList.documentStatus,
          filterOptions: {
            names: [`Pendente de aprovação`, `Pendente de elaboração`, `Pendente de revisão`, `Vencido`, `Vigente`],
          },
          filterType: `dropdown`,
          customFilterListRender: v => `Situação: ${v}`,
        },
      },
      {
        filterName: `isActive`,
        label: `Ativo`,
        name: `is_active`,
        options: {
          display: `excluded`,
          filter: true,
          filterList: propsInternalDocs.filterList.isActive,
          filterType: `dropdown`,
          filterOptions: {
            names: [`Ativo`, `Inativo`],
          },
          customFilterListRender: v => `Ativo: ${v}`,
        },
      },
      {
        filterName: `hasValidity`,
        label: `Vencidos ou a vencer`,
        name: `hasValidity`,
        options: {
          display: `excluded`,
          filter: false,
          filterList: propsInternalDocs.filterList.hasValidity,
          filterOptions: {
            names: [`Sim`, `Não`],
          },
          filterType: `dropdown`,
          customFilterListRender: v => `Vencidos ou a vencer`,
        },
      },
    ];
    const options = {
      rowsPerPage: propsInternalDocs.rowsPerPage,
      viewColumns: false,
      print: false,
      fixedHeader: true,
      selectableRows: `none`,
      filter: true,
      filterType: `textField`,
      responsive: `stacked`,
      serverSide: true,
      count: propsInternalDocs.count,
      page: propsInternalDocs.page,
      searchText: propsInternalDocs.searchText,
      customToolbar: () => {
        const { approvePending, expiredOrExpiring, page, reviewPending, searchText } = propsInternalDocs;
        return (
          <>
            <Tooltip title="Para revisar">
              <IconButton
                onClick={() =>
                  changeInternalDocsFilters({
                    page,
                    searchText,
                    filterList: {
                      documentStatus: [`Pendente de revisão`],
                      isActive: [`Ativo`],
                    },
                  })
                }
              >
                <Badge badgeContent={reviewPending} color="secondary" style={{ marginRight: 10 }}>
                  <RateReview />
                </Badge>
              </IconButton>
            </Tooltip>
            <Tooltip title="Para aprovar">
              <IconButton
                onClick={() =>
                  changeInternalDocsFilters({
                    page,
                    searchText,
                    filterList: {
                      documentStatus: [`Pendente de aprovação`],
                      isActive: [`Ativo`],
                    },
                  })
                }
              >
                <Badge badgeContent={approvePending} color="secondary" style={{ marginRight: 10 }}>
                  <Check />
                </Badge>
              </IconButton>
            </Tooltip>
            <Tooltip title="Vencidos ou a vencer">
              <IconButton
                onClick={() =>
                  changeInternalDocsFilters({
                    page,
                    searchText,
                    sort: { 3: { order: `asc` } },
                    filterList: {
                      hasValidity: [true],
                      isActive: [`Ativo`],
                    },
                  })
                }
              >
                <Badge badgeContent={expiredOrExpiring} color="secondary" style={{ marginRight: 10 }}>
                  <ErrorIcon />
                </Badge>
              </IconButton>
            </Tooltip>
            <Tooltip title="Adicionar documento">
              <Button
                aria-label="Adicionar"
                aria-owns="addMenu"
                variant="contained"
                color="primary"
                onClick={event => openInternalDocsRequest({ id: null })}
              >
                <Add /> Adicionar
              </Button>
            </Tooltip>
          </>
        );
      },
      textLabels: {
        body: {
          noMatch: `Desculpe, nenhum registro encontrado!`,
          toolTip: `Ordenar`,
        },
        pagination: {
          next: `Próxima página`,
          previous: `Págima anterior`,
          rowsPerPage: `Linhas por página:`,
          displayRows: `de`,
        },
        toolbar: {
          search: `Procurar`,
          downloadCsv: `Download CSV`,
          print: `Imprimir`,
          viewColumns: `Colunas visíveis`,
          filterTable: `Filtrar a tabela`,
        },
        filter: {
          title: `Filtros`,
          reset: `Resetar`,
        },
        viewColumns: {
          title: `Mostrar colunas`,
          titleAria: `Mostrar/Ocultar Colunas da Tabela`,
        },
        selectedRows: {
          text: `linha(s) selecionada(s)`,
          delete: `Deletar`,
          deleteAria: `Deletear linha(s) selecionada(s)`,
        },
      },
      onRowClick: rowData => {
        openInternalDocsRequest({ id: rowData[6] });
      },
      onTableChange: (action, tableState) => {
        const { filterList, page, rowsPerPage, searchText } = tableState;
        const filters = filterList.reduce(
          (acc, v, i) => (v.length ? Object.assign(acc, { [columns[i].filterName]: v }) : acc),
          {},
        );
        switch (action) {
          case `sort`:
            changeInternalDocsSort({
              activeColumn: tableState.activeColumn,
              sort: tableState.announceText.split(` : `)[1],
            });
            break;
          case `resetFilters`:
          case `filterChange`:
            changeInternalDocsFilters({ page, searchText, filterList: filters });
            break;
          case `search`:
            clearTimeout(this.searchTimeout);
            this.searchTimeout = setTimeout(() => {
              changeInternalDocsFilters({ page, searchText, filterList: filters });
            }, 500);
            break;
          case `changeRowsPerPage`:
          case `changePage`:
            changeInternalDocsPages({ page, rowsPerPage });
            break;
          default:
        }
      },
      onDownload: () => {
        let url = `/internaldocs/documentinfo/getreport/?page=${propsInternalDocs.page + 1}&length=${
          propsInternalDocs.rowsPerPage
        }&${buildQueryStringFromObject(propsInternalDocs.filterList, propsInternalDocs.searchText)}`;
        if (Object.entries(propsInternalDocs.sort).length !== 0) {
          url += `&sort=${Object.keys(propsInternalDocs.sort)[0]},${
            propsInternalDocs.sort[Object.keys(propsInternalDocs.sort)[0]].order
          }`;
        }
        api
          .get(url, { responseType: `blob` })
          .then(res => saveFile(res.data, `report.xlsx`))
          .catch(error => {
            // eslint-disable-next-line
            console.error(error);
          });
        return false;
      },
    };

    return (
      <>
        <Hidden only={[`md`, `lg`, `xl`]}>
          <div className={classes.smRecipient} onScroll={this.handleScroll}>
            <MobileInternalDocs
              data={propsInternalDocs.data}
              isLoading={propsInternalDocs.isLoading}
              institutions={Institutions}
              handleOpen={openInternalDocsRequest}
            />
          </div>
          <DialogIntDocsForm
            avatar={avatar}
            fullscreen
            open={propsInternalDocs.modalOpen}
            handleClose={closeInternalDocsRequest}
            formInstance={propsInternalDocs.formInstance}
            getData={loadInternalDocsRequest}
            resetFormInstance={closeInternalDocsSuccess}
          />
        </Hidden>
        <Hidden only={[`xs`, `sm`]}>
          {propsInternalDocs.isLoading && <LinearProgress style={{ position: `relative` }} />}
          <MUIDataTable title="Documentos internos" data={propsInternalDocs.data} columns={columns} options={options} />
          <DialogIntDocsForm
            avatar={avatar}
            open={propsInternalDocs.modalOpen}
            handleClose={closeInternalDocsRequest}
            formInstance={propsInternalDocs.formInstance}
            getData={loadInternalDocsRequest}
            resetFormInstance={closeInternalDocsSuccess}
          />
        </Hidden>
      </>
    );
  }
}

const mapStateToProps = state => ({
  propsInternalDocs: state.InternalDocs,
  loggedUserInfo: state.Auth,
  Sectors: state.Sectors.data,
  Institutions: state.Institutions.data,
});

const mapDispatchToProps = dispatch => bindActionCreators(internalDocsActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(withRouter(InternalDocs)));

export { InternalDocs as InternalDocsTests };
