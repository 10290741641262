import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { DialogProvider } from 'muibox';
import { SnackbarProvider } from 'notistack';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import App from './App';
import { theme } from './Utils/ThemeObject';
import { store } from './ReduxFlow/ConfigureStore';

// eslint-disable-next-line react/prefer-stateless-function
class RootApp extends Component {
  render() {
    return (
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <DialogProvider>
            <SnackbarProvider
              maxSnack={1}
              autoHideDuration={2000}
              anchorOrigin={{
                vertical: `top`,
                horizontal: `right`,
              }}
            >
              <BrowserRouter>{store && <App />}</BrowserRouter>
            </SnackbarProvider>
          </DialogProvider>
        </MuiThemeProvider>
      </Provider>
    );
  }
}

if (module.hot) {
  module.hot.accept();
}

export default RootApp;
