import React, { MouseEvent, ReactElement, SFC } from 'react';
import { Avatar, Box, Button, IconButton } from '@material-ui/core';
import { AddAPhoto as AddAPhotoIcon, Person as PersonIcon } from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';

export enum PickerFormats {
  rounded = 'rounded', // eslint-disable-line
}

type ImagePickerProps = {
  children?: ReactElement;
  isLoading: boolean;
  isPicking: boolean;
  loadedImage?: string;
  genericImage?: string;
  onClick?: (event: MouseEvent) => void;
  sizeAndFormat: {
    format?: PickerFormats;
    height: number;
    width: number;
  };
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    image: {
      height: 120,
      position: `relative`,
      width: 120,
    },
    imageButton: {
      fontSize: `1em`,
      position: `relative`,
      zIndex: 1,
    },
    imageEditting: {
      background: `#00000099 0% 0% no-repeat padding-box`,
    },
    imageIcon: {
      color: `#fff`,
      fontSize: `2em`,
    },
  }),
);

const ImagePicker: SFC<ImagePickerProps> = ({
  children,
  isLoading,
  loadedImage,
  isPicking,
  genericImage,
  onClick,
  sizeAndFormat,
}) => {
  const classes = useStyles();
  const { format, height, width } = sizeAndFormat;

  return (
    <>
      {isLoading ? (
        <Skeleton height={height} variant={format ? `rect` : `circle`} width={width} />
      ) : (
        <Box alignContent="center" alignItems="center" display="flex" justifyContent="flex-start" maxWidth={width}>
          <Avatar
            className={classes.image}
            src={loadedImage || genericImage}
            style={{ height, width }}
            variant={format}
          >
            {children || <PersonIcon />}
          </Avatar>
          {isPicking && format !== PickerFormats.rounded && (
            <IconButton
              className={`${classes.imageButton} ${classes.imageEditting}`}
              onClick={onClick}
              style={{ left: -width, height, width }}
            >
              <AddAPhotoIcon className={classes.imageIcon} />
            </IconButton>
          )}
          {isPicking && format === PickerFormats.rounded && (
            <Button
              className={`${classes.imageButton} ${classes.imageEditting}`}
              onClick={onClick}
              style={{ left: -width, height, minWidth: width, width }}
            >
              <AddAPhotoIcon className={classes.imageIcon} />
            </Button>
          )}
        </Box>
      )}
    </>
  );
};

export default ImagePicker;
