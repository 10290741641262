import React, { useState, useEffect } from 'react';
/**
 * Material UI - Core
 */
import {
  Chip,
  CircularProgress,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
  withStyles,
} from '@material-ui/core';
/**
 * Material UI - Icons
 */
import { CheckCircle } from '@material-ui/icons';
import Markdown from 'markdown-to-jsx';
import markdownOverrides from '../../Utils/markdownOverrides';

const styles = () => ({
  chipLabel: {
    breakWord: `break-all`,
    whiteSpace: `normal`,
    wordWrap: `break-word`,
  },
  chipRoot: {
    height: `auto`,
  },
  smRecipient: {
    height: `calc(100vh - 112px)`,
    maxWidth: `100vw`,
    overflowX: `hidden`,
    overflowY: `auto`,
    textDecoration: `none`,
    wordBreak: `break-word`,
  },
});

let scrollTimeout;

const Releases = ({ classes, firestore }) => {
  const [releases, setReleases] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getReleases = async () => {
      const lastRelease = await firestore
        .collection(`releases`)
        .doc(process.env.REACT_APP_VERSION)
        .get();
      const response = await firestore
        .collection(`releases`)
        .orderBy(`releaseDate`, `desc`)
        .startAt(lastRelease.data().releaseDate)
        .limit(5)
        .get();
      setReleases(response.docs);
    };
    getReleases();
  }, [firestore]);

  const handleScroll = event => {
    event.stopPropagation();
    setIsLoading(true);
    clearTimeout(scrollTimeout);
    if (event.target.scrollHeight - event.target.scrollTop - 100 <= event.target.clientHeight) {
      scrollTimeout = setTimeout(async () => {
        const response = await firestore
          .collection(`releases`)
          .orderBy(`releaseDate`, `desc`)
          .startAfter(releases.slice(-1)[0].data().releaseDate)
          .limit(5)
          .get();
        setReleases([...releases, ...response.docs]);
        setIsLoading(false);
      }, 200);
    }
  };

  return (
    <div className={classes.smRecipient} onScroll={handleScroll}>
      {!!releases.length &&
        releases.map(doc => {
          const release = doc.data();
          const { releaseDate, sessions } = release;

          return (
            <Paper key={doc.id} style={{ padding: 20, marginBottom: 20 }} elevation={2}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="h6">{doc.id}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6" align="right">
                    {releaseDate.toDate().toLocaleDateString(`pt-br`)}
                  </Typography>
                </Grid>
              </Grid>
              <Divider style={{ marginBottom: 5 }} />
              {sessions.map((session, idx) => {
                const { items, name, type } = session;

                return (
                  <Grid container key={idx}>
                    <>
                      <Grid item xs={12}>
                        <Chip
                          classes={{ label: classes.chipLabel, root: classes.chipRoot }}
                          color={type === `feature` ? `primary` : `secondary`}
                          label={<Typography color="inherit">{name}</Typography>}
                        />
                      </Grid>
                      <Grid container>
                        <Grid item xs={12}>
                          <List>
                            {items.map((feat, idx) => {
                              return (
                                <ListItem key={idx} disableGutters>
                                  <ListItemIcon>
                                    <CheckCircle />
                                  </ListItemIcon>
                                  <ListItemText style={{ textAlign: `justify` }}>
                                    <Markdown options={{ overrides: markdownOverrides }}>{feat}</Markdown>
                                  </ListItemText>
                                </ListItem>
                              );
                            })}
                          </List>
                        </Grid>
                      </Grid>
                    </>
                  </Grid>
                );
              })}
            </Paper>
          );
        })}
      {isLoading && (
        <Grid container item justify="center" alignItems="center" alignContent="center">
          <CircularProgress />
        </Grid>
      )}
    </div>
  );
};

export default withStyles(styles)(Releases);
