import React from 'react';
/**
 * Date dependencies
 */
import DateFnsUtils from '@date-io/date-fns';
import ptLocale from 'date-fns/locale/pt-BR';
/**
 * Material UI - Pickers
 */
import {
  DatePicker,
  DateTimePicker,
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from '@material-ui/pickers';
/**
 * Material UI - Core
 */
import { makeStyles, useTheme } from '@material-ui/core/styles';

const TZ_OFFSET = new Date().getTimezoneOffset() * 60000;

const useStyles = theme =>
  makeStyles({
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: `98%`,
    },
  });

const MyDatePicker = ({
  autoFocus,
  autoOk,
  customClasses,
  disabled,
  error,
  handleDateChange,
  helperText,
  InputProps,
  keyboard,
  label,
  margin,
  minDate,
  name,
  onClose,
  placeholder,
  required,
  value: presetValue,
  variant,
  width,
}) => {
  const classes = useStyles(useTheme())();
  const { textField } = customClasses || classes;
  const value = presetValue ? new Date(presetValue).getTime() + TZ_OFFSET : presetValue;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
      {!keyboard ? (
        <DatePicker
          label={label}
          clearable
          margin={margin}
          name={name}
          format="dd/MM/yyyy"
          value={value}
          onChange={handleDateChange}
          animateYearScrolling
          className={textField}
          onClose={onClose}
          autoOk={autoOk}
          autoFocus={autoFocus}
          inputVariant={variant}
          error={error}
          required={required}
          helperText={helperText}
          style={{ width }}
          minDate={minDate}
          disabled={disabled}
          clearLabel="Limpar"
          cancelLabel="Cancelar"
          placeholder={placeholder}
          InputLabelProps={{
            shrink: placeholder ? true : undefined,
          }}
          InputProps={{
            ...InputProps,
          }}
        />
      ) : (
        <KeyboardDatePicker
          label={label}
          clearable
          margin={margin}
          name={name}
          format="dd/MM/yyyy"
          mask={value => (value ? [/\d/, /\d/, `/`, /\d/, /\d/, `/`, /\d/, /\d/, /\d/, /\d/] : [])}
          value={value || ``}
          onChange={handleDateChange}
          animateYearScrolling
          className={textField}
          onClose={onClose}
          autoOk={autoOk}
          autoFocus={autoFocus}
          inputVariant={variant}
          error={error}
          required={required}
          helperText={helperText}
          style={{ width }}
          minDate={minDate}
          disabled={disabled}
          clearLabel="Limpar"
          cancelLabel="Cancelar"
          placeholder={placeholder}
          InputLabelProps={{
            shrink: placeholder ? true : undefined,
          }}
          InputProps={{
            ...InputProps,
          }}
        />
      )}
    </MuiPickersUtilsProvider>
  );
};

const MyTimePicker = ({
  autoFocus,
  autoOk,
  disabled,
  error,
  handleDateChange,
  keyboard,
  label,
  margin,
  minDate,
  minDateMessage,
  name,
  onClose,
  placeholder,
  required,
  value,
  variant,
  width,
}) => {
  const classes = useStyles(useTheme())();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
      {keyboard ? (
        <TimePicker
          error={error}
          label={label}
          clearable
          ampm={false}
          margin={margin}
          name={name}
          value={value}
          animateYearScrolling
          onChange={handleDateChange}
          className={classes.textField}
          onClose={onClose}
          autoOk={autoOk}
          autoFocus={autoFocus}
          inputVariant={variant}
          style={{ width }}
          required={required}
          minDate={minDate}
          minDateMessage={minDateMessage}
          disabled={disabled}
          clearLabel="Limpar"
          cancelLabel="Cancelar"
          placeholder={placeholder}
          InputLabelProps={{
            shrink: placeholder ? true : undefined,
          }}
        />
      ) : (
        <KeyboardTimePicker
          label={label}
          clearable
          mask={[/\d/, /\d/, `:`, /\d/, /\d/]}
          ampm={false}
          margin={margin}
          name={name}
          value={value}
          animateYearScrolling
          onChange={handleDateChange}
          className={classes.textField}
          onClose={onClose}
          autoOk={autoOk}
          autoFocus={autoFocus}
          inputVariant={variant}
          style={{ width }}
          required={required}
          minDate={minDate}
          minDateMessage={minDateMessage}
          disabled={disabled}
          clearLabel="Limpar"
          cancelLabel="Cancelar"
          placeholder={placeholder}
          InputLabelProps={{
            shrink: placeholder ? true : undefined,
          }}
        />
      )}
    </MuiPickersUtilsProvider>
  );
};

const MyDateTimePicker = ({
  autoFocus,
  autoOk,
  customClasses,
  disabled,
  handleDateChange,
  keyboard,
  label,
  minDate,
  minDateMessage,
  name,
  placeholder,
  required,
  value,
  variant,
  width,
}) => {
  const classes = useStyles(useTheme())();
  const { textField } = customClasses || classes;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
      {keyboard ? (
        <DateTimePicker
          ampm={false}
          animateYearScrolling
          autoFocus={autoFocus}
          autoOk={autoOk}
          cancelLabel="Cancelar"
          className={textField}
          clearLabel="Limpar"
          clearable
          disabled={disabled}
          format="dd/MM/yyyy HH:mm"
          InputLabelProps={{
            shrink: placeholder ? true : undefined,
          }}
          inputVariant={disabled ? `filled` : variant}
          label={label}
          minDate={minDate}
          minDateMessage={minDateMessage}
          name={name}
          onChange={handleDateChange}
          placeholder={placeholder}
          required={required}
          style={{ width }}
          value={value}
        />
      ) : (
        <KeyboardDateTimePicker
          ampm={false}
          animateYearScrolling
          autoFocus={autoFocus}
          autoOk={autoOk}
          cancelLabel="Cancelar"
          className={textField}
          clearLabel="Limpar"
          clearable
          disabled={disabled}
          format="dd/MM/yyyy HH:mm"
          InputLabelProps={{
            shrink: placeholder ? true : undefined,
          }}
          inputVariant={disabled ? `filled` : variant}
          label={label}
          minDate={minDate}
          minDateMessage={minDateMessage}
          name={name}
          onChange={handleDateChange}
          placeholder={placeholder}
          required={required}
          style={{ width }}
          value={value}
        />
      )}
    </MuiPickersUtilsProvider>
  );
};

const MyDatePickerStyles = MyDatePicker;
const MyTimePickerStyles = MyTimePicker;
const MyDateTimePickerStyles = MyDateTimePicker;

export { MyDatePickerStyles, MyTimePickerStyles, MyDateTimePickerStyles };
