import { action, ActionType } from 'typesafe-actions';
import { Dialog } from 'muibox';
import { InstitutionsTypes, Institution, InstitutionError } from './Types';

export const loadRequest = (dialog: Dialog): ActionType<typeof action> =>
  action(InstitutionsTypes.LOAD_REQUEST, { dialog });
export const loadSuccess = (data: Institution[]): ActionType<typeof action> =>
  action(InstitutionsTypes.LOAD_SUCCESS, { data });
export const loadFailure = (): ActionType<typeof action> => action(InstitutionsTypes.LOAD_FAILURE);
export const openInstitutionsRequest = (data: { dialog: Dialog; id?: number | string }): ActionType<typeof action> =>
  action(InstitutionsTypes.OPEN_REQUEST, data);
export const openInstitutionsSuccess = (data?: Institution[]): ActionType<typeof action> =>
  action(InstitutionsTypes.OPEN_SUCCESS, data);
export const closeInstitutionsRequest = (): ActionType<typeof action> => action(InstitutionsTypes.CLOSE_REQUEST);
export const closeInstitutionsSuccess = (): ActionType<typeof action> => action(InstitutionsTypes.CLOSE_SUCCESS);
export const addOrUpdateRequest = (data: {
  dialog: Dialog;
  id: number | undefined;
  postData: FormData;
}): ActionType<typeof action> => action(InstitutionsTypes.ADD_OR_UPDATE_REQUEST, data);
export const addOrUpdateFailure = (errors: InstitutionError): ActionType<typeof action> =>
  action(InstitutionsTypes.ADD_OR_UPDATE_FAILURE, { errors });
export const addSuccess = (data: Institution): ActionType<typeof action> =>
  action(InstitutionsTypes.ADD_SUCCESS, { data });
export const updateSuccess = (data: Institution): ActionType<typeof action> =>
  action(InstitutionsTypes.UPDATE_SUCCESS, { data });
export const changeInstitutionsRangeRequest = (): ActionType<typeof action> => action(InstitutionsTypes.RANGE_REQUEST);
export const changeInstitutionsRangeSuccess = (): ActionType<typeof action> => action(InstitutionsTypes.RANGE_SUCCESS);
