import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
/**
 * Redux dependencies
 */
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
/**
 * Material-UI Core
 */
import { Chip, LinearProgress, Link, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
/**
 * Tasks actions
 */
import * as tasksActions from '../../../ReduxFlow/Reducers/Tasks/Actions';

const useStyles = makeStyles({
  markerRoot: {
    fontSize: 12,
    height: 15,
  },
  markerChildren: {
    padding: `0 6px 0 6px`,
  },
});

const CallsHistory = ({ changeTasksFilters, changeTasksPages, inventoryId, tabIndex, Tasks }) => {
  let timer = null;
  const { count, data, isLoading, rowsPerPage } = Tasks;
  const classes = useStyles();

  useEffect(() => {
    if (inventoryId && tabIndex === 1) {
      changeTasksFilters({
        page: 0,
        filterList: [[], [], [], [], [], [], [], [], [], [`Chamado`], [], [], [], [], []],
        searchText: ``,
        invId: inventoryId,
      });
    }
  }, [changeTasksFilters, inventoryId, tabIndex]);

  const handleScroll = event => {
    event.stopPropagation();
    clearTimeout(timer);
    if (data.length < count && event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight) {
      timer = setTimeout(() => {
        changeTasksPages({ rowsPerPage: rowsPerPage + 15, page: 0, invId: inventoryId });
      }, 200);
    }
  };

  const getCallStatus = status => {
    switch (status) {
      case `Parado`:
        return (
          <Chip
            label="Parado"
            style={{ color: `#fff`, backgroundColor: `#dc3545` }}
            className={classes.markerRoot}
            classes={{ label: classes.markerChildren }}
          />
        );
      case `Parcial`:
        return (
          <Chip
            label="Parcial"
            style={{ color: `#fff`, backgroundColor: `#ffc107` }}
            className={classes.markerRoot}
            classes={{ label: classes.markerChildren }}
          />
        );
      case `Funcionando`:
        return (
          <Chip
            label="Funcionando"
            style={{ color: `#fff`, backgroundColor: `#28a745` }}
            className={classes.markerRoot}
            classes={{ label: classes.markerChildren }}
          />
        );
      default:
        return `Sem status`;
    }
  };

  return (
    <div id="mainDiv" style={{ maxHeight: `100%`, padding: 16, overflowY: `auto` }} onScroll={handleScroll}>
      {isLoading && <LinearProgress />}
      {data.length ? (
        data.map(call => {
          if (!call.equipment) {
            return undefined;
          }
          const { situation } = call.equipment;
          const url = `tasks/?id=${call.id}&type=Chamado`;
          return (
            <Paper key={call.id} style={{ padding: 10, marginBottom: 5 }}>
              <Link href={url} color="inherit" underline="none">
                <Grid container alignItems="center">
                  <Grid item xs={6}>
                    <Typography>{call.taskTitle.title}</Typography>
                    <Typography component="div">
                      <small>
                        {`#${call.taskTitle.id} - `}
                        {getCallStatus(situation)}
                      </small>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography align="right">
                      <small>Data inicial: </small>
                      <small>{call.dates[0] ? new Date(call.dates[0]).toLocaleString(`pt-BR`) : `Não informada`}</small>
                    </Typography>
                    <Typography align="right">
                      <small>Data final: </small>
                      <small>{call.dates[1] ? new Date(call.dates[1]).toLocaleString(`pt-BR`) : `Não informada`}</small>
                    </Typography>
                  </Grid>
                </Grid>
              </Link>
            </Paper>
          );
        })
      ) : (
        <Typography align="center">Nenhum chamado encontrado.</Typography>
      )}
    </div>
  );
};

CallsHistory.defaultProps = {
  inventoryId: null,
};

CallsHistory.propTypes = {
  inventoryId: PropTypes.number,
  changeTasksFilters: PropTypes.func.isRequired,
  changeTasksPages: PropTypes.func.isRequired,
  tabIndex: PropTypes.number.isRequired,
  Tasks: PropTypes.shape({
    modalOpen: PropTypes.bool,
    formInstance: PropTypes.arrayOf(PropTypes.object),
    isLoading: PropTypes.bool,
    menuType: PropTypes.string,
    page: PropTypes.number,
    count: PropTypes.number,
    data: PropTypes.arrayOf(PropTypes.object),
    searchText: PropTypes.string,
    rowsPerPage: PropTypes.number,
    filterList: PropTypes.arrayOf(PropTypes.array),
    sort: PropTypes.object,
  }).isRequired,
};

/* istanbul ignore next */
const mapStateToProps = state => ({
  Tasks: state.Tasks,
});

/* istanbul ignore next */
const mapDispatchToProps = dispatch => bindActionCreators(tasksActions, dispatch);

/* istanbul ignore next */
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CallsHistory);

export { CallsHistory };
