import React from 'react';
/**
 * Material UI - Core
 */
import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@material-ui/core';
/**
 * Material UI - Icons
 */
import { ExpandMore } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { skeletonItemsFunction } from '../../Pages/Home/HomeConstants';

const HomeSkeleton = () => {
  const skeletonItems = skeletonItemsFunction();

  return (
    <Accordion expanded>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Skeleton height={25} width={63.5} />
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          {skeletonItems.map(dashItem => {
            const { containerSizes, desktop, key } = dashItem;
            const { counter, icon, sizes, text, textAndCounterSizes } = desktop;

            return (
              <Grid
                alignContent="flex-start"
                alignItems="flex-start"
                container
                item
                key={key}
                xs={containerSizes[0]}
                sm={containerSizes[1]}
                md={containerSizes[2]}
                lg={containerSizes[3]}
                xl={containerSizes[4]}
              >
                <Grid
                  alignContent="flex-start"
                  alignItems="flex-start"
                  container
                  item
                  justify="flex-start"
                  xs={sizes[0]}
                  sm={sizes[1]}
                  md={sizes[2]}
                  lg={sizes[3]}
                  xl={sizes[4]}
                >
                  {icon}
                </Grid>
                <Grid
                  container
                  direction="column"
                  item
                  xs={textAndCounterSizes[0]}
                  sm={textAndCounterSizes[1]}
                  md={textAndCounterSizes[2]}
                  lg={textAndCounterSizes[3]}
                  xl={textAndCounterSizes[4]}
                >
                  {counter}
                  {text}
                </Grid>
              </Grid>
            );
          })}
          <Grid item style={{ display: `flex` }} xs={12}>
            <Skeleton height={36} variant="rect" width={141.422} />
            <Skeleton height={36} style={{ marginLeft: 10 }} variant="rect" width={141.422} />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default HomeSkeleton;
