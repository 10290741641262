import React, { FC } from 'react';
import { Avatar } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Markdown from 'markdown-to-jsx';
import { getMoodFromString } from '../../../../MoodPicker';
import markdownOverrides from '../../../../../Utils/markdownOverrides';
import AutoComment from '../../AutoComment';
import { AutoCommentCommonTypes } from '../..';
import { Comment } from '..';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      backgroundColor: `#fff`,
      height: theme.spacing(3),
      paddingTop: theme.spacing(0.625),
      width: theme.spacing(3),
    },
  }),
);

interface FeedbackParsed {
  mood: string;
  text: string;
}

type FeedbackAutoCommentProps = Omit<AutoCommentCommonTypes<Comment>, 'genericAvatar'>;// eslint-disable-line

const FeedbackAutoComment: FC<FeedbackAutoCommentProps> = ({
  comment,
  fromNow,
  isFirstItem = false,
  isLastItem = false,
  nextIsAutoComment,
  previousIsAutoComment,
}) => {
  const classes = useStyles();
  const { userDetail } = comment;
  const { mood, text } = JSON.parse(comment.comment) as FeedbackParsed;
  const moodIcon = getMoodFromString(mood);

  return (
    <AutoComment
      avatar={<Avatar className={classes.avatar}>{moodIcon ? moodIcon.src : undefined}</Avatar>}
      fromNow={fromNow}
      headerActionText="respondeu a pesquisa de satisfação"
      isFirstItem={isFirstItem}
      isLastItem={isLastItem}
      nextIsAutoComment={nextIsAutoComment}
      previousIsAutoComment={previousIsAutoComment}
      userName={userDetail.fullName}
    >
      <Markdown
        options={{
          overrides: markdownOverrides,
        }}
      >
        {text}
      </Markdown>
    </AutoComment>
  );
};

export default FeedbackAutoComment;
