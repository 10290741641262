import React from 'react';
/**
 * Redux dependencies
 */
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
/**
 * Supplier actions
 */
/**
 * fetchJsonp for fetchin cnpj API
 */
import fetchJsonp from 'fetch-jsonp';
/**
 * MaskedInput
 */
import MaskedInput from 'react-text-mask';
/**
 * Material UI - Core
 */
import { Checkbox, FormControlLabel, Grid, TextField, withStyles } from '@material-ui/core';
/**
 * My components
 */
import { withDialog } from 'muibox';
import * as SupplierActions from '../../../ReduxFlow/Reducers/Suppliers/Actions';
import api from '../../../Services/api';
import CustomSelect from '../../../Components/CustomSelect';
import InstitutionsSelector from '../../../Components/InstitutionsSelector';

const styles = theme => ({
  container: {
    display: `flex`,
    flexWrap: `wrap`,
  },
  dense: {
    marginTop: 19,
  },
  fab: {
    position: `relative`,
    bottom: 0,
    left: theme.spacing(15),
  },
  menu: {
    width: 200,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: `98%`,
  },
  textFieldExtended: {
    marginLeft: 4,
    marginRight: theme.spacing(1),
    marginTop: 17,
    width: `98%`,
  },
});

function CNPJMask(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      // eslint-disable-next-line
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /[0-9]/,
        /[0-9]/,
        `.`,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        `.`,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        `/`,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        `-`,
        /[0-9]/,
        /[0-9]/,
      ]}
      placeholderChar={`\u2000`}
    />
  );
}

function CEPMask(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      // eslint-disable-next-line
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, `-`, /[0-9]/, /[0-9]/, /[0-9]/]}
      placeholderChar={`\u2000`}
    />
  );
}
function handlePhoneMask(value) {
  if (value.replace(/\D+/g, ``).includes(`0800`)) {
    return [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ` `, /[0-9]/, /[0-9]/, ` `, /[0-9]/, /[0-9]/, ` `, /[0-9]/, /[0-9]/];
  }
  if (value.replace(/\D+/g, ``).match(/\d\d9\d\d\d\d/)) {
    return [
      `(`,
      /[0-9]/,
      /[0-9]/,
      `)`,
      ` `,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      ` `,
      /[0-9]/,
      /[0-9]/,
      ` `,
      /[0-9]/,
      /[0-9]/,
    ];
  }
  return [
    `(`,
    /[0-9]/,
    /[0-9]/,
    `)`,
    ` `,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    ` `,
    /[0-9]/,
    /[0-9]/,
    ` `,
    /[0-9]/,
    /[0-9]/,
  ];
}

function PhoneMask(props) {
  const { inputRef, ...other } = props;
  // eslint-disable-next-line react/destructuring-assignment
  const val = handlePhoneMask(props.value);
  return (
    <MaskedInput
      // eslint-disable-next-line
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={val}
      placeholderChar={`\u2000`}
    />
  );
}

class SupplierForm extends React.Component {
  state = {
    name: ``,
    tradingName: ``,
    cnpj: ``,
    cep: ``,
    state: ``,
    city: ``,
    district: ``,
    address: ``,
    phone: ``,
    contact: ``,
    activity: ``,
    observations: ``,
    isActive: true,
    institution: [],
    fetchingCep: false,
    fetchingCnpj: false,
  };

  componentDidMount() {
    const { Institutions } = this.props;
    if (Institutions.length === 1) this.setState({ institution: [Institutions[0].id] });
    this.mountFormInstance();
  }

  componentDidUpdate(prevProps) {
    const { formInstance } = this.props;
    if (prevProps.formInstance.length === 0 && formInstance.length > 0) {
      this.mountFormInstance();
    }
  }

  mountFormInstance = () => {
    const { formInstance } = this.props;
    if (formInstance.length) {
      const {
        name,
        tradingName,
        cnpj,
        cep,
        state,
        city,
        district,
        address,
        phone,
        contact,
        activity,
        observations,
        isActive,
        institution,
      } = formInstance[0];
      this.setState({
        name,
        tradingName,
        cnpj,
        cep,
        state,
        city,
        district,
        address,
        phone,
        contact,
        activity,
        observations,
        isActive,
        institution,
      });
    }
  };

  handleFetchCep = cep => {
    const { dialog } = this.props;
    this.setState({
      fetchingCep: true,
    });
    fetch(`https://viacep.com.br/ws/${cep}/json/`, {
      method: `GET`,
    })
      .then(res => (res.ok ? res.json() : res.json().then(err => Promise.reject(err))))
      .then(json => {
        this.setState({
          fetchingCep: false,
          state: json.uf,
          city: json.localidade,
          district: json.bairro,
          address: `${json.logradouro}, ${json.complemento}`,
        });
      })
      .catch(() => {
        this.setState(
          {
            fetchingCep: false,
          },
          () =>
            dialog.alert({
              title: `Erro na API de CEP`,
              message: `Serviço fora do ar, não foi possível autocompletar o endereço!`,
              ok: { text: `Ok`, variant: `contained`, color: `primary` },
            }),
        );
      });
  };

  getSupplierIfCnpjExists = async cnpj => {
    const { Suppliers } = this.props;
    const reduxSupplier = Suppliers.filter(supplier => supplier.cnpj.replace(/\D/g, ``) === cnpj);
    if (reduxSupplier.length) return `existsOnRedux`;
    try {
      const response = await api.get(`/supplier/checkIfSupplierExists/${cnpj.replace(/\D/g, ``)}/`);
      if (response.status === 200) {
        return [response.data];
      }
    } catch (e) {
      return false;
    }
    return false;
  };

  handleFetchCnpj = async cnpj => {
    const { addSuccess, dialog, handleClick } = this.props;
    this.setState({
      fetchingCnpj: true,
    });
    const supplier = await this.getSupplierIfCnpjExists(cnpj);
    if (supplier === `existsOnRedux`) {
      dialog.alert({
        title: `Fornecedor já cadastrado`,
        message: `O fornecedor já está disponível em sua lista de fornecedores!`,
        ok: { text: `Ok`, variant: `contained`, color: `primary` },
      });
      handleClick();
      return;
    }
    if (supplier) {
      dialog
        .confirm({
          title: `Fornecedor já cadastrado`,
          message: `Gostaria de adicionar o fornecedor em sua(s) instituição(ões)?`,
          ok: { text: `Confirmar`, variant: `contained`, color: `primary` },
          cancel: { text: `Cancelar`, color: `secondary` },
        })
        .then(async () => {
          this.setState({
            fetchingCnpj: false,
          });
          const response = await api.get(`/supplier/${cnpj.replace(/\D/g, ``)}/addUserInstitutionsToSupplier/ `);
          if (response.status === 200) {
            addSuccess(response.data);
          } else {
            dialog.alert({
              title: `Erro`,
              message: `Erro ao tentar adicionar o fornecedor em sua(s) instituição(ões), favor tentar novamente mais tarde!`,
              ok: { text: `Confirmar`, variant: `contained`, color: `primary` },
            });
          }
          handleClick();
        })
        .catch(() => handleClick());
    } else {
      fetchJsonp(`https://www.receitaws.com.br/v1/cnpj/${cnpj}`, {
        jsonpCallbackFunction: `jsonp`,
      })
        .then(async res => {
          const json = await res.json();
          return res.ok && json.status !== `ERROR` ? res.json() : res.json().then(err => Promise.reject(err));
        })
        .then(json => {
          this.setState(
            {
              fetchingCnpj: false,
              name: json.nome
                .toLowerCase()
                .split(` `)
                .map((word, idx, arr) => {
                  if (idx === arr.length - 1) {
                    return word.toUpperCase();
                  }
                  return word.charAt(0).toUpperCase() + word.slice(1);
                })
                .join(` `),
              tradingName: json.fantasia,
              activity: json.atividade_principal[0].text,
              cep: json.cep.replace(/\D+/g, ``),
            },
            () => {
              const { cep } = this.state;
              this.handleFetchCep(cep);
            },
          );
        })
        .catch(error => {
          this.setState(
            {
              fetchingCnpj: false,
            },
            () =>
              dialog.alert({
                title: `Erro na API de CNPJ`,
                message: `${error.message}.`,
                ok: { text: `Ok`, variant: `contained`, color: `primary` },
              }),
          );
        });
    }
  };

  handleChange = name => event => {
    if (event && `target` in event) {
      const cepOrCnpj = event.target.value.replace(/\D+/g, ``);
      if (event.target.type === `checkbox`) {
        this.setState({
          [name]: event.target.checked,
        });
      } else {
        this.setState({
          [name]: event.target.value,
        });
      }
      if (name === `cep` && cepOrCnpj.length === 8) {
        this.handleFetchCep(cepOrCnpj);
      } else if (name === `cnpj` && cepOrCnpj.length === 14) {
        this.handleFetchCnpj(cepOrCnpj);
      }
    } else if (Array.isArray(event) && !event.some(val => Number.isNaN(Number(val)))) {
      this.setState({
        [name]: event,
      });
    } else if (!event) {
      this.setState({
        [name]: [],
      });
    } else {
      this.setState({
        [name]: event.map(val => val.value),
      });
    }
  };

  cnpjErrorText = () => {
    const { formErrors } = this.props;
    const { cnpj } = this.state;
    if (!(`cnpj` in formErrors) && cnpj) return null;
    return `cnpj` in formErrors && !cnpj ? `Esse campo é obrigatório!` : `CNPJ já cadastrado no sistema!`;
  };

  render() {
    const { classes, formErrors, formInstance, handleSubmit, Institutions } = this.props;
    const {
      activity,
      address,
      cep,
      city,
      cnpj,
      contact,
      district,
      name,
      fetchingCep,
      fetchingCnpj,
      institution,
      isActive,
      phone,
      state,
      tradingName,
      observations,
    } = this.state;

    return (
      <form
        id="supplierForm"
        className={classes.container}
        noValidate
        autoComplete="off"
        onSubmit={formInstance.length ? handleSubmit(formInstance[0].id) : handleSubmit()}
      >
        <Grid container spacing={0} style={{ width: `100%` }}>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <TextField
              className={classes.textField}
              disabled={Boolean(formInstance.length) && !formInstance[0].isActive}
              error={`cnpj` in formErrors}
              helperText={this.cnpjErrorText()}
              id="id_cnpj"
              InputProps={{
                inputComponent: CNPJMask,
              }}
              label="CNPJ"
              margin="normal"
              name="cnpj"
              onChange={this.handleChange(`cnpj`)}
              required
              value={cnpj}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <TextField
              className={classes.textField}
              disabled={(Boolean(formInstance.length) && !formInstance[0].isActive) || fetchingCnpj}
              error={`name` in formErrors}
              helperText={`name` in formErrors && `Esse campo é obrigatório!`}
              id="id_name"
              label="Nome"
              margin="normal"
              name="name"
              onChange={this.handleChange(`name`)}
              required
              value={name}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <TextField
              className={classes.textField}
              disabled={(Boolean(formInstance.length) && !formInstance[0].isActive) || fetchingCnpj}
              id="id_tradingName"
              label="Nome fantasia"
              margin="normal"
              name="tradingName"
              onChange={this.handleChange(`tradingName`)}
              value={tradingName}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <TextField
              className={classes.textField}
              disabled={Boolean(formInstance.length) && !formInstance[0].isActive}
              error={`cep` in formErrors}
              helperText={`cep` in formErrors && `Esse campo é obrigatório!`}
              id="id_cep"
              InputProps={{
                inputComponent: CEPMask,
              }}
              label="CEP"
              margin="normal"
              name="cep"
              onChange={this.handleChange(`cep`)}
              required
              value={cep}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={10} sm={5} md={3} lg={3} xl={3}>
            <TextField
              className={classes.textField}
              disabled={(Boolean(formInstance.length) && !formInstance[0].isActive) || fetchingCep}
              error={`city` in formErrors}
              helperText={`city` in formErrors && `Esse campo é obrigatório!`}
              id="id_city"
              label="Cidade"
              margin="normal"
              name="city"
              onChange={this.handleChange(`city`)}
              required
              value={city}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={2} sm={1} md={1} lg={1} xl={1}>
            <CustomSelect
              className={classes.textFieldExtended}
              disabled={(Boolean(formInstance.length) && !formInstance[0].isActive) || fetchingCep}
              errorMessage={`state` in formErrors ? `Esse campo é obrigatório!` : null}
              handleChange={this.handleChange}
              id="id_state"
              idKey="id"
              label="UF"
              margin="normal"
              name="state"
              nameKey="state"
              options={[
                `AC`,
                `AL`,
                `AP`,
                `AM`,
                `BA`,
                `CE`,
                `DF`,
                `ES`,
                `GO`,
                `MA`,
                `MT`,
                `MS`,
                `MG`,
                `PA`,
                `PB`,
                `PR`,
                `PE`,
                `PI`,
                `RJ`,
                `RN`,
                `RS`,
                `RO`,
                `RR`,
                `SC`,
                `SP`,
                `SE`,
                `TO`,
              ].map(val => ({ id: val, state: val }))}
              required
              value={state}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <TextField
              className={classes.textField}
              disabled={(Boolean(formInstance.length) && !formInstance[0].isActive) || fetchingCep}
              error={`district` in formErrors}
              helperText={`district` in formErrors && `Esse campo é obrigatório!`}
              id="id_district"
              label="Bairro"
              margin="normal"
              name="district"
              onChange={this.handleChange(`district`)}
              required
              value={district}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <TextField
              className={classes.textField}
              disabled={(Boolean(formInstance.length) && !formInstance[0].isActive) || fetchingCep}
              error={`address` in formErrors}
              helperText={`address` in formErrors && `Esse campo é obrigatório!`}
              id="id_address"
              label="Endereço"
              margin="normal"
              name="address"
              onChange={this.handleChange(`address`)}
              required
              value={address}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <TextField
              className={classes.textField}
              disabled={Boolean(formInstance.length) && !formInstance[0].isActive}
              error={`contact` in formErrors}
              helperText={`contact` in formErrors && `Esse campo é obrigatório!`}
              id="id_contact"
              label="Contato"
              margin="normal"
              name="contact"
              onChange={this.handleChange(`contact`)}
              required
              value={contact}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <TextField
              className={classes.textField}
              disabled={Boolean(formInstance.length) && !formInstance[0].isActive}
              error={`phone` in formErrors}
              helperText={`phone` in formErrors && `Esse campo é obrigatório!`}
              id="id_phone"
              InputProps={{
                inputComponent: PhoneMask,
              }}
              label="Telefone"
              margin="normal"
              name="phone"
              onChange={this.handleChange(`phone`)}
              required
              value={phone}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <TextField
              className={classes.textField}
              error={`activity` in formErrors}
              helperText={`activity` in formErrors && `Esse campo é obrigatório!`}
              id="id_activity"
              inputProps={{
                readOnly: Boolean(cnpj && activity),
              }}
              label="Atividade"
              margin="normal"
              name="activity"
              onChange={this.handleChange(`activity`)}
              required
              value={activity}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <InstitutionsSelector
              error={!!formErrors.institution}
              helperText={formErrors.institution}
              id="id_institution"
              institutions={Institutions}
              margin="normal"
              multiple
              name="institution"
              onChange={this.handleChange(`institution`)}
              required
              selectClass={classes.textField}
              value={institution}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={6}
            md={4}
            lg={4}
            xl={4}
            alignItems="center"
            alignContent="center"
            justify="center"
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={isActive}
                  value={isActive ? `isActive` : ``}
                  id="id_isActive"
                  color="primary"
                  onChange={this.handleChange(`isActive`)}
                />
              }
              label="Ativo"
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <TextField
              className={classes.textField}
              id="id_observations"
              label="Observações"
              margin="normal"
              multiline
              name="observations"
              onChange={this.handleChange(`observations`)}
              value={observations}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  Suppliers: state.Suppliers.data,
  Institutions: state.Institutions.data,
  loggedUserInfo: state.Auth.data,
});

const mapDispatchToProps = dispatch => bindActionCreators(SupplierActions, dispatch);

export default withDialog()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withStyles(styles)(SupplierForm)),
);
