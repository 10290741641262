import React from 'react';
import { useHistory } from 'react-router-dom';
/**
 * Material UI - Core
 */
import { Button, Typography } from '@material-ui/core';

const NoMatch = () => {
  const history = useHistory();
  return (
    <div style={{ margin: `20vh 0 auto 0` }}>
      <Typography align="center" variant="h2" color="textPrimary">
        404/403
      </Typography>
      <Typography align="center" color="textPrimary">
        A página que você procurava não foi encontrada, ou você não tem permissão de acesso.
      </Typography>
      <Typography align="center" color="textPrimary">
        <Button onClick={() => history.replace(`/`)} color="primary" variant="contained">
          Voltar para Home
        </Button>
      </Typography>
    </div>
  );
};

export default NoMatch;
