import React from 'react';
/**
 * Material UI - Core
 */
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
/**
 * Material UI - Icons
 */
import { AddShoppingCart, Close } from '@material-ui/icons';
/**
 * My components
 */
import SupplierForm from './SupplierForm';

const styles = () => ({
  dialogTitle: {
    display: `flex`,
    flexFlow: `row wrap`,
    alignConent: `center`,
    justifyContent: `flex-end`,
    width: `100%`,
    padding: 0,
  },
  titleText: {
    display: `flex`,
    margin: `auto auto auto 32px`,
  },
});

const DialogSuppForm = ({
  isSaving,
  formErrors,
  open,
  formInstance,
  handleClick,
  fullscreen,
  handleSubmit,
  classes,
  openSuppliersRequest,
}) => {
  const hasFormInstance = !!formInstance.length;
  const id = (hasFormInstance && formInstance[0].id) || null;
  const name = (hasFormInstance && formInstance[0].name) || null;

  return (
    <>
      <Dialog
        fullScreen={fullscreen}
        open={open}
        onClose={handleClick}
        aria-labelledby="addSuppForm"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle className={classes.dialogTitle} disableTypography>
          {hasFormInstance && (
            <Typography className={classes.titleText} variant="overline">
              <AddShoppingCart style={{ marginRight: 5 }} />
              {name}
              <Typography color="textSecondary" component="span" style={{ marginLeft: 5 }} variant="overline">
                #{id}
              </Typography>
            </Typography>
          )}
          <Tooltip title="Fechar">
            <IconButton aria-label="Close" onClick={handleClick}>
              <Close />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent>
          <SupplierForm
            isSaving={isSaving}
            formErrors={formErrors}
            formInstance={formInstance}
            handleClick={handleClick}
            handleSubmit={handleSubmit}
            openSuppliersRequest={openSuppliersRequest}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClick} color="secondary" style={{ marginRight: 5 }}>
            Cancelar
          </Button>
          <Button form="supplierForm" type="submit" color="primary" variant="contained" disabled={isSaving}>
            Salvar {isSaving && <CircularProgress size={20} />}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withStyles(styles)(DialogSuppForm);
