import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { parse } from 'date-fns';
import { withSnackbar } from 'notistack';
/**
 * Material UI - Core
 */
import { Button, CircularProgress, Dialog, DialogActions, Grid } from '@material-ui/core';

import { useTheme } from '@material-ui/core/styles';

import api from '../../../Services/api';

import * as UserActions from '../../../ReduxFlow/Reducers/Users/Actions';

import { ChangePassword, SecondStep, UpdateUserTitle } from '../../../Components/UserForm';

import { fieldsValidation, initState, useStyles } from '../../../Components/UserForm/UserFormConstants';

import UserCommentsContainer from '../../../Components/NewUIComponents/CommentsContainer/Users';

const UpdateUserForm = ({
  closeCreateOrUpdateDialog,
  enqueueSnackbar,
  formInstanceId,
  fullscreen,
  genericAvatar,
  loggedUserInfo,
  open,
  updateRequest,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme)();
  const [isLoading, setIsLoading] = useState(true);
  const [isViewing, setIsViewing] = useState(true);
  const [startUserData, setStartUserData] = useState({});
  const [userData, setUserData] = useState({
    ...initState,
    genericAvatar,
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [passwordDialog, setPasswordDialog] = useState(false);
  const [commentsRange, setCommentsRange] = useState(5);

  const parseUserData = ({
    admissionDate,
    avatar,
    birthDate,
    cpf,
    email,
    firebaseUid,
    firstName,
    groups,
    histories,
    isActive,
    isFakeEmail,
    isMultiOrganization,
    lastName,
    sector,
    sex,
    userInstitution,
  }) => {
    return {
      admissionDate: admissionDate ? parse(admissionDate, `yyyy-MM-dd`, new Date()) : null,
      avatar,
      birthDate: birthDate ? parse(birthDate, `yyyy-MM-dd`, new Date()) : null,
      cpf,
      email,
      firebaseUid,
      firstName,
      groups: groups.length ? groups[0] : ``,
      histories,
      isActive,
      isFakeEmail,
      isMultiOrganization,
      lastName,
      sector,
      sex: sex || ``,
      userInstitution,
    };
  };

  const setLoadedUserData = data => {
    setStartUserData(data);
    setUserData(data);
    setIsLoading(false);
  };

  const commentsControll = total => () => {
    if (total > commentsRange) {
      setCommentsRange(total);
    }
  };

  useEffect(() => {
    if (!formInstanceId) return;
    try {
      const getUser = async () => {
        const response = await api.get(`/account/users/${formInstanceId}/`);
        setLoadedUserData(parseUserData(response.data));
      };
      getUser();
    } catch (error) {
      console.error(error);
    }
  }, [formInstanceId]);

  const { avatar, firebaseUid, firstName, isActive, isFakeEmail, lastName } = userData;

  const handleSubmit = async () => {
    try {
      const { admissionDate, birthDate, cpf, groups } = userData;
      setIsSubmitting(true);
      const postUserData = {
        ...userData,
        admissionDate: new Date(admissionDate).toLocaleDateString(`pt-br`),
        birthDate: new Date(birthDate).toLocaleDateString(`pt-br`),
        cpf: cpf.replace(/\D+/g, ``),
        groups: [groups],
      };
      delete postUserData.avatar;
      delete postUserData.isMultiOrganization;
      delete postUserData.password;
      const response = await api.patch(`/account/users/${formInstanceId}/`, postUserData);
      updateRequest(response.data);
      enqueueSnackbar(`Usuário editado com sucesso`);
      setIsSubmitting(false);
      setIsViewing(true);
    } catch (error) {
      console.log(error);
      setIsSubmitting(false);
      setErrors(error.response.data);
      enqueueSnackbar(`Erro ao editar usuário`);
    }
  };

  const generateRandomPassword = event => {
    event.preventDefault();
    const password = `${Math.random().toString(36)}00000000000000000`.slice(2, 8);
    setUserData(prevUserData => ({ ...prevUserData, password }));
  };

  const handleFieldChange = name => event => {
    switch (true) {
      case !!event && !!event.target && event.target.type === `checkbox`:
        event.persist();
        if (event.target.checked && name === `isFakeEmail`) {
          const fakeDomain = `@s.im`;
          setUserData(prevUserData => ({
            ...prevUserData,
            [name]: event.target.checked,
            email: prevUserData.email.endsWith(fakeDomain)
              ? prevUserData.email
              : prevUserData.email.replace(/@/g, ``) + fakeDomain,
          }));
        } else {
          setUserData(prevUserData => ({ ...prevUserData, [name]: event.target.checked }));
        }
        break;
      case !!event && !!event.persist:
        event.persist();
        setUserData(prevUserData => ({ ...prevUserData, [name]: event.target.value }));
        break;
      default:
        setUserData(prevUserData => ({ ...prevUserData, [name]: event }));
        break;
    }
    fieldsValidation(name, event, setErrors, userData);
  };

  const handleUserInactivation = async ({ reason, date }) => {
    try {
      setIsSubmitting(true);
      const updateData = {
        inactivationDate: date.toLocaleDateString(`pt-BR`),
        inactivationReason: reason,
        isActive: false,
      };
      const response = await api.patch(`/account/users/${formInstanceId}/`, updateData);
      setLoadedUserData(parseUserData(response.data));
      updateRequest(response.data);
      setIsSubmitting(false);
      enqueueSnackbar(`Usuário inativado com sucesso`);
    } catch (error) {
      console.log(error);
      setIsSubmitting(false);
      enqueueSnackbar(`Erro ao inativar usuário`);
    }
  };

  const handleUserReactivation = async ({ reason }) => {
    try {
      setIsSubmitting(true);
      const updateData = {
        reactivationReason: reason,
        isActive: true,
      };
      const response = await api.patch(`/account/users/${formInstanceId}/`, updateData);
      setLoadedUserData(parseUserData(response.data));
      updateRequest(response.data);
      setIsSubmitting(false);
      enqueueSnackbar(`Usuário reativado com sucesso`);
    } catch (error) {
      console.log(error);
      setIsSubmitting(false);
      enqueueSnackbar(`Erro ao reativar usuário`);
    }
  };

  return (
    <Dialog
      aria-labelledby="updateUserForm"
      fullScreen={fullscreen}
      fullWidth
      maxWidth="sm"
      onClose={closeCreateOrUpdateDialog}
      open={open}
    >
      {passwordDialog && (
        <ChangePassword
          adminEditing
          closePasswordDialog={() => setPasswordDialog(false)}
          firebaseUid={firebaseUid}
          formInstanceId={formInstanceId}
          passwordOpen={passwordDialog}
          userData={userData}
        />
      )}
      <UpdateUserTitle
        avatar={avatar}
        canEdit={() => setIsViewing(false)}
        changePassword={() => setPasswordDialog(true)}
        closeCreateOrUpdateDialog={closeCreateOrUpdateDialog}
        fullName={`${firstName} ${lastName}`}
        isActiveUser={isActive}
        isFakeEmail={isFakeEmail}
        isLoading={isLoading}
        isSubmitting={isSubmitting}
        isViewing={isViewing}
        handleUserInactivation={handleUserInactivation}
        handleUserReactivation={handleUserReactivation}
        genericAvatar={genericAvatar}
      />
      <SecondStep
        errors={errors}
        formInstanceId={formInstanceId}
        generateRandomPassword={generateRandomPassword}
        handleFieldChange={handleFieldChange}
        isLoading={isLoading}
        isViewing={isViewing}
        loggedUserInfo={loggedUserInfo}
        key="secondStep"
        userData={userData}
      />
      <Grid item xs={12} className={classes.commentContainer}>
        <UserCommentsContainer
          comments={userData.histories || []}
          commentsControllCallback={commentsControll}
          commentsRange={commentsRange}
          genericAvatar={genericAvatar}
          isLoading={false}
          label="Histórico"
          loggedUserId={loggedUserInfo.id}
          parentType="usuário"
        />
      </Grid>
      <DialogActions classes={{ root: classes.actionsRoot, spacing: classes.actionsSpacing }}>
        {!isViewing && (
          <>
            <Button
              className={`${classes.button} ${classes.cancelButton}`}
              disabled={isSubmitting}
              onClick={() => {
                setUserData(startUserData);
                setIsViewing(true);
              }}
            >
              Cancelar
            </Button>
            <Button
              classes={{ disabled: classes.disabledButton }}
              className={classes.button}
              color="primary"
              disabled={isSubmitting}
              onClick={() => {
                const validation = Object.keys(userData).map(key => {
                  if ([`avatar`, `isFakeEmail`, `isMultiOrganization`, `password`].indexOf(key) < 0)
                    return fieldsValidation(key, null, setErrors, userData);
                  return false;
                });
                if (validation.every(field => !field)) {
                  handleSubmit();
                }
              }}
              variant="contained"
            >
              {isSubmitting ? <CircularProgress color="inherit" size={24} /> : `Salvar`}
            </Button>
          </>
        )}
        {isViewing && isSubmitting && <CircularProgress color="inherit" size={24} />}
      </DialogActions>
    </Dialog>
  );
};

UpdateUserForm.defaultProps = {
  formInstanceId: undefined,
  fullscreen: false,
};

UpdateUserForm.propTypes = {
  closeCreateOrUpdateDialog: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  formInstanceId: PropTypes.number,
  fullscreen: PropTypes.bool,
  genericAvatar: PropTypes.string.isRequired,
  loggedUserInfo: PropTypes.shape({
    id: PropTypes.number,
    isAdminOrSuperAdmin: PropTypes.bool,
    isHoldingUser: PropTypes.bool,
    isSuperAdmin: PropTypes.bool,
    isSuperuser: PropTypes.bool,
    userInstitution: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  open: PropTypes.bool.isRequired,
  updateRequest: PropTypes.func.isRequired,
};

/* istanbul ignore next */
const mapDispatchToProps = dispatch => bindActionCreators(UserActions, dispatch);

/* istanbul ignore next */
const mapStateToProps = state => ({
  loggedUserInfo: state.Auth.data.user,
});

export const TestUpdateUserForm = UpdateUserForm;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withSnackbar(UpdateUserForm));
