import { Component, ReactNode } from 'react';
import { HeightUpdaterProps } from './height-updater.interfaces';

class HeightUpdater extends Component<HeightUpdaterProps, {}> {
  constructor(props: HeightUpdaterProps) {
    super(props);
    this.onWindowResize = this.onWindowResize.bind(this);
  }

  public componentDidMount(): void {
    window.addEventListener(`resize`, this.onWindowResize);
  }

  public componentWillUnmount(): void {
    window.removeEventListener(`resize`, this.onWindowResize);
  }

  public onWindowResize(): void {
    const height = window.innerHeight;
    const { height: propsHeight, onHeightChange } = this.props;
    if (height !== propsHeight) {
      onHeightChange(height);
    }
  }

  public render(): ReactNode {
    return null;
  }
}

export default HeightUpdater;
