import React, { ReactElement, SFC, useCallback, useEffect, useState } from 'react';
import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import { ArrowDropDown as ArrowDropDownIcon, Delete as DeleteIcon, Edit as EditIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import { Column, SortDirection, SortParams, TableData } from './types';

const useStyles = makeStyles({
  actionsCell: {
    padding: 0,
  },
  skeletonButtonsWrapper: {
    alignContent: `center`,
    alignItems: `center`,
    display: `flex`,
    height: `100%`,
    justifyContent: `flex-end`,
    width: `100%`,
  },
  inactiveCategoryActionCell: {
    background: `rgba(245, 245, 245, 0.4)`,
    padding: 0,
  },
  inactiveCategoryCell: {
    background: `rgba(245, 245, 245, 0.4)`,
    opacity: 0.4,
  },
  inactiveCategoryHeader: {
    background: `rgba(245, 245, 245, 0.4)`,
  },
});

interface CategoriesTableProps {
  addCallback?: Function;
  addLabel?: string;
  customColumns?: Column[];
  data: TableData[];
  deleteCallback?: Function;
  editCallback?: Function;
  hideButtons?: boolean;
  isInactiveCategoryTable?: boolean;
  isLoadingUI: boolean;
  keyName: string;
  loadingData: boolean;
  reactivateCallback?: Function;
}

const defaultColumns: Column[] = [
  {
    customRender: undefined,
    label: `Nome`,
    name: `name`,
    sort: SortDirection.desc,
  },
  {
    customRender: undefined,
    label: `Descrição`,
    name: `description`,
  },
  {
    customRender: (date: string): string => new Date(date).toLocaleDateString(`pt-br`),
    label: `Criada em`,
    name: `createdAt`,
  },
];

const SimpleCategoriesTable: SFC<CategoriesTableProps> = ({
  addCallback,
  addLabel,
  customColumns,
  data: propsData,
  deleteCallback,
  editCallback,
  hideButtons,
  isInactiveCategoryTable,
  isLoadingUI,
  keyName,
  loadingData,
  reactivateCallback,
}) => {
  const tableColumns = customColumns ? defaultColumns.concat(customColumns) : defaultColumns;

  const [selected, setSelected] = useState<number | string | null>(null);
  const [columns, setColumns] = useState<Column[]>(tableColumns);
  const [data, setData] = useState<TableData[]>(propsData);
  const classes = useStyles();
  const hasData = !!propsData.length;
  const canDisplayData = hasData && !isLoadingUI;
  const isLoading = (loadingData || isLoadingUI) && !hasData;
  const emptyTable = !hasData && !isLoadingUI && !loadingData;
  const isActiveTable = isInactiveCategoryTable !== true;

  useEffect(() => {
    setData(propsData);
  }, [propsData]);

  const sortCallback = useCallback(({ sortedData, sort }: SortParams): void => {
    setData(sortedData);
    const { column, direction } = sort;
    setColumns(prevColumns => [
      ...prevColumns.map(col => {
        if (col.name === column || (Array.isArray(col.name) && col.name.join(``) === column))
          return { ...col, sort: direction };
        return col;
      }),
    ]);
  }, []);

  const sortHandler = (columnId: string | string[], sort?: SortDirection): void => {
    function sortDirectionHandler(a: TableData, b: TableData): number {
      return Array.isArray(columnId)
        ? JSON.stringify(columnId.reduce((acc: (string | number)[], key) => [...acc, b[key]], [])).localeCompare(
            JSON.stringify(columnId.reduce((acc: (string | number)[], key) => [...acc, a[key]], [])),
          )
        : (b[columnId] || ``).localeCompare(a[columnId]);
    }
    const column = Array.isArray(columnId) ? columnId.join(``) : columnId;
    switch (true) {
      case sort && sort === SortDirection.asc:
        sortCallback({
          sortedData: data.sort((a, b) => sortDirectionHandler(a, b)),
          sort: { column, direction: SortDirection.desc },
        });
        break;
      default:
        sortCallback({
          sortedData: data.sort((a, b) => sortDirectionHandler(b, a)),
          sort: { column, direction: SortDirection.asc },
        });
        break;
    }
  };

  const tableSkeleton = [0, 1, 2, 3, 4, 5].map(
    (item): ReactElement => {
      return (
        <TableRow hover key={item}>
          {columns.map(column => {
            const { name } = column;
            const key = Array.isArray(name) ? `${name.join(``)}${item}` : `${name}${item}`;
            return (
              <TableCell key={key}>
                <Skeleton height={24} width="80%" />
              </TableCell>
            );
          })}
          <TableCell align="right">
            <div className={classes.skeletonButtonsWrapper}>
              <Skeleton height={24} variant="circle" width={24} />
              <Skeleton height={24} variant="circle" width={24} />
            </div>
          </TableCell>
        </TableRow>
      );
    },
  );

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow className={isActiveTable ? undefined : classes.inactiveCategoryHeader}>
            {columns.map(
              (column): ReactElement => {
                const { label, name, sort } = column;
                return (
                  <TableCell key={label} sortDirection={sort}>
                    <TableSortLabel
                      active={!!sort}
                      direction={sort}
                      IconComponent={ArrowDropDownIcon}
                      onClick={(): void => {
                        sortHandler(name, sort);
                      }}
                    >
                      {label}
                    </TableSortLabel>
                  </TableCell>
                );
              },
            )}
            <TableCell align="right">
              {isActiveTable && !hideButtons && (
                <Button color="primary" onClick={(): void => addCallback && addCallback()}>
                  {addLabel || `Criar Categoria`}
                </Button>
              )}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {canDisplayData &&
            data.map(
              (item): ReactElement => {
                const key = item[keyName];
                return (
                  <TableRow
                    hover
                    key={key}
                    onMouseEnter={(): void => setSelected(key)}
                    onMouseLeave={(): void => setSelected(null)}
                    selected={key === selected}
                  >
                    {columns.map((column, columnIdx) => {
                      const { customRender, name } = column;
                      const cellKey = `${keyName}-${item.id}-${columnIdx}`;
                      return (
                        <TableCell key={cellKey} className={isActiveTable ? undefined : classes.inactiveCategoryCell}>
                          {((): string | ReactElement => {
                            const customRenderValue = Array.isArray(name)
                              ? name.map((columnKey: string): string | number => item[columnKey])
                              : item[name];
                            if (typeof customRender === `function`) return customRender(customRenderValue);
                            return Array.isArray(customRenderValue) ? customRenderValue.join(` `) : customRenderValue;
                          })()}
                        </TableCell>
                      );
                    })}
                    <TableCell
                      align="right"
                      className={isActiveTable ? classes.actionsCell : classes.inactiveCategoryActionCell}
                    >
                      {isActiveTable && selected === key && !hideButtons && (
                        <>
                          <IconButton
                            onClick={(): void => {
                              if (!editCallback) return;
                              editCallback(key);
                              setSelected(null);
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            onClick={(): void => {
                              if (!deleteCallback) return;
                              deleteCallback(key);
                              setSelected(null);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </>
                      )}
                      {!isActiveTable && !hideButtons && (
                        <Button color="primary" onClick={(): void => reactivateCallback && reactivateCallback(key)}>
                          reativar categoria
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                );
              },
            )}
          {isLoading && tableSkeleton}
          {emptyTable && (
            <TableRow hover>
              <TableCell colSpan={columns.length + 1}>Nenhuma categoria encontrada!</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SimpleCategoriesTable;
