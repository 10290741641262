import fileDownload from 'js-file-download';
import { buildQueryStringFromArray, buildQueryStringFromObject, filterListToQueryObject } from './queryString';

function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  };
}

export const urlB64ToUint8Array = base64String => {
  const padding = `=`.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, `+`).replace(/_/g, `/`);
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; i += 1) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

export const saveFile = (data, name) => {
  fileDownload(data, name);
};

export const stringToBlob = (data, contentType) => new Blob([data], { type: contentType });

export { buildQueryStringFromArray, buildQueryStringFromObject, filterListToQueryObject, hexToRgb };
