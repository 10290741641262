import React, { ChangeEvent, FC, MouseEvent, useEffect, useRef, useState } from 'react';
import {
  Avatar,
  Button,
  CircularProgress,
  ClickAwayListener,
  Grid,
  InputAdornment,
  Slide,
  Typography,
} from '@material-ui/core';
import { DeleteOutlined as DeleteOutlinedIcon } from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Markdown from 'markdown-to-jsx';
import FieldWithMarkdownMenu from '../FieldWithMarkdownMenu';
import CustomTextField from '../CustomTextField';
import markdownOverrides from '../../../Utils/markdownOverrides';
import { AutoCommentCommonTypes, BaseComment } from './index';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionButton: {
      minHeight: theme.spacing(3.125),
    },
    avatar: {
      height: theme.spacing(3.75),
      width: theme.spacing(3.75),
    },
    avatarContainer: {
      minWidth: `fit-content`,
      maxWidth: `fit-content`,
      paddingRight: theme.spacing(1.25),
      paddingTop: theme.spacing(1),
    },
    bullet: {
      margin: `0 ${theme.spacing(0.5)}px`,
    },
    button: {
      padding: 0,
      minWidth: 0,
      textTransform: `none`,
      width: `fit-content`,
    },
    commentFullName: {
      color: theme.palette.primary.dark,
      fontWeight: 500,
    },
    commentWrapper: {
      background: `#fafafa 0% 0% no-repeat padding-box`,
      borderRadius: theme.spacing(0.75),
      overflow: `hidden`,
      padding: theme.spacing(1.25),
      textAlign: `justify`,
      whiteSpace: `pre-wrap`,
      wordWrap: `break-word`,
    },
    editCommentSaveButton: {
      bottom: theme.spacing(1),
      minHeight: theme.spacing(2.75),
      minWidth: theme.spacing(4.25),
      right: theme.spacing(2),
      position: `absolute`,
    },
    textField: {
      height: `fit-content`,
      paddingLeft: theme.spacing(1),
      width: `100%`,
    },
  }),
);

interface CommentProps extends AutoCommentCommonTypes<BaseComment<any>> {
  disableHoverListener?: boolean;
  disableTouchListener?: boolean;
  deleteCallback?: (id: number | string) => () => void;
  editCallback?: (comment: string, id?: number | string) => (event: MouseEvent<HTMLButtonElement>) => Promise<void>;
  edited?: string;
  isDeleted?: boolean;
  nextIsAutoComment?: boolean;
  previousIsAutoComment?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Comment: FC<CommentProps> = ({
  comment,
  disableHoverListener = false,
  disableTouchListener = false,
  deleteCallback,
  editCallback,
  edited,
  fromNow,
  isDeleted = false,
  genericAvatar,
  nextIsAutoComment = false,
  previousIsAutoComment = false,
}) => {
  const classes = useStyles();
  const commentRef = useRef<HTMLDivElement>(null);
  const [displayButtons, setDisplayButtons] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [savingError, setSavingError] = useState(false);
  const [textAreaRows, setTextAreaRows] = useState<number>(2);
  const [commentText, setCommentText] = useState(comment.comment);
  const commentUser = comment.userDetail;

  useEffect(() => {
    if (commentRef.current && !isEditing) {
      const rowsCalc = Math.floor((commentRef.current.clientHeight - 76) / 19);
      /* istanbul ignore next */
      setTextAreaRows(rowsCalc > 2 ? rowsCalc : 2);
    }
  }, [isEditing]);

  function handleInputChange(
    event: ChangeEvent<HTMLTextAreaElement> | { target: { scrollHeight: number; value: string } },
  ): void {
    setTextAreaRows(Math.floor((event.target.scrollHeight - 10) / 19) || 2);
    setCommentText(event.target.value);
  }

  return (
    <Slide key={comment.id} in direction="up" mountOnEnter unmountOnExit>
      <Grid
        container
        onMouseEnter={!disableHoverListener && !isEditing ? (): void => setDisplayButtons(true) : undefined}
        onMouseLeave={!disableHoverListener && !isEditing ? (): void => setDisplayButtons(false) : undefined}
        onClick={!disableTouchListener ? (): void => setDisplayButtons(prevState => !prevState) : undefined}
        style={{
          marginBottom: nextIsAutoComment ? 0 : 16,
          marginTop: previousIsAutoComment ? 0 : 8,
        }}
        wrap="nowrap"
      >
        <Grid
          className={classes.avatarContainer}
          container
          item
          alignItems="flex-start"
          alignContent="flex-start"
          justify="flex-start"
        >
          <Avatar className={classes.avatar} src={commentUser.avatarThumb || genericAvatar} />
        </Grid>
        <Grid container item>
          {isEditing && (
            <ClickAwayListener
              onClickAway={(event: MouseEvent<HTMLDocument>): void => {
                if (!(event.target as HTMLElement).closest(`[data-is-click-safe="true"]`)) setIsEditing(false);
              }}
            >
              <FieldWithMarkdownMenu inputChangeCallback={handleInputChange} value={commentText}>
                <CustomTextField
                  autoFocus
                  className={classes.textField}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          className={`${classes.button} ${classes.editCommentSaveButton}`}
                          color="primary"
                          onClick={
                            editCallback
                              ? async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
                                  setIsSaving(true);
                                  try {
                                    await editCallback(commentText, comment.id)(event);
                                    setIsEditing(false);
                                  } catch (error) {
                                    setSavingError(true);
                                  } finally {
                                    setIsSaving(false);
                                  }
                                }
                              : undefined
                          }
                          size="small"
                        >
                          {isSaving ? <CircularProgress size={24} /> : `Salvar`}
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                  error={savingError}
                  helperText={
                    savingError ? `Ocorreu um erro ao tentar editar o comentário, tente novamente.` : undefined
                  }
                  id={`comment${comment.id}`}
                  label="Editar comentário"
                  margin="none"
                  multiline
                  onChange={handleInputChange}
                  rows={textAreaRows}
                  value={commentText}
                />
              </FieldWithMarkdownMenu>
            </ClickAwayListener>
          )}
          <Grid
            className={classes.commentWrapper}
            container
            justify="flex-start"
            ref={commentRef}
            style={{
              position: isEditing ? `fixed` : undefined,
              visibility: isEditing ? `hidden` : undefined,
            }}
          >
            <Grid alignContent="center" alignItems="center" container item justify="space-between" xs={12}>
              <Grid item>
                <Typography className={classes.commentFullName} display="inline" variant="body2">
                  {commentUser.fullName} &nbsp;
                </Typography>
                {edited && (
                  <Typography color="textSecondary" component="span" display="inline" variant="body2">
                    (editado {edited.toLowerCase()})
                  </Typography>
                )}
              </Grid>
              <Typography color="textSecondary" variant="body2">
                {fromNow}
              </Typography>
            </Grid>
            <Grid item style={{ minHeight: 24 }} xs={11} sm={12}>
              {!isDeleted && (
                <Markdown
                  options={{
                    overrides: markdownOverrides,
                  }}
                >
                  {comment.comment}
                </Markdown>
              )}
              {isDeleted && (
                <Grid alignItems="center" container>
                  <Grid item>
                    <DeleteOutlinedIcon color="disabled" />
                  </Grid>
                  <Grid item>
                    <Typography color="textSecondary" variant="body2">
                      Este comentário foi apagado
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
            {displayButtons && (
              <Grid alignItems="flex-end" container item justify="flex-end" xs={11} sm={12}>
                <Button
                  className={`${classes.actionButton} ${classes.button}`}
                  color="primary"
                  size="small"
                  onClick={(event: MouseEvent<HTMLButtonElement>): void => {
                    event.stopPropagation();
                    setIsEditing(true);
                  }}
                >
                  Editar
                </Button>
                <Typography className={classes.bullet} color="primary" display="inline" variant="body1">
                  •
                </Typography>
                <Button
                  className={`${classes.actionButton} ${classes.button}`}
                  color="primary"
                  onClick={
                    deleteCallback
                      ? (): void => {
                          deleteCallback(comment.id)();
                        }
                      : undefined
                  }
                  size="small"
                >
                  Excluir
                </Button>
              </Grid>
            )}
            {!displayButtons && <Grid item className={classes.actionButton} xs={11} sm={12} />}
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
};

export default Comment;
