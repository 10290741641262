import React from 'react';
/**
 * Redux dependencies
 */
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
/**
 * Material UI - Core
 */
import { Badge, Button, Hidden, IconButton, LinearProgress, Tooltip, Typography, withStyles } from '@material-ui/core';
/**
 * Material UI - Icons
 */
import { Add, Clear, Done, Error as ErrorIcon } from '@material-ui/icons';
/**
 * MUI-Datatable
 */
import MUIDataTable from 'mui-datatables';
/**
 * ExternalDocs actions
 */
import * as externalDocsActions from '../../ReduxFlow/Reducers/ExternalDocs/Actions';
/**
 * My components
 */
import RangePicker from '../../Components/RangePicker';
import DialogExtDocsForm from './DialogExtDocsForm';
import MobileExternalDocs from './MobileExternalDocs';
import api from '../../Services/api';
import { buildQueryStringFromObject, saveFile } from '../../Utils';
import * as muiDatatableHelper from '../../Utils/muiDatatableHelper';

const styles = theme => ({
  smRecipient: {
    height: `calc(100vh - 56px)`,
    overflowY: `auto`,
  },
  button: {
    position: `relative`,
    top: 0,
    left: 0,
  },
  institutionAvatar: {
    borderRadius: `50%`,
    transition: `transform .3s`,
    '&:hover': {
      transform: `scale(3)`,
    },
  },
  markerRoot: {
    fontSize: 12,
    height: 15,
  },
  markerChildren: {
    padding: `0 6px 0 6px`,
  },
});

class ExternalDocs extends React.PureComponent {
  state = {
    downloadingReport: false,
  };

  componentDidMount() {
    const {
      changeExternalDocsFilters,
      filterValue,
      handleFetchStatus,
      history,
      loadExternalDocsRequest,
      loggedUserInfo,
      openExternalDocsRequest,
    } = this.props;
    const { isLoadingUI } = loggedUserInfo;
    const { currentOrganization } = loggedUserInfo.data;
    const localStorageOrg = window.localStorage.getItem(`currentOrganization`);
    if (localStorageOrg === JSON.stringify(currentOrganization) && !isLoadingUI) {
      if (filterValue.status) {
        changeExternalDocsFilters({
          filterList: { isActive: [`Ativo`] },
          searchText: filterValue.value,
        });
        handleFetchStatus();
      } else {
        loadExternalDocsRequest();
      }
      const parameters = new URLSearchParams(history.location.search);
      if (parameters.get(`id`)) {
        history.replace(history.location.pathname);
        openExternalDocsRequest({ id: parameters.get(`id`) });
      }
    }
  }

  componentDidUpdate() {
    const { changeExternalDocsFilters, filterValue, handleFetchStatus } = this.props;
    if (filterValue.status) {
      changeExternalDocsFilters({
        filterList: { isActive: [`Ativo`] },
        searchText: filterValue.value,
      });
      handleFetchStatus();
    }
  }

  handleScroll = event => {
    event.stopPropagation();
    const { changeExternalDocsFilters, changeExternalDocsPages, propsExternalDocs, filterValue } = this.props;
    clearTimeout(this.scrollTimeout);
    if (
      propsExternalDocs.data.length < propsExternalDocs.count &&
      event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight
    ) {
      this.scrollTimeout = setTimeout(() => {
        if (filterValue.value) {
          changeExternalDocsFilters({
            filterList: { isActive: [`Ativo`] },
            searchText: filterValue.value,
          });
        } else {
          changeExternalDocsPages({ rowsPerPage: ExternalDocs.rowsPerPage + 15, page: 0 });
        }
      }, 200);
    }
  };

  render() {
    const { downloadingReport } = this.state;
    const {
      avatar,
      changeExternalDocsFilters,
      changeExternalDocsPages,
      changeExternalDocsSort,
      classes,
      closeExternalDocsRequest,
      closeExternalDocsSuccess,
      propsExternalDocs,
      Institutions,
      loadExternalDocsRequest,
      Sectors,
      Suppliers,
      openExternalDocsRequest,
    } = this.props;

    const columns = [
      {
        label: `Título`,
        name: `tableTitle`,
        options: {
          filter: true,
          customFilterListRender: v => `Título: ${v}`,
          filterList: propsExternalDocs.filterList.tableTitle,
          customBodyRender: value => {
            return (
              <>
                <Typography>{value.title}</Typography>
                <Typography color="textSecondary" style={{ marginRight: 5 }}>
                  <small>{`#${value.id}`}</small>
                </Typography>
              </>
            );
          },
        },
      },
      muiDatatableHelper.buildInstitutionColumn(Institutions, propsExternalDocs.filterList.institution, {
        name: `institution`,
      }),
      {
        label: `Categoria`,
        name: `categories`,
        options: {
          filter: true,
          filterList: propsExternalDocs.filterList.categories,
          customFilterListRender: v => `Categoria: ${v}`,
        },
      },
      {
        label: `Validade`,
        name: `validity`,
        options: {
          filter: true,
          filterList: propsExternalDocs.filterList.validity,
          filterType: `custom`,
          customBodyRender: (value, tableMeta, updateValue) => {
            return value
              ? new Date(`${value}T00:01`).toLocaleString([`pt-br`], {
                  year: `numeric`,
                  month: `2-digit`,
                  day: `2-digit`,
                })
              : `Indeterminado`;
          },
          customFilterListRender: v => {
            if (v.length) {
              return `Datas: ${v[0].toLocaleDateString(`pt-br`)} - ${v[1].toLocaleDateString(`pt-br`)}`;
            }
            return false;
          },
          filterOptions: {
            names: [],
            display: (filterList, onChange, index, column) => {
              return (
                <RangePicker
                  startDate={filterList[index].length ? filterList[index][0] : undefined}
                  endDate={filterList[index].length ? filterList[index][1] : undefined}
                  label="Validade"
                  handleSelect={event => {
                    if (event.eventType === 3) {
                      onChange([event.start, event.end], index, column);
                    }
                  }}
                />
              );
            },
          },
        },
      },
      {
        label: `Ativo`,
        name: `isActive`,
        options: {
          filter: true,
          customFilterListRender: v => `Ativo: ${v}`,
          filterList: propsExternalDocs.filterList.isActive,
          filterType: `dropdown`,
          filterOptions: {
            names: [`Ativo`, `Inativo`],
          },
          customBodyRender: (value, tableMeta, updateValue) => (value ? <Done /> : <Clear />),
        },
      },
      {
        label: `Responsável`,
        name: `ownerFullName`,
        options: {
          filter: true,
          customFilterListRender: v => `Responsável: ${v}`,
          filterList: propsExternalDocs.filterList.ownerFullName,
        },
      },
      {
        label: `Id`,
        name: `tableTitle.id`,
        options: {
          filter: false,
          display: `excluded`,
        },
      },
      {
        label: `Setor`,
        name: `sector`,
        options: {
          filter: true,
          customFilterListRender: v => `Setor: ${v}`,
          filterList: propsExternalDocs.filterList.sector,
          filterType: `multiselect`,
          display: `excluded`,
          filterOptions: {
            names: Sectors.map(sector => sector.name),
          },
        },
      },
      {
        label: `Valor`,
        name: `cost`,
        options: {
          customFilterListRender: v => `Valor: ${v}`,
          customBodyRender: value =>
            value ? parseInt(value, 10).toLocaleString(`pt-br`, { style: `currency`, currency: `BRL` }) : ``,
          filter: false,
        },
      },
      {
        label: `Fornecedor`,
        name: `supplier`,
        options: {
          filter: true,
          customFilterListRender: v => `Fornecedor: ${v}`,
          filterList: propsExternalDocs.filterList.supplier,
          filterType: `multiselect`,
          display: `excluded`,
          filterOptions: {
            names: Suppliers.map(s => s.name),
          },
        },
      },
    ];
    const options = {
      download: !downloadingReport,
      rowsPerPage: propsExternalDocs.rowsPerPage,
      viewColumns: false,
      print: false,
      fixedHeader: true,
      selectableRows: `none`,
      filter: true,
      filterType: `textField`,
      responsive: `stacked`,
      serverSide: true,
      count: propsExternalDocs.count,
      page: propsExternalDocs.page,
      searchText: propsExternalDocs.searchText,
      customToolbar: () => {
        const { expiredOrExpiring, page, searchText } = propsExternalDocs;
        return (
          <>
            <Tooltip title="Vencidos ou a vencer">
              <IconButton
                onClick={() =>
                  changeExternalDocsFilters({
                    page,
                    searchText,
                    sort: { 3: { order: `asc` } },
                    filterList: {
                      hasValidity: [true],
                      isActive: [`Ativo`],
                    },
                  })
                }
              >
                <Badge badgeContent={expiredOrExpiring} color="secondary" style={{ marginRight: 10 }}>
                  <ErrorIcon />
                </Badge>
              </IconButton>
            </Tooltip>
            <Tooltip title="Adicionar documento">
              <Button
                aria-label="Adicionar"
                aria-owns="addMenu"
                variant="contained"
                color="primary"
                onClick={event => openExternalDocsRequest({ id: null })}
              >
                <Add /> Adicionar
              </Button>
            </Tooltip>
          </>
        );
      },
      textLabels: {
        body: {
          noMatch: `Desculpe, nenhum registro encontrado!`,
          toolTip: `Ordenar`,
        },
        pagination: {
          next: `Próxima página`,
          previous: `Págima anterior`,
          rowsPerPage: `Linhas por página:`,
          displayRows: `de`,
        },
        toolbar: {
          search: `Procurar`,
          downloadCsv: `Download CSV`,
          print: `Imprimir`,
          viewColumns: `Colunas visíveis`,
          filterTable: `Filtrar a tabela`,
        },
        filter: {
          title: `Filtros`,
          reset: `Resetar`,
        },
        viewColumns: {
          title: `Mostrar colunas`,
          titleAria: `Mostrar/Ocultar Colunas da Tabela`,
        },
        selectedRows: {
          text: `linha(s) selecionada(s)`,
          delete: `Deletar`,
          deleteAria: `Deletear linha(s) selecionada(s)`,
        },
      },
      onRowClick: rowData => {
        openExternalDocsRequest({ id: rowData[6] });
      },
      onTableChange: (action, tableState) => {
        const { filterList, page, rowsPerPage, searchText } = tableState;
        const filters = filterList.reduce(
          (acc, v, i) => (v.length ? Object.assign(acc, { [columns[i].name]: v }) : acc),
          {},
        );
        switch (action) {
          case `sort`:
            changeExternalDocsSort({
              activeColumn: tableState.activeColumn,
              sort: tableState.announceText.split(` : `)[1],
            });
            break;
          case `resetFilters`:
          case `filterChange`:
            changeExternalDocsFilters({ page, filterList: filters, searchText });
            break;
          case `search`:
            clearTimeout(this.searchTimeout);
            this.searchTimeout = setTimeout(() => {
              changeExternalDocsFilters({ page, filterList: filters, searchText });
            }, 500);
            break;
          case `changeRowsPerPage`:
          case `changePage`:
            changeExternalDocsPages({ page, rowsPerPage });
            break;
          default:
        }
      },
      onDownload: () => {
        this.setState({
          downloadingReport: true,
        });
        const query = buildQueryStringFromObject(propsExternalDocs.filterList, propsExternalDocs.searchText);
        let sort = ``;
        if (Object.entries(propsExternalDocs.sort).length !== 0) {
          sort = `&sort=${Object.keys(propsExternalDocs.sort)[0]},${
            propsExternalDocs.sort[Object.keys(propsExternalDocs.sort)[0]].order
          }`;
        }
        const url = `/externaldocs/externaldocs/getreport/?page=${propsExternalDocs.page + 1}&length=${
          propsExternalDocs.rowsPerPage
        }&${query}${sort}`;
        api
          .get(url, {
            responseType: `blob`,
          })
          .then(res => {
            saveFile(res.data, `report.xlsx`);
            this.setState({
              downloadingReport: false,
            });
          })
          .catch(error => {
            // eslint-disable-next-line
            console.error(error);
            this.setState({
              downloadingReport: false,
            });
          });
        return false;
      },
    };

    return (
      <>
        <Hidden only={[`md`, `lg`, `xl`]}>
          <div className={classes.smRecipient} onScroll={this.handleScroll}>
            <MobileExternalDocs
              data={propsExternalDocs.data}
              institutions={Institutions}
              isLoading={propsExternalDocs.isLoading}
              handleOpen={openExternalDocsRequest}
            />
          </div>
          <DialogExtDocsForm
            avatar={avatar}
            fullscreen
            open={propsExternalDocs.modalOpen}
            handleClose={closeExternalDocsRequest}
            formInstance={propsExternalDocs.formInstance}
            getData={loadExternalDocsRequest}
            resetFormInstance={closeExternalDocsSuccess}
          />
        </Hidden>
        <Hidden only={[`xs`, `sm`]}>
          {propsExternalDocs.isLoading && <LinearProgress style={{ position: `relative` }} />}
          <MUIDataTable title="Documentos externos" data={propsExternalDocs.data} columns={columns} options={options} />
          <DialogExtDocsForm
            avatar={avatar}
            open={propsExternalDocs.modalOpen}
            handleClose={closeExternalDocsRequest}
            formInstance={propsExternalDocs.formInstance}
            getData={loadExternalDocsRequest}
            resetFormInstance={closeExternalDocsSuccess}
          />
        </Hidden>
      </>
    );
  }
}

const mapStateToProps = state => ({
  propsExternalDocs: state.ExternalDocs,
  loggedUserInfo: state.Auth,
  Sectors: state.Sectors.data,
  Institutions: state.Institutions.data,
  Suppliers: state.Suppliers.data,
});

const mapDispatchToProps = dispatch => bindActionCreators(externalDocsActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(withRouter(ExternalDocs)));
