import { ChangeEvent, Dispatch, ReactNode, SetStateAction } from 'react';
import { SlaKpis } from 'ReduxFlow/Reducers/types';
import { Institution } from 'ReduxFlow/Reducers/Institutions/Types';
import { Sector } from 'ReduxFlow/Reducers/Sectors/Types';
import { User } from 'ReduxFlow/Reducers/Auth/Types';
import { TasksCategoryState } from 'ReduxFlow/Reducers/TasksCategories/Types';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { DropzoneInputProps, DropzoneRootProps, FileWithPath } from 'react-dropzone';
import { Comment } from 'Components/NewUIComponents/CommentsContainer/Tasks';
import { Attach } from '../TaskAttachsContainer';

interface CommonFields {
  description: string;
  estimatedEndDate: string | null;
  institution?: number | number[];
  requester?: number | number[];
  requesterSector: number | string;
  responsible?: number | number[];
  responsibleSector?: number | string;
  sector: string | number[];
  startDate: string | null;
  subcategory?: number;
  title: string;
  users?: number | number[];
}

interface CommonInstance extends CommonFields {
  feedbackTime?: string;
  finisher?: string;
  finishTime?: string;
  id: number;
  isActive: boolean;
  updatedAt?: string;
}

interface CommonError {
  description?: string;
  institution?: string;
  requester?: string;
  requesterSector?: string;
  responsible?: string;
  responsibleSector?: string;
  sector?: string;
  title?: string;
  users?: string;
}

export interface CommonTasksProps {
  formErrors?: CommonError;
  formInstance: CommonInstance;
  isLoading: boolean;
  isSaving: boolean;
  slaKpis?: SlaKpis;
}

// SavingInfo Component
export enum TaskTypes {
  CALL = `Chamado`,
  NCS = `Não conformidade`,
  OMBUDSMAN = `Ouvidoria`,
  PREVENTIVE = `Preventiva`,
  TASK = `Tarefa`,
}

export interface SavingInfoProps {
  isMobile: boolean;
  isSaving: boolean;
  lastSaveDate?: string;
  taskId: number;
  taskType: string;
}

// RightColumn Component
export interface RightColumnProps {
  classes: {
    customAutocomplete: string;
    customInstitutionsField: string;
    [key: string]: string;
  };
  commonFields: CommonFields;
  commonTasksProps: CommonTasksProps;
  customContextField: ReactNode;
  disableAllFields: boolean;
  formCategoryText: (disabled: boolean, text: string) => ReactNode;
  handleFieldChange: (name: keyof CommonFields) => (event: ChangeEvent<HTMLInputElement>) => void;
  handleInstitutionChange: (value: number | number[]) => void;
  handleReactSelectChange: (name: keyof CommonFields) => (value?: number | number[]) => void;
  handleToggleEditing: (name: keyof CommonFields, value?: boolean) => () => void;
  isEditingFields: { [key in keyof CommonInstance]?: boolean };
  institutions: Institution[];
  loggedUser: User;
  sectors: Sector[];
}

// FormHeader Component
export interface FormHeaderProps {
  disableAllFields?: boolean;
  disableCloseReopenButton: boolean;
  formHeaderFields: CommonFields;
  formHeaderTasksProps: CommonTasksProps;
  fullScreen: boolean;
  handleCloseDialog: () => void;
  handleSaveEditing: (name: keyof CommonFields) => (value: string) => void;
  handleToggleEditing: (name: keyof CommonFields, value?: boolean) => () => void;
  isEditingFields: { [key in keyof CommonFields]?: boolean };
  setCloseReopenDialog: Dispatch<SetStateAction<boolean>>;
}

// FormBody Component
export interface FormBodyProps {
  attachs: Attach[];
  comment: string;
  comments: Comment[];
  commentsControll: (total: number) => (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  commentsRange: number;
  disableAllFields: boolean;
  disableDescription: boolean;
  formBodyFields: CommonFields;
  formBodyTasksProps: CommonTasksProps;
  formCategoryText: (disabled: boolean, text: string) => ReactNode;
  fullScreen: boolean;
  getInputProps: (props?: DropzoneInputProps) => DropzoneInputProps;
  getRootProps: (props?: DropzoneRootProps) => DropzoneRootProps;
  handleCommentDelete: (id: string | number) => (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  handleCommentSubmit: (
    comment: string,
    id?: string | number,
  ) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<void>;
  handleDateFieldChange: (name: keyof CommonFields) => (event: MaterialUiPickersDate) => void;
  handleFileDelete: (id: string | number) => (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  handleSaveEditing: (name: keyof CommonFields) => (value: string) => void;
  handleSubCategoryChange: (
    event: ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => void;
  handleToggleEditing: (name: keyof CommonFields, value?: boolean) => () => void;
  isEditingFields: { [key in keyof CommonFields]?: boolean };
  isGettingComments: boolean;
  isSaving: boolean;
  loggedUser: User;
  setComment: Dispatch<SetStateAction<string>>;
  specificTaskInput?: ReactNode;
  tasksCategoriesState: TasksCategoryState;
  temporaryAttachs: FileWithPath[];
  temporaryAttachsHasError?: { [key: number]: boolean };
  temporaryAttachsProgress?: { [key: number]: number };
  updateFormHandleFileSubmit: (files: FileWithPath[]) => Promise<void>;
}
