import React, { FC, MouseEvent } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AttachWrapper from './AttachWrapper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    attachmentList: {
      justifyContent: `space-between`,
    },
    clickHereText: {
      color: theme.palette.primary.dark,
      cursor: `pointer`,
      fontWeight: 500,
    },
    disabled: {
      opacity: 0.4,
      pointerEvents: `none`,
    },
    inputLabel: {
      background: `#fbf9ff 0% 0% no-repeat padding-box`,
      border: `1px dashed #d5d2db`,
      borderRadius: theme.spacing(0.75),
      color: `#968ea4`,
      marginBottom: theme.spacing(0.75),
      minHeight: theme.spacing(5.375),
      width: `100%`,
      '&: > label > input': {
        display: `none`,
      },
    },
    typography: {
      fontSize: theme.spacing(1.75),
      padding: `${theme.spacing(1.875)}px 0px`,
    },
  }),
);

export interface Attach {
  attachment: string;
  id: number;
  externaldocs: number;
  user: number;
}

interface ExtDocAttachsContainerProps {
  attachs: (Attach | File)[];
  deleteCallback?: (id?: number | string) => (event?: MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  disableTitle?: boolean;
}

const ExtDocAttachsContainer: FC<ExtDocAttachsContainerProps> = ({
  attachs,
  deleteCallback,
  disabled,
  disableTitle,
}) => {
  const classes = useStyles();

  return (
    <Grid className={disabled ? classes.disabled : undefined} container>
      <Grid item xs={12}>
        {!disableTitle && (
          <Typography align="left" variant="body2">
            <strong>Anexos</strong>
          </Typography>
        )}
        {!!attachs.length && (
          <Grid alignContent="center" alignItems="center" container className={classes.attachmentList}>
            {attachs.map(attach => (
              <AttachWrapper
                attach={attach}
                deleteCallback={deleteCallback}
                key={`id` in attach ? attach.id : (attach as File).name}
              />
            ))}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default ExtDocAttachsContainer;
