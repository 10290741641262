import { action } from 'typesafe-actions';
import { QCMarkersTypes, QCMarker, QCMarkerError } from './Types';

export const loadRequest = (token: string) => action(QCMarkersTypes.LOAD_REQUEST, { token });
export const loadSuccess = (data: QCMarker[]) => action(QCMarkersTypes.LOAD_SUCCESS, { data });
export const loadFailure = () => action(QCMarkersTypes.LOAD_FAILURE);
export const addRequest = (data = {}) => action(QCMarkersTypes.ADD_REQUEST, data);
export const addSuccess = (data: QCMarker) => action(QCMarkersTypes.ADD_SUCCESS, { data });
export const addFailure = (errors: QCMarkerError) => action(QCMarkersTypes.ADD_FAILURE, { errors });
export const updateRequest = (data: { id: number; data: {} }) =>
  action(QCMarkersTypes.UPDATE_REQUEST, data);
export const updateSuccess = (data: QCMarker) => action(QCMarkersTypes.UPDATE_SUCCESS, { data });
export const updateFailure = (errors: QCMarkerError) =>
  action(QCMarkersTypes.UPDATE_FAILURE, { errors });
