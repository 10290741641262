import React, { FC } from 'react';
import { Typography } from '@material-ui/core';
import { CloudUploadOutlined as CloudUploadOutlinedIcon } from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    darkColor: {
      color: theme.palette.primary.dark,
    },
    dropZone: {
      alignItems: `center`,
      alignContent: `center`,
      background: `#FBF9FF 0% 0% no-repeat padding-box`,
      border: `2px dashed ${theme.palette.primary.dark}`,
      borderRadius: theme.spacing(0.75),
      display: `flex`,
      flexDirection: `column`,
      flexWrap: `nowrap`,
      height: `100%`,
      justifyContent: `center`,
      opacity: 0.93,
      position: `absolute`,
      width: `100%`,
      zIndex: 1300,
    },
  }),
);

interface DropZoneIndicatorProps {
  isDragActive: boolean;
}

const DropZoneIndicator: FC<DropZoneIndicatorProps> = ({ isDragActive }) => {
  const classes = useStyles();

  return (
    <div className={classes.dropZone} style={{ display: !isDragActive ? `none` : undefined }}>
      <Typography>
        <CloudUploadOutlinedIcon className={classes.darkColor} fontSize="large" />
      </Typography>
      <Typography className={classes.darkColor}>Solte seus arquivos aqui</Typography>
    </div>
  );
};

export default DropZoneIndicator;
