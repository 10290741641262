import React, { ReactNode } from 'react';
import { CreateAutoComment, InactivationAutoComment, ReactivationAutoComment } from './AutoComments';
import CommentsContainer, { BaseComment, ContainerCfg, GetCommentComponentProps, getFromNow } from '..';

export enum CommentType {
  CREATE = 'USER_HISTORY_CREATE', // eslint-disable-line
  INACTIVATE = 'USER_HISTORY_INACTIVATE', // eslint-disable-line
  REACTIVATE = 'USER_HISTORY_REACTIVATE', // eslint-disable-line
}

export interface Comment extends BaseComment<CommentType> {
  actionType: CommentType;
  createdBy: number;
  formalDate?: string;
  user: number;
}

const isAutoComment = (commentType: CommentType): boolean => true;

const getCommentComponent = ({
  comment,
  isFirstItem,
  isLastItem,
  previousIsAutoComment,
  nextIsAutoComment,
  genericAvatar,
}: GetCommentComponentProps<Comment>): ReactNode => {
  const { actionType, date } = comment;
  const fromNow = getFromNow(date);

  switch (actionType) {
    case CommentType.CREATE:
      return (
        <CreateAutoComment
          comment={comment}
          fromNow={fromNow}
          genericAvatar={genericAvatar}
          isFirstItem={isFirstItem}
          isLastItem={isLastItem}
          key={comment.id}
          nextIsAutoComment={nextIsAutoComment}
          previousIsAutoComment={previousIsAutoComment}
        />
      );
    case CommentType.INACTIVATE:
      return (
        <InactivationAutoComment
          comment={comment}
          fromNow={fromNow}
          genericAvatar={genericAvatar}
          isFirstItem={isFirstItem}
          isLastItem={isLastItem}
          key={comment.id}
          nextIsAutoComment={nextIsAutoComment}
          previousIsAutoComment={previousIsAutoComment}
        />
      );
    case CommentType.REACTIVATE:
      return (
        <ReactivationAutoComment
          comment={comment}
          fromNow={fromNow}
          genericAvatar={genericAvatar}
          isFirstItem={isFirstItem}
          isLastItem={isLastItem}
          key={comment.id}
          nextIsAutoComment={nextIsAutoComment}
          previousIsAutoComment={previousIsAutoComment}
        />
      );
    default:
      console.log(`Warning: unrecognized user history type: ${actionType}`)
      return <span key={comment.id} />;
  }
};

const UserContainerCfg: ContainerCfg<Comment, CommentType> = {
  getCommentComponent,
  isAutoComment,
};

const UserCommentsContainer = CommentsContainer(UserContainerCfg);

export default UserCommentsContainer;
