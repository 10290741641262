import React, { FC } from 'react';
import { Avatar } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AutoComment from '../../AutoComment';
import { AutoCommentCommonTypes } from '../..';
import { Comment } from '..';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      height: theme.spacing(3),
      width: theme.spacing(3),
    },
    container: {
      marginTop: theme.spacing(2.5),
    },
  }),
);

type CreateAutoCommentProps = AutoCommentCommonTypes<Comment> & { taskType: string };

const CreateAutoComment: FC<CreateAutoCommentProps> = ({
  comment,
  fromNow,
  genericAvatar,
  isLastItem,
  nextIsAutoComment,
  previousIsAutoComment,
  taskType,
}) => {
  const classes = useStyles();
  const { userDetail } = comment;

  return (
    <AutoComment
      avatar={<Avatar className={classes.avatar} src={userDetail.avatarThumb || genericAvatar} />}
      componentClasses={{
        root: classes.container,
      }}
      fromNow={fromNow}
      headerActionText={`abriu ${taskType !== `chamado` ? `a ${taskType}` : `o ${taskType}`}`}
      isFirstItem
      isLastItem={isLastItem}
      nextIsAutoComment={nextIsAutoComment}
      previousIsAutoComment={previousIsAutoComment}
      userName={userDetail.fullName}
    />
  );
};

export default CreateAutoComment;
