export const getTaskTypeName = (type: string): string => {
  switch (type) {
    case `calls`:
      return `Chamados`;
    case `ncs`:
      return `Não-conformidades`;
    case `ombudsmans`:
      return `Ouvidorias`;
    case `preventives`:
      return `Preventivas`;
    default:
      return `Tarefas`;
  }
};
