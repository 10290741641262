import React, { ReactElement, SFC, useEffect, useState, MouseEvent } from 'react';
import { useDialog } from 'muibox';
import { Accordion, AccordionSummary, Button, Grid, LinearProgress, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';

import { CompoundCategoryDialog, CompoundCategoryState, SimpleCategoryDialog } from '../../ReduxFlow/Reducers/types';
import { Column } from '../../Components/Categories/SimpleCategoriesTable/types';
import CompoundCategoriesPanel, { AdditionalFields } from '../../Components/Categories/CompoundCategoriesPanel';
import DeleteConfirmDialog from '../../Components/CategoriesForm/DeleteConfirmDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ltr: {
      direction: `rtl`,
    },
  }),
);

type CompoundCategoriesProps = {
  additionalFields?: AdditionalFields[];
  addUpdateDialog: React.ReactNode;
  addUpdateSubCategoryDialog: React.ReactNode;
  addOrUpdateRequest: Function;
  addOrUpdateSubCategoryRequest: Function;
  categories: CompoundCategoryState;
  customSubcategoryColumns?: Column[];
  isLoadingUI: boolean;
  loadRequests: Function[];
  openCategoryRequest: Function;
  openSubCategoryRequest: Function;
  showInactive?: boolean;
};

const CompoundCategories: SFC<CompoundCategoriesProps> = ({
  additionalFields,
  addUpdateDialog,
  addUpdateSubCategoryDialog,
  addOrUpdateRequest,
  addOrUpdateSubCategoryRequest,
  categories,
  customSubcategoryColumns,
  isLoadingUI,
  loadRequests,
  openCategoryRequest,
  openSubCategoryRequest,
  showInactive,
}) => {
  const classes = useStyles();
  const dialog = useDialog();
  const { data: propsData, loading: loadingData, dialogOpen, isSaving, subCategoryDialogOpen } = categories;
  const activeCategories = propsData.filter(v => v.isActive);
  const inactiveCategories = propsData.filter(v => !v.isActive);
  const hasData = !!propsData.length;
  const canDisplayData = hasData && !isLoadingUI;
  const isLoading = (loadingData || isLoadingUI) && !hasData;
  const [deleteDialog, setDeleteDialog] = useState<{ open: boolean; category: CompoundCategoryDialog }>({
    open: false,
    category: { name: `Nenhuma categoria selecionada` },
  });
  const [deleteSubCategoryDialog, setDeleteSubCategoryDialog] = useState<{
    open: boolean;
    category: SimpleCategoryDialog;
  }>({
    open: false,
    category: { name: `Nenhuma categoria selecionada` },
  });

  useEffect(() => {
    if (!isLoadingUI) {
      loadRequests.forEach(request => request(dialog));
    }
  }, [isLoadingUI, loadRequests, dialog]);

  const deleteCallback = (category: CompoundCategoryDialog) => (event: any): void => {
    event.stopPropagation();
    if (category.id) {
      setDeleteDialog({
        open: true,
        category,
      });
    }
  };

  const deleteSubCategoryCallback = (category: CompoundCategoryDialog, subcategory: SimpleCategoryDialog): void => {
    if (subcategory.id) {
      setDeleteSubCategoryDialog({
        open: true,
        category: subcategory,
      });
    }
  };

  const deleteSubmit = (): void => {
    const { category } = deleteDialog;
    const postData = { id: category.id, isActive: false };
    addOrUpdateRequest({ dialog, postData });
    setDeleteDialog({
      open: false,
      category: { name: `Nenhuma categoria selecionada` },
    });
  };

  const deleteSubCategorySubmit = (): void => {
    const { category } = deleteSubCategoryDialog;
    const postData = { id: category.id, isActive: false };
    addOrUpdateSubCategoryRequest({ dialog, postData });
    setDeleteSubCategoryDialog({
      open: false,
      category: { name: `Nenhuma categoria selecionada` },
    });
  };

  const editCallback = (category: CompoundCategoryDialog) => (event: MouseEvent): void => {
    event.stopPropagation();
    if (category.id) openCategoryRequest({ dialog, id: category.id });
  };

  const editSubCategoryCallback = (category: CompoundCategoryDialog, subcategory: SimpleCategoryDialog): void => {
    if (category.id) openSubCategoryRequest({ dialog, category, subcategory });
  };

  const reactivateCallback = (id: number): void => {
    const postData = { id, isActive: true };
    addOrUpdateRequest({ dialog, postData });
  };

  const reactivateSubCatecoryCallback = (category: CompoundCategoryDialog, subcategory: SimpleCategoryDialog): void => {
    const postData = { id: subcategory.id, isActive: true };
    addOrUpdateSubCategoryRequest({ dialog, category, postData });
  };

  const skeleton = [0, 1, 2, 3, 4, 5].map(
    (item): ReactElement => {
      return (
        <Accordion key={item}>
          <AccordionSummary expandIcon={<Skeleton height={48} variant="circle" width={48} />}>
            <Grid container alignItems="center">
              <Grid item xs={3}>
                <Skeleton height={48} width="80%" />
              </Grid>
              <Grid item xs={7}>
                <Skeleton height={48} width="80%" />
              </Grid>
              <Grid item xs={2} className={classes.ltr}>
                <span style={{ display: `flex` }}>
                  <Skeleton height={48} variant="circle" width={48} />
                  <Skeleton height={48} variant="circle" width={48} />
                </span>
              </Grid>
            </Grid>
          </AccordionSummary>
        </Accordion>
      );
    },
  );

  return (
    <>
      {(loadingData || isSaving) && <LinearProgress />}
      {isLoading && skeleton}
      {canDisplayData && (
        <>
          <Grid container>
            <Grid item xs={12} className={classes.ltr}>
              <Button color="primary" onClick={(): void => openCategoryRequest(dialog)}>
                Criar Categoria
              </Button>
            </Grid>
          </Grid>

          <CompoundCategoriesPanel
            additionalFields={additionalFields}
            addSubCategoryCallback={openSubCategoryRequest}
            categories={activeCategories}
            customSubcategoryColumns={customSubcategoryColumns}
            deleteCallback={deleteCallback}
            deleteSubCategoryCallback={deleteSubCategoryCallback}
            editCallback={editCallback}
            editSubCategoryCallback={editSubCategoryCallback}
            isLoadingUI={isLoadingUI}
            loadingData={loadingData}
            reactivateSubCatecoryCallback={reactivateSubCatecoryCallback}
            showInactive={showInactive}
          />
          {showInactive && !!inactiveCategories.length && (
            <>
              <Typography variant="body1" style={{ marginTop: `20px`, marginBottom: `10px` }}>
                Categorias desativadas
              </Typography>
              <CompoundCategoriesPanel
                categories={inactiveCategories}
                customSubcategoryColumns={customSubcategoryColumns}
                isInactiveCategoryTable
                isLoadingUI={isLoadingUI}
                loadingData={loadingData}
                reactivateCallback={reactivateCallback}
                showInactive={showInactive}
              />
            </>
          )}
        </>
      )}
      {dialogOpen && addUpdateDialog}
      {subCategoryDialogOpen && addUpdateSubCategoryDialog}
      {deleteDialog && (
        <DeleteConfirmDialog
          closeDeleteDialog={setDeleteDialog}
          deleteDialog={deleteDialog}
          deleteSubmit={deleteSubmit}
        />
      )}
      {deleteSubCategoryDialog && (
        <DeleteConfirmDialog
          closeDeleteDialog={setDeleteSubCategoryDialog}
          deleteDialog={deleteSubCategoryDialog}
          deleteSubmit={deleteSubCategorySubmit}
        />
      )}
    </>
  );
};

export default CompoundCategories;
