import { all, call, put } from 'redux-saga/effects';
import { Action } from 'redux';
import { SagaIterator } from 'redux-saga';
import { Dialog } from 'muibox';

/**
 * API from axios
 */
import api from '../../../Services/api';

import {
  addOrUpdateFailure,
  addSuccess,
  closeExternalDocsCategoryRequest,
  closeExternalDocsCategorySuccess,
  loadRequest,
  loadSuccess,
  openExternalDocsCategorySuccess,
  updateSuccess,
} from './Actions';
import { ExternalDocsCategoriesTypes } from './Types';

interface LoadActionType extends Action {
  payload: {
    dialog: Dialog;
  };
}

interface OpenOrCloseDialogType extends Action {
  payload: {
    dialog: Dialog;
    id?: number | string;
  };
}

interface AddOrUpdateActionType extends Action {
  payload: {
    dialog: Dialog;
    id?: number | string;
    postData: {
      description?: string;
      id: number | string;
      name: string;
    };
  };
}

export function* loadExternalDocsCategories(action: LoadActionType): SagaIterator {
  try {
    const response = yield call(api.get, `/externaldocs/externaldocscategory/`);
    yield put(loadSuccess(response.data));
  } catch (error) {
    const { dialog } = action.payload;
    dialog.alert(`Não foi possível connectar com o servidor!`);
    yield put({ type: ExternalDocsCategoriesTypes.LOAD_ERRORS });
  }
}

export function* openExternalDocsCategoriesDialog(action: OpenOrCloseDialogType): SagaIterator {
  try {
    if (action.payload.id) {
      const response = yield call(api.get, `/externaldocs/externaldocscategory/${action.payload.id}/`);
      yield put(openExternalDocsCategorySuccess(response.data));
    } else {
      yield put(openExternalDocsCategorySuccess());
    }
  } catch (error) {
    const { dialog } = action.payload;
    dialog.alert(
      `Não foi possível realizar essa ação, por favor realize um refresh no navegador e caso o problema não seja resolvido entre em contato com nosso suporte!`,
    );
    yield put({ type: ExternalDocsCategoriesTypes.LOAD_ERRORS });
  }
}

export function* closeExternalDocsCategoriesDialog(action: OpenOrCloseDialogType): SagaIterator {
  try {
    yield put(closeExternalDocsCategorySuccess());
  } catch (error) {
    const { dialog } = action.payload;
    dialog.alert(
      `Não foi possível realizar essa ação, por favor realize um refresh no navegador e caso o problema não seja resolvido entre em contato com nosso suporte!`,
    );
  }
}

export function* addOrUpdateExternalDocsCategories(action: AddOrUpdateActionType): SagaIterator {
  const { dialog, postData } = action.payload;
  try {
    if (postData.id) {
      const response = yield call(api.patch, `/externaldocs/externaldocscategory/${postData.id}/`, postData);
      yield all([put(updateSuccess(response.data)), put(loadRequest(dialog))]);
    } else {
      const response = yield call(api.post, `/externaldocs/externaldocscategory/`, postData);
      yield all([put(addSuccess(response.data)), put(loadRequest(dialog))]);
    }
    yield put(closeExternalDocsCategoryRequest());
  } catch (error) {
    dialog.alert(
      `Não foi possível realizar essa ação, por favor realize um refresh no navegador e caso o problema não seja resolvido entre em contato com nosso suporte!`,
    );
    yield put(addOrUpdateFailure(error.response.data));
  }
}
