import { action, ActionType } from 'typesafe-actions';
import { Dialog } from 'muibox';
import { InventoryCategoryActionTypes, InventoryCategoryError, InventoryCategory, InventorySubcategory } from './Types';

export const loadRequest = (dialog: Dialog): ActionType<typeof action> =>
  action(InventoryCategoryActionTypes.LOAD_REQUEST, { dialog });
export const loadSuccess = (data: InventoryCategory[]): ActionType<typeof action> =>
  action(InventoryCategoryActionTypes.LOAD_SUCCESS, { data });
export const loadFailure = (): ActionType<typeof action> => action(InventoryCategoryActionTypes.LOAD_FAILURE);
export const openCategoryRequest = (data: { dialog: Dialog; id?: number | string }): ActionType<typeof action> =>
  action(InventoryCategoryActionTypes.OPEN_REQUEST, data);
export const openCategorySuccess = (data?: InventoryCategory[]): ActionType<typeof action> =>
  action(InventoryCategoryActionTypes.OPEN_SUCCESS, data);
export const closeCategoryRequest = (): ActionType<typeof action> => action(InventoryCategoryActionTypes.CLOSE_REQUEST);
export const closeCategorySuccess = (): ActionType<typeof action> => action(InventoryCategoryActionTypes.CLOSE_SUCCESS);
export const addOrUpdateRequest = (data: {
  dialog: Dialog;
  postData: { description?: string; id: number | string; name: string };
}): ActionType<typeof action> => action(InventoryCategoryActionTypes.ADD_OR_UPDATE_REQUEST, data);
export const addOrUpdateFailure = (errors: InventoryCategoryError): ActionType<typeof action> =>
  action(InventoryCategoryActionTypes.ADD_OR_UPDATE_FAILURE, { errors });
export const addSuccess = (data: InventoryCategory): ActionType<typeof action> =>
  action(InventoryCategoryActionTypes.ADD_SUCCESS, { data });
export const updateSuccess = (data: InventoryCategory): ActionType<typeof action> =>
  action(InventoryCategoryActionTypes.UPDATE_SUCCESS, { data });
export const addOrUpdateSubCategoryRequest = (data: {
  dialog: Dialog;
  postData: { description?: string; id: number | string; name: string; type: string };
}): ActionType<typeof action> => action(InventoryCategoryActionTypes.ADD_OR_UPDATE_SUBCATEGORY_REQUEST, data);
export const openSubCategoryRequest = (data: {
  dialog: Dialog;
  category: InventoryCategory;
  subcategory: InventorySubcategory;
}): ActionType<typeof action> => action(InventoryCategoryActionTypes.OPEN_SUBCATEGORY_SUCCESS, data);
export const openSubCategorySuccess = (data?: InventoryCategory[]): ActionType<typeof action> =>
  action(InventoryCategoryActionTypes.OPEN_SUBCATEGORY_SUCCESS, data);
export const closeSubCategoryRequest = (): ActionType<typeof action> =>
  action(InventoryCategoryActionTypes.CLOSE_SUBCATEGORY_REQUEST);
