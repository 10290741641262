import { action, ActionType } from 'typesafe-actions';
import { ExternalDoc, ExternalDocsTypes } from './Types';

export const loadExternalDocsRequest = (): ActionType<typeof action> => action(ExternalDocsTypes.LOAD_REQUEST);
export const loadExternalDocsSuccess = (data: {
  count: number;
  next?: string;
  previous?: string;
  results: ExternalDoc[];
}): ActionType<typeof action> => action(ExternalDocsTypes.LOAD_SUCCESS, data);
export const openExternalDocsRequest = (data: {
  id: number | string;
  searchText?: string;
  filterList?: string[][];
}): ActionType<typeof action> => action(ExternalDocsTypes.OPEN_REQUEST, data);
export const openExternalDocsSuccess = (data: any[]): ActionType<typeof action> =>
  action(ExternalDocsTypes.OPEN_SUCCESS, data);
export const closeExternalDocsRequest = (): ActionType<typeof action> => action(ExternalDocsTypes.CLOSE_REQUEST);
export const closeExternalDocsSuccess = (): ActionType<typeof action> => action(ExternalDocsTypes.CLOSE_SUCCESS);
export const changeExternalDocsSort = (data: { activeColumn: number; sort: string }): ActionType<typeof action> =>
  action(ExternalDocsTypes.CHANGE_SORT, data);
export const changeExternalDocsFilters = (data: {
  filterList: string[][];
  searchText: string;
}): ActionType<typeof action> => action(ExternalDocsTypes.CHANGE_FILTERS, data);
export const changeExternalDocsPages = (data: { rowsPerPage: number; page: number }): ActionType<typeof action> =>
  action(ExternalDocsTypes.CHANGE_PAGES, data);
