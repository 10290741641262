import React, {
  ChangeEvent,
  FormEvent,
  MouseEvent,
  ReactElement,
  ReactNode,
  RefObject,
  SFC,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import {
  Avatar,
  Button,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { Business as BusinessIcon, Close as CloseIcon } from '@material-ui/icons';
import { useTheme } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import { Dialog as DialogType } from 'muibox';
import { brazilianStates, CNPJMask, useStyles } from './FormConstants';
import ImagePicker, { PickerFormats } from '../../../Components/ImagePicker';
import {
  Institution,
  InstitutionFieldKeys,
  InstitutionError,
  InstitutionsState,
} from '../../../ReduxFlow/Reducers/Institutions/Types';
import { validateCnpj } from '../../../Utils/CpfAndCnpjValidator';

type DialogInstFormProps = {
  addOrUpdateRequest: Function;
  dialog: DialogType;
  dialogOpen: boolean;
  institutionsState: InstitutionsState;
  onClose: (event: MouseEvent<HTMLButtonElement> | {}, reason?: `backdropClick` | `escapeKeyDown`) => void;
};

const DialogInstForm: SFC<DialogInstFormProps> = ({
  addOrUpdateRequest,
  dialog,
  dialogOpen,
  institutionsState,
  onClose,
}) => {
  const { data, formErrors: formErrorsFromProps, formInstance, isLoading, isSaving } = institutionsState;
  const theme = useTheme();
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down(`sm`));
  const avatarRef = useRef<HTMLInputElement>(null);
  const logoRef = useRef<HTMLInputElement>(null);
  const [isEditing, setIsEditing] = useState(!formInstance);
  const [formErrors, setFormErrors] = useState(formErrorsFromProps);
  const [institutionData, setInstitutionData] = useState({
    institutionName: formInstance ? formInstance.institutionName : ``,
    institutionCity: formInstance ? formInstance.institutionCity : ``,
    institutionState: formInstance ? formInstance.institutionState : ``,
    institutionAddress: formInstance ? formInstance.institutionAddress : ``,
    institutionCnpj: formInstance ? formInstance.institutionCnpj : ``,
    institutionAvatar: formInstance ? formInstance.institutionAvatar : ``,
    institutionLogo: formInstance ? formInstance.institutionLogo : ``,
    isOrganization: formInstance ? formInstance.isOrganization : false,
    parent: formInstance && formInstance.parent !== null ? formInstance.parent : ``,
  });

  const {
    institutionName,
    institutionCity,
    institutionState,
    institutionAddress,
    institutionCnpj,
    institutionAvatar,
    institutionLogo,
    isOrganization,
    parent,
  } = institutionData;

  useEffect(() => {
    if (formInstance) {
      setInstitutionData({
        institutionName: formInstance.institutionName,
        institutionCity: formInstance.institutionCity || ``,
        institutionState: formInstance.institutionState || ``,
        institutionAddress: formInstance.institutionAddress || ``,
        institutionCnpj: formInstance.institutionCnpj || ``,
        institutionAvatar: formInstance.institutionAvatar || ``,
        institutionLogo: formInstance.institutionLogo || ``,
        isOrganization: formInstance.isOrganization,
        parent: formInstance.parent || ``,
      });
      setIsEditing(false);
    }
  }, [formInstance]);

  const formValidation = (name?: InstitutionFieldKeys, value?: string): boolean => {
    const newFormErrors: InstitutionError = formErrors || {};
    if (!validateCnpj(name === InstitutionFieldKeys.institutionCnpj && value ? value : institutionCnpj)) {
      newFormErrors[InstitutionFieldKeys.institutionCnpj] = `CNPJ informado não é válido.`;
    } else {
      newFormErrors[InstitutionFieldKeys.institutionCnpj] = undefined;
    }
    if (!institutionName || (!value && name === InstitutionFieldKeys.institutionName)) {
      newFormErrors[InstitutionFieldKeys.institutionName] = `Esse campo não pode ser em branco.`;
    } else {
      newFormErrors[InstitutionFieldKeys.institutionName] = undefined;
    }
    setFormErrors(newFormErrors);
    return (
      !!newFormErrors[InstitutionFieldKeys.institutionCnpj] || !!newFormErrors[InstitutionFieldKeys.institutionName]
    );
  };

  const getDialogTitle = (): string => {
    switch (true) {
      case formInstance && !isEditing:
        return `Visualizar ${isOrganization ? `organização` : `instituição`}`;
      case formInstance && isEditing:
        return `Editar ${isOrganization ? `organização` : `instituição`}`;
      default:
        return `Adicionar nova instituição`;
    }
  };

  const getSubmitButtonTextAndSize = (): { size: number; text: string } => {
    return formInstance ? { size: 160, text: `Salvar Edições` } : { size: 229, text: `Adicionar Institutição` };
  };

  const handleFieldChange = (name: InstitutionFieldKeys) => (event: ChangeEvent<HTMLInputElement>): void => {
    event.persist();
    if (`files` in event.target && event.target.files) {
      const temporaryFileUrl = URL.createObjectURL(event.target.files[0]);
      setInstitutionData(prevData => ({
        ...prevData,
        [name]: temporaryFileUrl,
      }));
    } else if (`value` in event.target) {
      setInstitutionData(prevData => ({
        ...prevData,
        [name]: event.target.value,
      }));
      formValidation(name, event.target.value);
    }
  };

  const submitFormData = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    event.persist();
    if (formValidation()) return;
    const postData = new FormData(event.currentTarget);
    addOrUpdateRequest({ dialog, id: formInstance ? formInstance.id : undefined, postData });
  };

  const pickerCallback = (ref: RefObject<HTMLInputElement>) => (event: MouseEvent): void => {
    if (ref.current) ref.current.click();
  };

  const org = useMemo<Institution | undefined>((): Institution | undefined => {
    const organization = data.find((inst: Institution) => inst.isOrganization);
    if (!formInstance && organization) setInstitutionData(prevData => ({ ...prevData, parent: organization.id }));
    return organization;
  }, [data, formInstance]);

  const getInstitutionAvatarOrLogo = (
    inst: Institution,
    field: InstitutionFieldKeys.institutionAvatar | InstitutionFieldKeys.institutionLogo,
  ): string | undefined => {
    if (!inst[field] && org) {
      const insideParent = data.find((insideInst: Institution) => inst.parent === insideInst.id);
      return insideParent && insideParent[field] ? insideParent[field] : org[field];
    }
    return inst[field];
  };

  const availableInstitutions = useMemo<Institution[] | undefined>((): Institution[] | undefined => {
    if (org) {
      const insideInstitutions = data.filter((inst: Institution) => org && inst.parent === org.id);
      return insideInstitutions.length && org ? [org, ...insideInstitutions] : [org];
    }
  }, [data, org]);
  const isDisabledAndReadOnly = useMemo(() => formInstance && !isEditing, [formInstance, isEditing]);
  const formInstanceHasChildren = useMemo(
    () => formInstance && institutionsState.data.some(inst => inst.parent === formInstance.id),
    [formInstance, institutionsState.data],
  );
  const variantProps = useMemo(
    () =>
      !formInstance || isEditing
        ? { variant: `outlined` as "outlined" } // eslint-disable-line
        : { variant: `filled` as "filled" },// eslint-disable-line
    [formInstance, isEditing],
  );

  const selectVariantProps = useMemo(
    () =>
      !formInstance || (isEditing && !formInstanceHasChildren)
        ? // or use const expression
          { variant: `outlined` as "outlined" }// eslint-disable-line
        : { variant: `filled` as "filled" },// eslint-disable-line
    [formInstance, formInstanceHasChildren, isEditing],
  );

  return (
    <Dialog
      aria-labelledby="institution-form-dialog"
      fullScreen={fullScreen}
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      open={dialogOpen}
    >
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <Grid alignContent="center" alignItems="center" container justify="space-between">
          <Grid item>
            {isLoading ? (
              <Skeleton animation="wave" height={36} variant="rect" width={207} />
            ) : (
              <Typography variant="h6">{getDialogTitle()}</Typography>
            )}
          </Grid>
          <Grid>
            {!isEditing && (
              <Button color="primary" onClick={(): void => setIsEditing(prevValue => !prevValue)}>
                Editar
              </Button>
            )}
            {formInstance && (
              <IconButton onClick={onClose} size="small">
                <CloseIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <form id="institutionForm" noValidate onSubmit={submitFormData}>
          <Grid className={classes.boxWrapper} container>
            <Grid className={classes.imagePickerWrapper} item style={{ marginRight: 24 }}>
              <Typography className={classes.imagePickerLabel}>Ícone</Typography>
              {isLoading ? (
                <Skeleton animation="wave" height={80} variant="circle" width={80} />
              ) : (
                <ImagePicker
                  isLoading={false}
                  isPicking={!formInstance || isEditing}
                  loadedImage={
                    formInstance
                      ? getInstitutionAvatarOrLogo(formInstance, InstitutionFieldKeys.institutionAvatar)
                      : institutionAvatar
                  }
                  onClick={pickerCallback(avatarRef)}
                  sizeAndFormat={{ height: 80, width: 80 }}
                >
                  <BusinessIcon style={{ fontSize: `3em` }} />
                </ImagePicker>
              )}
              <input
                id={`id_${InstitutionFieldKeys.institutionAvatar}`}
                name={InstitutionFieldKeys.institutionAvatar}
                type="file"
                onChange={handleFieldChange(InstitutionFieldKeys.institutionAvatar)}
                ref={avatarRef}
                style={{ display: `none` }}
              />
            </Grid>
            <Grid className={classes.imagePickerWrapper} item>
              <Typography className={classes.imagePickerLabel}>Logo</Typography>
              {isLoading ? (
                <Skeleton animation="wave" height={74} variant="rect" width={167} />
              ) : (
                <ImagePicker
                  isLoading={false}
                  isPicking={!formInstance || isEditing}
                  loadedImage={
                    formInstance
                      ? getInstitutionAvatarOrLogo(formInstance, InstitutionFieldKeys.institutionLogo)
                      : institutionLogo
                  }
                  onClick={pickerCallback(logoRef)}
                  sizeAndFormat={{ format: PickerFormats.rounded, height: 74, width: 167 }}
                >
                  <BusinessIcon style={{ fontSize: `3em` }} />
                </ImagePicker>
              )}
              <input
                id={`id_${InstitutionFieldKeys.institutionLogo}`}
                name={InstitutionFieldKeys.institutionLogo}
                type="file"
                onChange={handleFieldChange(InstitutionFieldKeys.institutionLogo)}
                ref={logoRef}
                style={{ display: `none` }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              {isLoading ? (
                <Skeleton animation="wave" className={classes.textField} height={56} variant="rect" />
              ) : (
                <TextField
                  className={classes.textField}
                  disabled={isDisabledAndReadOnly}
                  error={!!formErrors && !!formErrors.institutionName}
                  helperText={!!formErrors && formErrors.institutionName}
                  id={`id_${InstitutionFieldKeys.institutionName}`}
                  InputProps={{
                    inputProps: {
                      readOnly: isDisabledAndReadOnly,
                    },
                  }}
                  label="Nome da instituição"
                  name={InstitutionFieldKeys.institutionName}
                  onChange={handleFieldChange(InstitutionFieldKeys.institutionName)}
                  required
                  value={institutionName}
                  {...variantProps}// eslint-disable-line
                />
              )}
            </Grid>
          </Grid>
          <Grid container>
            <Grid className={classes.twoTextFieldsFirstWrapper} item xs={12} sm={6}>
              {isLoading ? (
                <Skeleton animation="wave" className={classes.textField} height={56} variant="rect" />
              ) : (
                <TextField
                  className={classes.textField}
                  disabled={isDisabledAndReadOnly}
                  id={`id_${InstitutionFieldKeys.institutionCity}`}
                  InputProps={{
                    inputProps: {
                      readOnly: isDisabledAndReadOnly,
                    },
                  }}
                  label="Cidade"
                  name={InstitutionFieldKeys.institutionCity}
                  onChange={handleFieldChange(InstitutionFieldKeys.institutionCity)}
                  value={institutionCity}
                  {...variantProps}// eslint-disable-line
                />
              )}
            </Grid>
            <Grid className={classes.twoTextFieldsSecondWrapper} item xs={12} sm={6}>
              {isLoading ? (
                <Skeleton animation="wave" className={classes.textField} height={56} variant="rect" />
              ) : (
                <TextField
                  className={classes.textField}
                  disabled={isDisabledAndReadOnly}
                  id={`id_${InstitutionFieldKeys.institutionState}`}
                  InputProps={{
                    inputProps: {
                      readOnly: isDisabledAndReadOnly,
                    },
                  }}
                  label="UF"
                  name={InstitutionFieldKeys.institutionState}
                  onChange={handleFieldChange(InstitutionFieldKeys.institutionState)}
                  select
                  value={institutionState}
                  {...variantProps}// eslint-disable-line
                >
                  {brazilianStates.map(state => (
                    <MenuItem key={state} value={state}>
                      {state}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Grid>
          </Grid>
          <Grid container>
            <Grid className={classes.twoTextFieldsFirstWrapper} item xs={12} sm={6}>
              {isLoading ? (
                <Skeleton animation="wave" className={classes.textField} height={56} variant="rect" />
              ) : (
                <TextField
                  className={classes.textField}
                  disabled={isDisabledAndReadOnly}
                  id={`id_${InstitutionFieldKeys.institutionAddress}`}
                  InputProps={{
                    inputProps: {
                      readOnly: isDisabledAndReadOnly,
                    },
                  }}
                  label="Endereço"
                  name={InstitutionFieldKeys.institutionAddress}
                  onChange={handleFieldChange(InstitutionFieldKeys.institutionAddress)}
                  value={institutionAddress}
                  {...variantProps}// eslint-disable-line
                />
              )}
            </Grid>
            <Grid className={classes.twoTextFieldsSecondWrapper} item xs={12} sm={6}>
              {isLoading ? (
                <Skeleton animation="wave" className={classes.textField} height={56} variant="rect" />
              ) : (
                <TextField
                  className={isOrganization ? classes.lastTextField : classes.textField}
                  disabled={isDisabledAndReadOnly}
                  error={!!formErrors && !!formErrors.institutionCnpj}
                  helperText={!!formErrors && formErrors.institutionCnpj}
                  id={`id_${InstitutionFieldKeys.institutionCnpj}`}
                  InputProps={{
                    inputComponent: CNPJMask as any,
                    inputProps: {
                      readOnly: isDisabledAndReadOnly,
                    },
                  }}
                  label="CNPJ"
                  name={InstitutionFieldKeys.institutionCnpj}
                  onChange={handleFieldChange(InstitutionFieldKeys.institutionCnpj)}
                  value={institutionCnpj}
                  {...variantProps}// eslint-disable-line
                />
              )}
            </Grid>
          </Grid>
          {!isOrganization && (
            <Grid container>
              <Grid item xs={12}>
                {availableInstitutions && !isLoading ? (
                  <TextField
                    className={classes.lastTextField}
                    disabled={isDisabledAndReadOnly || formInstanceHasChildren}
                    FormHelperTextProps={{
                      // @ts-ignore
                      component: Box,
                    }}
                    helperText={
                      <Box className={classes.helperTextWrapper}>
                        <Box className={classes.helperTextColumnRectWrapper}>
                          <Box className={classes.helperTextRectangle} marginBottom="2px" />
                          <Box className={classes.helperTextRectWrapper}>
                            <Box className={classes.helperTextVerticalRect} />
                            <Box className={classes.helperTextHorizontalRect} />
                            <Box className={classes.helperTextRectangle} />
                          </Box>
                        </Box>
                        <Typography className={classes.helperTextText} variant="caption">
                          As instituições serão exibidas de forma agrupada
                        </Typography>
                      </Box>
                    }
                    id={`id_${InstitutionFieldKeys.parent}`}
                    label="Instituição mãe"
                    name={InstitutionFieldKeys.parent}
                    onChange={handleFieldChange(InstitutionFieldKeys.parent)}
                    InputProps={{
                      inputProps: {
                        readOnly: isDisabledAndReadOnly || formInstanceHasChildren,
                      },
                    }}
                    select
                    SelectProps={{
                      classes: { outlined: parent ? classes.selectTextField : undefined },
                      renderValue(selectedIndex: any): ReactNode {
                        const selectedInstitution = availableInstitutions.find(inst => inst.id === selectedIndex);
                        if (selectedInstitution) {
                          return (
                            <Grid
                              alignContent="center"
                              alignItems="center"
                              className={classes.renderValueWrapper}
                              container
                              justify="flex-start"
                            >
                              <Avatar
                                className={classes.selectAvatar}
                                src={getInstitutionAvatarOrLogo(
                                  selectedInstitution,
                                  InstitutionFieldKeys.institutionAvatar,
                                )}
                              >
                                <BusinessIcon />
                              </Avatar>
                              <Typography className={classes.renderValueText} variant="body1">
                                {selectedInstitution.institutionName}
                              </Typography>
                            </Grid>
                          );
                        }
                        return <Typography variant="body1">Instituição não encontrada</Typography>;
                      },
                    }}
                    value={parent}
                  {...selectVariantProps}// eslint-disable-line
                  >
                    {availableInstitutions.map(
                      (inst: Institution): ReactElement => (
                        <MenuItem key={inst.id} value={inst.id}>
                          <ListItemAvatar>
                            <Avatar src={getInstitutionAvatarOrLogo(inst, InstitutionFieldKeys.institutionAvatar)}>
                              <BusinessIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText secondary={inst.institutionCity}>{inst.institutionName}</ListItemText>
                        </MenuItem>
                      ),
                    )}
                  </TextField>
                ) : (
                  <Skeleton height={56} variant="rect" width="100%" />
                )}
              </Grid>
            </Grid>
          )}
        </form>
      </DialogContent>
      <DialogActions classes={{ root: classes.actionsRoot, spacing: classes.actionsSpacing }}>
        {!isDisabledAndReadOnly && !isLoading && (
          <>
            <Button
              className={classes.button}
              onClick={formInstance ? (): void => setIsEditing(prevValue => !prevValue) : onClose}
            >
              Cancelar
            </Button>
            <Button
              classes={{ disabled: classes.disabledButton }}
              className={classes.button}
              color="primary"
              disabled={isSaving}
              form="institutionForm"
              style={{
                minWidth: getSubmitButtonTextAndSize().size,
              }}
              type="submit"
              variant="contained"
            >
              {isSaving ? <CircularProgress color="inherit" size={24} /> : getSubmitButtonTextAndSize().text}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

const DialogInstFormTests = DialogInstForm;

export { DialogInstFormTests };

export default DialogInstForm;
