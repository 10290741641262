import { Pagination, TableSortOrder } from '../types';
/**
 * Action types
 */
export enum InventoriesTypes {
  REPORT_REQUEST = '@Inventories/REPORT_REQUEST', // eslint-disable-line
  REPORT_SUCCESSFULL = '@Inventories/REPORT_SUCCESSFULL', // eslint-disable-line
  LOAD_REQUEST = '@Inventories/LOAD_REQUEST', // eslint-disable-line
  LOAD_SUCCESS = '@Inventories/LOAD_SUCCESS', // eslint-disable-line
  LOAD_FAILURE = '@Inventories/LOAD_FAILURE', // eslint-disable-line
  OPEN_REQUEST = '@Inventories/OPEN_REQUEST', // eslint-disable-line
  OPEN_SUCCESS = '@Inventories/OPEN_SUCCESS', // eslint-disable-line
  CLOSE_REQUEST = '@Inventories/CLOSE_REQUEST', // eslint-disable-line
  CLOSE_SUCCESS = '@Inventories/CLOSE_SUCCESS', // eslint-disable-line
  CHANGE_SORT = '@Inventories/CHANGE_SORT', // eslint-disable-line
  CHANGE_FILTERS = '@Inventories/CHANGE_FILTERS', // eslint-disable-line
  CHANGE_PAGES = '@Inventories/CHANGE_PAGES', // eslint-disable-line
  MOBILE_INFINITE_SCROLL_REQUEST = '@Inventories/MOBILE_INFINITE_SCROLL_REQUEST', // eslint-disable-line
  MOBILE_INFINITE_SCROLL_SUCCESS = '@Inventories/MOBILE_INFINITE_SCROLL_SUCCESS', // eslint-disable-line
  LOAD_ERRORS = '@Inventories/LOAD_ERRORS', // eslint-disable-line
  ADD_OR_UPDATE_REQUEST = '@Inventories/ADD_OR_UPDATE_REQUEST', // eslint-disable-line
  ADD_OR_UPDATE_FAILURE = '@Inventories/ADD_OR_UPDATE_FAILURE', // eslint-disable-line
  ADD_SUCCESS = '@Inventories/ADD_SUCCESS', // eslint-disable-line
  UPDATE_SUCCESS = '@Inventories/UPDATE_SUCCESS', // eslint-disable-line
  INACTIVATE_OPEN_REQUEST = '@Inventories/INACTIVATE_OPEN_REQUEST', // eslint-disable-line
  INACTIVATE_CLOSE_REQUEST = '@Inventories/INACTIVATE_CLOSE_REQUEST', // eslint-disable-line
}
/**
 * Data types
 */
declare enum TaskStatus {
  pc = 'PC', // eslint-disable-line
  sp = 'SP', // eslint-disable-line
  wk = 'WK', // eslint-disable-line
}
export interface Inventory {
  id: number;
  institution: number;
  isActive: boolean;
  name: string;
  patrimony: string;
  status: TaskStatus;
  title: {
    id: number;
    name: string;
  };
  type: number;
}
export interface FormInstance {
  acquisitionPrice: number;
  acquisitionType: number;
  dicomInfo: {
    aeTitle: string;
    id: number;
    ip: string;
    port: number;
  };
  id: number;
  inactivatedAt: string;
  inactivationReason: string;
  inactivationUser: number;
  installationDate: string;
  institution: number;
  inventoryFather: number;
  isAcessory: boolean;
  isActive: boolean;
  isApproved: boolean;
  location: string;
  manufactureDate: string;
  manufacturer: string;
  mCost: number;
  model: string;
  msregister: string;
  name: string;
  observations: string;
  occupiedArea: number;
  patrimony: string;
  responsible: number;
  serialnumber: string;
  type: number;
  whFinal: string;
  whInitial: string;
  workingDays: number;
}

export interface InventoryError {
  id?: string;
  installationDate?: string;
  institution?: string;
  manufactureDate?: string;
  manufacturer?: string;
  model?: string;
  msregister?: string;
  name?: string;
  serialnumber?: string;
  type?: string;
  whFinal?: string;
  whInitial?: string;
  workingDays?: string;
}
/**
 * State types
 */
export interface InventoriesState {
  readonly data: Inventory[];
  readonly dialogOpen: boolean;
  readonly error: boolean;
  readonly filterList: { [key: string]: string[] };
  readonly formErrors?: InventoryError;
  readonly formInstance?: FormInstance;
  readonly inactivateOpen: boolean;
  readonly isLoading: boolean;
  readonly isSaving: boolean;
  readonly page: number;
  readonly pagination: Pagination;
  readonly rowsPerPage: number;
  readonly searchText?: string;
  readonly sort?: { [key: string]: { order: TableSortOrder } };
  readonly toApprove: number;
}
