import React, { FC } from 'react';
import { Avatar, Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Cancel as CancelIcon } from '@material-ui/icons';
import moment from 'moment';
import AutoComment from '../../AutoComment';
import { AutoCommentCommonTypes } from '../..';
import { Comment } from '..';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      height: theme.spacing(3),
      width: theme.spacing(3),
    },
    icon: {
      color: theme.palette.secondary.main,
      height: theme.spacing(2.25),
      width: theme.spacing(2.25),
    },
  }),
);

type InactivationAutoCommentProps = AutoCommentCommonTypes<Comment>;

const InactivationAutoComment: FC<InactivationAutoCommentProps> = ({
  comment,
  fromNow,
  genericAvatar,
  isFirstItem = false,
  isLastItem = false,
  nextIsAutoComment,
  previousIsAutoComment,
}) => {
  const classes = useStyles();
  const { comment: userComment, formalDate, userDetail } = comment;
  const displayDate = moment(formalDate, `YYYY-MM-DD`).format(`D/MM/YYYY`);

  return (
    <AutoComment
      avatar={<Avatar className={classes.avatar} src={userDetail.avatarThumb || genericAvatar} />}
      fromNow={fromNow}
      headerActionText="desativou o usuário"
      isFirstItem={isFirstItem}
      isLastItem={isLastItem}
      nextIsAutoComment={nextIsAutoComment}
      previousIsAutoComment={previousIsAutoComment}
      startAdornment={<CancelIcon className={classes.icon} />}
      useLeftPadding={false}
      userName={userDetail.fullName}
    >
      <Grid container>
        <Grid item>
          <Typography variant="body1">{userComment}</Typography>
          <Typography variant="body1">Data formal de desligamento: {displayDate}</Typography>
        </Grid>
      </Grid>
    </AutoComment>
  );
};

export default InactivationAutoComment;
