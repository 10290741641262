import { action, ActionType } from 'typesafe-actions';
import { Dialog } from 'muibox';
import { InternalDocsCategoriesTypes, InternalDocsCategoriesError, InternalDocsCategory } from './Types';

interface OkAndCancelType {
  color: string;
  text: string;
  variant: string;
}

export const loadRequest = (dialog: Dialog): ActionType<typeof action> =>
  action(InternalDocsCategoriesTypes.LOAD_REQUEST, { dialog });
export const loadSuccess = (data: InternalDocsCategory[]): ActionType<typeof action> =>
  action(InternalDocsCategoriesTypes.LOAD_SUCCESS, { data });
export const loadFailure = (): ActionType<typeof action> => action(InternalDocsCategoriesTypes.LOAD_FAILURE);
export const openInternalDocsCategoryRequest = (data: {
  dialog: Dialog;
  id?: number | string;
}): ActionType<typeof action> => action(InternalDocsCategoriesTypes.OPEN_REQUEST, data);
export const openInternalDocsCategorySuccess = (data?: InternalDocsCategory[]): ActionType<typeof action> =>
  action(InternalDocsCategoriesTypes.OPEN_SUCCESS, data);
export const closeInternalDocsCategoryRequest = (): ActionType<typeof action> =>
  action(InternalDocsCategoriesTypes.CLOSE_REQUEST);
export const closeInternalDocsCategorySuccess = (): ActionType<typeof action> =>
  action(InternalDocsCategoriesTypes.CLOSE_SUCCESS);
export const addOrUpdateRequest = (data: {
  dialog: Dialog;
  postData: { description?: string; id: number | string; name: string };
}): ActionType<typeof action> => action(InternalDocsCategoriesTypes.ADD_OR_UPDATE_REQUEST, data);
export const addOrUpdateFailure = (errors: InternalDocsCategoriesError): ActionType<typeof action> =>
  action(InternalDocsCategoriesTypes.ADD_OR_UPDATE_FAILURE, { errors });
export const addSuccess = (data: InternalDocsCategory): ActionType<typeof action> =>
  action(InternalDocsCategoriesTypes.ADD_SUCCESS, { data });
export const updateSuccess = (data: InternalDocsCategory): ActionType<typeof action> =>
  action(InternalDocsCategoriesTypes.UPDATE_SUCCESS, { data });
