import { call, put, select } from 'redux-saga/effects';
import { Action, Store } from 'redux';
import { SagaIterator } from 'redux-saga';

/**
 * API from axios
 */
import api from '../../../Services/api';
import { buildQueryStringFromArray } from '../../../Utils';

import {
  closeTasksSuccess,
  loadTasksKpisSuccess,
  loadTasksRequest,
  loadTasksSuccess,
  openTasksSuccess,
} from './Actions';
import { TasksTypes, TasksState } from './Types';

/**
 * Get access token
 */
interface AppStore extends Store {
  Auth?: any;
  Tasks?: TasksState;
}
interface AppAction extends Action {
  payload?: any;
}

function getTasksQueryString(tasksState: TasksState, invId?: number | string | boolean): string {
  let query = buildQueryStringFromArray(tasksState.filterList, tasksState.searchText);
  if (tasksState.sort && Object.entries(tasksState.sort).length !== 0) {
    query += `&sort=${Object.keys(tasksState.sort)[0]},${tasksState.sort[Object.keys(tasksState.sort)[0]].order}`;
  }
  if (invId) {
    query += `&invId=${invId}`;
  }
  query = `page=${tasksState.page + 1}&length=${tasksState.rowsPerPage}&${query}`;
  return query;
}

export function* loadTasksKpis(action: AppAction): SagaIterator {
  try {
    const tasksState = yield select((state: AppStore) => state.Tasks);
    const query = getTasksQueryString(tasksState);
    const response = yield call(api.get, `/tasks/tasks/get-sla-kpi/?${query}`);
    yield put(loadTasksKpisSuccess(response.data));
  } catch (error) {
    alert(`Não foi possível connectar com o servidor!`); // eslint-disable-line
    yield put({ type: TasksTypes.LOAD_ERRORS });
  }
}

export function* loadTasks(action: AppAction): SagaIterator {
  try {
    const tasksState = yield select((state: AppStore) => state.Tasks);
    const query = getTasksQueryString(tasksState, action.payload !== undefined && action.payload.invId);
    const response = yield call(api.get, `/tasks/tasks/?${query}`);
    yield put(loadTasksSuccess(response.data));
  } catch (error) {
    alert(`Não foi possível connectar com o servidor!`); // eslint-disable-line
    yield put({ type: TasksTypes.LOAD_ERRORS });
  }
}

export function* openTasksModal(data: AppAction): SagaIterator {
  try {
    if (data.payload.id) {
      const response = yield call(api.get, `/tasks/tasks/${data.payload.id}/`);
      yield put(openTasksSuccess([response.data]));
    } else {
      yield put(openTasksSuccess([]));
    }
  } catch (error) {
    alert(
      `Não foi possível realizar essa ação, por favor realize um refresh no navegador e caso o problema não seja resolvido entre em contato com nosso suporte!`,
    ); // eslint-disable-line
    yield put({ type: TasksTypes.LOAD_ERRORS });
  }
}

export function* closeTasksModal(): SagaIterator {
  try {
    yield put(loadTasksRequest());
    yield put(closeTasksSuccess());
  } catch (error) {
    alert(`Não foi possível realizar essa ação, tente mais tarde!`); // eslint-disable-line
  }
}
