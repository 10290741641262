import React, { FC } from 'react';
import { ListItemAvatar, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import { FeedbackOutlined as FeedbackOutlinedIcon } from '@material-ui/icons';

import activitiesUtil from '../../Utils/activities';
import { FeedbackRequestNotification } from './types';

const { getModelIcon, getDateFromTimestamp } = activitiesUtil;

interface FeedbackRequestNotificationCardProps {
  notification: FeedbackRequestNotification;
}

const FeedbackRequestNotificationCard: FC<FeedbackRequestNotificationCardProps> = ({ notification }) => {
  const { al, m, mn, tt, ts } = notification;

  return (
    <>
      <ListItemAvatar>
        <FeedbackOutlinedIcon fontSize="large" />
      </ListItemAvatar>
      <ListItemText
        primary={tt}
        secondary={
          <Typography component="small" variant="body2" color="textSecondary">
            {m}
            {m && <br />}
            {getDateFromTimestamp(ts)}
          </Typography>
        }
      />
      <ListItemIcon style={{ display: `flex`, flexDirection: `column`, alignItems: `flex-end` }}>
        {getModelIcon(al, mn)}
      </ListItemIcon>
    </>
  );
};

export default FeedbackRequestNotificationCard;
