import React, { ChangeEvent, FC, FormEvent, useEffect, useState } from 'react';
import { useDialog } from 'muibox';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { CRMError, FormInstance } from '../../../ReduxFlow/Reducers/CRMs/Types';
import * as CRMsActions from '../../../ReduxFlow/Reducers/CRMs/Actions';
import CRMForm from './CRMForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionsRoot: {
      alignItems: `center`,
      display: `flex`,
      flex: `0 0 auto`,
      justifyContent: `flex-end`,
      padding: 24,
    },
    actionsSpacing: {
      '& > :not(:first-child)': {
        marginLeft: 16,
      },
    },
    button: {
      font: `Medium 14px/19px Hind`,
      letterSpacing: 1.25,
      minWidth: 105,
      opacity: 1,
    },
    cancelButton: {
      color: `#767676`,
    },
    disabledButton: {
      backgroundColor: `${theme.palette.primary[theme.palette.type]} !important`,
      color: `${theme.palette.primary.contrastText} !important`,
    },
  }),
);

export type CRMData = Omit<FormInstance, 'id' | 'birthDate'> & { // eslint-disable-line
  birthDate?: MaterialUiPickersDate | string;
  documentTypeSelected?: string;
};

interface DialogCRMFormProps {
  addOrUpdateRequest: typeof CRMsActions.addOrUpdateRequest;
  afterSavingCallback?: (data: FormInstance) => void;
  formErrors?: CRMError;
  formInstance?: FormInstance;
  fullscreen: boolean;
  handleClick: typeof CRMsActions.closeCRMRequest;
  isSaving: boolean;
  open: boolean;
}

const DialogCRMForm: FC<DialogCRMFormProps> = ({
  addOrUpdateRequest,
  afterSavingCallback,
  formErrors,
  formInstance,
  fullscreen,
  handleClick,
  isSaving,
  open,
}) => {
  const classes = useStyles();
  const dialog = useDialog();

  const [crmData, setCRMData] = useState<CRMData>({
    birthDate: null,
    document: ``,
    documentType: ``,
    documentTypeSelected: ``,
    email: ``,
    externalId: ``,
    fullName: ``,
  });

  useEffect(() => {
    if (formInstance) {
      const { birthDate, document, documentType, email, externalId, fullName } = formInstance;
      setCRMData({
        birthDate,
        document,
        documentType,
        documentTypeSelected: [`CPF`, `Passaporte`].indexOf(documentType || `CPF`) > -1 ? documentType : `Outro`,
        email,
        externalId,
        fullName,
      });
    }
  }, [formInstance]);

  const handleDateChange = (date: MaterialUiPickersDate): void => {
    setCRMData(prevData => ({
      ...prevData,
      birthDate: date,
    }));
  };

  const handleChange = (name: string) => (event: ChangeEvent<HTMLInputElement>): void => {
    event.persist();
    setCRMData(prevData => {
      const newCrmData = { ...prevData };
      if (name === `documentTypeSelected` && event.target.value !== `Outro`) newCrmData.documentType = ``;
      return {
        ...newCrmData,
        [name]: event.target.value,
      };
    });
  };

  function handleSubmit(event: FormEvent<HTMLFormElement>): void {
    event.stopPropagation();
    event.preventDefault();
    const newCrmData = { ...crmData };
    if (
      newCrmData.documentTypeSelected &&
      newCrmData.document &&
      [`CPF`, `Passaporte`].indexOf(newCrmData.documentTypeSelected) > -1
    )
      newCrmData.document = newCrmData.document.replace(/\D/g, ``);
    delete newCrmData.documentTypeSelected;
    addOrUpdateRequest({
      afterSavingCallback,
      dialog,
      data: {
        ...newCrmData,
        birthDate:
          typeof crmData.birthDate !== `string` && crmData.birthDate
            ? crmData.birthDate.toLocaleDateString(`pt-BR`)
            : (crmData.birthDate && new Date(crmData.birthDate).toLocaleDateString(`pt-BR`)) || undefined,
        documentType: crmData.documentType || crmData.documentTypeSelected,
        id: (formInstance && formInstance.id) || undefined,
      },
    });
  }

  return (
    <Dialog
      fullScreen={fullscreen}
      open={open}
      onClose={handleClick}
      aria-labelledby="addCRMForm"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>{!formInstance ? `Cadastrar novo cliente` : `Atualizar cadastro do cliente`}</DialogTitle>
      <DialogContent>
        <CRMForm
          crmData={crmData}
          formErrors={formErrors}
          handleChange={handleChange}
          handleDateChange={handleDateChange}
          handleSubmit={handleSubmit}
        />
      </DialogContent>
      <DialogActions classes={{ root: classes.actionsRoot, spacing: classes.actionsSpacing }}>
        <Button
          className={`${classes.button} ${classes.cancelButton}`}
          color="default"
          onClick={handleClick}
          variant="outlined"
        >
          Cancelar
        </Button>
        <Button
          classes={{ disabled: classes.disabledButton }}
          className={classes.button}
          form="crmsForm"
          type="submit"
          color="primary"
          variant="contained"
          disabled={isSaving}
        >
          {isSaving && <CircularProgress color="inherit" size={24} />}
          {!isSaving && formInstance && `Salvar`}
          {!isSaving && !formInstance && `Cadastrar`}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogCRMForm;
