import { Reducer } from 'redux';
import { TaskMarker, TaskMarkersState, TaskMarkersTypes } from './Types';

export const INITIAL_STATE: TaskMarkersState = {
  data: [],
  error: false,
  loading: false,
  isSaving: false,
  errors: {},
};

const reducer: Reducer<TaskMarkersState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TaskMarkersTypes.LOAD_REQUEST:
      return { loading: true, error: false, isSaving: false, data: state.data };
    case TaskMarkersTypes.LOAD_SUCCESS:
      return { loading: false, error: false, isSaving: false, data: action.payload.data };
    case TaskMarkersTypes.LOAD_FAILURE:
      return { loading: false, error: true, isSaving: false, data: [] };
    case TaskMarkersTypes.ADD_REQUEST:
      return { loading: false, error: false, isSaving: true, data: state.data };
    case TaskMarkersTypes.ADD_SUCCESS:
      return {
        loading: false,
        error: false,
        isSaving: false,
        data: [action.payload.data, ...state.data],
      };
    case TaskMarkersTypes.ADD_FAILURE:
      return {
        loading: false,
        error: true,
        isSaving: false,
        errors: action.payload.errors,
        data: state.data,
      };
    case TaskMarkersTypes.UPDATE_REQUEST:
      return { loading: false, error: false, isSaving: true, data: state.data };
    case TaskMarkersTypes.UPDATE_SUCCESS:
      return {
        loading: false,
        error: false,
        isSaving: false,
        data: state.data.map((marker: TaskMarker) =>
          marker.id === action.payload.data.id ? action.payload.data : marker,
        ),
      };
    case TaskMarkersTypes.UPDATE_FAILURE:
      return {
        loading: false,
        error: false,
        isSaving: false,
        errors: action.payload.errors,
        data: state.data,
      };
    default:
      return state;
  }
};

export default reducer;
