import { all, call, put } from 'redux-saga/effects';
import { Action, Store } from 'redux';
import { SagaIterator } from 'redux-saga';

import api, { cache } from '../../../Services/api';
import firebase, { quality } from '../../../Utils/firebaseConfig';

/**
 * Auth Actions
 */
import { loginError, loginSuccessfull, logoutError, logoutSuccessfull, resetState } from './Actions';
/**
 * Actions every refresh
 */
import { loadRequest as groupsLoadRequest } from '../Groups/Actions';
import { loadRequest as institutionsLoadRequest } from '../Institutions/Actions';
import { loadRequest as qcmarkersLoadRequest } from '../QCMarkers/Actions';
import { loadRequest as sectorsLoadRequest } from '../Sectors/Actions';
import { loadRequest as suppliersLoadRequest } from '../Suppliers/Actions';
import { loadRequest as tasksMarkersLoadRequest } from '../TasksMarkers/Actions';
import { loadTasksRequest, openTasksRequest } from '../Tasks/Actions';
import { loadInternalDocsRequest, openInternalDocsRequest } from '../InternalDocs/Actions';
import { loadExternalDocsRequest, openExternalDocsRequest } from '../ExternalDocs/Actions';
import { loadQualityControlsRequest, openQualityControlsRequest } from '../QualityControls/Actions';

interface AppAction extends Action {
  payload?: any;
}
interface AppStore extends Store {
  Auth?: any;
}

export function* login(action: AppAction): SagaIterator {
  const { history } = action.payload;
  const { pathname, search } = history.location;
  const params = new URLSearchParams(search);
  const auth = quality.auth();
  try {
    const tokenResponse = yield call(api.get, `/auth/token`);
    yield call([auth, auth.signInWithCustomToken], tokenResponse.data);
    const response = yield call(api.get, `/auth/`);
    yield all([
      put(loginSuccessfull(response.data)),
      put(groupsLoadRequest(response.data.access)),
      put(institutionsLoadRequest(response.data.access)),
      put(qcmarkersLoadRequest(response.data.access)),
      put(sectorsLoadRequest(response.data.access)),
      put(suppliersLoadRequest(response.data.access)),
      put(tasksMarkersLoadRequest(response.data.access)),
    ]);
    if (history) {
      const id = params.get(`id`);
      const menuType = params.get(`type`);
      if (search || pathname) {
        if (pathname.indexOf(`tasks`) > -1) {
          yield all([
            put(loadTasksRequest()),
            id && menuType ? yield put(openTasksRequest({ id, menuType })) : undefined,
          ]);
        } else if (pathname.indexOf(`internaldocs`) > -1) {
          yield all([put(loadInternalDocsRequest()), id ? yield put(openInternalDocsRequest({ id })) : undefined]);
        } else if (pathname.indexOf(`externaldocs`) > -1) {
          yield all([put(loadExternalDocsRequest()), id ? yield put(openExternalDocsRequest({ id })) : undefined]);
        } else if (pathname.indexOf(`qualitycontrol`) > -1) {
          yield all([
            put(loadQualityControlsRequest()),
            id ? yield put(openQualityControlsRequest({ id })) : undefined,
          ]);
        }
        history.replace(`${pathname}`);
      }
    }
  } catch (err) {
    if (err.response) {
      if (err.response.data && err.response.data.code !== `no_tenant`) yield call([auth, auth.signOut]);
    } else {
      yield call([auth, auth.signOut]);
    }
    yield put(loginError());
    action.payload.history.replace(`/?redirect=${window.location.pathname}${window.location.search}`);
  }
}

export function* logout(): SagaIterator {
  try {
    yield put(logoutSuccessfull());
  } catch (err) {
    yield put(logoutError());
  }
}

export function* resetStateSagas(action: AppAction): SagaIterator {
  const { currentOrganization, history } = action.payload;
  const firestore = firebase.firestore();
  try {
    firestore
      .collection(`users`)
      .doc(action.payload.firebaseUid)
      .update({
        currentOrganization: action.payload.currentOrganization,
      });
    window.localStorage.setItem(`currentOrganization`, JSON.stringify(action.payload.currentOrganization));
    // @ts-ignore
    yield cache.store.clear();
    yield put(resetState({ currentOrganization, history }));
  } catch (err) {
    console.log(err);
    yield put(loginError());
    history.replace(`/?redirect=${window.location.pathname}${window.location.search}`);
  }
}
