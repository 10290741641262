import React, { FC, useRef, UIEvent } from 'react';
import { useDispatch } from 'react-redux';
import { CircularProgress, List, ListItem } from '@material-ui/core';
import { Check as CheckIcon, Delete as DeleteIcon } from '@material-ui/icons';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import MuiSwipeableListItem from 'mui-swipeable-list-item';
import api from 'Services/api';
import { TaskType, taskTypeMap } from 'Pages/Tasks/tasksCommonHandlers';
import { AxiosResponse } from 'axios';
import { useDialog } from 'muibox';
import { CommonTaskInstance, CommonTaskTable } from 'ReduxFlow/Reducers/types';
/**
 * State types
 */
import { Comment } from 'Components/NewUIComponents/CommentsContainer/Tasks';
import { TasksMobileProps } from './mobile-tasks.interfaces';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItemContainer: {
      paddingRight: theme.spacing(10),
    },
    loadingItems: {
      justifyContent: `center !important`,
    },
    mobileList: {
      maxHeight: `calc(100vh - 48px)`,
      maxWidth: `100vw`,
      overflowY: `auto`,
    },
  }),
);

const TasksMobile: FC<TasksMobileProps> = ({
  changePages,
  data,
  elements,
  isLoading,
  loadData,
  onClick,
  rowsPerPage,
  tasksTotal,
  taskType,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const dialog = useRef(useDialog());
  const scrollTimeout = useRef<number | undefined>(undefined);

  async function deleteTask(taskId: number | string): Promise<void> {
    try {
      await dialog.current.confirm({
        title: `Excluir ${taskTypeMap[taskType].title()}`,
        message: `Você tem certeza que deseja excluir essa ${taskTypeMap[taskType]}?`,
        ok: { color: `secondary`, text: `Excluir`, variant: `contained` },
        cancel: { color: `default`, text: `Cancelar`, variant: `outlined` },
      });
      await api.delete(`/tasks/${TaskType[taskType]}/${taskId}/`);
      dispatch(loadData(dialog.current));
    } catch (e) {
      if (e && e.response) {
        dialog.current.alert({
          title: e.response && e.response.status === 409 ? `Proibido` : `Erro`,
          message:
            e.response && e.response.status === 409
              ? `Você não tem permissão para excluir essa ${taskTypeMap[taskType]}.`
              : `Não foi possível excluir a ${taskTypeMap[taskType]}, tente novamente mais tarde.`,
          ok: { color: `primary`, text: `Ok`, variant: `outlined` },
        });
      }
    }
  }

  async function handleScroll(event: UIEvent<HTMLUListElement>): Promise<void> {
    event.stopPropagation();
    clearTimeout(scrollTimeout.current);
    if (
      data.length < tasksTotal &&
      event.currentTarget.scrollHeight - event.currentTarget.scrollTop <= event.currentTarget.clientHeight
    ) {
      scrollTimeout.current = setTimeout(() => {
        dispatch(changePages({ dialog: dialog.current, page: 0, rowsPerPage: rowsPerPage + 15 }));
      });
    }
  }

  async function endTask(
    taskId: number | string,
    comment: string,
  ): Promise<AxiosResponse<{ comments: Comment[]; task: CommonTaskInstance }>> {
    try {
      const response = await api.patch(`/tasks/${TaskType[taskType]}/${taskId}/close-task/`, { comment });
      return response;
    } catch (e) {
      dialog.current.alert({
        title: e.response && e.response.status === 409 ? `Proibido` : `Erro`,
        message:
          e.response && e.response.status === 409
            ? `Você não tem permissão para finalizar essa ${taskTypeMap[taskType]}.`
            : `Não foi possível finalizar a ${taskTypeMap[taskType]}, tente novamente mais tarde.`,
        ok: { color: `primary`, text: `Ok`, variant: `outlined` },
      });
      return e;
    }
  }
  async function reopenTask(
    taskId: number | string,
    comment: string,
  ): Promise<AxiosResponse<{ comments: Comment[]; task: CommonTaskInstance }>> {
    try {
      const response = await api.patch(`/tasks/${TaskType[taskType]}/${taskId}/reopen-task/`, { comment });
      return response;
    } catch (e) {
      dialog.current.alert({
        title: e.response && e.response.status === 409 ? `Proibido` : `Erro`,
        message:
          e.response && e.response.status === 409
            ? `Você não tem permissão para reabrir essa ${taskTypeMap[taskType]}.`
            : `Não foi possível reabrir a ${taskTypeMap[taskType]}, tente novamente mais tarde.`,
        ok: { color: `primary`, text: `Ok`, variant: `outlined` },
      });
      return e;
    }
  }

  return (
    <>
      <List className={classes.mobileList} onScroll={handleScroll}>
        {data.map(item => {
          return (
            <MuiSwipeableListItem
              background={{
                actionIconLeft: <DeleteIcon />,
                actionIconRight: <CheckIcon />,
                backgroundColorLeft: theme.palette.secondary.main,
                backgroundColorRight: theme.palette.success.main,
              }}
              disableDeleteAnimation
              itemIcon={elements.itemIcon}
              key={elements.key(item)}
              ListItemProps={{
                classes: {
                  container: classes.listItemContainer,
                },
                onClick: (): void => onClick(item)(),
              }}
              ListItemTextProps={{
                style: {
                  color: theme.palette.text.primary,
                },
              }}
              onSwipedLeft={(): void => {
                deleteTask((item as CommonTaskTable).id);
              }}
              onSwipedRight={(): void => {
                dialog.current
                  .prompt({
                    ok: { color: `primary`, text: `Confirmar`, variant: `contained` },
                    cancel: { color: `default`, text: `Cancelar`, variant: `outlined` },
                    placeholder: `Comentário`,
                    required: true,
                    title: `Para finalizar/reabrir a tarefa escreva um comentário.`,
                  })
                  .then(async comment => {
                    let response: AxiosResponse<{ comments: Comment[]; task: CommonTaskInstance }>;
                    const { id, taskType: insideTaskType } = item as CommonTaskTable;
                    const { isActive } = insideTaskType;
                    if (isActive) {
                      response = await endTask(id, comment);
                    } else {
                      response = await reopenTask(id, comment);
                    }
                    if (response.status === 200) {
                      dispatch(loadData(dialog.current));
                    }
                  });
              }}
              primaryText={elements.listItemText(item)}
              secondaryAction={elements.listItemSecondaryAction(item)}
              secondaryText={elements.listItemSecondaryText(item)}
            />
          );
        })}
        {isLoading && (
          <ListItem className={classes.loadingItems}>
            <CircularProgress />
          </ListItem>
        )}
      </List>
    </>
  );
};

export default TasksMobile;
