import { call, put } from 'redux-saga/effects';
import { Action, Store } from 'redux';
import api from '../../../Services/api';

import {
  addFailure,
  addSuccess,
  closeSuppliersRequest,
  closeSuppliersSuccess,
  changeSuppliersRangeSuccess,
  loadFailure,
  loadSuccess,
  openSuppliersSuccess,
  updateFailure,
  updateSuccess,
} from './Actions';

interface AppAction extends Action {
  payload?: any;
}

interface AppStore extends Store {
  Auth?: any;
}

export function* loadSuppliers(action: AppAction) {
  try {
    const response = yield call(api.get, `/supplier/supplier/`);
    yield put(loadSuccess(response.data));
  } catch (err) {
    yield put(loadFailure());
  }
}

export function* addSupplier(action: AppAction) {
  try {
    const response = yield call(api.post, `/supplier/supplier/`, action.payload);
    yield put(addSuccess(response.data));
    yield put(closeSuppliersRequest());
  } catch (error) {
    yield put(addFailure(error.response.data));
  }
}

export function* updateSupplier(action: AppAction) {
  try {
    const response = yield call(api.patch, `/supplier/supplier/${action.payload.id}/`, action.payload.data);
    yield put(updateSuccess(response.data));
    yield put(closeSuppliersRequest());
  } catch (error) {
    yield put(updateFailure(error.response.data));
  }
}

export function* openSuppliersModal(data: AppAction) {
  try {
    if (data.payload.id) {
      const response = yield call(api.get, `/supplier/supplier/${data.payload.id}/`);
      yield put(openSuppliersSuccess([response.data]));
    } else {
      yield put(openSuppliersSuccess([]));
    }
  } catch (error) {
    alert(`Não foi possível realizar essa ação, tente mais tarde!`); // eslint-disable-line
  }
}

export function* closeSuppliersModal() {
  try {
    yield put(closeSuppliersSuccess());
  } catch (error) {
    alert(`Não foi possível realizar essa ação, tente mais tarde!`); // eslint-disable-line
  }
}

export function* changeSuppliersRange() {
  try {
    yield put(changeSuppliersRangeSuccess());
  } catch (error) {
    console.error(error); // eslint-disable-line
    alert(`Não foi possível realizar essa ação, tente mais tarde!`); // eslint-disable-line
  }
}
