import React, { FC } from 'react';
import {
  Avatar,
  CircularProgress,
  Fab,
  Fade,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Slide,
} from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { User as AuthUser } from '../../ReduxFlow/Reducers/Auth/Types';
import { User } from '../../ReduxFlow/Reducers/Users/Types';
import { Institution } from '../../ReduxFlow/Reducers/Institutions/Types';
import TableInstitutions from '../../Components/TableInstitutions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      position: `absolute`,
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    loaderWrapper: {
      position: `absolute`,
      bottom: 30,
      width: `98%`,
      textAlign: `center`,
    },
    paper: {
      maxWidth: `100vw`,
      minHeight: `100%`,
    },
  }),
);

type MobileUsersProps = {
  data: User[];
  handleOpen: (id: number | undefined) => Function;
  institutions: Institution[];
  isLoading: boolean;
  loggedUserInfo: AuthUser;
};

const MobileUsers: FC<MobileUsersProps> = ({ data, handleOpen, institutions, isLoading, loggedUserInfo }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.paper} elevation={0}>
      <List className={classes.paper}>
        {data.map(user => (
          <Slide key={user.id} in direction="up" mountOnEnter unmountOnExit>
            <ListItem divider onClick={(): void => handleOpen(user.id)()}>
              <ListItemAvatar>
                <Avatar src={user.avatarThumb} alt="Imagem não encontrada!" />
              </ListItemAvatar>
              <ListItemText primary={`#${user.id} ${user.fullName}`} secondary={user.emailAndId.email} />
              <TableInstitutions institutions={institutions} selected={user.userInstitution} />
            </ListItem>
          </Slide>
        ))}
        {isLoading && (
          <div className={classes.loaderWrapper}>
            <Fade
              in={isLoading}
              style={{
                transitionDelay: isLoading ? `800ms` : `0ms`,
              }}
              unmountOnExit
            >
              <CircularProgress />
            </Fade>
          </div>
        )}
      </List>
      {loggedUserInfo && (
        <Fab className={classes.fab} color="primary" onClick={(): void => handleOpen(undefined)()}>
          <AddIcon />
        </Fab>
      )}
    </Paper>
  );
};

export default MobileUsers;
