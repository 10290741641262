import { action, ActionType } from 'typesafe-actions';
import { Task, TasksTypes } from './Types';
import { SlaKpis } from '../types';

export const loadReportRequest = (): ActionType<typeof action> => action(TasksTypes.REPORT_REQUEST);
export const loadReportSuccessfull = (): ActionType<typeof action> => action(TasksTypes.REPORT_SUCCESSFULL);
export const loadTasksRequest = (data?: { invId?: number }): ActionType<typeof action> =>
  action(TasksTypes.LOAD_REQUEST, data);
export const loadTasksSuccess = (data: {
  count: number;
  next?: string;
  previous?: string;
  results: Task[];
}): ActionType<typeof action> => action(TasksTypes.LOAD_SUCCESS, data);
export const openTasksRequest = (data: {
  id: number | string;
  menuType?: string;
  searchText?: string;
  filterList?: string[][];
}): ActionType<typeof action> => action(TasksTypes.OPEN_REQUEST, data);
export const openTasksSuccess = (data: any[]): ActionType<typeof action> => action(TasksTypes.OPEN_SUCCESS, data);
export const closeTasksRequest = (): ActionType<typeof action> => action(TasksTypes.CLOSE_REQUEST);
export const closeTasksSuccess = (): ActionType<typeof action> => action(TasksTypes.CLOSE_SUCCESS);
export const convertCallToTask = (): ActionType<typeof action> => action(TasksTypes.CONVERT_CALL_TO_TASK);
export const convertTaskToCall = (): ActionType<typeof action> => action(TasksTypes.CONVERT_TASK_TO_CALL);
export const changeTasksSort = (data?: { activeColumn: number; sort: string }): ActionType<typeof action> =>
  action(TasksTypes.CHANGE_SORT, data);
export const changeTasksFilters = (data: {
  filterList: string[][];
  searchText: string;
  invId?: number;
}): ActionType<typeof action> => action(TasksTypes.CHANGE_FILTERS, data);
export const changeTasksPages = (data: {
  rowsPerPage: number;
  page: number;
  invId?: number;
}): ActionType<typeof action> => action(TasksTypes.CHANGE_PAGES, data);
export const loadTasksKpisSuccess = (data: SlaKpis): ActionType<typeof action> =>
  action(TasksTypes.LOAD_KPIS_SUSUCCESS, data);
