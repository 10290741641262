import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Button, Popover } from '@material-ui/core';
import NotificationsList from './NotificationsList';

const NotificationsMenu = ({
  handleNotificationsOpen,
  handleReadNotification,
  handleScrollNotifications,
  history,
  notifyAnchorEl,
  notifications,
  readAllNotifications,
  genericAvatar,
}) => {
  return (
    <Popover
      id="notification-menu"
      anchorEl={notifyAnchorEl}
      open={!!notifyAnchorEl}
      onClose={handleNotificationsOpen}
      anchorOrigin={{
        vertical: `bottom`,
        horizontal: `right`,
      }}
      transformOrigin={{
        vertical: `top`,
        horizontal: `right`,
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        maxHeight="80vh"
        maxWidth={500}
        overflow="hidden"
      >
        <NotificationsList
          handleReadNotification={handleReadNotification}
          handleScrollNotifications={handleScrollNotifications}
          notifications={notifications}
          readAllNotifications={readAllNotifications}
          genericAvatar={genericAvatar}
        />
        <Box bottom={0} left={0} maxWidth={500} position="relative" textAlign="center">
          <Button
            color="primary"
            onClick={() => {
              history.push(`/notifications`);
              handleNotificationsOpen();
            }}
            size="small"
          >
            Ver tudo
          </Button>
        </Box>
      </Box>
    </Popover>
  );
};

NotificationsMenu.defaultProps = {
  notifyAnchorEl: null,
  notifications: [],
};

NotificationsMenu.propTypes = {
  handleNotificationsOpen: PropTypes.func.isRequired,
  handleReadNotification: PropTypes.func.isRequired,
  handleScrollNotifications: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  notifyAnchorEl: PropTypes.any,
  notifications: PropTypes.arrayOf(PropTypes.object),
  readAllNotifications: PropTypes.func.isRequired,
};

export default withRouter(NotificationsMenu);
