import React, { MouseEvent, SFC } from 'react';
import { Avatar, Card, Typography } from '@material-ui/core';
import { Business as BusinessIcon } from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export enum CardLevel {
  card0 = 'card0', // eslint-disable-line
  card1 = 'card1', // eslint-disable-line
  card2 = 'card2', // eslint-disable-line
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      height: 30,
      margin: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(3)}px`,
      width: 30,
    },
    [CardLevel.card0]: {
      alignContent: `center`,
      alignItems: `center`,
      boxShadow: `0px 1px 3px #00000033`,
      display: `flex`,
      margin: `${theme.spacing(3)}px 0 ${theme.spacing(3)}px 0`,
      cursor: `pointer`,
      [theme.breakpoints.down(`xs`)]: {
        margin: `${theme.spacing(1.5)}px 0 ${theme.spacing(1.5)}px 0`,
      },
    },
    [CardLevel.card1]: {
      alignContent: `center`,
      alignItems: `center`,
      boxShadow: `0px 1px 3px #00000033`,
      display: `flex`,
      margin: `${theme.spacing(3)}px 0 0 ${theme.spacing(6)}px`,
      cursor: `pointer`,
      [theme.breakpoints.down(`xs`)]: {
        margin: `${theme.spacing(1.5)}px 0 0 ${theme.spacing(3)}px`,
      },
    },
    [CardLevel.card2]: {
      alignContent: `center`,
      alignItems: `center`,
      boxShadow: `0px 1px 3px #00000033`,
      display: `flex`,
      margin: `${theme.spacing(1)}px 0 ${theme.spacing(1)}px ${theme.spacing(27)}px`,
      cursor: `pointer`,
      [theme.breakpoints.down(`xs`)]: {
        margin: `${theme.spacing(0.5)}px 0 ${theme.spacing(0.5)}px ${theme.spacing(13.5)}px`,
      },
    },
    paper: {
      boxShadow: `0px 1px 3px #00000033`,
      padding: theme.spacing(3),
    },
  }),
);

type InstitutionsCardProps = {
  institutionAvatar?: string;
  institutionName: string;
  level: CardLevel;
  onClick: (event: MouseEvent) => void;
};

const InstitutionsCard: SFC<InstitutionsCardProps> = ({ institutionAvatar, institutionName, level, onClick }) => {
  const classes = useStyles();

  return (
    <Card className={classes[level]} onClick={onClick}>
      <Avatar className={classes.avatar} src={institutionAvatar}>
        <BusinessIcon />
      </Avatar>
      <Typography display="inline" variant="body1">
        {institutionName}
      </Typography>
    </Card>
  );
};

const InstitutionsCardTests = InstitutionsCard;

export { InstitutionsCardTests };

export default InstitutionsCard;
