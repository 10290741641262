import { Reducer } from 'redux';
import { ExternalDocsTypes, ExternalDocsState } from './Types';

export const INITIAL_STATE: ExternalDocsState = {
  count: 0,
  data: [],
  expiredOrExpiring: 0,
  filterList: { isActive: [`Ativo`] },
  formInstance: [],
  isLoading: false,
  modalOpen: false,
  page: 0,
  rowsPerPage: (window as any).innerHeight > 760 ? 15 : 10,
  searchText: ``,
  sort: {},
};

const reducer: Reducer<ExternalDocsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ExternalDocsTypes.LOAD_REQUEST:
      return { ...state, isLoading: true };
    case ExternalDocsTypes.LOAD_SUCCESS:
      return {
        ...state,
        count: action.payload.count,
        data: action.payload.results,
        expiredOrExpiring: action.payload.expiredOrExpiring,
        isLoading: false,
        sort: {},
      };
    case ExternalDocsTypes.OPEN_REQUEST:
      return { ...state, isLoading: true };
    case ExternalDocsTypes.OPEN_SUCCESS:
      return { ...state, modalOpen: true, formInstance: action.payload, isLoading: false };
    case ExternalDocsTypes.CLOSE_REQUEST:
      return { ...state, isLoading: true, modalOpen: false };
    case ExternalDocsTypes.CLOSE_SUCCESS:
      return { ...state, formInstance: [], isLoading: false };
    case ExternalDocsTypes.CHANGE_SORT:
      return {
        ...state,
        sort: { [action.payload.activeColumn]: { order: action.payload.sort } },
        isLoading: true,
      };
    case ExternalDocsTypes.CHANGE_FILTERS:
      return {
        ...state,
        filterList: action.payload.filterList,
        searchText: action.payload.searchText,
        sort: { ...state.sort, ...action.payload.sort },
        isLoading: true,
      };
    case ExternalDocsTypes.CHANGE_PAGES:
      return {
        ...state,
        page: action.payload.page,
        rowsPerPage: action.payload.rowsPerPage,
        isLoading: true,
      };
    default:
      return state;
  }
};

export default reducer;
