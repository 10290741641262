import { action, ActionType } from 'typesafe-actions';
import { AuthTypes, Auth, User } from './Types';

export const loginRequest = (data = {}): ActionType<typeof action> => action(AuthTypes.LOGIN_REQUEST, data);
export const loginSuccessfull = (data: Auth): ActionType<typeof action> =>
  action(AuthTypes.LOGIN_SUCCESSFULL, { data });
export const loginError = (): ActionType<typeof action> => action(AuthTypes.LOGIN_ERROR);
export const logoutRequest = (): ActionType<typeof action> => action(AuthTypes.LOGOUT_REQUEST);
export const logoutSuccessfull = (): ActionType<typeof action> => action(AuthTypes.LOGOUT_SUCCESSFULL);
export const logoutError = (): ActionType<typeof action> => action(AuthTypes.LOGOUT_ERROR);
export const renewToken = (token: string): ActionType<typeof action> => action(AuthTypes.RENEW_TOKEN, token);
export const renewError = (): ActionType<typeof action> => action(AuthTypes.RENEW_ERROR);
export const resetState = (data: {
  currentOrganization?: { displayName: string; id: string };
  firebaseUid?: string;
  history?: {};
}): ActionType<typeof action> => action(AuthTypes.RESET_STATE, data);
export const setCurrentOrganization = (data: {
  currentOrganization: { displayName: string; id: string };
  firebaseUid: string;
  history: {};
}): ActionType<typeof action> => action(AuthTypes.CHANGE_CURRENT_ORGANIZATION, data);
export const updateAuthUserInfo = (data: User): ActionType<typeof action> =>
  action(AuthTypes.UPDATE_AUTH_USER_INFO, data);
