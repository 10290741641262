import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
/**
 * Material UI - Core
 */
import {
  Badge,
  Button,
  Chip,
  Hidden,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
/**
 * Material-UI - Icons
 */
import {
  Add,
  Build,
  CheckCircle,
  EventAvailableOutlined,
  FormatListBulleted,
  RecordVoiceOverOutlined,
  ReportProblem,
} from '@material-ui/icons';
/**
 * MUI Datatables
 */
import MUIDataTable from 'mui-datatables';
/**
 * Ombudsman
 */
import { withDialog } from 'muibox';
import * as OmbudsmanActions from '../../ReduxFlow/Reducers/Ombudsmans/Actions';
import OmbudsmanDialogForm from './Ombudsmans/DialogForms';
/**
 * Axios API
 */
import api from '../../Services/api';
import { buildQueryStringFromArray } from '../../Utils';
/**
 * Tasks actions
 */
import * as tasksActions from '../../ReduxFlow/Reducers/Tasks/Actions';

/**
 * My components
 */
import DialogTaskForm from './TaskForm';
import MobileTasks from './MobileTasks';
/**
 * Range Picker
 */
import RangePicker from '../../Components/RangePicker';
import * as muiDatatableHelper from '../../Utils/muiDatatableHelper';
import SLATooltip, { Dot, DotTypeEnum } from '../../Components/SLATooltip';
import CustomTableTitle from '../../Components/CustomTableTitle';
import { timestampToDaysHoursAndMinutesString } from '../../Utils/categoriesUtils';
import { getMoodFromInt } from '../../Components/MoodPicker';
import { subTasksDictionary } from '../../Components/Main/sideBarPathNames';

const styles = theme => ({
  button: {
    position: `relative`,
    top: 0,
    left: 0,
  },
  cell: {
    border: `none`,
    overflow: `hidden`,
    paddingRight: theme.spacing(2),
  },
  headerCell: {
    color: theme.palette.text.secondary,
  },
  institutionAvatar: {
    transition: `transform .3s`,
    '&:hover': {
      transform: `scale(3)`,
      zIndex: 100,
    },
  },
  markerRoot: {
    fontSize: 12,
    height: 15,
  },
  markerChildren: {
    padding: `0 6px 0 6px`,
  },
  smRecipient: {
    height: `calc(100vh - 56px)`,
    overflowY: `auto`,
  },
  table: {
    width: `100%`,
  },
});

class Tasks extends React.PureComponent {
  state = {
    addMenuSelector: null,
  };

  componentDidMount() {
    const { loggedUserInfo, history, openTasksRequest } = this.props;
    const { isLoadingUI } = loggedUserInfo;
    const { currentOrganization } = loggedUserInfo.data;
    const localStorageOrg = window.localStorage.getItem(`currentOrganization`);
    if (localStorageOrg === JSON.stringify(currentOrganization) && !isLoadingUI) {
      this.tasksFilterFunction(0);
      const parameters = new URLSearchParams(history.location.search);
      if (parameters.get(`id`)) {
        history.replace(history.location.pathname);
        openTasksRequest({ id: parameters.get(`id`), menuType: parameters.get(`type`) });
      }
    }
  }

  componentDidUpdate() {
    const { loggedUserInfo } = this.props;
    const { isLoadingUI } = loggedUserInfo;
    const { currentOrganization } = loggedUserInfo.data;
    const localStorageOrg = window.localStorage.getItem(`currentOrganization`);
    if (localStorageOrg === JSON.stringify(currentOrganization) && !isLoadingUI) {
      this.tasksFilterFunction(1000);
    }
  }

  tasksFilterFunction = timeout => {
    const { changeTasksFilters, filterValue, handleFetchStatus, loggedUserInfo } = this.props;
    const { sector } = loggedUserInfo.data.user;

    clearTimeout(this.externalFilterTimeout);
    this.externalFilterTimeout = setTimeout(() => {
      if (filterValue.status) {
        switch (filterValue.value) {
          case `Chamado/Parado`:
          case `Chamado/Parcial`:
            changeTasksFilters({
              page: 0,
              filterList: [
                [`Abertas`],
                [], // filter1 id+title
                [], // filter2 institution
                [], // filter3 inventory
                [], // filter4 create_time
                [], // filter5 started_by
                [], // filter6 responsible
                [], // filter7 priority
                [], // filter8 unused
                [filterValue.value.split(`/`)[0]], // filter9 to-do type
                sector.map(sec => sec.name), // filter10 sector
                [filterValue.value.split(`/`)[1]], // filter11 call__situation
                [], // filter12 users
                [], // filter13 ativas
                [], // filter14 com/sem responsável
              ],
              searchText: ``,
            });
            break;
          case `Chamado`:
          case `Preventiva`:
          case `Tarefa`:
          case `Não conformidade`:
          case `Ouvidoria`:
            changeTasksFilters({
              page: 0,
              filterList: [
                [`Abertas`],
                [], // filter1 id+title
                [], // filter2 institution
                [], // filter3 inventory
                [], // filter4 create_time
                [], // filter5 started_by
                [], // filter6 responsible
                [], // filter7 priority
                [], // filter8 unused
                [filterValue.value], // filter9 to-do type
                sector.map(sec => sec.name), // filter10 sector
                [], // filter11 call__situation
                [], // filter12 users
                [], // filter13 ativas
                [], // filter14 com/sem responsável
              ],
              searchText: ``,
            });
            break;
          default:
            if (filterValue.responsible) {
              changeTasksFilters({
                page: 0,
                filterList: [
                  [`Abertas`],
                  [], // filter1 id+title
                  [], // filter2 institution
                  [], // filter3 inventory
                  [], // filter4 create_time
                  [], // filter5 started_by
                  [filterValue.value], // filter6 responsible
                  [], // filter7 priority
                  [], // filter8 unused
                  [], // filter9 to-do type
                  sector.map(sec => sec.name), // filter10 sector
                  [], // filter11 call__situation
                  [], // filter12 users
                  [], // filter13 ativas
                  [], // filter14 com/sem responsável
                ],
                searchText: ``,
              });
            } else if (filterValue.followers) {
              changeTasksFilters({
                page: 0,
                filterList: [
                  [`Abertas`],
                  [], // filter1 id+title
                  [], // filter2 institution
                  [], // filter3 inventory
                  [], // filter4 create_time
                  [], // filter5 started_by
                  [], // filter6 responsible
                  [], // filter7 priority
                  [], // filter8 unused
                  [], // filter9 to-do type
                  sector.map(sec => sec.name), // filter10 sector
                  [], // filter11 call__situation
                  [filterValue.value], // filter12 users
                  [], // filter13 ativas
                  [], // filter14 com/sem responsável
                ],
                searchText: ``,
              });
            } else {
              changeTasksFilters({
                page: 0,
                filterList: [
                  [`Abertas`],
                  [], // filter1 id+title
                  [], // filter2 institution
                  [], // filter3 inventory
                  [], // filter4 create_time
                  [], // filter5 started_by
                  [], // filter6 responsible
                  [], // filter7 priority
                  [], // filter8 unused
                  [], // filter9 to-do type
                  sector.map(sec => sec.name), // filter10 sector
                  [], // filter11 call__situation
                  [], // filter12 users
                  [], // filter13 ativas
                  [], // filter14 com/sem responsável
                ],
                searchText: filterValue.value,
              });
            }
        }
        handleFetchStatus();
      }
    }, timeout);
  };

  handleScroll = event => {
    event.stopPropagation();
    const { changeTasksPages, Tasks: propsTasks } = this.props;
    clearTimeout(this.scrollTimeout);
    if (
      propsTasks.data.length < propsTasks.count &&
      event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight
    ) {
      this.scrollTimeout = setTimeout(() => {
        changeTasksPages({ rowsPerPage: propsTasks.rowsPerPage + 15, page: 0 });
      }, 200);
    }
  };

  handleAnchorOpen = name => event => {
    this.setState({
      [name]: event.currentTarget,
    });
  };

  handleAnchorClose = name => () => {
    this.setState({
      [name]: null,
    });
  };

  getKpiDotColor = kpi => {
    if (kpi <= 0.6) return DotTypeEnum.ERROR;
    if (kpi >= 0.9) return DotTypeEnum.SUCCESS;
    return DotTypeEnum.WARNING;
  };

  buildKpiComponent = () => {
    const { classes, Tasks: propsTasks } = this.props;
    const slaKpis = propsTasks.slaKpis || {};
    const { frtKpi = 0, meanFrt = 0, meanMood = 0, meanRt = 0, rtKpi = 0 } = slaKpis;
    const meanMoodString = meanMood.toFixed(1);
    const roundedMood = Math.floor(parseFloat(meanMoodString)); // use icon according to rounded value
    const mood = getMoodFromInt(roundedMood);
    const moodEmoji = mood ? mood.src : ``;

    return (
      <TableContainer aria-label="KPI's table" classes={{ root: classes.cell }}>
        <Table className={classes.table} padding="none" size="small">
          <TableHead>
            <TableRow>
              <TableCell className={`${classes.cell} ${classes.headerCell}`}>TPR Médio</TableCell>
              <TableCell className={`${classes.cell} ${classes.headerCell}`}>
                <Tooltip title="To-dos dentro do TPR esperado">
                  <span>% TPR</span>
                </Tooltip>
              </TableCell>
              <TableCell className={`${classes.cell} ${classes.headerCell}`}>TR Médio</TableCell>
              <TableCell className={`${classes.cell} ${classes.headerCell}`}>
                <Tooltip title="To-dos dentro do TR esperado">
                  <span>% TR</span>
                </Tooltip>
              </TableCell>
              {!!moodEmoji && (
                <TableCell className={`${classes.cell} ${classes.headerCell}`}>Satisfação Média</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={classes.cell}>{timestampToDaysHoursAndMinutesString(meanFrt, true)}</TableCell>
              <TableCell className={classes.cell}>
                <>
                  <Dot dotType={this.getKpiDotColor(frtKpi)} marginRight={8} />
                  {(frtKpi * 100).toFixed(1)}%
                </>
              </TableCell>
              <TableCell className={classes.cell}>{timestampToDaysHoursAndMinutesString(meanRt, true)}</TableCell>
              <TableCell className={classes.cell}>
                <>
                  <Dot dotType={this.getKpiDotColor(rtKpi)} marginRight={8} />
                  {(rtKpi * 100).toFixed(1)}%
                </>
              </TableCell>
              {!!moodEmoji && (
                <TableCell className={classes.cell}>
                  <>{`${moodEmoji} ${meanMoodString} de 5`}</>
                </TableCell>
              )}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  openTaskMobile = ({ id, menuType, isExluded }) => {
    const { openTasksRequest } = this.props;
    openTasksRequest({ id, menuType, isExluded });
    // TODO uncoment this once ombudsman mobile is ready
    // const { dialog, openOmbudsmanRequest, openTasksRequest } = this.props;
    // if (menuType === `Ouvidoria`) {
    //   openOmbudsmanRequest({ id, dialog });
    // } else {
    //   openTasksRequest({ id, menuType, isExluded });
    // }
  };

  render() {
    const {
      avatar,
      changeTasksFilters,
      changeTasksPages,
      changeTasksSort,
      closeTasksRequest,
      classes,
      dialog,
      filterValue,
      Institutions,
      loggedUserInfo,
      OmbudsmansProps,
      openOmbudsmanRequest,
      openTasksRequest,
      Sectors,
      Tasks: propsTasks,
    } = this.props;
    const { addMenuSelector } = this.state;
    const { sector } = loggedUserInfo.data.user;

    const columns = [
      {
        label: `Status`,
        name: `taskType`,
        options: {
          filter: true,
          customFilterListRender: v => `Status: ${v}`,
          filterList: propsTasks.filterList[0].length ? propsTasks.filterList[0] : null,
          filterType: `dropdown`,
          filterOptions: {
            names: [`Fechadas`, `Abertas`],
          },
          customBodyRender: value => {
            if (value.isActive === `Fechada`) {
              return (
                <Tooltip title={value.taskType}>
                  <CheckCircle style={{ color: `rgb(29, 189, 29)` }} />
                </Tooltip>
              );
            }
            switch (value.taskType) {
              case `Preventiva`:
                return (
                  <Tooltip title={value.taskType}>
                    <EventAvailableOutlined style={{ color: `rgb(33, 117, 33)` }} />
                  </Tooltip>
                );
              case `Chamado`:
                return (
                  <Tooltip title={value.taskType}>
                    <Build style={{ color: `rgb(33, 64, 215)` }} />
                  </Tooltip>
                );
              case `Não conformidade`:
                return (
                  <Tooltip title={value.taskType}>
                    <ReportProblem color="secondary" />
                  </Tooltip>
                );
              case `Ouvidoria`:
                return (
                  <Tooltip title={value.taskType}>
                    <RecordVoiceOverOutlined style={{ color: `rgb(128, 0, 128)` }} />
                  </Tooltip>
                );
              default:
                return (
                  <Tooltip title={value.taskType}>
                    <FormatListBulleted style={{ color: `#4bc2c8` }} />
                  </Tooltip>
                );
            }
          },
        },
      },
      {
        label: `Título`,
        name: `taskTitle`,
        options: {
          customBodyRender: value => {
            return (
              <>
                <Typography>{value.title}</Typography>
                <Typography color="textSecondary">
                  <small>{`#${value.id}`}</small>
                </Typography>
              </>
            );
          },
          filter: true,
          customFilterListRender: v => `Título: ${v}`,
          filterList: propsTasks.filterList[1].length ? propsTasks.filterList[1] : null,
        },
      },
      muiDatatableHelper.buildInstitutionColumn(
        Institutions,
        propsTasks.filterList[2].length ? propsTasks.filterList[2] : [],
        {
          label: `Instituições`,
        },
      ),
      {
        label: `Ativo`,
        name: `equipment`,
        options: {
          filter: true,
          customFilterListRender: v => `Ativo: ${v}`,
          filterList: propsTasks.filterList[3].length ? propsTasks.filterList[3] : null,
          customBodyRender: value => {
            const cell = (
              <>
                {Boolean(value) && Boolean(`markers` in value) && Boolean(value.markers.length) && (
                  <div style={{ textAlign: `center ` }}>
                    {value.markers.map(val => (
                      <Chip
                        key={val[0]}
                        label={val[1]}
                        style={{ color: val[2], backgroundColor: val[3] }}
                        className={classes.markerRoot}
                        classes={{ label: classes.markerChildren }}
                      />
                    ))}
                  </div>
                )}
                <div style={{ textAlign: `center ` }}>{value && <span>{value.inventory}</span>}</div>
                <div style={{ textAlign: `center ` }}>
                  {value && value.situation === `Parado` && (
                    <Chip
                      label="Parado"
                      style={{ color: `#fff`, backgroundColor: `#dc3545` }}
                      className={classes.markerRoot}
                      classes={{ label: classes.markerChildren }}
                    />
                  )}
                  {value && value.situation === `Parcial` && (
                    <Chip
                      label="Parcial"
                      style={{ color: `#fff`, backgroundColor: `#ffc107` }}
                      className={classes.markerRoot}
                      classes={{ label: classes.markerChildren }}
                    />
                  )}
                  {value && value.situation === `Funcionando` && (
                    <Chip
                      label="Funcionando"
                      style={{ color: `#fff`, backgroundColor: `#28a745` }}
                      className={classes.markerRoot}
                      classes={{ label: classes.markerChildren }}
                    />
                  )}
                </div>
              </>
            );
            return cell;
          },
        },
      },
      {
        label: `Datas`,
        name: `dates`,
        options: {
          filter: true,
          filterList: propsTasks.filterList[4].length ? propsTasks.filterList[4] : null,
          filterType: `custom`,
          customBodyRender: value => {
            const start = value[0] ? new Date(value[0]).toLocaleDateString(`pt-br`) : null;
            const estimated = value[1] ? new Date(value[1]).toLocaleDateString(`pt-br`) : null;
            if (start && estimated) {
              return <span>{`${start} - ${estimated}`}</span>;
            }
            return ``;
          },
          customFilterListRender: v => {
            if (v.length) {
              return `Datas: ${v[0].toLocaleDateString()} - ${v[1].toLocaleDateString(`pt-br`)}`;
            }
            return false;
          },
          filterOptions: {
            names: [],
            display: (filterList, onChange, index, column) => {
              return (
                <RangePicker
                  startDate={filterList[4].length ? filterList[4][0] : undefined}
                  endDate={filterList[4].length ? filterList[4][1] : undefined}
                  label="Data inicial"
                  handleSelect={event => {
                    if (event.eventType === 3) {
                      onChange([event.start, event.end], index, column);
                    }
                  }}
                />
              );
            },
          },
        },
      },
      {
        label: `Aberto por`,
        name: `taskStarted`,
        options: {
          filter: true,
          customFilterListRender: v => `Aberto por: ${v}`,
          filterList: propsTasks.filterList[5].length ? propsTasks.filterList[5] : null,
        },
      },
      {
        label: `Responsável`,
        name: `taskResponsible`,
        options: {
          filter: true,
          customFilterListRender: v => `Responsável: ${v}`,
          filterList: propsTasks.filterList[6].length ? propsTasks.filterList[6] : null,
        },
      },
      {
        label: `Prioridade`,
        name: `priority`,
        options: {
          filter: true,
          customFilterListRender: v => `Prioridade: ${v}`,
          filterOptions: {
            names: [`Baixa`, `Normal`, `Alta`],
          },
          filterList: propsTasks.filterList[7].length ? propsTasks.filterList[7] : null,
          filterType: `dropdown`,
          customBodyRender: value => (
            <div style={{ textAlign: `center` }}>
              {value === `HG` && (
                <Chip
                  label="Alta"
                  style={{ color: `#fff`, backgroundColor: `#dc3545` }}
                  className={classes.markerRoot}
                  classes={{ label: classes.markerChildren }}
                />
              )}
              {value === `NM` && (
                <Chip
                  label="Normal"
                  style={{ color: `#fff`, backgroundColor: `#6c757d` }}
                  className={classes.markerRoot}
                  classes={{ label: classes.markerChildren }}
                />
              )}
              {value === `LW` && (
                <Chip
                  label="Baixa"
                  style={{ color: `#fff`, backgroundColor: `#007bff` }}
                  className={classes.markerRoot}
                  classes={{ label: classes.markerChildren }}
                />
              )}
            </div>
          ),
        },
      },
      {
        label: `Id`,
        name: `id`,
        options: {
          filter: false,
          display: `excluded`,
        },
      },
      {
        label: `Tipo`,
        name: `taskType`,
        options: {
          filter: true,
          display: `excluded`,
          customFilterListRender: v => `Tipo: ${v}`,
          filterType: `dropdown`,
          filterList: propsTasks.filterList[9].length ? propsTasks.filterList[9] : null,
          filterOptions: {
            names: [`Chamado`, `Tarefa`, `Ouvidoria`, `Não conformidade`, `Preventiva`],
          },
        },
      },
      {
        label: `Setor`,
        name: `sector`,
        options: {
          filter: true,
          filterType: `multiselect`,
          customFilterListRender: v => `Setor: ${v}`,
          filterList: propsTasks.filterList[10].length ? propsTasks.filterList[10] : null,
          filterOptions: {
            names: Sectors.map(sec => sec.name),
          },
          display: `excluded`,
        },
      },
      {
        label: `Situação`,
        name: `equipment`,
        options: {
          filter: true,
          customFilterListRender: v => `Situação: ${v}`,
          filterList: propsTasks.filterList[11].length ? propsTasks.filterList[11] : null,
          filterType: `dropdown`,
          filterOptions: {
            names: [`Parado`, `Funcionando`, `Parcial`],
          },
          display: `excluded`,
        },
      },
      {
        label: `Seguidores`,
        name: `users`,
        options: {
          filter: true,
          customFilterListRender: v => `Seguidores: ${v}`,
          filterList: propsTasks.filterList[12] ? propsTasks.filterList[12] : null,
          display: `excluded`,
        },
      },
      {
        label: `Excluída`,
        name: `isExcluded`,
        options: {
          filter: true,
          display: `excluded`,
          // customFilterListRender: v => `${v == }`,
          filterType: `dropdown`,
          filterList: propsTasks.filterList[13].length ? propsTasks.filterList[13] : null,
          filterOptions: {
            names: [`Excluídas`, `Ativas`],
          },
        },
      },
      {
        label: `Não atribuídas`,
        name: `isOrphan`,
        options: {
          filter: true,
          display: `excluded`,
          filterList: propsTasks.filterList[14].length ? propsTasks.filterList[14] : null,
          filterOptions: {
            names: [`Sem responsável`, `Com responsável`],
          },
          filterType: `dropdown`,
        },
      },
      {
        label: `TPR/TR`,
        name: `tprAndTr`,
        options: {
          filter: false,
          customBodyRender: tprAndTr => <SLATooltip tprAndTr={tprAndTr} />,
        },
      },
    ];
    const options = {
      rowsPerPage: propsTasks.rowsPerPage,
      viewColumns: false,
      print: false,
      fixedHeader: true,
      selectableRows: `none`,
      filter: true,
      filterType: `textField`,
      responsive: `stacked`,
      serverSide: true,
      count: propsTasks.count,
      page: propsTasks.page,
      searchText: propsTasks.searchText,
      customToolbar: () => {
        return (
          <>
            <Tooltip title="Sem responsável">
              <IconButton
                onClick={() =>
                  changeTasksFilters({
                    page: propsTasks.page,
                    filterList: [
                      [`Abertas`],
                      [],
                      [],
                      [],
                      [],
                      [],
                      [],
                      [],
                      [],
                      propsTasks.filterList[9], // to-do type (call, nc, etc)
                      sector.map(sec => sec.name),
                      [],
                      [],
                      [],
                      [`Sem responsável`],
                    ],
                    searchText: propsTasks.searchText,
                  })
                }
              >
                <Badge badgeContent={propsTasks.orphans} color="secondary" style={{ marginRight: 10 }}>
                  {propsTasks.filterList[9][0] === `Chamado` ? (
                    subTasksDictionary.Chamados.icon
                  ) : propsTasks.filterList[9][0] === `Preventiva` ? (
                    subTasksDictionary.Preventivas.icon
                  ) : propsTasks.filterList[9][0] === `Tarefa` ? (
                    subTasksDictionary.Tarefas.icon
                  ) : propsTasks.filterList[9][0] === `Ouvidoria` ? (
                    subTasksDictionary.Ouvidorias.icon
                  ) : propsTasks.filterList[9][0] === `Não conformidade` ? (
                    subTasksDictionary[`NC's`].icon
                  ) : (
                    <FormatListBulleted />
                  )}
                </Badge>
              </IconButton>
            </Tooltip>
            <Tooltip title="Adicionar tarefa">
              <Button
                aria-label="Adicionar"
                aria-owns="addMenu"
                variant="contained"
                color="primary"
                onClick={this.handleAnchorOpen(`addMenuSelector`)}
                startIcon={<Add />}
              >
                Adicionar
              </Button>
            </Tooltip>
            <Menu
              id="addMenu"
              anchorEl={addMenuSelector}
              open={Boolean(addMenuSelector)}
              anchorOrigin={{ vertical: `bottom`, horizontal: `center` }}
              transformOrigin={{ vertical: `top`, horizontal: `center` }}
              getContentAnchorEl={null}
              onClose={this.handleAnchorClose(`addMenuSelector`)}
              disableAutoFocusItem
            >
              {[
                { name: `Tarefa`, id: 0 },
                { name: `Chamado`, id: 1 },
                { name: `Não conformidade`, id: 3 },
                { name: `Preventiva`, id: 4 },
              ].map(val => (
                <MenuItem key={val.id} onClick={() => openTasksRequest({ id: null, menuType: val.name })}>
                  {val.name}
                </MenuItem>
              ))}
            </Menu>
          </>
        );
      },
      textLabels: {
        body: {
          noMatch: `Desculpe, nenhum registro encontrado!`,
          toolTip: `Ordenar`,
        },
        pagination: {
          next: `Próxima página`,
          previous: `Págima anterior`,
          rowsPerPage: `Linhas por página:`,
          displayRows: `de`,
        },
        toolbar: {
          search: `Procurar`,
          downloadCsv: `Baixar relatório`,
          print: `Imprimir`,
          viewColumns: `Colunas visíveis`,
          filterTable: `Filtrar a tabela`,
        },
        filter: {
          all: `Todos`,
          title: `Filtros`,
          reset: `Resetar`,
        },
        viewColumns: {
          title: `Mostrar colunas`,
          titleAria: `Mostrar/Ocultar Colunas da Tabela`,
        },
        selectedRows: {
          text: `linha(s) selecionada(s)`,
          delete: `Deletar`,
          deleteAria: `Deletear linha(s) selecionada(s)`,
        },
      },
      onRowClick: rowData => {
        if (rowData[9] && rowData[9].taskType === `Ouvidoria`) {
          openOmbudsmanRequest({ id: rowData[8], dialog });
        } else {
          openTasksRequest({ id: rowData[8], menuType: rowData[9].taskType, isExluded: rowData[13] });
        }
      },
      onTableChange: (action, tableState) => {
        const { filterList, page, rowsPerPage, searchText } = tableState;
        switch (action) {
          case `sort`:
            changeTasksSort({
              activeColumn: tableState.activeColumn,
              sort: tableState.announceText.split(` : `)[1],
            });
            break;
          case `resetFilters`:
          case `filterChange`:
            changeTasksFilters({ page, filterList, searchText });
            break;
          case `search`:
            clearTimeout(this.searchTimeout);
            this.searchTimeout = setTimeout(() => {
              changeTasksFilters({ page, filterList, searchText });
            }, 500);
            break;
          case `changeRowsPerPage`:
          case `changePage`:
            changeTasksPages({ page, rowsPerPage });
            break;
          default:
        }
      },
      onDownload: () => {
        const { loadReportRequest, loadReportSuccessfull, Tasks } = this.props;
        loadReportRequest();
        let url = `/tasks/tasks/getreport/?${buildQueryStringFromArray(Tasks.filterList, Tasks.searchText)}`;
        if (Object.entries(Tasks.sort).length !== 0) {
          url += `&sort=${Object.keys(Tasks.sort)[0]},${Tasks.sort[Object.keys(Tasks.sort)[0]].order}`;
        }
        api
          .get(url)
          .then(() => {
            loadReportSuccessfull();
            dialog.alert({
              title: `Baixar relatório`,
              message: `O relatório será processado e você receberá uma notificação assim que estiver pronto`,
              ok: { text: `Ok`, variant: `contained`, color: `primary` },
            });
          })
          .catch(error => {
            // eslint-disable-next-line
            console.error(error);
          });
        return false;
      },
    };

    return (
      <>
        <Hidden only={[`md`, `lg`, `xl`]}>
          <div className={classes.smRecipient} onScroll={this.handleScroll}>
            <MobileTasks
              addmenuselector={addMenuSelector}
              close={this.handleAnchorClose(`addMenuSelector`)}
              data={propsTasks.data}
              institutions={Institutions}
              isLoading={propsTasks.isLoading}
              handleOpen={this.openTaskMobile}
              open={this.handleAnchorOpen(`addMenuSelector`)}
            />
          </div>
          <DialogTaskForm
            avatar={avatar}
            fullscreen
            open={propsTasks.modalOpen}
            handleClose={closeTasksRequest}
            formInstance={propsTasks.formInstance}
            isConvertingTaskToCall={propsTasks.isConvertingTaskToCall}
            formType={propsTasks.menuType}
          />
          {OmbudsmansProps.dialogOpen && <OmbudsmanDialogForm formInstance={OmbudsmansProps.formInstance} />}
        </Hidden>
        <Hidden only={[`xs`, `sm`]}>
          {propsTasks.isLoading && <LinearProgress style={{ position: `relative` }} />}
          <MUIDataTable
            title={
              <CustomTableTitle
                title={
                  [`Preventiva`, `Chamado`, `Ouvidoria`, `Não conformidade`, `Tarefa`].indexOf(filterValue.value) > -1
                    ? `${filterValue.value}s`
                    : filterValue.value || `To-dos`
                }
              >
                {this.buildKpiComponent()}
              </CustomTableTitle>
            }
            data={propsTasks.data}
            columns={columns}
            options={options}
          />
          <DialogTaskForm
            avatar={avatar}
            open={propsTasks.modalOpen}
            handleClose={closeTasksRequest}
            isConvertingTaskToCall={propsTasks.isConvertingTaskToCall}
            formInstance={propsTasks.formInstance}
            formType={propsTasks.menuType}
          />
          {OmbudsmansProps.dialogOpen && <OmbudsmanDialogForm formInstance={OmbudsmansProps.formInstance} />}
        </Hidden>
      </>
    );
  }
}

const mapStateToProps = state => ({
  loggedUserInfo: state.Auth,
  Institutions: state.Institutions.data,
  Sectors: state.Sectors.data,
  Tasks: state.Tasks,
  OmbudsmansProps: state.Ombudsmans,
});

const mapDispatchToProps = dispatch => bindActionCreators({ ...OmbudsmanActions, ...tasksActions }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(withRouter(withDialog()(Tasks))));
