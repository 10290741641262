import { call, delay, put, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { Action } from 'redux';
import { Dialog } from 'muibox';
import { AxiosResponse } from 'axios';
import { ApplicationState } from '../index';
import { FormInstance, CRMsState as CRMsStateType, CRMsTypes } from './Types';
import { TableSortOrder } from '../types';
import api from '../../../Services/api';
import { filterListToQueryObject } from '../../../Utils';

/**
 * CRM actions
 */
import {
  addOrUpdateFailure,
  addSuccess,
  closeCRMRequest,
  closeCRMSuccess,
  CRMMobileInfiniteScrollSuccess,
  loadCRMsSuccess,
  loadRequest,
  openCRMSuccess,
  SagaFormInstance,
  updateSuccess,
} from './Actions';

interface AddOrUpdateAction extends Action {
  payload: {
    afterSavingCallback?: (data: FormInstance) => void;
    data: SagaFormInstance;
    dialog: Dialog;
  };
}

interface LoadAction extends Action {
  payload: {
    dialog: Dialog;
    mobile?: boolean;
  };
}

interface OpenDialogAction extends Action {
  payload: {
    dialog: Dialog;
    id?: number | string;
  };
}
export function getSortValue(
  sort: { [key: string]: { order: TableSortOrder } } | undefined, // eslint-disable-line
): string | undefined {
  if (sort && Object.keys(sort).length) {
    const sortColumn = Object.entries(sort);
    const orderMap = {
      ascending: ``,
      descending: `-`,
    };
    return `${orderMap[sortColumn[0][1].order]}${sortColumn[0][0]}`;
  }
  return undefined;
}

export function* loadCRMs(action: LoadAction): SagaIterator {
  try {
    yield delay(500);
    const CRMsState: CRMsStateType = yield select((state: ApplicationState) => state.CRMs);
    const { filterList, page, rowsPerPage, searchText, sort } = CRMsState;
    const response = yield call(api.get, `/crm/person/`, {
      params: {
        ...filterListToQueryObject(filterList),
        globalSearch: searchText,
        length: rowsPerPage,
        page: page + 1,
        sort: getSortValue(sort),
      },
    });
    if (action.payload.mobile) {
      yield put(CRMMobileInfiniteScrollSuccess(response.data));
    } else {
      yield put(loadCRMsSuccess(response.data));
    }
  } catch (error) {
    const { dialog } = action.payload;
    dialog.alert(`Não foi possível connectar com o servidor!`);
    yield put({ type: CRMsTypes.LOAD_ERRORS });
  }
}

export function* addOrUpdateCRM(action: AddOrUpdateAction): SagaIterator {
  const { afterSavingCallback, dialog } = action.payload;
  try {
    const { id } = action.payload.data;
    const { data } = action.payload;
    delete data.files;
    delete data.id;
    let response: AxiosResponse<FormInstance>;
    if (Object.keys(data).length) {
      if (id) {
        response = yield call(api.patch, `/crm/person/${id}/`, data);
        yield put(updateSuccess(response.data));
        yield put(closeCRMRequest());
      } else {
        response = yield call(api.post, `/crm/person/`, data);
        if (afterSavingCallback) afterSavingCallback(response.data);
        yield put(addSuccess(response.data));
        yield put(closeCRMRequest());
      }
    }
    yield put(loadRequest(dialog));
  } catch (error) {
    yield put(addOrUpdateFailure(error.response.data));
  }
}

export function* openCRMsDialog(action: OpenDialogAction): SagaIterator {
  try {
    const { id } = action.payload;
    if (id) {
      const response = yield call(api.get, `/crm/person/${id}/`);
      yield put(openCRMSuccess(response.data));
    } else {
      yield put(openCRMSuccess());
    }
  } catch (error) {
    const { dialog } = action.payload;
    dialog.alert(`Não foi possível connectar com o servidor!`);
    yield put({ type: CRMsTypes.LOAD_ERRORS });
  }
}

export function* closeCRMsDialog(): SagaIterator {
  yield put(closeCRMSuccess());
}
