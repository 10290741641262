import React, { ChangeEvent, FormEvent, SFC, useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Dialog as DialogType } from 'muibox';
import { InventoryCategoryDialog } from '../../../../ReduxFlow/Reducers/InventoryCategories/Types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionsRoot: {
      alignItems: `center`,
      display: `flex`,
      flex: `0 0 auto`,
      justifyContent: `flex-end`,
      padding: 24,
    },
    actionsSpacing: {
      '& > :not(:first-child)': {
        marginLeft: 16,
      },
    },
    disabledButton: {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: `${theme.palette.primary.contrastText} !important`,
    },
    textField: {
      marginBottom: theme.spacing(3.25),
      width: `100%`,
    },
  }),
);

export type InventoryCategoriesDialogProps = {
  addOrUpdateRequest: Function;
  closeDialogForm: Function;
  dialog: DialogType;
  dialogOpen: boolean;
  formInstance?: InventoryCategoryDialog;
  isSaving: boolean;
  subtitle: string;
};

const InventoryCategoriesDialog: SFC<InventoryCategoriesDialogProps> = ({
  addOrUpdateRequest,
  closeDialogForm,
  dialog,
  dialogOpen,
  formInstance,
  isSaving,
  subtitle,
}) => {
  const classes = useStyles();
  const [inventoryCategoryData, setInventoryCategoryData] = useState<InventoryCategoryDialog>({
    description: ``,
    name: ``,
    subCategories: [],
  });
  useEffect(() => {
    if (formInstance) {
      setInventoryCategoryData(formInstance);
    }
  }, [formInstance]);

  function handleSubmitForm(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    const postData: InventoryCategoryDialog = {
      ...inventoryCategoryData,
      createdAt: undefined,
      isActive: true,
    };
    if (formInstance) {
      postData.id = formInstance.id;
    }
    addOrUpdateRequest({ dialog, postData });
  }

  const handleFieldChange = (name: string) => (event: ChangeEvent<HTMLInputElement>): void => {
    event.persist();
    setInventoryCategoryData(prevData => ({
      ...prevData,
      [name]: event.target.value,
    }));
  };

  return (
    <Dialog
      aria-labelledby="docs-categories-dialog"
      fullWidth
      maxWidth="xs"
      onClose={(): void => closeDialogForm()}
      open={dialogOpen}
    >
      <DialogTitle disableTypography>
        <Typography variant="h6">Criar categoria</Typography>
        <Typography variant="body1" style={{ color: `#00000099` }}>
          {subtitle}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <form id="docsCategoriesForm" onSubmit={handleSubmitForm}>
          <TextField
            autoFocus
            className={classes.textField}
            id="id_name"
            label="Nome da categoria"
            name="name"
            onChange={handleFieldChange(`name`)}
            value={inventoryCategoryData.name}
            variant="outlined"
          />
          <TextField
            className={classes.textField}
            helperText="Dica: Descreva as características dos itens desta categoria"
            id="id_description"
            label="Descrição da categoria"
            name="description"
            onChange={handleFieldChange(`description`)}
            value={inventoryCategoryData.description}
            variant="outlined"
          />
        </form>
      </DialogContent>
      <DialogActions classes={{ root: classes.actionsRoot, spacing: classes.actionsSpacing }}>
        <Button color="default" onClick={(): void => closeDialogForm()}>
          Cancelar
        </Button>
        <Button
          classes={{ disabled: classes.disabledButton }}
          color="primary"
          disabled={isSaving}
          form="docsCategoriesForm"
          type="submit"
          variant="contained"
        >
          {!isSaving && (formInstance ? `Salvar edições` : `Criar categoria`)}
          {isSaving && <CircularProgress color="inherit" size={24} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InventoryCategoriesDialog;
