import React from 'react';
/**
 * Material UI - Core
 */
import {
  Chip,
  CircularProgress,
  Fab,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Slide,
  Typography,
  withStyles,
} from '@material-ui/core';
/**
 * Material UI - Icons
 */
import { Add } from '@material-ui/icons';
import TableInstitutions from '../../Components/TableInstitutions';

const styles = theme => ({
  instAvatar: {
    margin: 5,
    width: 50,
    height: 50,
  },
  userText: {
    wordWrap: `break-word`,
  },
  userWrapper: {
    margin: `5px auto 5px auto`,
    maxWidth: `98%`,
  },
  fab: {
    position: `absolute`,
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  loader: {
    position: `fixed`,
    bottom: 30,
    left: `45%`,
    zIndex: 999,
  },
  footer: {
    width: `100vw`,
    height: `56px`,
    position: `absolute`,
    bottom: 0,
    left: 0,
  },
});

const MobileQC = ({ addmenuselector, classes, close, data, handleOpen, isLoading, institutions, open }) => {
  return (
    <>
      {isLoading && <CircularProgress className={classes.loader} />}
      {data.map(value => {
        return (
          <Slide key={value.tableName.id} in direction="up" mountOnEnter unmountOnExit>
            <Paper
              className={classes.userWrapper}
              onClick={event => handleOpen({ dbname: value.tableQctype, id: value.tableName.id })}
            >
              <Grid container alignItems="center">
                <Grid item xs={9} style={{ marginLeft: 10 }}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} className={classes.userText}>
                      {value.tableMarker && (
                        <Chip
                          label={value.tableMarker.name}
                          style={{
                            marginTop: 10,
                            fontSize: 11,
                            fontWeight: `bolder`,
                            color: value.tableMarker.fontColor,
                            backgroundColor: value.tableMarker.color,
                            height: 15,
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>
                  <Grid container alignItems="center">
                    <Grid item xs={12}>
                      <Typography>{value.tableName.name}</Typography>
                      <Typography color="textSecondary">
                        <small>{`#${value.tableName.id}`}</small>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container item xs={2} alignItems="center" alignContent="center" justify="center">
                  <div>
                    <Typography>
                      <small style={{ marginRight: 5 }}>
                        {new Date(`${value.executed}T12:00`).toLocaleDateString(`pt-br`)}
                      </small>
                    </Typography>
                  </div>
                  <TableInstitutions institutions={institutions} selected={[value.institution]} />
                </Grid>
              </Grid>
            </Paper>
          </Slide>
        );
      })}
      <Fab aria-label="Adicionar" aria-owns="addMobileMenu" className={classes.fab} color="primary" onClick={open}>
        <Add />
      </Fab>
      <Menu
        id="addMenu"
        anchorEl={addmenuselector}
        open={Boolean(addmenuselector)}
        anchorOrigin={{ vertical: `top`, horizontal: `right` }}
        transformOrigin={{ vertical: `bottom`, horizontal: `center` }}
        getContentAnchorEl={null}
        onClose={close}
      >
        {[
          { dbName: `qcserver`, name: `CQ Servidor`, id: 0 },
          { dbName: `qctemperature`, name: `CQ Temperatura`, id: 1 },
          { dbName: `qctempctmr`, name: `CQ Temperatura TC-RM`, id: 2 },
          { dbName: `qcquench`, name: `CQ Quench`, id: 3 },
          { dbName: `qcphysics`, name: `CQ Física`, id: 4 },
        ].map(val => (
          <MenuItem key={val.id} onClick={event => handleOpen({ dbname: val.dbName, id: null })}>
            {val.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default withStyles(styles)(MobileQC);
