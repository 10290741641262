import { call, put, select } from 'redux-saga/effects';
import { Action, Store } from 'redux';
import { SagaIterator } from 'redux-saga';

/**
 * API from axios
 */
import api from '../../../Services/api';

/**
 * Modal actions
 */
import { closeExternalDocsSuccess, loadExternalDocsSuccess, openExternalDocsSuccess } from './Actions';
import { buildQueryStringFromObject } from '../../../Utils';

/**
 * Get access token
 */
interface AppStore extends Store {
  Auth?: any;
  ExternalDocs?: any;
}
interface AppAction extends Action {
  payload?: any;
}

export function* loadExternalDocs(): SagaIterator {
  try {
    const externalDocsState = yield select((state: AppStore) => state.ExternalDocs);
    let url = `&${buildQueryStringFromObject(externalDocsState.filterList, externalDocsState.searchText)}`;
    if (Object.entries(externalDocsState.sort).length !== 0) {
      url += `&sort=${Object.keys(externalDocsState.sort)[0]},${
        externalDocsState.sort[Object.keys(externalDocsState.sort)[0]].order
      }`;
    }
    const response = yield call(
      api.get,
      `/externaldocs/externaldocs/?page=${externalDocsState.page + 1}&length=${externalDocsState.rowsPerPage}${url}`,
    );
    yield put(loadExternalDocsSuccess(response.data));
  } catch (error) {
    alert(`Não foi possível conectar com o servidor!`); // eslint-disable-line
  }
}

export function* openExternalDocsModal(data: AppAction): SagaIterator {
  try {
    if (data.payload.id) {
      const response = yield call(api.get, `/externaldocs/externaldocs/${data.payload.id}/`);
      yield put(openExternalDocsSuccess([response.data]));
    } else {
      yield put(openExternalDocsSuccess([]));
    }
  } catch (error) {
    alert(`Não foi possível realizar essa ação, tente mais tarde!`); // eslint-disable-line
  }
}

export function* closeExternalDocsModal(): SagaIterator {
  try {
    yield put(closeExternalDocsSuccess());
  } catch (error) {
    alert(`Não foi possível realizar essa ação, tente mais tarde!`); // eslint-disable-line
  }
}
