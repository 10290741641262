import { action, ActionType } from 'typesafe-actions';
import { InternalDoc, InternalDocsTypes } from './Types';

export const loadInternalDocsRequest = (): ActionType<typeof action> => action(InternalDocsTypes.LOAD_REQUEST);
export const loadInternalDocsSuccess = (data: {
  count: number;
  next?: string;
  previous?: string;
  results: InternalDoc[];
}): ActionType<typeof action> => action(InternalDocsTypes.LOAD_SUCCESS, data);
export const openInternalDocsRequest = (data: {
  id: number | string;
  searchText?: string;
  filterList?: { [key: string]: string[] };
}): ActionType<typeof action> => action(InternalDocsTypes.OPEN_REQUEST, data);
export const openInternalDocsSuccess = (data: any[]): ActionType<typeof action> =>
  action(InternalDocsTypes.OPEN_SUCCESS, data);
export const closeInternalDocsRequest = (): ActionType<typeof action> => action(InternalDocsTypes.CLOSE_REQUEST);
export const closeInternalDocsSuccess = (): ActionType<typeof action> => action(InternalDocsTypes.CLOSE_SUCCESS);
export const changeInternalDocsSort = (data: { activeColumn: number; sort: string }): ActionType<typeof action> =>
  action(InternalDocsTypes.CHANGE_SORT, data);
export const changeInternalDocsFilters = (data: {
  filterList: { [key: string]: string[] };
  sort: { [key: string]: string };
  searchText: string;
}): ActionType<typeof action> => action(InternalDocsTypes.CHANGE_FILTERS, data);
export const changeInternalDocsPages = (data: { rowsPerPage: number; page: number }): ActionType<typeof action> =>
  action(InternalDocsTypes.CHANGE_PAGES, data);
