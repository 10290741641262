import { Button, Dialog, DialogContent, Grid, Hidden, MenuItem, Paper, RootRef, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { CommentOutlined as CommentOutlinedIcon, Maximize as MaximizeIcon } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { AxiosResponse } from 'axios';
import CRMSelect from 'Components/NewUIComponents/CRMSelect';
import CustomTextField from 'Components/NewUIComponents/CustomTextField';
import DropZoneIndicator from 'Components/NewUIComponents/DropZoneIndicator';
import { Attach } from 'Components/NewUIComponents/TaskAttachsContainer';
import TasksCloseReopenDialog from 'Components/NewUIComponents/TasksCloseReopenDialog';
import { useDialog } from 'muibox';
import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { FileWithPath, useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
/**
 * State types
 */
import { ApplicationState } from 'ReduxFlow/Reducers';
import { User } from 'ReduxFlow/Reducers/Auth/Types';
import { Institution } from 'ReduxFlow/Reducers/Institutions/Types';
import * as OmbudsmansActions from 'ReduxFlow/Reducers/Ombudsmans/Actions';
import { OmbudsmanInstance, OmbudsmansState } from 'ReduxFlow/Reducers/Ombudsmans/Types';
import { OmbudsmanSource } from 'ReduxFlow/Reducers/OmbudsmanSources/Types';
import { Sector } from 'ReduxFlow/Reducers/Sectors/Types';
import { TasksCategoryState } from 'ReduxFlow/Reducers/TasksCategories/Types';
import api from 'Services/api';
import SwipeableBottomSheet from 'Components/NewUIComponents/SwipeableBottomSheet';
import RightColumn from 'Components/NewUIComponents/TasksForms/RigthColumn';
import FormHeader from 'Components/NewUIComponents/TasksForms/FormHeader';
import FormBody from 'Components/NewUIComponents/TasksForms/FormBody';
import { Comment } from 'Components/NewUIComponents/CommentsContainer/Tasks';
import { OmbudsmanFields } from '..';
import { commentsAndAttachsHandlers, handleChangeFields, TaskType } from '../../../tasksCommonHandlers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bottomSheetHeader: {
      minHeight: theme.spacing(8),
      position: `absolute`,
      zIndex: 4,
      width: `100%`,
      height: `fit-content`,
      margin: `-13px 0 -12px -12px`,
    },
    bottomSheetHeaderButtons: {
      marginTop: -theme.spacing(2),
      minHeight: theme.spacing(6),
    },
    bottomSheetHeaderCommentButton: {
      borderColor: theme.palette.primary.dark,
      color: theme.palette.primary.dark,
    },
    bottomSheetScrollable: {
      marginTop: theme.spacing(8),
    },
    buttonDark: {
      backgroundColor: theme.palette.primary.dark,
    },
    customAutocomplete: {
      width: `100%`,
    },
    customInstitutionsField: {
      width: `100%`,
      [theme.breakpoints.up(`sm`)]: {
        marginRight: theme.spacing(0.5),
        width: `calc(100% - ${theme.spacing(0.5)}px)`,
      },
    },
    customTextFieldLeft: {
      marginRight: theme.spacing(0.5),
      width: `calc(100% - ${theme.spacing(0.5)}px)`,
    },
    fieldsContainer: {
      marginTop: theme.spacing(2.5),
    },
    formCategoryText: {
      font: `500 16px/22px Hind`,
      letterSpacing: -0.27,
      marginBottom: theme.spacing(1.25),
      marginTop: theme.spacing(2.5),
    },
    formCategoryTextDisabled: {
      opacity: 0.4,
    },
    ombSkeletonMargin: {
      marginBottom: theme.spacing(0.5),
      marginTop: theme.spacing(1),
    },
  }),
);

const OmbudsmanViewUpdateForm: FC<{ fullScreen: boolean }> = ({ fullScreen }) => {
  const classes = useStyles();
  const dialog = useRef(useDialog());
  const dispatch = useDispatch();
  const history = useHistory();

  const [attachs, setAttachs] = useState<Attach[]>([]);
  const [closeReopenDialog, setCloseReopenDialog] = useState<boolean>(false);
  const [comment, setComment] = useState<string>(``);
  const [comments, setComments] = useState<Comment[]>([]);
  const [commentsRange, setCommentsRange] = useState<number>(5);
  const [backdrop, setBackdrop] = useState<boolean>(false);
  const [emailSend, setEmailSend] = useState<boolean>(false);
  const [isGettingComments, setIsGettingComments] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [temporaryAttachs, setTemporaryAttachs] = useState<FileWithPath[]>([]);
  const [temporaryAttachsHasError, setTemporaryAttachsHasError] = useState<{ [key: number]: boolean } | undefined>(
    undefined,
  );
  const [temporaryAttachsProgress, setTemporaryAttachsProgress] = useState<{ [key: number]: number } | undefined>(
    undefined,
  );

  const [ombudsmanFields, setOmbudsmanFields] = useState<OmbudsmanFields>({
    crm: undefined,
    description: ``,
    estimatedEndDate: null,
    institution: undefined,
    requester: undefined,
    requesterSector: ``,
    responsible: undefined,
    responsibleSector: ``,
    sector: [],
    source: ``,
    startDate: null,
    subcategory: undefined,
    title: ``,
    users: undefined,
  });
  const [isEditingFields, setIsEditingFields] = useState<{ [key in keyof OmbudsmanFields]: boolean }>({
    crm: false,
    description: false,
    estimatedEndDate: false,
    institution: false,
    requester: false,
    requesterSector: false,
    responsible: false,
    responsibleSector: false,
    sector: false,
    source: false,
    startDate: false,
    subcategory: false,
    title: false,
    users: false,
  });

  const institutions = useSelector<ApplicationState, Institution[]>(
    state => state.Institutions.data,
    (previous, next) => JSON.stringify(previous) === JSON.stringify(next),
  );
  const sectors = useSelector<ApplicationState, Sector[]>(
    state => state.Sectors.data,
    (previous, next) => JSON.stringify(previous) === JSON.stringify(next),
  );
  const ombudsmansProps = useSelector<ApplicationState, OmbudsmansState>(
    state => state.Ombudsmans,
    (previous, next) => JSON.stringify(previous) === JSON.stringify(next),
  );
  const ombudsmanSources = useSelector<ApplicationState, OmbudsmanSource[]>(
    state => state.OmbudsmanSources.data,
    (previous, next) => JSON.stringify(previous) === JSON.stringify(next),
  );
  const tasksCategoriesState = useSelector<ApplicationState, TasksCategoryState>(
    state => state.TasksCategories,
    (previous, next) => JSON.stringify(previous) === JSON.stringify(next),
  );
  const loggedUser = useSelector<ApplicationState, User>(
    state => state.Auth.data.user,
    (previous, next) => JSON.stringify(previous) === JSON.stringify(next),
  );

  const { dialogOpen, formInstance } = ombudsmansProps;
  const newFormInstance = formInstance as OmbudsmanInstance;

  const disableAllFields = !newFormInstance.isActive;

  const disableDescription = newFormInstance.startedBy !== loggedUser.id;

  const disableCloseReopenButton =
    !!newFormInstance.feedbackTime ||
    (!loggedUser.isAdminOrSuperAdmin &&
      [newFormInstance.responsible, newFormInstance.startedBy].indexOf(loggedUser.id) < 0);

  const { closeOmbudsmansRequest } = OmbudsmansActions;

  const {
    commentsControll,
    getAttachs,
    getComments,
    endTask,
    handleCommentDelete,
    handleCommentSubmit,
    handleFileDelete,
    reopenTask,
    updateFormHandleFileSubmit,
  } = commentsAndAttachsHandlers<OmbudsmanInstance>({
    attachs,
    attachsSetter: setAttachs,
    commentSetter: setComment,
    comments,
    commentsSetter: setComments,
    dialog: dialog.current,
    isGettingCommentsSetter: setIsGettingComments,
    isSavingSetter: setIsSaving,
    loggedUser,
    range: commentsRange,
    rangeSetter: setCommentsRange,
    taskId: newFormInstance.id,
    taskType: TaskType.ombudsman,
    temporaryAttachsSetter: setTemporaryAttachs,
    temporaryAttachsHasErrorSetter: setTemporaryAttachsHasError,
    temporaryAttachsProgressSetter: setTemporaryAttachsProgress,
  });

  function handleSubmitAfterChange(name: keyof OmbudsmanFields, value: string): void {
    const newName = name === `source` ? `ombudsman.source` : name;
    const data = new FormData();
    if ([`startDate`, `estimatedEndDate`].indexOf(name) > -1) {
      data.append(newName, new Date(value).toLocaleString(`pt-BR`));
    } else {
      data.append(newName, value);
    }
    if (name === `subcategory`) {
      dispatch(
        OmbudsmansActions.ombudsmanAddOrUpdateRequest({
          afterUpdateCallback: () => getComments(comments[comments.length - 1].id),
          data,
          dialog: dialog.current,
          id: newFormInstance.id,
        }),
      );
    } else {
      dispatch(OmbudsmansActions.ombudsmanAddOrUpdateRequest({ data, dialog: dialog.current, id: newFormInstance.id }));
    }
  }

  const {
    handleDateFieldChange,
    handleFieldChange,
    handleInstitutionChange,
    handleReactSelectChange,
    handleSubCategoryChange,
  } = handleChangeFields<OmbudsmanFields>({
    afterChangeCallback: handleSubmitAfterChange,
    errorSetter: (_name, _remove, _formErrors) => {},
    fieldsValues: ombudsmanFields,
    setter: setOmbudsmanFields,
    tasksCategoriesState,
  });

  const onDrop = async (acceptedFiles: FileWithPath[]): Promise<void> => {
    if (temporaryAttachs.length) {
      setTemporaryAttachs(prevAttachs => [...prevAttachs, ...acceptedFiles]);
      await updateFormHandleFileSubmit(acceptedFiles);
      return;
    }
    setTemporaryAttachs(acceptedFiles);
    await updateFormHandleFileSubmit(acceptedFiles);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ disabled: disableAllFields, onDrop });
  const { ref, ...rootProps } = getRootProps();

  function handleCloseDialog(): void {
    if (emailSend) {
      api.get(`/tasks/sendmail/?type=UpdateTask&id=${newFormInstance.id}`).finally(() => {
        history.push(`/tasks/ombudsman`);
        dispatch(closeOmbudsmansRequest(dialog.current));
      });
    } else {
      history.push(`/tasks/ombudsman`);
      dispatch(closeOmbudsmansRequest(dialog.current));
    }
  }

  function handleToggleEditing(name: keyof OmbudsmanFields, value?: boolean): () => void {
    return function toggleEditing(): void {
      setIsEditingFields(prevFields => ({
        ...prevFields,
        [name]: value !== undefined ? value : !prevFields[name],
      }));
    };
  }

  function handleSaveEditing(name: keyof OmbudsmanFields): (value: string) => void {
    return function saveEditing(value: string): void {
      handleToggleEditing(name)();
      setOmbudsmanFields(prevValues => ({
        ...prevValues,
        [name]: value,
      }));
      setEmailSend(true);
      handleSubmitAfterChange(name, value);
    };
  }

  useEffect((): void => {
    const { id: _id, ...restOfTheFields } = newFormInstance;
    setOmbudsmanFields({
      ...restOfTheFields,
      institution: restOfTheFields.institution.length ? restOfTheFields.institution[0] : undefined,
      source: restOfTheFields.ombudsman.source,
      sector: restOfTheFields.sector.length ? restOfTheFields.sector : [],
    });
  }, [newFormInstance]);

  useEffect(getAttachs, []);
  useEffect(getComments, []);

  async function closeOrReopenTask(): Promise<void> {
    setIsSaving(true);
    let response: AxiosResponse<{ comments: Comment[]; task: OmbudsmanInstance }>;
    if (newFormInstance.isActive) {
      response = await endTask(comment);
      if (response.status === 200) {
        setCloseReopenDialog(false);
        dispatch(OmbudsmansActions.ombudsmanUpdateSuccess(response.data.task));
        setComments(prevComments => [...prevComments, ...response.data.comments]);
        setComment(``);
        setIsSaving(false);
      } else {
        setIsSaving(false);
      }
      return;
    }
    response = await reopenTask(comment);
    if (response.status === 200) {
      setCloseReopenDialog(false);
      dispatch(OmbudsmansActions.ombudsmanUpdateSuccess(response.data.task));
      setComments(prevComments => [...prevComments, ...response.data.comments]);
      setComment(``);
      setIsSaving(false);
    } else {
      setIsSaving(false);
    }
  }

  function formCategoryText(disabled: boolean, text: string): ReactNode {
    return (
      <Typography
        className={
          disableAllFields
            ? `${classes.formCategoryText} ${classes.formCategoryTextDisabled}`
            : classes.formCategoryText
        }
      >
        {text}
      </Typography>
    );
  }

  return (
    <RootRef rootRef={ref}>
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        maxWidth="md"
        onClose={handleCloseDialog}
        open={dialogOpen}
        {...rootProps} // eslint-disable-line
        onClick={undefined}
      >
        <TasksCloseReopenDialog
          closeOrReopenTask={closeOrReopenTask}
          comment={comment}
          handleChangeComment={(event): void => setComment(event.target.value)}
          isSaving={isSaving}
          loggedUserAvatar={loggedUser.avatarThumb}
          onClose={(): void => setCloseReopenDialog(false)}
          open={closeReopenDialog}
        />
        <DropZoneIndicator isDragActive={isDragActive && !disableAllFields} />
        <DialogContent style={{ padding: fullScreen ? 12 : 30 }}>
          <FormHeader
            disableAllFields={disableAllFields}
            disableCloseReopenButton={disableCloseReopenButton}
            formHeaderFields={ombudsmanFields}
            formHeaderTasksProps={{ ...ombudsmansProps, formInstance: newFormInstance }}
            fullScreen={fullScreen}
            handleCloseDialog={handleCloseDialog}
            handleSaveEditing={handleSaveEditing}
            handleToggleEditing={handleToggleEditing}
            isEditingFields={isEditingFields}
            setCloseReopenDialog={setCloseReopenDialog}
          />
          <Grid className={classes.fieldsContainer} container>
            <FormBody
              attachs={attachs}
              comment={comment}
              comments={comments}
              commentsControll={commentsControll}
              commentsRange={commentsRange}
              disableAllFields={disableAllFields}
              disableDescription={disableDescription}
              formBodyFields={ombudsmanFields}
              formBodyTasksProps={{ ...ombudsmansProps, formInstance: newFormInstance }}
              formCategoryText={formCategoryText}
              fullScreen={fullScreen}
              getInputProps={getInputProps}
              getRootProps={getRootProps}
              handleCommentDelete={handleCommentDelete}
              handleCommentSubmit={handleCommentSubmit}
              handleDateFieldChange={handleDateFieldChange}
              handleFileDelete={handleFileDelete}
              handleSaveEditing={handleSaveEditing}
              handleSubCategoryChange={handleSubCategoryChange}
              handleToggleEditing={handleToggleEditing}
              isEditingFields={isEditingFields}
              isGettingComments={isGettingComments}
              isSaving={isSaving}
              loggedUser={loggedUser}
              setComment={setComment}
              specificTaskInput={
                !ombudsmanSources.length ? (
                  <Skeleton
                    animation="wave"
                    className={`${classes.customTextFieldLeft} ${classes.ombSkeletonMargin}`}
                    height={54}
                    variant="rect"
                  />
                ) : (
                  <CustomTextField
                    className={classes.customTextFieldLeft}
                    disabled={disableAllFields}
                    error={!!ombudsmansProps.formErrors && !!ombudsmansProps.formErrors.source}
                    fullWidth
                    hasInstance
                    helperText={!!ombudsmansProps.formErrors && ombudsmansProps.formErrors.source}
                    id="id_ombSource"
                    isEditing={isEditingFields.source}
                    label="Origem da ouvidoria"
                    margin="dense"
                    name="ombudsman.source"
                    onBlur={handleToggleEditing(`source`, false)}
                    onChange={handleFieldChange(`source`)}
                    onClick={!isEditingFields.source && !disableAllFields ? handleToggleEditing(`source`) : undefined}
                    required
                    select
                    value={ombudsmanFields.source}
                  >
                    {ombudsmanSources.map(source => (
                      <MenuItem dense={false} key={source.id} value={source.id}>
                        {source.name}
                      </MenuItem>
                    ))}
                  </CustomTextField>
                )
              }
              tasksCategoriesState={tasksCategoriesState}
              temporaryAttachs={temporaryAttachs}
              temporaryAttachsHasError={temporaryAttachsHasError}
              temporaryAttachsProgress={temporaryAttachsProgress}
              updateFormHandleFileSubmit={updateFormHandleFileSubmit}
            />
            <Hidden mdDown>
              <Grid item style={{ paddingLeft: 60 }} xs={12} md={5}>
                <RightColumn
                  classes={classes}
                  commonFields={ombudsmanFields}
                  commonTasksProps={{ ...ombudsmansProps, formInstance: newFormInstance }}
                  customContextField={
                    <CRMSelect
                      disabled={disableAllFields}
                      hasInstance
                      inputClassName={classes.customAutocomplete}
                      isEditing={isEditingFields.crm}
                      onBlur={handleToggleEditing(`crm`, false)}
                      onChange={handleReactSelectChange(`crm`)}
                      onFocus={handleToggleEditing(`crm`)}
                      value={ombudsmanFields.crm}
                    />
                  }
                  disableAllFields={disableAllFields}
                  formCategoryText={formCategoryText}
                  handleFieldChange={handleFieldChange}
                  handleInstitutionChange={handleInstitutionChange}
                  handleReactSelectChange={handleReactSelectChange}
                  handleToggleEditing={handleToggleEditing}
                  isEditingFields={isEditingFields}
                  institutions={institutions}
                  loggedUser={loggedUser}
                  sectors={sectors}
                />
              </Grid>
            </Hidden>
          </Grid>
          <Hidden mdUp>
            <SwipeableBottomSheet
              bodyStyle={{
                borderRadius: `12px 12px 0 0`,
                padding: 12,
              }}
              marginTop={24}
              onChange={(): void => {
                setBackdrop(!backdrop);
              }}
              open={backdrop}
              overflowHeight={89}
              style={{
                zIndex: 3,
              }}
            >
              <Grid item xs={12}>
                <Paper className={classes.bottomSheetHeader} elevation={0}>
                  <Grid container direction="column" alignContent="center" alignItems="center" justify="center">
                    <MaximizeIcon
                      fontSize="large"
                      onClick={(): void => {
                        setBackdrop(!backdrop);
                      }}
                      style={{
                        color: `#E4E4E4`,
                      }}
                    />
                    <Grid container alignContent="center" alignItems="center" direction="row" justify="space-evenly">
                      <Button
                        className={`${classes.bottomSheetHeaderButtons} ${classes.bottomSheetHeaderCommentButton}`}
                        onClick={(): void => {
                          setBackdrop(false);
                          const commentsInput = document.getElementById(`id_comment`);
                          if (commentsInput) commentsInput.focus();
                        }}
                        variant="outlined"
                      >
                        <CommentOutlinedIcon /> Comentar
                      </Button>
                      <Button
                        className={
                          newFormInstance.isActive
                            ? classes.bottomSheetHeaderButtons
                            : `${classes.bottomSheetHeaderButtons} ${classes.buttonDark}`
                        }
                        color="primary"
                        disabled={disableCloseReopenButton}
                        onClick={(): void => setCloseReopenDialog(true)}
                        variant="contained"
                      >
                        {newFormInstance.isActive ? `Marcar como concluída` : `Reabrir`}
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid className={classes.bottomSheetScrollable} item xs={12}>
                <RightColumn
                  classes={classes}
                  commonFields={ombudsmanFields}
                  commonTasksProps={{ ...ombudsmansProps, formInstance: newFormInstance }}
                  customContextField={
                    <CRMSelect
                      disabled={disableAllFields}
                      hasInstance
                      inputClassName={classes.customAutocomplete}
                      isEditing={isEditingFields.crm}
                      onBlur={handleToggleEditing(`crm`, false)}
                      onChange={handleReactSelectChange(`crm`)}
                      onFocus={handleToggleEditing(`crm`)}
                      value={ombudsmanFields.crm}
                    />
                  }
                  disableAllFields={disableAllFields}
                  formCategoryText={formCategoryText}
                  handleFieldChange={handleFieldChange}
                  handleInstitutionChange={handleInstitutionChange}
                  handleReactSelectChange={handleReactSelectChange}
                  handleToggleEditing={handleToggleEditing}
                  isEditingFields={isEditingFields}
                  institutions={institutions}
                  loggedUser={loggedUser}
                  sectors={sectors}
                />
              </Grid>
            </SwipeableBottomSheet>
          </Hidden>
        </DialogContent>
      </Dialog>
    </RootRef>
  );
};

export default OmbudsmanViewUpdateForm;
