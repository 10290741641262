import React, { FC } from 'react';
import { Avatar } from '@material-ui/core';
import { CheckCircle as CheckCircleIcon, Replay as ReplayIcon } from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Markdown from 'markdown-to-jsx';
import AutoComment from '../../AutoComment';
import { AutoCommentCommonTypes } from '../..';
import { Comment, CommentType } from '..';
import markdownOverrides from '../../../../../Utils/markdownOverrides';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      height: theme.spacing(3),
      width: theme.spacing(3),
    },
    closeIcon: {
      color: theme.palette.success.main,
    },
    icon: {
      height: theme.spacing(2.25),
      width: theme.spacing(2.25),
    },
    reopenIcon: {
      color: theme.palette.primary[theme.palette.type],
    },
  }),
);

type CloseOrReopenAutoCommentProps = AutoCommentCommonTypes<Comment> & { taskType: string };

const CloseOrReopenAutoComment: FC<CloseOrReopenAutoCommentProps> = ({
  comment,
  fromNow,
  genericAvatar,
  isFirstItem = false,
  isLastItem = false,
  nextIsAutoComment,
  previousIsAutoComment,
  taskType,
}) => {
  const classes = useStyles();
  const { userDetail } = comment;
  const isCloseComment = comment.commentType === CommentType.TASK_CLOSE;

  return (
    <AutoComment
      avatar={<Avatar className={classes.avatar} src={userDetail.avatarThumb || genericAvatar} />}
      fromNow={fromNow}
      headerActionText={`${isCloseComment ? `finalizou` : `reabriu`} ${
        taskType !== `chamado` ? `esta ${taskType}` : `este ${taskType}`
      }`}
      isFirstItem={isFirstItem}
      isLastItem={isLastItem}
      nextIsAutoComment={nextIsAutoComment}
      previousIsAutoComment={previousIsAutoComment}
      startAdornment={
        isCloseComment ? (
          <CheckCircleIcon className={`${classes.icon} ${classes.closeIcon}`} />
        ) : (
          <ReplayIcon className={`${classes.icon} ${classes.reopenIcon}`} />
        )
      }
      userName={userDetail.fullName}
    >
      <Markdown
        options={{
          overrides: markdownOverrides,
        }}
      >
        {comment.comment}
      </Markdown>
    </AutoComment>
  );
};

export default CloseOrReopenAutoComment;
