import React, { FC, FocusEventHandler, MouseEvent, ReactElement, ReactNode } from 'react';
import { Grid, InputAdornment, ListItemIcon, ListItemText, MenuItem, Typography } from '@material-ui/core';
import {
  AccountCircleOutlined as AccountCircleOutlinedIcon,
  Add as AddIcon,
  BusinessCenterOutlined as BusinessCenterOutlinedIcon,
  PersonAddDisabledOutlined as PersonAddDisabledOutlinedIcon,
} from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog as DialogType, useDialog } from 'muibox';
import { ValueType } from 'react-select';
/**
 * Types
 */
import { ApplicationState } from 'ReduxFlow/Reducers';
import { CRMsState, FormInstance as CRM } from 'ReduxFlow/Reducers/CRMs/Types';
import { MarginType } from 'Utils/globalTypes';
import {
  addOrUpdateRequest as crmAddOrUpdateRequest,
  closeCRMRequest as closeCRMRequestDialog,
  openCRMRequest as openCRMRequestDialog,
  SagaFormInstance,
} from 'ReduxFlow/Reducers/CRMs/Actions';
import CustomAutocomplete, {
  CustomSelectOptionProps,
  stylesForCrmAndUserBuilder,
} from 'Components/NewUIComponents/CustomAutocomplete';
import DialogCRMForm from 'Pages/CRMs/DialogCRMForm';
import { FontSize } from 'Pages/Tasks/DynamicTasksPage';
import { formatDocumentByType } from 'Pages/CRMs';

interface CRMTextAndIcon {
  className: string;
  icon: ReactElement;
  text?: ReactElement;
}

export const getCrmTextAndIcon = (data: {
  classes: {
    listItemRootOther: string;
    listItemRootPerson: string;
  };
  fontSize?: FontSize;
  iconClassName: string;
  isTooltip?: boolean;
  optionData: CRM;
}): CRMTextAndIcon | undefined => {
  const { classes, fontSize, iconClassName, isTooltip, optionData } = data;
  if (optionData && !Array.isArray(optionData)) {
    const newOptionData = optionData;
    if (newOptionData.fullName === `Anônimo`)
      return {
        className: classes.listItemRootOther,
        icon: <PersonAddDisabledOutlinedIcon className={iconClassName} fontSize={fontSize} />,
        text: isTooltip ? <Typography variant="body1">{newOptionData.fullName}</Typography> : undefined,
      };
    if (newOptionData.type === `INDIVIDUAL`)
      return {
        className: classes.listItemRootPerson,
        icon: <AccountCircleOutlinedIcon className={iconClassName} fontSize={fontSize} />,
        text: (
          <>
            {isTooltip && <Typography variant="body1">{newOptionData.fullName}</Typography>}
            {newOptionData.documentInfo && newOptionData.documentInfo.document && (
              <Typography style={{ color: isTooltip ? `#8D8D8D` : `inherit` }} variant="body2">
                <Typography color={isTooltip ? `inherit` : `textSecondary`} component="span" variant="body2">
                  {`${newOptionData.documentInfo.documentType}: `}
                </Typography>
                <Typography color={isTooltip ? `inherit` : `textPrimary`} component="span" variant="body2">
                  {formatDocumentByType(newOptionData.documentInfo, true)}
                </Typography>
              </Typography>
            )}
            {newOptionData.birthDate && (
              <Typography style={{ color: isTooltip ? `#8D8D8D` : `inherit` }} variant="body2">
                <Typography color={isTooltip ? `inherit` : `textSecondary`} component="span" variant="body2">
                  Nascimento:
                </Typography>
                <Typography color={isTooltip ? `inherit` : `textSecondary`} component="span" variant="body2">
                  {` ${new Date(newOptionData.birthDate).toLocaleDateString(`pt-BR`)}`}
                </Typography>
              </Typography>
            )}
          </>
        ),
      };
    return {
      className: classes.listItemRootOther,
      icon: <BusinessCenterOutlinedIcon className={iconClassName} color="primary" fontSize={fontSize} />,
      text:
        newOptionData.documentInfo && newOptionData.documentInfo.document ? (
          <Typography component="div">CNPJ: {newOptionData.documentInfo.document}</Typography>
        ) : (
          undefined
        ),
    };
  }
  return undefined;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selectorIcon: {
      cursor: `pointer`,
      height: theme.spacing(1.75),
      width: theme.spacing(1.75),
    },
    selectorIconColorDark: {
      color: theme.palette.primary.dark,
    },
  }),
);

interface CRMSelectProps {
  disabled?: boolean;
  hasInstance?: boolean;
  inputClassName?: string;
  isEditing?: boolean;
  onBlur?: FocusEventHandler;
  onChange: (id?: number | number[]) => void;
  onFocus?: FocusEventHandler;
  value?: number | number[];
}

const CRMSelect: FC<CRMSelectProps> = ({
  disabled,
  hasInstance,
  inputClassName,
  isEditing,
  onBlur,
  onChange,
  onFocus,
  value,
}) => {
  const classes = useStyles();
  const crmClasses = stylesForCrmAndUserBuilder();
  const dialog = useDialog();
  const dispatch = useDispatch();
  const crms = useSelector<ApplicationState, CRMsState>(
    state => state.CRMs,
    (previous, next) => JSON.stringify(previous) === JSON.stringify(next),
  );

  return (
    <CustomAutocomplete<CRM>
      createDialog={
        <DialogCRMForm
          addOrUpdateRequest={(data: {
            afterSavingCallback?: (data: CRM) => void;
            dialog: DialogType;
            data: SagaFormInstance;
          }): { type: string } => {
            const { afterSavingCallback, dialog: intDialog, data: instance } = data;
            return dispatch(
              crmAddOrUpdateRequest({
                afterSavingCallback,
                dialog: intDialog,
                data: instance,
              }),
            );
          }}
          afterSavingCallback={(data: CRM): void => {
            onChange(data.id);
          }}
          formErrors={crms.formErrors}
          formInstance={crms.formInstance}
          fullscreen={window.innerWidth < 960}
          handleClick={(): { type: string } => dispatch(closeCRMRequestDialog())}
          isSaving={crms.isSaving}
          open={crms.dialogOpen}
        />
      }
      customAddOption={{
        id: -2,
        fullName: `customAddOption`,
        type: `INDIVIDUAL`,
      }}
      disabled={disabled}
      defaultOptions={[
        {
          fullName: `Anônimo`,
          id: -1,
          type: `INDIVIDUAL`,
        },
      ]}
      getOptionValue={(selectedData: ValueType<CRM>): string => {
        return `${(selectedData as CRM).id}`;
      }}
      hasInstance={hasInstance}
      inputClassName={inputClassName}
      inputValue={(selectedData: ValueType<CRM>): string => {
        if (selectedData && !Array.isArray(selectedData)) {
          return (selectedData as CRM).fullName;
        }
        return ``;
      }}
      isEditing={isEditing}
      label="Cliente"
      margin={MarginType.dense}
      mountTooltipTitle={(selectedData: ValueType<CRM>): ReactElement => {
        const optionIconAndText = getCrmTextAndIcon({
          classes: crmClasses,
          fontSize: FontSize.small,
          iconClassName: classes.selectorIcon,
          isTooltip: true,
          optionData: selectedData as CRM,
        });
        if (!Array.isArray(selectedData)) {
          return (
            <Grid container direction="row" wrap="nowrap">
              <Grid container direction="column" style={{ marginRight: 6, width: `fit-content` }}>
                {optionIconAndText && optionIconAndText.icon}
              </Grid>
              <Grid container direction="column">
                {optionIconAndText && optionIconAndText.text}
              </Grid>
            </Grid>
          );
        }
        return <></>;
      }}
      multiple={false}
      name="crm"
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      openUp={false}
      Option={(props: CustomSelectOptionProps<CRM>): ReactElement => {
        const {
          data: optionData,
          innerProps,
          innerRef,
          isDisabled: autoCompleteDisabled,
          isFocused,
          isMulti,
          isSelected,
        } = props;
        const optionIconAndText = getCrmTextAndIcon({
          classes: crmClasses,
          iconClassName: `${classes.selectorIcon} ${classes.selectorIconColorDark}`,
          isTooltip: false,
          optionData,
        });
        return optionData.fullName !== `customAddOption` ? (
          <MenuItem
            buttonRef={innerRef}
            selected={isFocused}
            component="div"
            style={{
              alignItems: `flex-start`,
              backgroundColor: isMulti && isSelected ? `#e6f7ff` : undefined,
              color: autoCompleteDisabled ? `#d3d3d3` : ``,
              minHeight: 46,
            }}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...innerProps}
          >
            {optionIconAndText && (
              <ListItemIcon classes={{ root: optionIconAndText.className }}>{optionIconAndText.icon}</ListItemIcon>
            )}
            <ListItemText
              primary={optionData.fullName}
              secondary={optionIconAndText && optionIconAndText.text}
              secondaryTypographyProps={{
                component: `div`,
              }}
            />
          </MenuItem>
        ) : (
          <MenuItem
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...innerProps}
            buttonRef={innerRef}
            component="div"
            onClick={(event: MouseEvent<HTMLDivElement>): void => {
              event.stopPropagation();
              dispatch(openCRMRequestDialog({ dialog }));
            }}
            selected={isFocused}
            style={{
              alignItems: `flex-start`,
              backgroundColor: isMulti && isSelected ? `#e6f7ff` : undefined,
              color: autoCompleteDisabled ? `#d3d3d3` : ``,
              minHeight: 46,
            }}
          >
            <ListItemIcon classes={{ root: crmClasses.listItemRootAdd }}>
              <AddIcon className={classes.selectorIconColorDark} />
            </ListItemIcon>
            <ListItemText className={classes.selectorIconColorDark} primary="Cadastrar novo cliente" />
          </MenuItem>
        );
      }}
      placeholder="Anônimo"
      startAdornment={(selectedData: ValueType<CRM>): ReactNode => {
        const optionTextAndIcon = getCrmTextAndIcon({
          classes: crmClasses,
          iconClassName: crmClasses.startAdornmentIcon,
          fontSize: FontSize.small,
          optionData: selectedData as CRM,
        });
        return optionTextAndIcon ? (
          <InputAdornment classes={{ root: crmClasses.startAdornment }} position="start">
            {optionTextAndIcon.icon}
          </InputAdornment>
        ) : (
          undefined
        );
      }}
      searchUrl="crm/person/"
      value={value}
    />
  );
};

export default CRMSelect;
