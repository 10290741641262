import React from 'react';
import {
  AddShoppingCart,
  AssignmentTurnedIn,
  Build,
  ContactMail,
  DescriptionOutlined,
  DevicesOther,
  EventAvailableOutlined,
  FolderShared,
  FormatListBulleted,
  Layers,
  LocationCity,
  Notes,
  PictureAsPdfOutlined,
  RecordVoiceOverOutlined,
  ReportProblem,
  SupervisorAccount,
  Timeline,
  Update,
} from '@material-ui/icons';

export const navigationPathNames = [
  `/`,
  `/activities`,
  `/tasks`,
  `/qualitycontrol`,
  `/internaldocs`,
  `/externaldocs`,
  `/crm`,
  `/inventory`,
  `/users`,
  `/institutions`,
  `/sectors`,
  `/suppliers`,
  `/categories`,
  `/updates`,
];

export const sideBarDictionary = {
  '/activities': {
    id: 1,
    name: `Atividades`,
    icon: <Timeline />,
  },
  '/tasks': {
    id: 2,
    name: `To-dos`,
    icon: <FormatListBulleted />,
  },
  '/qualitycontrol': {
    id: 3,
    name: `Controle de qualidade`,
    icon: <AssignmentTurnedIn />,
  },
  '/internaldocs': {
    id: 4,
    name: `Documentos internos`,
    icon: <DescriptionOutlined />,
  },
  '/externaldocs': {
    id: 5,
    name: `Documentos externos`,
    icon: <PictureAsPdfOutlined />,
  },
  '/crm': {
    id: 6,
    name: `CRM`,
    icon: <ContactMail />,
  },
  '/inventory': {
    id: 7,
    name: `Ativos`,
    icon: <DevicesOther />,
  },
  '/users': {
    id: 8,
    name: `Colaboradores`,
    icon: <SupervisorAccount />,
  },
  '/institutions': {
    id: 9,
    name: `Instituições`,
    icon: <LocationCity />,
  },
  '/sectors': {
    id: 10,
    name: `Setores`,
    icon: <FolderShared />,
  },
  '/suppliers': {
    id: 11,
    name: `Fornecedores`,
    icon: <AddShoppingCart />,
  },
  '/categories': {
    id: 12,
    name: `Categorias`,
    icon: <Layers />,
  },
  '/updates': {
    id: 13,
    name: `Atualizações`,
    icon: <Update />,
  },
};
export const subTasksDictionary = {
  Tarefas: {
    id: 2.1,
    badge: `tasks`,
    icon: <Notes />,
  },
  Chamados: {
    id: 2.2,
    badge: `calls`,
    icon: <Build />,
  },
  Ouvidorias: {
    id: 2.3,
    badge: `ombudsmans`,
    icon: <RecordVoiceOverOutlined />,
  },
  "NC's": {
    id: 2.4,
    badge: `ncs`,
    icon: <ReportProblem />,
  },
  Preventivas: {
    id: 2.5,
    badge: `preventives`,
    icon: <EventAvailableOutlined />,
  },
};
