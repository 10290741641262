import { call, put, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { Store } from 'redux';
import { ActivitiesState } from './Types';
import api from '../../../Services/api';

import { loadActivitiesFailure, loadActivitiesSuccess, loadScoreFailure, loadScoreSuccess } from './Actions';

interface AppStore extends Store {
  Activities: ActivitiesState;
}

const getActivitiesState = (state: AppStore): ActivitiesState => state.Activities;

export function* loadActivities(): SagaIterator {
  try {
    const activitiesState = yield select(getActivitiesState);
    const response = yield call(
      api.get,
      `/logs/activities/?page=${activitiesState.activitiesPage + 1}&length=15&globalSearch=${
        activitiesState.searchText
      }`,
    );
    yield put(loadActivitiesSuccess(response.data));
  } catch (err) {
    yield put(loadActivitiesFailure());
  }
}

export function* loadScore(): SagaIterator {
  try {
    const response = yield call(api.get, `/logs/score/`);
    yield put(loadScoreSuccess(response.data));
  } catch (err) {
    yield put(loadScoreFailure());
  }
}
