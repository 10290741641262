import React, { FC, ReactNode } from 'react';
import { Grid, ListItemText, MenuItem, Typography } from '@material-ui/core';
import CustomInstitutionsSelector, { SelectionMode } from 'Components/NewUIComponents/CustomInstitutionsSelector';
import CustomTextField from 'Components/NewUIComponents/CustomTextField';
import UserSelect from 'Components/NewUIComponents/UserSelect';
import { MarginType } from 'Utils/globalTypes';
import { RightColumnProps } from '../tasks-forms.interfaces';

const RigthColumn: FC<RightColumnProps> = ({
  classes,
  commonFields,
  commonTasksProps,
  customContextField,
  disableAllFields,
  formCategoryText,
  handleFieldChange,
  handleInstitutionChange,
  handleReactSelectChange,
  handleToggleEditing,
  isEditingFields,
  institutions,
  loggedUser,
  sectors,
}) => {
  const disableResponsible =
    !!commonFields.responsibleSector &&
    !loggedUser.sector.some(sec => sec.id === (commonFields.responsibleSector as number));

  const getNoOptionsMessage = (sectorField: `requesterSector` | `responsibleSector`): string | undefined => {
    if (!commonFields.institution) return `Selecione uma institutição`;
    if (!commonFields[sectorField])
      return `Selecione um setor ${sectorField === `requesterSector` ? `solicitante` : `responsável`}`;
    return undefined;
  };

  const noResponsibleObject = {
    ...loggedUser,
    avatarThumb: `Não encontrada`,
    firstName: `Sem`,
    id: -1,
    lastName: `Responsável`,
    userInstitution: [],
  };

  const selectedInstitution = institutions.find(inst => inst.id === commonFields.institution);

  const userInstitutions = selectedInstitution
    ? [
        ...institutions
          .filter(
            inst => inst.id === selectedInstitution.parent || inst.parent === selectedInstitution.id || !inst.parent,
          )
          .map(inst => inst.id),
        selectedInstitution.id,
      ]
    : undefined;

  return (
    <Grid container direction="column" justify="flex-end">
      <Grid item xs={12}>
        {formCategoryText(disableAllFields, `Contexto`)}
      </Grid>
      <Grid item xs={12}>
        <CustomInstitutionsSelector
          disabled={disableAllFields}
          error={!!commonTasksProps.formErrors && !!commonTasksProps.formErrors.institution}
          helperText={commonTasksProps.formErrors ? commonTasksProps.formErrors.institution : undefined}
          hasInstance
          id="id_institution"
          isEditing={isEditingFields.institution}
          institutions={institutions}
          margin={MarginType.dense}
          name="institution"
          onBlur={handleToggleEditing(`institution`, false)}
          onChange={handleInstitutionChange}
          onFocus={handleToggleEditing(`institution`)}
          required
          selectClass={classes.customInstitutionsField}
          selectionMode={SelectionMode.single}
          value={commonFields.institution}
          variant="outlined"
        />
      </Grid>
      {!!customContextField && (
        <Grid item xs={12}>
          {customContextField}
        </Grid>
      )}
      <Grid item xs={12}>
        {formCategoryText(disableAllFields, `Solicitante`)}
      </Grid>
      <Grid item xs={12}>
        <CustomTextField
          className={classes.customAutocomplete}
          disabled={disableAllFields}
          error={!!commonTasksProps.formErrors && !!commonTasksProps.formErrors.requesterSector}
          hasInstance
          helperText={!!commonTasksProps.formErrors && commonTasksProps.formErrors.requesterSector}
          id="id_requesterSector"
          isEditing={isEditingFields.requesterSector}
          label="Setor solicitante"
          onBlur={handleToggleEditing(`requesterSector`, false)}
          onChange={handleFieldChange(`requesterSector`)}
          onClick={
            !isEditingFields.requesterSector && !disableAllFields ? handleToggleEditing(`requesterSector`) : undefined
          }
          margin={MarginType.dense}
          name="requesterSector"
          required
          SelectProps={{
            renderValue(value: unknown): ReactNode {
              const sector = sectors.find(sec => sec.id === value);
              return sector ? sector.name : ``;
            },
          }}
          select
          value={commonFields.requesterSector}
        >
          {sectors.map(
            (sector): ReactNode => {
              return (
                <MenuItem dense={false} key={sector.id} value={sector.id}>
                  <ListItemText primary={sector.name} />
                </MenuItem>
              );
            },
          )}
        </CustomTextField>
      </Grid>
      <Grid item xs={12}>
        <UserSelect
          disabled={disableAllFields}
          error={!!commonTasksProps.formErrors && !!commonTasksProps.formErrors.requester}
          hasInstance
          helperText={commonTasksProps.formErrors ? commonTasksProps.formErrors.requester : undefined}
          inputClassName={classes.customAutocomplete}
          institutionIds={userInstitutions}
          isEditing={isEditingFields.requester}
          label="Relator"
          name="requester"
          noOptionsMessage={getNoOptionsMessage(`requesterSector`)}
          onBlur={handleToggleEditing(`requester`, false)}
          onChange={handleReactSelectChange(`requester`)}
          onFocus={handleToggleEditing(`requester`)}
          preventSearch={!commonFields.institution || !commonFields.requesterSector}
          required
          sectorIds={`${commonFields.requesterSector}`}
          value={commonFields.requester}
        />
      </Grid>
      <Grid item xs={12}>
        {formCategoryText(disableAllFields, `Responsável`)}
      </Grid>
      <Grid item xs={12}>
        <CustomTextField
          className={classes.customAutocomplete}
          disabled={disableAllFields}
          error={!!commonTasksProps.formErrors && !!commonTasksProps.formErrors.responsibleSector}
          hasInstance
          helperText={!!commonTasksProps.formErrors && commonTasksProps.formErrors.responsibleSector}
          id="id_responsibleSector"
          isEditing={isEditingFields.responsibleSector}
          label="Setor responsável"
          onBlur={handleToggleEditing(`responsibleSector`, false)}
          onChange={handleFieldChange(`responsibleSector`)}
          onClick={
            !isEditingFields.responsibleSector && !disableAllFields
              ? handleToggleEditing(`responsibleSector`)
              : undefined
          }
          margin={MarginType.dense}
          name="responsibleSector"
          required
          SelectProps={{
            renderValue(value: unknown): ReactNode {
              const sector = sectors.find(sec => sec.id === value);
              return sector ? sector.name : ``;
            },
          }}
          select
          value={commonFields.responsibleSector}
        >
          {sectors.map(
            (sector): ReactNode => {
              return (
                <MenuItem dense={false} key={sector.id} value={sector.id}>
                  <ListItemText primary={sector.name} />
                </MenuItem>
              );
            },
          )}
        </CustomTextField>
      </Grid>
      <Grid item xs={12}>
        <UserSelect
          defaultOptions={[noResponsibleObject]}
          disabled={disableResponsible || disableAllFields}
          hasInstance
          inputClassName={classes.customAutocomplete}
          institutionIds={userInstitutions}
          isEditing={isEditingFields.responsible}
          label="Responsável"
          name="responsible"
          noOptionsMessage={getNoOptionsMessage(`responsibleSector`)}
          onBlur={handleToggleEditing(`responsible`, false)}
          onChange={handleReactSelectChange(`responsible`)}
          onFocus={handleToggleEditing(`responsible`)}
          preventSearch={!commonFields.institution || !commonFields.responsibleSector}
          sectorIds={`${commonFields.responsibleSector}`}
          value={commonFields.responsible}
        />
      </Grid>
      <Grid item xs={12}>
        {formCategoryText(disableAllFields, `Acompanhamento`)}
      </Grid>
      <Grid item xs={12}>
        <CustomTextField
          className={classes.customAutocomplete}
          disabled={disableAllFields}
          hasInstance
          id="id_sector"
          isEditing={isEditingFields.sector}
          label="Setores seguidores"
          onBlur={handleToggleEditing(`sector`, false)}
          onChange={handleFieldChange(`sector`)}
          onClick={!isEditingFields.sector && !disableAllFields ? handleToggleEditing(`sector`) : undefined}
          margin={MarginType.dense}
          name="sector"
          SelectProps={{
            multiple: true,
            renderValue(value: unknown): ReactNode {
              const newValue = value as number[];
              return newValue.map(
                (sec: number): ReactNode => {
                  const sector = sectors.find(findSec => findSec.id === sec);
                  return (
                    <Typography key={sec} style={{ fontSize: 14 }}>
                      {sector ? sector.name : ``}
                    </Typography>
                  );
                },
              );
            },
          }}
          select
          value={commonFields.sector}
        >
          {sectors.map(
            (sector): ReactNode => {
              return (
                <MenuItem dense={false} key={sector.id} value={sector.id}>
                  <ListItemText primary={sector.name} />
                </MenuItem>
              );
            },
          )}
        </CustomTextField>
      </Grid>
      <Grid item xs={12}>
        <UserSelect
          disabled={disableAllFields}
          hasInstance
          inputClassName={classes.customAutocomplete}
          isEditing={isEditingFields.users}
          label="Seguidores"
          multiple
          name="users"
          onBlur={handleToggleEditing(`users`, false)}
          onChange={handleReactSelectChange(`users`)}
          onClose={handleToggleEditing(`users`, false)}
          onFocus={handleToggleEditing(`users`)}
          renderAllUsers
          value={commonFields.users}
        />
      </Grid>
    </Grid>
  );
};

export default RigthColumn;
