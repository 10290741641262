import React from 'react';
import { NavLink } from 'react-router-dom';
import { AppBar, Avatar, Badge, IconButton, InputBase, Toolbar, Tooltip } from '@material-ui/core';
import {
  AccountCircle as AccountCircleIcon,
  GroupOutlined as GroupOutlinedIcon,
  MenuOutlined as MenuOutlinedIcon,
  MoreVert as MoreVertIcon,
  NotificationsOutlined as NotificationsOutlinedIcon,
  FeedbackOutlined as FeedbackOutlinedIcon,
  PlaylistAddCheck as PlaylistAddCheckIcon,
  Search as SearchIcon,
  SignalWifiOff as SignalWifiOffIcon,
} from '@material-ui/icons';

import OrganizationMenu from '../OrganizationMenu';
import { ReactComponent as TopBarLogo } from '../../Images/top-bar-logo.svg';

const CustomAppBarComponent = ({
  theme,
  handleDrawerToggle,
  classes,
  handleMobileMenuOpen,
  handleNotificationsOpen,
  handleFeedbackDialogOpen,
  handleProfileMenuOpen,
  handleSearchChange,
  handleSubMenuActive,
  hasInternet,
  loggedUserInfo,
  filterValue,
  isMenuOpen,
  unreadNotificationCount,
  genericAvatar,
}) => (
  <AppBar position="fixed" color={theme.palette.type === `dark` ? `default` : `primary`} style={{ padding: 0 }}>
    <Toolbar>
      <IconButton color="inherit" aria-label="Open drawer" onClick={handleDrawerToggle} className={classes.menuButton}>
        <MenuOutlinedIcon />
      </IconButton>
      <NavLink
        to="/"
        replace={window.location.pathname === `/`}
        className={`${classes.logoNavLink} ${classes.navlink}`}
      >
        <TopBarLogo className={classes.logoWrapper} viewBox="7 15 151 29.70" />
        {Boolean(!hasInternet) && (
          <Tooltip title="Sem internet">
            <SignalWifiOffIcon className={classes.desktopNoInternetIcon} />
          </Tooltip>
        )}
      </NavLink>
      {window.location.pathname !== `/quality` &&
        (window.innerWidth < 960 || window.location.pathname === `/quality/activities`) && (
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Procurar…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              value={filterValue.value}
              onChange={handleSearchChange}
              type="search"
            />
          </div>
        )}
      <div className={classes.grow} />
      <div className={classes.sectionDesktop}>
        <OrganizationMenu />
        <IconButton
          color="inherit"
          onClick={event =>
            handleSubMenuActive(
              event,
              2,
              `${loggedUserInfo.user.firstName} ${loggedUserInfo.user.lastName}`,
              false,
              true,
              true,
            )
          }
        >
          <NavLink to="/tasks" replace={window.location.pathname === `/tasks`} className={classes.navlink}>
            <Tooltip title="Outros to-dos">
              <Badge
                badgeContent={
                  `generalInfo` in loggedUserInfo && loggedUserInfo.generalInfo !== undefined
                    ? loggedUserInfo.generalInfo.userAsFollower
                    : 0
                }
                color="secondary"
              >
                <GroupOutlinedIcon />
              </Badge>
            </Tooltip>
          </NavLink>
        </IconButton>
        <IconButton
          color="inherit"
          onClick={event =>
            handleSubMenuActive(
              event,
              2,
              `${loggedUserInfo.user.firstName} ${loggedUserInfo.user.lastName}`,
              true,
              false,
              true,
            )
          }
        >
          <NavLink to="/tasks" replace={window.location.pathname === `/tasks`} className={classes.navlink}>
            <Tooltip title="Meus to-dos">
              <Badge
                badgeContent={
                  `generalInfo` in loggedUserInfo && loggedUserInfo.generalInfo !== undefined
                    ? loggedUserInfo.generalInfo.userTodos
                    : 0
                }
                color="secondary"
              >
                <PlaylistAddCheckIcon />
              </Badge>
            </Tooltip>
          </NavLink>
        </IconButton>
        <IconButton aria-describedby="notification-menu" color="inherit" onClick={handleNotificationsOpen}>
          <Tooltip title="Notificações">
            <Badge badgeContent={unreadNotificationCount} color="secondary">
              <NotificationsOutlinedIcon />
            </Badge>
          </Tooltip>
        </IconButton>

        <IconButton aria-describedby="feedback-menu" color="inherit" onClick={handleFeedbackDialogOpen}>
          <Tooltip title="Dar feedback">
            <FeedbackOutlinedIcon />
          </Tooltip>
        </IconButton>

        <IconButton
          aria-owns={isMenuOpen ? `material-appbar` : undefined}
          aria-haspopup="true"
          onClick={handleProfileMenuOpen}
          color="inherit"
        >
          <Tooltip title="Menu do usuário">
            {loggedUserInfo.user ? (
              <Avatar src={loggedUserInfo.user.avatarThumb || genericAvatar} />
            ) : (
              <AccountCircleIcon />
            )}
          </Tooltip>
        </IconButton>
      </div>
      <div className={classes.sectionMobile}>
        <IconButton aria-describedby="notification-menu" color="inherit" onClick={handleNotificationsOpen}>
          <Tooltip title="Notificações">
            <Badge badgeContent={unreadNotificationCount} color="secondary">
              <NotificationsOutlinedIcon />
            </Badge>
          </Tooltip>
        </IconButton>
        <IconButton aria-haspopup="true" onClick={handleMobileMenuOpen} color="inherit">
          <MoreVertIcon />
        </IconButton>
      </div>
    </Toolbar>
  </AppBar>
);

export default CustomAppBarComponent;
