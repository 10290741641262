import React from 'react';
import { withDialog } from 'muibox';
/**
 * Redux dependencies
 */
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
/**
 * Material UI - Core
 */
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Fade,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  withStyles,
  TextField,
  Tooltip,
  Typography,
  FormHelperText,
} from '@material-ui/core';
/**
 * Material UI - Icons
 */
import { Autorenew, Close, CloudUpload, Delete, PictureAsPdfOutlined } from '@material-ui/icons';

/**
 * My components
 */
import { MyDatePickerStyles } from 'Components/MyDatePicker';
import NewAutoComplete from 'Components/NewAutoComplete';
import InstitutionsSelector, { filterUserInstitutions } from 'Components/InstitutionsSelector';
import HelperBox, { HelperBoxVariantTypes } from 'Components/NewUIComponents/HelperBox';
import api from 'Services/api';
import UserSelector from 'Components/UserSelector';
import NumberInput from 'Components/NumberInput';
import ExtDocAttachsContainer from 'Components/NewUIComponents/ExtDocAttachsContainer';

const MILISECONDS_IN_DAY = 86400000;

const styles = theme => ({
  avatar: {
    width: `30px`,
    height: `30px`,
  },
  button: {
    margin: theme.spacing(1),
  },
  chipClass: {
    '&:hover': {
      '& svg': {
        visibility: `visible`,
      },
    },
  },
  closeButton: {
    position: `absolute`,
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  container: {
    display: `flex`,
    flexWrap: `wrap`,
  },
  costTextField: {
    marginLeft: 0,
    marginRight: theme.spacing(1),
    width: `100%`,
  },
  deleteIcon: {
    visibility: `hidden`,
  },
  dense: {
    marginTop: 19,
  },
  dialogTitle: {
    display: `flex`,
    flexFlow: `row wrap`,
    alignConent: `center`,
    justifyContent: `flex-end`,
    width: `100%`,
    padding: 0,
  },
  fab: {
    position: `relative`,
    bottom: -20,
    left: theme.spacing(15),
  },
  fileWrapper: {
    width: `100%`,
    height: `98%`,
    padding: `auto`,
    '& > img': {
      margin: `auto`,
    },
  },
  input: {
    display: `none`,
  },
  menu: {
    width: 200,
  },
  observationsTextField: {
    marginLeft: theme.spacing(1),
    marginRight: 0,
    width: `98%`,
  },
  observationWrapper: {
    whiteSpace: `pre-line`,
  },
  rightIcon: {
    marginLeft: 5,
  },
  selectTextField: {
    marginLeft: 0,
    marginRight: theme.spacing(1),
    width: `100%`,
  },
  sharedTextField: {
    marginLeft: 0,
    marginRight: 0,
    width: `100%`,
  },
});

class ExtDocsForm extends React.PureComponent {
  state = {
    attachs: [],
    category: ``,
    categories: [],
    cost: ``,
    createdAt: ``, //eslint-disable-line
    emailSend: { send: false, doc: null },
    fileModal: false,
    file: ``,
    fileError: false,
    fileUrl: ``,
    formErrors: {},
    id: null,
    institution: ``,
    isActive: true,
    isExcluded: false,
    isSaving: false,
    location: ``,
    notes: ``,
    owner: this.props.loggedUserInfo.user.id, //eslint-disable-line
    ownerFullName: ``,
    sector: ``,
    sharedUsers: ``,
    sharedSectors: ``,
    supplier: ``,
    suppliers: [],
    title: ``,
    validity: null,
    validityReminderDays: 0,
  };

  componentDidMount() {
    this.categoriesFetch();
    this.suppliersFetch();
    const { formInstance, Institutions } = this.props;
    if (Institutions.length === 1) this.setState({ institution: Institutions[0].id });
    if (formInstance.length) {
      this.mountStateFromFormInstance(formInstance);
    }
  }

  componentDidUpdate(nextProps) {
    const { formInstance } = nextProps;
    const { id } = this.state;
    if (!!formInstance.length && id !== formInstance[0].id) {
      this.mountStateFromFormInstance(formInstance);
    }
  }

  componentWillUnmount() {
    const { emailSend } = this.state;
    if (emailSend.send) {
      api.get(`/externaldocs/sendmail/?type=UpdateDoc&id=${emailSend.doc}`);
    }
  }

  mountStateFromFormInstance = formInstance => {
    this.attachsFetch();
    const {
      id,
      title,
      createdAt,
      validity,
      isActive,
      location,
      cost,
      notes,
      category,
      institution,
      owner,
      ownerFullName,
      sector,
      supplier,
      sharedUsers,
      sharedSectors,
      validityReminder,
    } = formInstance[0];
    this.setState({
      createdAt, //eslint-disable-line
      category,
      cost: cost || ``,
      id,
      institution,
      isActive,
      location: location || ``,
      notes,
      owner,
      ownerFullName,
      sector,
      sharedUsers,
      sharedSectors,
      supplier: supplier || ``,
      title,
      validity: validity || null,
      validityReminderDays:
        validity && validityReminder ? (new Date(validity) - new Date(validityReminder)) / MILISECONDS_IN_DAY : 0,
    });
  };

  attachsFetch = () => {
    const { formInstance, enqueueSnackbar } = this.props;
    api
      .get(`/externaldocs/externaldocattach/?doc=${formInstance[0].id}`)
      .then(attachs => {
        this.setState({ attachs: attachs.data });
      })
      .catch(error => {
        console.error(`Erro no fetch de documentos!`, error.response); // eslint-disable-line
        enqueueSnackbar(`Não foi possível conectar com o servidor e buscar o(s) documento(s).`, {
          variant: `error`,
        });
      });
  };

  categoriesFetch = () => {
    const { enqueueSnackbar } = this.props;
    api
      .get(`/externaldocs/externaldocscategory/`)
      .then(categories => {
        this.setState({ categories: categories.data });
      })
      .catch(error => {
        console.error(`Erro no fetch de categorias!`, error.response); // eslint-disable-line
        enqueueSnackbar(`Não foi possível conectar com o servidor e buscar a(s) categoria(s).`, {
          variant: `error`,
        });
      });
  };

  suppliersFetch = () => {
    const { enqueueSnackbar } = this.props;
    api
      .get(`/supplier/supplier/`)
      .then(suppliers => {
        this.setState({ suppliers: suppliers.data });
      })
      .catch(error => {
        console.error(`Erro no fetch de fornecedor!`, error.response); // eslint-disable-line
        enqueueSnackbar(`Não foi possível conectar com o servidor e buscar o(s) fornecedor(es).`, {
          variant: `error`,
        });
      });
  };

  handleSubmit = (id = null) => event => {
    event.persist();
    event.preventDefault();
    const { formInstance, getData, handleClick, enqueueSnackbar } = this.props;
    const { formErrors } = this.state;

    if (!event.target.checkValidity()) {
      // this is not an array, but an array-like PoS
      for (let i = 0; i < event.target.elements.length; i += 1) {
        const input = event.target.elements[i];
        if (input.validity && !input.validity.valid) {
          formErrors[input.name] = true;
        }
      }

      this.setState({ formErrors });
      return;
    }

    clearTimeout(this.submitTimeout);
    if (event.target.type === `search`) {
      return;
    }
    if (!formInstance.length && !document.getElementById(`id_attach`).files.length) {
      this.setState({
        fileError: true,
      });
      return;
    }
    const timeout = id ? 4000 : 0;
    this.setState({
      isSaving: true,
    });
    this.submitTimeout = setTimeout(() => {
      const {
        attachs,
        category,
        institution,
        isActive,
        location: docLocation,
        notes,
        owner,
        sector,
        sharedSectors,
        sharedUsers,
        supplier,
        title,
        validity,
        validityReminderDays,
      } = this.state;
      const data = new FormData(event.target);
      data.set(`sharedSectors`, sharedSectors);
      data.set(`sharedUsers`, sharedUsers);
      data.set(`owner`, owner);
      data.set(`title`, title);
      data.set(`location`, docLocation);
      data.set(`notes`, notes);
      data.set(`category`, category);
      data.set(`institution`, institution);
      data.set(`sector`, sector);
      data.set(`supplier`, supplier);
      data.delete(`validityReminderDays`);

      if (isActive !== ``) {
        data.set(`isActive`, isActive);
      }

      if (validity && validityReminderDays) {
        const validityDate = new Date(validity);
        const validityReminder = new Date(validityDate - validityReminderDays * MILISECONDS_IN_DAY);
        data.set(`validityReminder`, validityReminder.toLocaleDateString(`pt-BR`));
      }

      let apiPromise;
      if (id) {
        apiPromise = api.patch(`/externaldocs/externaldocs/${id}/`, data);
      } else {
        apiPromise = api.post(`/externaldocs/externaldocs/`, data);
      }
      apiPromise
        .then(json => {
          if (id) {
            getData();
            this.setState({
              isSaving: false,
              saves: true,
              emailSend: {
                send: !!formInstance[0],
                doc: !!formInstance[0] && formInstance[0].id,
              },
            });
          } else {
            for (let i = 0; i < attachs.length; i += 1) {
              const dataFile = new FormData();
              dataFile.set(`attachment`, attachs[i]);
              dataFile.set(`externaldocs`, json.data.id);
              api.post(`/externaldocs/externaldocattach/`, dataFile).catch(() =>
                enqueueSnackbar(`Erro ao tentar carregar o arquivo!`, {
                  variant: `error`,
                }),
              );
            }
            handleClick();
            this.setState({
              isSaving: false,
            });
          }
        })
        .catch(error => {
          this.setState({
            isSaving: false,
            formErrors: error.response.data,
          });
          enqueueSnackbar(`Não foi possível salvar este documento!`, {
            variant: `error`,
          });
        });
    }, timeout);
  };

  handleLogReadAction = (id = null, url = ``) => {
    const { enqueueSnackbar } = this.props;
    const isIOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    if (id) {
      api
        .get(`/externaldocs/getattach/${id}/`)
        .then(() => {
          if (isIOS) {
            this.setState({
              fileModal: true,
              fileUrl: url,
            });
          } else {
            window.open(url);
          }
        })
        .catch(error => {
          console.error(error.response, `Não foi possível encontrar o documento!`); // eslint-disable-line
          enqueueSnackbar(`Não foi possível abrir o documento, tente novamente mais tarde!`, {
            variant: `error`,
          });
        });
    }
  };

  handleFileSubmit = (id = null) => event => {
    event.preventDefault();
    const { formInstance, enqueueSnackbar } = this.props;
    const { attachs } = this.state;
    for (let i = 0; i < event.target.files.length; i += 1) {
      const data = event.target.tagName === `form` ? new FormData(event.target) : new FormData();
      data.set(`attachment`, event.target.files[i]);
      data.set(`externaldocs`, formInstance[0].id);

      let apiPromise;
      if (id) {
        apiPromise = api.patch(`/externaldocs/externaldocattach/${id}/`, data);
      } else {
        apiPromise = api.post(`/externaldocs/externaldocattach/`, data);
      }
      apiPromise
        .then(json => {
          if (id) {
            console.error(`Implement editing attachs!`); // eslint-disable-line
          } else {
            this.setState({
              attachs: [...attachs, json.data],
            });
          }
        })
        .catch(() =>
          enqueueSnackbar(`Não foi possível carregar o anexo!`, {
            variant: `error`,
          }),
        );
    }
  };

  handleRenew = () => {
    const { formInstance, loggedUserInfo, enqueueSnackbar, resetFormInstance } = this.props;
    const data = new FormData();
    data.set(`isActive`, false);
    data.set(`closedAt`, new Date().toLocaleString(`pt-br`));
    api
      .patch(`/externaldocs/externaldocs/${formInstance[0].id}/`, data)
      .then(json => {
        enqueueSnackbar(`Documento anterior alterado para obsoleto!`, {
          variant: `info`,
        });
        resetFormInstance();
        this.setState({
          owner: loggedUserInfo.user.id,
          validity: null,
        });
        api.get(`/externaldocs/sendmail/?type=RenDoc&id=${json.data.id}`).catch(() =>
          enqueueSnackbar(`Não foi possível notificar os usuários!`, {
            variant: `error`,
          }),
        );
      })
      .catch(() => {
        enqueueSnackbar(`Não foi possível renovar o documento!`, {
          variant: `error`,
        });
      });
  };

  handleDelete = () => {
    const { dialog, formInstance, getData, handleClick, enqueueSnackbar, resetFormInstance } = this.props;
    dialog
      .confirm({
        title: `Confirmação de exclusão`,
        message: `Deseja realmente excluir esse registro?`,
        ok: { text: `Confirmar`, variant: `contained`, color: `primary` },
        cancel: { text: `Cancelar`, color: `secondary` },
      })
      .then(() => {
        const data = new FormData();
        data.set(`isActive`, false);
        data.set(`closedAt`, new Date().toLocaleString(`pt-br`));
        data.set(`isExcluded`, true);
        api
          .patch(`/externaldocs/externaldocs/${formInstance[0].id}/`, data)
          .then(() => {
            getData();
            resetFormInstance();
            handleClick(null);
          })
          .catch(() => {
            enqueueSnackbar(`Não foi possível excluir o documento!`, {
              variant: `error`,
            });
          });
      })
      .catch(() => enqueueSnackbar(`Ação cancelada!`));
  };

  handleDateChange = name => date => {
    const { formInstance } = this.props;
    this.setState({
      [name]: date,
    });
    if (formInstance.length) {
      document.getElementById(`ExtDocsForm`).dispatchEvent(new Event(`submit`, { cancelable: true, bubbles: true }));
    }
  };

  handleChange = name => event => {
    const { formInstance } = this.props;
    const { formErrors } = this.state;

    if (event && !(typeof event === `number`)) {
      if (`target` in event) {
        if (event.target.validity) {
          formErrors[name] = !event.target.validity.valid;
          this.setState({ formErrors });
        }
        if (event.target.type === `checkbox`) {
          this.setState({
            [name]: event.target.checked,
          });
        } else {
          this.setState({
            [name]: event.target.value,
          });
        }
      } else if (`value` in event) {
        this.setState({ [name]: event.value });
      } else if (!event.length) {
        this.setState({ [name]: [] });
      } else if (event[0].value) {
        this.setState({ [name]: event.map(val => val.value) });
      } else {
        this.setState({ [name]: event });
      }
    } else if (typeof event === `number`) {
      this.setState({ [name]: event });
    } else {
      this.setState({ [name]: [] });
    }
    if (formInstance.length) {
      document.getElementById(`ExtDocsForm`).dispatchEvent(new Event(`submit`, { cancelable: true, bubbles: true }));
    }
  };

  handleFileDelete = id => async () => {
    const { dialog } = this.props;
    const { attachs } = this.state;

    this.setState({
      isSaving: true,
    });
    dialog
      .confirm({
        title: `Excluir anexo?`,
        message: `Tem certeza que deseja excluir este anexo? Esta ação é irreversível.`,
        ok: { text: `Excluir`, color: `secondary` },
        cancel: { text: `Cancelar`, color: `default` },
      })
      .then(async () => {
        try {
          await api.delete(`/externaldocs/externaldocattach/${id}/`);
          this.setState({
            attachs: [...attachs.filter(attach => attach.id !== id)],
          });
        } catch (e) {
          dialog.alert({
            title: e.response && e.response.status === 409 ? `Proibido` : `Erro`,
            message:
              e.response && e.response.status === 409
                ? `Você não tem permissão para exclusão desse anexo.`
                : `Não foi possível excluir o anexo, tente novamente mais tarde.`,
            ok: { color: `primary`, text: `Ok`, variant: `outlined` },
          });
        } finally {
          this.setState({
            isSaving: false,
          });
        }
      })
      .catch(() => {
        this.setState({
          isSaving: false,
        });
      });
  };

  addFile = event => {
    const { attachs } = this.state;
    const copy = attachs.slice();
    copy.push(event.target.files[0]);
    this.setState({ attachs: copy });
  };

  removeFile = name => async () => {
    const { attachs } = this.state;
    const copy = attachs.filter(file => {
      return file.name !== name;
    });
    this.setState({ attachs: copy });
  };

  render() {
    const { classes, formInstance, handleClick, Institutions, loggedUserInfo, Sectors } = this.props;
    const {
      attachs,
      cost,
      categories,
      category,
      file,
      fileError,
      fileModal,
      fileUrl,
      formErrors,
      isActive,
      isExcluded,
      institution,
      isSaving,
      location: docLocation,
      notes,
      ownerFullName,
      saves,
      sector,
      sharedSectors,
      sharedUsers,
      supplier,
      suppliers,
      title,
      usersSelector,
      validity,
      validityReminderDays,
    } = this.state;

    const hasFormInstance = !!formInstance.length;
    const currentCategory = categories.filter(v => v.id === category);
    const isInactiveCategory = currentCategory.length ? !currentCategory[0].isActive : false;
    const isDisabled = isExcluded || !isActive;
    const fileDeleteCallback = hasFormInstance ? this.handleFileDelete : this.removeFile;

    return (
      <>
        <DialogTitle className={classes.dialogTitle} disableTypography>
          {hasFormInstance && isActive && new Date(`${validity}T23:59`) < new Date() && (
            <Button
              variant="outlined"
              style={{ margin: `auto auto auto 10px`, height: 32, padding: `0 10px 0 5px` }}
              onClick={this.handleRenew}
            >
              <Autorenew /> Renovar
            </Button>
          )}
          {hasFormInstance && (
            <Tooltip title="Excluir">
              <IconButton aria-label="Delete" onClick={this.handleDelete}>
                <Delete />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Fechar">
            <IconButton aria-label="Close" onClick={handleClick}>
              <Close />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent>
          <form
            id="ExtDocsForm"
            className={classes.container}
            autoComplete="off"
            onSubmit={hasFormInstance ? this.handleSubmit(formInstance[0].id) : this.handleSubmit()}
          >
            <Grid container>
              <Grid container item xs={12} justify="space-between" alignContent="flex-end">
                <Typography variant="h6" display="inline">
                  Informações do documento
                </Typography>
                <Typography variant="overline" style={{ fontSize: `smaller`, alignContent: `flex-end` }}>
                  Criado por {ownerFullName}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Grid>
            {isInactiveCategory && (
              <HelperBox
                text="A categoria à qual este documento está vinculado foi desabilitada."
                variant={HelperBoxVariantTypes.warning}
              />
            )}
            <Grid container spacing={0} style={{ width: `100%` }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  className={classes.selectTextField}
                  helperText={hasFormInstance ? `#${formInstance[0].id}` : ``}
                  id="id_title"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment>
                        <PictureAsPdfOutlined />
                      </InputAdornment>
                    ),
                    style: {
                      fontSize: 26,
                      fontWeight: `bold`,
                    },
                  }}
                  margin="normal"
                  name="title"
                  onChange={this.handleChange(`title`)}
                  placeholder="Título ..."
                  required
                  value={title}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid container spacing={0} style={{ width: `100%` }}>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InstitutionsSelector
                  error={!!formErrors.institution}
                  helperText={formErrors.institutions ? `Esse campo é obrigatório!` : null}
                  id="id_institution"
                  institutions={filterUserInstitutions(loggedUserInfo.user, Institutions)}
                  margin="normal"
                  name="institution"
                  onChange={this.handleChange(`institution`)}
                  required
                  selectClass={classes.selectTextField}
                  value={institution}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <NewAutoComplete
                  error={`sector` in formErrors}
                  formInstance={hasFormInstance ? formInstance[0].sector : false}
                  handleChange={this.handleChange(`sector`)}
                  label="Setor do documento"
                  margin="normal"
                  name="sector"
                  placeholder="Selecione o setor"
                  required
                  single={sector}
                  suggestions={Sectors.map(val => ({ label: val.name, value: val.id }))}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid container spacing={0} style={{ width: `100%` }}>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <NewAutoComplete
                  classes={{
                    root: classes.selectTextField,
                  }}
                  error={`category` in formErrors}
                  formInstance={hasFormInstance ? formInstance[0].institution : false}
                  handleChange={this.handleChange(`category`)}
                  label="Categoria"
                  margin="normal"
                  name="category"
                  placeholder="Selecione a categoria"
                  required
                  single={category}
                  suggestions={categories.map(val => ({ label: val.name, value: val.id, active: val.isActive }))}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <NewAutoComplete
                  error={`supplier` in formErrors}
                  formInstance={hasFormInstance ? formInstance[0].supplier : false}
                  handleChange={this.handleChange(`supplier`)}
                  label="Fornecedor"
                  margin="normal"
                  name="supplier"
                  placeholder="Selecione o fornecedor"
                  single={supplier}
                  suggestions={suppliers.map(val => ({ label: val.name, value: val.id }))}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid container spacing={0} style={{ width: `100%` }}>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <TextField
                  className={classes.selectTextField}
                  helperText="Somente se armazenado fisicamente"
                  id="id_location"
                  label="Local de armazenamento"
                  margin="normal"
                  name="location"
                  onChange={this.handleChange(`location`)}
                  value={docLocation}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <MyDatePickerStyles
                  autoOk
                  error={new Date(`${validity}T23:59`) < new Date() && isActive}
                  handleDateChange={this.handleDateChange(`validity`)}
                  helperText={new Date(`${validity}T23:59`) < new Date() && isActive && `Este documento está vencido!`}
                  keyboard
                  label="Validade"
                  margin="normal"
                  minDate={new Date().toDateString()}
                  name="validity"
                  placeholder="Indeterminado"
                  value={validity}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <TextField
                  className={classes.costTextField}
                  error={formErrors.cost}
                  id="id_cost"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                    inputProps: {
                      min: 0,
                      max: 999999.99,
                      step: `any`,
                    },
                  }}
                  label="Custo mensal"
                  margin="normal"
                  name="cost"
                  onChange={this.handleChange(`cost`)}
                  type="number"
                  value={cost}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <TextField
                  className={classes.observationsTextField}
                  id="id_notes"
                  label="Observações"
                  margin="normal"
                  multiline
                  name="notes"
                  onChange={this.handleChange(`notes`)}
                  value={notes}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid container spacing={0} style={{ width: `100%` }}>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <TextField
                  className={classes.textField}
                  disabled={!validity}
                  id="id_validity_reminder_days"
                  label="Lembrete de expiração"
                  margin="normal"
                  name="validityReminderDays"
                  onChange={this.handleChange(`validityReminderDays`, `docInfoForm`)}
                  style={{ width: `98%` }}
                  value={validityReminderDays}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <Typography style={{ color: `#00000099`, width: `120px`, textAlign: `end` }}>
                        dias antes
                      </Typography>
                    ),
                    inputComponent: NumberInput,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ display: `flex`, alignItems: `center` }}>
                <Typography
                  style={{ color: `#00000099`, width: `98%`, fontSize: `0.75rem` }}
                  className={classes.textField}
                >
                  O responsável, aprovadores e revisores receberão um lembrete da expiração do documento
                </Typography>
              </Grid>
            </Grid>
            <Dialog
              onClose={() => this.setState({ fileModal: false, fileUrl: `` })}
              aria-labelledby="file-dialog-title"
              open={fileModal}
              fullScreen
            >
              <DialogTitle id="file-dialog-title">
                {category &&
                  category.length &&
                  categories.length &&
                  `${categories.filter(val => val.id === category)[0].name} - ${title}`}
                <Tooltip title="Fechar">
                  <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={() => this.setState({ fileModal: false, fileUrl: `` })}
                  >
                    <Close />
                  </IconButton>
                </Tooltip>
              </DialogTitle>
              <DialogContent style={{ overflowY: `hidden`, padding: 0 }}>
                <iframe title="Document frame" src={fileUrl} style={{ width: `100%`, height: `100%` }} />
              </DialogContent>
            </Dialog>
            <Grid container spacing={0} style={{ width: `100%` }}>
              <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 8 }}>
                <label htmlFor="id_attach">
                  <Button
                    variant="contained"
                    color="default"
                    component="span"
                    className={classes.selectTextField}
                    style={{ marginTop: 10 }}
                  >
                    Upload
                    <CloudUpload className={classes.rightIcon} />
                  </Button>
                  <input
                    className={classes.input}
                    id="id_attach"
                    name="attach"
                    type="file"
                    onChange={hasFormInstance ? this.handleFileSubmit() : this.addFile}
                  />
                </label>
                <Typography style={{ marginTop: 18, marginLeft: 15 }}>{file.name}</Typography>
                {fileError && <FormHelperText error={fileError}>Obrigatório o envio de um documento!</FormHelperText>}
              </Grid>
            </Grid>
            <Grid container spacing={0} style={{ width: `100%` }}>
              {!!attachs.length && (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 15, textAlign: `center` }}>
                  <Divider />
                  <ExtDocAttachsContainer
                    attachs={attachs}
                    deleteCallback={!isDisabled ? fileDeleteCallback : undefined}
                    loggedUserId={loggedUserInfo.user.id}
                  />
                </Grid>
              )}
            </Grid>
            <Grid container spacing={0} style={{ marginTop: 16 }}>
              <Grid item xs={12}>
                <Typography variant="h6">
                  Compartilhamento
                  <Divider />
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              <UserSelector
                institutionIds={[]}
                label="Usuários"
                loggedUserInfo={loggedUserInfo.user}
                multiple
                name="sharedUsers"
                onChange={this.handleChange(`sharedUsers`)}
                value={sharedUsers}
              />
            </Grid>
            <Grid container spacing={0}>
              <NewAutoComplete
                classes={{
                  root: classes.sharedTextField,
                }}
                formInstance={hasFormInstance ? formInstance[0].sharedSectors : false}
                handleChange={this.handleChange(`sharedSectors`)}
                helperText="Usuários dos setores da instituição do documento"
                label="Setores"
                margin="normal"
                multiple={sharedSectors}
                name="sharedSectors"
                openUp
                placeholder="Selecionar setores para compartilhar"
                suggestions={Sectors.map(val => ({ label: val.name, value: val.id }))}
                variant="outlined"
              />
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          {hasFormInstance && isSaving && (
            <Hidden only={[`xs`, `sm`]}>
              <Fade in style={{ transitionDelay: `800ms`, position: `absolute`, bottom: 15, right: 15 }} unmountOnExit>
                <CircularProgress />
              </Fade>
            </Hidden>
          )}
          {hasFormInstance && !isSaving && saves && (
            <Hidden only={[`xs`, `sm`]}>
              <span style={{ position: `absolute`, bottom: 15, right: 15 }}>Salvo!</span>
            </Hidden>
          )}
          {hasFormInstance && isSaving && (
            <Hidden only={[`md`, `lg`, `xl`]}>
              <Fade in style={{ transitionDelay: `800ms`, position: `absolute`, bottom: 15, right: 15 }} unmountOnExit>
                <CircularProgress />
              </Fade>
            </Hidden>
          )}
          {hasFormInstance && !isSaving && saves && (
            <Hidden only={[`md`, `lg`, `xl`]}>
              <span style={{ position: `absolute`, bottom: 15, right: 15 }}>Salvo!</span>
            </Hidden>
          )}
          {!hasFormInstance && !usersSelector && (
            <>
              <Button size="small" onClick={handleClick} color="secondary" style={{ marginRight: 10 }}>
                Cancelar
              </Button>
              <Button
                style={{ minWidth: isSaving ? 100 : 64 }}
                size="small"
                form="ExtDocsForm"
                type="submit"
                color="primary"
                variant="contained"
                disabled={isSaving}
              >
                Salvar {isSaving && <CircularProgress size={20} />}
              </Button>
            </>
          )}
        </DialogActions>
      </>
    );
  }
}

const mapStateToProps = state => ({
  Sectors: state.Sectors.data,
  Institutions: state.Institutions.data,
  loggedUserInfo: state.Auth.data,
});

export default withDialog()(connect(mapStateToProps)(withSnackbar(withStyles(styles)(ExtDocsForm))));
