import React from 'react';
/**
 * Redux dependencies
 */
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
/**
 * Material UI - Core
 */
import { Hidden, Typography, withStyles } from '@material-ui/core';
/**
 * Material UI - Icons
 */
import { Clear, Done } from '@material-ui/icons';
/**
 * MUI-Datatables
 */
import MUIDataTable from 'mui-datatables';
/**
 * Supplier actions
 */
import * as SupplierActions from '../../ReduxFlow/Reducers/Suppliers/Actions';
/**
 * My components
 */
import CustomToolbar from '../../Components/CustomToolbar';
import DialogSuppForm from './DialogSuppForm';
import MobileSupplier from './MobileSupplier';
import * as muiDatatableHelper from '../../Utils/muiDatatableHelper';

const styles = theme => ({
  smRecipient: {
    height: `calc(100vh - 56px)`,
    overflowY: `auto`,
  },
  institutionAvatar: {
    width: 30,
    height: 30,
    transition: `transform .3s`,
    '&:hover': {
      transform: `scale(3)`,
    },
  },
});

class Suppliers extends React.PureComponent {
  state = {
    filterList: [[], [], [], [], []],
    searchText: ``,
  };

  handleSubmit = (id = null) => event => {
    event.preventDefault();
    const data = new FormData(event.target);
    data
      .get(`institution`)
      .split(`,`)
      .forEach((val, idx) => {
        if (idx === 0) data.set(`institution`, parseInt(val, 10));
        if (idx > 0) data.append(`institution`, parseInt(val, 10));
      });
    const { addRequest, updateRequest } = this.props;
    data.set(`isActive`, true);
    if (id) {
      updateRequest({ id, data });
    } else {
      addRequest(data);
    }
  };

  handleScroll = length => event => {
    event.stopPropagation();
    const { changeSuppliersRangeRequest, Suppliers: propsSuppliers } = this.props;
    if (
      event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight &&
      propsSuppliers.range <= propsSuppliers.data.length
    ) {
      changeSuppliersRangeRequest();
    }
  };

  render() {
    const {
      classes,
      closeSuppliersRequest,
      filterValue,
      Institutions,
      loggedUserInfo,
      openSuppliersRequest,
      Suppliers: propsSuppliers,
    } = this.props;
    const { filterList, searchText } = this.state;
    let data = propsSuppliers.data.length
      ? propsSuppliers.data.map(supplier => {
          return [
            supplier.isActive ? `Ativo` : `Inativo`,
            JSON.stringify({
              name: supplier.name,
              id: supplier.id,
            }),
            supplier.institution,
            supplier.contact,
            supplier.phone,
            supplier.id,
            supplier.cnpj,
          ];
        })
      : [];
    if (filterValue.value) {
      data = data.filter(val =>
        JSON.stringify(val)
          .toLowerCase()
          .includes(filterValue.value.toLowerCase()),
      );
    }
    const columns = [
      {
        name: `Ativo`,
        options: {
          filter: true,
          customFilterListRender: v => `Ativo: ${v}`,
          filterType: `multiselect`,
          filterList: filterList[0].length ? filterList[0] : null,
          filterOptions: {
            names: [`Ativo`, `Inativo`],
          },
          customBodyRender: value => {
            return value === `Ativo` ? <Done /> : <Clear />;
          },
        },
      },
      {
        name: `Nome`,
        options: {
          filter: true,
          customFilterListRender: v => `Nome: ${v}`,
          filterList: filterList[1].length ? filterList[1] : null,
          customBodyRender: value => {
            const suppNameId = JSON.parse(value);
            return (
              <>
                <Typography>{suppNameId.name}</Typography>
                <Typography color="textSecondary">
                  <small>{`#${suppNameId.id}`}</small>
                </Typography>
              </>
            );
          },
          filterOptions: {
            logic: (stringNameId, filter) => {
              const suppNameId = JSON.parse(stringNameId);
              if (`${suppNameId.id} - ${suppNameId.name}`.includes(filter)) {
                return false;
              }
              return true;
            },
          },
        },
      },
      muiDatatableHelper.buildInstitutionColumnForLocalTable(Institutions, filterList[2].length ? filterList[2] : [], {
        label: `Instituições`,
      }),
      {
        name: `Contato`,
        options: {
          customFilterListRender: v => `Contato: ${v}`,
          filterList: filterList[3].length ? filterList[3] : null,
        },
      },
      {
        name: `Telefone`,
        options: {
          customFilterListRender: v => `Telefone: ${v}`,
          filterList: filterList[4].length ? filterList[4] : null,
        },
      },
      {
        name: `Id`,
        options: {
          filter: false,
          display: `excluded`,
        },
      },
      {
        name: `CNPJ`,
        options: {
          filter: false,
          display: `excluded`,
        },
      },
    ];
    const options = {
      customSort: muiDatatableHelper.localInstitutionColumnSort(Institutions, 2),
      rowsPerPage: window.innerHeight > 760 ? 15 : 10,
      viewColumns: false,
      print: false,
      fixedHeader: true,
      selectableRows: `none`,
      filter: true,
      filterType: `textField`,
      responsive: `stacked`,
      onTableChange: (action, tableState) => {
        const { filterList, searchText } = tableState;
        switch (action) {
          case `search`:
          case `resetFilters`:
          case `filterChange`:
            this.setState({ searchText, filterList });
            break;
          default:
        }
      },
      searchText,
      customToolbar:
        loggedUserInfo.user.groups.indexOf(1) > -1 || loggedUserInfo.user.groups.indexOf(2) > -1
          ? () => {
              return <CustomToolbar tooltip="Adicionar fornecedor" handleModalState={openSuppliersRequest} />;
            }
          : null,
      textLabels: {
        body: {
          noMatch: `Desculpe, nenhum registro encontrado!`,
          toolTip: `Ordenar`,
        },
        pagination: {
          next: `Próxima página`,
          previous: `Págima anterior`,
          rowsPerPage: `Linhas por página:`,
          displayRows: `de`,
        },
        toolbar: {
          search: `Procurar`,
          downloadCsv: `Download CSV`,
          print: `Imprimir`,
          viewColumns: `Colunas visíveis`,
          filterTable: `Filtrar a tabela`,
        },
        filter: {
          title: `Filtros`,
          reset: `Resetar`,
        },
        viewColumns: {
          title: `Mostrar colunas`,
          titleAria: `Mostrar/Ocultar Colunas da Tabela`,
        },
        selectedRows: {
          text: `linha(s) selecionada(s)`,
          delete: `Deletar`,
          deleteAria: `Deletear linha(s) selecionada(s)`,
        },
      },
      onRowClick: rowData => {
        openSuppliersRequest({ id: rowData[5] });
      },
    };
    return (
      <>
        <Hidden only={[`md`, `lg`, `xl`]}>
          <div className={classes.smRecipient} onScroll={this.handleScroll(data.length)}>
            <MobileSupplier
              data={data.slice(0, propsSuppliers.range)}
              handleOpen={openSuppliersRequest}
              institutions={Institutions}
              isLoading={propsSuppliers.isLoading}
              loggedUserInfo={loggedUserInfo.user}
            />
          </div>
          <DialogSuppForm
            isSaving={propsSuppliers.isSaving}
            formErrors={propsSuppliers.formErrors}
            fullscreen
            open={propsSuppliers.modalOpen}
            handleClick={closeSuppliersRequest}
            formInstance={propsSuppliers.formInstance}
            handleSubmit={this.handleSubmit}
            openSuppliersRequest={openSuppliersRequest}
          />
        </Hidden>
        <Hidden only={[`xs`, `sm`]}>
          <MUIDataTable title="Fornecedores" data={data} columns={columns} options={options} />
          <DialogSuppForm
            isSaving={propsSuppliers.isSaving}
            formErrors={propsSuppliers.formErrors}
            open={propsSuppliers.modalOpen}
            handleClick={closeSuppliersRequest}
            formInstance={propsSuppliers.formInstance}
            handleSubmit={this.handleSubmit}
            openSuppliersRequest={openSuppliersRequest}
          />
        </Hidden>
      </>
    );
  }
}

const mapStateToProps = state => ({
  loggedUserInfo: state.Auth.data,
  Institutions: state.Institutions.data,
  Suppliers: state.Suppliers,
});

const mapDispatchToProps = dispatch => bindActionCreators(SupplierActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(Suppliers));
