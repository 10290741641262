import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { withDialog } from 'muibox';
/**
 * Redux dependencies
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withSnackbar } from 'notistack';
/**
 * Firebase Config
 */
import firebase, { quality } from './Utils/firebaseConfig';
/**
 * Auth actions
 */
import * as AuthActions from './ReduxFlow/Reducers/Auth/Actions';
/**
 * My components
 */
import ErrorPage from './Pages/ErrorPage';
import Main from './Components/Main';

class App extends PureComponent {
  state = {
    error: false,
    showUpdateBanner: false,
  };

  componentDidMount() {
    const { dialog, history, loginRequest, renewToken, setCurrentOrganization } = this.props;
    this.checkForServiceWorkerUpdates();
    this.firestore = firebase.firestore();
    const localStorageOrg = JSON.parse(window.localStorage.getItem(`currentOrganization`));
    this.unsubscribe = firebase.auth().onAuthStateChanged(async currentUser => {
      if (currentUser) {
        const firebaseUid = currentUser.uid;
        const token = await currentUser.getIdToken(false);
        await renewToken(token);
        const user = await this.firestore
          .collection(`users`)
          .doc(currentUser.uid)
          .get();
        const userData = user.data();
        const { currentOrganization } = userData;
        const { search } = window.location;
        const params = new URLSearchParams(search);
        const queryOrg = params.get(`_oid`);
        if (queryOrg && (localStorageOrg.id !== queryOrg || currentOrganization.id !== queryOrg)) {
          try {
            const queryOrgDoc = await this.firestore
              .collection(`organizations`)
              .doc(queryOrg)
              .get();
            setCurrentOrganization({
              currentOrganization: { ...queryOrgDoc.data(), id: queryOrgDoc.id },
              firebaseUid,
              history,
            });
          } catch (err) {
            const { code } = err;
            if (code === `permission-denied`) {
              history.replace(`/quality`);
              dialog.alert({
                title: `Sem acesso`,
                message: `Você não tem permissão para realizar essa requisição.`,
              });
            }
          }
        } else if (currentOrganization) {
          if (JSON.stringify(localStorageOrg) !== JSON.stringify(currentOrganization)) {
            setCurrentOrganization({ currentOrganization, firebaseUid, history });
          } else {
            loginRequest({ undefined, firebaseUid, history });
          }
        }
      } else {
        history.replace(`/`);
      }
    });
  }

  componentDidUpdate() {
    this.checkForServiceWorkerUpdates();
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  // componentDidCatch(error, info) {
  //   console.error(error, info);
  // }

  checkForServiceWorkerUpdates = () => {
    const { shouUpdateBanner } = this.state;
    const shouldUpdateServiceWorker = window.localStorage.getItem(`shouldUpdateServiceWorker`);
    if (JSON.parse(shouldUpdateServiceWorker) && !shouUpdateBanner) {
      this.setState({ showUpdateBanner: true });
    }
  };

  handleLogout = async () => {
    const { history, logoutRequest, enqueueSnackbar } = this.props;
    logoutRequest();
    quality
      .auth()
      .signOut()
      .then(async () => {
        await quality.auth().signOut();
        await firebase.auth().signOut();
        history.replace(`/`);
      })
      .catch(err => {
        console.error(err); // eslint-disable-line
        enqueueSnackbar(`Não foi possível deslogar!`);
      });
  };

  handleSubmit = event => {
    event.preventDefault();
    const { loginRequest } = this.props;
    const data = new FormData(event.target);
    loginRequest(data);
  };

  render() {
    const { showUpdateBanner, error } = this.state;
    const { history } = this.props;
    if (error) {
      return <ErrorPage clearError={() => this.setState({ error: false }) + history.replace(`/hub`)} />;
    }
    return <Main handleLogout={this.handleLogout} showUpdateBanner={showUpdateBanner} />;
  }
}

const mapStateToProps = state => ({
  loggedUserInfo: state.Auth,
  Institutions: state.Institutions,
  Groups: state.Groups.data,
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(AuthActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withSnackbar(withRouter(withDialog()(App))));
