import { Component, ReactNode } from 'react';
import { ScrollToTopProps } from './scroll-to-top.interfaces';

class ScrollToTop extends Component<ScrollToTopProps, {}> {
  public componentDidMount(): void {
    const { element } = this.props;
    (element() as HTMLDivElement).scrollTop = 0;
  }

  public render(): ReactNode {
    return null;
  }
}

export default ScrollToTop;
