import { SagaIterator } from 'redux-saga';
import { all, takeLatest } from 'redux-saga/effects';
/**
 * QualityControl sagas
 */
import { QualityControlsTypes } from './QualityControls/Types';
import { closeQualityControlsModal, loadQualityControls, openQualityControlsModal } from './QualityControls/Sagas';
/**
 * InternalDocs sagas
 */
import { InternalDocsTypes } from './InternalDocs/Types';
import { closeInternalDocsModal, loadInternalDocs, openInternalDocsModal } from './InternalDocs/Sagas';
/**
 * InternalDocsCategories sagas
 */
import { InternalDocsCategoriesTypes } from './InternalDocsCategories/Types';
import {
  addOrUpdateInternalDocsCategories,
  closeInternalDocsCategoriesDialog,
  loadInternalDocsCategories,
  openInternalDocsCategoriesDialog,
} from './InternalDocsCategories/Sagas';
/**
 * ExternalDocs sagas
 */
import { ExternalDocsTypes } from './ExternalDocs/Types';
import { closeExternalDocsModal, loadExternalDocs, openExternalDocsModal } from './ExternalDocs/Sagas';
/**
 * ExternalDocsCategories sagas
 */
import { ExternalDocsCategoriesTypes } from './ExternalDocsCategories/Types';
import {
  addOrUpdateExternalDocsCategories,
  closeExternalDocsCategoriesDialog,
  loadExternalDocsCategories,
  openExternalDocsCategoriesDialog,
} from './ExternalDocsCategories/Sagas';
/**
 * OmbudsmanSources sagas
 */
import { OmbudsmanSourcesTypes } from './OmbudsmanSources/Types';
import {
  addOrUpdateOmbudsmanSources,
  closeOmbudsmanSourcesDialog,
  loadOmbudsmanSources,
  openOmbudsmanSourcesDialog,
} from './OmbudsmanSources/Sagas';

/**
 * TasksCategories sagas
 */
import { TasksCategoryActionTypes } from './TasksCategories/Types';
import {
  addOrUpdateTasksCategories,
  addOrUpdateTasksSubCategories,
  closeTasksCategoriesDialog,
  loadTasksCategories,
  loadTasksCategoryTypes,
  openTasksCategoriesDialog,
} from './TasksCategories/Sagas';
/**
 * Tasks sagas
 */
import { TasksTypes } from './Tasks/Types';
import { closeTasksModal, loadTasks, loadTasksKpis, openTasksModal } from './Tasks/Sagas';
/**
 * Ombudsmans sagas
 */
import { OmbudsmansTypes } from './Ombudsmans/Types';
import {
  ombudsmansAddOrUpdateRequest,
  closeOmbudsmansDialog,
  loadOmbudsmans,
  loadOmbudsmansKpis,
  openOmbudsmansDialog,
} from './Ombudsmans/Sagas';
/**
 * Activities sagas
 */
import { ActivitiesTypes } from './Activities/Types';
import { loadActivities, loadScore } from './Activities/Sagas';
/**
 * Institutions sagas
 */
import {
  addOrUpdateInstitutions,
  changeInstitutionsRange,
  closeInstitutionsDialog,
  loadInstitutions,
  openInstitutionsDialog,
} from './Institutions/Sagas';
import { InstitutionsTypes } from './Institutions/Types';
/**
 * Inventories sagas
 */
import {
  addOrUpdateInventory,
  closeInventoriesDialog,
  loadInventories,
  openInventoriesDialog,
} from './Inventories/Sagas';
import { InventoriesTypes } from './Inventories/Types';
import {
  loadInventoryCategories,
  openInventoryCategoriesDialog,
  closeInventoryCategoriesDialog,
  addOrUpdateInventoryCategories,
  addOrUpdateInventorySubCategories,
} from './InventoryCategories/Sagas';
import { InventoryCategoryActionTypes } from './InventoryCategories/Types';
/**
 * QCMarkers sagas
 */
import { loadQCMarkers } from './QCMarkers/Sagas';
import { QCMarkersTypes } from './QCMarkers/Types';
/**
 * Sectors sagas
 */
import {
  addSector,
  changeSectorsRange,
  closeSectorsModal,
  loadSectors,
  openSectorsModal,
  updateSector,
} from './Sectors/Sagas';
import { SectorsTypes } from './Sectors/Types';
/**
 * Suppliers sagas
 */
import {
  addSupplier,
  changeSuppliersRange,
  closeSuppliersModal,
  loadSuppliers,
  updateSupplier,
  openSuppliersModal,
} from './Suppliers/Sagas';
import { SuppliersTypes } from './Suppliers/Types';
/**
 * TasksMarkers sagas
 */
import { addTaskMarker, loadTaskMarkers, updateTaskMarker } from './TasksMarkers/Sagas';
import { TaskMarkersTypes } from './TasksMarkers/Types';
/**
 * Users sagas
 */
import { loadUsers } from './Users/Sagas';
import { UsersTypes } from './Users/Types';
/**
 * Groups sagas
 */
import { loadGroups } from './Groups/Sagas';
import { GroupsTypes } from './Groups/Types';
/**
 * Auth sagas
 */
import { AuthTypes } from './Auth/Types';
import { login, logout, resetStateSagas } from './Auth/Sagas';
/**
 * CRMs sagas
 */
import { addOrUpdateCRM, closeCRMsDialog, loadCRMs, openCRMsDialog } from './CRMs/Sagas';
import { CRMsTypes } from './CRMs/Types';

export default function* rootSaga(): SagaIterator {
  yield all([
    // InternalDocs Sagas
    takeLatest(InternalDocsTypes.CHANGE_SORT, loadInternalDocs),
    takeLatest(InternalDocsTypes.CHANGE_FILTERS, loadInternalDocs),
    takeLatest(InternalDocsTypes.CHANGE_PAGES, loadInternalDocs),
    takeLatest(InternalDocsTypes.LOAD_REQUEST, loadInternalDocs),
    takeLatest(InternalDocsTypes.OPEN_REQUEST, openInternalDocsModal),
    takeLatest(InternalDocsTypes.CLOSE_REQUEST, closeInternalDocsModal),
    takeLatest(InternalDocsTypes.CLOSE_SUCCESS, loadInternalDocs),
    takeLatest(InternalDocsTypes.LOAD_REQUEST, loadInternalDocsCategories),
    // InternalDocs Categories Sagas
    takeLatest(InternalDocsCategoriesTypes.LOAD_REQUEST, loadInternalDocsCategories),
    takeLatest(InternalDocsCategoriesTypes.OPEN_REQUEST, openInternalDocsCategoriesDialog),
    takeLatest(InternalDocsCategoriesTypes.CLOSE_REQUEST, closeInternalDocsCategoriesDialog),
    takeLatest(InternalDocsCategoriesTypes.ADD_OR_UPDATE_REQUEST, addOrUpdateInternalDocsCategories),
    // QualityControls Sagas
    takeLatest(QualityControlsTypes.CHANGE_SORT, loadQualityControls),
    takeLatest(QualityControlsTypes.CHANGE_FILTERS, loadQualityControls),
    takeLatest(QualityControlsTypes.CHANGE_PAGES, loadQualityControls),
    takeLatest(QualityControlsTypes.LOAD_REQUEST, loadQualityControls),
    takeLatest(QualityControlsTypes.OPEN_REQUEST, openQualityControlsModal),
    takeLatest(QualityControlsTypes.CLOSE_REQUEST, closeQualityControlsModal),
    takeLatest(QualityControlsTypes.CLOSE_SUCCESS, loadQualityControls),
    // ExternalDocs Sagas
    takeLatest(ExternalDocsTypes.CHANGE_SORT, loadExternalDocs),
    takeLatest(ExternalDocsTypes.CHANGE_FILTERS, loadExternalDocs),
    takeLatest(ExternalDocsTypes.CHANGE_PAGES, loadExternalDocs),
    takeLatest(ExternalDocsTypes.LOAD_REQUEST, loadExternalDocs),
    takeLatest(ExternalDocsTypes.LOAD_REQUEST, loadExternalDocsCategories),
    takeLatest(ExternalDocsTypes.OPEN_REQUEST, openExternalDocsModal),
    takeLatest(ExternalDocsTypes.CLOSE_REQUEST, closeExternalDocsModal),
    takeLatest(ExternalDocsTypes.CLOSE_SUCCESS, loadExternalDocs),
    // ExternalDocs Categories Sagas
    takeLatest(ExternalDocsCategoriesTypes.LOAD_REQUEST, loadExternalDocsCategories),
    takeLatest(ExternalDocsCategoriesTypes.OPEN_REQUEST, openExternalDocsCategoriesDialog),
    takeLatest(ExternalDocsCategoriesTypes.CLOSE_REQUEST, closeExternalDocsCategoriesDialog),
    takeLatest(ExternalDocsCategoriesTypes.ADD_OR_UPDATE_REQUEST, addOrUpdateExternalDocsCategories),
    // Tasks Sagas
    takeLatest(TasksTypes.CHANGE_SORT, loadTasks),
    takeLatest(TasksTypes.CHANGE_FILTERS, loadTasks),
    takeLatest(TasksTypes.CHANGE_FILTERS, loadTasksKpis),
    takeLatest(TasksTypes.CHANGE_PAGES, loadTasks),
    takeLatest(TasksTypes.LOAD_REQUEST, loadTasks),
    takeLatest(TasksTypes.LOAD_REQUEST, loadTasksKpis),
    takeLatest(TasksTypes.OPEN_REQUEST, openTasksModal),
    takeLatest(TasksTypes.CLOSE_REQUEST, closeTasksModal),
    takeLatest(TasksTypes.CLOSE_SUCCESS, loadTasks),
    // Ombudsmans Sagas
    takeLatest(OmbudsmansTypes.CHANGE_SORT, loadOmbudsmans),
    takeLatest(OmbudsmansTypes.CHANGE_FILTERS, loadOmbudsmans),
    takeLatest(OmbudsmansTypes.CHANGE_FILTERS, loadOmbudsmansKpis),
    takeLatest(OmbudsmansTypes.CHANGE_PAGES, loadOmbudsmans),
    takeLatest(OmbudsmansTypes.LOAD_REQUEST, loadOmbudsmans),
    takeLatest(OmbudsmansTypes.LOAD_REQUEST, loadOmbudsmansKpis),
    takeLatest(OmbudsmansTypes.OPEN_REQUEST, openOmbudsmansDialog),
    takeLatest(OmbudsmansTypes.CLOSE_REQUEST, closeOmbudsmansDialog),
    takeLatest(OmbudsmansTypes.ADD_OR_UPDATE_REQUEST, ombudsmansAddOrUpdateRequest),
    // Ombudsman Sources Actions
    takeLatest(OmbudsmanSourcesTypes.ADD_OR_UPDATE_REQUEST, addOrUpdateOmbudsmanSources),
    takeLatest(OmbudsmanSourcesTypes.CLOSE_REQUEST, closeOmbudsmanSourcesDialog),
    takeLatest(OmbudsmanSourcesTypes.LOAD_REQUEST, loadOmbudsmanSources),
    takeLatest(OmbudsmanSourcesTypes.OPEN_REQUEST, openOmbudsmanSourcesDialog),
    // Activities Sagas
    takeLatest(ActivitiesTypes.LOAD_REQUEST, loadActivities),
    takeLatest(ActivitiesTypes.SCORE_REQUEST, loadScore),
    takeLatest(ActivitiesTypes.CHANGE_FILTERS, loadActivities),
    // Groups Sagas
    takeLatest(GroupsTypes.LOAD_REQUEST, loadGroups),
    // Inventories Sagas
    takeLatest(InventoriesTypes.LOAD_REQUEST, loadInventories),
    takeLatest(InventoriesTypes.CHANGE_FILTERS, loadInventories),
    takeLatest(InventoriesTypes.CHANGE_PAGES, loadInventories),
    takeLatest(InventoriesTypes.CHANGE_SORT, loadInventories),
    takeLatest(InventoriesTypes.MOBILE_INFINITE_SCROLL_REQUEST, loadInventories),
    takeLatest(InventoriesTypes.OPEN_REQUEST, openInventoriesDialog),
    takeLatest(InventoriesTypes.CLOSE_REQUEST, closeInventoriesDialog),
    takeLatest(InventoriesTypes.ADD_OR_UPDATE_REQUEST, addOrUpdateInventory),
    // Institutions Sagas
    takeLatest(InstitutionsTypes.LOAD_REQUEST, loadInstitutions),
    takeLatest(InstitutionsTypes.ADD_OR_UPDATE_REQUEST, addOrUpdateInstitutions),
    takeLatest(InstitutionsTypes.OPEN_REQUEST, openInstitutionsDialog),
    takeLatest(InstitutionsTypes.CLOSE_REQUEST, closeInstitutionsDialog),
    takeLatest(InstitutionsTypes.RANGE_REQUEST, changeInstitutionsRange),
    // InventoryCategory Sagas
    takeLatest(InventoryCategoryActionTypes.LOAD_REQUEST, loadInventoryCategories),
    takeLatest(InventoryCategoryActionTypes.OPEN_REQUEST, openInventoryCategoriesDialog),
    takeLatest(InventoryCategoryActionTypes.CLOSE_REQUEST, closeInventoryCategoriesDialog),
    takeLatest(InventoryCategoryActionTypes.ADD_OR_UPDATE_REQUEST, addOrUpdateInventoryCategories),
    takeLatest(InventoryCategoryActionTypes.ADD_OR_UPDATE_SUBCATEGORY_REQUEST, addOrUpdateInventorySubCategories),
    // QCMarkers Sagas
    takeLatest(QCMarkersTypes.LOAD_REQUEST, loadQCMarkers),
    // Sectors Sagas
    takeLatest(SectorsTypes.LOAD_REQUEST, loadSectors),
    takeLatest(SectorsTypes.ADD_REQUEST, addSector),
    takeLatest(SectorsTypes.UPDATE_REQUEST, updateSector),
    takeLatest(SectorsTypes.OPEN_REQUEST, openSectorsModal),
    takeLatest(SectorsTypes.CLOSE_REQUEST, closeSectorsModal),
    takeLatest(SectorsTypes.RANGE_REQUEST, changeSectorsRange),
    // Suppliers Sagas
    takeLatest(SuppliersTypes.LOAD_REQUEST, loadSuppliers),
    takeLatest(SuppliersTypes.ADD_REQUEST, addSupplier),
    takeLatest(SuppliersTypes.UPDATE_REQUEST, updateSupplier),
    takeLatest(SuppliersTypes.OPEN_REQUEST, openSuppliersModal),
    takeLatest(SuppliersTypes.CLOSE_REQUEST, closeSuppliersModal),
    takeLatest(SuppliersTypes.RANGE_REQUEST, changeSuppliersRange),
    // Task Markers Sagas
    takeLatest(TaskMarkersTypes.LOAD_REQUEST, loadTaskMarkers),
    takeLatest(TaskMarkersTypes.ADD_REQUEST, addTaskMarker),
    takeLatest(TaskMarkersTypes.UPDATE_REQUEST, updateTaskMarker),
    // Users Sagas
    takeLatest(UsersTypes.LOAD_REQUEST, loadUsers),
    takeLatest(UsersTypes.CHANGE_FILTERS, loadUsers),
    takeLatest(UsersTypes.CHANGE_PAGES, loadUsers),
    takeLatest(UsersTypes.CHANGE_SORT, loadUsers),
    takeLatest(UsersTypes.MOBILE_INFINITE_SCROLL_REQUEST, loadUsers),
    // Auth Sagas
    takeLatest(AuthTypes.LOGIN_REQUEST, login),
    takeLatest(AuthTypes.LOGOUT_REQUEST, logout),
    takeLatest(AuthTypes.CHANGE_CURRENT_ORGANIZATION, resetStateSagas),
    takeLatest(AuthTypes.RESET_STATE, login),
    // Tasks Category Sagas
    takeLatest(TasksCategoryActionTypes.LOAD_REQUEST, loadTasksCategories),
    takeLatest(TasksCategoryActionTypes.OPEN_REQUEST, openTasksCategoriesDialog),
    takeLatest(TasksCategoryActionTypes.CLOSE_REQUEST, closeTasksCategoriesDialog),
    takeLatest(TasksCategoryActionTypes.ADD_OR_UPDATE_REQUEST, addOrUpdateTasksCategories),
    takeLatest(TasksCategoryActionTypes.ADD_OR_UPDATE_SUBCATEGORY_REQUEST, addOrUpdateTasksSubCategories),
    takeLatest(TasksCategoryActionTypes.LOAD_TYPES_REQUEST, loadTasksCategoryTypes),
    // CRMS Sagas
    takeLatest(CRMsTypes.LOAD_REQUEST, loadCRMs),
    takeLatest(CRMsTypes.CHANGE_FILTERS, loadCRMs),
    takeLatest(CRMsTypes.CHANGE_PAGES, loadCRMs),
    takeLatest(CRMsTypes.CHANGE_SORT, loadCRMs),
    takeLatest(CRMsTypes.MOBILE_INFINITE_SCROLL_REQUEST, loadCRMs),
    takeLatest(CRMsTypes.OPEN_REQUEST, openCRMsDialog),
    takeLatest(CRMsTypes.CLOSE_REQUEST, closeCRMsDialog),
    takeLatest(CRMsTypes.ADD_OR_UPDATE_REQUEST, addOrUpdateCRM),
  ]);
}
