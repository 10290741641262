export default class CustomAxiosError extends Error {
  response: {
    data: {
      [key: string]: string;
      code: string;
    };
  };

  constructor(name: string, message: string) {
    super(name);
    this.message = message;
    this.name = name;
    this.response = {
      data: {
        [name]: this.message,
        code: name,
      },
    };
  }
}
