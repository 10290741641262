import React, { ReactNode, SFC, UIEvent, useEffect, useRef, useState } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import MuiDatatable, { MUIDataTableColumn, MUIDataTableOptions, MUIDataTableState } from 'mui-datatables';
import { Hidden, LinearProgress, Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useDialog } from 'muibox';
import CustomToolbar from '../../Components/CustomToolbar';
import * as CRMsActions from '../../ReduxFlow/Reducers/CRMs/Actions';
import { ApplicationState } from '../../ReduxFlow/Reducers';
import { AuthState } from '../../ReduxFlow/Reducers/Auth/Types';
import { CRMsState } from '../../ReduxFlow/Reducers/CRMs/Types';
import MobileCRM from './MobileCRM';
import DialogCRMForm from './DialogCRMForm';
import RangePicker from '../../Components/RangePicker';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customBodyCell: {
      '& > div': {
        width: `100%`,
      },
      '& > div > div > table > tbody > tr > td': {
        padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
      },
    },
    institutionAvatar: {
      width: 30,
      height: 30,
      transition: `transform .3s`,
      '&:hover': {
        transform: `scale(3)`,
      },
    },
    markerRoot: {
      fontSize: 12,
      height: 15,
    },
    markerChildren: {
      padding: `0 6px 0 6px`,
    },
    smRecipient: {
      height: `calc(100vh - 56px)`,
      overflowY: `auto`,
    },
  }),
);

interface ColumnType extends MUIDataTableColumn {
  filterName: string;
}

type StoreProps = {
  crms: CRMsState;
  loggedUserInfo: AuthState;
};

type DispatchProps = {
  addOrUpdateRequest: typeof CRMsActions.addOrUpdateRequest;
  changeCRMFilters: typeof CRMsActions.changeCRMFilters;
  changeCRMPages: typeof CRMsActions.changeCRMPages;
  changeCRMSort: typeof CRMsActions.changeCRMSort;
  closeCRMRequest: typeof CRMsActions.closeCRMRequest;
  CRMMobileInfiniteScrollRequest: typeof CRMsActions.CRMMobileInfiniteScrollRequest;
  loadRequest: typeof CRMsActions.loadRequest;
  openCRMRequest: typeof CRMsActions.openCRMRequest;
};

type CRMsProps = {
  filterValue: {
    followers: boolean;
    responsible: boolean;
    status: boolean;
    type: string;
    value: string;
  };
  handleFetchStatus: Function;
};

export function formatDocumentByType(
  documentInfo?: { document?: string; documentType?: string },
  noPrefix?: boolean,
): string {
  if (documentInfo && documentInfo.document) {
    switch (documentInfo.documentType) {
      case `CPF`:
        return `${noPrefix ? `` : `${documentInfo.documentType} `}${documentInfo.document.replace(
          /(\d{3})(\d{3})(\d{3})(\d{2})/g,
          `$1.$2.$3-$4`,
        )}`;
      case `Passaporte`:
        return `${noPrefix ? `` : `${documentInfo.documentType} `}${documentInfo.document.replace(
          /(\d{3})(\d{2})(\d{4})/g,
          `$1-$2-$3`,
        )}`;
      default:
        return `${noPrefix ? `` : `${documentInfo.documentType} `}${documentInfo.document}`;
    }
  }
  return ``;
}

const CRMs: SFC<StoreProps & DispatchProps & CRMsProps> = ({
  addOrUpdateRequest,
  changeCRMFilters,
  changeCRMPages,
  changeCRMSort,
  closeCRMRequest,
  crms,
  filterValue,
  handleFetchStatus,
  CRMMobileInfiniteScrollRequest,
  loadRequest,
  loggedUserInfo,
  openCRMRequest,
}) => {
  const classes = useStyles();
  const dialog = useRef(useDialog());
  const { data } = crms;
  const { isLoadingUI } = loggedUserInfo;
  const { currentOrganization } = loggedUserInfo.data;
  const { isAdminOrSuperAdmin } = loggedUserInfo.data.user;
  const [firstLoad, setFirstLoad] = useState<boolean>(true);

  useEffect(() => {
    if (!firstLoad) {
      changeCRMFilters({
        dialog: dialog.current,
        filterList: {},
        searchText: filterValue.value,
      });
    }
  }, [changeCRMFilters, filterValue.value, firstLoad]);

  useEffect(() => {
    const localStorageOrg = window.localStorage.getItem(`currentOrganization`);
    if (localStorageOrg === JSON.stringify(currentOrganization) && !isLoadingUI && !data.length && firstLoad) {
      if (filterValue.status) {
        changeCRMFilters({
          dialog: dialog.current,
          filterList: {},
          rowsPerPage: window.innerHeight > 760 ? 100 : 10,
          searchText: filterValue.value,
        });
        handleFetchStatus();
      } else {
        changeCRMFilters({
          dialog: dialog.current,
          filterList: crms.filterList,
          rowsPerPage: window.innerHeight > 760 ? 100 : 10,
        });
        loadRequest(dialog.current);
      }
      setFirstLoad(false);
    }
  }, [
    changeCRMFilters,
    crms.filterList,
    currentOrganization,
    data,
    filterValue.status,
    filterValue.value,
    firstLoad,
    handleFetchStatus,
    isLoadingUI,
    loadRequest,
  ]);

  function handleScroll(event: UIEvent<HTMLDivElement>): void {
    event.stopPropagation();
    const { clientHeight, scrollHeight, scrollTop } = event.currentTarget;
    if ((scrollHeight - scrollTop) * 0.7 <= clientHeight && crms.pagination.count >= crms.data.length) {
      CRMMobileInfiniteScrollRequest({ dialog: dialog.current });
    }
  }

  const columns: ColumnType[] = [
    {
      filterName: `fullName`,
      label: `Nome`,
      name: `fullName`,
      options: {
        filter: true,
        customFilterListRender: (v: string): string => `Nome: ${v}`,
        filterList: crms.filterList.fullName,
      },
    },
    {
      filterName: `birthDate`,
      label: `Data de nascimento`,
      name: `birthDate`,
      options: {
        customBodyRender: (v): React.ReactNode => {
          if (!v) return ``;
          const start = v[0] ? new Date(v[0]).toLocaleDateString(`pt-br`) : null;
          const estimated = v[1] ? new Date(v[1]).toLocaleDateString(`pt-br`) : null;
          if (start && estimated) {
            return <span>{`${start} - ${estimated}`}</span>;
          }
          return ``;
        },
        customFilterListRender: (v): string =>
          v.length ? `Datas: ${v[0].toLocaleDateString()} - ${v[1].toLocaleDateString(`pt-br`)}` : ``,
        filter: true,
        filterList: crms.filterList.birthDate,
        filterOptions: {
          names: [],
          // eslint-disable-next-line react/display-name
          display: (filterList, onChange, index, column): React.ReactNode => {
            const startDate =
              crms.filterList.birthDate && crms.filterList.birthDate.length
                ? ((crms.filterList.birthDate[0] as unknown) as Date)
                : undefined;
            const endDate =
              crms.filterList.birthDate && crms.filterList.birthDate.length
                ? ((crms.filterList.birthDate[1] as unknown) as Date)
                : undefined;
            return (
              <RangePicker
                startDate={startDate}
                endDate={endDate}
                label="Data de nascimento"
                handleSelect={(event): void => {
                  if (event.eventType === 3) {
                    onChange([event.start, event.end], index, column);
                  }
                }}
              />
            );
          },
        },
        filterType: `custom`,
      },
    },
    {
      filterName: `documentInfo`,
      label: `Documento`,
      name: `documentInfo`,
      options: {
        filter: true,
        customFilterListRender: (v: string): string => `Documento: ${v}`,
        filterList: crms.filterList.documentInfo,
        customBodyRender: (value: { documentType?: string; document?: string }): ReactNode =>
          formatDocumentByType(value),
      },
    },
    {
      filterName: `email`,
      label: `E-mail`,
      name: `email`,
      options: {
        filter: true,
        customFilterListRender: (v: string): string => `E-mail: ${v}`,
        filterList: crms.filterList.email,
      },
    },
    {
      filterName: `id`,
      name: `id`,
      options: {
        filter: false,
        display: `false`,
      },
    },
  ];

  const options: MUIDataTableOptions = {
    rowsPerPage: window.innerHeight > 760 ? 100 : 10,
    viewColumns: false,
    print: false,
    fixedHeader: true,
    selectableRows: `none`,
    filter: true,
    filterType: `textField`,
    responsive: `stacked`,
    serverSide: true,
    count: crms.pagination.count,
    page: crms.page,
    onTableChange: (action: string, tableState: MUIDataTableState): void => {
      const { activeColumn, announceText, filterList, page, rowsPerPage, searchText } = tableState;
      const filters = filterList.reduce(
        (acc, v, i) => (v.length ? Object.assign(acc, { [columns[i].filterName]: v }) : acc),
        {},
      );
      switch (action) {
        case `sort`:
          if (activeColumn && announceText)
            changeCRMSort({
              activeColumn: columns[parseInt(activeColumn, 10)].name,
              dialog: dialog.current,
              sort: announceText.split(` : `)[1],
            });
          break;
        case `resetFilters`:
        case `filterChange`:
          changeCRMFilters({ dialog: dialog.current, filterList: filters, searchText: searchText || undefined });
          break;
        case `search`:
          changeCRMFilters({ dialog: dialog.current, filterList: filters, searchText: searchText || undefined });
          break;
        case `changeRowsPerPage`:
        case `changePage`:
          changeCRMPages({ dialog: dialog.current, page, rowsPerPage });
          break;
        default:
      }
    },
    searchText: crms.searchText,
    customToolbar: isAdminOrSuperAdmin
      ? (): ReactNode => {
          return <CustomToolbar tooltip="Adicionar CRM" handleModalState={openCRMRequest} />;
        }
      : undefined,
    textLabels: {
      body: {
        noMatch: crms.isLoading || isLoadingUI ? `Buscando CRMs...` : `Desculpe, nenhum registro encontrado!`,
        toolTip: `Ordenar`,
      },
      pagination: {
        next: `Próxima página`,
        previous: `Págima anterior`,
        rowsPerPage: `Linhas por página:`,
        displayRows: `de`,
      },
      toolbar: {
        search: `Procurar`,
        downloadCsv: `Download`,
        print: `Imprimir`,
        viewColumns: `Colunas visíveis`,
        filterTable: `Filtrar a tabela`,
      },
      filter: {
        all: `Todos`,
        title: `Filtros`,
        reset: `Resetar`,
      },
      viewColumns: {
        title: `Mostrar colunas`,
        titleAria: `Mostrar/Ocultar Colunas da Tabela`,
      },
      selectedRows: {
        text: `linha(s) selecionada(s)`,
        delete: `Deletar`,
        deleteAria: `Deletear linha(s) selecionada(s)`,
      },
    },
    onRowClick: rowData => {
      openCRMRequest({ dialog: dialog.current, id: rowData[4] });
    },
  };

  return (
    <>
      {crms.isLoading && <LinearProgress />}
      <Hidden only={[`md`, `lg`, `xl`]}>
        <div className={classes.smRecipient} onScroll={handleScroll}>
          <MobileCRM
            data={crms.data}
            dialog={dialog.current}
            formatDocumentByType={formatDocumentByType}
            handleOpen={openCRMRequest}
            isLoading={crms.isLoading}
            loggedUserInfo={loggedUserInfo.data.user}
          />
        </div>
      </Hidden>
      <Hidden only={[`xs`, `sm`]}>
        <Grid className={classes.customBodyCell} container>
          <MuiDatatable columns={columns} data={crms.data} options={options} title="CRM" />
        </Grid>
      </Hidden>
      {crms.dialogOpen && (
        <DialogCRMForm
          addOrUpdateRequest={addOrUpdateRequest}
          formErrors={crms.formErrors}
          formInstance={crms.formInstance}
          fullscreen={window.innerWidth < 960}
          handleClick={closeCRMRequest}
          isSaving={crms.isSaving}
          open={crms.dialogOpen}
        />
      )}
    </>
  );
};

const CRMsTests = CRMs;

export { CRMsTests };

const mapStateToProps = (state: ApplicationState): StoreProps => ({
  loggedUserInfo: state.Auth,
  crms: state.CRMs,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => bindActionCreators(CRMsActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CRMs);
