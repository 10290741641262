import React, { MouseEvent, ReactElement, SFC, useMemo, useState } from 'react';
import { Avatar, Fade, List, ListItem, Paper, Popper, Typography } from '@material-ui/core';
import { Business as BusinessIcon } from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import { Institution } from '../../ReduxFlow/Reducers/Institutions/Types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      height: 20,
      width: 20,
      marginRight: theme.spacing(1),
      [theme.breakpoints.up(`sm`)]: {
        '&:last-child': {
          marginRight: 0,
        },
      },
      [theme.breakpoints.down(`md`)]: {
        marginRight: 0,
        '&:nth-child(1)': {
          marginRight: theme.spacing(0.5),
        },
        '&:nth-child(2)': {
          marginRight: theme.spacing(0.5),
        },
        '&:nth-child(odd)': {
          marginBottom: theme.spacing(0.5),
        },
        '&:only-child': {
          height: theme.spacing(6),
          width: theme.spacing(6),
        },
      },
    },
    avatarWrapper: {
      alignContent: `center`,
      alignItems: `center`,
      display: `flex`,
      justifyContent: `flex-start`,
      [theme.breakpoints.down(`md`)]: {
        alignContent: `flex-start`,
        alignItems: `flex-start`,
        flexDirection: `column`,
        flexWrap: `wrap`,
        justifyContent: `center`,
      },
    },
    counterAvatar: {
      backgroundColor: `#767676`,
      fontSize: `0.9em`,
      marginRight: 0,
      [theme.breakpoints.down(`md`)]: {
        fontSize: `0.7em`,
      },
    },
    counterWrapper: {
      alignContent: `center`,
      alignItems: `center`,
      display: `flex`,
      justifyContent: `flex-end`,
      [theme.breakpoints.down(`md`)]: {
        alignContent: `flex-start`,
        alignItems: `flex-start`,
        flexDirection: `column`,
        flexWrap: `wrap`,
        justifyContent: `center`,
      },
    },
    listAvatar: {
      height: 20,
      marginRight: theme.spacing(1),
      width: 20,
    },
    listPrimaryText: {
      marginRight: theme.spacing(0.5),
      overflowX: `hidden`,
      textOverflow: `ellipsis`,
      whiteSpace: `nowrap`,
    },
    listSecondaryText: {
      color: `#00000099`,
      whiteSpace: `nowrap`,
    },
    paper: {
      alignContent: `center`,
      alignItems: `center`,
      boxShadow: `0px 1px 3px #00000033`,
      display: `flex`,
      height: theme.spacing(4.5),
      maxWidth: theme.spacing(20),
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(2)}px`,
      width: `fit-content`,
      [theme.breakpoints.down(`md`)]: {
        alignContent: `flex-start`,
        alignItems: `flex-start`,
        boxShadow: `none`,
        flexDirection: `column`,
        flexWrap: `wrap`,
        height: theme.spacing(7.5),
        padding: theme.spacing(1),
        maxWidth: theme.spacing(7.5),
      },
    },
    popper: {
      margin: `0 ${theme.spacing(2)}px`,
      maxWidth: 350,
    },
    selected: {
      background: `rgba(75, 194, 200, 0.1) 0% 0% no-repeat padding-box !important`,
    },
  }),
);

type TableInstitutionsProps = {
  institutions: Institution[];
  selected: number[];
};

type TransitionPropsType = {
  TransitionProps:
    | {
        in: boolean;
        onEnter: () => {};
        onExited: () => {};
      }
    | undefined;
};

const TableInstitutions: SFC<TableInstitutionsProps> = ({ institutions, selected }) => {
  const org = useMemo(() => institutions.find(inst => inst.isOrganization), [institutions]);
  const filteredInstitutions = useMemo(() => institutions.filter(inst => selected.indexOf(inst.id) > -1), [
    institutions,
    selected,
  ]);
  const { innerWidth } = window;
  const renderInstitutions = useMemo(
    () => ({
      counter: filteredInstitutions.slice(innerWidth < 960 ? 3 : 4).length,
      data: filteredInstitutions.slice(0, innerWidth < 960 ? 3 : 4),
    }),
    [filteredInstitutions, innerWidth],
  );
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement>();
  return institutions.length ? (
    <Paper
      className={classes.paper}
      elevation={0}
      onClick={(event: MouseEvent<HTMLDivElement>): void =>
        setAnchorEl(prevAnchor => {
          event.stopPropagation();
          event.persist();
          return prevAnchor ? undefined : event.currentTarget;
        })
      }
      onMouseEnter={(event: MouseEvent<HTMLDivElement>): void => setAnchorEl(event.currentTarget)}
      onMouseLeave={(): void => setAnchorEl(undefined)}
    >
      {renderInstitutions.data.map(inst => {
        const mainOffice = institutions.find(parent => inst.parent === parent.id);
        return (
          <Avatar
            className={classes.avatar}
            key={inst.id}
            src={
              inst.institutionAvatar || (mainOffice && mainOffice.institutionAvatar) || (org && org.institutionAvatar)
            }
          >
            <BusinessIcon />
          </Avatar>
        );
      })}
      {!!renderInstitutions.counter && (
        <Avatar className={`${classes.avatar} ${classes.counterAvatar}`}>{`+${renderInstitutions.counter}`}</Avatar>
      )}
      <Popper anchorEl={anchorEl} className={classes.popper} open={!!anchorEl} placement="right-start" transition>
        {({ TransitionProps }: TransitionPropsType): ReactElement => (
          <Fade
            in={/* istanbul ignore next */ TransitionProps ? TransitionProps.in : undefined}
            key={JSON.stringify(selected)}
            onEnter={/* istanbul ignore next */ TransitionProps ? TransitionProps.onEnter : undefined}
            onExited={/* istanbul ignore next */ TransitionProps ? TransitionProps.onExited : undefined}
            timeout={350}
          >
            <Paper>
              {org ? (
                <List dense>
                  {filteredInstitutions.map(inst => {
                    if (inst.id === org.id) {
                      return (
                        <ListItem key={org.id}>
                          <Avatar className={classes.listAvatar} src={org.institutionAvatar}>
                            <BusinessIcon />
                          </Avatar>
                          <Typography className={classes.listPrimaryText} display="inline" variant="body2">
                            {org.institutionName}
                          </Typography>
                          <Typography className={classes.listSecondaryText} display="inline" variant="caption">
                            {org.institutionCity}
                          </Typography>
                        </ListItem>
                      );
                    }
                    if (inst.parent === org.id) {
                      return (
                        <ListItem key={inst.id}>
                          <Avatar className={classes.listAvatar} src={inst.institutionAvatar || org.institutionAvatar}>
                            <BusinessIcon />
                          </Avatar>
                          <Typography className={classes.listPrimaryText} display="inline" variant="body2">
                            {inst.institutionName}
                          </Typography>
                          <Typography className={classes.listSecondaryText} display="inline" variant="caption">
                            {inst.institutionCity}
                          </Typography>
                        </ListItem>
                      );
                    }
                    const parent = institutions.find(checkParent => checkParent.id === inst.parent);
                    return (
                      <ListItem key={inst.id}>
                        <Avatar
                          className={classes.listAvatar}
                          src={inst.institutionAvatar || (parent && parent.institutionAvatar) || org.institutionAvatar}
                        >
                          <BusinessIcon />
                        </Avatar>
                        <Typography className={classes.listPrimaryText} display="inline" variant="body2">
                          {inst.institutionName}
                        </Typography>
                        <Typography className={classes.listSecondaryText} display="inline" variant="caption">
                          {inst.institutionCity}
                        </Typography>
                      </ListItem>
                    );
                  })}
                </List>
              ) : (
                <Typography>Nenhuma instituição encontrada</Typography>
              )}
            </Paper>
          </Fade>
        )}
      </Popper>
    </Paper>
  ) : (
    <Skeleton animation="wave" className={classes.paper} variant="rect" />
  );
};

export default TableInstitutions;
