import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
/**
 * Material UI - Core
 */
import { Checkbox, ListItemText, MenuItem, TextField } from '@material-ui/core';

const CustomSelect = ({
  adornment,
  className,
  customRender,
  disabled,
  emptyMessage,
  errorMessage,
  handleChange,
  helperText,
  id,
  idKey,
  label,
  margin,
  multiple,
  name,
  nameKey,
  onClose,
  open,
  options,
  required,
  surnameKey,
  value,
  variant,
}) => {
  const hasData = options && options.length > 0;
  const [sortedOptions, setSortedOptions] = useState([]);
  useEffect(
    () => setSortedOptions(hasData ? options.slice().sort((a, b) => a[nameKey].localeCompare(b[nameKey])) : []),
    [hasData, nameKey, options],
  );

  const getValueString = val => {
    const matches = options.filter(idx => idx.id === val);
    if (matches.length) return matches[0][nameKey];
    return ``;
  };

  const getRenderValue = selected => {
    if (multiple) {
      return selected.map(val => getValueString(val)).join(`, `);
    }
    return getValueString(selected);
  };

  return (
    <TextField
      className={className}
      disabled={disabled}
      error={!!errorMessage}
      helperText={errorMessage || helperText || null}
      id={id}
      InputProps={{
        startAdornment: adornment,
      }}
      label={label}
      margin={margin}
      name={name}
      open={open}
      onChange={handleChange(name.split(`.`).length > 1 ? name.split(`.`)[1] : name)}
      required={required}
      select
      SelectProps={{
        multiple,
        onClose,
        renderValue: hasData && customRender ? customRender : getRenderValue,
      }}
      value={value}
      variant={variant}
    >
      {hasData ? (
        sortedOptions.map(val => (
          <MenuItem key={val[idKey]} value={val[idKey]} disabled={`isDisabled` in val}>
            {multiple && <Checkbox checked={value && value.indexOf(val[idKey]) > -1} />}
            <ListItemText primary={surnameKey ? `${val[nameKey]} ${val[surnameKey]}` : val[nameKey]} />
          </MenuItem>
        ))
      ) : (
        <MenuItem value={0}>
          <ListItemText primary={emptyMessage} />
        </MenuItem>
      )}
    </TextField>
  );
};

CustomSelect.defaultProps = {
  adornment: null,
  className: null,
  customRender: null,
  disabled: false,
  emptyMessage: `Nenhuma opção disponível`,
  errorMessage: null,
  helperText: null,
  margin: `none`,
  multiple: false,
  onClose: null,
  open: null,
  required: false,
  surnameKey: null,
  value: ``,
  variant: null,
};

CustomSelect.propTypes = {
  adornment: PropTypes.element,
  className: PropTypes.string,
  customRender: PropTypes.func,
  disabled: PropTypes.bool,
  emptyMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  helperText: PropTypes.string,
  id: PropTypes.string.isRequired,
  idKey: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  margin: PropTypes.string,
  multiple: PropTypes.bool,
  name: PropTypes.string.isRequired,
  nameKey: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  required: PropTypes.bool,
  surnameKey: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.number, PropTypes.string]),
  variant: PropTypes.string,
};

export default CustomSelect;
