import React from 'react';
import { withDialog } from 'muibox';
/**
 * Redux dependencies
 */
import { connect } from 'react-redux';
/**
 * Material UI - Core
 */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
/**
 * Material UI - Icons
 */
import { CloudUpload, ExpandMore, Search } from '@material-ui/icons';
/**
 * FontAwesome Icons
 */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faFileWord, faPaperclip } from '@fortawesome/free-solid-svg-icons';
/**
 * My components
 */
import { MyDatePickerStyles, MyTimePickerStyles } from '../../../Components/MyDatePicker';
import CustomSelect from '../../../Components/CustomSelect';
import NewAutoComplete from '../../../Components/NewAutoComplete';
import InstitutionsSelector, { filterUserInstitutions } from '../../../Components/InstitutionsSelector';
import CategoriesSelector from '../../../Components/Categories/CategoriesSelector';
import UserSelector from '../../../Components/UserSelector';
import api from '../../../Services/api';

const styles = theme => ({
  container: {
    display: `flex`,
    flexWrap: `wrap`,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  rightIcon: {
    marginLeft: 5,
  },
  fab: {
    position: `relative`,
    bottom: 0,
    left: theme.spacing(15),
  },
  outlinedTextField: {
    marginRight: theme.spacing(1),
    minWidth: 243,
    width: `calc(100% - ${2 * theme.spacing(1)}px)`,
  },
  selectTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: `calc(100% - ${2 * theme.spacing(1)}px)`,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: `98%`,
  },
  textFieldExtended: {
    marginBottom: 15,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: 15,
    width: `calc(100% - ${2 * theme.spacing(1)}px)`,
  },
  verticalCenter: {
    margin: `${theme.spacing(2)}px 0 ${theme.spacing(1)}px 0`,
    flexGrow: 1,
    minHeight: 50,
    minWidth: 215,
    width: `calc(100% - ${2 * theme.spacing(1)}px)`,
  },
});

const acquisitionTypes = [
  { id: 1, label: `Próprio` },
  { id: 2, label: `Alugado` },
  { id: 3, label: `Comodato` },
  { id: 4, label: `Emprestado` },
  { id: 5, label: `Outro` },
];

const trimAndNormalizeString = str =>
  str
    ? str
        .trim()
        .toLocaleLowerCase()
        .normalize(`NFD`)
        .replace(/[\u0300-\u036f]/g, ``)
    : ``;

class InventoryForm extends React.Component {
  state = {
    /**
     * Inventory fields
     */
    attachs: [],
    files: [],
    name: ``,
    model: ``,
    msregister: ``,
    serialnumber: ``,
    manufacturer: ``,
    manufactureDate: null,
    installationDate: null,
    whInitial: null,
    whFinal: null,
    workingDays: ``,
    observations: ``,
    isActive: true,
    mCost: ``,
    acquisitionPrice: ``,
    patrimony: ``,
    responsible: ``,
    institution: ``,
    /**
     * Type fields
     */
    type: ``,
    /**
     * DICOM Fields
     */
    aeTitle: ``,
    ip: ``,
    port: ``,
    /**
     * Attachs fields
     */
    attachType: `Outros`,
    location: ``,
    occupiedArea: ``,
    acquisitionType: ``,
    inactivatedAt: ``,
    inactivationReason: ``,
    inactivationUser: ``,
    inactivationUserFullName: ``,
    isAcessory: false,
    inventoryFather: ``,
  };

  componentDidMount() {
    const { formInstance, Institutions } = this.props;
    if (Institutions.length === 1) this.setState({ institution: Institutions[0].id });
    if (formInstance) {
      const {
        name,
        model,
        msregister,
        serialnumber,
        manufacturer,
        manufactureDate,
        installationDate,
        whInitial,
        whFinal,
        workingDays,
        observations,
        isActive,
        mCost,
        acquisitionPrice,
        patrimony,
        institution,
        type,
        dicomInfo,
        location,
        occupiedArea,
        acquisitionType,
        inactivatedAt,
        inactivationReason,
        inactivationUser,
        inactivationUserFullName,
        isAcessory,
        inventoryFather,
        responsible,
      } = formInstance;
      const today = new Date();
      this.setState({
        name,
        model,
        msregister,
        serialnumber,
        manufacturer,
        manufactureDate,
        installationDate,
        whInitial: new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          whInitial.slice(0, 2),
          whInitial.slice(3, 5),
          whInitial.slice(6, 9),
        ),
        whFinal: new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          whFinal.slice(0, 2),
          whFinal.slice(3, 5),
          whFinal.slice(6, 9),
        ),
        workingDays,
        observations,
        isActive, // eslint-disable-line
        mCost,
        acquisitionPrice,
        patrimony,
        institution,
        type,
        aeTitle: dicomInfo ? dicomInfo.aeTitle : ``,
        ip: dicomInfo ? dicomInfo.ip : ``,
        port: dicomInfo ? dicomInfo.port : ``,
        location,
        occupiedArea,
        acquisitionType,
        inactivatedAt,
        inactivationReason,
        inactivationUser,
        inactivationUserFullName,
        isAcessory,
        inventoryFather,
        responsible,
      });
      this.handleAttachsFetch();
    }
  }

  handleAttachsFetch = () => {
    const { dialog, formInstance } = this.props;
    const { id } = formInstance;
    api
      .get(`/inventory/attachs/`, {
        params: {
          inventory: id,
        },
      })
      .then(response => {
        this.setState({
          attachs: response.data,
        });
      })
      .catch(err => {
        console.error(err);// eslint-disable-line
        dialog.alert({
          message: `Não foi possível salvar o anexo, tente novamente mais tarde.`,
          title: `Erro ao salvar o anexo`,
        });
      });
  };

  handleOpenFile = (url = null) => {
    window.open(url);
  };

  handleDateChange = name => date => {
    this.setState({ [name]: date });
  };

  handleSubmit = event => {
    event.preventDefault();
    const { addOrUpdateRequest, dialog, formInstance, InventoryCategories } = this.props;
    const {
      aeTitle,
      files,
      ip,
      model,
      msregister,
      name,
      port,
      serialnumber,
      manufacturer,
      manufactureDate,
      installationDate,
      whInitial,
      whFinal,
      workingDays,
      observations,
      isActive,
      mCost,
      acquisitionPrice,
      patrimony,
      institution,
      type,
      location,
      occupiedArea,
      acquisitionType,
      inactivatedAt,
      inactivationReason,
      inactivationUser,
      isAcessory,
      inventoryFather,
      responsible,
    } = this.state;
    const dicomRequired = InventoryCategories.some(
      val => val.subCategories && val.subCategories.some(subCat => subCat.id === type && subCat.dicomRequired),
    );
    const dicomInfo = dicomRequired ? { aeTitle, ip, port } : undefined;
    addOrUpdateRequest({
      dialog,
      data: {
        name: name || undefined,
        model: model || undefined,
        msregister: msregister || undefined,
        serialnumber: serialnumber || undefined,
        manufacturer: manufacturer || undefined,
        manufactureDate: manufactureDate ? new Date(manufactureDate).toLocaleDateString(`pt-br`) : undefined,
        installationDate: installationDate ? new Date(installationDate).toLocaleDateString(`pt-br`) : undefined,
        whInitial: whInitial ? new Date(whInitial).toLocaleTimeString(`pt-br`) : undefined,
        whFinal: whFinal ? new Date(whFinal).toLocaleTimeString(`pt-br`) : undefined,
        workingDays: workingDays || undefined,
        observations: observations || undefined,
        isActive: isActive || undefined,
        mCost: mCost || undefined,
        acquisitionPrice: acquisitionPrice || undefined,
        patrimony: patrimony || ``,
        institution: institution || undefined,
        type: type || undefined,
        dicomInfo: dicomInfo || undefined,
        location: location || undefined,
        occupiedArea: occupiedArea || undefined,
        acquisitionType: acquisitionType || undefined,
        inactivatedAt: inactivatedAt || undefined,
        inactivationReason: inactivationReason || undefined,
        inactivationUser: inactivationUser || undefined,
        isAcessory: isAcessory || undefined,
        inventoryFather: inventoryFather || undefined,
        responsible: responsible || undefined,
        files,
        id: formInstance ? formInstance.id : undefined,
      },
    });
  };

  handleChange = name => event => {
    if (event && !(typeof event === `number`)) {
      if (name === `files`) {
        const { attachType, files } = this.state;
        this.setState({
          files: [...files, { attachment: event.target.files[0], attachType }],
        });
      } else if (`target` in event) {
        if (event.target.type === `checkbox`) {
          this.setState({
            [name]: event.target.checked,
          });
        } else {
          this.setState({
            [name]: event.target.value,
          });
        }
      } else if (`value` in event) {
        this.setState({
          [name]: event.value,
        });
      } else {
        this.setState({
          [name]: event.map(val => val.value),
        });
      }
    } else if (typeof event === `number`) {
      this.setState({
        [name]: event,
      });
    } else {
      this.setState({
        [name]: [],
      });
    }
  };

  handleFileSubmit = async event => {
    event.preventDefault();
    if (event.target.files.length > 1) return;
    const { dialog, formInstance } = this.props;
    const { attachs, attachType } = this.state;
    const data = new FormData();
    data.set(`attachment`, event.target.files[0]);
    data.set(`attachType`, attachType);
    data.set(`inventory`, formInstance.id);
    api
      .post(`/inventory/attachs/`, data)
      .then(response => {
        this.setState({
          attachs: [...attachs, response.data],
        });
      })
      .catch(err => {
        console.error(err);// eslint-disable-line
        dialog.alert({
          message: `Não foi possível salvar o anexo, tente novamente mais tarde.`,
          title: `Erro ao salvar o anexo`,
        });
      });
  };

  handleAnvisaRedirect = async () => {
    document.getElementById(`anvisaForm`).submit();
  };

  render() {
    const {
      classes,
      formErrors,
      formInstance,
      Institutions,
      Inventories,
      InventoryCategories,
      loggedUserInfo,
    } = this.props;
    const {
      aeTitle,
      attachs,
      installationDate,
      institution,
      ip,
      mCost,
      acquisitionPrice,
      manufacturer,
      manufactureDate,
      model,
      msregister,
      name,
      observations,
      patrimony,
      port,
      serialnumber,
      type,
      whInitial,
      whFinal,
      workingDays,
      attachType,
      location,
      occupiedArea,
      acquisitionType,
      inactivatedAt,
      inactivationReason,
      inactivationUserFullName,
      isActive,
      isAcessory,
      inventoryFather,
      responsible,
    } = this.state;
    const hasInvoice = (!!attachs.length && attachs.some(val => val.attachType === `Nota fiscal`)) || false;

    return (
      <>
        <form
          id="inventoryForm"
          className={classes.container}
          noValidate
          autoComplete="off"
          onSubmit={this.handleSubmit}
        >
          <Grid container spacing={0} style={{ width: `100%` }}>
            {inactivatedAt && (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} container>
                <Grid item xs={12}>
                  <Typography className={classes.textField} align="justify">
                    {`Inativado em ${new Date(Date.parse(inactivatedAt)).toLocaleString(
                      `pt-BR`,
                    )} pelo usuário ${inactivationUserFullName}.`}
                  </Typography>
                  <Grid item xs={12}>
                    <Typography className={classes.textField} align="justify">
                      Motivo: {inactivationReason}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} container>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  className={classes.textField}
                  error={`name` in formErrors}
                  helperText={`name` in formErrors && `Esse campo é obrigatório!`}
                  id="id_name"
                  label="Nome"
                  margin="normal"
                  name="name"
                  onChange={this.handleChange(`name`)}
                  required
                  value={name || ``}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  className={classes.textField}
                  id="id_manufacturer"
                  label="Fabricante"
                  margin="normal"
                  name="manufacturer"
                  onChange={this.handleChange(`manufacturer`)}
                  value={manufacturer}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <InstitutionsSelector
                  error={!!formErrors.institution}
                  helperText={formErrors.institutions ? `Esse campo é obrigatório!` : null}
                  id="id_institution"
                  institutions={filterUserInstitutions(loggedUserInfo.data.user, Institutions)}
                  margin="normal"
                  name="institution"
                  onChange={this.handleChange(`institution`)}
                  required
                  selectClass={classes.selectTextField}
                  value={institution}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <CategoriesSelector
                  categoriesAndSubCategories={InventoryCategories}
                  formControlClass={classes.selectTextField}
                  id="id_type"
                  isLoading={InventoryCategories.loading}
                  label="Categoria"
                  margin="normal"
                  name="type"
                  onChange={this.handleChange(`type`)}
                  value={type}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <UserSelector
                  forceInstitution
                  institutionIds={institution ? [institution] : []}
                  label="Responsável"
                  loggedUserInfo={loggedUserInfo.data.user}
                  margin="normal"
                  name="responsible"
                  onChange={this.handleChange(`responsible`)}
                  value={responsible}
                />
              </Grid>
              {InventoryCategories.some(
                val =>
                  val.subCategories && val.subCategories.some(subCat => subCat.id === type && subCat.dicomRequired),
              ) && (
                <>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TextField
                      className={classes.textField}
                      error={`dicomInfo` in formErrors && `aeTitle` in formErrors.dicomInfo}
                      helperText={
                        `dicomInfo` in formErrors && `aeTitle` in formErrors.dicomInfo && `Esse campo é obrigatório!`
                      }
                      id="id_dicomInfo.aeTitle"
                      label="AE Title"
                      margin="normal"
                      name="dicomInfo.aeTitle"
                      onChange={this.handleChange(`aeTitle`)}
                      value={aeTitle}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={8} sm={4} md={4} lg={4} xl={4}>
                    <TextField
                      className={classes.textField}
                      error={`dicomInfo` in formErrors && `ip` in formErrors.dicomInfo}
                      helperText={
                        `dicomInfo` in formErrors && `ip` in formErrors.dicomInfo && `Esse campo é obrigatório!`
                      }
                      id="id_dicomInfo.ip"
                      label="IP"
                      margin="normal"
                      name="dicomInfo.ip"
                      onChange={this.handleChange(`ip`)}
                      value={ip}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <TextField
                      className={classes.textField}
                      error={`dicomInfo` in formErrors && `port` in formErrors.dicomInfo}
                      helperText={
                        `dicomInfo` in formErrors && `port` in formErrors.dicomInfo && `Esse campo é obrigatório!`
                      }
                      id="id_dicomInfo.port"
                      label="Porta"
                      margin="normal"
                      name="dicomInfo.port"
                      onChange={this.handleChange(`port`)}
                      type="number"
                      value={port}
                      variant="outlined"
                    />
                  </Grid>
                </>
              )}
            </Grid>
            <Grid item container spacing={0}>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <TextField
                  className={classes.textField}
                  error={`model` in formErrors}
                  helperText={`model` in formErrors && `Esse campo é obrigatório!`}
                  id="id_model"
                  label="Modelo"
                  margin="normal"
                  name="model"
                  onChange={this.handleChange(`model`)}
                  required
                  value={model}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <TextField
                  className={classes.textField}
                  id="id_msregister"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <Tooltip title="Abrir no site da ANVISA">
                          <IconButton onClick={this.handleAnvisaRedirect}>
                            <Search />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  label="Registro ANVISA"
                  margin="normal"
                  name="msregister"
                  onChange={this.handleChange(`msregister`)}
                  value={msregister}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <MyDatePickerStyles
                  handleDateChange={this.handleDateChange(`manufactureDate`)}
                  keyboard
                  label="Data de fabricação"
                  margin="normal"
                  name="manufactureDate"
                  value={manufactureDate}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <MyDatePickerStyles
                  handleDateChange={this.handleDateChange(`installationDate`)}
                  keyboard
                  label="Data de instalação"
                  margin="normal"
                  name="installationDate"
                  value={installationDate}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid item container spacing={0}>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <TextField
                  className={classes.textField}
                  id="id_serialnumber"
                  label="Número de série"
                  margin="normal"
                  name="serialnumber"
                  onChange={this.handleChange(`serialnumber`)}
                  value={serialnumber}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <TextField
                  className={classes.textField}
                  id="id_patrimony"
                  label="Patrimônio"
                  margin="normal"
                  name="patrimony"
                  onChange={this.handleChange(`patrimony`)}
                  value={patrimony}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <MyTimePickerStyles
                  handleDateChange={this.handleDateChange(`whInitial`)}
                  keyboard
                  label="Início do trabalho"
                  margin="normal"
                  name="whInitial"
                  value={whInitial}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <MyTimePickerStyles
                  handleDateChange={this.handleDateChange(`whFinal`)}
                  keyboard
                  label="Término do trabalho"
                  margin="normal"
                  name="whFinal"
                  value={whFinal}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid item container spacing={0}>
              <Grid item container spacing={0} xs={12}>
                <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                  <TextField
                    className={classes.textField}
                    error={!!formErrors.workingDays}
                    helperText={formErrors.workingDays && `Esse campo é obrigatório!`}
                    id="id_workingDays"
                    label="Dias úteis de utilização"
                    margin="normal"
                    name="workingDays"
                    onChange={this.handleChange(`workingDays`)}
                    required
                    type="number"
                    value={workingDays || ``}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                  <TextField
                    className={classes.textField}
                    id="id_mCost"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                    }}
                    label="Custo mensal"
                    margin="normal"
                    name="mCost"
                    onChange={this.handleChange(`mCost`)}
                    type="number"
                    value={mCost}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                  <TextField
                    className={classes.textField}
                    id="id_acquisitionPrice"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                    }}
                    label="Preço de compra"
                    margin="normal"
                    name="acquisitionPrice"
                    onChange={this.handleChange(`acquisitionPrice`)}
                    type="number"
                    value={acquisitionPrice}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                  <CustomSelect
                    className={classes.textFieldExtended}
                    errorMessage={`acquisitionType` in formErrors ? `Esse campo é obrigatório!` : null}
                    handleChange={this.handleChange}
                    id="id_acquisition_type"
                    idKey="id"
                    label="Aquisição"
                    margin="normal"
                    name="acquisitionType"
                    nameKey="label"
                    options={acquisitionTypes}
                    value={acquisitionType || ``}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                  <TextField
                    className={classes.textFieldExtended}
                    error={`location` in formErrors}
                    helperText={`location` in formErrors && `Esse campo é obrigatório!`}
                    id="id_location"
                    label="Localização"
                    margin="normal"
                    name="location"
                    onChange={this.handleChange(`location`)}
                    value={location || ``}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                  <TextField
                    className={classes.textFieldExtended}
                    error={!!formErrors.occupiedArea}
                    helperText={formErrors.occupiedArea && `Esse campo é obrigatório!`}
                    id="id_occupied_area"
                    label="Área ocupada"
                    margin="normal"
                    name="occupiedArea"
                    onChange={this.handleChange(`occupiedArea`)}
                    type="number"
                    value={occupiedArea || ``}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  sm={6}
                  md={3}
                  lg={3}
                  xl={3}
                  alignItems="flex-end"
                  alignContent="flex-end"
                  justify="center"
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isAcessory}
                        value={isAcessory ? `isAcessory` : ``}
                        name="isAcessory"
                        id="id_isAcessory"
                        color="primary"
                        onChange={this.handleChange(`isAcessory`)}
                      />
                    }
                    label="Acessório"
                    margin="normal"
                  />
                </Grid>
                {isAcessory && (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    lg={3}
                    xl={3}
                    style={{
                      display: `flex`,
                      flexFlow: `row wrap`,
                      alignItems: `center`,
                      alignContent: `center`,
                    }}
                  >
                    <NewAutoComplete
                      error={`inventoryFather` in formErrors}
                      filterOption={(candidate, input) => {
                        const text = trimAndNormalizeString(input);
                        return (
                          trimAndNormalizeString(candidate.data.location).includes(text) ||
                          trimAndNormalizeString(candidate.data.patrimony).includes(text) ||
                          trimAndNormalizeString(candidate.label).includes(text)
                        );
                      }}
                      formInstance={formInstance ? formInstance.inventory : false}
                      handleChange={this.handleChange(`inventoryFather`)}
                      isClearable
                      label="Ativo pai"
                      margin="normal"
                      name="inventoryFather"
                      nooptionsmessage="Sem opções"
                      openUp
                      OptionComponent={({
                        children,
                        innerProps,
                        innerRef,
                        isDisabled,
                        isFocused,
                        isSelected,
                        data,
                      }) => {
                        return (
                          <MenuItem
                            buttonRef={innerRef}
                            selected={isFocused}
                            component="div"
                            style={{
                              fontWeight: isSelected ? 500 : 400,
                              color: isDisabled ? `#d3d3d3` : null,
                            }}
                            // eslint-disable-next-line
                            {...innerProps}
                          >
                            <Grid container spacing={0} style={{ width: `100%` }}>
                              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Typography>{children}</Typography>
                              </Grid>
                              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Typography color="textSecondary">
                                  <small>
                                    {data.location}
                                    {data.location && data.patrimony && <br />}
                                    {data.patrimony}
                                  </small>
                                </Typography>
                              </Grid>
                            </Grid>
                          </MenuItem>
                        );
                      }}
                      placeholder="Selecione o ativo pai"
                      required
                      single={inventoryFather}
                      style={{ marginTop: `auto` }}
                      suggestions={Inventories.filter(
                        val => val.institution === institution && val.type !== 61 && !val.isAcessory,
                      ).map(val => ({
                        label: val.name,
                        value: val.id,
                        location: val.location,
                        patrimony: val.patrimony,
                      }))}
                      variant="outlined"
                    />
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <TextField
                  className={classes.textField}
                  id="id_observations"
                  label="Observações"
                  margin="normal"
                  multiline
                  name="observations"
                  onChange={this.handleChange(`observations`)}
                  value={observations}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                container
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                alignItems="center"
                alignContent="center"
                justify="center"
                style={{ display: `none` }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isActive}
                      value={isActive ? `isActive` : ``}
                      name="isActive"
                      id="id_isActive"
                      color="primary"
                      onChange={this.handleChange(`isActive`)}
                    />
                  }
                  label="Ativo"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                style={{ marginTop: 15, alignItems: `center`, textAlign: `center` }}
              >
                <Accordion style={{ marginLeft: 5, minHeight: 56, width: `98%`, textAlign: `center` }}>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    {`Esse ativo possui ${attachs.length} anexos.`}
                  </AccordionSummary>
                  <AccordionDetails>
                    <div
                      style={{
                        textAlign: `center`,
                        width: `100%`,
                      }}
                    >
                      {!!attachs.length &&
                        attachs.map(attach => {
                          const { attachment, attachType: mapAttachType, id, inventory } = attach;
                          const regex = new RegExp(`/${inventory} - (.*)/`, `i`);
                          const { attachIcon, attachIconColor, attachName } = (() => {
                            const mapAttachName = decodeURIComponent(attachment)
                              .split(`?Expires`)[0]
                              .split(regex)[2]
                              .replace(/_/g, ` `)
                              .replace(/\..*/, ``);
                            if (attachment.match(/\.pdf/)) {
                              return { attachIcon: faFilePdf, attachIconColor: `red`, attachName: mapAttachName };
                            }
                            if (attachment.match(/\.(doc|docx)/i)) {
                              return {
                                attachIcon: faFileWord,
                                attachIconColor: `blue`,
                                attachName: mapAttachName,
                              };
                            }
                            return { attachIcon: faPaperclip, attachIconColor: `red`, attachName: mapAttachName };
                          })();
                          return (
                            <Grid key={id} container>
                              <Grid container item xs={12} justify="center" alignContent="center" alignItems="center">
                                <Grid item xs={6}>
                                  <Typography>{`${mapAttachType || `Outros`}`}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Button onClick={() => this.handleOpenFile(attachment)}>
                                    <Typography>
                                      <FontAwesomeIcon icon={attachIcon} style={{ color: `${attachIconColor}` }} />
                                      <small>{` ${attachName}`}</small>
                                    </Typography>
                                  </Button>
                                </Grid>
                              </Grid>
                              <Grid item xs={12}>
                                <Divider />
                              </Grid>
                            </Grid>
                          );
                        })}
                      <Grid container justify="space-between">
                        <Grid item xs={6}>
                          <CustomSelect
                            className={classes.textField}
                            handleChange={this.handleChange}
                            id="id_attachType"
                            idKey="id"
                            label="Tipo de anexo"
                            name="attachType"
                            nameKey="attachType"
                            options={[
                              {
                                id: `Nota fiscal`,
                                attachType: `Nota fiscal`,
                              },
                              {
                                id: `Manual`,
                                attachType: `Manual`,
                              },
                              {
                                id: `Outros`,
                                attachType: `Outros`,
                              },
                            ]}
                            value={attachType}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <div
                            style={{
                              width: `100%`,
                              display: `flex`,
                              flexDirection: `row-reverse`,
                              marginTop: 10,
                            }}
                          >
                            <label htmlFor="id_attachment">
                              <Button variant="contained" color="default" component="span">
                                Upload
                                <CloudUpload className={classes.rightIcon} />
                              </Button>
                              <input
                                id="id_attachment"
                                name="attachment"
                                type="file"
                                style={{ display: `none` }}
                                onChange={formInstance ? this.handleFileSubmit : this.handleChange(`files`)}
                              />
                            </label>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
            {!hasInvoice && (
              <Grid item xs={12}>
                <FormHelperText error className={classes.textField}>
                  Para um melhor controle contábil é de extrema importância anexar a nota fiscal de compra do ativo!
                </FormHelperText>
              </Grid>
            )}
          </Grid>
        </form>
        <form
          id="anvisaForm"
          action="http://www.anvisa.gov.br/scriptsweb/Tecnovigilancia/ResultadoGGTPS.asp"
          target="_blank"
          method="POST"
        >
          <input type="hidden" name="BD" value="6" />
          <input type="hidden" name="txRegistro" value={msregister} />
        </form>
      </>
    );
  }
}

const mapStateToProps = state => ({
  InventoryCategories: state.InventoryCategories.data,
  Inventories: state.Inventories.data,
  Institutions: state.Institutions.data,
  loggedUserInfo: state.Auth,
});

export default connect(mapStateToProps, null)(withDialog()(withStyles(styles)(InventoryForm)));
