import { WorkSchedulle } from '../types';
/**
 * Action types
 */
export enum SectorsTypes {
  LOAD_REQUEST = '@Sectors/LOAD_REQUEST', // eslint-disable-line
  LOAD_SUCCESS = '@Sectors/LOAD_SUCCESS', // eslint-disable-line
  LOAD_FAILURE = '@Sectors/LOAD_FAILURE', // eslint-disable-line
  ADD_REQUEST = '@Sectors/ADD_REQUEST', // eslint-disable-line
  ADD_SUCCESS = '@Sectors/ADD_SUCCESS', // eslint-disable-line
  ADD_FAILURE = '@Sectors/ADD_FAILURE', // eslint-disable-line
  UPDATE_REQUEST = '@Sectors/UPDATE_REQUEST', // eslint-disable-line
  UPDATE_SUCCESS = '@Sectors/UPDATE_SUCCESS', // eslint-disable-line
  UPDATE_FAILURE = '@Sectors/UPDATE_FAILURE', // eslint-disable-line
  OPEN_REQUEST = '@Sectors/OPEN_REQUEST', // eslint-disable-line
  OPEN_SUCCESS = '@Sectors/OPEN_SUCCESS', // eslint-disable-line
  CLOSE_REQUEST = '@Sectors/CLOSE_REQUEST', // eslint-disable-line
  CLOSE_SUCCESS = '@Sectors/CLOSE_SUCCESS', // eslint-disable-line
  RANGE_REQUEST = '@Sectors/RANGE_REQUEST', // eslint-disable-line
  RANGE_SUCCESS = '@Sectors/RANGE_SUCCESS', // eslint-disable-line
}
/**
 * Data types
 */
export interface Sector {
  id: number;
  name: string;
  workSchedulle: WorkSchedulle;
  workSchedulleEnabled: boolean;
}

export interface SectorError {
  id?: string;
  name?: string;
}
/**
 * State types
 */
export interface SectorsState {
  readonly data: Sector[];
  readonly formErrors?: SectorError;
  readonly isLoading: boolean;
  readonly error: boolean;
  readonly isSaving: boolean;
  readonly modalOpen: boolean;
  readonly formInstance: any[];
  readonly range: number;
}
