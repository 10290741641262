import React, { ReactNode, SFC, UIEvent, useEffect, useMemo, useState } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import MuiDatatable, { MUIDataTableColumn, MUIDataTableOptions, MUIDataTableState } from 'mui-datatables';
import { Badge, Chip, Hidden, IconButton, LinearProgress, Tooltip, Typography } from '@material-ui/core';
import {
  Clear as ClearIcon,
  Done as DoneIcon,
  CheckCircleOutlined as CheckCircleOutlinedIcon,
} from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useDialog } from 'muibox';
import { FilterValue } from '../../Routes';
import CustomToolbar from '../../Components/CustomToolbar';
import * as InventoryActions from '../../ReduxFlow/Reducers/Inventories/Actions';
import { loadRequest as loadCategoriesRequestAction } from '../../ReduxFlow/Reducers/InventoryCategories/Actions';
import { ApplicationState } from '../../ReduxFlow/Reducers';
import { AuthState } from '../../ReduxFlow/Reducers/Auth/Types';
import { InventoriesState } from '../../ReduxFlow/Reducers/Inventories/Types';
import { InventoryCategory, InventorySubcategory } from '../../ReduxFlow/Reducers/InventoryCategories/Types';
import { Institution } from '../../ReduxFlow/Reducers/Institutions/Types';
import { taskSituationMap } from './InventoriesConstants';
import api from '../../Services/api';
import { getSortValue } from '../../ReduxFlow/Reducers/Inventories/Sagas';
import { filterListToQueryObject, saveFile } from '../../Utils';
import MobileInventory from './MobileInventory';
import DialogInvForm from './DialogInvForm';
import * as muiDatatableHelper from '../../Utils/muiDatatableHelper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    smRecipient: {
      height: `calc(100vh - 56px)`,
      overflowY: `auto`,
    },
    institutionAvatar: {
      width: 30,
      height: 30,
      transition: `transform .3s`,
      '&:hover': {
        transform: `scale(3)`,
      },
    },
    markerRoot: {
      fontSize: 12,
      height: 15,
    },
    markerChildren: {
      padding: `0 6px 0 6px`,
    },
  }),
);

enum TaskSitatuion {
  pc = 'PC', // eslint-disable-line
  sp = 'SP', // eslint-disable-line
  ss = 'SS', // eslint-disable-line
  wk = 'WK', // eslint-disable-line
}

interface ColumnType extends MUIDataTableColumn {
  filterName: string;
}

type StoreProps = {
  institutions: Institution[];
  inventories: InventoriesState;
  inventoryCategories: InventoryCategory[];
  loggedUserInfo: AuthState;
};

type DispatchProps = {
  addOrUpdateRequest: typeof InventoryActions.addOrUpdateRequest;
  changeInventoryFilters: typeof InventoryActions.changeInventoryFilters;
  changeInventoryPages: typeof InventoryActions.changeInventoryPages;
  changeInventorySort: typeof InventoryActions.changeInventorySort;
  closeInactivateInventoryRequest: typeof InventoryActions.closeInactivateInventoryRequest;
  closeInventoryRequest: typeof InventoryActions.closeInventoryRequest;
  inventoryMobileInfiniteScrollRequest: typeof InventoryActions.inventoryMobileInfiniteScrollRequest;
  loadCategoriesRequest: typeof loadCategoriesRequestAction;
  loadRequest: typeof InventoryActions.loadRequest;
  openInactivateInventoryRequest: typeof InventoryActions.openInactivateInventoryRequest;
  openInventoryRequest: typeof InventoryActions.openInventoryRequest;
};

type InventoriesProps = {
  filterValue: FilterValue;
  handleFetchStatus: Function;
};

const Inventories: SFC<StoreProps & DispatchProps & InventoriesProps> = ({
  addOrUpdateRequest,
  changeInventoryFilters,
  changeInventoryPages,
  changeInventorySort,
  closeInactivateInventoryRequest,
  closeInventoryRequest,
  filterValue,
  handleFetchStatus,
  institutions,
  inventories,
  inventoryCategories,
  inventoryMobileInfiniteScrollRequest,
  loadCategoriesRequest,
  loadRequest,
  loggedUserInfo,
  openInactivateInventoryRequest,
  openInventoryRequest,
}) => {
  const classes = useStyles();
  const dialog = useDialog();
  const { data } = inventories;
  const { isLoadingUI } = loggedUserInfo;
  const { currentOrganization } = loggedUserInfo.data;
  const { isAdminOrSuperAdmin, isSuperAdmin } = loggedUserInfo.data.user;
  const subCategories = useMemo(() => {
    let insideSubCategories: InventorySubcategory[] = [];
    inventoryCategories.forEach(cat => {
      if (cat.subCategories) {
        insideSubCategories = insideSubCategories.concat(cat.subCategories);
      }
    });
    return insideSubCategories;
  }, [inventoryCategories]);
  const [downloadingReport, setDownloadingReport] = useState<boolean>(false);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);

  useEffect(() => {
    if (!firstLoad) {
      changeInventoryFilters({
        dialog,
        filterList: { isActive: [`Ativo`] },
        searchText: filterValue.value,
      });
    }
  }, [changeInventoryFilters, dialog, filterValue.value, firstLoad]);

  useEffect(() => {
    const localStorageOrg = window.localStorage.getItem(`currentOrganization`);
    if (localStorageOrg === JSON.stringify(currentOrganization) && !isLoadingUI && !data.length && firstLoad) {
      loadCategoriesRequest(dialog);
      if (filterValue.status) {
        changeInventoryFilters({
          dialog,
          filterList: { isActive: [`Ativo`] },
          searchText: filterValue.value,
        });
        handleFetchStatus();
      } else {
        loadRequest(dialog);
      }
      setFirstLoad(false);
    }
  }, [
    changeInventoryFilters,
    currentOrganization,
    dialog,
    filterValue.status,
    filterValue.value,
    firstLoad,
    handleFetchStatus,
    isLoadingUI,
    data,
    loadCategoriesRequest,
    loadRequest,
  ]);

  function getSubCategoryName(id: number): string {
    let subCategoryName = ``;
    inventoryCategories.forEach(cat => {
      if (cat.subCategories) {
        cat.subCategories.forEach(subCat => {
          if (subCat.id === id) {
            subCategoryName = subCat.name;
          }
        });
      }
    });
    return subCategoryName;
  }

  function handleScroll(event: UIEvent<HTMLDivElement>): void {
    event.stopPropagation();
    const { clientHeight, scrollHeight, scrollTop } = event.currentTarget;
    if ((scrollHeight - scrollTop) * 0.7 <= clientHeight && inventories.pagination.count >= inventories.data.length) {
      inventoryMobileInfiniteScrollRequest({ dialog });
    }
  }

  const columns: ColumnType[] = [
    {
      filterName: `isActive`,
      label: `Ativo`,
      name: `isActive`,
      options: {
        filter: true,
        customFilterListRender: (v: string): string => {
          return `Ativo: ${v}`;
        },
        customBodyRender(value: boolean): ReactNode {
          return value ? <DoneIcon /> : <ClearIcon />;
        },
        filterType: `dropdown`,
        filterOptions: {
          names: [`Ativo`, `Inativo`],
        },
        filterList: inventories.filterList.isActive,
      },
    },
    {
      filterName: `name`,
      label: `Nome`,
      name: `title`,
      options: {
        filter: true,
        customFilterListRender: (v: string): string => `Nome: ${v}`,
        filterList: inventories.filterList.name,
        customBodyRender(value: { id: number; name: string }): ReactNode {
          return (
            <>
              <Typography>{value.name}</Typography>
              <Typography color="textSecondary">
                <small>{`#${value.id}`}</small>
              </Typography>
            </>
          );
        },
        filterOptions: {
          logic(invNameId: string, filter: string[]): boolean {
            if (JSON.stringify(filter).includes(invNameId)) {
              return true;
            }
            return false;
          },
        },
      },
    },
    (muiDatatableHelper.buildInstitutionColumn(institutions, (inventories.filterList
      .institutions as unknown) as number[]) as unknown) as ColumnType,
    {
      filterName: `type`,
      label: `Tipo de ativo`,
      name: `type`,
      options: {
        customBodyRender(value: number): ReactNode {
          return getSubCategoryName(value);
        },
        customFilterListRender: (v: string): string => `Tipo de ativo: ${v}`,
        filterOptions: {
          names: subCategories.map(subCat => subCat.name),
        },
        filterType: `multiselect`,
        filterList: inventories.filterList.type,
      },
    },
    {
      filterName: `id`,
      name: `id`,
      options: {
        filter: false,
        display: `false`,
      },
    },
    {
      filterName: `isApproved`,
      label: `Aprovado`,
      name: `isApproved`,
      options: {
        filter: true,
        customBodyRender(value: boolean): ReactNode {
          if (value) return `Aprovado`;
          return `Pendente`;
        },
        customFilterListRender: (v: string): string => `Aprovado: ${v}`,
        display: `true`,
        filterType: `dropdown`,
        filterOptions: {
          names: [`Aprovado`, `Pendente`],
        },
        filterList: inventories.filterList.isApproved,
      },
    },
    {
      filterName: `patrimony`,
      label: `Patrimônio`,
      name: `patrimony`,
      options: {
        filter: true,
        customFilterListRender: (v: string): string => `Patrimônio: ${v}`,
        display: `false`,
        searchable: true,
        filterList: inventories.filterList.patrimony,
      },
    },
    {
      filterName: `status`,
      label: `Situação`,
      name: `status`,
      options: {
        filter: false,
        customBodyRender(value: TaskSitatuion): ReactNode {
          const situationObject = taskSituationMap[value];
          const { backgroundColor, name } = situationObject;
          return (
            <div>
              <Chip
                label={name}
                style={{ color: `#fff`, backgroundColor }}
                className={classes.markerRoot}
                classes={{ label: classes.markerChildren }}
              />
            </div>
          );
        },
      },
    },
  ];

  const options: MUIDataTableOptions = {
    rowsPerPage: window.innerHeight > 760 ? 15 : 10,
    viewColumns: false,
    print: false,
    fixedHeader: true,
    selectableRows: `none`,
    filter: true,
    filterType: `textField`,
    responsive: `scrollFullHeight`,
    serverSide: true,
    count: inventories.pagination.count,
    page: inventories.page,
    download: !downloadingReport,
    onTableChange: (action: string, tableState: MUIDataTableState): void => {
      const { activeColumn, announceText, filterList, page, rowsPerPage, searchText } = tableState;
      const filters = filterList.reduce(
        (acc, v, i) => (v.length ? Object.assign(acc, { [columns[i].filterName]: v }) : acc),
        {},
      );
      switch (action) {
        case `sort`:
          if (activeColumn && announceText)
            changeInventorySort({
              activeColumn: columns[parseInt(activeColumn, 10)].name,
              dialog,
              sort: announceText.split(` : `)[1],
            });
          break;
        case `resetFilters`:
        case `filterChange`:
          changeInventoryFilters({ dialog, filterList: filters, searchText: searchText || undefined });
          break;
        case `search`:
          changeInventoryFilters({ dialog, filterList: filters, searchText: searchText || undefined });
          break;
        case `changeRowsPerPage`:
        case `changePage`:
          changeInventoryPages({ dialog, page, rowsPerPage });
          break;
        default:
      }
    },
    searchText: inventories.searchText,
    customToolbar: isAdminOrSuperAdmin
      ? (): ReactNode => {
          return (
            <>
              {isSuperAdmin && (
                <Tooltip title="Para aprovar">
                  <IconButton
                    onClick={(): void => {
                      const { filterList, searchText } = inventories;
                      changeInventoryFilters({
                        dialog,
                        filterList: { ...filterList, isApproved: [`Pendente`] },
                        searchText,
                      });
                    }}
                  >
                    <Badge badgeContent={inventories.toApprove} color="secondary" style={{ marginRight: 10 }}>
                      <CheckCircleOutlinedIcon />
                    </Badge>
                  </IconButton>
                </Tooltip>
              )}
              <CustomToolbar tooltip="Adicionar ativo" handleModalState={openInventoryRequest} />
            </>
          );
        }
      : undefined,
    textLabels: {
      body: {
        noMatch: inventories.isLoading || isLoadingUI ? `Buscando ativos...` : `Desculpe, nenhum registro encontrado!`,
        toolTip: `Ordenar`,
      },
      pagination: {
        next: `Próxima página`,
        previous: `Págima anterior`,
        rowsPerPage: `Linhas por página:`,
        displayRows: `de`,
      },
      toolbar: {
        search: `Procurar`,
        downloadCsv: `Download`,
        print: `Imprimir`,
        viewColumns: `Colunas visíveis`,
        filterTable: `Filtrar a tabela`,
      },
      filter: {
        all: `Todos`,
        title: `Filtros`,
        reset: `Resetar`,
      },
      viewColumns: {
        title: `Mostrar colunas`,
        titleAria: `Mostrar/Ocultar Colunas da Tabela`,
      },
      selectedRows: {
        text: `linha(s) selecionada(s)`,
        delete: `Deletar`,
        deleteAria: `Deletear linha(s) selecionada(s)`,
      },
    },
    onRowClick: rowData => {
      openInventoryRequest({ dialog, id: rowData[4] });
    },
    onDownload: () => {
      setDownloadingReport(true);
      const { filterList, page, rowsPerPage, searchText, sort } = inventories;
      api
        .get(`/inventory/inventory/getreport/`, {
          params: {
            ...filterListToQueryObject(filterList),
            globalSearch: searchText,
            length: rowsPerPage,
            page: page + 1,
            sort: getSortValue(sort),
          },
          responseType: `blob`,
        })
        .then(res => {
          saveFile(res.data, `report.xlsx`);
          setDownloadingReport(false);
        })
        .catch(error => {
          // eslint-disable-next-line
          console.error(error);
          setDownloadingReport(false);
        });
      return false;
    },
  };

  return (
    <>
      {inventories.isLoading && <LinearProgress />}
      <Hidden only={[`md`, `lg`, `xl`]}>
        <div className={classes.smRecipient} onScroll={handleScroll}>
          <MobileInventory
            data={inventories.data}
            dialog={dialog}
            getSubCategoryName={getSubCategoryName}
            handleOpen={openInventoryRequest}
            institutions={institutions}
            isLoading={inventories.isLoading}
            loggedUserInfo={loggedUserInfo.data.user}
          />
        </div>
      </Hidden>
      <Hidden only={[`xs`, `sm`]}>
        {downloadingReport && <LinearProgress />}
        <MuiDatatable columns={columns} data={inventories.data} options={options} title="Ativos" />
      </Hidden>
      {inventories.dialogOpen && (
        <DialogInvForm
          addOrUpdateRequest={addOrUpdateRequest}
          formErrors={inventories.formErrors}
          formInstance={inventories.formInstance}
          fullscreen={window.innerWidth < 960}
          handleClick={closeInventoryRequest}
          isSaving={inventories.isSaving}
          loggedUserInfo={loggedUserInfo}
          open={inventories.dialogOpen}
          inactivateOpen={inventories.inactivateOpen}
          inactivateDialogClose={closeInactivateInventoryRequest}
          inactivateDialogOpen={openInactivateInventoryRequest}
        />
      )}
    </>
  );
};

const InventoriesTests = Inventories;

export { InventoriesTests };

const mapStateToProps = (state: ApplicationState): StoreProps => ({
  loggedUserInfo: state.Auth,
  inventories: state.Inventories,
  institutions: state.Institutions.data,
  inventoryCategories: state.InventoryCategories.data,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators({ ...InventoryActions, loadCategoriesRequest: loadCategoriesRequestAction }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Inventories);
