import React, { FC } from 'react';
import { Button, CircularProgress, Grid, InputAdornment } from '@material-ui/core';
import { InfoOutlined as InfoOutlinedIcon } from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CommentsInput from 'Components/Comments/CommentsInput';
import CustomDateTimePicker from 'Components/NewUIComponents/CustomDateTimePicker';
import CustomTextField from 'Components/NewUIComponents/CustomTextField';
import HelperBox, { HelperBoxVariantTypes } from 'Components/NewUIComponents/HelperBox';
import TaskAttachsContainer from 'Components/NewUIComponents/TaskAttachsContainer';
import TasksCommentsContainer from 'Components/NewUIComponents/CommentsContainer/Tasks';
import TaskCategorySelect from 'Components/NewUIComponents/TaskCategorySelect';
import { disableDates, getTaskCategories } from 'Pages/Tasks/tasksCommonHandlers';
import genericAvatar from 'Images/GenericAvatar/genericavatar.png';
import { MarginType } from 'Utils/globalTypes';
import { FormBodyProps } from '../tasks-forms.interfaces';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      height: `30px`,
      width: `30px`,
    },
    commentsButton: {
      color: theme.palette.primary.dark,
    },
    customTextFieldLeft: {
      marginRight: theme.spacing(0.5),
      width: `calc(100% - ${theme.spacing(0.5)}px)`,
    },
    customTextFieldRight: {
      marginLeft: theme.spacing(0.5),
      width: `calc(100% - ${theme.spacing(0.5)}px)`,
    },
    selectorIcon: {
      cursor: `pointer`,
      height: theme.spacing(1.75),
      width: theme.spacing(1.75),
    },
    selectorIconColorDark: {
      color: theme.palette.primary.dark,
    },
  }),
);

const FormBody: FC<FormBodyProps> = ({
  attachs,
  comment,
  comments,
  commentsControll,
  commentsRange,
  disableAllFields,
  disableDescription,
  formBodyFields,
  formBodyTasksProps,
  formCategoryText,
  fullScreen,
  getInputProps,
  getRootProps,
  handleCommentDelete,
  handleCommentSubmit,
  handleDateFieldChange,
  handleFileDelete,
  handleSaveEditing,
  handleSubCategoryChange,
  handleToggleEditing,
  isEditingFields,
  isGettingComments,
  isSaving,
  loggedUser,
  setComment,
  specificTaskInput,
  tasksCategoriesState,
  temporaryAttachs,
  temporaryAttachsHasError,
  temporaryAttachsProgress,
  updateFormHandleFileSubmit,
}) => {
  const classes = useStyles();
  const { formInstance } = formBodyTasksProps;
  const { ref, ...rootProps } = getRootProps();

  return (
    <Grid item xs={12} md={7}>
      <Grid container direction="column">
        {!formInstance.isActive && (
          <Grid item xs={12}>
            <HelperBox
              icon={<InfoOutlinedIcon color="inherit" />}
              text={
                <>
                  Esta ouvidoria foi <strong>fechada</strong> em&nbsp;
                  <strong>{new Date(formInstance.finishTime as string).toLocaleString(`pt-BR`)}</strong> por
                  <strong>&nbsp;{formInstance.finisher}</strong>. Para realizar edições e fazer comentários, clique no
                  botão <strong>Reabrir</strong>
                </>
              }
              variant={HelperBoxVariantTypes.danger}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <CustomTextField
            cancelSaveAction={handleToggleEditing(`description`)}
            disabled={disableAllFields || disableDescription}
            error={!!formBodyTasksProps.formErrors && !!formBodyTasksProps.formErrors.description}
            fullWidth
            hasInstance
            helperText={!!formBodyTasksProps.formErrors && formBodyTasksProps.formErrors.description}
            id="id_description"
            isEditing={isEditingFields.description && !disableDescription}
            label="Detalhamento"
            margin="dense"
            multiline
            name="description"
            onClick={!isEditingFields.description && !disableAllFields ? handleToggleEditing(`description`) : undefined}
            required
            saveAction={handleSaveEditing(`description`)}
            value={formBodyFields.description}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="row">
            <Grid item xs={12} sm={6}>
              {specificTaskInput}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TaskCategorySelect
                categoriesAndSubCategories={getTaskCategories(
                  formBodyFields.responsibleSector as number,
                  tasksCategoriesState,
                )}
                disabled={disableAllFields}
                formControlClass={classes.customTextFieldRight}
                hasInstance
                id="id_subcategory"
                isEditing={isEditingFields.subcategory}
                isLoading={tasksCategoriesState.loading}
                label="Categorias"
                margin={MarginType.dense}
                name="subcategory"
                onBlur={handleToggleEditing(`subcategory`, false)}
                onChange={handleSubCategoryChange}
                onFocus={handleToggleEditing(`subcategory`)}
                value={formBodyFields.subcategory}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="row">
            <Grid item xs={12} sm={6}>
              <CustomDateTimePicker
                className={classes.customTextFieldLeft}
                disabled={disableDates(tasksCategoriesState, formBodyFields.subcategory) || disableAllFields}
                hasInstance
                isEditing={isEditingFields.startDate}
                label="Data de início prevista"
                margin={MarginType.dense}
                name="startDate"
                onBlur={handleToggleEditing(`startDate`, false)}
                onChange={handleDateFieldChange(`startDate`)}
                onClick={!isEditingFields.startDate && !disableAllFields ? handleToggleEditing(`startDate`) : undefined}
                value={formBodyFields.startDate}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomDateTimePicker
                className={classes.customTextFieldRight}
                disabled={disableDates(tasksCategoriesState, formBodyFields.subcategory) || disableAllFields}
                hasInstance
                isEditing={isEditingFields.estimatedEndDate}
                label="Data de entrega esperada"
                margin={MarginType.dense}
                name="estimatedEndDate"
                onBlur={handleToggleEditing(`estimatedEndDate`, false)}
                onChange={handleDateFieldChange(`estimatedEndDate`)}
                onClick={
                  !isEditingFields.estimatedEndDate && !disableAllFields
                    ? handleToggleEditing(`estimatedEndDate`)
                    : undefined
                }
                value={formBodyFields.estimatedEndDate}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {formCategoryText(disableAllFields, `Anexos`)}
        </Grid>
        {!!temporaryAttachs.length && (
          <Grid item xs={12}>
            <TaskAttachsContainer
              attachs={temporaryAttachs}
              disabled={disableAllFields}
              disableTitle
              hasError={temporaryAttachsHasError}
              loggedUserId={loggedUser.id}
              progress={temporaryAttachsProgress}
              retryCallback={updateFormHandleFileSubmit}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <TaskAttachsContainer
            attachs={attachs}
        attachsInput={<input {...getInputProps()} id="id_attachs" name="attachs" />} // eslint-disable-line
            deleteCallback={handleFileDelete}
            disabled={disableAllFields}
            disableTitle
            loggedUserId={loggedUser.id}
            reactDropzoneOnClick={rootProps.onClick}
          />
        </Grid>
        <Grid item xs={12}>
          {formCategoryText(disableAllFields, `Comentários`)}
        </Grid>
        <Grid item xs={12}>
          <TasksCommentsContainer
            comments={comments}
            commentsControllCallback={commentsControll}
            commentsRange={commentsRange}
            deleteAttachCallback={handleFileDelete}
            deleteCallback={handleCommentDelete}
            disabled={disableAllFields}
            disableHoverListener={false}
            disableTitle
            disableTouchListener={false}
            editCallback={handleCommentSubmit}
            genericAvatar={genericAvatar}
            isLoading={isGettingComments}
            loggedUserId={loggedUser.id}
            parentType="ouvidoria"
          />
        </Grid>
        <Grid item style={{ marginBottom: fullScreen ? 89 : 0 }} xs={12}>
          <CommentsInput
            avatarStyles={classes.avatar}
            avatarThumb={loggedUser.avatarThumb}
            disabled={disableAllFields}
            endAdornment={
              <InputAdornment position="end">
                <Button
                  className={classes.commentsButton}
                  disabled={isSaving || disableAllFields}
                  id="commentButton"
                  onClick={handleCommentSubmit(comment)}
                >
                  Enviar {isSaving && <CircularProgress size={30} />}
                </Button>
              </InputAdornment>
            }
            id="id_comment"
            inputChangeCallback={(event): void => setComment(event.target.value)}
            margin={MarginType.normal}
            name="comment"
            placeholder="Adicione um comentário"
            value={comment}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FormBody;
