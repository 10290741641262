import { Reducer } from 'redux';
import { Supplier, SuppliersState, SuppliersTypes } from './Types';

export const INITIAL_STATE: SuppliersState = {
  data: [],
  error: false,
  isLoading: false,
  isSaving: false,
  modalOpen: false,
  formInstance: [],
  range: 10,
  formErrors: {},
};

const reducer: Reducer<SuppliersState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SuppliersTypes.LOAD_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: false,
        isSaving: false,
        data: state.data,
        formErrors: {},
      };
    case SuppliersTypes.LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        isSaving: false,
        data: action.payload.data,
      };
    case SuppliersTypes.LOAD_FAILURE:
      return { ...state, isLoading: false, error: true, isSaving: false, data: [] };
    case SuppliersTypes.ADD_REQUEST:
      return { ...state, isLoading: false, error: false, isSaving: true, data: state.data };
    case SuppliersTypes.ADD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        isSaving: false,
        data: [action.payload.data, ...state.data],
      };
    case SuppliersTypes.ADD_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
        isSaving: false,
        formErrors: action.payload.errors,
        data: state.data,
      };
    case SuppliersTypes.UPDATE_REQUEST:
      return { ...state, isLoading: false, error: false, isSaving: true, data: state.data };
    case SuppliersTypes.UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        isSaving: false,
        data: state.data.map((supplier: Supplier) =>
          supplier.id === action.payload.data.id ? action.payload.data : supplier,
        ),
      };
    case SuppliersTypes.UPDATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: false,
        isSaving: false,
        formErrors: action.payload.errors,
        data: state.data,
      };
    case SuppliersTypes.OPEN_REQUEST:
      return { ...state, isLoading: true };
    case SuppliersTypes.OPEN_SUCCESS:
      return { ...state, modalOpen: true, formInstance: action.payload, isLoading: false };
    case SuppliersTypes.CLOSE_REQUEST:
      return { ...state, isLoading: true, modalOpen: false };
    case SuppliersTypes.CLOSE_SUCCESS:
      return { ...state, modalOpen: false, formInstance: [], isLoading: false, formErrors: {} }
    case SuppliersTypes.RANGE_REQUEST:
      return { ...state, isLoading: true };
    case SuppliersTypes.RANGE_SUCCESS:
      return { ...state, isLoading: false, range: state.range + 10 };
    default:
      return state;
  }
};

export default reducer;
