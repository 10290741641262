import { Reducer } from 'redux';
import { TasksTypes, TasksState } from './Types';

export const INITIAL_STATE: TasksState = {
  count: 0,
  data: [],
  filterList: [[], [], [], [], [], [], [], [], [], [], [], [], [], [`Ativas`], []],
  formInstance: [],
  isConvertingTaskToCall: false,
  isLoading: false,
  menuType: ``,
  modalOpen: false,
  orphans: 0,
  page: 0,
  rowsPerPage: (window as any).innerHeight > 760 ? 15 : 10,
  searchText: ``,
  slaKpis: undefined,
  sort: {},
};

const reducer: Reducer<TasksState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TasksTypes.REPORT_REQUEST:
      return { ...state, isLoading: true };
    case TasksTypes.REPORT_SUCCESSFULL:
      return { ...state, isLoading: false };
    case TasksTypes.LOAD_REQUEST:
      return { ...state, isLoading: true };
    case TasksTypes.LOAD_SUCCESS:
      return {
        ...state,
        count: action.payload.count,
        data: action.payload.results,
        isConvertingTaskToCall: false,
        isLoading: false,
        orphans: action.payload.orphans,
        sort: {},
      };
    case TasksTypes.OPEN_REQUEST:
      return {
        ...state,
        isConvertingTaskToCall: false,
        isLoading: true,
        menuType: action.payload.menuType ? action.payload.menuType : ``,
      };
    case TasksTypes.OPEN_SUCCESS:
      return { ...state, modalOpen: true, formInstance: action.payload, isLoading: false };
    case TasksTypes.CLOSE_REQUEST:
      return {
        ...state,
        isConvertingTaskToCall: false,
        isLoading: true,
        modalOpen: false,
      };
    case TasksTypes.CLOSE_SUCCESS:
      return { ...state, isLoading: false, formInstance: [], menuType: ``, modalOpen: false };
    case TasksTypes.CONVERT_CALL_TO_TASK:
      return { ...state, isConvertingTaskToCall: false, menuType: `Tarefa` };
    case TasksTypes.CONVERT_TASK_TO_CALL:
      return { ...state, isConvertingTaskToCall: true, menuType: `Chamado` };
    case TasksTypes.CHANGE_SORT:
      return { ...state, sort: { [action.payload.activeColumn]: { order: action.payload.sort } }, isLoading: true };
    case TasksTypes.CHANGE_FILTERS:
      return {
        ...state,
        page: action.payload.page,
        filterList: action.payload.filterList,
        searchText: action.payload.searchText,
        sort: `sort` in action.payload ? action.payload.sort : {},
        isLoading: true,
      };
    case TasksTypes.CHANGE_PAGES:
      return {
        ...state,
        page: action.payload.page,
        rowsPerPage: action.payload.rowsPerPage,
        isLoading: true,
      };
    case TasksTypes.LOAD_ERRORS:
      return {
        ...state,
        isLoading: false,
      };
    case TasksTypes.LOAD_KPIS_SUSUCCESS:
      return {
        ...state,
        slaKpis: action.payload || undefined,
      };
    default:
      return state;
  }
};

export default reducer;
