/**
 * Action types
 */
export enum QCMarkersTypes {
  LOAD_REQUEST = '@QCMarkers/LOAD_REQUEST', // eslint-disable-line
  LOAD_SUCCESS = '@QCMarkers/LOAD_SUCCESS', // eslint-disable-line
  LOAD_FAILURE = '@QCMarkers/LOAD_FAILURE', // eslint-disable-line
  ADD_REQUEST = '@QCMarkers/ADD_REQUEST', // eslint-disable-line
  ADD_SUCCESS = '@QCMarkers/ADD_SUCCESS', // eslint-disable-line
  ADD_FAILURE = '@QCMarkers/ADD_FAILURE', // eslint-disable-line
  UPDATE_REQUEST = '@QCMarkers/UPDATE_REQUEST', // eslint-disable-line
  UPDATE_SUCCESS = '@QCMarkers/UPDATE_SUCCESS', // eslint-disable-line
  UPDATE_FAILURE = '@QCMarkers/UPDATE_FAILURE', // eslint-disable-line
}
/**
 * Data types
 */
export interface QCMarker {
  id: number;
  name: string;
  color: string;
  fontColor: string;
}

export interface QCMarkerError {
  id?: string;
  name?: string;
  color?: string;
  fontColor?: string;
}
/**
 * State types
 */
export interface QCMarkersState {
  readonly data: QCMarker[];
  readonly errors?: QCMarkerError;
  readonly loading: boolean;
  readonly error: boolean;
  readonly isSaving: boolean;
}
