import React from 'react';
/**
 * Material UI - Core
 */
import { CircularProgress, Fab, Fade, Grid, Paper, Slide, Typography, withStyles } from '@material-ui/core';
/**
 * Material UI - Icons
 */
import { Add } from '@material-ui/icons';
import TableInstitutions from '../../Components/TableInstitutions';

const styles = theme => ({
  avatar: {
    margin: 10,
    width: 50,
    height: 50,
  },
  instAvatar: {
    maxWidth: 40,
  },
  userText: {
    wordWrap: `break-word`,
  },
  userWrapper: {
    margin: `5px auto 5px auto`,
    maxWidth: `98%`,
  },
  fab: {
    position: `absolute`,
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  loaderWrapper: {
    width: `98%`,
    textAlign: `center`,
  },
  footer: {
    width: `100vw`,
    height: `56px`,
    position: `absolute`,
    bottom: 0,
    left: 0,
  },
});

const MobileSupplier = ({ loggedUserInfo, data, classes, institutions, isLoading, handleOpen }) => {
  return (
    <>
      {data.map(value => {
        const suppNameId = JSON.parse(value[1]);
        return (
          <Slide key={value[5]} in direction="up" mountOnEnter unmountOnExit>
            <Paper className={classes.userWrapper} onClick={() => handleOpen({ id: value[5] })}>
              <Grid container alignItems="center">
                <Grid item xs={3}>
                  <TableInstitutions institutions={institutions} selected={value[2]} />
                </Grid>
                <Grid item xs={9} className={classes.userText}>
                  <Typography>{`#${suppNameId.id} ${suppNameId.name}`}</Typography>
                  <Typography>
                    <small>{value[3]} </small>
                    <small> {value[4]}</small>
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Slide>
        );
      })}
      {isLoading && (
        <div className={classes.loaderWrapper}>
          <Fade
            in={isLoading}
            style={{
              transitionDelay: isLoading ? `800ms` : `0ms`,
            }}
            unmountOnExit
          >
            <CircularProgress />
          </Fade>
        </div>
      )}
      {loggedUserInfo.groups.indexOf(1) > -1 && (
        <Fab className={classes.fab} color="primary" onClick={handleOpen}>
          <Add />
        </Fab>
      )}
    </>
  );
};

export default withStyles(styles)(MobileSupplier);
