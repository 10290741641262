import { makeStyles } from '@material-ui/core/styles';
import { validateCpf } from '../../Utils/CpfAndCnpjValidator';

const initState = {
  admissionDate: null,
  birthDate: null,
  cpf: ``,
  email: ``,
  firstName: ``,
  groups: ``,
  isFakeEmail: false,
  lastName: ``,
  password: ``,
  sector: [],
  sex: ``,
  userInstitution: [],
};

const useStyles = theme =>
  makeStyles({
    actionsRoot: {
      alignItems: `center`,
      display: `flex`,
      flex: `0 0 auto`,
      justifyContent: `flex-end`,
      padding: 24,
    },
    actionsSpacing: {
      '& > :not(:first-child)': {
        marginLeft: 16,
      },
    },
    button: {
      font: `Medium 14px/19px Hind`,
      letterSpacing: 1.25,
      minWidth: 105,
      opacity: 1,
    },
    cancelButton: {
      color: `#767676`,
    },
    checkCircle: {
      color: `#32C685`,
      fontSize: 28,
      marginRight: 10.1,
    },
    closeButton: {
      textAlign: `right`,
      color: `#767676`,
      opacity: 1,
    },
    closeButtonGrid: {
      paddingRight: theme.spacing(2),
    },
    commentContainer: {
      margin: `0 ${theme.spacing(0.625)}px 0 ${theme.spacing(1)}px`, // fix to keep comments aligned with user form
      padding: `${theme.spacing(0)}px ${theme.spacing(3)}px`,
    },
    contentRoot: {
      '&:first-child': {
        // dialog without title
        padding: `${theme.spacing(5)}px ${theme.spacing(6)}px ${theme.spacing(5)}px ${theme.spacing(5)}px`,
      },
    },
    copyUserInfoGrid: {
      background: `rgba(0, 0, 0, 0.07) 0% 0% no-repeat padding-box`,
      borderRadius: 4,
      marginBottom: theme.spacing(3),
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
    },
    dialogTitle: {
      marginLeft: theme.spacing(1),
    },
    disabledButton: {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: `${theme.palette.primary.contrastText} !important`,
    },
    emailText: {
      color: `#00000099`,
      letterSpacing: 0.13,
      marginBottom: 20,
      opacity: 1,
    },
    successInfo: {
      color: `#00000099`,
      font: `normal 16px/24px Hind`,
      letterSpacing: 0.13,
    },
    successMessage: {
      letterSpacing: 0.26,
      textAlign: `justify`,
    },
    successText: {
      color: `#000000DE`,
      font: `500 20px/26px Hind`,
    },
    thirdStepGrids: {
      marginBottom: theme.spacing(3),
    },
    userInfoContent: {
      textAlign: `left`,
      font: `normal 16px/21px Hind`,
      letterSpacing: 0.15,
      color: `#000000DE`,
      opacity: 1,
    },
    userInfoLabel: {
      color: `#000000`,
      font: `normal 12px/16px Hind`,
      letterSpacing: 0.4,
      textAlign: `left`,
      opacity: 0.3,
    },
  });

const fieldsValidation = (name, event, setErrors, userData) => {
  let notValid = false;
  if (!!event && !!event.persist) {
    const value = !!event && !!event.persist && !!event.target && event.target.value;
    switch (true) {
      case name === `firstName` && value.length > 255:
        setErrors(prevState => ({ ...prevState, firstName: `Esse campo não pode ultrapassar 255 caracteres` }));
        notValid = true;
        break;
      case name === `lastName` && value.length > 255:
        setErrors(prevState => ({ ...prevState, lastName: `Esse campo não pode ultrapassar 255 caracteres` }));
        notValid = true;
        break;
      case name === `email` && value.length > 255:
        setErrors(prevState => ({ ...prevState, email: `Esse campo não pode ultrapassar 255 caracteres` }));
        notValid = true;
        break;
      case name === `cpf` && !validateCpf(value):
        setErrors(prevState => ({ ...prevState, cpf: `Favor digitar um CPF válido.` }));
        notValid = true;
        break;
      default:
        setErrors(prevState => ({ ...prevState, [name]: undefined }));
        notValid = false;
        break;
    }
  } else if (!event) {
    if (
      name !== `histories` &&
      (!userData[name] || [``, null, undefined].indexOf(userData[name]) > -1 || userData[name].length === 0)
    ) {
      setErrors(prevState => ({ ...prevState, [name]: `Esse campo é obrigatório.` }));
      notValid = true;
    }
  } else {
    setErrors(prevState => ({ ...prevState, [name]: undefined }));
    notValid = false;
  }
  return notValid;
};

export { fieldsValidation, initState, useStyles };
