import React from 'react';
/**
 * Redux dependencies
 */
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
/**
 * Material UI - Core
 */
import { Hidden, withStyles } from '@material-ui/core';
/**
 * MUI-Datatable
 */
import MUIDataTable from 'mui-datatables';
/**
 * Sectors actions
 */
import * as SectorActions from '../../ReduxFlow/Reducers/Sectors/Actions';
/**
 * My components
 */
import CustomToolbar from '../../Components/CustomToolbar';
import DialogSectorForm from './DialogSectorForm';
import MobileSector from './MobileSector';

const styles = theme => ({
  smRecipient: {
    height: `calc(100vh - 56px)`,
    overflowY: `auto`,
  },
});

class Sectors extends React.PureComponent {
  handleScroll = length => event => {
    event.stopPropagation();
    const { changeSectorsRangeRequest, Sectors: propsSectors } = this.props;
    if (
      event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight &&
      propsSectors.range <= propsSectors.data.length
    ) {
      changeSectorsRangeRequest();
    }
  };

  render() {
    const {
      classes,
      closeSectorsRequest,
      filterValue,
      loggedUserInfo,
      openSectorsRequest,
      Sectors: propsSectors,
    } = this.props;
    const { isSuperAdmin } = loggedUserInfo.user;
    let data = propsSectors.data.map(sector => {
      return [sector.id, sector.name, sector.workSchedulleEnabled ? `Configurado` : `Não configurado`, sector.id];
    });
    if (filterValue.value) {
      data = data.filter(val => JSON.stringify(val).includes(filterValue.value));
    }
    const columns = [
      {
        label: `Id`,
      },
      {
        label: `Nome`,
      },
      {
        label: `Horário de trabalho`,
      },
      {
        label: `Id`,
        options: {
          display: `excluded`,
          filter: false,
        },
      },
    ];
    const options = {
      rowsPerPage: 100,
      viewColumns: false,
      print: false,
      fixedHeader: true,
      selectableRows: `none`,
      filter: false,
      responsive: `stacked`,
      customToolbar: () => {
        if (isSuperAdmin) return <CustomToolbar tooltip="Adicionar setor" handleModalState={openSectorsRequest} />;
        return <></>;
      },
      textLabels: {
        body: {
          noMatch: `Desculpe, nenhum registro encontrado!`,
          toolTip: `Ordenar`,
        },
        pagination: {
          next: `Próxima página`,
          previous: `Págima anterior`,
          rowsPerPage: `Linhas por página:`,
          displayRows: `de`,
        },
        toolbar: {
          search: `Procurar`,
          downloadCsv: `Download CSV`,
          print: `Imprimir`,
          viewColumns: `Colunas visíveis`,
          filterTable: `Filtrar a tabela`,
        },
        filter: {
          title: `Filtros`,
          reset: `Resetar`,
        },
        viewColumns: {
          title: `Mostrar colunas`,
          titleAria: `Mostrar/Ocultar Colunas da Tabela`,
        },
        selectedRows: {
          text: `linha(s) selecionada(s)`,
          delete: `Deletar`,
          deleteAria: `Deletear linha(s) selecionada(s)`,
        },
      },
      onRowClick: rowData => {
        openSectorsRequest({ id: rowData[3] });
      },
    };
    return (
      <>
        <Hidden only={[`md`, `lg`, `xl`]}>
          <div className={classes.smRecipient} onScroll={this.handleScroll(data.length)}>
            <MobileSector
              data={data.slice(0, propsSectors.range)}
              isLoading={propsSectors.isLoading}
              handleModalState={openSectorsRequest}
            />
          </div>
          <DialogSectorForm
            isSaving={propsSectors.isSaving}
            formErrors={propsSectors.formErrors}
            fullscreen
            open={propsSectors.modalOpen}
            handleClick={closeSectorsRequest}
            formInstance={propsSectors.formInstance}
          />
        </Hidden>
        <Hidden only={[`xs`, `sm`]}>
          <MUIDataTable title="Setores" data={data} columns={columns} options={options} />
          <DialogSectorForm
            isSaving={propsSectors.isSaving}
            formErrors={propsSectors.formErrors}
            open={propsSectors.modalOpen}
            handleClick={closeSectorsRequest}
            formInstance={propsSectors.formInstance}
          />
        </Hidden>
      </>
    );
  }
}

const mapStateToProps = state => ({
  loggedUserInfo: state.Auth.data,
  Sectors: state.Sectors,
});

const mapDispatchToProps = dispatch => bindActionCreators(SectorActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(Sectors));
