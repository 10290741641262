import { all, call, put } from 'redux-saga/effects';
import { Action } from 'redux';
import { SagaIterator } from 'redux-saga';
import { Dialog } from 'muibox';

/**
 * API from axios
 */
import api from '../../../Services/api';

import {
  addOrUpdateFailure,
  addSuccess,
  closeOmbudsmanSourceRequest,
  closeOmbudsmanSourceSuccess,
  loadRequest,
  loadSuccess,
  openOmbudsmanSourceSuccess,
  updateSuccess,
} from './Actions';
import { OmbudsmanSourcesTypes } from './Types';

interface LoadActionType extends Action {
  payload: {
    dialog: Dialog;
    query?: object;
  };
}

interface OpenOrCloseDialogType extends Action {
  payload: {
    dialog: Dialog;
    id?: number | string;
  };
}

interface AddOrUpdateActionType extends Action {
  payload: {
    dialog: Dialog;
    id?: number | string;
    postData: {
      description?: string;
      id: number | string;
      name: string;
    };
  };
}

export function* loadOmbudsmanSources(action: LoadActionType): SagaIterator {
  try {
    const params = {
      params: { ...action.payload.query },
    };
    const response = yield call(api.get, `/tasks/ombudsman-sources/`, params);
    yield put(loadSuccess(response.data));
  } catch (error) {
    const { dialog } = action.payload;
    dialog.alert(`Não foi possível connectar com o servidor!`);
    yield put({ type: OmbudsmanSourcesTypes.LOAD_ERRORS });
  }
}

export function* openOmbudsmanSourcesDialog(action: OpenOrCloseDialogType): SagaIterator {
  try {
    if (action.payload.id) {
      const response = yield call(api.get, `/tasks/ombudsman-sources/${action.payload.id}/`);
      yield put(openOmbudsmanSourceSuccess(response.data));
    } else {
      yield put(openOmbudsmanSourceSuccess());
    }
  } catch (error) {
    const { dialog } = action.payload;
    dialog.alert(
      `Não foi possível realizar essa ação, por favor realize um refresh no navegador e caso o problema não seja resolvido entre em contato com nosso suporte!`,
    );
    yield put({ type: OmbudsmanSourcesTypes.LOAD_ERRORS });
  }
}

export function* closeOmbudsmanSourcesDialog(action: OpenOrCloseDialogType): SagaIterator {
  try {
    yield put(closeOmbudsmanSourceSuccess());
  } catch (error) {
    const { dialog } = action.payload;
    dialog.alert(
      `Não foi possível realizar essa ação, por favor realize um refresh no navegador e caso o problema não seja resolvido entre em contato com nosso suporte!`,
    );
  }
}

export function* addOrUpdateOmbudsmanSources(action: AddOrUpdateActionType): SagaIterator {
  const { dialog, postData } = action.payload;
  try {
    if (postData.id) {
      const response = yield call(api.patch, `/tasks/ombudsman-sources/${postData.id}/`, postData);
      yield all([put(updateSuccess(response.data)), put(loadRequest(dialog))]);
    } else {
      const response = yield call(api.post, `/tasks/ombudsman-sources/`, postData);
      yield all([put(addSuccess(response.data)), put(loadRequest(dialog))]);
    }
    yield put(closeOmbudsmanSourceRequest());
  } catch (error) {
    dialog.alert(
      `Não foi possível realizar essa ação, por favor realize um refresh no navegador e caso o problema não seja resolvido entre em contato com nosso suporte!`,
    );
    yield put(addOrUpdateFailure(error.response.data));
  }
}
