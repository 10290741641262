import React, { ChangeEvent, ReactNode, useRef, useState } from 'react';
import { TextField, TextFieldProps } from '@material-ui/core';
import { Autocomplete, AutocompleteProps, UseAutocompleteSingleProps } from '@material-ui/lab';
import { AxiosResponse } from 'axios';
import { Pagination } from '../../ReduxFlow/Reducers/types';
import api from '../../Services/api';

type AutocompleteOnDemandProps<T> = AutocompleteProps<T> &
  UseAutocompleteSingleProps<T> & {
    adittionalParams: {
      [key: string]: string;
    };
    fetchUrl: string;
    getOptionLabel: (option: T, options: T[]) => string;
    selectedOnFormInstance?: T;
    startSearchText: ReactNode;
    textFieldProps: TextFieldProps;
  };

type PaginationWithResults<T> = Pagination & {
  results: T[];
};

function AutocompleteOnDemand<T>({
  adittionalParams,
  disabled,
  fetchUrl,
  filterOptions,
  getOptionLabel,
  getOptionSelected,
  id,
  loadingText,
  noOptionsText,
  onChange,
  renderOption,
  selectedOnFormInstance,
  startSearchText,
  style,
  textFieldProps,
  value,
}: AutocompleteOnDemandProps<T>): JSX.Element {
  const debounceApi = useRef<number>(0);
  const textFieldRef = useRef<HTMLInputElement>(null);
  const [options, setOptions] = useState<T[]>(selectedOnFormInstance ? [selectedOnFormInstance] : []);
  const [loading, setLoading] = useState<boolean>(false);

  function getNoOptionsText(): ReactNode {
    if (noOptionsText) return noOptionsText;
    if (!loading && textFieldRef.current && !textFieldRef.current.value) return startSearchText;
    return `Nenhum item encontrado.`;
  }

  return (
    <Autocomplete
      disabled={disabled}
      filterOptions={filterOptions}
      filterSelectedOptions
      getOptionLabel={(option: T): string => getOptionLabel(option, options)}
      getOptionSelected={getOptionSelected}
      id={id}
      loading={loading}
      loadingText={loadingText}
      noOptionsText={getNoOptionsText()}
      onChange={onChange}
      onInputChange={(event: ChangeEvent<{}>, inputValue: string, reason: `input` | `reset` | `clear`): void => {
        if (reason === `input`) {
          setLoading(true);
          clearTimeout(debounceApi.current);
          debounceApi.current = window.setTimeout(async () => {
            const response: AxiosResponse<PaginationWithResults<T>> = await api.get(fetchUrl, {
              params: {
                ...adittionalParams,
                globalSearch: inputValue,
              },
            });
            setOptions(response.data.results);
            setLoading(false);
          }, 1000);
        }
      }}
      options={options}
      renderInput={({ InputLabelProps, inputProps, InputProps }): ReactNode => {
        const {
          error,
          helperText,
          InputLabelProps: customInputLabelProps,
          InputProps: customInputProps,
          label,
          margin,
          name,
          variant,
        } = textFieldProps;
        const newInputProps = {
          ...InputProps,
          ...customInputProps,
          inputRef: textFieldRef,
          inputProps,
        };
        const newInputLabelProps = {
          ...InputLabelProps,
          ...customInputLabelProps,
        };
        return (
          // @ts-ignore
          <TextField
            disabled={disabled}
            error={error}
            fullWidth
            helperText={helperText}
            id={id}
            InputLabelProps={newInputLabelProps}
            InputProps={newInputProps}
            label={label}
            margin={margin || `none`}
            name={name}
            variant={variant}
          />
        );
      }}
      renderOption={renderOption}
      style={style}
      value={value}
    />
  );
}

export default AutocompleteOnDemand;
