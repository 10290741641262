import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
/**
 * React Select
 */
import Select from 'react-select';
/**
 * Material UI - ColorManipulator
 */
import { emphasize } from '@material-ui/core/styles/colorManipulator';
/**
 * Material UI - Core
 */
import { Chip, MenuItem, NoSsr, Paper, TextField, Typography, withStyles } from '@material-ui/core';
/**
 * Material UI - Icons
 */
import { Cancel } from '@material-ui/icons';

const styles = theme => ({
  root: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    flexGrow: 1,
    minHeight: 50,
    width: `98%`,
  },
  input: {
    display: `flex`,
    padding: 0,
    height: `auto`,
  },
  valueContainer: {
    display: `flex`,
    flexWrap: `wrap`,
    flex: 1,
    alignItems: `center`,
    overflow: `hidden`,
    marginLeft: theme.spacing(2),
    minHeight: 56,
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === `light` ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  singleValue: {
    fontSize: 14,
  },
  placeholder: {
    fontSize: 14,
    left: theme.spacing(2),
    position: `absolute`,
  },
  paperDown: {
    marginTop: theme.spacing(1),
    zIndex: 3,
    position: `absolute`,
    width: `100%`,
  },
  paperUp: {
    marginTop: theme.spacing(1),
    zIndex: 3,
    position: `absolute`,
    width: `100%`,
    bottom: `100%`,
    top: `auto`,
  },
  divider: {
    height: theme.spacing(2),
  },
});

function NoOptionsMessage({ innerProps, selectProps }) {
  return (
    <Typography color="textSecondary" className={selectProps.classes.noOptionsMessage} {...innerProps}>
      Sem opções
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control({ children, innerProps, innerRef, selectProps }) {
  return (
    <>
      <TextField
        fullWidth
        InputProps={{
          inputComponent,
          inputProps: {
            className: selectProps.classes.input,
            inputRef: innerRef,
            children,
            ...innerProps,
          },
        }}
        margin="normal"
        variant="outlined"
        {...selectProps.textFieldProps}
      />
    </>
  );
}

function Option({ children, innerProps, innerRef, isDisabled, isFocused, isSelected }) {
  return (
    <MenuItem
      buttonRef={innerRef}
      selected={isFocused}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
        color: isDisabled ? `#d3d3d3` : null,
      }}
      {...innerProps}
    >
      {children}
    </MenuItem>
  );
}

function Placeholder({ children, innerProps, selectProps }) {
  return (
    <Typography color="textSecondary" className={selectProps.classes.placeholder} {...innerProps}>
      {children}
    </Typography>
  );
}

function SingleValue({ children, innerProps, selectProps }) {
  return (
    <Typography className={selectProps.classes.singleValue} {...innerProps}>
      {children}
    </Typography>
  );
}

function ValueContainer({ children, selectProps }) {
  return <div className={selectProps.classes.valueContainer}>{children}</div>;
}

function MultiValue({ children, chipFocused, isFocused, selectProps, removeProps }) {
  return (
    <Chip
      tabIndex={-1}
      label={children}
      className={classNames(selectProps.classes.chip, {
        [selectProps.classes.chipFocused]: isFocused,
      })}
      onDelete={removeProps.onClick}
      deleteIcon={<Cancel {...removeProps} />}
    />
  );
}

function Menu({ children, innerProps, selectProps }) {
  return (
    <Paper
      square
      className={selectProps.openUp ? selectProps.classes.paperUp : selectProps.classes.paperDown}
      {...innerProps}
    >
      {children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

const NewAutoComplete = ({
  disabled,
  error,
  single,
  multiple,
  name,
  placeholder,
  label,
  autofocus,
  classes,
  theme,
  suggestions,
  handleChange,
  onClose,
  openUp,
  helperText,
  filterOption,
  OptionComponent,
  isClearable,
}) => {
  const hasData = suggestions && suggestions.length > 0;
  const singleValue = hasData && single && suggestions.filter(val => val.value === single)[0];
  const multipleValue = hasData && multiple ? suggestions.filter(val => multiple.some(id => id === val.value)) : null;
  const [sortedOptions, setSortedOptions] = useState([]);
  useEffect(
    () =>
      setSortedOptions(
        suggestions ? suggestions.filter(v => v.active !== false).sort((a, b) => a.label.localeCompare(b.label)) : [],
      ),
    [suggestions],
  );

  const selectStyles = {
    input: base => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: `inherit`,
        width: `100%`,
      },
    }),
  };

  function handleKeyUp(event) {
    event.stopPropagation();
  }

  function handleKeyDown(event) {
    event.stopPropagation();
  }

  const selectcomponents = components;
  if (OptionComponent) selectcomponents.Option = OptionComponent;
  return (
    <div className={classes.root}>
      <NoSsr>
        {single !== undefined && (
          <Select
            isClearable={isClearable}
            name={name}
            classes={classes}
            styles={selectStyles}
            options={sortedOptions}
            components={selectcomponents}
            value={singleValue}
            textFieldProps={{
              label,
              InputLabelProps: {
                shrink: true,
              },
              helperText: error ? `Esse campo é obrigatório!` : helperText,
              error,
              disabled,
            }}
            onChange={handleChange}
            openUp={openUp}
            placeholder={placeholder}
            onBlur={onClose}
            autoFocus={autofocus}
            isDisabled={disabled}
            onKeyUp={handleKeyUp}
            onKeyDown={handleKeyDown}
            filterOption={filterOption || undefined}
          />
        )}
        {multiple !== undefined && (
          <Select
            name={name}
            classes={classes}
            styles={selectStyles}
            textFieldProps={{
              label,
              InputLabelProps: {
                shrink: true,
              },
              helperText: error ? `Esse campo é obrigatório!` : helperText,
              error,
            }}
            options={sortedOptions}
            components={components}
            value={multipleValue}
            onChange={handleChange}
            openUp={openUp}
            placeholder={placeholder}
            onBlur={onClose}
            autoFocus={autofocus}
            isDisabled={disabled}
            isMulti
            onKeyUp={handleKeyUp}
            onKeyDown={handleKeyDown}
          />
        )}
      </NoSsr>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(NewAutoComplete);
