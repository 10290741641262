import React from 'react';

// Material UI
import {
  CircularProgress,
  Fab,
  Fade,
  Grid,
  Paper,
  Slide,
  Typography,
  withStyles,
} from '@material-ui/core';

// Material UI Icons
import { Add } from '@material-ui/icons';

const styles = theme => ({
  avatar: {
    margin: 10,
    width: 50,
    height: 50,
  },
  userText: {
    wordWrap: `break-word`,
  },
  userWrapper: {
    margin: `5px auto 5px auto`,
    maxWidth: `98%`,
    minHeight: 65,
  },
  fab: {
    position: `absolute`,
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  loaderWrapper: {
    width: `98%`,
    textAlign: `center`,
  },
});

const MobileSector = ({ data, classes, isLoading, handleModalState }) => {
  return (
    <>
      {data.map(value => (
        <Slide key={value[0]} in direction="up" mountOnEnter unmountOnExit>
          <Paper
            className={classes.userWrapper}
            onClick={() => handleModalState({ id: value[0] })}
            style={{ display: `flex`, justifyContent: `center` }}
          >
            <Grid container alignItems="center" alignContent="center" justify="center">
              <Grid item xs={3}>
                <Typography variant="h6" align="center" color="textSecondary">
                  {`#${value[0]}`}
                </Typography>
              </Grid>
              <Grid item xs={9} className={classes.userText}>
                <Typography>{value[1]}</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Slide>
      ))}
      {isLoading && (
        <div className={classes.loaderWrapper}>
          <Fade
            in={isLoading}
            style={{
              transitionDelay: isLoading ? `800ms` : `0ms`,
            }}
            unmountOnExit
          >
            <CircularProgress />
          </Fade>
        </div>
      )}
      <Fab className={classes.fab} color="primary" onClick={handleModalState}>
        <Add />
      </Fab>
    </>
  );
};

export default withStyles(styles)(MobileSector);
