import React from 'react';
/**
 * Infinite calendar dependencies
 */
import InfiniteCalendar, { withRange, Calendar } from 'react-infinite-calendar';
/**
 * Material UI - Core
 */
import { FormLabel, FormGroup, Grid, Menu, MenuItem, TextField } from '@material-ui/core';

interface Props {
  startDate?: Date;
  endDate?: Date;
  label: string;
  handleSelect: (data: { start: Date; end: Date; eventType?: number }) => void;
}

interface State {
  anchorEl: Element | null;
  calendar: boolean;
}

const App: React.FunctionComponent<Props> = ({ startDate, endDate, handleSelect, label }) => {
  const [display, setDisplay] = React.useState<State>({ anchorEl: null, calendar: false });
  return (
    <>
      <FormLabel>{label}</FormLabel>
      <FormGroup row>
        <TextField
          value={startDate && endDate ? `${new Date(startDate).toLocaleDateString(`pt-br`)}` : undefined}
          onClick={(event: React.MouseEvent<HTMLInputElement>): void =>
            setDisplay({ ...display, anchorEl: event.currentTarget })
          }
          aria-controls="simple-menu"
          aria-haspopup="true"
          InputLabelProps={{
            shrink: true,
          }}
          style={{
            width: `45%`,
            marginRight: `5%`,
          }}
        />
        <TextField
          value={startDate && endDate ? `${new Date(endDate).toLocaleDateString(`pt-br`)}` : undefined}
          onClick={(event: React.MouseEvent<HTMLInputElement>): void =>
            setDisplay({ ...display, anchorEl: event.currentTarget })
          }
          aria-controls="simple-menu"
          aria-haspopup="true"
          InputLabelProps={{
            shrink: true,
          }}
          style={{
            width: `45%`,
            marginLeft: `5%`,
          }}
        />
      </FormGroup>
      <Menu
        id="simple-menu"
        anchorEl={display.anchorEl}
        open={Boolean(display.anchorEl)}
        onClose={(): void => setDisplay({ calendar: false, anchorEl: null })}
      >
        <Grid container>
          <Grid item xs={display.calendar ? 3 : 12}>
            <MenuItem
              onClick={(): void => {
                handleSelect({ start: new Date(), end: new Date(), eventType: 3 });
                setDisplay({ ...display, anchorEl: null });
              }}
            >
              Hoje
            </MenuItem>
            <MenuItem
              onClick={(): void => {
                const start = new Date();
                start.setDate(start.getDate() - 1);
                handleSelect({ start, end: start, eventType: 3 });
                setDisplay({ ...display, anchorEl: null });
              }}
            >
              Ontem
            </MenuItem>
            <MenuItem
              onClick={(): void => {
                const start = new Date();
                const end = new Date();
                start.setDate(start.getDate() - 6);
                handleSelect({ start, end, eventType: 3 });
                setDisplay({ ...display, anchorEl: null });
              }}
            >
              Últimos 7 dias
            </MenuItem>
            <MenuItem
              onClick={(): void => {
                const start = new Date();
                const end = new Date();
                start.setDate(start.getDate() - 29);
                handleSelect({ start, end, eventType: 3 });
                setDisplay({ ...display, anchorEl: null });
              }}
            >
              Últimos 30 dias
            </MenuItem>
            <MenuItem
              onClick={(): void => {
                const today = new Date();
                const start = new Date(today.getFullYear(), today.getMonth(), 1, 0, 0, 0);
                const end = new Date(today.getFullYear(), today.getMonth() + 1, 0, 0, 0, 0);
                handleSelect({ start, end, eventType: 3 });
                setDisplay({ ...display, anchorEl: null });
              }}
            >
              Mês atual
            </MenuItem>
            <MenuItem
              onClick={(): void => {
                const today = new Date();
                const start = new Date(today.getFullYear(), today.getMonth() - 1, 1, 0, 0, 0);
                const end = new Date(today.getFullYear(), today.getMonth(), 0, 0, 0, 0);
                handleSelect({ start, end, eventType: 3 });
                setDisplay({ ...display, anchorEl: null });
              }}
            >
              Mês passado
            </MenuItem>
            <MenuItem onClick={(): void => setDisplay({ ...display, calendar: !display.calendar })}>Outro</MenuItem>
          </Grid>
          {display.calendar && (
            <Grid item xs={9}>
              <Grid container alignItems="center" alignContent="center" justify="center">
                <InfiniteCalendar
                  theme={{
                    accentColor: `rgb(75, 194, 200)`,
                    floatingNav: {
                      background: `#fff`,
                      color: `#fff`,
                      chevron: `rgb(75, 194, 200)`,
                    },
                    headerColor: `rgb(75, 194, 200)`,
                    selectionColor: `rgb(75, 194, 200)`,
                    textColor: {
                      default: `#333`,
                      active: `#fff`,
                    },
                    todayColor: `rgb(75, 194, 200)`,
                    weekdayColor: `rgb(75, 194, 200)`,
                  }}
                  locale={{
                    locale: require(`date-fns/locale/pt`),// eslint-disable-line
                    headerFormat: `dddd, D MMM`,
                    weekdays: [`Dom`, `Seg`, `Ter`, `Qua`, `Qui`, `Sex`, `Sáb`],
                    blank: `Nenhuma data selecionada`,
                    todayLabel: {
                      long: `Hoje`,
                    },
                  }}
                  Component={withRange(Calendar)}
                  height={280}
                  selected={{
                    start: startDate || new Date(),
                    end: endDate || new Date(),
                  }}
                  displayOptions={{
                    showHeader: false,
                  }}
                  onSelect={(event: { start: Date; end: Date; eventType: number }): void => {
                    if (event.eventType === 3) {
                      handleSelect(event);
                      setDisplay({ calendar: false, anchorEl: null });
                    }
                  }}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Menu>
    </>
  );
};

export default App;
