import React from 'react';
/**
 * Material UI - Core
 */
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  withStyles,
} from '@material-ui/core';
/**
 * Material UI - Icons
 */
import { Close } from '@material-ui/icons';
/**
 * My components
 */
import SectorForm from './SectorForm';

const styles = theme => ({
  dialogTitle: {
    display: `flex`,
    flexFlow: `row wrap`,
    alignConent: `center`,
    justifyContent: `flex-end`,
    width: `100%`,
    padding: 0,
  },
});

const DialogSectorForm = ({ isSaving, formErrors, open, formInstance, handleClick, fullscreen, classes }) => (
  <>
    <Dialog
      fullScreen={fullscreen}
      open={open}
      onClose={handleClick}
      aria-labelledby="addSectorForm"
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <Tooltip title="Fechar">
          <IconButton aria-label="Close" onClick={handleClick}>
            <Close />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent>
        <SectorForm formErrors={formErrors} formInstance={formInstance} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClick} color="secondary" style={{ marginRight: 5 }}>
          Cancelar
        </Button>
        <Button form="sectorForm" type="submit" color="primary" variant="contained" disabled={isSaving}>
          Salvar {isSaving && <CircularProgress size={20} />}
        </Button>
      </DialogActions>
    </Dialog>
  </>
);

export default withStyles(styles)(DialogSectorForm);
