import React, { SFC, useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, IconButton, Typography } from '@material-ui/core';
import { Delete as DeleteIcon, Edit as EditIcon, ExpandMore } from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { CompoundCategory } from '../../../ReduxFlow/Reducers/types';
import { Column } from '../SimpleCategoriesTable/types';
import SimpleCategoriesTable from '../SimpleCategoriesTable';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    categoryCell: {
      maxHeight: `40px`,
    },
    inactiveCategoryAction: {
      background: `rgba(245, 245, 245, 0.4)`,
      direction: `rtl`,
    },
    inactiveCategoryCell: {
      background: `rgba(245, 245, 245, 0.4)`,
      maxHeight: `40px`,
      opacity: 0.4,
    },
    inactiveCategoryPanel: {
      background: `rgba(245, 245, 245, 0.4)`,
      opacity: 0.4,
    },
    inactiveCategory: {
      background: `rgba(245, 245, 245, 0.4)`,
    },
    invisibleButton: {
      backgroundColor: `transparent`,
      color: `#fff`,
      '&:hover': {
        backgroundColor: `transparent`,
        borderColor: `transparent`,
        '@media (hover: none)': {
          backgroundColor: `transparent`,
        },
      },
      cursor: `default`,
      maxHeight: `1px`,
    },
    ltr: {
      direction: `rtl`,
    },
    subCategoryPanel: {
      borderLeft: `24px solid #f5f5f5`,
      borderRight: `24px solid #f5f5f5`,
      borderTop: `8px solid #f5f5f5`,
      borderBottom: `24px solid #f5f5f5`,
      marginBottom: `-24px`,
      marginLeft: `-24px`,
      marginRight: `-24px`,
    },
    subCategoryTitle: {
      backgroundColor: `#f5f5f5`,
    },
  }),
);

export type AdditionalFields = {
  label: string;
  content: Function;
};

type CompoundCategoriesPanelProps = {
  additionalFields?: AdditionalFields[];
  addSubCategoryCallback?: Function;
  categories: CompoundCategory[];
  customSubcategoryColumns?: Column[];
  deleteCallback?: Function;
  deleteSubCategoryCallback?: Function;
  editCallback?: Function;
  editSubCategoryCallback?: Function;
  isInactiveCategoryTable?: boolean;
  isLoadingUI: boolean;
  loadingData: boolean;
  reactivateCallback?: Function;
  reactivateSubCatecoryCallback?: Function;
  showInactive?: boolean;
};

const CompoundCategoriesPanel: SFC<CompoundCategoriesPanelProps> = ({
  additionalFields,
  addSubCategoryCallback,
  categories,
  customSubcategoryColumns,
  deleteCallback,
  deleteSubCategoryCallback,
  editCallback,
  editSubCategoryCallback,
  isInactiveCategoryTable,
  isLoadingUI,
  loadingData,
  reactivateCallback,
  reactivateSubCatecoryCallback,
  showInactive,
}) => {
  const [data, setData] = useState<CompoundCategory[]>(categories);
  const [selectedCategory, setSelectedCategory] = useState<CompoundCategory>();
  const classes = useStyles();
  const hasData = !!categories.length;
  const canDisplayData = hasData && !isLoadingUI;
  const isActiveTable = isInactiveCategoryTable !== true;

  useEffect(() => {
    setData(categories);
  }, [categories]);

  const buildDetailLine = (field: AdditionalFields, cat: CompoundCategory): React.ReactNode => (
    <div style={{ display: `flex` }} key={`category-detail-${cat.id}-${field.label}`}>
      <Grid container>
        <Grid item xs={3} className={classes.categoryCell}>
          <Typography variant="subtitle2">{field.label}</Typography>
        </Grid>
        <Grid item xs={9}>
          {field.content(cat)}
        </Grid>
      </Grid>
      <IconButton edge="end" disableFocusRipple disableRipple className={classes.invisibleButton}>
        <EditIcon />
      </IconButton>
    </div>
  );

  const defaultFields: AdditionalFields[] = [
    {
      label: `Descrição da categoria`,
      content: (cat: CompoundCategory): string => cat.description || ``,
    },
    {
      label: `Criada em`,
      content: (cat: CompoundCategory): string =>
        cat.createdAt ? new Date(cat.createdAt).toLocaleDateString(`pt-br`) : ``,
    },
  ];
  const fields = defaultFields.concat(additionalFields || []);

  return (
    <>
      {canDisplayData &&
        data.map(cat => {
          const activeSubCategories = cat.subCategories ? cat.subCategories.filter(v => v.isActive) : [];
          const inactiveSubCategories = cat.subCategories ? cat.subCategories.filter(v => !v.isActive) : [];
          return (
            <Accordion
              key={`category-panel-${cat.id}`}
              onClick={(): void => setSelectedCategory(cat)}
              className={isActiveTable ? `` : classes.inactiveCategory}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Grid container alignItems="center">
                  <Grid item xs={3} className={isActiveTable ? classes.categoryCell : classes.inactiveCategoryCell}>
                    <Typography variant="subtitle2">Categoria</Typography>
                  </Grid>
                  <Grid item xs={7} className={isActiveTable ? classes.categoryCell : classes.inactiveCategoryCell}>
                    <Typography variant="body2">{cat.name}</Typography>
                  </Grid>
                  <Grid item xs={2} className={isActiveTable ? classes.ltr : classes.inactiveCategoryAction}>
                    {isActiveTable && (
                      <>
                        <IconButton onClick={editCallback && editCallback(cat)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton onClick={deleteCallback && deleteCallback(cat)}>
                          <DeleteIcon />
                        </IconButton>
                      </>
                    )}
                    {!isActiveTable && (
                      <Button
                        color="primary"
                        onClick={(event: React.MouseEvent): void => {
                          event.stopPropagation();
                          if (reactivateCallback) reactivateCallback(cat.id);
                        }}
                      >
                        reativar categoria
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails className={isActiveTable ? `` : classes.inactiveCategoryPanel}>
                <Grid container direction="column">
                  {fields.map(f => buildDetailLine(f, cat))}
                  <div className={classes.subCategoryPanel}>
                    <Typography className={classes.subCategoryTitle}>Subcategorias</Typography>
                    <SimpleCategoriesTable
                      addCallback={(): void =>
                        addSubCategoryCallback && addSubCategoryCallback({ category: selectedCategory })
                      }
                      addLabel="Criar Subcategoria"
                      customColumns={customSubcategoryColumns}
                      data={activeSubCategories}
                      deleteCallback={(id: number): void => {
                        if (deleteSubCategoryCallback) {
                          const subcategory = activeSubCategories.find(v => v.id === id);
                          deleteSubCategoryCallback(selectedCategory, subcategory);
                        }
                      }}
                      editCallback={(id: number): void => {
                        if (editSubCategoryCallback) {
                          const subcategory = activeSubCategories.find(v => v.id === id);
                          editSubCategoryCallback(selectedCategory, subcategory);
                        }
                      }}
                      hideButtons={!isActiveTable}
                      isLoadingUI={isLoadingUI}
                      keyName="id"
                      loadingData={loadingData}
                    />
                  </div>
                  {showInactive && !!inactiveSubCategories.length && (
                    <div className={classes.subCategoryPanel}>
                      <Typography className={classes.subCategoryTitle}>Subcategorias desativadas</Typography>
                      <SimpleCategoriesTable
                        data={inactiveSubCategories}
                        hideButtons={!isActiveTable}
                        isInactiveCategoryTable
                        isLoadingUI={isLoadingUI}
                        keyName="id"
                        loadingData={loadingData}
                        reactivateCallback={(id: number): void => {
                          if (reactivateSubCatecoryCallback) {
                            const subcategory = inactiveSubCategories.find(v => v.id === id);
                            reactivateSubCatecoryCallback(selectedCategory, subcategory);
                          }
                        }}
                      />
                    </div>
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
          );
        })}
    </>
  );
};

export default CompoundCategoriesPanel;
