import { action, ActionType } from 'typesafe-actions';
import { Dialog } from 'muibox';
import { SlaKpis } from '../types';
import { Ombudsman, OmbudsmanInstance, OmbudsmansState, OmbudsmansTypes } from './Types';

export type SagaFormInstance = Omit<OmbudsmanInstance, 'id'> & { // eslint-disable-line
  id?: number;
};

export const loadOmbudsmanReportRequest = (dialog: Dialog): ActionType<typeof action> =>
  action(OmbudsmansTypes.REPORT_REQUEST, { dialog });
export const loadOmbudsmanReportSuccessfull = (): ActionType<typeof action> =>
  action(OmbudsmansTypes.REPORT_SUCCESSFULL);
export const loadOmbudsmansRequest = (dialog: Dialog): ActionType<typeof action> =>
  action(OmbudsmansTypes.LOAD_REQUEST, { dialog });
export const loadOmbudsmansSuccess = (data: {
  count: number;
  next?: string;
  previous?: string;
  results: Ombudsman[];
}): ActionType<typeof action> => action(OmbudsmansTypes.LOAD_SUCCESS, data);
export const openOmbudsmanRequest = (data: { id?: number | string; dialog: Dialog }): ActionType<typeof action> =>
  action(OmbudsmansTypes.OPEN_REQUEST, data);
export const openOmbudsmansSuccess = (data?: OmbudsmanInstance): ActionType<typeof action> =>
  action(OmbudsmansTypes.OPEN_SUCCESS, data);
export const closeOmbudsmansRequest = (dialog: Dialog): ActionType<typeof action> =>
  action(OmbudsmansTypes.CLOSE_REQUEST, { dialog });
export const closeOmbudsmansSuccess = (): ActionType<typeof action> => action(OmbudsmansTypes.CLOSE_SUCCESS);
export const ombudsmanAddOrUpdateRequest = (data: {
  afterUpdateCallback?: Function;
  data: FormData;
  dialog: Dialog;
  id?: number;
}): ActionType<typeof action> => action(OmbudsmansTypes.ADD_OR_UPDATE_REQUEST, data);
export const ombudsmanAddSuccess = (data: OmbudsmanInstance): ActionType<typeof action> =>
  action(OmbudsmansTypes.ADD_SUCCESS, { data });
export const ombudsmanUpdateSuccess = (data: OmbudsmanInstance): ActionType<typeof action> =>
  action(OmbudsmansTypes.UPDATE_SUCCESS, { data });
export const changeOmbudsmansSort = (data?: {
  activeColumn: number;
  dialog: Dialog;
  sort: string;
}): ActionType<typeof action> => action(OmbudsmansTypes.CHANGE_SORT, data);
export const changeOmbudsmansFilters = (data: {
  dialog: Dialog;
  filterList?: OmbudsmansState['filterList']; // eslint-disable-line
  searchText: string;
}): ActionType<typeof action> => action(OmbudsmansTypes.CHANGE_FILTERS, data);
export const changeOmbudsmansPages = (data: {
  dialog: Dialog;
  rowsPerPage: number;
  page: number;
}): ActionType<typeof action> => action(OmbudsmansTypes.CHANGE_PAGES, data);
export const loadOmbudmansKpisSuccess = (data: SlaKpis): ActionType<typeof action> =>
  action(OmbudsmansTypes.LOAD_KPIS_SUSUCCESS, data);
export const ombudsmanFormErrors = (data: { [key: string]: string } | {}): ActionType<typeof action> =>
  action(OmbudsmansTypes.ADD_OR_UPDATE_ERROR, data);
