import { CommonTaskInstance, CommonTaskTable, Pagination, SlaKpis, TprAndTrType } from '../types';

/**
 * Action types
 */
export enum OmbudsmansTypes {
  REPORT_REQUEST = '@Ombudsmans/REPORT_REQUEST',   // eslint-disable-line
  REPORT_SUCCESSFULL = '@Ombudsmans/REPORT_SUCCESSFULL',   // eslint-disable-line
  LOAD_REQUEST = '@Ombudsmans/LOAD_REQUEST',   // eslint-disable-line
  LOAD_SUCCESS = '@Ombudsmans/LOAD_SUCCESS',   // eslint-disable-line
  LOAD_FAILURE = '@Ombudsmans/LOAD_FAILURE',   // eslint-disable-line
  OPEN_REQUEST = '@Ombudsmans/OPEN_REQUEST',   // eslint-disable-line
  OPEN_SUCCESS = '@Ombudsmans/OPEN_SUCCESS',   // eslint-disable-line
  CLOSE_REQUEST = '@Ombudsmans/CLOSE_REQUEST',   // eslint-disable-line
  CLOSE_SUCCESS = '@Ombudsmans/CLOSE_SUCCESS',   // eslint-disable-line
  ADD_OR_UPDATE_REQUEST = '@Ombudsmans/ADD_OR_UPDATE_REQUEST',   // eslint-disable-line
  ADD_SUCCESS = '@Ombudsmans/ADD_SUCCESS',   // eslint-disable-line
  UPDATE_SUCCESS = '@Ombudsmans/UPDATE_SUCCESS',   // eslint-disable-line
  ADD_OR_UPDATE_ERROR = '@Ombudsmans/ADD_OR_UPDATE_ERROR',   // eslint-disable-line
  CHANGE_SORT = '@Ombudsmans/CHANGE_SORT',   // eslint-disable-line
  CHANGE_FILTERS = '@Ombudsmans/CHANGE_FILTERS',   // eslint-disable-line
  CHANGE_PAGES = '@Ombudsmans/CHANGE_PAGES',   // eslint-disable-line
  LOAD_ERRORS = '@Ombudsmans/LOAD_ERRORS',   // eslint-disable-line
  LOAD_KPIS_SUSUCCESS = '@Ombudsmans/LOAD_KPIS_SUSUCCESS',   // eslint-disable-line
}
/**
 * Data types
 */
export type OmbudsmanInstance = CommonTaskInstance & {
  ombudsman: {
    id: number;
    ombSource?: string;
    source: number;
  };
};

export interface OmbudsmanError {
  crm?: string;
  description?: string;
  feedbackMood?: string;
  feedbackText?: string;
  institution?: string;
  ombudsman?: {
    source?: string;
  };
  priority?: string;
  requester?: string;
  requesterSector?: string;
  responsibleSector?: string;
  sector?: string;
  source?: string;
  title?: string;
}

export type Ombudsman = Omit<CommonTaskTable, 'equipment'> & { // eslint-disable-line
  tpr: TprAndTrType['tpr']; // eslint-disable-line
  tr: TprAndTrType['tr']; // eslint-disable-line
};
/**
 * State types
 */
export interface OmbudsmansState {
  readonly data: Ombudsman[];
  readonly dialogOpen: boolean;
  readonly filterList: { [key: string]: string[] };
  readonly formErrors?: OmbudsmanError;
  readonly formInstance?: OmbudsmanInstance;
  readonly isLoading: boolean;
  readonly isSaving: boolean;
  readonly orphans: number;
  readonly page: number;
  readonly pagination: Pagination;
  readonly rowsPerPage: number;
  readonly searchText?: string;
  readonly slaKpis?: SlaKpis;
  readonly sort?: { [key: number]: { order: string }; [key: string]: { order: string } };
}
