import React from 'react';
import MaskedInput from 'react-text-mask';
import { makeStyles } from '@material-ui/core/styles';

function EmailMask(props) {
  const { inputRef, ...other } = props;
  const fakeDomain = `@s.im`;

  return (
    <MaskedInput
      // eslint-disable-next-line
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={text => {
        if (!text.endsWith(fakeDomain)) text.replace(/@/g, ``).concat(fakeDomain);
        return text.split(``).map(c => /\S/);
      }}
      pipe={(text, config) => {
        let value = text.trim().toLocaleLowerCase();
        if (value.endsWith(fakeDomain)) return value;
        value = value.replace(/@/g, ``) + fakeDomain;
        const indexesOfPipedChars = fakeDomain.split(``).map((v, i) => value.length - i);
        return { value, indexesOfPipedChars };
      }}
      placeholderChar={`\u2000`}
    />
  );
}

const sexOptions = { M: `Masculino`, F: `Feminino`, O: `Outro` };

const useStyles = theme =>
  makeStyles({
    buttonText: {
      whiteSpace: `nowrap`,
    },
    checkboxContainer: {
      alignContent: `center`,
      alignItems: `center`,
      display: `flex`,
      justifyContent: `flex-start`,
    },
    customDisabled: {
      color: `inherit !important`,
    },
    oneField: {
      [theme.breakpoints.up(`sm`)]: {
        margin: `0px ${theme.spacing(1)}px ${theme.spacing(3)}px ${theme.spacing(1)}px`,
        width: `98%`,
      },
      [theme.breakpoints.down(`sm`)]: {
        margin: `0px ${theme.spacing(1)}px ${theme.spacing(4)}px ${theme.spacing(1)}px`,
        width: `98%`,
      },
    },
    oneFieldCheckbox: {
      [theme.breakpoints.up(`sm`)]: {
        margin: `0px ${theme.spacing(1)}px ${theme.spacing(3)}px ${theme.spacing(1)}px`,
        width: `98%`,
      },
      [theme.breakpoints.down(`sm`)]: {
        margin: `0px ${theme.spacing(1)}px ${theme.spacing(4)}px ${theme.spacing(1)}px`,
        width: `98%`,
      },
    },
    oneFieldFirst: {
      [theme.breakpoints.up(`sm`)]: {
        margin: `0px ${theme.spacing(1)}px ${theme.spacing(0)}px ${theme.spacing(1)}px`,
        width: `98%`,
      },
      [theme.breakpoints.down(`sm`)]: {
        margin: `0px ${theme.spacing(1)}px ${theme.spacing(0)}px ${theme.spacing(1)}px`,
        width: `98%`,
      },
    },
    textFieldInactivateDialog: {
      margin: `${theme.spacing(2.5)}px 0 0 0`,
      width: `100%`,
    },
    threeFields: {
      [theme.breakpoints.up(`sm`)]: {
        margin: `0px ${theme.spacing(1)}px ${theme.spacing(3)}px ${theme.spacing(1)}px`,
        width: `90%`,
      },
      [theme.breakpoints.down(`sm`)]: {
        margin: `0px ${theme.spacing(1)}px ${theme.spacing(4)}px ${theme.spacing(1)}px`,
        width: `98%`,
      },
    },
    twoFieldsCenter: {
      [theme.breakpoints.up(`sm`)]: {
        margin: `0px ${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(2)}px`,
        width: `92%`,
      },
      [theme.breakpoints.down(`sm`)]: {
        margin: `0px ${theme.spacing(1)}px ${theme.spacing(4)}px ${theme.spacing(1)}px`,
        width: `98%`,
      },
    },
    twoFieldsLeft: {
      [theme.breakpoints.up(`sm`)]: {
        margin: `0px ${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(1)}px`,
        width: `92%`,
      },
      [theme.breakpoints.down(`sm`)]: {
        margin: `0px ${theme.spacing(1)}px ${theme.spacing(4)}px ${theme.spacing(1)}px`,
        width: `98%`,
      },
    },
    twoFieldsRight: {
      [theme.breakpoints.up(`sm`)]: {
        margin: `0px ${theme.spacing(1)}px ${theme.spacing(3)}px ${theme.spacing(2)}px`,
        width: `92%`,
      },
      [theme.breakpoints.down(`sm`)]: {
        margin: `0px ${theme.spacing(1)}px ${theme.spacing(4)}px ${theme.spacing(1)}px`,
        width: `98%`,
      },
    },
    twoFieldsRightCheckbox: {
      [theme.breakpoints.up(`sm`)]: {
        margin: `0px ${theme.spacing(1)}px ${theme.spacing(3)}px ${theme.spacing(1)}px`,
        width: `92%`,
      },
      [theme.breakpoints.down(`sm`)]: {
        margin: `0px ${theme.spacing(1)}px ${theme.spacing(4)}px ${theme.spacing(1)}px`,
        width: `98%`,
      },
    },
  });

export { EmailMask, sexOptions, useStyles };
