import React, { ChangeEvent, SFC, useState } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  FormHelperText,
  Grid,
  Typography,
} from '@material-ui/core';
import { History } from '@material-ui/icons';
import { useDialog } from 'muibox';

import api from '../../../Services/api';

interface LegacyFilesUploadCardProps {
  classes: {
    input: string;
    cardHeaderAction: string;
    cardHeaderRoot: string;
    textField: string;
  };
  formInstance: {
    legacyArchive: string;
    id: number;
  };
  uploadCompleted: () => void;
}

const LegacyFilesUploadCard: SFC<LegacyFilesUploadCardProps> = ({ classes, formInstance, uploadCompleted }) => {
  const [fileError, setFileError] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const dialog = useDialog();

  const isValidFile = (file: File): boolean => {
    if (file.type) {
      return (
        [`application/zip`, `application/x-zip-compressed`, `multipart/x-zip`, `application/x-compressed`].indexOf(
          file.type,
        ) >= 0
      );
    }

    // falback to when the browser does not inform a file type
    const ext = file.name.slice((Math.max(0, file.name.lastIndexOf(`.`)) || Infinity) + 1).toLowerCase();
    return ext === `zip`;
  };

  const handleFileSubmit = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
    event.preventDefault();
    const file = event.target && event.target.files && event.target.files[0];
    if (!file || !isValidFile(file)) {
      setFileError(true);
      return;
    }

    setFileError(false);
    try {
      setIsSaving(true);
      const data = new FormData();
      data.set(`legacyArchive`, file);
      const response = await api.patch(`/internaldocs/documentinfo/${formInstance.id}/`, data);
      formInstance.legacyArchive = response.data.legacyArchive;
      uploadCompleted();
    } catch (e) {
      console.log(`Erro ao salvar arquivo legado de documento interno`, e);
      dialog.alert({
        title: `Erro`,
        message: `Não foi possível salvar este arquivo.`,
      })
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 15 }}>
      <Card elevation={0} style={{ boxShadow: `0px 1px 3px #00000033` }}>
        <CardHeader
          avatar={<History />}
          classes={{ action: classes.cardHeaderAction, root: classes.cardHeaderRoot }}
          title={
            <>
              <Typography display="inline" style={{ fontWeight: 400, marginRight: 16 }}>
                Armazene aqui as versões anteriores de seus documentos, quando não utilizava o SIM para gerenciá-los
              </Typography>
            </>
          }
        />
        <Divider />
        <CardContent>
          <Typography className={classes.textField} style={{ color: `#00000099` }}>
            <small>Compacte todas as versões em um arquivo .zip e faça upload abaixo</small>
          </Typography>
        </CardContent>
        <CardActions>
          <label htmlFor="id_legacy_attachment">
            <Button
              component="span"
              disabled={isSaving}
              color="primary"
              className={classes.textField}
              style={{ marginTop: 10 }}
            >
              Upload {isSaving && <CircularProgress size={20} />}
            </Button>
            <input
              className={classes.input}
              id="id_legacy_attachment"
              name="legacy_attachment"
              type="file"
              accept=".zip"
              onChange={handleFileSubmit}
              // hidden
              />
          </label>
            {fileError && (
              <FormHelperText className={classes.textField} error={fileError}>
                Apenas arquivos ZIP são suportados.
              </FormHelperText>
            )}
        </CardActions>
      </Card>
    </Grid>
  );
};

export default LegacyFilesUploadCard;
