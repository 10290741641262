import { Reducer } from 'redux';
import { Institution, InstitutionsState, InstitutionsTypes } from './Types';

export const INITIAL_STATE: InstitutionsState = {
  data: [],
  error: false,
  isLoading: false,
  isSaving: false,
  dialogOpen: false,
  formInstance: undefined,
  range: 10,
  formErrors: {},
};

const reducer: Reducer<InstitutionsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case InstitutionsTypes.LOAD_REQUEST:
      return { ...state, isLoading: true, error: false, isSaving: false, formErrors: {} };
    case InstitutionsTypes.LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        isSaving: false,
        data: action.payload.data,
      };
    case InstitutionsTypes.LOAD_FAILURE:
      return { ...state, isLoading: false, error: true, isSaving: false, data: [] };
    case InstitutionsTypes.ADD_OR_UPDATE_REQUEST:
      return { ...state, loading: false, error: false, isSaving: true };
    case InstitutionsTypes.ADD_OR_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        isSaving: false,
        errors: action.payload.errors,
      };
    case InstitutionsTypes.ADD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        isSaving: false,
        data: [action.payload.data, ...state.data],
      };
    case InstitutionsTypes.UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        isSaving: false,
        data: state.data.map((inst: Institution) => (inst.id === action.payload.data.id ? action.payload.data : inst)),
      };
    case InstitutionsTypes.OPEN_REQUEST:
      return { ...state, dialogOpen: true, isLoading: true, formErrors: {} };
    case InstitutionsTypes.OPEN_SUCCESS:
      return { ...state, formInstance: action.payload, isLoading: false };
    case InstitutionsTypes.CLOSE_REQUEST:
      return { ...state, isLoading: true, dialogOpen: false };
    case InstitutionsTypes.CLOSE_SUCCESS:
      return { ...state, dialogOpen: false, formInstance: undefined, isLoading: false, formErrors: {} };
    case InstitutionsTypes.RANGE_REQUEST:
      return { ...state, isLoading: true };
    case InstitutionsTypes.RANGE_SUCCESS:
      return { ...state, isLoading: false, range: state.range + 10 };
    default:
      return state;
  }
};

export default reducer;
