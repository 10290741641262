import React, { MouseEvent, ReactNode, SFC, UIEvent, useEffect, useState } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import MuiDatatable, { MUIDataTableColumn, MUIDataTableOptions, MUIDataTableState } from 'mui-datatables';
import { Avatar, Button, Hidden, LinearProgress, Menu, MenuItem, Tooltip, Typography } from '@material-ui/core';
import { Add as AddIcon, Clear as ClearIcon, Done as DoneIcon } from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useDialog } from 'muibox';
import * as UserActions from '../../ReduxFlow/Reducers/Users/Actions';
import { ApplicationState } from '../../ReduxFlow/Reducers';
import { AuthState } from '../../ReduxFlow/Reducers/Auth/Types';
import { Group } from '../../ReduxFlow/Reducers/Groups/Types';
import { Sector } from '../../ReduxFlow/Reducers/Sectors/Types';
import { Institution } from '../../ReduxFlow/Reducers/Institutions/Types';
import { UsersState } from '../../ReduxFlow/Reducers/Users/Types';
import MobileUsers from './MobileUsers';
import DialogUserForm from './DialogUserForm';
import DialogCsvImporter from './csvImporter';
import * as muiDatatableHelper from '../../Utils/muiDatatableHelper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    smRecipient: {
      height: `calc(100vh - 56px)`,
      overflowY: `auto`,
    },
    institutionAvatar: {
      width: 30,
      height: 30,
      transition: `transform .3s`,
      '&:hover': {
        transform: `scale(3)`,
      },
    },
    markerRoot: {
      fontSize: 12,
      height: 15,
    },
    markerChildren: {
      padding: `0 6px 0 6px`,
    },
  }),
);

type StoreProps = {
  groups: Group[];
  institutions: Institution[];
  loggedUserInfo: AuthState;
  sectors: Sector[];
  users: UsersState;
};

type DispatchProps = {
  changeUserFilters: typeof UserActions.changeUserFilters;
  changeUserPages: typeof UserActions.changeUserPages;
  changeUserSort: typeof UserActions.changeUserSort;
  userMobileInfiniteScrollRequest: typeof UserActions.userMobileInfiniteScrollRequest;
  loadRequest: typeof UserActions.loadRequest;
};

type UsersProps = {
  filterValue: {
    followers: boolean;
    responsible: boolean;
    status: boolean;
    type: string;
    value: string;
  };
  genericAvatar: string;
  handleFetchStatus: Function;
};

const Users: SFC<StoreProps & DispatchProps & UsersProps> = ({
  changeUserFilters,
  changeUserPages,
  changeUserSort,
  filterValue,
  genericAvatar,
  groups,
  handleFetchStatus,
  institutions,
  userMobileInfiniteScrollRequest,
  loadRequest,
  loggedUserInfo,
  sectors,
  users,
}) => {
  const classes = useStyles();
  const dialog = useDialog();
  const { data } = users;
  const { isLoadingUI } = loggedUserInfo;
  const { currentOrganization } = loggedUserInfo.data;
  const { isAdminOrSuperAdmin } = loggedUserInfo.data.user;
  const [addMenuSelector, setAddMenuSelector] = useState<HTMLButtonElement | undefined>(undefined);
  const [createOrUpdateUserDialogOpen, setCreateOrUpdateUserDialogOpen] = useState<boolean>(false);
  const [downloadingReport] = useState<boolean>(false);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [formInstanceId, setFormInstanceId] = useState<number | undefined>(undefined);
  const [userImportDialogOpen, setUserImportDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!firstLoad) {
      changeUserFilters({
        dialog,
        filterList: { isActive: [`Ativo`] },
        searchText: filterValue.value,
      });
    }
  }, [changeUserFilters, dialog, filterValue.value, firstLoad]);

  useEffect(() => {
    const localStorageOrg = window.localStorage.getItem(`currentOrganization`);
    if (localStorageOrg === JSON.stringify(currentOrganization) && !isLoadingUI && !data.length && firstLoad) {
      if (filterValue.status) {
        changeUserFilters({
          dialog,
          filterList: { isActive: [`Ativo`] },
          page: 0,
          searchText: filterValue.value,
        });
        handleFetchStatus();
      } else {
        loadRequest(dialog);
      }
      setFirstLoad(false);
    }
  }, [
    changeUserFilters,
    currentOrganization,
    data,
    dialog,
    filterValue.status,
    filterValue.value,
    firstLoad,
    handleFetchStatus,
    isLoadingUI,
    loadRequest,
  ]);

  function handleScroll(event: UIEvent<HTMLDivElement>): void {
    event.stopPropagation();
    const { clientHeight, scrollHeight, scrollTop } = event.currentTarget;
    if ((scrollHeight - scrollTop) * 0.7 <= clientHeight && users.pagination.count >= users.data.length) {
      userMobileInfiniteScrollRequest({ dialog });
    }
  }

  const columns: MUIDataTableColumn[] = [
    {
      label: `Avatar`,
      name: `avatarThumb`,
      options: {
        customBodyRender(avatarUrl): ReactNode {
          return <Avatar alt="Não encontrado" src={avatarUrl} />;
        },
        filter: false,
      },
    },
    {
      label: `E-mail`,
      name: `emailAndId`,
      options: {
        filter: true,
        customFilterListRender: (v: string): string => `E-mail: ${v}`,
        filterList: users.filterList.emailAndId,
        customBodyRender({ email, id }): ReactNode {
          return (
            <>
              <Typography>{email}</Typography>
              <Typography color="textSecondary">
                <small>{`#${id}`}</small>
              </Typography>
            </>
          );
        },
      },
    },
    {
      label: `Nome`,
      name: `fullName`,
      options: {
        customFilterListRender: (v: string): string => `Nome: ${v}`,
        filterType: `textField`,
        filterList: users.filterList.fullName,
      },
    },
    {
      label: `Status`,
      name: `isActive`,
      options: {
        filter: true,
        customFilterListRender: (v: string): string => `Status: ${v}`,
        customBodyRender(value: boolean): ReactNode {
          return value ? <DoneIcon /> : <ClearIcon />;
        },
        filterType: `dropdown`,
        filterOptions: {
          names: [`Ativo`, `Inativo`],
        },
        filterList: users.filterList.isActive,
      },
    },
    {
      label: `Grupos`,
      name: `groups`,
      options: {
        filter: true,
        customFilterListRender: (v: string): string => `Grupo: ${v}`,
        customBodyRender(value: number[]): ReactNode {
          return groups
            .filter(group => value.indexOf(group.id) > -1)
            .map(group => group.name)
            .join(`, `);
        },
        filterType: `multiselect`,
        filterOptions: {
          names: groups.map(group => group.name),
        },
        filterList: users.filterList.groups,
      },
    },
    muiDatatableHelper.buildInstitutionColumn(institutions, (users.filterList.userInstitution as unknown) as number[], {
      name: `userInstitution`,
    }),
    {
      name: `id`,
      options: {
        filter: false,
        display: `false`,
      },
    },
    {
      label: `Setores`,
      name: `sector`,
      options: {
        customBodyRender(value: number[]): ReactNode {
          return sectors
            .filter(v => value.indexOf(v.id) >= 0)
            .map(v => v.name)
            .join(`, `);
        },
        filter: true,
        filterType: `multiselect`,
        customFilterListRender: (v): string => `Setor: ${v}`,
        filterList: users.filterList.sector,
        filterOptions: {
          names: sectors.map(v => v.name),
        },
      },
    },
  ];

  const options: MUIDataTableOptions = {
    rowsPerPage: window.innerHeight > 760 ? 15 : 10,
    viewColumns: false,
    print: false,
    fixedHeader: true,
    selectableRows: `none`,
    filter: true,
    filterType: `textField`,
    responsive: `scrollFullHeight`,
    serverSide: true,
    count: users.pagination.count,
    page: users.page,
    download: !downloadingReport,
    onTableChange: (action: string, tableState: MUIDataTableState): void => {
      const { activeColumn, announceText, filterList, page, rowsPerPage, searchText } = tableState;
      const filters = filterList.reduce(
        (acc, v, i) => (v.length ? Object.assign(acc, { [columns[i].name]: v }) : acc),
        {},
      );
      switch (action) {
        case `sort`:
          if (activeColumn && announceText)
            changeUserSort({
              activeColumn: columns[parseInt(activeColumn, 10)].name,
              dialog,
              sort: announceText.split(` : `)[1],
            });
          break;
        case `resetFilters`:
        case `filterChange`:
          changeUserFilters({ dialog, filterList: filters, page: 0, searchText: searchText || undefined });
          break;
        case `search`:
          changeUserFilters({ dialog, filterList: filters, page: 0, searchText: searchText || undefined });
          break;
        case `changeRowsPerPage`:
        case `changePage`:
          changeUserPages({ dialog, page, rowsPerPage });
          break;
        default:
      }
    },
    searchText: users.searchText,
    customToolbar: isAdminOrSuperAdmin
      ? (): ReactNode => {
          return (
            <>
              <Tooltip title="Adicionar">
                <Button
                  aria-label="Adicionar"
                  aria-owns="addMenu"
                  variant="contained"
                  color="primary"
                  onClick={(event: MouseEvent<HTMLButtonElement>): void => {
                    setAddMenuSelector(event.currentTarget);
                  }}
                >
                  <AddIcon /> Adicionar
                </Button>
              </Tooltip>
              <Menu
                id="addMenu"
                anchorEl={addMenuSelector}
                open={!!addMenuSelector}
                anchorOrigin={{ vertical: `bottom`, horizontal: `center` }}
                transformOrigin={{ vertical: `top`, horizontal: `center` }}
                getContentAnchorEl={null}
                onClose={(): void => setAddMenuSelector(undefined)}
                disableAutoFocusItem
              >
                {[
                  {
                    name: `Usuário`,
                    id: 0,
                    handler: (): void => {
                      setCreateOrUpdateUserDialogOpen(true);
                      setFormInstanceId(undefined);
                    },
                  },
                  { name: `Importar de arquivo`, id: 1, handler: (): void => setUserImportDialogOpen(true) },
                ].map(val => (
                  <MenuItem key={val.id} onClick={val.handler}>
                    {val.name}
                  </MenuItem>
                ))}
              </Menu>
            </>
          );
        }
      : undefined,
    textLabels: {
      body: {
        noMatch: users.isLoading || isLoadingUI ? `Buscando usuários...` : `Desculpe, nenhum registro encontrado!`,
        toolTip: `Ordenar`,
      },
      pagination: {
        next: `Próxima página`,
        previous: `Págima anterior`,
        rowsPerPage: `Linhas por página:`,
        displayRows: `de`,
      },
      toolbar: {
        search: `Procurar`,
        downloadCsv: `Download`,
        print: `Imprimir`,
        viewColumns: `Colunas visíveis`,
        filterTable: `Filtrar a tabela`,
      },
      filter: {
        all: `Todos`,
        title: `Filtros`,
        reset: `Resetar`,
      },
      viewColumns: {
        title: `Mostrar colunas`,
        titleAria: `Mostrar/Ocultar Colunas da Tabela`,
      },
      selectedRows: {
        text: `linha(s) selecionada(s)`,
        delete: `Deletar`,
        deleteAria: `Deletear linha(s) selecionada(s)`,
      },
    },
    onRowClick: rowData => {
      setCreateOrUpdateUserDialogOpen(true);
      setFormInstanceId(parseInt(rowData[6], 10));
    },
  };

  return (
    <>
      {users.isLoading && <LinearProgress />}
      <Hidden only={[`md`, `lg`, `xl`]}>
        <div className={classes.smRecipient} onScroll={handleScroll}>
          <MobileUsers
            data={users.data}
            handleOpen={(id: number | undefined): Function => (): void => {
              setCreateOrUpdateUserDialogOpen(true);
              setFormInstanceId(id);
            }}
            institutions={institutions}
            isLoading={users.isLoading}
            loggedUserInfo={loggedUserInfo.data.user}
          />
        </div>
      </Hidden>
      <Hidden only={[`xs`, `sm`]}>
        <MuiDatatable columns={columns} data={users.data} options={options} title="Colaboradores" />
      </Hidden>
      {createOrUpdateUserDialogOpen && (
        <DialogUserForm
          closeCreateOrUpdateDialog={(): void => {
            setCreateOrUpdateUserDialogOpen(false);
            setFormInstanceId(undefined);
          }}
          formInstanceId={formInstanceId}
          fullscreen={window.innerWidth < 960}
          genericAvatar={genericAvatar}
          open={createOrUpdateUserDialogOpen}
        />
      )}
      <DialogCsvImporter handleClose={(): void => setUserImportDialogOpen(false)} open={userImportDialogOpen} />
    </>
  );
};

const UsersTests = Users;

export { UsersTests };

const mapStateToProps = (state: ApplicationState): StoreProps => ({
  groups: state.Groups.data,
  institutions: state.Institutions.data,
  loggedUserInfo: state.Auth,
  users: state.Users,
  sectors: state.Sectors.data,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => bindActionCreators({ ...UserActions }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Users);
