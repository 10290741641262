import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  palette: {
    common: {
      black: `#222`,
      white: `#fff`,
    },
    primary: {
      light: `#6fced3`,
      main: `#4ac3c9`,
      dark: `#2c1d49`,
      contrastText: `#fff`,
    },
    secondary: {
      light: `#ff4081`,
      main: `#ee0000`,
      dark: `#7c0000`,
      contrastText: `#fff`,
    },
    error: {
      light: `#e57373`,
      main: `#ee0000`,
      dark: `#7c0000`,
      contrastText: `#fff`,
    },
    warning: {
      light: `#ffb74d`,
      main: `#ff9800`,
      dark: `#f57c00`,
      contrastText: `rgba(0, 0, 0, 0.87)`,
    },
    info: {
      light: `#64b5f6`,
      main: `#2196f3`,
      dark: `#1976d2`,
      contrastText: `#fff`,
    },
    success: {
      light: `#81c784`,
      main: `#2dc376`,
      dark: `#388e3c`,
      contrastText: `#fff`,
    },
    grey: {
      50: `#fdfdfd`,
      100: `#fafafa`,
      200: `#f5f5f5`,
      300: `#e4e4e4`,
      400: `#767676`,
      500: `#9e9e9e`,
      600: `#757575`,
      700: `#616161`,
      800: `#424242`,
      900: `#212121`,
      A100: `#d5d5d5`,
      A200: `#aaaaaa`,
      A400: `#303030`,
      A700: `#616161`,
    },
  },
  props: {
    MuiDialog: {
      scroll: window.innerWidth > 600 ? `body` : `paper`,
    },
    MuiDialogContent: {
      dividers: window.innerWidth < 600,
    },
    MuiSvgIcon: {
      viewBox: `0 0 24 24`,
    },
  },
  overrides: {
    MuiBadge: {
      badge: {
        maxWidth: `20px`,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 6,
        boxShadow: `none`,
        minHeight: `38px`,
        padding: `0 20px`,
      },
      contained: {
        boxShadow: `none`,
      },
      startIcon: {
        marginBottom: `4px`,
        marginRight: `4px`,
      },
    },
    MuiCssBaseline: {
      '@global': {
        // @ts-ignore
        fontFamily: `Hind`,
        body: {
          fontFamily: `Hind`,
        },
        p: {
          fontFamily: `Hind`,
        },
      },
    },
    MuiTableContainer: {
      root: {
        width: `100%`,
        overflowX: `auto`,
        border: `1px solid #F0F0F0`,
      },
    },
    MuiTableRow: {
      root: {
        '&$selected': {
          background: `#EDEDED 0% 0% no-repeat padding-box`,
        },
        '&$selected:hover': {
          background: `#EDEDED 0% 0% no-repeat padding-box`,
        },
      },
    },
    MuiInput: {
      underline: {
        '&:after': {
          borderBottom: `2px solid #F1F1F1`,
        },
        '&:before': {
          borderBottom: `2px solid #F1F1F1`,
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderColor: `#B0B0B0`,
        },
      },
    },
    MuiInputBase: {
      input: {
        height: `1.188em`,
      },
      root: {
        fontSize: 14,
        '&$disabled': {
          background: `#f5f5f5 0% 0% no-repeat padding-box`,
          border: `1px solid #e4e4e4`,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: `#B0B0B0`,
        },
      },
      notchedOutline: {
        borderColor: `#F1F1F1`,
        borderWidth: `2px`,
      },
    },
    // @ts-ignore
    MUIDataTableBodyCell: {
      root: {
        cursor: `pointer`,
        paddingBottom: 4,
        paddingTop: 4,
        maxHeight: window.innerHeight - 269 > 1500 ? (window.innerHeight - 269) / 15 : (window.innerHeight - 269) / 10,
      },
    },
  },
  typography: {
    fontFamily: `Hind`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    htmlFontSize: 16,
    h1: {
      fontFamily: `Hind`,
      fontWeight: 300,
      fontSize: `6rem`,
      lineHeight: 1.4,
      letterSpacing: `-0.01562em`,
    },
    h2: {
      fontFamily: `Hind`,
      fontWeight: 300,
      fontSize: `3.75rem`,
      lineHeight: 1.4,
      letterSpacing: `-0.00833em`,
    },
    h3: {
      fontFamily: `Hind`,
      fontWeight: 400,
      fontSize: `3rem`,
      lineHeight: 1.4,
      letterSpacing: `0em`,
    },
    h4: {
      fontFamily: `Hind`,
      fontWeight: 400,
      fontSize: `2.125rem`,
      lineHeight: 1.4,
      letterSpacing: `0.00735em`,
    },
    h5: {
      fontFamily: `Hind`,
      fontWeight: 400,
      fontSize: `1.5rem`,
      lineHeight: 1.4,
      letterSpacing: `0em`,
    },
    h6: {
      fontFamily: `Hind`,
      fontWeight: 500,
      fontSize: `1.25rem`,
      lineHeight: 1.4,
      letterSpacing: `0.0075em`,
    },
    subtitle1: {
      fontFamily: `Hind`,
      fontWeight: 400,
      fontSize: `1rem`,
      lineHeight: 1.4,
      letterSpacing: `0.00938em`,
    },
    subtitle2: {
      fontFamily: `Hind`,
      fontWeight: 500,
      fontSize: `0.875rem`,
      lineHeight: 1.4,
      letterSpacing: `0.00714em`,
    },
    body1: {
      font: `400 14px/20px Hind`,
      lineHeight: 1.4,
      letterSpacing: 0,
    },
    body2: {
      font: `400 13px/18px Hind`,
      lineHeight: 1.4,
      letterSpacing: 0,
    },
    button: {
      fontFamily: `Hind`,
      fontWeight: 500,
      fontSize: `14px`,
      lineHeight: 1.4,
      letterSpacing: `0`,
      textTransform: `none`,
    },
    caption: {
      fontFamily: `Hind`,
      fontWeight: 400,
      fontSize: `0.75rem`,
      lineHeight: 1.4,
      letterSpacing: `0.03333em`,
    },
    overline: {
      fontFamily: `Hind`,
      fontWeight: 400,
      fontSize: `0.75rem`,
      lineHeight: 1.4,
      letterSpacing: `0.08333em`,
      textTransform: `uppercase`,
    },
  },
});
