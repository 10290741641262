import React, { FC, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useStyles } from './SecondStepConstants';

interface ReactivationConfirmationDialogProps {
  onClose: (data?: { reason: string }) => void;
  open: boolean;
  userName: string;
}

const ReactivationConfirmationDialog: FC<ReactivationConfirmationDialogProps> = ({ onClose, open, userName }) => {
  const [reason, setReason] = useState(``);
  const [errors, setErrors] = useState<{ reason: boolean }>({ reason: false });
  const theme = useTheme();
  const classes = useStyles(theme)();

  const handleSubmit = (): void => {
    if (!reason) setErrors({ ...errors, reason: true });
    if (!reason) return;
    onClose({ reason });
  };

  return (
    <Dialog onClose={(): void => onClose()} aria-labelledby="inactivation-dialog-title" open={open}>
      <DialogTitle id="inactivation-dialog-title">Reativar usuário?</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <Typography>
              Ao reativá-lo, o usuário <strong>{userName}</strong> terá seu acesso ao SIM reestabelecido. É possível
              desativá-lo a qualquer momento.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              autoComplete="off"
              className={classes.textFieldInactivateDialog}
              error={errors.reason}
              fullWidth
              helperText={errors.reason ? `Este campo é obrigatório` : null}
              id="id_reason"
              label="Motivo da reativação"
              name="reason"
              onChange={(event): void => setReason(event.target.value)}
              required
              value={reason}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="default" onClick={(): void => onClose()}>
          Cancelar
        </Button>
        <Button color="primary" onClick={(): void => handleSubmit()}>
          Reativar usuário
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReactivationConfirmationDialog;
