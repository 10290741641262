import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  Badge,
  Chip,
  Collapse,
  Divider,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
} from '@material-ui/core';

import {
  Add as AddIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  FormatListBulleted as FormatListBulletedIcon,
  SignalWifiOff as SignalWifiOffIcon,
  Update as UpdateIcon,
} from '@material-ui/icons';
/**
 * Utils
 */
import { navigationPathNames, sideBarDictionary, subTasksDictionary } from '../Main/sideBarPathNames';
import DrawerSkeleton from './DrawerSkeleton';
import { ReactComponent as MobileDrawerLogo } from '../../Images/mobile-drawer-logo.svg';

const CustomDrawerComponent = ({
  classes,
  handleDrawerToggle,
  handleExpandMenuState,
  handleMenuActive,
  handleSubMenuActive,
  hasInternet,
  isAdminOrSuperAdmin,
  isLoadingUI,
  isSuperAdmin,
  loggedUserInfo,
  menuOpen,
  selectedIndex,
}) => {
  return (
    <div
      tabIndex={-1}
      role="menu"
      className={classes.toolbar}
      onClick={handleDrawerToggle}
      onKeyDown={handleDrawerToggle}
    >
      {!isLoadingUI ? (
        <List style={{ paddingTop: 0 }}>
          {navigationPathNames.map((path, idx) => {
            if (idx === 0) {
              return (
                <Hidden implementation="css" key={0} lgUp>
                  <NavLink
                    to="/"
                    replace={window.location.pathname === path}
                    className={`${classes.mobileLogoNavlink} ${classes.navlink}`}
                    onClick={event => handleMenuActive(event, idx)}
                  >
                    <MobileDrawerLogo className={classes.mobileLogoWrapper} viewBox="7 15 151 29.70" />
                    {Boolean(!hasInternet) && (
                      <Tooltip title="Sem internet">
                        <SignalWifiOffIcon className={classes.mobileNoInternetIcon} color="primary" />
                      </Tooltip>
                    )}
                  </NavLink>
                  <Divider />
                </Hidden>
              );
            }
            if ([1, 5].indexOf(idx) > -1 && isAdminOrSuperAdmin) {
              return (
                <NavLink
                  key={sideBarDictionary[path].id}
                  to={path}
                  replace={window.location.pathname === path}
                  className={classes.navlink}
                  onClick={event => handleMenuActive(event, idx)}
                >
                  <ListItem button selected={selectedIndex === idx}>
                    <ListItemIcon>{sideBarDictionary[path].icon}</ListItemIcon>
                    <ListItemText primary={sideBarDictionary[path].name} />
                  </ListItem>
                </NavLink>
              );
            }
            if ([1, 5].indexOf(idx) > -1 && !isAdminOrSuperAdmin) return null;
            if (path === `/tasks`) {
              return (
                <React.Fragment key={sideBarDictionary[path].id}>
                  <NavLink
                    to={path}
                    replace={window.location.pathname === path}
                    className={classes.navlink}
                    onClick={event => handleMenuActive(event, idx)}
                  >
                    <ListItem button selected={selectedIndex === idx}>
                      <ListItemIcon>
                        <FormatListBulletedIcon />
                      </ListItemIcon>
                      <ListItemText primary="To-dos" />
                    </ListItem>
                  </NavLink>
                  <List component="div" disablePadding style={{ marginTop: 10 }}>
                    {[`Tarefas`, `Chamados`, `Ouvidorias`, `NC's`, `Preventivas`].map((subTask, subTaskIdx) => {
                      const hasGeneralInfo =
                        `generalInfo` in loggedUserInfo && loggedUserInfo.generalInfo !== undefined;
                      const filterName = subTask === `NC's` ? `Não conformidade` : subTask.slice(0, subTask.length - 1);
                      const subSelectedIndex = idx + (subTaskIdx + 1) / 10;
                      return subTask === `Ouvidorias` ? (
                        <NavLink
                          key={subTasksDictionary[subTask].id}
                          to={`${path}/ombudsman`}
                          replace={window.location.pathname === path}
                          className={classes.navlink}
                          onClick={event => handleMenuActive(event, idx)}
                        >
                          <ListItem button className={classes.nested} selected={selectedIndex === subSelectedIndex}>
                            <ListItemIcon>{subTasksDictionary[subTask].icon}</ListItemIcon>
                            <Badge
                              badgeContent={
                                hasGeneralInfo ? loggedUserInfo.generalInfo[subTasksDictionary[subTask].badge] : 0
                              }
                              color={hasGeneralInfo ? `secondary` : `primary`}
                              classes={{ badge: classes.badge }}
                              style={{ width: 125 }}
                            >
                              <ListItemText style={{ paddingLeft: 0 }} inset primary={subTask} />
                            </Badge>
                          </ListItem>
                        </NavLink>
                      ) : (
                        <NavLink
                          key={subTasksDictionary[subTask].id}
                          to={path}
                          replace={window.location.pathname === path}
                          className={classes.navlink}
                          onClick={event =>
                            handleSubMenuActive(event, subSelectedIndex, filterName, false, false, true)
                          }
                        >
                          <ListItem button className={classes.nested} selected={selectedIndex === subSelectedIndex}>
                            <ListItemIcon>{subTasksDictionary[subTask].icon}</ListItemIcon>
                            <Badge
                              badgeContent={
                                hasGeneralInfo ? loggedUserInfo.generalInfo[subTasksDictionary[subTask].badge] : 0
                              }
                              color={hasGeneralInfo ? `secondary` : `primary`}
                              classes={{ badge: classes.badge }}
                              style={{ width: 125 }}
                            >
                              <ListItemText style={{ paddingLeft: 0 }} inset primary={subTask} />
                            </Badge>
                          </ListItem>
                        </NavLink>
                      );
                    })}
                  </List>
                </React.Fragment>
              );
            }
            if (idx === 6 && isSuperAdmin) {
              return (
                <NavLink
                  key={sideBarDictionary[path].id}
                  to={path}
                  replace={window.location.pathname === path}
                  className={classes.navlink}
                  onClick={event => handleMenuActive(event, idx)}
                >
                  <ListItem button selected={selectedIndex === idx}>
                    <ListItemIcon>{sideBarDictionary[path].icon}</ListItemIcon>
                    <ListItemText primary={sideBarDictionary[path].name} />
                  </ListItem>
                </NavLink>
              );
            }
            if (idx === 7 && isAdminOrSuperAdmin) {
              return (
                <React.Fragment key={sideBarDictionary[path].id + 0.1}>
                  <ListItem button onClick={handleExpandMenuState}>
                    <ListItemIcon>
                      <AddIcon />
                    </ListItemIcon>
                    <ListItemText primary="Cadastros" />
                    {menuOpen ? <ExpandLessIcon id="id_catalogos" /> : <ExpandMoreIcon id="id_catalogos" />}
                  </ListItem>
                  <Collapse in={menuOpen} timeout="auto" unmountOnExit>
                    {navigationPathNames.slice(7, navigationPathNames.length - 1).map((catVal, catIdx) => {
                      const isInstitutions = catIdx + 7 === 9;
                      const isUser = catIdx + 7 === 8;
                      const isSector = catIdx + 7 === 10;
                      if (isInstitutions && !isSuperAdmin) return null;
                      if ((isSector || isUser) && !isAdminOrSuperAdmin) return null;
                      if (window.innerWidth < 600 && catIdx + 7 === 12) return null;
                      return (
                        <NavLink
                          key={sideBarDictionary[catVal].id}
                          to={catVal}
                          replace={window.location.pathname === catVal}
                          className={classes.navlink}
                          onClick={event => handleMenuActive(event, catIdx + 7)}
                        >
                          <ListItem button className={classes.nested} selected={selectedIndex === catIdx + 7}>
                            <ListItemIcon>{sideBarDictionary[catVal].icon}</ListItemIcon>
                            <ListItemText primary={sideBarDictionary[catVal].name} />
                          </ListItem>
                        </NavLink>
                      );
                    })}
                  </Collapse>
                </React.Fragment>
              );
            }
            if ([6, 7, 8, 9, 10, 11, 12].indexOf(idx) > -1) return null;
            if (idx === 13) {
              return (
                <NavLink
                  key={sideBarDictionary[path].id}
                  to="/updates"
                  replace={window.location.pathname === `/updates`}
                  className={classes.navlink}
                  onClick={event => handleMenuActive(event, idx)}
                >
                  <ListItem button selected={selectedIndex === idx}>
                    <ListItemIcon>
                      <UpdateIcon />
                    </ListItemIcon>
                    <ListItemText primary="Atualizações" />
                    <ListItemSecondaryAction>
                      <Chip style={{ cursor: `pointer` }} label={process.env.REACT_APP_VERSION} />
                    </ListItemSecondaryAction>
                  </ListItem>
                </NavLink>
              );
            }
            return (
              <NavLink
                key={sideBarDictionary[path].id}
                to={path}
                replace={window.location.pathname === path}
                className={classes.navlink}
                onClick={event => handleMenuActive(event, idx)}
              >
                <ListItem button selected={selectedIndex === idx}>
                  <ListItemIcon>{sideBarDictionary[path].icon}</ListItemIcon>
                  <ListItemText primary={sideBarDictionary[path].name} />
                </ListItem>
              </NavLink>
            );
          })}
        </List>
      ) : (
        <List style={{ paddingTop: 0 }}>
          <DrawerSkeleton />
        </List>
      )}
    </div>
  );
};

export default CustomDrawerComponent;
