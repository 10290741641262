import React from 'react';
/**
 * Material UI - Core
 */
import { Dialog } from '@material-ui/core';
/**
 * My component
 */
import TaskForm from './TaskForm';

const DialogTaskForm = ({ avatar, formInstance, formType, fullscreen, handleClose, isConvertingTaskToCall, open }) => (
  <Dialog
    fullScreen={fullscreen}
    open={open}
    onClose={handleClose}
    aria-labelledby="addTaskForm"
    fullWidth
    maxWidth={formType === `Não conformidade` ? `sm` : `md`}
  >
    <TaskForm
      avatar={avatar}
      handleClose={handleClose}
      isConvertingTaskToCall={isConvertingTaskToCall}
      formInstance={formInstance}
      formType={formType}
    />
  </Dialog>
);

export default DialogTaskForm;
