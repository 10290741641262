import React, { ReactElement } from 'react';
import { Checkbox, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ComponentOverride } from 'markdown-to-jsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    anchor: {
      overflow: `hidden`,
      wordBreak: `break-all`,
    },
    code: {
      backgroundColor: `#DBF3F4`,
      borderRadius: theme.spacing(0.375),
      color: `#3B9CA1`,
      fontFamily: `Inconsolata, monospace`,
      fontSize: `0.8125rem`,
      margin: `0 ${theme.spacing(0.625)}px`,
      padding: `${theme.spacing(0.625)}px`,
      whiteSpace: `pre-wrap`,
    },
  }),
);

const CustomCode = ({ children, className }: { children: any, className: string }): ReactElement => {
  const classes = useStyles();
  const codeClasses = `${className || ``} ${classes.code}`;
  if (typeof children !== `string` || children.split(`\n`).length <= 1) {
    return (
      <Typography className={codeClasses} component="span">
        {children}
      </Typography>
    );
  }
  return <Typography className={codeClasses}>{children}</Typography>;
};

const markdownOverrides: { [key: string]: ComponentOverride } = {
  a: {
    component: function CustomAnchor({ children, className, href, title }): ReactElement {
      const classes = useStyles();
      return (
        <a
          className={`${className} ${classes.anchor}`}
          href={href}
          rel="noopener noreferrer"
          target="_blank"
          title={title}
        >
          {children}
        </a>
      );
    },
  },
  code: CustomCode,
  input: {
    component: function CustomCheckbox({ checked, className, readOnly }): ReactElement {
      return <Checkbox className={className} checked={checked} color="primary" inputProps={{ readOnly }} />;
    },
  },
  img: {
    component: function ImgWrapper({ alt, className, src, title }): ReactElement {
      return (
        <a href={src} target="_blank" rel="noopener noreferrer">
          <img alt={alt} className={className} src={src} style={{ maxWidth: `100%` }} title={title} />
        </a>
      );
    },
  },
  span: {
    component: function CustomSpan({ children, className, style }): ReactElement {
      return (
        <span className={className} style={style}>
          {children}
        </span>
      );
    },
    props: {
      style: {
        fontFamily: `"Hind", "Helvetica", "Arial", sans-serif`,
        fontWeight: 400,
        lineHeight: 1.5,
        letterSpacing: `0.00938em`,
      },
    },
  },
};

export default markdownOverrides;
