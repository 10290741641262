import React, { FC, MouseEvent, WheelEvent } from 'react';
import { NavLink, Route, Switch } from 'react-router-dom';
import firebase from 'firebase';
/**
 * Material-UI
 */
import { Box, Button, Typography } from '@material-ui/core';
import { Build } from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Auth } from '../ReduxFlow/Reducers/Auth/Types';
/**
 * Pages
 */
import Activities from '../Pages/Activities';
import Categories from '../Pages/Categories';
import CRMs from '../Pages/CRMs';
import ExternalDocs from '../Pages/ExternalDocs';
import Home from '../Pages/Home';
import Institutions from '../Pages/Institutions';
import InternalDocs from '../Pages/InternalDocs';
import Inventories from '../Pages/Inventories';
import NoMatch from '../Pages/NoMatch';
import NotificationsList from '../Pages/Notifications/NotificationsList';
import QualityControl from '../Pages/QualityControl';
import Releases from '../Pages/Releases';
import Sectors from '../Pages/Sectors';
import Suppliers from '../Pages/Suppliers';
import Tasks from '../Pages/Tasks';
import DynamicTasks from '../Pages/Tasks/DynamicTasksPage';
import Users from '../Pages/Users';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navlink: {
      textDecoration: `none`,
      color: `unset`,
    },
  }),
);

export interface FilterValue {
  value: string;
  type: string;
  responsible: boolean;
  followers: boolean;
  status: boolean;
}

interface Notification {
  a: string;
  al: string;
  m: string;
  mid: number;
  mn: string;
  p: string;
  r: boolean;
  ts: number;
  uid: number;
}

interface RoutesProps {
  filterValue: FilterValue;
  firestore: firebase.firestore.Firestore;
  genericAvatar: string;
  handleFetchStatus: () => void;
  handleMenuActive: (event: MouseEvent<HTMLDivElement>, index: number) => void;
  handleReadNotification: (
    id: number,
    al: string,
    mid: string,
    mn: string,
  ) => (event: MouseEvent<HTMLDivElement>) => Promise<void>;
  handleScrollNotifications: (ts: number) => (event: WheelEvent<HTMLDivElement>) => Promise<void>;
  handleSubMenuActive: (
    event: MouseEvent<HTMLAnchorElement>,
    index: number,
    filter: string,
    responsible: boolean,
    followers: boolean,
    status: boolean,
  ) => void;
  hasGeneralInfoOnLoggedUserInfo: boolean;
  isAdminOrSuperAdmin: boolean;
  isLoadingUI: boolean;
  isSuperAdmin: boolean;
  loggedUserInfo: Auth;
  notifications: Notification[];
  readAllNotifications: () => Promise<void>;
}

const Routes: FC<RoutesProps> = ({
  filterValue,
  firestore,
  genericAvatar,
  handleFetchStatus,
  handleMenuActive,
  handleReadNotification,
  handleScrollNotifications,
  handleSubMenuActive,
  hasGeneralInfoOnLoggedUserInfo,
  isAdminOrSuperAdmin,
  isLoadingUI,
  isSuperAdmin,
  loggedUserInfo,
  notifications,
  readAllNotifications,
}) => {
  const classes = useStyles();
  return (
    <Switch>
      {isAdminOrSuperAdmin && (
        <Route path="/activities">
          <Activities
            avatar={genericAvatar}
            handleMenuActive={handleMenuActive}
            filterValue={filterValue}
            handleFetchStatus={handleFetchStatus}
          />
        </Route>
      )}
      <Route path="/qualitycontrol">
        {/* 
        // @ts-ignore */}
        <QualityControl filterValue={filterValue} handleFetchStatus={handleFetchStatus} />
      </Route>
      <Route exact path="/tasks">
        {/*
        // @ts-ignore */}
        <Tasks filterValue={filterValue} handleFetchStatus={handleFetchStatus} avatar={genericAvatar} />
      </Route>
      <Route path="/tasks/:modelName/:id?">
        {/*
        // @ts-ignore */}
        <DynamicTasks avatar={genericAvatar} filterValue={filterValue} handleFetchStatus={handleFetchStatus} />
      </Route>
      {isAdminOrSuperAdmin && (
        <Route path="/inventory">
          <Inventories filterValue={filterValue} handleFetchStatus={handleFetchStatus} />
        </Route>
      )}
      {isAdminOrSuperAdmin && (
        <Route path="/users">
          <Users filterValue={filterValue} genericAvatar={genericAvatar} handleFetchStatus={handleFetchStatus} />
        </Route>
      )}
      {isSuperAdmin && (
        <Route path="/institutions">
          <Institutions />
        </Route>
      )}
      {isAdminOrSuperAdmin && (
        <Route path="/sectors">
          {/*
          // @ts-ignore */}
          <Sectors filterValue={filterValue} />
        </Route>
      )}
      {isAdminOrSuperAdmin && (
        <Route path="/suppliers">
          {/*
          // @ts-ignore */}
          <Suppliers filterValue={filterValue} />
        </Route>
      )}
      {isAdminOrSuperAdmin && window.innerWidth > 600 && (
        <Route path="/categories">
          <Categories />
        </Route>
      )}
      {isAdminOrSuperAdmin && (
        <Route path="/externaldocs">
          {/*
          // @ts-ignore */}
          <ExternalDocs avatar={genericAvatar} filterValue={filterValue} handleFetchStatus={handleFetchStatus} />
        </Route>
      )}
      {isSuperAdmin && (
        <Route path="/crm">
          <CRMs filterValue={filterValue} handleFetchStatus={handleFetchStatus} />
        </Route>
      )}
      <Route path="/internaldocs">
        {/*
        // @ts-ignore */}
        <InternalDocs avatar={genericAvatar} filterValue={filterValue} handleFetchStatus={handleFetchStatus} />
      </Route>
      <Route path="/updates">{firestore && <Releases firestore={firestore} />}</Route>
      <Route path="/notifications">
        <Box
          height="calc(100vh - 72px)"
          maxWidth={700}
          style={{
            color: `rgba(0, 0, 0, 0.87)`,
            margin: `auto`,
          }}
        >
          <NotificationsList
            handleReadNotification={handleReadNotification}
            handleScrollNotifications={handleScrollNotifications}
            height="calc(100vh - 110px)"
            notifications={notifications}
            readAllNotifications={readAllNotifications}
            width={700}
          />
        </Box>
      </Route>
      <Route path="/" exact>
        <Home
          institutions={hasGeneralInfoOnLoggedUserInfo ? loggedUserInfo.generalInfo.institutions : 0}
          inventories={hasGeneralInfoOnLoggedUserInfo ? loggedUserInfo.generalInfo.inventories : 0}
          isAdminOrSuperAdmin={isAdminOrSuperAdmin}
          isLoadingUI={isLoadingUI}
          partialCalls={
            hasGeneralInfoOnLoggedUserInfo ? (
              <NavLink
                to="/tasks"
                replace={window.location.pathname === `/tasks`}
                className={classes.navlink}
                onClick={(event: MouseEvent<HTMLAnchorElement>): void =>
                  handleSubMenuActive(event, 2.2, `Chamado/Parcial`, false, false, true)
                }
              >
                <Button
                  variant="outlined"
                  color="default"
                  style={{
                    color: `rgb(255, 193, 7)`,
                    borderColor: `rgb(255, 193, 7)`,
                    marginLeft: 10,
                  }}
                >
                  <Build style={{ marginRight: 8 }} />
                  {loggedUserInfo.generalInfo.partialCalls} parciais
                </Button>
              </NavLink>
            ) : (
              <Typography component="p" color="inherit" style={{ color: `rgb(255, 193, 7)` }}>
                0 parcialmente parados.
              </Typography>
            )
          }
          qcs={hasGeneralInfoOnLoggedUserInfo ? loggedUserInfo.generalInfo.qcs : 0}
          stoppedCalls={
            hasGeneralInfoOnLoggedUserInfo ? (
              <NavLink
                to="/tasks"
                replace={window.location.pathname === `/tasks`}
                className={classes.navlink}
                onClick={(event: MouseEvent<HTMLAnchorElement>): void =>
                  handleSubMenuActive(event, 2.2, `Chamado/Parado`, false, false, true)
                }
              >
                <Button variant="outlined" color="secondary">
                  <Build style={{ marginRight: 8 }} />
                  {loggedUserInfo.generalInfo.stoppedCalls} parados
                </Button>
              </NavLink>
            ) : (
              <Typography component="p" color="secondary">
                0 parados.
              </Typography>
            )
          }
          todos={hasGeneralInfoOnLoggedUserInfo ? loggedUserInfo.generalInfo.Todos : 0}
          users={hasGeneralInfoOnLoggedUserInfo ? loggedUserInfo.generalInfo.users : 0}
        />
      </Route>
      <Route>
        <NoMatch />
      </Route>
    </Switch>
  );
};

export default Routes;
