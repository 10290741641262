import React from 'react';

import {
  AssignmentTurnedIn,
  DevicesOther,
  FormatListBulleted,
  LocationCity,
  SupervisorAccount,
} from '@material-ui/icons';

import { Skeleton } from '@material-ui/lab';

const dashboardItemsFunction = (institutions, inventories, qcs, todos, users) => [
  {
    containerSizes: [12, 6, 3, 3, 3],
    desktop: {
      counter: inventories,
      icon: <DevicesOther style={{ fontSize: 56 }} />,
      sizes: [3, 4, 3, 3, 2],
      text: `Equip. de diagnóstico`,
      textAndCounterSizes: [9, 8, 9, 9, 10],
    },
    key: `firstItem`,
    mobile: {
      counter: users,
      icon: <SupervisorAccount style={{ fontSize: 56 }} />,
      sizes: [3, 4, 4, 3, 2],
      text: `Colaboradores`,
      textAndCounterSizes: [9, 8, 8, 9, 10],
    },
  },
  {
    containerSizes: [12, 6, 3, 3, 3],
    desktop: {
      counter: qcs,
      icon: <AssignmentTurnedIn style={{ fontSize: 56 }} />,
      sizes: [3, 4, 3, 3, 2],
      text: `Cont. de qualidade`,
      textAndCounterSizes: [9, 8, 9, 9, 10],
    },
    key: `secondItem`,
  },
  {
    containerSizes: [12, 6, 3, 2, 2],
    desktop: {
      counter: institutions,
      icon: <LocationCity style={{ fontSize: 56 }} />,
      sizes: [3, 4, 4, 4, 3],
      text: `Instituições`,
      textAndCounterSizes: [9, 8, 8, 8, 9],
    },
    key: `thirdItem`,
  },
  {
    containerSizes: [12, 6, 3, 2, 2],
    desktop: {
      counter: todos,
      icon: <FormatListBulleted style={{ fontSize: 56 }} />,
      sizes: [3, 4, 4, 4, 3],
      text: `To-dos`,
      textAndCounterSizes: [9, 8, 8, 8, 9],
    },
    key: `fourthItem`,
  },
  {
    containerSizes: [12, 6, 3, 2, 2],
    desktop: {
      counter: users,
      icon: <SupervisorAccount style={{ fontSize: 56 }} />,
      sizes: [3, 4, 4, 4, 3],
      text: `Colaboradores`,
      textAndCounterSizes: [9, 8, 8, 8, 9],
    },
    key: `fifthItem`,
    mobile: {
      counter: inventories,
      icon: <DevicesOther style={{ fontSize: 56 }} />,
      sizes: [3, 4, 4, 4, 3],
      text: `Equip. de diagnóstico`,
      textAndCounterSizes: [9, 8, 8, 8, 9],
    },
  },
];

const skeletonItemsFunction = () => [
  {
    containerSizes: [12, 6, 3, 3, 3],
    desktop: {
      counter: <Skeleton height={39} width="30%" />,
      icon: <Skeleton height={56} width={56} />,
      sizes: [3, 4, 3, 3, 2],
      text: <Skeleton height={24} width="90%" />,
      textAndCounterSizes: [9, 8, 9, 9, 10],
    },
    key: `firstItem`,
  },
  {
    containerSizes: [12, 6, 3, 3, 3],
    desktop: {
      counter: <Skeleton height={39} width="30%" />,
      icon: <Skeleton height={56} width={56} />,
      sizes: [3, 4, 3, 3, 2],
      text: <Skeleton height={24} width="90%" />,
      textAndCounterSizes: [9, 8, 9, 9, 10],
    },
    key: `secondItem`,
  },
  {
    containerSizes: [12, 6, 3, 2, 2],
    desktop: {
      counter: <Skeleton height={39} width="30%" />,
      icon: <Skeleton height={56} width={56} />,
      sizes: [3, 4, 4, 4, 3],
      text: <Skeleton height={24} width="90%" />,
      textAndCounterSizes: [9, 8, 8, 8, 9],
    },
    key: `thirdItem`,
  },
  {
    containerSizes: [12, 6, 3, 2, 2],
    desktop: {
      counter: <Skeleton height={39} width="30%" />,
      icon: <Skeleton height={56} width={56} />,
      sizes: [3, 4, 4, 4, 3],
      text: <Skeleton height={24} width="90%" />,
      textAndCounterSizes: [9, 8, 8, 8, 9],
    },
    key: `fourthItem`,
  },
  {
    containerSizes: [12, 6, 3, 2, 2],
    desktop: {
      counter: <Skeleton height={39} width="30%" />,
      icon: <Skeleton height={56} width={56} />,
      sizes: [3, 4, 4, 4, 3],
      text: <Skeleton height={24} width="90%" />,
      textAndCounterSizes: [9, 8, 8, 8, 9],
    },
    key: `fifthItem`,
  },
];

export { dashboardItemsFunction, skeletonItemsFunction };
