import React, {
  ChangeEvent,
  cloneElement,
  MouseEvent,
  ReactElement,
  ReactNode,
  useRef,
  useState,
  forwardRef,
} from 'react';
import { Fade, MenuList, MenuItem, Paper, Popper, PopperProps } from '@material-ui/core';
import {
  Code as CodeIcon,
  FormatBold as FormatBoldIcon,
  FormatItalic as FormatItalicIcon,
  InsertLink as InsertLinkIcon,
} from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    popper: {
      zIndex: 1301,
    },
    listRoot: {
      display: `inline-flex`,
    },
    wrapper: {
      width: `100%`,
    },
  }),
);

interface FieldWithMarkdownMenuProps {
  children: ReactElement;
  inputChangeCallback: (
    event: ChangeEvent<HTMLTextAreaElement> | { target: { scrollHeight: number; value: string } },
  ) => void;
  value: string;
}

const FieldWithMarkdownMenu = forwardRef<HTMLDivElement, FieldWithMarkdownMenuProps>(
  ({ children, inputChangeCallback, value }, ref) => {
    const classes = useStyles();

    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const [markdownAnchorEl, setMarkdownAnchoEl] = useState<PopperProps['anchorEl']>(null); // eslint-disable-line

    function getSelection(): { selectionEnd: number; selectionStart: number; text: string } | undefined {
      if (textAreaRef.current) {
        const textArea = textAreaRef.current;
        const { selectionEnd, selectionStart } = textArea;
        return { selectionEnd, selectionStart, text: textArea.value.substring(selectionStart, selectionEnd) };
      }
      return undefined;
    }

    function closeMarkdownMenu(): void {
      if (textAreaRef.current) {
        const textAreaValueLen = textAreaRef.current.value.length;
        textAreaRef.current.selectionEnd = textAreaValueLen;
        textAreaRef.current.selectionStart = textAreaValueLen;
      }
      setMarkdownAnchoEl(null);
    }

    function openMarkdownMenu(event: MouseEvent<HTMLInputElement>): void {
      event.preventDefault();
      event.persist();
      if (markdownAnchorEl) {
        closeMarkdownMenu();
        return;
      }
      const selection = getSelection();
      if (selection && selection.text) {
        const { clientX, clientY } = event;
        const { clientHeight, clientWidth } = textAreaRef.current || { clientHeight: 0, clientWidth: 0 };
        const getBoundingClientRect = (): ClientRect => ({
          bottom: clientY,
          height: clientHeight,
          left: clientX,
          right: clientX,
          top: clientY,
          width: clientWidth,
        });
        setMarkdownAnchoEl({
          clientHeight,
          clientWidth,
          getBoundingClientRect,
        });
      }
    }

    function checkIfIsSelected(regExp: RegExp): boolean {
      const stringSelected = getSelection();
      return !!stringSelected && !!stringSelected.text.match(regExp);
    }

    function turnBoldOrItalic(styleChars: string): void {
      const selection = getSelection();
      const regExp = new RegExp(`^${styleChars}[^*]*${styleChars}$`);
      if (selection && selection.text) {
        const isBoldOrItalic = selection.text.match(regExp);
        if (isBoldOrItalic)
          inputChangeCallback({
            target: {
              scrollHeight: textAreaRef.current ? textAreaRef.current.scrollHeight : 0,
              value: `${value.substring(0, selection.selectionStart)}${value
                .substring(selection.selectionStart, selection.selectionEnd)
                .replace(/\*/g, ``)}${value.substring(selection.selectionEnd, value.length)}`,
            },
          });
        if (!isBoldOrItalic && !selection.text.match(/\*/)) {
          const newStyleChars = styleChars.replace(/\\/g, ``);
          inputChangeCallback({
            target: {
              scrollHeight: textAreaRef.current ? textAreaRef.current.scrollHeight : 0,
              value: `${value.substring(0, selection.selectionStart)}${newStyleChars}${value.substring(
                selection.selectionStart,
                selection.selectionEnd,
              )}${newStyleChars}${value.substring(selection.selectionEnd, value.length)}`,
            },
          });
        }
      }
      closeMarkdownMenu();
    }

    function turnCode(): void {
      const selection = getSelection();
      const styleChars = `\`\`\``;
      const regExp = /^```.+```$/;
      if (selection && selection.text) {
        if (selection.text.match(regExp)) {
          inputChangeCallback({
            target: {
              scrollHeight: textAreaRef.current ? textAreaRef.current.scrollHeight : 0,
              value: `${value.substring(0, selection.selectionStart)}${value
                .substring(selection.selectionStart, selection.selectionEnd)
                .replace(/```/g, ``)}${value.substring(selection.selectionEnd, value.length)}`,
            },
          });
        } else {
          inputChangeCallback({
            target: {
              scrollHeight: textAreaRef.current ? textAreaRef.current.scrollHeight : 0,
              value: `${value.substring(0, selection.selectionStart)}${styleChars}${value.substring(
                selection.selectionStart,
                selection.selectionEnd,
              )}${styleChars}${value.substring(selection.selectionEnd, value.length)}`,
            },
          });
        }
      }
      closeMarkdownMenu();
    }

    function addHyperlink(): void {
      const selection = getSelection();
      if (selection) {
        if (selection.text.match(/\[([^\]]+)\]\(([^)]+)\)/))
          inputChangeCallback({
            target: {
              scrollHeight: textAreaRef.current ? textAreaRef.current.scrollHeight : 0,
              value: `${value.substring(0, selection.selectionStart)}${value
                .substring(selection.selectionStart, selection.selectionEnd)
                .replace(/[[,\]]/g, ``)
                .replace(/\((.*?)\)/g, ``)}${value.substring(selection.selectionEnd, value.length)}`,
            },
          });
        if (!selection.text.match(/\[([^\]]+)\]\(([^)]+)\)/))
          inputChangeCallback({
            target: {
              scrollHeight: textAreaRef.current ? textAreaRef.current.scrollHeight : 0,
              value: `${value.substring(0, selection.selectionStart)}[${value.substring(
                selection.selectionStart,
                selection.selectionEnd,
              )}](Digite aqui a url)${value.substring(selection.selectionEnd, value.length)}`,
            },
          });
      }
      closeMarkdownMenu();
    }

    const MarkdownMenu = (
      <Popper
        anchorEl={markdownAnchorEl}
        className={classes.popper}
        data-is-click-safe
        open={!!markdownAnchorEl}
        transition
      >
        {({
          TransitionProps,
        }: {
          TransitionProps: {
            in: boolean;
            onEnter: (node: HTMLElement, isAppearing: boolean) => void;
            onExited: (node: HTMLElement) => void;
          };
        }): ReactNode => {
          return (
            <Fade
              in={TransitionProps.in}
              onEnter={TransitionProps.onEnter}
              onExited={TransitionProps.onExited}
              timeout={350}
            >
              <Paper>
                <MenuList
                  classes={{
                    root: classes.listRoot,
                  }}
                >
                  <MenuItem
                    onClick={(): void => turnBoldOrItalic(`\\*\\*`)}
                    selected={checkIfIsSelected(/^\*\*[^*]*\*\*$/)}
                  >
                    <FormatBoldIcon />
                  </MenuItem>
                  <MenuItem onClick={(): void => turnBoldOrItalic(`\\*`)} selected={checkIfIsSelected(/^\*[^*]*\*$/)}>
                    <FormatItalicIcon />
                  </MenuItem>
                  <MenuItem onClick={addHyperlink} selected={checkIfIsSelected(/\[([^\]]+)\]\(([^)]+)\)/)}>
                    <InsertLinkIcon />
                  </MenuItem>
                  <MenuItem onClick={(): void => turnCode()} selected={checkIfIsSelected(/^```.+```$/)}>
                    <CodeIcon />
                  </MenuItem>
                </MenuList>
              </Paper>
            </Fade>
          );
        }}
      </Popper>
    );

    return (
      <div className={classes.wrapper} ref={ref}>
        {cloneElement(children, {
          ...children.props,
          inputProps: { ...children.props.inputProps },
          InputProps: {
            ...children.props.InputProps,
            inputProps: {
              ref: textAreaRef,
              style: {
                height: `fit-content`,
                padding: `10px 0 0 0`,
              },
            },
          },
          onMouseUp: openMarkdownMenu,
        })}
        {MarkdownMenu}
      </div>
    );
  },
);

export default FieldWithMarkdownMenu;
