import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Divider, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import api from '../../Services/api';
import ActionNotificationCard from './ActionNotificationCard';
import ExpirationNotificationCard from './ExpirationNotificationCard';
import FeedbackRequestNotificationCard from './FeedbackRequestNotificationCard';
import ReportReadyCard from './ReportReadyCard';

const useStyles = makeStyles({
  notRead: {
    backgroundColor: `rgba(0, 0, 0, 0.2)`,
  },
});

const useDividerStyles = makeStyles(
  {
    inset: {
      marginLeft: `0px`,
    },
  },
  { name: `MuiDivider` },
);

const getNotificationCard = (notification, users) => {
  switch (notification.t) {
    case `expiry`:
      return <ExpirationNotificationCard notification={notification} />;
    case `feedback_rq`:
      return <FeedbackRequestNotificationCard notification={notification} />;
    case `report-ready`:
      return <ReportReadyCard users={users} notification={notification} />;
    case `action`:
    default:
      // legacy notifications does not have a 'type' field, and where always about actions
      return <ActionNotificationCard users={users} notification={notification} />;
  }
};

const NotificationsList = ({
  handleReadNotification,
  handleScrollNotifications,
  height,
  notifications,
  readAllNotifications,
  width,
}) => {
  const classes = useStyles();
  const dividerStyles = useDividerStyles();
  const [users, setUsers] = useState([]);

  async function getUserInfo(ids) {
    const response = await api.get(`account/users/select/`, { params: { ids: ids.join(`...`) } });
    setUsers(prevUsers => [...prevUsers, ...response.data.results]);
  }

  useEffect(() => {
    const newUsers = notifications.reduce(
      (acc, n) => (!n.uid || users.find(u => u.id === n.uid) || acc.find(e => e === n.uid) ? acc : [...acc, n.uid]),
      [],
    );
    if (newUsers.length) getUserInfo(newUsers);
  }, [notifications, users]);

  return (
    <Box dispaly="flex" flexDirection="column" justifyContent="start" overflow="hidden">
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        maxWidth={width}
        style={{ padding: `0px 5px 0px 5px` }}
      >
        <Typography
          inline="true"
          style={{ fontWeight: `bolder`, marginTop: `auto`, marginBottom: `auto`, paddingTop: 2 }}
          variant="overline"
        >
          Notificações
        </Typography>
        <Button color="primary" onClick={readAllNotifications} size="small">
          Marcar todas como lidas
        </Button>
      </Box>
      <Divider />
      <Box
        maxHeight={height}
        maxWidth={width}
        onScroll={notifications.length ? handleScrollNotifications(notifications.slice(-1)[0].ts) : null}
        style={{ overflowY: `auto` }}
      >
        <List component="nav" style={{ paddingTop: 0 }}>
          {notifications.length ? (
            notifications.map(notification => {
              const { al, id, mid, mn, r } = notification;
              return (
                <React.Fragment key={id}>
                  <ListItem
                    button
                    onClick={handleReadNotification(id, al, mid, mn)}
                    className={!r ? classes.notRead : ``}
                    classes={{ divider: dividerStyles.inset }}
                  >
                    {getNotificationCard(notification, users)}
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </React.Fragment>
              );
            })
          ) : (
            <>
              <ListItem>
                <ListItemText primary="Você ainda não tem notificações! 😮" />
              </ListItem>
              <Divider />
            </>
          )}
        </List>
      </Box>
    </Box>
  );
};

NotificationsList.defaultProps = {
  height: `calc(80vh - 63px)`,
  notifications: [],
  width: 500,
};

NotificationsList.propTypes = {
  handleReadNotification: PropTypes.func.isRequired,
  handleScrollNotifications: PropTypes.func.isRequired,
  height: PropTypes.string,
  notifications: PropTypes.arrayOf(PropTypes.object),
  readAllNotifications: PropTypes.func.isRequired,
  width: PropTypes.number,
};

export default NotificationsList;
