import React, { FC, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import CustomDatePicker from '../NewUIComponents/CustomDatePicker';
import CustomTextField from '../NewUIComponents/CustomTextField';
import { useStyles } from './SecondStepConstants';

interface InactivationConfirmationDialogProps {
  onClose: (data?: { reason: string; date: Date }) => void;
  open: boolean;
  userName: string;
}

const InactivationConfirmationDialog: FC<InactivationConfirmationDialogProps> = ({ onClose, open, userName }) => {
  const [reason, setReason] = useState(``);
  const [date, setDate] = useState<Date | null>(new Date());
  const [errors, setErrors] = useState<{ reason: boolean; date: boolean }>({ reason: false, date: false });
  const theme = useTheme();
  const classes = useStyles(theme)();

  const handleSubmit = (): void => {
    if (!reason) setErrors({ ...errors, reason: true });
    if (!date) setErrors({ ...errors, date: true });
    if (!reason || !date) return;
    onClose({ reason, date });
  };

  return (
    <Dialog onClose={(): void => onClose()} aria-labelledby="inactivation-dialog-title" open={open}>
      <DialogTitle id="inactivation-dialog-title">Desativar usuário?</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <Typography>
              Ao desativá-lo, o usuário <strong>{userName}</strong> não conseguirá mais acessar o SIM. É possível
              reativá-lo a qualquer momento.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              autoComplete="off"
              className={classes.textFieldInactivateDialog}
              error={errors.reason}
              fullWidth
              helperText={errors.reason ? `Este campo é obrigatório` : null}
              id="id_reason"
              label="Motivo da desativação"
              name="reason"
              onChange={(event): void => setReason(event.target.value)}
              required
              value={reason}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <CustomDatePicker
              autoFocus={false}
              autoOk
              className={classes.textFieldInactivateDialog}
              disabled={false}
              error={errors.date}
              onChange={setDate}
              helperText={errors.date ? `Este campo é obrigatório` : null}
              label="Data formal de desligamento"
              name="date"
              required
              value={date}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="default" onClick={(): void => onClose()}>
          Cancelar
        </Button>
        <Button color="secondary" onClick={(): void => handleSubmit()}>
          Desativar usuário
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InactivationConfirmationDialog;
