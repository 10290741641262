export function timestampToDaysHoursAndMinutes(
  value: number,
): { days: number; hours: number; minutes: number; seconds: number } {
  const valueInSeconds = Math.floor(value / 1000);
  const newDays = Math.floor(valueInSeconds / 86400);
  const newHours = Math.floor((valueInSeconds - newDays * 86400) / 3600);
  const newMinutes = Math.floor((valueInSeconds - newDays * 86400 - newHours * 3600) / 60);
  const newSeconds = valueInSeconds - newDays * 86400 - newHours * 3600 - newMinutes * 3600;
  return { days: newDays, hours: newHours, minutes: newMinutes, seconds: newSeconds };
}

export function timestampToDaysHoursAndMinutesString(value: number, reduced?: boolean, isForm?: boolean): string {
  const timestampObject = timestampToDaysHoursAndMinutes(value);
  const daysText = reduced ? `d` : ` dia${timestampObject.days > 1 ? `s` : ``}`;
  const hoursText = reduced ? `h` : ` hora${timestampObject.hours > 1 ? `s` : ``}`;
  const minText = reduced ? `min` : ` min`;
  const secText = reduced ? `seg` : ` seg`;
  const message = `${timestampObject.days ? `${timestampObject.days}${daysText}` : ``} ${
    timestampObject.hours ? `${timestampObject.hours}${hoursText}` : ``
  } ${timestampObject.minutes ? `${timestampObject.minutes}${minText}` : ``}`;
  return /\S/.test(message) || isForm ? message.trimEnd() : ` ${timestampObject.seconds}${secText}`;
}
