import { all, call, put } from 'redux-saga/effects';
import { Action } from 'redux';
import { SagaIterator } from 'redux-saga';
import { Dialog } from 'muibox';

/**
 * API from axios
 */
import api from '../../../Services/api';

import {
  addOrUpdateFailure,
  addSuccess,
  closeTasksCategoryRequest,
  closeTasksCategorySuccess,
  closeTasksSubCategoryRequest,
  loadRequest,
  loadSuccess,
  loadTypesSuccess,
  openTasksCategorySuccess,
  updateSuccess,
} from './Actions';

import { TasksCategoryActionTypes, TasksCategory, TasksSubcategory } from './Types';

interface LoadActionType extends Action {
  payload: {
    dialog: Dialog;
    taskType?: string;
  };
}

interface OpenOrCloseDialogType extends Action {
  payload: {
    dialog: Dialog;
    id?: number | string;
  };
}

interface AddOrUpdateActionType extends Action {
  payload: {
    dialog: Dialog;
    id?: number | string;
    postData: TasksCategory;
  };
}

interface AddOrUpdateSubCategoryActionType extends Action {
  payload: {
    dialog: Dialog;
    category: TasksCategory;
    id?: number | string;
    postData: TasksSubcategory;
  };
}

export function* loadTasksCategories(action: LoadActionType): SagaIterator {
  const { dialog, taskType } = action.payload;
  try {
    const response = yield call(api.get, `/tasks/categories/`, { params: { taskType } });
    yield put(loadSuccess(response.data));
  } catch (error) {
    dialog.alert(`Não foi possível connectar com o servidor!`);
    yield put({ type: TasksCategoryActionTypes.LOAD_ERRORS });
  }
}

export function* openTasksCategoriesDialog(action: OpenOrCloseDialogType): SagaIterator {
  try {
    if (action.payload.id) {
      const response = yield call(api.get, `/tasks/categories/${action.payload.id}/`);
      yield put(openTasksCategorySuccess(response.data));
    } else {
      yield put(openTasksCategorySuccess());
    }
  } catch (error) {
    const { dialog } = action.payload;
    dialog.alert(
      `Não foi possível realizar essa ação, por favor realize um refresh no navegador e caso o problema não seja resolvido entre em contato com nosso suporte!`,
    );
    yield put({ type: TasksCategoryActionTypes.LOAD_ERRORS });
  }
}

export function* closeTasksCategoriesDialog(action: OpenOrCloseDialogType): SagaIterator {
  try {
    yield put(closeTasksCategorySuccess());
  } catch (error) {
    const { dialog } = action.payload;
    dialog.alert(
      `Não foi possível realizar essa ação, por favor realize um refresh no navegador e caso o problema não seja resolvido entre em contato com nosso suporte!`,
    );
  }
}

export function* addOrUpdateTasksCategories(action: AddOrUpdateActionType): SagaIterator {
  const { dialog, postData } = action.payload;
  try {
    if (postData.id) {
      const response = yield call(api.patch, `/tasks/categories/${postData.id}/`, postData);
      yield all([put(updateSuccess(response.data)), put(loadRequest({ dialog }))]);
    } else {
      const response = yield call(api.post, `/tasks/categories/`, postData);
      yield all([put(addSuccess(response.data)), put(loadRequest({ dialog }))]);
    }
    yield put(closeTasksCategoryRequest());
  } catch (error) {
    dialog.alert(
      `Não foi possível realizar essa ação, por favor realize um refresh no navegador e caso o problema não seja resolvido entre em contato com nosso suporte!`,
    );
    yield put(addOrUpdateFailure(error.response.data));
  }
}

export function* addOrUpdateTasksSubCategories(action: AddOrUpdateSubCategoryActionType): SagaIterator {
  const { dialog, category, postData } = action.payload;
  try {
    if (category) {
      postData.category = category.id;
    }
    if (postData.id) {
      const response = yield call(api.patch, `/tasks/subcategories/${postData.id}/`, postData);
      yield all([put(updateSuccess(response.data)), put(loadRequest({ dialog }))]);
    } else {
      const response = yield call(api.post, `/tasks/subcategories/`, postData);
      yield all([put(addSuccess(response.data)), put(loadRequest({ dialog }))]);
    }
    yield put(closeTasksSubCategoryRequest());
  } catch (error) {
    dialog.alert(
      `Não foi possível realizar essa ação, por favor realize um refresh no navegador e caso o problema não seja resolvido entre em contato com nosso suporte!`,
    );
    yield put(addOrUpdateFailure(error.response.data));
  }
}

export function* loadTasksCategoryTypes(action: LoadActionType): SagaIterator {
  try {
    const response = yield call(api.get, `/tasks/category-types/`);
    yield put(loadTypesSuccess(response.data));
  } catch (error) {
    const { dialog } = action.payload;
    dialog.alert(`Não foi possível connectar com o servidor!`);
    yield put({ type: TasksCategoryActionTypes.LOAD_ERRORS });
  }
}
