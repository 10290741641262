import React, { FC, MouseEvent, ReactNode } from 'react';
import { Button, Grid, LinearProgress } from '@material-ui/core';
import { Error as ErrorIcon } from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FileWithPath } from 'react-dropzone';
import Attach from './Attach';
import { Attach as AttachType } from '.';
import { CommentType } from '../CommentsContainer/Tasks';
import api from '../../../Services/api';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    attachWithoutDelete: {
      justifyContent: `flex-start`,
    },
    attachWrapper: {
      alignContent: `center`,
      alignItems: `center`,
      borderRadius: theme.spacing(0.75),
      cursor: `default`,
      display: `flex`,
      marginTop: theme.spacing(1.25),
      minHeight: theme.spacing(5.375),
      padding: theme.spacing(1.25),
      textDecoration: `none`,
      width: theme.spacing(31.625),
      '& > span > span > button': {
        visibility: `hidden`,
        '@media (hover: none)': {
          visibility: `visible`,
        },
      },
      '&:not([data-is-excluded="false"])': {
        pointerEvents: `none`,
      },
      '&:not([data-is-excluded="false"]) > span > span > button': {
        display: `none`,
        pointerEvents: `none`,
      },
      '@media (hover: hover)': {
        '&:not([data-is-excluded="true"]):hover': {
          background: `#eae8ed 0% 0% no-repeat padding-box`,
          cursor: `pointer`,
        },
        '&:not([data-is-excluded="true"]):hover > span > span > button': {
          visibility: `visible`,
        },
      },
    },
    autoCommentRemoved: {
      textDecoration: `line-through`,
    },
    withBackground: {
      background: `#f5f5f5 0% 0% no-repeat padding-box`,
    },
  }),
);

interface AttachWrapperProps {
  attach: AttachType | FileWithPath;
  autoCommentType?: CommentType;
  className?: string;
  deleteCallback?: (id: number | string) => (event?: MouseEvent<HTMLButtonElement>) => void;
  hasError?: { [key: number]: boolean };
  loggedUserId: number;
  progress?: { [key: number]: number };
  retryCallback?: () => Promise<void>;
  startAdornment?: ReactNode;
}

const AttachWrapper: FC<AttachWrapperProps> = ({
  attach,
  autoCommentType,
  className,
  deleteCallback,
  hasError,
  loggedUserId,
  progress,
  retryCallback,
  startAdornment,
}) => {
  const classes = useStyles();

  function isAttach(attachCheck: AttachType | FileWithPath): attachCheck is AttachType {
    return (attachCheck as AttachType).attachment !== undefined;
  }

  function getWrapperClasses(): string {
    const hasDeleteCallback = deleteCallback && isAttach(attach) && loggedUserId === attach.user;
    if (autoCommentType) {
      switch (autoCommentType) {
        case CommentType.TASK_ADD_ATTACH:
          return hasDeleteCallback ? classes.attachWrapper : `${classes.attachWrapper} ${classes.attachWithoutDelete}`;
        default:
          return `${classes.attachWrapper} ${classes.autoCommentRemoved}`;
      }
    }
    return hasDeleteCallback
      ? `${classes.attachWrapper} ${classes.withBackground}`
      : `${classes.attachWrapper} ${classes.attachWithoutDelete} ${classes.withBackground}`;
  }

  const handleOpenFile = async (): Promise<void> => {
    try {
      const response = await api.get(`/tasks/attachs/${(attach as AttachType).id}`);
      window.open(response.data.attachment);
    } catch (error) {
      // eslint-disable-next-line
      console.error(`Erro no fetch de anexos!`, error.response);
      // eslint-disable-next-line
      alert(`Não foi possível conectar com o servidor e buscar o anexo.`);
    }
  };

  return (
    <Grid item>
      <Grid container direction="column" wrap="nowrap">
        <Button
          className={`${getWrapperClasses()} ${className}`}
          component="div"
          data-is-excluded={
            !!autoCommentType &&
            [CommentType.TASK_ADD_ATTACH_DELETED, CommentType.TASK_DELETE_ATTACH].indexOf(autoCommentType) > -1
          }
          onClick={isAttach(attach) ? handleOpenFile : undefined}
        >
          {startAdornment}
          {isAttach(attach) && (
            <Attach
              deleteCallback={
                deleteCallback && loggedUserId === attach.user
                  ? (event: MouseEvent<HTMLButtonElement>): void => {
                      event.preventDefault();
                      event.persist();
                      event.stopPropagation();
                      deleteCallback(attach.id)(event);
                    }
                  : undefined
              }
              task={attach.task}
              url={attach.attachment}
            />
          )}
          {!isAttach(attach) && (
            <>
              <Attach
                deleteCallback={(event: MouseEvent<HTMLButtonElement>): void => {
                  event.preventDefault();
                  event.persist();
                  event.stopPropagation();
                  if (deleteCallback) deleteCallback(attach.lastModified)(event);
                }}
                url={attach.name}
              />
              {hasError && hasError[attach.lastModified] && (
                <ErrorIcon color="secondary" onClick={retryCallback || undefined} />
              )}
            </>
          )}
        </Button>
        {!isAttach(attach) && progress && progress[attach.lastModified] && (
          <LinearProgress
            style={{ width: `calc(100% - 10px)` }}
            value={progress[attach.lastModified]}
            variant="determinate"
          />
        )}
      </Grid>
    </Grid>
  );
};

export default AttachWrapper;
