import React, { SFC, RefObject, ReactNode } from 'react';
import {
  Badge,
  Card,
  CardContent,
  CardHeader,
  ClickAwayListener,
  Divider,
  Grid,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Attachment, CloudDownload, Comment as CommentIcon, EditOutlined, Visibility } from '@material-ui/icons';
import CommentsWrapper, { Comment } from '../../../Components/Comments/CommentsWrapper';
import genericAvatar from '../../../Images/GenericAvatar/genericavatar.png';
import { User } from '../../../ReduxFlow/Reducers/Users/Types';
import { Auth } from '../../../ReduxFlow/Reducers/Auth/Types';

interface ActionsCardProps {
  canEdit?: boolean;
  classes: {
    input: string;
    cardHeaderAction: string;
    cardHeaderRoot: string;
    textField: string;
  };
  commentCountByVersion?: number;
  commentsByVersion: Comment[];
  downloadMenuAnchorEl: HTMLButtonElement | null;
  elaboratorUserName: string;
  fileInputRef?: RefObject<HTMLInputElement>;
  handleCommentAction: () => void;
  handleLogDownloadAction: (id: number, type?: string) => void;
  handleFileSubmit?: (id: number) => (event: React.ChangeEvent) => void;
  handleLogReadAction: (id: number) => void;
  hasIntDocFormInstance: boolean;
  intDocFormInstance: {
    id: number;
    idApprovers: number[];
    idReviewers: number[];
    reviewersList: {
      id: number;
      reviewed: boolean;
      userReviewer: number;
      userFullName: string;
      reviewedAt: string;
    }[];
    approversList: {
      id: number;
      approved: boolean;
      userApprover: number;
      userFullName: string;
      approvedAt: string;
    }[];
    createdByName: string;
    attachment: string;
    pdfAttachment: string;
    createdAt: string;
    closedAt: string;
    version: number;
    documentInfo: number;
    createdBy: number;
  };
  isClosed?: boolean;
  lastVersion?: boolean;
  loggedUserInfo?: Auth;
  openOrCloseDownloadMenu: (event: React.MouseEvent | React.MouseEvent<any, MouseEvent>) => void;
  showComments?: boolean;
  Users?: User[];
}

const ActionsCard: SFC<ActionsCardProps> = ({
  canEdit = false,
  classes,
  commentCountByVersion = 0,
  commentsByVersion,
  downloadMenuAnchorEl,
  elaboratorUserName,
  fileInputRef,
  handleCommentAction,
  handleLogDownloadAction,
  handleFileSubmit,
  handleLogReadAction,
  hasIntDocFormInstance,
  intDocFormInstance,
  isClosed = true,
  lastVersion = false,
  loggedUserInfo,
  openOrCloseDownloadMenu,
  showComments,
}) => {
  const isPending =
    intDocFormInstance.reviewersList.some(reviewer => !reviewer.reviewed) ||
    intDocFormInstance.approversList.some(approver => !approver.approved);

  return (
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 15 }}>
      <Card elevation={0} style={{ boxShadow: `0px 1px 3px #00000033` }}>
        <CardHeader
          action={
            <>
              <Tooltip title="Visualizar">
                <IconButton
                  key={intDocFormInstance.id}
                  onClick={(): void => handleLogReadAction(intDocFormInstance.id)}
                >
                  <Visibility />
                </IconButton>
              </Tooltip>
              {commentCountByVersion > 0 && handleCommentAction && (
                <IconButton style={{ marginLeft: `auto` }} onClick={handleCommentAction}>
                  <Badge badgeContent={commentCountByVersion} color="secondary">
                    <CommentIcon />
                  </Badge>
                </IconButton>
              )}
              {hasIntDocFormInstance && handleFileSubmit && (
                <label htmlFor="id_attachment">
                  <Tooltip title={isClosed ? `Processo encerrado` : `Editar anexo`}>
                    <IconButton component="span" size="small" disabled={!canEdit || isClosed}>
                      <EditOutlined />
                    </IconButton>
                  </Tooltip>
                  <input
                    className={classes.input}
                    id="id_attachment"
                    ref={fileInputRef}
                    name="attachment"
                    type="file"
                    accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    onChange={handleFileSubmit(intDocFormInstance.id)}
                    disabled={!canEdit || isClosed}
                  />
                </label>
              )}
              <Tooltip title="Menu de download">
                <IconButton disabled={!canEdit} onClick={openOrCloseDownloadMenu}>
                  <CloudDownload />
                </IconButton>
              </Tooltip>
              <Popper
                anchorEl={downloadMenuAnchorEl}
                disablePortal
                open={!!downloadMenuAnchorEl}
                role={undefined}
                transition
              >
                {({ TransitionProps, placement }): ReactNode => (
                  <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: placement === `bottom` ? `right top` : `right bottom` }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={openOrCloseDownloadMenu}>
                        <MenuList id="file-download-menu">
                          <MenuItem
                            onClick={(event: React.MouseEvent<HTMLLIElement>): void => {
                              openOrCloseDownloadMenu(event);
                              handleLogDownloadAction(intDocFormInstance.id);
                            }}
                          >
                            Baixar PDF
                          </MenuItem>
                          <MenuItem
                            onClick={(event: React.MouseEvent<HTMLLIElement>): void => {
                              openOrCloseDownloadMenu(event);
                              handleLogDownloadAction(intDocFormInstance.id, `original`);
                            }}
                          >
                            Baixar original (doc, docx)
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </>
          }
          avatar={<Attachment style={{ color: `red` }} />}
          classes={{ action: classes.cardHeaderAction, root: classes.cardHeaderRoot }}
          title={
            <>
              <Typography display="inline" style={{ fontWeight: 500, marginRight: 16 }}>
                Arquivo
              </Typography>
              <Typography display="inline" style={{ color: `#00000099`, marginRight: 16 }}>
                Versão {intDocFormInstance.version} {isClosed && lastVersion ? `vigente:` : ``}
                {isPending ? `pendente` : ``}
              </Typography>
            </>
          }
        />
        <Divider />
        <CardContent>
          <Typography className={classes.textField} style={{ color: `#00000099` }}>
            <small>{elaboratorUserName}</small>
          </Typography>
          {hasIntDocFormInstance &&
            intDocFormInstance.reviewersList.map(reviewer => (
              <Typography key={reviewer.userReviewer} className={classes.textField} style={{ color: `#00000099` }}>
                <small>
                  {!reviewer.reviewed
                    ? `Pendente de revisão por ${reviewer.userFullName}`
                    : `Revisado por ${reviewer.userFullName} em ${new Date(reviewer.reviewedAt).toLocaleString(
                        `pt-BR`,
                      )}`}
                </small>
              </Typography>
            ))}
          {hasIntDocFormInstance &&
            intDocFormInstance.approversList.map(approver => (
              <Typography key={approver.userApprover} className={classes.textField} style={{ color: `#00000099` }}>
                <small>
                  {!approver.approved
                    ? `Pendente de aprovação por ${approver.userFullName}`
                    : `Aprovado por ${approver.userFullName} em ${new Date(approver.approvedAt).toLocaleString(
                        `pt-BR`,
                      )}`}
                </small>
              </Typography>
            ))}
          {showComments && loggedUserInfo && (
            <CommentsWrapper
              comments={commentsByVersion || []}
              genericAvatar={genericAvatar}
              isLoading={!commentsByVersion}
            />
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default ActionsCard;
