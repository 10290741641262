import React, { FC, MouseEvent, ReactNode } from 'react';
import { Button, Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Attach from './Attach';
import { Attach as AttachType } from '.';
import api from '../../../Services/api';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    attachWithoutDelete: {
      justifyContent: `flex-start`,
    },
    attachWrapper: {
      alignContent: `center`,
      alignItems: `center`,
      borderRadius: theme.spacing(0.75),
      cursor: `default`,
      display: `flex`,
      marginTop: theme.spacing(1.25),
      minHeight: theme.spacing(5.375),
      padding: theme.spacing(1.25),
      textDecoration: `none`,
      width: theme.spacing(31.625),
    },
    autoCommentRemoved: {
      textDecoration: `line-through`,
    },
    withBackground: {
      background: `#f5f5f5 0% 0% no-repeat padding-box`,
    },
  }),
);

interface AttachWrapperProps {
  attach: AttachType | File;
  className?: string;
  deleteCallback?: (id?: number | string) => (event?: MouseEvent<HTMLButtonElement>) => void;
  startAdornment?: ReactNode;
}

const AttachWrapper: FC<AttachWrapperProps> = ({ attach, className, deleteCallback, startAdornment }) => {
  const classes = useStyles();

  function getWrapperClasses(): string {
    const hasDeleteCallback = deleteCallback && `id` in attach;
    return hasDeleteCallback
      ? `${classes.attachWrapper} ${classes.withBackground}`
      : `${classes.attachWrapper} ${classes.attachWithoutDelete} ${classes.withBackground}`;
  }

  const handleOpenFile = async (): Promise<void> => {
    try {
      const response = await api.get(`/externaldocs/externaldocattach/${(attach as AttachType).id}`);
      window.open(response.data.attachment);
    } catch (error) {
      // eslint-disable-next-line
      console.error(`Erro no fetch de anexos!`, error.response);
      // eslint-disable-next-line
      alert(`Não foi possível conectar com o servidor e buscar o anexo.`);
    }
  };

  return (
    <Grid item>
      <Grid container direction="column" wrap="nowrap">
        <Button className={`${getWrapperClasses()} ${className}`} component="div" onClick={handleOpenFile}>
          {startAdornment}
          <Attach
            deleteCallback={deleteCallback}
            externaldoc={`externaldocs` in attach ? attach.externaldocs : undefined}
            url={`attachment` in attach ? attach.attachment : (attach as File).name}
            id={`id` in attach ? attach.id : (attach as File).name}
          />
        </Button>
      </Grid>
    </Grid>
  );
};

export default AttachWrapper;
