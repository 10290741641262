import React from 'react';
/**
 * Material UI - Core
 */
import { Button, Typography } from '@material-ui/core';

const ErrorPage = ({ clearError }) => (
  <div style={{ margin: `20vh 0 auto 0` }}>
    <Typography align="center" variant="h2" color="textPrimary">
      Ops! Ocorreu algum erro.
    </Typography>
    <Typography align="center" color="textPrimary">
      Ocorreu um erro na página/função que você tentou executar, por favor informe a equipe SIM.
    </Typography>
    <Typography align="center" color="textPrimary">
      <Button onClick={clearError} color="primary" variant="contained">
        Voltar para tela de Login
      </Button>
    </Typography>
  </div>
);

export default ErrorPage;
