import React, { FC, ReactNode } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleContainer: {
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(3),
    },
  }),
);

interface CustomTableTitleProps {
  children?: ReactNode;
  title: string;
}

const CustomTableTitle: FC<CustomTableTitleProps> = ({ children, title }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.titleContainer} container>
      <Typography variant="h6">{title}</Typography>
      {children}
    </Grid>
  );
};

export default CustomTableTitle;
