import './set-public-path';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import App from './RootApp';

// eslint-disable-next-line no-extend-native
Object.defineProperty(String.prototype, `title`, {
  value(): string {
    return this.split(` `)
      .map((subString: string) => {
        if (subString.length <= 3) return subString;
        return `${this.slice(0, 1).toLocaleUpperCase()}${this.slice(1)}`;
      })
      .join(` `);
  },
});

const reactLifecycles = singleSpaReact({
  // @ts-ignore
  React,
  ReactDOM,
  rootComponent: App,
  domElementGetter: (): Element => {
    let el = document.getElementById(`quality`);
    if (!el) {
      el = document.createElement(`div`);
      el.id = `quality`;
      document.body.appendChild(el);
    }

    return el;
  },
  suppressComponentDidCatchWarning: true,
});

export const bootstrap = [reactLifecycles.bootstrap];

export const mount = [reactLifecycles.mount];

export const unmount = [reactLifecycles.unmount];
