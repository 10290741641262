/* eslint-disable no-restricted-syntax */
import React, { FC, useState } from 'react';
import { Button, FormHelperText, Tooltip, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    moodContainer: {
      display: `block`,
      paddingBottom: theme.spacing(1.5),
      paddingTop: theme.spacing(1.5),
      '& > :first-child': {
        marginLeft: 0,
      },
      '& > :last-child': {
        marginRight: 0,
      },
    },
    moodButton: {
      border: `2px solid transparent`,
      borderRadius: theme.spacing(8),
      cursor: `pointer`,
      display: `inline-block`,
      fontSize: `36px`,
      height: theme.spacing(8),
      margin: `0 ${theme.spacing(1)}px`,
      outline: `none`,
      padding: `0`,
      width: theme.spacing(8),
      '&:hover': {
        backgroundColor: `#4bc2c81a`,
        border: `2px solid transparent`,
      },
    },
    moodButtonActive: {
      backgroundColor: `#4bc2c81a`,
      border: `2px solid #4bc2c8`,
    },
  }),
);

interface Mood {
  src: string;
  submitValue: string;
  tooltip: string;
}

const moods: Mood[] = [
  { tooltip: `Terrível`, submitValue: `terrible`, src: `\u{1F621}` },
  { tooltip: `Ruim`, submitValue: `bad`, src: `\u{1F615}` },
  { tooltip: `Ok`, submitValue: `ok`, src: `\u{1F610}` },
  { tooltip: `Bom`, submitValue: `good`, src: `\u{1F642}` },
  { tooltip: `Incrível`, submitValue: `incredible`, src: `\u{1F60D}` },
];

export const getMoodFromInt = (value: number): Mood | null => {
  const index = value - 1;
  if (index < 0 || index >= moods.length) return null;
  return moods[index];
};

export const getMoodFromString = (value: string): Mood | null => {
  if (!value) return null;
  return moods.find((mood: Mood) => mood.submitValue === value) || null;
};

interface MoodPickerProps {
  disabled?: boolean;
  error?: boolean;
  label: string;
  onChange: (value: string) => void;
  value?: string;
}

const MoodPicker: FC<MoodPickerProps> = ({ disabled, error, label, onChange, value }) => {
  const classes = useStyles();
  const moodButtonInactive = classes.moodButton;
  const moodButtonActive = `${classes.moodButton} ${classes.moodButtonActive}`;
  const [selectedMood, setSelectedMood] = useState<string>(value || ``);

  const handleMoodSelection = (mood: string) => (event: React.MouseEvent): void => {
    event.preventDefault();
    setSelectedMood(mood);
    onChange(mood);
  };

  return (
    <div>
      <Typography variant="body2" color="textSecondary">
        {label}
      </Typography>
      <div className={classes.moodContainer}>
        {moods.map(mood => {
          // const [mood, config] = entry;

          return (
            <Tooltip title={mood.tooltip} key={mood.submitValue}>
              <Button
                disabled={disabled}
                id={mood.submitValue}
                onClick={handleMoodSelection(mood.submitValue)}
                role="button"
                tabIndex={0}
                className={mood.submitValue === selectedMood ? moodButtonActive : moodButtonInactive}
              >
                {mood.src}
              </Button>
            </Tooltip>
          );
        })}
      </div>
      {error && <FormHelperText error={error}>Este campo é obrigatório</FormHelperText>}
    </div>
  );
};

export default MoodPicker;
