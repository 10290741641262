const serializeValue = (v: any): string => {
  if (v === null || v === undefined) return ``;
  if (v instanceof Date) return (v as Date).toISOString();
  return encodeURIComponent(v);
};

export const buildQueryStringFromArray = (params: any[][], globalSearch?: string): string =>
  `globalSearch=${serializeValue(globalSearch)}${params
    .map((val, idx) => `&filter${idx}=${val.length ? val.map(v => serializeValue(v)).join(`...`) : `None`}`)
    .join(``)}`;

export const buildQueryStringFromObject = (params: object, globalSearch?: string): string =>
  `globalSearch=${serializeValue(globalSearch)}${Object.entries(params)
    .map(([k, v]) => `&${k}=${Array.isArray(v) ? v.map(val => serializeValue(val)).join(`...`) : v || `None`}`)
    .join(``)}`;

export const filterListToQueryObject = (filterList: { [k: string]: any[] }): { [k: string]: string } =>
  Object.entries(filterList).reduce(
    (acc, e) => {
      acc[e[0]] = e[1].map(v => serializeValue(v)).join(`...`);
      return acc;
    },
    {} as { [k: string]: string },
  );
