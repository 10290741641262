import { call, put, select } from 'redux-saga/effects';
import { Action, Store } from 'redux';
import { SagaIterator } from 'redux-saga';
/**
 * API from axios
 */
import api from '../../../Services/api';
import { buildQueryStringFromArray } from '../../../Utils';
/**
 * Modal actions
 */
import { closeQualityControlsSuccess, loadQualityControlsSuccess, openQualityControlsSuccess } from './Actions';
/**
 * Get access token
 */
interface AppStore extends Store {
  Auth?: any;
  QualityControls?: any;
}
interface AppAction extends Action {
  payload?: any;
}

export function* loadQualityControls(): SagaIterator {
  try {
    const qualitycontrolState = yield select((state: AppStore) => state.QualityControls);
    let url = buildQueryStringFromArray(qualitycontrolState.filterList, qualitycontrolState.searchText);
    if (Object.entries(qualitycontrolState.sort).length !== 0) {
      url += `&sort=${Object.keys(qualitycontrolState.sort)[0]},${
        qualitycontrolState.sort[Object.keys(qualitycontrolState.sort)[0]].order
      }`;
    }
    const response = yield call(
      api.get,
      `/qualitycontrol/qualitycontrol/?page=${qualitycontrolState.page + 1}&length=${
        qualitycontrolState.rowsPerPage
      }&${url}`,
    );
    yield put(loadQualityControlsSuccess(response.data));
  } catch (error) {
    alert(`Não foi possível conectar com o servidor!`); // eslint-disable-line
  }
}

export function* openQualityControlsModal(data: AppAction): SagaIterator {
  try {
    if (data.payload.id) {
      const response = yield call(api.get, `/qualitycontrol/qualitycontrol/${data.payload.id}/`);
      yield put(openQualityControlsSuccess([response.data]));
    } else {
      yield put(openQualityControlsSuccess([]));
    }
  } catch (error) {
    alert(`Não foi possível realizar essa ação, tente mais tarde!`); // eslint-disable-line
  }
}

export function* closeQualityControlsModal(): SagaIterator {
  try {
    yield put(closeQualityControlsSuccess());
  } catch (error) {
    alert(`Não foi possível realizar essa ação, tente mais tarde!`); // eslint-disable-line
  }
}
