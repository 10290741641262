import React, { FC } from 'react';
import { CircularProgress, Fab, Fade, Grid, Paper, Slide, Typography } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Dialog } from 'muibox';
import { Inventory } from '../../ReduxFlow/Reducers/Inventories/Types';
import { Institution } from '../../ReduxFlow/Reducers/Institutions/Types';
import { User } from '../../ReduxFlow/Reducers/Auth/Types';
import TableInstitutions from '../../Components/TableInstitutions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      margin: 10,
      width: 50,
      height: 50,
    },
    instAvatar: {
      maxWidth: 40,
    },
    userText: {
      wordWrap: `break-word`,
    },
    userWrapper: {
      margin: `5px auto 5px auto`,
      maxWidth: `98%`,
    },
    fab: {
      position: `absolute`,
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    loaderWrapper: {
      position: `absolute`,
      bottom: 30,
      width: `98%`,
      textAlign: `center`,
    },
    footer: {
      width: `100vw`,
      height: `56px`,
      position: `absolute`,
      bottom: 0,
      left: 0,
    },
  }),
);

type MobileInventoryProps = {
  data: Inventory[];
  dialog: Dialog;
  getSubCategoryName: (id: number) => string;
  handleOpen: (data: { dialog: Dialog; id?: string | number }) => void;
  institutions: Institution[];
  isLoading: boolean;
  loggedUserInfo: User;
};

const MobileInventory: FC<MobileInventoryProps> = ({
  data,
  dialog,
  getSubCategoryName,
  handleOpen,
  institutions,
  isLoading,
  loggedUserInfo,
}) => {
  const classes = useStyles();
  return (
    <>
      {data.map(inv => (
        <Slide key={inv.id} in direction="up" mountOnEnter unmountOnExit>
          <Paper className={classes.userWrapper} onClick={(): void => handleOpen({ dialog, id: inv.id })}>
            <Grid container alignItems="center">
              <Grid item xs={3}>
                <TableInstitutions institutions={institutions} selected={[inv.institution]} />
              </Grid>
              <Grid item xs={9} className={classes.userText}>
                <Typography>{inv.title.name}</Typography>
                <Typography>
                  <small>{`#${inv.title.id}   ${getSubCategoryName(inv.type)}`}</small>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Slide>
      ))}
      {isLoading && (
        <div className={classes.loaderWrapper}>
          <Fade
            in={isLoading}
            style={{
              transitionDelay: isLoading ? `800ms` : `0ms`,
            }}
            unmountOnExit
          >
            <CircularProgress />
          </Fade>
        </div>
      )}
      {loggedUserInfo && (
        <Fab className={classes.fab} color="primary" onClick={(): void => handleOpen({ dialog })}>
          <AddIcon />
        </Fab>
      )}
    </>
  );
};

export default MobileInventory;
