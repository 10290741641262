import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { parse } from 'date-fns';
import { Button, CircularProgress, Dialog, DialogActions } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import * as UserActions from '../../ReduxFlow/Reducers/Auth/Actions';
import { fieldsValidation, useStyles } from '../UserForm/UserFormConstants';
import { AvatarDialog, SecondStep, UpdateUserTitle } from '../UserForm';
import ChangePassword from '../UserForm/ChangePassword';
import api from '../../Services/api';
import { initState } from './ProfileConstants';

const ProfileForm = ({
  closeProfileDialog,
  formInstanceId,
  fullscreen,
  genericAvatar,
  open,
  updateAuthUserInfo,
  userInfo,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme)();
  const [avatarDialog, setAvatarDialog] = useState(false);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isViewing, setIsViewing] = useState(true);
  const [passwordDialog, setPasswordDialog] = useState(false);
  const [userData, setUserData] = useState(initState);

  useEffect(() => {
    const {
      avatar,
      birthDate,
      cpf,
      email,
      enableEmailNotifications,
      firebaseUid,
      firstName,
      isFakeEmail,
      lastName,
      sex,
    } = userInfo;
    setUserData({
      avatar,
      birthDate: birthDate ? parse(birthDate, `yyyy-MM-dd`, new Date()) : null,
      cpf,
      email,
      enableEmailNotifications,
      firebaseUid,
      firstName,
      isFakeEmail,
      lastName,
      sex: sex || ``,
    });
    setIsLoading(false);
  }, [userInfo]);

  const handleSubmit = async () => {
    try {
      const { birthDate, cpf, email, enableEmailNotifications, firstName, lastName, sex } = userData;
      setIsSubmitting(true);
      const postUserData = {
        birthDate: birthDate ? new Date(birthDate).toLocaleDateString(`pt-br`) : null,
        cpf: cpf ? cpf.replace(/\D+/g, ``) : null,
        email,
        enableEmailNotifications,
        firstName,
        lastName,
        sex,
      };
      delete postUserData.avatar;
      const response = await api.patch(`/account/users/${formInstanceId}/`, postUserData);
      updateAuthUserInfo(response.data);
      setIsSubmitting(false);
      setIsViewing(true);
    } catch (error) {
      setIsSubmitting(false);
      setErrors(error.response.data);
    }
  };

  const handleFieldChange = name => event => {
    switch (true) {
      case !!event && !!event.target && event.target.type === `checkbox`:
        event.persist();
        setUserData(prevUserData => ({ ...prevUserData, [name]: event.target.checked }));
        break;
      case !!event && !!event.persist:
        event.persist();
        setUserData(prevUserData => ({ ...prevUserData, [name]: event.target.value }));
        break;
      default:
        setUserData(prevUserData => ({ ...prevUserData, [name]: event }));
        break;
    }
    if (!!event && !!event.persist) {
      const value = !!event && !!event.persist && !!event.target && event.target.value;
      switch (true) {
        case name === `firstName` && value.length > 30:
          setErrors(prevState => ({ ...prevState, firstName: `Esse campo não pode ultrapassar 30 caracteres` }));
          break;
        case name === `lastName` && value.length > 30:
          setErrors(prevState => ({ ...prevState, lastName: `Esse campo não pode ultrapassar 30 caracteres` }));
          break;
        case name === `cpf` && value.length < 11:
          setErrors(prevState => ({ ...prevState, cpf: `Favor digitar um CPF válido.` }));
          break;
        default:
          setErrors({});
      }
      fieldsValidation(name, event, setErrors, userData);
    }
  };

  const { avatar, firebaseUid, firstName, isFakeEmail, lastName } = userData;

  const fullName = `${firstName} ${lastName}`;

  return (
    <>
      {avatarDialog && (
        <Dialog
          open={avatarDialog}
          onClose={() => {
            setIsViewing(true);
            setAvatarDialog(false);
          }}
          aria-labelledby="avatar-dialog-title"
          maxWidth="sm"
          fullScreen={window.innerWidth <= 600}
        >
          <AvatarDialog
            open={avatarDialog}
            handleClose={() => {
              setIsViewing(true);
              setAvatarDialog(false);
            }}
            user={formInstanceId}
          />
        </Dialog>
      )}
      <Dialog
        aria-labelledby="profileForm"
        fullScreen={fullscreen}
        open={open}
        onClose={closeProfileDialog}
        fullWidth
        maxWidth="sm"
      >
        {passwordDialog && (
          <ChangePassword
            closePasswordDialog={() => setPasswordDialog(false)}
            firebaseUid={firebaseUid}
            formInstanceId={formInstanceId}
            passwordOpen={passwordDialog}
            userData={userData}
          />
        )}
        <UpdateUserTitle
          avatar={avatar}
          canEdit={() => setIsViewing(false)}
          changePassword={() => setPasswordDialog(true)}
          closeCreateOrUpdateDialog={closeProfileDialog}
          fullName={fullName}
          genericAvatar={genericAvatar}
          isActiveUser
          isFakeEmail={isFakeEmail}
          isLoading={isLoading}
          isProfile
          isViewing={isViewing}
          openAvatarDialog={() => setAvatarDialog(true)}
        />
        <SecondStep
          errors={errors}
          formInstanceId={formInstanceId}
          generateRandomPassword={() => console.error(`Forbidden`)}
          handleFieldChange={handleFieldChange}
          isEditingMultiOrgUser={!isFakeEmail}
          isLoading={isLoading}
          isProfile
          isViewing={isViewing}
          userData={userData}
        />
        {!isViewing && (
          <DialogActions classes={{ root: classes.actionsRoot, spacing: classes.actionsSpacing }}>
            <Button
              className={`${classes.button} ${classes.cancelButton}`}
              disabled={isSubmitting}
              onClick={() => setIsViewing(true)}
            >
              Cancelar
            </Button>
            <Button
              classes={{ disabled: classes.disabledButton }}
              className={classes.button}
              color="primary"
              disabled={isSubmitting}
              onClick={() => {
                Object.keys(userData).forEach(key => {
                  fieldsValidation(key, null, setErrors, userData);
                });
                if (!Object.keys(errors).some(key => errors[key])) {
                  handleSubmit();
                }
              }}
              variant="contained"
            >
              {isSubmitting ? <CircularProgress color="inherit" size={24} /> : `Salvar`}
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};

ProfileForm.defaultProps = {
  fullscreen: false,
};

ProfileForm.propTypes = {
  closeProfileDialog: PropTypes.func.isRequired,
  formInstanceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  fullscreen: PropTypes.bool,
  genericAvatar: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  updateAuthUserInfo: PropTypes.func.isRequired,
  userInfo: PropTypes.shape({
    birthDate: PropTypes.string,
    cpf: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    isFakeEmail: PropTypes.bool,
    lastName: PropTypes.string,
    sex: PropTypes.string,
  }).isRequired,
};

export const TestProfileForm = ProfileForm;

/* istanbul ignore next */
const mapDispatchToProps = dispatch => bindActionCreators(UserActions, dispatch);

export default connect(
  undefined,
  mapDispatchToProps,
)(ProfileForm);
