import React, { useEffect, useRef, useState } from 'react';
import { Checkbox, FormControl, FormHelperText, InputLabel, ListItemText, MenuItem, Select } from '@material-ui/core';

const UserFormMultiSelect = ({
  classes,
  disabled,
  displayNameKey,
  error,
  helperText,
  idKey,
  inputId,
  inputProps,
  label,
  labelId,
  name,
  onChange,
  options,
  required,
  value,
  variant,
}) => {
  const { formControl } = classes;
  const inputLabel = useRef();
  const [labelWidth, setLabelWidth] = useState(0);
  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  return (
    <FormControl className={formControl} disabled={disabled} error={error} variant={variant}>
      <InputLabel id={labelId} ref={inputLabel}>
        {label}
      </InputLabel>
      <Select
        labelId={labelId}
        id={inputId}
        inputProps={inputProps}
        labelWidth={labelWidth}
        MenuProps={{
          anchorOrigin: { horizontal: `left`, vertical: `bottom` },
          getContentAnchorEl: undefined,
          transformOrigin: { horizontal: `left`, vertical: `top` },
        }}
        multiple
        name={name}
        value={value}
        onChange={onChange}
        renderValue={selected =>
          options
            .reduce((result, option) => {
              if (selected.indexOf(option[idKey]) > -1) {
                return [...result, option[displayNameKey]];
              }
              return result;
            }, [])
            .join(`, `)
        }
        required={required}
      >
        {options.map(option => {
          const id = option[idKey];
          const displayName = option[displayNameKey];
          return (
            <MenuItem key={id} value={id}>
              <Checkbox color="primary" checked={value.indexOf(id) > -1} />
              <ListItemText primary={displayName} />
            </MenuItem>
          );
        })}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default UserFormMultiSelect;
