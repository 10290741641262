import { action, ActionType } from 'typesafe-actions';
import { Dialog } from 'muibox';
import { UsersTypes, User } from './Types';

export const loadRequest = (dialog: Dialog): ActionType<typeof action> => action(UsersTypes.LOAD_REQUEST, { dialog });
export const loadUsersSuccess = (data: User[]): ActionType<typeof action> => action(UsersTypes.LOAD_SUCCESS, { data });
export const loadFailure = (): ActionType<typeof action> => action(UsersTypes.LOAD_FAILURE);
export const changeUserSort = (data: {
  activeColumn: string;
  dialog: Dialog;
  sort: string;
}): ActionType<typeof action> => action(UsersTypes.CHANGE_SORT, data);
export const changeUserFilters = (data: {
  dialog: Dialog;
  filterList: { [key: string]: string[] };
  page?: number;
  searchText?: string;
}): ActionType<typeof action> => action(UsersTypes.CHANGE_FILTERS, data);
export const changeUserPages = (data: {
  dialog: Dialog;
  page: number;
  rowsPerPage: number;
}): ActionType<typeof action> => action(UsersTypes.CHANGE_PAGES, data);
export const userMobileInfiniteScrollRequest = (data: { dialog: Dialog }): ActionType<typeof action> =>
  action(UsersTypes.MOBILE_INFINITE_SCROLL_REQUEST, { ...data, mobile: true });
export const userMobileInfiniteScrollSuccess = (data: User[]): ActionType<typeof action> =>
  action(UsersTypes.MOBILE_INFINITE_SCROLL_SUCCESS, data);
export const updateRequest = (data: User[]): ActionType<typeof action> => action(UsersTypes.UPDATE_REQUEST, data);
