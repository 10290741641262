import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { Grid, Slide, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    autoCommentAvatarWrapper: {
      alignContent: `center`,
      alignItems: `center`,
      display: `flex`,
      flexDirection: `column`,
      marginRight: theme.spacing(0.75),
      width: theme.spacing(3),
    },
    avatar: {
      alignItems: `center`,
      alignContent: `center`,
      background: `#f5f5f5 0% 0% no-repeat padding-box`,
      borderRadius: `50%`,
      display: `flex`,
      height: theme.spacing(3),
      justifyContent: `center`,
      width: theme.spacing(3),
    },
    children: {
      margin: `${theme.spacing(2)}px 0`,
    },
    commentAvatarAligner: {
      width: theme.spacing(5),
    },
    container: {
      alignItems: `flex-start`,
      display: `flex`,
      width: `100%`,
    },
    containerWithAligner: {
      alignItems: `flex-start`,
      display: `flex`,
      width: `calc(100% - 40px)`,
    },
    fromNow: {
      minWidth: `fit-content`,
      paddingRight: theme.spacing(1),
      textAlign: `right`,
    },
    headerAndChildrenContainer: {
      alignContent: `flex-start`,
      alignItems: `flex-start`,
      flexWrap: `nowrap`,
      paddingTop: theme.spacing(0.25),
      textAlign: `justify`,
      [theme.breakpoints.down(`sm`)]: {
        flexWrap: `wrap`,
      },
    },
    headerFullName: {
      color: theme.palette.primary.dark,
      fontWeight: 500,
    },
    headerGridItem: {
      display: `flex`,
      flexWrap: `wrap`,
    },
    iconContainer: {
      alignContent: `center`,
      alignItems: `center`,
      display: `flex`,
      justifyContent: `center`,
      marginRight: theme.spacing(1),
      textAlign: `center`,
    },
    topVerticalLine: {
      marginLeft: theme.spacing(1.3125),
      minHeight: theme.spacing(2.5),
    },
    topVerticalLinePreviousAutoComment: {
      marginLeft: theme.spacing(1.3125),
      minHeight: theme.spacing(1),
    },
    verticalLine: {
      background: `#f5f5f5 0% 0% no-repeat padding-box`,
      width: 3,
    },
  }),
);

interface CommentProps {
  avatar: ReactNode;
  children?: ReactNode;
  componentClasses?: {
    children?: string;
    header?: string;
    root?: string;
  };
  endAdornment?: ReactNode;
  fromNow: string;
  headerActionText?: ReactNode;
  isFirstItem?: boolean;
  isLastItem?: boolean;
  nextIsAutoComment?: boolean;
  previousIsAutoComment?: boolean;
  startAdornment?: ReactNode;
  useLeftPadding?: boolean;
  userName?: string;
}

const Comment: FC<CommentProps> = ({
  avatar,
  children,
  componentClasses = {},
  endAdornment,
  fromNow,
  headerActionText,
  isFirstItem = false,
  isLastItem = false,
  nextIsAutoComment = false,
  previousIsAutoComment = false,
  startAdornment,
  useLeftPadding = true,
  userName,
}) => {
  const classes = useStyles();
  const containerRef = useRef<HTMLDivElement>(null);
  const headerAndChildrenContainerRef = useRef<HTMLDivElement>(null);
  const [bottomVerticalLineHeight, setBottomVerticalLineHeight] = useState(16);
  const headerExtraClass = componentClasses.header ? componentClasses.header : ``;

  useEffect(() => {
    if (headerAndChildrenContainerRef.current) {
      const newHeight = headerAndChildrenContainerRef.current.clientHeight - 18;
      if (nextIsAutoComment && newHeight <= 12) {
        setBottomVerticalLineHeight(12);
      } else if (!nextIsAutoComment && newHeight <= 20) {
        setBottomVerticalLineHeight(20);
      } else {
        setBottomVerticalLineHeight(newHeight);
      }
    }
  }, [nextIsAutoComment]);

  return (
    <Slide in direction="up" mountOnEnter ref={containerRef} unmountOnExit>
      <Grid className={componentClasses.root} container>
        <Grid container>
          {useLeftPadding && <Grid item className={classes.commentAvatarAligner} />}
          {!isFirstItem && (
            <Grid
              className={`${
                previousIsAutoComment ? classes.topVerticalLinePreviousAutoComment : classes.topVerticalLine
              } ${classes.verticalLine}`}
              item
            />
          )}
        </Grid>
        <Grid container>
          {useLeftPadding && <Grid item className={classes.commentAvatarAligner} />}
          <Grid className={useLeftPadding ? classes.containerWithAligner : classes.container} item>
            <Grid
              alignItems="center"
              className={classes.autoCommentAvatarWrapper}
              container
              direction="column"
              justify="flex-start"
            >
              <Grid className={classes.avatar} item>
                {avatar}
              </Grid>
              {!isLastItem && (
                <Grid className={classes.verticalLine} item style={{ minHeight: bottomVerticalLineHeight }} />
              )}
            </Grid>
            <Grid
              alignContent="center"
              alignItems="center"
              className={classes.headerAndChildrenContainer}
              container
              justify="space-between"
              ref={headerAndChildrenContainerRef}
              wrap="nowrap"
            >
              <Grid item>
                <Grid container>
                  <Grid className={classes.headerGridItem} item xs={12}>
                    <Grid alignItems="center" container>
                      {startAdornment && (
                        <Grid className={classes.iconContainer} item>
                          {startAdornment}
                        </Grid>
                      )}
                      {userName && (
                        <Grid item>
                          <Typography
                            className={`${classes.headerFullName}, ${headerExtraClass}`}
                            component="span"
                            display="inline"
                            variant="body2"
                          >
                            {userName}&nbsp;
                          </Typography>
                        </Grid>
                      )}
                      {headerActionText && (
                        <Grid item>
                          <Typography
                            color="textSecondary"
                            component="span"
                            display="inline"
                            variant="body2"
                            className={headerExtraClass}
                          >
                            {headerActionText}
                          </Typography>
                        </Grid>
                      )}
                      {endAdornment}
                    </Grid>
                  </Grid>
                  {children && (
                    <Grid className={componentClasses.children || classes.children} item xs={12}>
                      {children}
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid className={classes.fromNow} item xs={3}>
                <Typography color="textSecondary" component="span" display="inline" variant="body2">
                  {fromNow}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
};

export default Comment;
