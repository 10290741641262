import { Reducer } from 'redux';
import { UsersState, UsersTypes, User } from './Types';

export const INITIAL_STATE: UsersState = {
  data: [],
  error: false,
  filterList: { isActive: [`Ativo`] },
  isLoading: false,
  page: 0,
  pagination: {
    count: 0,
    next: undefined,
    previous: undefined,
  },
  rowsPerPage: window.innerHeight > 760 || window.innerWidth < 600 ? 15 : 10,
  searchText: undefined,
  sort: {},
};

const reducer: Reducer<UsersState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UsersTypes.LOAD_REQUEST:
      return { ...state, isLoading: true, error: false, isSaving: false, formErrors: {} };
    case UsersTypes.LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        isSaving: false,
        data: action.payload.data.results,
        pagination: {
          count: action.payload.data.count,
          next: action.payload.data.next,
          previous: action.payload.data.previous,
        },
      };
    case UsersTypes.LOAD_FAILURE:
      return { ...state, isLoading: false, error: true, isSaving: false, data: [] };
    case UsersTypes.CHANGE_SORT:
      return {
        ...state,
        sort: { [action.payload.activeColumn]: { order: action.payload.sort } },
        isLoading: true,
      };
    case UsersTypes.CHANGE_FILTERS:
      return {
        ...state,
        filterList: action.payload.filterList,
        isLoading: true,
        page: action.payload.page || state.page,
        searchText: action.payload.searchText,
      };
    case UsersTypes.CHANGE_PAGES:
      return {
        ...state,
        page: action.payload.page,
        rowsPerPage: action.payload.rowsPerPage,
        isLoading: true,
      };
    case UsersTypes.MOBILE_INFINITE_SCROLL_REQUEST:
      return {
        ...state,
        page: state.page + 1,
        isLoading: true,
      };
    case UsersTypes.MOBILE_INFINITE_SCROLL_SUCCESS:
      return {
        ...state,
        pagination: {
          count: action.payload.count,
          next: action.payload.next,
          previous: action.payload.previous,
        },
        data: state.data
          .concat(action.payload.results)
          .filter((user: User, idx: number, array: User[]) => array.indexOf(user) === idx),
        isLoading: false,
      };
    case UsersTypes.UPDATE_REQUEST:
      return {
        ...state,
        data: state.data.map(
          (user): User => {
            if (user.id === action.payload.id) return { ...user, ...action.payload };
            return user;
          },
        ),
      };
    default:
      return state;
  }
};

export default reducer;
