import { Pagination, TableSortOrder } from '../types';
/**
 * Action types
 */
export enum UsersTypes {
  LOAD_REQUEST = '@Users/LOAD_REQUEST', // eslint-disable-line
  LOAD_SUCCESS = '@Users/LOAD_SUCCESS', // eslint-disable-line
  LOAD_FAILURE = '@Users/LOAD_FAILURE', // eslint-disable-line
  CHANGE_SORT = '@Users/CHANGE_SORT', // eslint-disable-line
  CHANGE_FILTERS = '@Users/CHANGE_FILTERS', // eslint-disable-line
  CHANGE_PAGES = '@Users/CHANGE_PAGES', // eslint-disable-line
  MOBILE_INFINITE_SCROLL_REQUEST = '@Users/MOBILE_INFINITE_SCROLL_REQUEST', // eslint-disable-line
  MOBILE_INFINITE_SCROLL_SUCCESS = '@Users/MOBILE_INFINITE_SCROLL_SUCCESS', // eslint-disable-line
  LOAD_ERRORS = '@Users/LOAD_ERRORS', // eslint-disable-line
  LOAD_HOME_COUNTERS_REQUEST = '@Users/LOAD_HOME_COUNTERS_REQUEST', // eslint-disable-line
  UPDATE_REQUEST = '@Users/UPDATE_REQUEST', // eslint-disable-line
}
/**
 * Data types
 */
export interface User {
  avatarThumb: string;
  emailAndId: { email: string; id: number };
  fullName: string;
  groups: number[];
  id: number;
  isActive: boolean;
  sector: number[];
  userInstitution: number[];
}

export interface UserError {
  admissionDate?: string;
  birthDate?: string;
  cpf?: string;
  dateJoined?: string;
  email?: string;
  firstName?: string;
  groups?: string;
  id?: string;
  lastName?: string;
  sector?: string;
  sex?: string;
  userInstitution?: string;
}
/**
 * State types
 */
export interface UsersState {
  readonly data: User[];
  readonly error: boolean;
  readonly filterList: { [key: string]: string[] };
  readonly isLoading: boolean;
  readonly page: number;
  readonly pagination: Pagination;
  readonly rowsPerPage: number;
  readonly searchText?: string;
  readonly sort?: { [key: string]: { order: TableSortOrder } };
}
