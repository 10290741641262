import React from 'react';
import { withDialog } from 'muibox';

import { connect } from 'react-redux';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  ListItemText,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import { AssignmentTurnedIn, Close, CloudUpload, ExpandMore } from '@material-ui/icons';
import { createFilterOptions } from '@material-ui/lab';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faFileWord, faPaperclip } from '@fortawesome/free-solid-svg-icons';
/**
 * My components
 */
import { MyDatePickerStyles } from '../../../Components/MyDatePicker';
import CustomSelect from '../../../Components/CustomSelect';
import UserSelector from '../../../Components/UserSelector';
import InstitutionsSelector, { filterUserInstitutions } from '../../../Components/InstitutionsSelector';
import api from '../../../Services/api';
import AutocompleteOnDemand from '../../../Components/AutocompleteOnDemand';

const styles = theme => ({
  accordingWrapper: {
    justifyContent: `center`,
  },
  autocompleteAvatar: {
    marginRight: theme.spacing(0.5),
    height: theme.spacing(1.5),
    width: theme.spacing(1.5),
  },
  autocompleteText: {
    whiteSpace: `nowrap`,
  },
  autocompleteTextEllipsis: {
    marginRight: theme.spacing(0.5),
    overflow: `hidden`,
    textOverflow: `ellipsis`,
    whiteSpace: `nowrap`,
  },
  container: {
    display: `flex`,
    flexWrap: `wrap`,
  },
  dense: {
    marginTop: 19,
  },
  dialogTitle: {
    display: `flex`,
    flexFlow: `row nowrap`,
    alignConent: `center`,
    justifyContent: `space-between`,
    width: `100%`,
    padding: 0,
  },
  fab: {
    position: `relative`,
    bottom: 0,
    left: theme.spacing(15),
  },
  fileWrapper: {
    width: `100%`,
    height: `98%`,
    padding: `auto`,
  },
  input: {
    display: `none`,
  },
  menu: {
    width: 200,
  },
  rightIcon: {
    marginLeft: 5,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: `98%`,
  },
  textFieldExtended: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: `98%`,
    marginTop: 4,
  },
  titleText: {
    alignContent: `center`,
    alignItems: `center`,
    display: `flex`,
    padding: `${theme.spacing(1)}px 0 0 ${theme.spacing(4)}px`,
  },
  uploadWrapper: {
    alignContent: `center`,
    alignItems: `center`,
    display: `flex`,
  },
});

class QCForm extends React.Component {
  state = {
    file: null,
    attachs: [],
    notes: ``,
    name: ``,
    institution: ``,
    inventory: null,
    responsible: this.props.loggedUserInfo.user.id, // eslint-disable-line
    isActive: true, // eslint-disable-line
    according: true,
    executed: new Date(),
    shelflife: ``,
    alerts: ``,
    breakdowns: ``,
    breakdownsNotes: ``,
    nobreakSystem: ``,
    linksSystem: ``,
    airSystem: ``,
    temperature: ``,
    umidade: ``,
    temperatureCommand: ``,
    umidadeCommand: ``,
    temperatureEquipment: ``,
    umidadeEquipment: ``,
    temperatureMachine: ``,
    umidadeMachine: ``,
    heliumPressure: ``,
    heliumLevel: ``,
    waterTemperature: ``,
    formErrors: {},
    isSaving: false,
  };

  componentDidMount() {
    const { dbname, formInstance, Institutions } = this.props;
    if (Institutions.length === 1) this.setState({ institution: Institutions[0].id });
    if (formInstance.length) {
      this.mountStateFromFormInstance(formInstance);
    } else {
      switch (dbname) {
        case `qcserver`:
          this.setState({
            name: `Controle de servidores e dispositivos de rede`,
          });
          break;
        case `qctemperature`:
          this.setState({
            name: `Controle de temperatura e umidade`,
          });
          break;
        case `qctempctmr`:
          this.setState({
            name: `Controle de temperatura e umidade TC e RM`,
          });
          break;
        case `qcquench`:
          this.setState({
            name: `Controle de qualidade-Quench`,
          });
          break;
        default:
          this.setState({ name: `` });
      }
    }
  }

  componentDidUpdate(nextProps) {
    const { formInstance } = nextProps;
    const { id } = this.state;
    if (!!formInstance.length && id !== formInstance[0].id) {
      this.mountStateFromFormInstance(formInstance);
    }
  }

  mountStateFromFormInstance = formInstance => {
    this.attachsFetch();
    const {
      id,
      notes,
      name,
      institution,
      inventory,
      responsible,
      isActive,
      according,
      executed,
      shelflife,
      qcserver,
      qctemperature,
      qctempctmr,
      qcquench,
    } = formInstance[0];
    const serverOrTemperature = qctemperature || qcserver;
    this.setState({
      id,
      notes,
      name,
      institution,
      inventory,
      responsible,
      isActive, // eslint-disable-line
      according,
      executed,
      shelflife,
      alerts: qcserver ? qcserver.alerts : ``,
      breakdowns: qcserver ? qcserver.breakdowns : ``,
      breakdownsNotes: qcserver ? qcserver.breakdownsNotes : ``,
      nobreakSystem: qcserver ? qcserver.nobreakSystem : ``,
      linksSystem: qcserver ? qcserver.linksSystem : ``,
      airSystem: qcserver ? qcserver.airSystem : ``,
      temperature: serverOrTemperature ? serverOrTemperature.temperature : ``,
      umidade: serverOrTemperature ? serverOrTemperature.umidade : ``,
      temperatureCommand: qctempctmr ? qctempctmr.temperatureCommand : ``,
      umidadeCommand: qctempctmr ? qctempctmr.umidadeCommand : ``,
      temperatureEquipment: qctempctmr ? qctempctmr.temperatureEquipment : ``,
      umidadeEquipment: qctempctmr ? qctempctmr.umidadeEquipment : ``,
      temperatureMachine: qctempctmr ? qctempctmr.temperatureMachine : ``,
      umidadeMachine: qctempctmr ? qctempctmr.umidadeMachine : ``,
      heliumPressure: qcquench ? qcquench.heliumPressure : ``,
      heliumLevel: qcquench ? qcquench.heliumLevel : ``,
      waterTemperature: qcquench ? qcquench.waterTemperature : ``,
    });
  };

  attachsFetch = () => {
    const { dialog, formInstance } = this.props;
    api
      .get(`/qualitycontrol/qcattach/?qc=${formInstance[0].id}`)
      .then(json => {
        this.setState({ attachs: json.data });
      })
      .catch(error => {
        console.error(`Erro no fetch de anexos!`, error.response); // eslint-disable-line
        dialog.alert({
          title: `Erro ao buscar anexos`,
          message: `Não foi possível conectar com o servidor e buscar os anexos.`,
          ok: { text: `Ok`, variant: `contained`, color: `primary` },
        });
      });
  };

  handleSubmit = (id = null) => event => {
    event.preventDefault();
    const { dialog, handleClick } = this.props;
    const { according, institution, inventory, name, responsible } = this.state;
    this.setState({
      isSaving: true,
    });
    const data = new FormData(event.target);
    const { files } = document.getElementById(`id_attach`);
    data.append(`responsible`, responsible);
    data.append(`institution`, institution);
    data.append(`inventory`, inventory);
    data.set(`according`, according);
    if (!data.get(`name`)) {
      data.append(`name`, name);
    }

    let apiPromise;
    if (id) {
      apiPromise = api.patch(`/qualitycontrol/qualitycontrol/${id}/`, data);
    } else {
      apiPromise = api.post(`/qualitycontrol/qualitycontrol/`, data);
    }

    apiPromise
      .then(json => {
        if (files.length) {
          for (let i = 0; i < files.length; i += 1) {
            const dataFile = new FormData();
            dataFile.append(`attachment`, files[i]);
            dataFile.append(`qc`, json.data.id);
            api.post(`/qualitycontrol/qcattach/`, dataFile).catch(() => {
              dialog.alert({
                title: `Erro no upload`,
                message: `Não foi possível realizar o upload, tente novamente mais tarde!`,
                ok: { text: `Ok`, variant: `contained`, color: `primary` },
              });
            });
          }
        }
        handleClick();
        this.setState({
          isSaving: false,
        });
      })
      .catch(error => {
        this.setState({
          isSaving: false,
        });
        this.setState({
          formErrors: error.response.data,
        });
      });
  };

  handleDateChange = name => date => {
    this.setState({ [name]: date });
  };

  handleAutoCompleteChange = name => (event, values) => {
    this.setState({
      [name]: values.id,
    });
  };

  handleChange = name => event => {
    const { dialog } = this.props;
    const { according } = this.state;
    if (event && !(typeof event === `number`)) {
      if (`target` in event) {
        if (name === `according`) {
          this.setState({ [name]: !according });
        } else if (name === `name`) {
          try {
            this.setState({
              [name]: event.target.value,
              shelflife: event.target.value.includes(`Semanal`) // eslint-disable-line
                ? 7
                : event.target.value.includes(`Mensal`) // eslint-disable-line
                ? 30
                : event.target.value.includes(`Anual`) // eslint-disable-line
                ? 365
                : event.target.value.includes(`Radiométrico`)
                ? 1460
                : 180,
            });
          } catch (e) {
            dialog.alert({
              title: `Cadastrar marcador`,
              message: `Favor solicitar ao suporte o cadastro de um marcador com o nome do teste`,
              ok: { text: `Ok`, variant: `contained`, color: `primary` },
            });
          }
        } else {
          this.setState({
            [name]: event.target.value,
          });
        }
      } else if (`value` in event) {
        this.setState({ [name]: event.value });
      } else if (!event.length) {
        this.setState({ [name]: [] });
      } else if (event[0].value) {
        this.setState({ [name]: event.map(val => val.value) });
      } else {
        this.setState({ [name]: event.map(val => val.value) });
      }
    } else if (typeof event === `number`) {
      this.setState({ [name]: event });
    } else {
      this.setState({ [name]: event });
    }
  };

  handleOpenFile = (url = null) => {
    if (url) {
      window.open(url);
    }
    return null;
  };

  handleFileSubmit = (id = null) => event => {
    event.preventDefault();
    const { dialog, formInstance } = this.props;
    const { attachs } = this.state;
    for (let i = 0; i < event.target.files.length; i += 1) {
      const data = event.target.tagName === `form` ? new FormData(event.target) : new FormData();
      data.append(`attachment`, event.target.files[i]);
      data.append(`qc`, formInstance[0].id);

      let apiPromise;
      if (id) {
        apiPromise = api.patch(`/qualitycontrol/qcattach/${id}/`, data);
      } else {
        apiPromise = api.post(`/qualitycontrol/qcattach/`, data);
      }
      apiPromise
        .then(json => {
          if (id) {
            console.error(`Implement editing attachs!`); // eslint-disable-line
          } else {
            this.setState({
              attachs: [...attachs, json.data],
            });
          }
        })
        .catch(() => {
          dialog.alert({
            title: `Erro no upload`,
            message: `Não foi possível realizar o upload, tente novamente mais tarde!`,
            ok: { text: `Ok`, variant: `contained`, color: `primary` },
          });
        });
    }
  };

  render() {
    const { classes, dbname, formInstance, handleClick, Institutions, loggedUserInfo } = this.props;
    const {
      according,
      alerts,
      airSystem,
      attachs,
      breakdowns,
      breakdownsNotes,
      executed,
      file,
      formErrors,
      heliumLevel,
      heliumPressure,
      institution,
      inventory,
      isSaving,
      linksSystem,
      name,
      nobreakSystem,
      notes,
      responsible,
      shelflife,
      temperature,
      temperatureCommand,
      temperatureEquipment,
      temperatureMachine,
      umidade,
      umidadeCommand,
      umidadeEquipment,
      umidadeMachine,
      waterTemperature,
    } = this.state;
    const hasFormInstance = !!formInstance.length;
    const instanceId = hasFormInstance ? formInstance[0].id : 0;
    const inventorySelect = hasFormInstance && formInstance[0].inventorySelect;
    const institutionIds = [institution].join(`...`);

    return (
      <>
        <DialogTitle className={classes.dialogTitle} disableTypography>
          <Typography className={classes.titleText} variant="overline">
            <AssignmentTurnedIn style={{ marginRight: 5 }} />
            {name}
            {hasFormInstance ? (
              <Typography color="textSecondary" component="span" style={{ marginLeft: 5 }} variant="overline">
                #{instanceId}
              </Typography>
            ) : (
              ``
            )}
          </Typography>
          <Tooltip title="Fechar">
            <IconButton aria-label="Close" onClick={handleClick}>
              <Close />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent>
          <form
            id="QCForm"
            className={classes.container}
            noValidate
            autoComplete="off"
            onSubmit={hasFormInstance ? this.handleSubmit(instanceId) : this.handleSubmit()}
          >
            <Grid container spacing={0} style={{ width: `100%` }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} container>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <UserSelector
                    error={formErrors.responsible}
                    forceInstitution
                    helperText={formErrors.responsible && `Esse campo é obrigatório`}
                    institutionIds={institution ? [institution] : []}
                    label="Responsável"
                    loggedUserInfo={loggedUserInfo.user}
                    margin="normal"
                    name="responsible"
                    onChange={this.handleChange(`responsible`)}
                    required
                    value={responsible}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <InstitutionsSelector
                    error={!!formErrors.institution}
                    helperText={formErrors.institutions ? `Esse campo é obrigatório!` : null}
                    id="id_institution"
                    institutions={filterUserInstitutions(loggedUserInfo.user, Institutions)}
                    margin="normal"
                    name="institution"
                    onChange={this.handleChange(`institution`)}
                    required
                    selectClass={classes.textField}
                    value={institution}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Grid container>
                    <Grid item xs={12}>
                      <AutocompleteOnDemand
                        adittionalParams={{
                          institutionIds,
                        }}
                        disabled={false}
                        fetchUrl="/inventory/inventory/select/"
                        filterOptions={createFilterOptions({
                          stringify: option => JSON.stringify(option),
                        })}
                        getOptionLabel={(option, options) => {
                          const selected = options.find(inv => inv.id === option) || {};
                          return selected.name || `Nenhuma opção encontrada.`;
                        }}
                        getOptionSelected={(option, value) => option.id === value}
                        id="id_inventory"
                        loadingText="Buscando ativos ..."
                        noOptionsText={!institution ? `Selecione uma instituição` : undefined}
                        onChange={this.handleAutoCompleteChange(`inventory`)}
                        renderOption={state => {
                          const { institution: institutionId, location, name: inventoryName, patrimony } = state;
                          const inst = Institutions.find(insideInst => insideInst.id === institutionId);
                          const { institutionAvatar, institutionName } = inst;
                          return (
                            <ListItemText
                              disableTypography
                              primary={
                                <Box display="flex" justifyContent="space-between">
                                  <Typography
                                    className={classes.autocompleteTextEllipsis}
                                    display="inline"
                                    variant="body1"
                                  >
                                    {inventoryName}
                                  </Typography>
                                  <Typography
                                    className={classes.autocompleteText}
                                    color="textSecondary"
                                    display="inline"
                                    variant="body1"
                                  >
                                    {patrimony}
                                  </Typography>
                                </Box>
                              }
                              secondary={
                                <Box
                                  alignContent="center"
                                  alignItems="center"
                                  display="flex"
                                  justifyContent="space-between"
                                >
                                  <Box
                                    alignContent="center"
                                    alignItems="center"
                                    display="flex"
                                    justifyContent="flex-start"
                                  >
                                    <Avatar className={classes.autocompleteAvatar} src={institutionAvatar} />
                                    <Typography
                                      className={classes.autocompleteTextEllipsis}
                                      color="textSecondary"
                                      display="inline"
                                      variant="body1"
                                    >
                                      {`${institutionName}${location ? ` - ${location}` : ``}`}
                                    </Typography>
                                  </Box>
                                </Box>
                              }
                            />
                          );
                        }}
                        selectedOnFormInstance={inventorySelect}
                        startSearchText="Digite para buscar um ativo."
                        style={{ margin: `0 8px`, width: `98%` }}
                        textFieldProps={{
                          error: !!formErrors.inventory,
                          helperText: formErrors.inventory ? `Esse campo é obrigatório!` : undefined,
                          label: `Ativo`,
                          margin: `normal`,
                          name: `inventory`,
                          variant: `outlined`,
                        }}
                        value={inventory}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MyDatePickerStyles
                        handleDateChange={this.handleDateChange(`executed`)}
                        value={executed}
                        required
                        label="Data de execução"
                        margin="normal"
                        name="executed"
                        keyboard
                        className={classes.textField}
                        error={`executed` in formErrors}
                        helperText={`executed` in formErrors && `Esse campo é obrigatório!`}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="id_shelflife"
                        label="Validade em dias"
                        className={classes.textField}
                        value={shelflife}
                        onChange={this.handleChange(`shelflife`)}
                        margin="normal"
                        name="shelflife"
                        type="number"
                        required
                        error={`shelflife` in formErrors}
                        helperText={`shelflife` in formErrors && `Esse campo é obrigatório!`}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  {dbname === `qcphysics` && (
                    <CustomSelect
                      className={classes.textField}
                      errorMessage={`marker` in formErrors ? `Esse campo é obrigatório!` : null}
                      handleChange={this.handleChange}
                      id="id_name"
                      idKey="id"
                      label="Teste"
                      labelwidth={50.5}
                      margin="normal"
                      name="name"
                      nameKey="name"
                      options={[
                        {
                          id: `Teste Semanal`,
                          name: `Teste Semanal`,
                        },
                        {
                          id: `Teste Mensal`,
                          name: `Teste Mensal`,
                        },
                        {
                          id: `Teste Semestral`,
                          name: `Teste Semestral`,
                        },
                        {
                          id: `Teste Anual`,
                          name: `Teste Anual`,
                        },
                        {
                          id: `Teste de EPI's`,
                          name: `Teste de EPI's`,
                        },
                        {
                          id: `Levantamento radiométrico`,
                          name: `Levantamento radiométrico`,
                        },
                        {
                          id: `Teste de negatoscópio/monitores de laudo`,
                          name: `Teste de negatoscópio/monitores de laudo`,
                        },
                      ]}
                      required
                      value={name}
                      variant="outlined"
                    />
                  )}
                  <TextField
                    id="id_notes"
                    label="Observações"
                    className={classes.textField}
                    value={notes}
                    onChange={this.handleChange(`notes`)}
                    margin="normal"
                    variant="outlined"
                    multiline
                    rows={dbname === `qcphysics` ? 5 : 9}
                    name="notes"
                  />
                </Grid>
              </Grid>
              {dbname === `qcserver` && (
                <>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Grid container>
                      <Grid item xs={12}>
                        <FormControl component="fieldset" margin="normal" style={{ width: `100%` }}>
                          <FormLabel component="legend" style={{ textAlign: `center` }}>
                            Avarias físicas?
                          </FormLabel>
                          <RadioGroup
                            aria-label="Breakdowns"
                            name="qcserver.breakdowns"
                            value={breakdowns}
                            onChange={this.handleChange(`breakdowns`)}
                            row
                            style={{ justifyContent: `center` }}
                          >
                            <FormControlLabel
                              value="Sem avarias"
                              control={<Radio color="primary" />}
                              label="Sem avarias"
                            />
                            <FormControlLabel
                              value="Com avarias"
                              control={<Radio color="primary" />}
                              label="Com avarias"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl component="fieldset" margin="normal" style={{ width: `100%` }}>
                          <FormLabel component="legend" style={{ textAlign: `center` }}>
                            Alertas luminosos?
                          </FormLabel>
                          <RadioGroup
                            aria-label="Alerts"
                            name="qcserver.alerts"
                            value={alerts}
                            onChange={this.handleChange(`alerts`)}
                            row
                            style={{ justifyContent: `center` }}
                          >
                            <FormControlLabel
                              value="Sem alertas"
                              control={<Radio color="primary" />}
                              label="Sem alertas"
                            />
                            <FormControlLabel
                              value="Com alertas"
                              control={<Radio color="primary" />}
                              label="Com alertas"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl component="fieldset" margin="normal" style={{ width: `100%` }}>
                          <FormLabel component="legend" style={{ textAlign: `center` }}>
                            Sistema de no-break?
                          </FormLabel>
                          <RadioGroup
                            aria-label="No-break System"
                            name="qcserver.nobreakSystem"
                            value={nobreakSystem}
                            onChange={this.handleChange(`nobreakSystem`)}
                            row
                            style={{ justifyContent: `center` }}
                          >
                            <FormControlLabel
                              value="Funcionando"
                              control={<Radio color="primary" />}
                              label="Funcionando"
                            />
                            <FormControlLabel
                              value="Inoperante"
                              control={<Radio color="primary" />}
                              label="Inoperante"
                            />
                            <FormControlLabel
                              value="Não se aplica"
                              control={<Radio color="primary" />}
                              label="Não se aplica"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl component="fieldset" margin="normal" style={{ width: `100%` }}>
                          <FormLabel component="legend" style={{ textAlign: `center` }}>
                            Redundância nos links?
                          </FormLabel>
                          <RadioGroup
                            aria-label="Links redundance"
                            name="qcserver.linksSystem"
                            value={linksSystem}
                            onChange={this.handleChange(`linksSystem`)}
                            row
                            style={{ justifyContent: `center` }}
                          >
                            <FormControlLabel
                              value="Funcionando"
                              control={<Radio color="primary" />}
                              label="Funcionando"
                            />
                            <FormControlLabel
                              value="Inoperante"
                              control={<Radio color="primary" />}
                              label="Inoperante"
                            />
                            <FormControlLabel
                              value="Não se aplica"
                              control={<Radio color="primary" />}
                              label="Não se aplica"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl component="fieldset" margin="normal" style={{ width: `100%` }}>
                          <FormLabel component="legend" style={{ textAlign: `center` }}>
                            Sistema de climatizadores?
                          </FormLabel>
                          <RadioGroup
                            aria-label="Air system"
                            name="qcserver.airSystem"
                            value={airSystem}
                            onChange={this.handleChange(`airSystem`)}
                            row
                            style={{ justifyContent: `center` }}
                          >
                            <FormControlLabel
                              value="Funcionando"
                              control={<Radio color="primary" />}
                              label="Funcionando"
                            />
                            <FormControlLabel
                              value="Inoperante"
                              control={<Radio color="primary" />}
                              label="Inoperante"
                            />
                            <FormControlLabel
                              value="Não se aplica"
                              control={<Radio color="primary" />}
                              label="Não se aplica"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Grid container>
                      <Grid item xs={12}>
                        <TextField
                          id="id_breakdownsNotes"
                          label="Descreva as avarias/alertas luminosos"
                          className={classes.textField}
                          value={breakdownsNotes}
                          onChange={this.handleChange(`breakdownsNotes`)}
                          margin="normal"
                          multiline
                          rows={6}
                          variant="outlined"
                          name="qcserver.breakdownsNotes"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          margin="normal"
                          name="qcserver.temperature"
                          label="Temperatura"
                          className={classes.textField}
                          value={temperature}
                          onChange={this.handleChange(`temperature`)}
                          id="id_temperature"
                          type="number"
                          InputProps={{
                            endAdornment: <InputAdornment position="end">ºC</InputAdornment>,
                            inputProps: { style: { textAlign: `right` } },
                          }}
                          required
                          error={`qcserver` in formErrors && `temperature` in formErrors.qcserver}
                          helperText={
                            `qcserver` in formErrors &&
                            `temperature` in formErrors.qcserver &&
                            `Esse campo é obrigatório!`
                          }
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          margin="normal"
                          name="qcserver.umidade"
                          label="Umidade"
                          className={classes.textField}
                          value={umidade}
                          onChange={this.handleChange(`umidade`)}
                          id="id_umidade"
                          type="number"
                          InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            inputProps: { style: { textAlign: `right` } },
                          }}
                          required
                          error={`qcserver` in formErrors && `umidade` in formErrors.qcserver}
                          helperText={
                            `qcserver` in formErrors && `umidade` in formErrors.qcserver && `Esse campo é obrigatório!`
                          }
                          variant="outlined"
                        />
                      </Grid>
                      <Grid className={`${classes.uploadWrapper} ${classes.accordingWrapper}`} item xs={6}>
                        <FormControlLabel
                          className={classes.textField}
                          style={{ marginTop: 16 }}
                          control={
                            <Checkbox
                              id="id_according"
                              name="according"
                              checked={according}
                              onChange={this.handleChange(`according`)}
                              value={according ? `Conforme` : `Não conforme`}
                              color="primary"
                            />
                          }
                          label="Conforme"
                        />
                      </Grid>
                      <Grid className={classes.uploadWrapper} item xs={6}>
                        <label htmlFor="id_attach">
                          <Button
                            variant="contained"
                            color="default"
                            component="span"
                            className={classes.textField}
                            style={{ marginTop: 16 }}
                          >
                            Upload
                            <CloudUpload className={classes.rightIcon} />
                          </Button>
                          <input
                            accept="image/*,application/pdf,application/msword"
                            className={classes.input}
                            id="id_attach"
                            name="attach"
                            type="file"
                            multiple
                            onChange={
                              hasFormInstance
                                ? this.handleFileSubmit()
                                : event => this.setState({ file: event.target.files })
                            }
                          />
                        </label>
                        {file && file.length && (
                          <Typography style={{ marginTop: 18, marginLeft: 15 }}>
                            {Array.from(file)
                              .map(val => val.name)
                              .join(`, `)}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
              {dbname === `qctemperature` && (
                <>
                  <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
                    <TextField
                      margin="normal"
                      name="qctemperature.temperature"
                      label="Temperatura"
                      className={classes.textField}
                      value={temperature}
                      onChange={this.handleChange(`temperature`)}
                      id="id_temperature"
                      type="number"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">ºC</InputAdornment>,
                        inputProps: { style: { textAlign: `right` } },
                      }}
                      required
                      error={`qctemperature` in formErrors && `temperature` in formErrors.qctemperature}
                      helperText={
                        `qctemperature` in formErrors &&
                        `temperature` in formErrors.qctemperature &&
                        `Esse campo é obrigatório!`
                      }
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
                    <TextField
                      margin="normal"
                      name="qctemperature.umidade"
                      label="Umidade"
                      className={classes.textField}
                      value={umidade}
                      onChange={this.handleChange(`umidade`)}
                      id="id_umidade"
                      type="number"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        inputProps: { style: { textAlign: `right` } },
                      }}
                      required
                      error={`qctemperature` in formErrors && `umidade` in formErrors.qctemperature}
                      helperText={
                        `qctemperature` in formErrors &&
                        `umidade` in formErrors.qctemperature &&
                        `Esse campo é obrigatório!`
                      }
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    className={`${classes.uploadWrapper} ${classes.accordingWrapper}`}
                    item
                    xs={6}
                    sm={3}
                    md={3}
                    lg={3}
                    xl={3}
                  >
                    <FormControlLabel
                      className={classes.textField}
                      style={{ marginTop: 10 }}
                      control={
                        <Checkbox
                          id="id_according"
                          name="according"
                          checked={according}
                          onChange={this.handleChange(`according`)}
                          value={according ? `Conforme` : `Não conforme`}
                          color="primary"
                        />
                      }
                      label="Conforme"
                    />
                  </Grid>
                  <Grid className={classes.uploadWrapper} item xs={6} sm={3} md={3} lg={3} xl={3}>
                    <label htmlFor="id_attach">
                      <input
                        accept="image/*,application/pdf,application/msword"
                        className={classes.input}
                        id="id_attach"
                        name="attach"
                        type="file"
                        multiple
                        onChange={hasFormInstance ? this.handleFileSubmit() : null}
                      />
                      <Button
                        variant="contained"
                        color="default"
                        component="span"
                        className={classes.textField}
                        style={{ marginTop: 10 }}
                      >
                        Upload
                        <CloudUpload className={classes.rightIcon} />
                      </Button>
                    </label>
                  </Grid>
                </>
              )}
              {dbname === `qctempctmr` && (
                <>
                  <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                    <TextField
                      margin="normal"
                      name="qctempctmr.temperatureCommand"
                      label="Temperatura-Comando"
                      className={classes.textField}
                      value={temperatureCommand}
                      onChange={this.handleChange(`temperatureCommand`)}
                      id="id_temperatureCommand"
                      type="number"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">ºC</InputAdornment>,
                        inputProps: { style: { textAlign: `right` } },
                      }}
                      required
                      error={`qctempctmr` in formErrors && `temperatureCommand` in formErrors.qctempctmr}
                      helperText={
                        `qctempctmr` in formErrors &&
                        `temperatureCommand` in formErrors.qctempctmr &&
                        `Esse campo é obrigatório!`
                      }
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                    <TextField
                      margin="normal"
                      name="qctempctmr.umidadeCommand"
                      label="Umidade-Comando"
                      className={classes.textField}
                      value={umidadeCommand}
                      onChange={this.handleChange(`umidadeCommand`)}
                      id="id_umidadeCommand"
                      type="number"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        inputProps: { style: { textAlign: `right` } },
                      }}
                      required
                      error={`qctempctmr` in formErrors && `umidadeCommand` in formErrors.qctempctmr}
                      helperText={
                        `qctempctmr` in formErrors &&
                        `umidadeCommand` in formErrors.qctempctmr &&
                        `Esse campo é obrigatório!`
                      }
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                    <TextField
                      margin="normal"
                      name="qctempctmr.temperatureEquipment"
                      label="Temperatura-Equip"
                      className={classes.textField}
                      value={temperatureEquipment}
                      onChange={this.handleChange(`temperatureEquipment`)}
                      id="id_temperatureEquipment"
                      type="number"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">ºC</InputAdornment>,
                        inputProps: { style: { textAlign: `right` } },
                      }}
                      required
                      error={`qctempctmr` in formErrors && `temperatureEquipment` in formErrors.qctempctmr}
                      helperText={
                        `qctempctmr` in formErrors &&
                        `temperatureEquipment` in formErrors.qctempctmr &&
                        `Esse campo é obrigatório!`
                      }
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                    <TextField
                      margin="normal"
                      name="qctempctmr.umidadeEquipment"
                      label="Umidade-Equip"
                      className={classes.textField}
                      value={umidadeEquipment}
                      onChange={this.handleChange(`umidadeEquipment`)}
                      id="id_umidadeEquipment"
                      type="number"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        inputProps: { style: { textAlign: `right` } },
                      }}
                      required
                      error={`qctempctmr` in formErrors && `umidadeEquipment` in formErrors.qctempctmr}
                      helperText={
                        `qctempctmr` in formErrors &&
                        `umidadeEquipment` in formErrors.qctempctmr &&
                        `Esse campo é obrigatório!`
                      }
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                    <TextField
                      margin="normal"
                      name="qctempctmr.temperatureMachine"
                      label="Temperatura-Máquina"
                      className={classes.textField}
                      value={temperatureMachine}
                      onChange={this.handleChange(`temperatureMachine`)}
                      id="id_temperatureMachine"
                      type="number"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">ºC</InputAdornment>,
                        inputProps: { style: { textAlign: `right` } },
                      }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                    <TextField
                      margin="normal"
                      name="qctempctmr.umidadeMachine"
                      label="Umidade-Máquina"
                      className={classes.textField}
                      value={umidadeMachine}
                      onChange={this.handleChange(`umidadeMachine`)}
                      id="id_umidadeMachine"
                      type="number"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        inputProps: { style: { textAlign: `right` } },
                      }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    className={`${classes.uploadWrapper} ${classes.accordingWrapper}`}
                    item
                    xs={6}
                    sm={3}
                    md={3}
                    lg={3}
                    xl={3}
                  >
                    <FormControlLabel
                      className={classes.textField}
                      style={{ marginTop: 10 }}
                      control={
                        <Checkbox
                          id="id_according"
                          name="according"
                          checked={according}
                          onChange={this.handleChange(`according`)}
                          value={according ? `Conforme` : `Não conforme`}
                          color="primary"
                        />
                      }
                      label="Conforme"
                    />
                  </Grid>
                  <Grid className={classes.uploadWrapper} item xs={6} sm={3} md={3} lg={3} xl={3}>
                    <label htmlFor="id_attach">
                      <input
                        accept="image/*,application/pdf,application/msword"
                        className={classes.input}
                        id="id_attach"
                        name="attach"
                        type="file"
                        multiple
                        onChange={hasFormInstance ? this.handleFileSubmit() : null}
                      />
                      <Button
                        variant="contained"
                        color="default"
                        component="span"
                        className={classes.textField}
                        style={{ marginTop: 10 }}
                      >
                        Upload
                        <CloudUpload className={classes.rightIcon} />
                      </Button>
                    </label>
                  </Grid>
                </>
              )}
              {dbname === `qcquench` && (
                <>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <TextField
                      margin="normal"
                      name="qcquench.heliumPressure"
                      label="Pressão do Hélio"
                      className={classes.textField}
                      value={heliumPressure}
                      onChange={this.handleChange(`heliumPressure`)}
                      id="id_heliumPressure"
                      type="number"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">bar</InputAdornment>,
                        inputProps: { style: { textAlign: `right` } },
                      }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <TextField
                      margin="normal"
                      name="qcquench.heliumLevel"
                      label="Nível do Hélio"
                      className={classes.textField}
                      value={heliumLevel}
                      onChange={this.handleChange(`heliumLevel`)}
                      id="id_heliumLevel"
                      type="number"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        inputProps: { style: { textAlign: `right` } },
                      }}
                      required
                      error={`qcquench` in formErrors && `heliumLevel` in formErrors.qcquench}
                      helperText={
                        `qcquench` in formErrors && `heliumLevel` in formErrors.qcquench && `Esse campo é obrigatório!`
                      }
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <TextField
                      margin="normal"
                      name="qcquench.waterTemperature"
                      label="Temperatura da água"
                      className={classes.textField}
                      value={waterTemperature}
                      onChange={this.handleChange(`waterTemperature`)}
                      id="id_waterTemperature"
                      type="number"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">ºC</InputAdornment>,
                        inputProps: { style: { textAlign: `right` } },
                      }}
                      required
                      error={`qcquench` in formErrors && `waterTemperature` in formErrors.qcquench}
                      helperText={
                        `qcquench` in formErrors &&
                        `waterTemperature` in formErrors.qcquench &&
                        `Esse campo é obrigatório!`
                      }
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    className={`${classes.uploadWrapper} ${classes.accordingWrapper}`}
                    item
                    xs={6}
                    sm={3}
                    md={3}
                    lg={3}
                    xl={3}
                  >
                    <FormControlLabel
                      className={classes.textField}
                      style={{ marginTop: 10 }}
                      control={
                        <Checkbox
                          id="id_according"
                          name="according"
                          checked={according}
                          onChange={this.handleChange(`according`)}
                          value={according ? `Conforme` : `Não conforme`}
                          color="primary"
                        />
                      }
                      label="Conforme"
                    />
                  </Grid>
                  <Grid className={classes.uploadWrapper} item xs={6} sm={3} md={3} lg={3} xl={3}>
                    <label htmlFor="id_attach">
                      <input
                        accept="image/*,application/pdf,application/msword"
                        className={classes.input}
                        id="id_attach"
                        name="attach"
                        type="file"
                        multiple
                        onChange={hasFormInstance ? this.handleFileSubmit() : null}
                      />
                      <Button
                        variant="contained"
                        color="default"
                        component="span"
                        className={classes.textField}
                        style={{ marginTop: 10 }}
                      >
                        Upload
                        <CloudUpload className={classes.rightIcon} />
                      </Button>
                    </label>
                  </Grid>
                </>
              )}
              {dbname === `qcphysics` && (
                <>
                  <Grid
                    className={`${classes.uploadWrapper} ${classes.accordingWrapper}`}
                    item
                    xs={6}
                    sm={3}
                    md={3}
                    lg={3}
                    xl={3}
                  >
                    <FormControlLabel
                      className={classes.textField}
                      style={{ marginTop: 10 }}
                      control={
                        <Checkbox
                          id="id_according"
                          name="according"
                          checked={according}
                          onChange={this.handleChange(`according`)}
                          value={according ? `Conforme` : `Não conforme`}
                          color="primary"
                        />
                      }
                      label="Conforme"
                    />
                  </Grid>
                  <Grid className={classes.uploadWrapper} item xs={6} sm={3} md={3} lg={3} xl={3}>
                    <label htmlFor="id_attach">
                      <input
                        accept="image/*,application/pdf,application/msword"
                        className={classes.input}
                        id="id_attach"
                        name="attach"
                        type="file"
                        multiple
                        onChange={hasFormInstance ? this.handleFileSubmit() : null}
                      />
                      <Button
                        variant="contained"
                        color="default"
                        component="span"
                        className={classes.textField}
                        style={{ marginTop: 10 }}
                      >
                        Upload
                        <CloudUpload className={classes.rightIcon} />
                      </Button>
                    </label>
                  </Grid>
                </>
              )}
              {hasFormInstance !== 0 && attachs.length > 0 && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  style={{ marginTop: 15, alignItems: `center`, textAlign: `center` }}
                >
                  <Accordion style={{ marginLeft: 5, width: `98%`, textAlign: `center` }}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                      Esse teste possui {attachs.length} anexo(s).
                    </AccordionSummary>
                    <AccordionDetails>
                      <div
                        style={{
                          textAlign: `center`,
                          width: `100%`,
                          display: `flex`,
                          flexDirection: `column`,
                        }}
                      >
                        {attachs.map(attach => {
                          const { attachment, id } = attach;
                          if (attachment.match(/\.pdf$/)) {
                            return (
                              <Button key={id} onClick={() => this.handleOpenFile(attachment)}>
                                <p>
                                  <FontAwesomeIcon icon={faFilePdf} style={{ color: `red` }} />
                                  <small>
                                    {decodeURIComponent(
                                      attachment
                                        .split(`?Expires`)[0]
                                        .split(/\/[0-9]*\//)[2]
                                        .replace(/_/g, ` `)
                                        .replace(/\..*/, ``),
                                    )}
                                  </small>
                                </p>
                                <Divider />
                              </Button>
                            );
                          }
                          if (attachment.match(/\.(doc|docx)$/)) {
                            return (
                              <Button key={id} onClick={() => this.handleOpenFile(attachment)}>
                                <p>
                                  <FontAwesomeIcon icon={faFileWord} style={{ color: `red` }} />
                                  <small>
                                    {decodeURIComponent(
                                      attachment
                                        .split(`?Expires`)[0]
                                        .split(/\/[0-9]*\//)[2]
                                        .replace(/_/g, ` `)
                                        .replace(/\..*/, ``),
                                    )}
                                  </small>
                                </p>
                                <Divider />
                              </Button>
                            );
                          }
                          return (
                            <Button key={id} onClick={() => this.handleOpenFile(attachment)}>
                              <p>
                                <FontAwesomeIcon icon={faPaperclip} style={{ color: `red` }} />
                                <small>
                                  {decodeURIComponent(
                                    attachment
                                      .split(`?Expires`)[0]
                                      .split(/\/[0-9]*\//)[2]
                                      .replace(/_/g, ` `),
                                  )}
                                </small>
                              </p>
                              <Divider />
                            </Button>
                          );
                        })}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              )}
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClick} color="secondary" style={{ marginRight: 5 }}>
            Cancelar
          </Button>
          <Button form="QCForm" type="submit" color="primary" variant="contained" disabled={isSaving}>
            Salvar {isSaving && <CircularProgress size={20} />}
          </Button>
        </DialogActions>
      </>
    );
  }
}

const mapStateToProps = state => ({
  QCMarkers: state.QCMarkers.data,
  Institutions: state.Institutions.data,
  loggedUserInfo: state.Auth.data,
});

export default withDialog()(connect(mapStateToProps)(withStyles(styles)(QCForm)));
