import React, { ReactNode } from 'react';
import {
  AttachAutoComment,
  CloseOrReopenAutoComment,
  CreateAutoComment,
  SLAAutoComment,
  FRTOrRTAutoComment,
  UpdateCategoryAutoComment,
  FeedbackAutoComment,
} from './AutoComments';
import Comment from '../Comment';
import CommentsContainer, { BaseComment, ContainerCfg, GetCommentComponentProps, getFromNow } from '..';

export enum CommentType {
  TASK_ADD_ATTACH = 'TASK_ADD_ATTACH', // eslint-disable-line
  TASK_ADD_ATTACH_DELETED = 'TASK_ADD_ATTACH_DELETED', // eslint-disable-line
  TASK_CLOSE = 'TASK_CLOSE', // eslint-disable-line
  TASK_DELETE_ATTACH = 'TASK_DELETE_ATTACH', // eslint-disable-line
  TASK_DELETE_COMMENT = 'TASK_DELETE_COMMENT', // eslint-disable-line
  TASK_FRT_DATA = 'TASK_FRT_DATA', // eslint-disable-line
  TASK_OPEN = 'TASK_OPEN', // eslint-disable-line
  TASK_REOPEN = 'TASK_REOPEN', // eslint-disable-line
  TASK_RT_DATA = 'TASK_RT_DATA', // eslint-disable-line
  TASK_SLA_DATA = 'TASK_SLA_DATA', // eslint-disable-line
  TASK_SLA_DATA_DELETED = 'TASK_SLA_DATA_DELETED', // eslint-disable-line
  TASK_UPDATE_CATEGORY = 'TASK_UPDATE_CATEGORY', // eslint-disable-line
  TASK_USER_COMMENT = 'TASK_USER_COMMENT', // eslint-disable-line
  TASK_USER_FEEDBACK = 'TASK_USER_FEEDBACK', // eslint-disable-line
}

export interface Comment extends BaseComment<CommentType> {
  attachment?: number;
  commentType: CommentType;
  updatedAt?: string;
  user: number;
  task: number;
}

const isAutoComment = (commentType: CommentType): boolean =>
  [CommentType.TASK_USER_COMMENT, CommentType.TASK_DELETE_COMMENT].indexOf(commentType) < 0;

const getCommentComponent = ({
  comment,
  isFirstItem,
  isLastItem,
  previousIsAutoComment,
  nextIsAutoComment,
  deleteAttachCallback,
  deleteCallback,
  disableHoverListener,
  disableTouchListener,
  editCallback,
  genericAvatar,
  loggedUserId,
  parentType,
}: GetCommentComponentProps<Comment>): ReactNode => {
  const { commentType, date, updatedAt } = comment;
  const fromNow = getFromNow(date);
  const edited = updatedAt ? getFromNow(updatedAt) : undefined;

  switch (commentType) {
    case CommentType.TASK_ADD_ATTACH:
    case CommentType.TASK_ADD_ATTACH_DELETED:
    case CommentType.TASK_DELETE_ATTACH:
      return (
        <AttachAutoComment
          comment={comment}
          deleteAttachCallback={deleteAttachCallback}
          fromNow={fromNow}
          genericAvatar={genericAvatar}
          isFirstItem={isFirstItem}
          isLastItem={isLastItem}
          key={comment.id}
          loggedUserId={loggedUserId}
          nextIsAutoComment={nextIsAutoComment}
          previousIsAutoComment={previousIsAutoComment}
        />
      );
    case CommentType.TASK_CLOSE:
    case CommentType.TASK_REOPEN:
      return (
        <CloseOrReopenAutoComment
          comment={comment}
          fromNow={fromNow}
          genericAvatar={genericAvatar}
          isFirstItem={isFirstItem}
          isLastItem={isLastItem}
          key={comment.id}
          taskType={parentType}
          nextIsAutoComment={nextIsAutoComment}
          previousIsAutoComment={previousIsAutoComment}
        />
      );
    case CommentType.TASK_DELETE_COMMENT:
      return (
        <Comment
          comment={comment}
          disableHoverListener
          disableTouchListener
          fromNow=""
          genericAvatar={genericAvatar}
          isDeleted={comment.commentType === CommentType.TASK_DELETE_COMMENT}
          key={comment.id}
          nextIsAutoComment={nextIsAutoComment}
          previousIsAutoComment={previousIsAutoComment}
        />
      );
    case CommentType.TASK_OPEN:
      return (
        <CreateAutoComment
          comment={comment}
          fromNow={fromNow}
          genericAvatar={genericAvatar}
          isLastItem={isLastItem}
          key={comment.id}
          nextIsAutoComment={nextIsAutoComment}
          previousIsAutoComment={previousIsAutoComment}
          taskType={parentType}
        />
      );
    case CommentType.TASK_SLA_DATA:
    case CommentType.TASK_SLA_DATA_DELETED:
      return (
        <SLAAutoComment
          comment={comment}
          fromNow={fromNow}
          isFirstItem={isFirstItem}
          isLastItem={isLastItem}
          key={comment.id}
          nextIsAutoComment={nextIsAutoComment}
          previousIsAutoComment={previousIsAutoComment}
        />
      );
    case CommentType.TASK_FRT_DATA:
      return (
        <FRTOrRTAutoComment
          comment={comment}
          fromNow={fromNow}
          isFirstItem={isFirstItem}
          isFrt
          isLastItem={isLastItem}
          key={comment.id}
          nextIsAutoComment={nextIsAutoComment}
          previousIsAutoComment={previousIsAutoComment}
        />
      );
    case CommentType.TASK_RT_DATA:
      return (
        <FRTOrRTAutoComment
          comment={comment}
          fromNow={fromNow}
          isFirstItem={isFirstItem}
          isLastItem={isLastItem}
          key={comment.id}
          nextIsAutoComment={nextIsAutoComment}
          previousIsAutoComment={previousIsAutoComment}
        />
      );
    case CommentType.TASK_UPDATE_CATEGORY:
      return (
        <UpdateCategoryAutoComment
          comment={comment}
          fromNow={fromNow}
          genericAvatar={genericAvatar}
          isFirstItem={isFirstItem}
          isLastItem={isLastItem}
          key={comment.id}
          nextIsAutoComment={nextIsAutoComment}
          previousIsAutoComment={previousIsAutoComment}
        />
      );
    case CommentType.TASK_USER_FEEDBACK:
      return (
        <FeedbackAutoComment
          comment={comment}
          fromNow={fromNow}
          isFirstItem={isFirstItem}
          isLastItem={isLastItem}
          key={comment.id}
          nextIsAutoComment={nextIsAutoComment}
          previousIsAutoComment={previousIsAutoComment}
        />
      );
    default:
      return (
        <Comment
          comment={comment}
          disableHoverListener={comment.user !== loggedUserId || disableHoverListener}
          disableTouchListener={comment.user !== loggedUserId || disableTouchListener}
          deleteCallback={deleteCallback}
          editCallback={editCallback}
          edited={edited}
          fromNow={fromNow}
          isDeleted={comment.commentType === CommentType.TASK_DELETE_COMMENT}
          genericAvatar={genericAvatar}
          key={comment.id}
          nextIsAutoComment={nextIsAutoComment}
          previousIsAutoComment={previousIsAutoComment}
        />
      );
  }
};

const TaskCommentsContainerProps: ContainerCfg<Comment, CommentType> = {
  getCommentComponent,
  isAutoComment,
};

const TaskCommentsContainer = CommentsContainer(TaskCommentsContainerProps);

export default TaskCommentsContainer;
