import React, { FC, FocusEventHandler, ReactElement, ReactNode, useRef } from 'react';
import {
  Avatar,
  Grid,
  InputAdornment,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Typography,
} from '@material-ui/core';
import {
  AccountCircle as AccountCircleIcon,
  Business as BusinessIcon,
  CheckCircle as CheckCircleIcon,
} from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ValueType } from 'react-select';
/**
 * Types
 */
import { Institution } from 'ReduxFlow/Reducers/Institutions/Types';
import { MarginType } from 'Utils/globalTypes';
import { User } from 'ReduxFlow/Reducers/Auth/Types';
import CustomAutocomplete, {
  CustomSelectOptionProps,
  stylesForCrmAndUserBuilder,
} from 'Components/NewUIComponents/CustomAutocomplete';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    allUsersAvatar: {
      marginRight: theme.spacing(0.625),
    },
    allUsersContainer: {
      marginBottom: theme.spacing(0.625),
    },
    multiInstitutions: {
      marginLeft: -theme.spacing(0.8125),
      '&:last-child': {
        marginLeft: 0,
      },
    },
    multiInstitutionsContainer: {
      maxWidth: `fit-content`,
    },
    multiInstitutionsText: {
      overflow: `hidden`,
      textOverflow: `ellipsis`,
      wordBreak: `keep-all`,
    },
    selectorIcon: {
      cursor: `pointer`,
      height: theme.spacing(1.75),
      width: theme.spacing(1.75),
    },
    selectorIconColorDark: {
      color: theme.palette.primary.dark,
    },
    userSelectorAvatar: {
      height: theme.spacing(2.125),
      marginTop: theme.spacing(0.375),
      width: theme.spacing(2.125),
    },
    userSelectorAvatarContainer: {
      marginRight: theme.spacing(2),
      maxWidth: theme.spacing(2.125),
    },
    userSelectorInstitutions: {
      height: theme.spacing(1.625),
      width: theme.spacing(1.625),
    },
    usersSelectorIcon: { color: `#3AACD7` },
  }),
);

interface UserSelectProps {
  defaultOptions?: User[];
  disabled?: boolean;
  error?: boolean;
  hasInstance?: boolean;
  helperText?: string;
  inputClassName?: string;
  institutionIds?: number[];
  isEditing?: boolean;
  label: string;
  name: string;
  multiple?: boolean;
  noOptionsMessage?: string;
  onBlur?: FocusEventHandler;
  onChange: (id?: number | number[]) => void;
  onClose?: () => void;
  onFocus?: FocusEventHandler;
  preventSearch?: boolean;
  renderAllUsers?: boolean;
  required?: boolean;
  sectorIds?: string;
  value?: number | number[];
}

const UserSelect: FC<UserSelectProps> = ({
  defaultOptions,
  disabled,
  error,
  hasInstance,
  helperText,
  inputClassName,
  institutionIds,
  isEditing,
  label,
  name,
  noOptionsMessage,
  multiple,
  onBlur,
  onChange,
  onClose,
  onFocus,
  preventSearch,
  renderAllUsers,
  required,
  sectorIds,
  value,
}) => {
  const classes = useStyles();
  const crmClasses = stylesForCrmAndUserBuilder();
  const allUsersContainerRef = useRef<HTMLDivElement>(null);

  function getUserAvatarAdornment(selectedData: ValueType<User>): ReactNode {
    if (renderAllUsers && Array.isArray(selectedData) && selectedData.length > 1 && !isEditing) {
      return (
        <InputAdornment classes={{ root: crmClasses.startAdornment }} position="start">
          <Grid container direction="column" ref={allUsersContainerRef}>
            {selectedData.map(
              (renderUser: User): ReactNode => (
                <Grid className={classes.allUsersContainer} container item key={renderUser.id} wrap="nowrap">
                  <Avatar
                    className={`${classes.userSelectorAvatar} ${classes.allUsersAvatar}`}
                    src={renderUser.avatarThumb}
                  >
                    <AccountCircleIcon />
                  </Avatar>
                  <Typography
                    style={{ fontSize: 14, marginTop: 3, overflow: `hidden`, textOverflow: `ellipsis` }}
                  >{`${renderUser.firstName} ${renderUser.lastName}`}</Typography>
                </Grid>
              ),
            )}
          </Grid>
        </InputAdornment>
      );
    }
    if (Array.isArray(selectedData) && selectedData.length > 0) {
      const newSelectedData = selectedData.slice(0, 4);
      return (
        <InputAdornment classes={{ root: crmClasses.startAdornment }} position="start">
          {newSelectedData.map(
            (renderUser: User): ReactNode => (
              <Avatar className={classes.userSelectorAvatar} key={renderUser.id} src={renderUser.avatarThumb}>
                <AccountCircleIcon />
              </Avatar>
            ),
          )}
        </InputAdornment>
      );
    }
    if (!Array.isArray(selectedData) && selectedData) {
      return (
        <InputAdornment classes={{ root: crmClasses.startAdornment }} position="start">
          <Avatar className={classes.userSelectorAvatar} src={(selectedData as User).avatarThumb}>
            <AccountCircleIcon />
          </Avatar>
        </InputAdornment>
      );
    }
    return (
      <InputAdornment classes={{ root: crmClasses.startAdornment }} position="start">
        <Avatar className={classes.userSelectorAvatar}>
          <AccountCircleIcon />
        </Avatar>
      </InputAdornment>
    );
  }

  function getUserInputValue(selectedData: ValueType<User>): string {
    if (renderAllUsers && !isEditing && Array.isArray(selectedData) && selectedData.length > 1) return ``;
    if (selectedData && Array.isArray(selectedData) && selectedData.length > 1) {
      return `${selectedData.length} pessoas`;
    }
    if (selectedData && Array.isArray(selectedData) && selectedData.length === 1) {
      const selectedUser = selectedData[0];
      return `${selectedUser.firstName} ${selectedUser.lastName}`;
    }
    if (selectedData && !Array.isArray(selectedData)) {
      const selectedUser = selectedData as User;
      return `${selectedUser.firstName} ${selectedUser.lastName}`;
    }
    return ``;
  }

  function renderInstitutionInfo(data: Institution[]): ReactNode {
    const isSingle = data.length === 1;
    return (
      <Grid container wrap="nowrap">
        <Grid
          alignItems="center"
          alignContent="center"
          className={isSingle ? classes.userSelectorAvatarContainer : classes.multiInstitutionsContainer}
          container
          direction={isSingle ? undefined : `row-reverse`}
          item
          justify={!isSingle ? `flex-end` : `flex-start`}
          style={{
            marginRight: isSingle ? 16 : 8,
          }}
        >
          {data.map(
            (inst: Institution): ReactNode => (
              <Avatar
                className={
                  !isSingle
                    ? `${classes.userSelectorInstitutions} ${classes.multiInstitutions}`
                    : classes.userSelectorInstitutions
                }
                key={inst.id}
                src={inst.institutionAvatar}
              >
                <BusinessIcon />
              </Avatar>
            ),
          )}
        </Grid>
        <Grid container item wrap="nowrap">
          {isSingle && <Typography display="inline">{data[0].institutionName}</Typography>}
          {data.length === 2 && (
            <Typography display="inline">
              {data[0].institutionName} e {data[1].institutionName}
            </Typography>
          )}
          {!isSingle && data.length > 2 && (
            <>
              <Typography className={classes.multiInstitutionsText} display="inline">
                {data[0].institutionName}
              </Typography>
              <Typography display="inline">&nbsp;e outras {data.length}</Typography>
            </>
          )}
        </Grid>
      </Grid>
    );
  }

  function renderUserInfo(data: User): ReactNode {
    return (
      <Grid container key={data.id}>
        <Grid className={classes.userSelectorAvatarContainer} item>
          <Avatar className={classes.userSelectorAvatar} src={data.avatarThumb}>
            <AccountCircleIcon />
          </Avatar>
        </Grid>
        <Grid item>
          <Typography>{`${data.firstName} ${data.lastName}`}</Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <CustomAutocomplete<User>
      customSearchParams={{
        sectorIds,
      }}
      defaultOptions={defaultOptions}
      disabled={disabled}
      error={error}
      hasInstance={hasInstance}
      helperText={helperText}
      getOptionValue={(selectedData: ValueType<User>): string => {
        return `${(selectedData as User).id}`;
      }}
      inputClassName={inputClassName}
      inputHidden={hasInstance && !isEditing && !!value && Array.isArray(value) && value.length > 1}
      inputStyles={
        renderAllUsers && !isEditing && allUsersContainerRef.current
          ? {
              background: `#fafafa 0% 0% no-repeat padding-box`,
              minHeight: allUsersContainerRef.current.offsetHeight + 24,
            }
          : {
              background: undefined,
              minHeight: undefined,
            }
      }
      inputValue={getUserInputValue}
      institutionIds={institutionIds}
      isEditing={isEditing}
      label={label}
      margin={MarginType.dense}
      mountTooltipTitle={(selectedData: ValueType<User>): ReactElement => {
        if (Array.isArray(selectedData)) {
          return <>{selectedData.map((item: User): ReactNode => renderUserInfo(item))}</>;
        }
        if (!Array.isArray(selectedData) && selectedData) {
          return (
            <>
              {renderUserInfo(selectedData as User)}
              {renderInstitutionInfo((selectedData as User).userInstitution)}
            </>
          );
        }
        return <>Nenhum usuário selecionado</>;
      }}
      multiple={!!multiple}
      name={name}
      noOptionsMessage={noOptionsMessage}
      onBlur={onBlur}
      onChange={onChange}
      onClose={onClose}
      onFocus={onFocus}
      openUp={false}
      Option={(props: CustomSelectOptionProps<User>): ReactElement => {
        const {
          data: optionData,
          innerProps,
          innerRef,
          isDisabled: autoCompleteDisabled,
          isFocused,
          isMulti,
          isSelected,
        } = props;
        return (
          <MenuItem
            buttonRef={innerRef}
            component="div"
            selected={isFocused}
            style={{
              backgroundColor: isMulti && isSelected ? `#E6F7FF` : undefined,
              color: autoCompleteDisabled ? `#d3d3d3` : ``,
              display: `block`,
              fontWeight: isSelected ? 500 : 400,
              padding: `8px 16px`,
            }}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...innerProps}
          >
            <ListItemText
              primary={<>{renderUserInfo(optionData)}</>}
              primaryTypographyProps={{ component: `div` }}
              secondary={<>{renderInstitutionInfo(optionData.userInstitution)}</>}
              secondaryTypographyProps={{ component: `div` }}
            />
            {multiple && isSelected && (
              <ListItemSecondaryAction classes={{ root: classes.usersSelectorIcon }}>
                <CheckCircleIcon />
              </ListItemSecondaryAction>
            )}
          </MenuItem>
        );
      }}
      placeholder="Nenhum"
      preventSearch={preventSearch}
      required={required}
      startAdornment={(selectedData: ValueType<User>): ReactNode => {
        return getUserAvatarAdornment(selectedData);
      }}
      searchUrl="account/users/select/"
      value={value}
    />
  );
};

export default UserSelect;
