import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  Checkbox,
  DialogContent,
  FormControlLabel,
  Grid,
  InputAdornment,
  ListItemText,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useTheme } from '@material-ui/core/styles';
import { MyDatePickerStyles } from '../MyDatePicker';
import InstitutionsSelector from '../InstitutionsSelector';
import UserFormMultiSelect from './UserFormMultiSelect';
import { EmailMask, sexOptions, useStyles } from './SecondStepConstants';
import { CPFMask } from '../NewUIComponents/InputMasks';

const SecondStep = ({
  errors,
  formInstanceId,
  generateRandomPassword,
  groups: groupOptions,
  handleFieldChange,
  institutions,
  isLoading,
  isProfile,
  isViewing,
  loggedUserInfo,
  sectors: sectorOptions,
  userData,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme)();
  const isAdmin = loggedUserInfo && loggedUserInfo.isAdminOrSuperAdmin && !loggedUserInfo.isSuperAdmin;
  const [filteredInstitutions, setFilteredInstitutions] = useState([]);

  useEffect(() => {
    if (isProfile) {
      setFilteredInstitutions(institutions);
    } else {
      const allowedInstitutions = loggedUserInfo.userInstitution;
      setFilteredInstitutions(
        isAdmin
          ? institutions.map(v =>
              allowedInstitutions.find(a => a.id === v.id) || allowedInstitutions.find(a => a.id === v.parent)
                ? v
                : { ...v, disabled: true },
            )
          : institutions,
      );
    }
  }, [loggedUserInfo, institutions, isAdmin, isProfile]);

  useEffect(() => {
    if (institutions.length === 1 && !formInstanceId && !userData.userInstitution.length)
      handleFieldChange(`userInstitution`)(institutions.map(inst => inst.id));
  }, [formInstanceId, handleFieldChange, institutions, userData.userInstitution]);

  useEffect(() => {
    if (!userData.groups) {
      const normalGroup = groupOptions.find(v => v.name === `Normal`);
      handleFieldChange(`groups`)(normalGroup.id);
    }
  }, [groupOptions, handleFieldChange, userData.groups]);

  const {
    admissionDate: admissionDateError,
    birthDate: birthDateError,
    cpf: cpfError,
    email: emailError,
    emailExists,
    firstName: firstNameError,
    groups: groupsError,
    lastName: lastNameError,
    password: passwordError,
    sector: sectorErrors,
    sex: sexError,
    userInstitution: userInstitutionError,
  } = errors;

  const {
    admissionDate,
    birthDate,
    cpf,
    email,
    enableEmailNotifications,
    firstName,
    groups,
    isFakeEmail,
    isMultiOrganization,
    lastName,
    password,
    sector,
    sex,
    userInstitution,
  } = userData;

  const showFakeEmailCheckbox = !!formInstanceId && !isProfile && !isMultiOrganization;

  const isEmailDisabledAndReadOnly =
    isViewing ||
    (isFakeEmail && isProfile) ||
    (!emailExists && !formInstanceId) ||
    (!!formInstanceId && isMultiOrganization && !isProfile);

  const isNamesDisabledAndReadOnly = isViewing || (!!formInstanceId && isMultiOrganization && !isProfile);

  return (
    <DialogContent>
      <form id="userForm">
        <Grid container>
          <Grid item xs={12}>
            {isLoading ? (
              <Skeleton
                className={showFakeEmailCheckbox ? classes.oneFieldFirst : classes.oneField}
                height={56}
                variant="rect"
              />
            ) : (
              <TextField
                autoComplete="off"
                className={showFakeEmailCheckbox ? classes.oneFieldFirst : classes.oneField}
                error={!!emailExists || !!emailError}
                fullWidth
                helperText={emailExists || emailError || undefined}
                id="id_email"
                InputProps={{
                  inputProps: {
                    disabled: isEmailDisabledAndReadOnly,
                  },
                  inputComponent: isFakeEmail ? EmailMask : undefined,
                  readOnly: isEmailDisabledAndReadOnly,
                }}
                label="E-mail"
                name="email"
                onChange={handleFieldChange(`email`)}
                required
                type={!isFakeEmail || isViewing ? `email` : null}
                value={email}
                variant={isEmailDisabledAndReadOnly ? `filled` : `outlined`}
              />
            )}
          </Grid>
          {showFakeEmailCheckbox && (
            <Grid className={classes.oneFieldCheckbox} item xs={12} sm={6}>
              {isLoading ? (
                <Skeleton className={classes.oneFieldCheckbox} height={56} variant="rect" />
              ) : (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isFakeEmail}
                      color="primary"
                      inputProps={{
                        readOnly: isViewing,
                      }}
                      onChange={handleFieldChange(`isFakeEmail`)}
                      value="isFakeEmail"
                    />
                  }
                  disabled={isViewing}
                  label="Sem e-mail"
                  required
                />
              )}
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            {isLoading ? (
              <Skeleton className={classes.twoFieldsLeft} height={56} variant="rect" />
            ) : (
              <TextField
                autoComplete="off"
                className={classes.twoFieldsLeft}
                error={!!firstNameError}
                helperText={firstNameError || undefined}
                id="id_firstName"
                InputProps={{
                  inputProps: {
                    disabled: isNamesDisabledAndReadOnly,
                  },
                  readOnly: isNamesDisabledAndReadOnly,
                }}
                label="Nome"
                name="firstName"
                onChange={handleFieldChange(`firstName`)}
                required
                value={firstName}
                variant={isNamesDisabledAndReadOnly ? `filled` : `outlined`}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {isLoading ? (
              <Skeleton className={classes.twoFieldsRight} height={56} variant="rect" />
            ) : (
              <TextField
                autoComplete="off"
                className={classes.twoFieldsRight}
                error={!!lastNameError}
                helperText={lastNameError || undefined}
                id="id_lastName"
                InputProps={{
                  inputProps: {
                    disabled: isNamesDisabledAndReadOnly,
                  },
                  readOnly: isNamesDisabledAndReadOnly,
                }}
                label="Sobrenome"
                name="lastName"
                onChange={handleFieldChange(`lastName`)}
                required
                value={lastName}
                variant={isNamesDisabledAndReadOnly ? `filled` : `outlined`}
              />
            )}
          </Grid>
          {isLoading ? (
            <Skeleton className={classes.oneField} height={56} variant="rect" />
          ) : (
            !isMultiOrganization &&
            !formInstanceId && (
              <Grid item xs={12}>
                <TextField
                  autoComplete="off"
                  className={classes.oneField}
                  error={!!passwordError}
                  fullWidth
                  helperText={
                    passwordError ||
                    `A senha acima é provisória, o usuário será solicitado à alterar a senha no primeiro login.`
                  }
                  id="id_password"
                  InputProps={{
                    inputProps: {
                      disabled: isViewing,
                    },
                    endAdornment: (
                      <InputAdornment>
                        <Button
                          className={classes.buttonText}
                          color="primary"
                          onClick={generateRandomPassword}
                          size="small"
                        >
                          Gerar senha
                        </Button>
                      </InputAdornment>
                    ),
                    readOnly: isViewing,
                  }}
                  label="Senha"
                  name="password"
                  onChange={handleFieldChange(`password`)}
                  required
                  value={password}
                  variant={!isViewing ? `outlined` : `filled`}
                />
              </Grid>
            )
          )}
          <Grid item xs={12} sm={6}>
            {isLoading ? (
              <Skeleton className={classes.twoFieldsLeft} height={56} variant="rect" />
            ) : (
              <MyDatePickerStyles
                autoOk
                customClasses={{ textField: classes.twoFieldsLeft }}
                disabled={isViewing}
                error={!!birthDateError}
                handleDateChange={handleFieldChange(`birthDate`)}
                helperText={birthDateError || ``}
                InputProps={{
                  classes: { disabled: classes.customDisabled },
                  readOnly: isViewing,
                }}
                label="Data de nascimento"
                name="birthDate"
                required
                value={birthDate}
                variant={!isViewing ? `outlined` : `filled`}
              />
            )}
          </Grid>
          {!isProfile && (
            <Grid item xs={12} sm={6}>
              {isLoading ? (
                <Skeleton className={classes.twoFieldsRight} height={56} variant="rect" />
              ) : (
                <MyDatePickerStyles
                  autoOk
                  customClasses={{ textField: classes.twoFieldsRight }}
                  disabled={isViewing}
                  error={!!admissionDateError}
                  handleDateChange={handleFieldChange(`admissionDate`)}
                  helperText={admissionDateError || ``}
                  InputProps={{
                    classes: { disabled: classes.customDisabled },
                    readOnly: isViewing,
                  }}
                  label="Data de admissão"
                  name="admissionDate"
                  required
                  value={admissionDate}
                  variant={!isViewing ? `outlined` : `filled`}
                />
              )}
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            {isLoading ? (
              <Skeleton
                className={!isProfile ? classes.twoFieldsLeft : classes.twoFieldsRight}
                height={56}
                variant="rect"
              />
            ) : (
              <TextField
                autoComplete="off"
                className={!isProfile ? classes.twoFieldsLeft : classes.twoFieldsRight}
                error={!!cpfError}
                id="id_cpf"
                helperText={cpfError || null}
                InputProps={{
                  inputProps: {
                    disabled: isViewing,
                  },
                  inputComponent: CPFMask,
                  readOnly: isViewing,
                }}
                label="CPF"
                margin="normal"
                name="cpf"
                onChange={handleFieldChange(`cpf`)}
                required
                value={cpf}
                variant={!isViewing ? `outlined` : `filled`}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {isLoading ? (
              <Skeleton
                className={isProfile ? classes.twoFieldsLeft : classes.twoFieldsRight}
                height={56}
                variant="rect"
              />
            ) : (
              <TextField
                className={isProfile ? classes.twoFieldsLeft : classes.twoFieldsRight}
                error={!!sexError}
                helperText={sexError || ``}
                id="id_sex"
                label="Sexo"
                inputProps={{
                  disabled: isViewing,
                  readOnly: isViewing,
                }}
                name="sex"
                onChange={handleFieldChange(`sex`)}
                required
                select
                SelectProps={{
                  renderValue: selected => sexOptions[selected],
                }}
                value={sex}
                variant={!isViewing ? `outlined` : `filled`}
              >
                {Object.keys(sexOptions).map(key => (
                  <MenuItem key={key} value={key}>
                    <ListItemText primary={sexOptions[key]} />
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Grid>
          {isProfile && (
            <Grid className={classes.checkboxContainer} item xs={12} sm={6}>
              {isLoading ? (
                <Skeleton className={classes.twoFieldsRight} height={56} variant="rect" />
              ) : (
                <FormControlLabel
                  className={classes.twoFieldsRightCheckbox}
                  control={
                    <Checkbox
                      checked={enableEmailNotifications}
                      color="primary"
                      inputProps={{
                        readOnly: isViewing,
                      }}
                      onChange={handleFieldChange(`enableEmailNotifications`)}
                      value="enableEmailNotifications"
                    />
                  }
                  disabled={isViewing}
                  label="Notificações por e-mail"
                  required
                />
              )}
            </Grid>
          )}
        </Grid>
        {!isProfile && (
          <Grid container>
            <Grid item xs={12}>
              {isLoading ? (
                <Skeleton className={classes.oneField} height={56} variant="rect" />
              ) : (
                <InstitutionsSelector
                  disabled={isViewing}
                  error={!!userInstitutionError}
                  helperText={userInstitutionError || ``}
                  id="id_institutions"
                  inputProps={{
                    readOnly: isViewing,
                  }}
                  institutions={filteredInstitutions}
                  multiple
                  name="userInstitution"
                  onChange={handleFieldChange(`userInstitution`)}
                  required
                  selectClass={classes.oneField}
                  value={userInstitution}
                  variant={!isViewing ? `outlined` : `filled`}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              {isLoading ? (
                <Skeleton className={classes.twoFieldsLeft} height={56} variant="rect" />
              ) : (
                <UserFormMultiSelect
                  classes={{ formControl: classes.twoFieldsLeft }}
                  displayNameKey="name"
                  error={!!sectorErrors}
                  helperText={sectorErrors || ``}
                  idKey="id"
                  inputId="id_sector"
                  inputProps={{
                    disabled: isViewing,
                    readOnly: isViewing,
                  }}
                  label="Setores"
                  labelId="labelSectors"
                  name="sector"
                  onChange={handleFieldChange(`sector`)}
                  options={sectorOptions}
                  required
                  value={sector}
                  variant={!isViewing ? `outlined` : `filled`}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              {isLoading ? (
                <Skeleton className={classes.twoFieldsRight} height={56} variant="rect" />
              ) : (
                <TextField
                  className={classes.twoFieldsRight}
                  error={!!groupsError}
                  helperText={groupsError || ``}
                  id="id_groups"
                  label="Grupos"
                  inputProps={{
                    disabled: isViewing || isAdmin,
                    readOnly: isViewing || isAdmin,
                  }}
                  name="groups"
                  onChange={handleFieldChange(`groups`)}
                  required
                  select
                  SelectProps={{
                    renderValue: selected => {
                      const optionSelected = groupOptions.filter(group => group.id === selected);
                      if (optionSelected.length) return optionSelected[0].name;
                      return null;
                    },
                  }}
                  value={groups}
                  variant={!(isViewing || isAdmin) ? `outlined` : `filled`}
                >
                  {groupOptions.map(group => {
                    const { id, name } = group;
                    return (
                      <MenuItem key={id} value={id}>
                        <ListItemText primary={name} />
                      </MenuItem>
                    );
                  })}
                </TextField>
              )}
            </Grid>
          </Grid>
        )}
      </form>
    </DialogContent>
  );
};

SecondStep.defaultProps = {
  errors: {},
  formInstanceId: undefined,
  groups: [],
  institutions: [],
  isLoading: false,
  isProfile: false,
  isViewing: false,
  loggedUserInfo: undefined,
  sector: [],
};

SecondStep.propTypes = {
  errors: PropTypes.shape({
    cpf: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  formInstanceId: PropTypes.number,
  generateRandomPassword: PropTypes.func.isRequired,
  groups: PropTypes.arrayOf(PropTypes.object),
  handleFieldChange: PropTypes.func.isRequired,
  institutions: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  isProfile: PropTypes.bool,
  isViewing: PropTypes.bool,
  loggedUserInfo: PropTypes.shape({
    id: PropTypes.number,
    isAdminOrSuperAdmin: PropTypes.bool,
    isHoldingUser: PropTypes.bool,
    isSuperAdmin: PropTypes.bool,
    isSuperuser: PropTypes.bool,
    userInstitution: PropTypes.arrayOf(PropTypes.object),
  }),
  sector: PropTypes.arrayOf(PropTypes.object),
  userData: PropTypes.shape({
    admissionDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    avatar: PropTypes.string,
    avatarThumb: PropTypes.string,
    birthDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    cpf: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    groups: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isFakeEmail: PropTypes.bool,
    isMultiOrganization: PropTypes.bool,
    lastName: PropTypes.string,
    password: PropTypes.string,
    sector: PropTypes.arrayOf(PropTypes.number),
    sex: PropTypes.string,
    userInstitution: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
};

export const TestSecondStep = SecondStep;

/* istanbul ignore next */
const mapStateToProps = state => ({
  groups: state.Groups.data,
  institutions: state.Institutions.data,
  sectors: state.Sectors.data,
});

export default connect(mapStateToProps)(SecondStep);
