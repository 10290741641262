import React, { MouseEvent, SFC } from 'react';
import { bindActionCreators, Dispatch, ActionCreatorsMapObject } from 'redux';
import { connect } from 'react-redux';
import { Dialog, withDialog } from 'muibox';
import { Box, Button, LinearProgress, Paper, Typography } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import { ApplicationState } from '../../ReduxFlow/Reducers';
import { InstitutionsState } from '../../ReduxFlow/Reducers/Institutions/Types';
import * as institutionsActions from '../../ReduxFlow/Reducers/Institutions/Actions';
import InstitutionsCard, { CardLevel } from '../../Components/InstitutionsCard';
import DialogInstForm from './DialogInstForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      boxShadow: `0px 1px 3px #00000033`,
      padding: theme.spacing(3),
    },
  }),
);

type InstitutionsProps = {
  addOrUpdateRequest: Function;
  closeInstitutionsRequest: Function;
  dialog: Dialog;
  institutionsState: InstitutionsState;
  openInstitutionsRequest: Function;
};

const Institutions: SFC<InstitutionsProps> = ({
  addOrUpdateRequest,
  closeInstitutionsRequest,
  dialog,
  institutionsState,
  openInstitutionsRequest,
}) => {
  const classes = useStyles();
  const { data: institutions, dialogOpen, isLoading } = institutionsState;
  const organization = institutions.find(inst => inst.isOrganization);

  return (
    <Paper className={classes.paper} elevation={0}>
      {isLoading && (
        <Box width="100%">
          <LinearProgress />
        </Box>
      )}
      <Box alignContent="center" alignItems="center" display="flex" justifyContent="space-between">
        {!isLoading && !!institutions.length && (
          <Typography variant="h6">Instituições de {organization && organization.institutionName}</Typography>
        )}
        {(isLoading || !institutions.length) && <Skeleton height={40} variant="rect" width={200} />}
        <Button color="primary" onClick={(): void => openInstitutionsRequest({ dialog })} variant="contained">
          <AddIcon /> Adicionar
        </Button>
      </Box>
      {institutions.map(inst => {
        const { institutionAvatar, institutionName } = inst;
        if (organization && inst.id === organization.id) {
          return (
            <InstitutionsCard
              institutionAvatar={institutionAvatar}
              institutionName={institutionName}
              key={inst.id}
              level={CardLevel.card0}
              onClick={(): void => openInstitutionsRequest({ dialog, id: inst.id })}
            />
          );
        }
        if (organization && inst.parent === organization.id) {
          return (
            <InstitutionsCard
              institutionAvatar={institutionAvatar || organization.institutionAvatar}
              institutionName={institutionName}
              key={inst.id}
              level={CardLevel.card1}
              onClick={(): void => openInstitutionsRequest({ dialog, id: inst.id })}
            />
          );
        }
        const father = institutions.find(parent => inst.parent === parent.id);
        return (
          <InstitutionsCard
            institutionAvatar={
              institutionAvatar ||
              (father && father.institutionAvatar) ||
              (organization && organization.institutionAvatar)
            }
            institutionName={institutionName}
            key={inst.id}
            level={CardLevel.card2}
            onClick={(): void => openInstitutionsRequest({ dialog, id: inst.id })}
          />
        );
      })}
      {dialogOpen && (
        <DialogInstForm
          addOrUpdateRequest={addOrUpdateRequest}
          dialog={dialog}
          dialogOpen={dialogOpen}
          institutionsState={institutionsState}
          onClose={(event: MouseEvent<HTMLButtonElement> | {}, reason?: `backdropClick` | `escapeKeyDown`): void =>
            closeInstitutionsRequest()
          }
        />
      )}
    </Paper>
  );
};

const InstitutionsTests = Institutions;

export { InstitutionsTests };

/* istanbul ignore next */
const mapStateToProps = (state: ApplicationState): { institutionsState: InstitutionsState } => ({
  institutionsState: state.Institutions,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch): ActionCreatorsMapObject =>
  bindActionCreators(institutionsActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withDialog()(Institutions));
