import { all, call, put } from 'redux-saga/effects';
import { Action } from 'redux';
import { SagaIterator } from 'redux-saga';
import { Dialog } from 'muibox';

/**
 * API from axios
 */
import api from '../../../Services/api';

import {
  addOrUpdateFailure,
  addSuccess,
  closeCategoryRequest,
  closeCategorySuccess,
  closeSubCategoryRequest,
  loadRequest,
  loadSuccess,
  openCategorySuccess,
  updateSuccess,
} from './Actions';

import { InventoryCategoryActionTypes, InventoryCategory, InventorySubcategory } from './Types';

interface LoadActionType extends Action {
  payload: {
    dialog: Dialog;
  };
}

interface OpenOrCloseDialogType extends Action {
  payload: {
    dialog: Dialog;
    id?: number | string;
  };
}

interface AddOrUpdateActionType extends Action {
  payload: {
    dialog: Dialog;
    id?: number | string;
    postData: InventorySubcategory;
  };
}

interface AddOrUpdateSubCategoryActionType extends Action {
  payload: {
    dialog: Dialog;
    category: InventoryCategory;
    id?: number | string;
    postData: InventorySubcategory;
  };
}

export function* loadInventoryCategories(action: LoadActionType): SagaIterator {
  try {
    const response = yield call(api.get, `/inventory/categories/`);
    yield put(loadSuccess(response.data));
  } catch (error) {
    const { dialog } = action.payload;
    dialog.alert(`Não foi possível connectar com o servidor!`);
    yield put({ type: InventoryCategoryActionTypes.LOAD_ERRORS });
  }
}

export function* openInventoryCategoriesDialog(action: OpenOrCloseDialogType): SagaIterator {
  try {
    if (action.payload.id) {
      const response = yield call(api.get, `/inventory/categories/${action.payload.id}/`);
      yield put(openCategorySuccess(response.data));
    } else {
      yield put(openCategorySuccess());
    }
  } catch (error) {
    const { dialog } = action.payload;
    dialog.alert(
      `Não foi possível realizar essa ação, por favor realize um refresh no navegador e caso o problema não seja resolvido entre em contato com nosso suporte!`,
    );
    yield put({ type: InventoryCategoryActionTypes.LOAD_ERRORS });
  }
}

export function* closeInventoryCategoriesDialog(action: OpenOrCloseDialogType): SagaIterator {
  try {
    yield put(closeCategorySuccess());
  } catch (error) {
    const { dialog } = action.payload;
    dialog.alert(
      `Não foi possível realizar essa ação, por favor realize um refresh no navegador e caso o problema não seja resolvido entre em contato com nosso suporte!`,
    );
  }
}

export function* addOrUpdateInventoryCategories(action: AddOrUpdateActionType): SagaIterator {
  const { dialog, postData } = action.payload;
  try {
    if (postData.id) {
      const response = yield call(api.patch, `/inventory/categories/${postData.id}/`, postData);
      yield all([put(updateSuccess(response.data)), put(loadRequest(dialog))]);
    } else {
      const response = yield call(api.post, `/inventory/categories/`, postData);
      yield all([put(addSuccess(response.data)), put(loadRequest(dialog))]);
    }
    yield put(closeCategoryRequest());
  } catch (error) {
    dialog.alert(
      `Não foi possível realizar essa ação, por favor realize um refresh no navegador e caso o problema não seja resolvido entre em contato com nosso suporte!`,
    );
    yield put(addOrUpdateFailure(error.response.data));
  }
}

export function* addOrUpdateInventorySubCategories(action: AddOrUpdateSubCategoryActionType): SagaIterator {
  const { dialog, category, postData } = action.payload;
  try {
    if (category) {
      postData.category = category.id;
    }
    if (postData.id) {
      const response = yield call(api.patch, `/inventory/subcategories/${postData.id}/`, postData);
      yield all([put(updateSuccess(response.data)), put(loadRequest(dialog))]);
    } else {
      const response = yield call(api.post, `/inventory/subcategories/`, postData);
      yield all([put(addSuccess(response.data)), put(loadRequest(dialog))]);
    }
    yield put(closeSubCategoryRequest());
  } catch (error) {
    dialog.alert(
      `Não foi possível realizar essa ação, por favor realize um refresh no navegador e caso o problema não seja resolvido entre em contato com nosso suporte!`,
    );
    yield put(addOrUpdateFailure(error.response.data));
  }
}
