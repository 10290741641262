import React, { FC } from 'react';
import { InputBaseComponentProps } from '@material-ui/core';
import MaskedInput from 'react-text-mask';

const PassportMask: FC<InputBaseComponentProps> = props => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      // eslint-disable-next-line
      {...other}
      ref={(ref: MaskedInput): void => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-9]/, /[0-9]/, /[0-9]/, `-`, /[0-9]/, /[0-9]/, `-`, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
      placeholderChar={`\u2000`}
    />
  );
};

export default PassportMask;
