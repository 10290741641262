import { call, put } from 'redux-saga/effects';
import { Action, Store } from 'redux';
import api from '../../../Services/api';

import {
  addFailure,
  addSuccess,
  changeSectorsRangeSuccess,
  closeSectorsRequest,
  closeSectorsSuccess,
  loadFailure,
  loadSuccess,
  openSectorsSuccess,
  updateFailure,
  updateSuccess,
} from './Actions';

interface AppAction extends Action {
  payload?: any;
}

interface AppStore extends Store {
  Auth?: any;
}

export function* loadSectors(action: AppAction) {
  try {
    const response = yield call(api.get, `/account/sectors/`);
    yield put(loadSuccess(response.data));
  } catch (err) {
    yield put(loadFailure());
  }
}

export function* addSector(action: AppAction) {
  try {
    const response = yield call(api.post, `/account/sectors/`, action.payload);
    yield put(addSuccess(response.data));
    yield put(closeSectorsRequest());
  } catch (error) {
    yield put(addFailure(error.response.data));
  }
}

export function* updateSector(action: AppAction) {
  try {
    const response = yield call(api.patch, `/account/sectors/${action.payload.id}/`, action.payload.data);
    yield put(updateSuccess(response.data));
    yield put(closeSectorsRequest());
  } catch (error) {
    yield put(updateFailure(error.response.data));
  }
}

export function* openSectorsModal(data: AppAction) {
  try {
    if (data.payload.id) {
      const response = yield call(api.get, `/account/sectors/${data.payload.id}/`);
      yield put(openSectorsSuccess([response.data]));
    } else {
      yield put(openSectorsSuccess([]));
    }
  } catch (error) {
    alert(`Não foi possível realizar essa ação, tente mais tarde!`); // eslint-disable-line
  }
}

export function* closeSectorsModal() {
  try {
    yield put(closeSectorsSuccess());
  } catch (error) {
    alert(`Não foi possível realizar essa ação, tente mais tarde!`); // eslint-disable-line
  }
}

export function* changeSectorsRange() {
  try {
    yield put(changeSectorsRangeSuccess());
  } catch (error) {
    console.error(error); // eslint-disable-line
    alert(`Não foi possível realizar essa ação, tente mais tarde!`); // eslint-disable-line
  }
}
