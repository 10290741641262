export default class NoEmailOrFakeMailError extends Error {
  response: {
    data: {
      code: string;
      message: string;
    };
  };

  constructor(name: string, message: string) {
    super(name);
    this.message = message;
    this.name = name;
    this.response = {
      data: {
        code: this.name,
        message,
      },
    };
  }
}
