import { action, ActionType } from 'typesafe-actions';
import { Dialog } from 'muibox';
import { OmbudsmanSourcesTypes, OmbudsmanSourcesError, OmbudsmanSource } from './Types';

interface OkAndCancelType {
  color: string;
  text: string;
  variant: string;
}

export const loadRequest = (dialog: Dialog, query?: object): ActionType<typeof action> =>
  action(OmbudsmanSourcesTypes.LOAD_REQUEST, { dialog, query });
export const loadSuccess = (data: OmbudsmanSource[]): ActionType<typeof action> =>
  action(OmbudsmanSourcesTypes.LOAD_SUCCESS, { data });
export const loadFailure = (): ActionType<typeof action> => action(OmbudsmanSourcesTypes.LOAD_FAILURE);
export const openOmbudsmanSourceRequest = (data: { dialog: Dialog; id?: number | string }): ActionType<typeof action> =>
  action(OmbudsmanSourcesTypes.OPEN_REQUEST, data);
export const openOmbudsmanSourceSuccess = (data?: OmbudsmanSource[]): ActionType<typeof action> =>
  action(OmbudsmanSourcesTypes.OPEN_SUCCESS, data);
export const closeOmbudsmanSourceRequest = (): ActionType<typeof action> => action(OmbudsmanSourcesTypes.CLOSE_REQUEST);
export const closeOmbudsmanSourceSuccess = (): ActionType<typeof action> => action(OmbudsmanSourcesTypes.CLOSE_SUCCESS);
export const addOrUpdateRequest = (data: {
  dialog: Dialog;
  postData: { description?: string; id: number | string; name: string };
}): ActionType<typeof action> => action(OmbudsmanSourcesTypes.ADD_OR_UPDATE_REQUEST, data);
export const addOrUpdateFailure = (errors: OmbudsmanSourcesError): ActionType<typeof action> =>
  action(OmbudsmanSourcesTypes.ADD_OR_UPDATE_FAILURE, { errors });
export const addSuccess = (data: OmbudsmanSource): ActionType<typeof action> =>
  action(OmbudsmanSourcesTypes.ADD_SUCCESS, { data });
export const updateSuccess = (data: OmbudsmanSource): ActionType<typeof action> =>
  action(OmbudsmanSourcesTypes.UPDATE_SUCCESS, { data });
