import { Reducer } from 'redux';
import { CRMsState, CRMsTypes, CRM } from './Types';

export const INITIAL_STATE: CRMsState = {
  data: [],
  dialogOpen: false,
  error: false,
  filterList: { isActive: [`Ativo`] },
  formErrors: {},
  formInstance: undefined,
  isLoading: false,
  isSaving: false,
  page: 0,
  pagination: {
    count: 0,
    next: undefined,
    previous: undefined,
  },
  rowsPerPage: 100,
  searchText: undefined,
  sort: {},
};

const reducer: Reducer<CRMsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CRMsTypes.LOAD_REQUEST:
      return { ...state, isLoading: true, error: false, isSaving: false, formErrors: {} };
    case CRMsTypes.LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        isSaving: false,
        data: action.payload.data.results,
        pagination: {
          count: action.payload.data.count,
          next: action.payload.data.next,
          previous: action.payload.data.previous,
        },
        toApprove: action.payload.data.toApprove,
      };
    case CRMsTypes.LOAD_FAILURE:
    case CRMsTypes.LOAD_ERRORS:
      return { ...state, isLoading: false, error: true, isSaving: false, data: [] };
    case CRMsTypes.ADD_OR_UPDATE_REQUEST:
      return { ...state, loading: false, error: false, isSaving: true };
    case CRMsTypes.ADD_OR_UPDATE_FAILURE:
      return {
        ...state,
        error: true,
        formErrors: action.payload.errors,
        isSaving: false,
        loading: false,
      };
    case CRMsTypes.ADD_SUCCESS:
      return {
        ...state,
        data: [...state.data, action.payload.data],
        error: false,
        isSaving: false,
        loading: false,
      };
    case CRMsTypes.UPDATE_SUCCESS:
      return {
        ...state,
        data: state.data.map(
          (inv: CRM): CRM => {
            if (inv.id === action.payload.data.id) {
              return action.payload.data;
            }
            return inv;
          },
        ),
        error: false,
        isSaving: false,
        loading: false,
      };
    case CRMsTypes.OPEN_REQUEST:
      return { ...state, isLoading: true, formErrors: {} };
    case CRMsTypes.OPEN_SUCCESS:
      return { ...state, dialogOpen: true, formInstance: action.payload, isLoading: false };
    case CRMsTypes.CLOSE_REQUEST:
      return { ...state, isLoading: true, dialogOpen: false };
    case CRMsTypes.CLOSE_SUCCESS:
      return {
        ...state,
        dialogOpen: false,
        formInstance: undefined,
        isLoading: false,
        formErrors: {},
        inactivateModal: false,
      };
    case CRMsTypes.CHANGE_SORT:
      return {
        ...state,
        sort: { [action.payload.activeColumn]: { order: action.payload.sort } },
        isLoading: true,
      };
    case CRMsTypes.CHANGE_FILTERS:
      return {
        ...state,
        filterList: action.payload.filterList,
        page: 0,
        rowsPerPage: action.payload.rowsPerPage || state.rowsPerPage,
        searchText: action.payload.searchText,
        isLoading: true,
      };
    case CRMsTypes.CHANGE_PAGES:
      return {
        ...state,
        page: action.payload.page,
        rowsPerPage: action.payload.rowsPerPage,
        isLoading: true,
      };
    case CRMsTypes.MOBILE_INFINITE_SCROLL_REQUEST:
      return {
        ...state,
        page: state.page + 1,
        isLoading: true,
      };
    case CRMsTypes.MOBILE_INFINITE_SCROLL_SUCCESS:
      return {
        ...state,
        pagination: {
          count: action.payload.count,
          next: action.payload.next,
          previous: action.payload.previous,
        },
        data: state.data
          .concat(action.payload.results)
          .filter((inv: CRM, idx: number, array: CRM[]) => array.indexOf(inv) === idx),
        isLoading: false,
      };
    default:
      return state;
  }
};

export default reducer;
