import { call, put, select } from 'redux-saga/effects';
import { Action, Store } from 'redux';
import { SagaIterator } from 'redux-saga';

/**
 * API from axios
 */
import api from '../../../Services/api';
import { buildQueryStringFromObject } from '../../../Utils';

/**
 * Modal actions
 */
import { closeInternalDocsSuccess, loadInternalDocsSuccess, openInternalDocsSuccess } from './Actions';

/**
 * Get access token
 */
interface AppStore extends Store {
  Auth?: any;
  InternalDocs?: any;
}
interface AppAction extends Action {
  payload?: any;
}

export function* loadInternalDocs(): SagaIterator {
  try {
    const internalDocsState = yield select((state: AppStore) => state.InternalDocs);
    let url = `/internaldocs/documentinfo/?page=${internalDocsState.page + 1}&length=${
      internalDocsState.rowsPerPage
    }&${buildQueryStringFromObject(internalDocsState.filterList, internalDocsState.searchText)}`;
    if (Object.entries(internalDocsState.sort).length !== 0) {
      url += `&sort=${Object.keys(internalDocsState.sort)[0]},${
        internalDocsState.sort[Object.keys(internalDocsState.sort)[0]].order
      }`;
    }
    const response = yield call(api.get, url);
    yield put(loadInternalDocsSuccess(response.data));
  } catch (error) {
    alert(`Não foi possível conectar com o servidor!`); // eslint-disable-line
  }
}

export function* openInternalDocsModal(data: AppAction): SagaIterator {
  try {
    if (data.payload.id) {
      const response = yield call(api.get, `/internaldocs/documentinfo/${data.payload.id}/`);
      yield put(openInternalDocsSuccess([response.data]));
    } else {
      yield put(openInternalDocsSuccess([]));
    }
  } catch (error) {
    alert(`Não foi possível realizar essa ação, tente mais tarde!`); // eslint-disable-line
  }
}

export function* closeInternalDocsModal(): SagaIterator {
  try {
    yield put(closeInternalDocsSuccess());
  } catch (error) {
    alert(`Não foi possível realizar essa ação, tente mais tarde!`); // eslint-disable-line
  }
}
