import React, { FC } from 'react';
import { CircularProgress, Fab, Fade, Grid, Paper, Slide, Typography } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Dialog } from 'muibox';
import { CRM } from '../../ReduxFlow/Reducers/CRMs/Types';
import { User } from '../../ReduxFlow/Reducers/Auth/Types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      margin: theme.spacing(1.25),
      width: theme.spacing(6.25),
      height: theme.spacing(6.25),
    },
    instAvatar: {
      maxWidth: theme.spacing(5),
    },
    userText: {
      wordWrap: `break-word`,
    },
    userWrapper: {
      margin: `5px auto 5px auto`,
      maxWidth: `98%`,
    },
    fab: {
      position: `absolute`,
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    loaderWrapper: {
      position: `absolute`,
      bottom: theme.spacing(3.75),
      width: `98%`,
      textAlign: `center`,
    },
    footer: {
      width: `100vw`,
      height: `56px`,
      position: `absolute`,
      bottom: 0,
      left: 0,
    },
  }),
);

type MobileCRMProps = {
  data: CRM[];
  dialog: Dialog;
  formatDocumentByType: (documentInfo?: { document?: string; documentType?: string }) => string;
  handleOpen: (data: { dialog: Dialog; id?: string | number }) => void;
  isLoading: boolean;
  loggedUserInfo: User;
};

const MobileCRM: FC<MobileCRMProps> = ({
  data,
  dialog,
  formatDocumentByType,
  handleOpen,
  isLoading,
  loggedUserInfo,
}) => {
  const classes = useStyles();
  return (
    <>
      {data.map(crm => (
        <Slide key={crm.id} in direction="up" mountOnEnter unmountOnExit>
          <Paper className={classes.userWrapper} onClick={(): void => handleOpen({ dialog, id: crm.id })}>
            <Grid container alignItems="center">
              <Grid item xs={12} className={classes.userText}>
                <Typography>{crm.fullName}</Typography>
                <Typography color="textSecondary">
                  <small>{crm.email}</small>
                </Typography>
                <Typography color="textSecondary">
                  <small>{formatDocumentByType(crm.documentInfo)}</small>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Slide>
      ))}
      {isLoading && (
        <div className={classes.loaderWrapper}>
          <Fade
            in={isLoading}
            style={{
              transitionDelay: isLoading ? `800ms` : `0ms`,
            }}
            unmountOnExit
          >
            <CircularProgress />
          </Fade>
        </div>
      )}
      {loggedUserInfo && (
        <Fab className={classes.fab} color="primary" onClick={(): void => handleOpen({ dialog })}>
          <AddIcon />
        </Fab>
      )}
    </>
  );
};

export default MobileCRM;
