import React, { FormEvent, SFC, useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TasksCategoryDialog, TasksCategoryTypes } from 'ReduxFlow/Reducers/TasksCategories/Types';
import { Sector } from 'ReduxFlow/Reducers/Sectors/Types';
import HelperBox, { HelperBoxVariantTypes } from 'Components/NewUIComponents/HelperBox';
import UserFormMultiSelect from 'Components/UserForm/UserFormMultiSelect';
import { getTaskTypeName } from 'Utils/tasks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      marginBottom: theme.spacing(3.25),
      width: `98%`,
    },
    twoFieldsLeft: {
      [theme.breakpoints.up(`sm`)]: {
        margin: `0px ${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(1)}px`,
        width: `92%`,
      },
      [theme.breakpoints.down(`sm`)]: {
        margin: `0px ${theme.spacing(1)}px ${theme.spacing(4)}px ${theme.spacing(1)}px`,
        width: `98%`,
      },
    },
  }),
);

export type TasksCategoryFormProps = {
  allSectors: Sector[];
  taskCategory: TasksCategoryDialog;
  tasksCategoryTypes: TasksCategoryTypes[];
  handleFieldChange: Function;
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
};

const TasksCategoryForm: SFC<TasksCategoryFormProps> = ({
  allSectors,
  taskCategory,
  tasksCategoryTypes,
  handleFieldChange,
  onSubmit,
}) => {
  const classes = useStyles();
  const updateSectors = handleFieldChange(`sectors`);
  const { name, description, sectors, subCategories, types } = taskCategory;
  const [translatedTypes, setTranslatedTypes] = useState<TasksCategoryTypes[]>([]);
  const [orderedSectors, setOrderedSectors] = useState<Sector[]>([]);
  const [multiSectorError, setMultiSectorError] = useState(false);

  useEffect(
    () =>
      setTranslatedTypes(
        tasksCategoryTypes
          .map(v => ({
            ...v,
            name: getTaskTypeName(v.name),
          }))
          .sort((a, b) => a.name.localeCompare(b.name)),
      ),
    [tasksCategoryTypes],
  );

  useEffect(() => setOrderedSectors(allSectors.sort((a, b) => a.name.localeCompare(b.name))), [allSectors]);

  const checkSectorChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const hasWorkSchedulle = !!(subCategories && subCategories.find(v => v.sectorWorkSchedulleEnabled));
    if (!hasWorkSchedulle || !event.target.value || event.target.value.length <= 1) {
      setMultiSectorError(false);
      updateSectors(event);
      return;
    }
    setMultiSectorError(true);
  };

  return (
    <form id="docsCategoriesForm" onSubmit={onSubmit}>
      <TextField
        autoFocus
        className={classes.textField}
        id="id_name"
        label="Nome da categoria"
        name="name"
        onChange={handleFieldChange(`name`)}
        value={name}
        variant="outlined"
      />
      <TextField
        className={classes.textField}
        helperText="Dica: Descreva as características dos itens desta categoria"
        id="id_description"
        label="Descrição da categoria"
        name="description"
        onChange={handleFieldChange(`description`)}
        value={description}
        variant="outlined"
      />
      <UserFormMultiSelect
        classes={{ formControl: classes.twoFieldsLeft }}
        disabled={false}
        displayNameKey="name"
        error={false}
        helperText="Dica: Os tipos de to-dos onde esta categoria será exibida"
        idKey="id"
        inputId="id_type"
        inputProps={{
          disabled: false,
          readOnly: false,
        }}
        label="Tipos"
        labelId="labelTipos"
        name="types"
        onChange={handleFieldChange(`types`)}
        options={translatedTypes}
        required
        value={types}
        variant="outlined"
      />
      <UserFormMultiSelect
        classes={{ formControl: classes.twoFieldsLeft }}
        disabled={false}
        displayNameKey="name"
        error={false}
        helperText="Dica: Os setores onde esta categoria será exibida"
        idKey="id"
        inputId="id_sector"
        inputProps={{
          disabled: false,
          readOnly: false,
        }}
        label="Setores"
        labelId="labelSetores"
        name="sectors"
        onChange={checkSectorChange}
        options={orderedSectors}
        required
        value={sectors}
        variant="outlined"
      />
      {multiSectorError && (
        <HelperBox
          text="Não é possível adicionar mais de 1 setor à categoria pois 1 ou mais subcategorias estão contando as horas dos 
          indicadores de TPR e TR somente durante o horário de trabalho do setor"
          variant={HelperBoxVariantTypes.warning}
        />
      )}
    </form>
  );
};

export default TasksCategoryForm;
