/**
 * Action types
 */
export enum ExternalDocsTypes {
  LOAD_REQUEST = '@ExternalDocs/LOAD_REQUEST', // eslint-disable-line
  LOAD_SUCCESS = '@ExternalDocs/LOAD_SUCCESS', // eslint-disable-line
  LOAD_FAILURE = '@ExternalDocs/LOAD_FAILURE', // eslint-disable-line
  OPEN_REQUEST = '@ExternalDocs/OPEN_REQUEST', // eslint-disable-line
  OPEN_SUCCESS = '@ExternalDocs/OPEN_SUCCESS', // eslint-disable-line
  CLOSE_REQUEST = '@ExternalDocs/CLOSE_REQUEST', // eslint-disable-line
  CLOSE_SUCCESS = '@ExternalDocs/CLOSE_SUCCESS', // eslint-disable-line
  CHANGE_SORT = '@ExternalDocs/CHANGE_SORT', // eslint-disable-line
  CHANGE_FILTERS = '@ExternalDocs/CHANGE_FILTERS', // eslint-disable-line
  CHANGE_PAGES = '@ExternalDocs/CHANGE_PAGES', // eslint-disable-line
}
/**
 * Data types
 */
export interface ExternalDoc {
  id: number;
  categories: string;
  category: number;
  closedAt: string;
  cost: string;
  createdAt: string[];
  institution: number;
  institutions: { id: number; avatar: string; name: string };
  isActive: boolean;
  isExcluded: boolean;
  isPublic: boolean;
  location: string;
  notes: string;
  owner: number;
  ownerFullName: string;
  sector: number;
  sharedSectors: number[];
  sharedUsers: number[];
  supplier: number;
  title: string;
  validity: string;
}
/**
 * State types
 */
export interface ExternalDocsState {
  readonly count: number;
  readonly data: ExternalDoc[];
  readonly expiredOrExpiring: number;
  readonly filterList: { [key: string]: string[] };
  readonly formInstance: any[];
  readonly isLoading: boolean;
  readonly modalOpen: boolean;
  readonly page: number;
  readonly rowsPerPage: number;
  readonly searchText?: string;
  readonly sort?: { [key: number]: { order: string } };
}
