import React from 'react';
/**
 * Redux dependencies
 */
import { connect } from 'react-redux';
/**
 * Moment JS
 */
import Moment from 'moment';
import 'moment/locale/pt-br';
/**
 * Material UI - Core
 */
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  FormControlLabel,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Slide,
  TextField,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
/**
 * Material UI - Icons
 */
import {
  Add as AddIcon,
  AccountCircleOutlined as AccountCircleOutlinedIcon,
  AttachFile,
  Build as BuildIcon,
  BusinessCenterOutlined as BusinessCenterOutlinedIcon,
  Clear,
  Close,
  Delete,
  Done,
  EventAvailableOutlined,
  FormatAlignLeft,
  FormatListBulleted,
  Info as InfoIcon,
  PersonAddDisabledOutlined as PersonAddDisabledOutlinedIcon,
  RecordVoiceOverOutlined,
  ReportProblem,
  WarningOutlined,
} from '@material-ui/icons';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { withDialog } from 'muibox';
/**
 * genericAvatar for users without avatar
 */
import { bindActionCreators } from 'redux';
import genericAvatar from 'Images/GenericAvatar/genericavatar.png';
import * as tasksActions from 'ReduxFlow/Reducers/Tasks/Actions';
import {
  loadRequest as loadCategoriesRequestAction,
  loadTypesRequest as loadCategoriesTypesRequestAction,
} from 'ReduxFlow/Reducers/TasksCategories/Actions';
import { loadRequest as loadOmbudsmanSourcesRequestAction } from 'ReduxFlow/Reducers/OmbudsmanSources/Actions';
import { addOrUpdateRequest, closeCRMRequest, openCRMRequest } from 'ReduxFlow/Reducers/CRMs/Actions';
/**
 * My components
 */
import { MyDateTimePickerStyles } from 'Components/MyDatePicker';
import InstitutionsSelector from 'Components/InstitutionsSelector';
import UserSelector from 'Components/UserSelector';
import api from 'Services/api';
import CategoriesSelector from 'Components/Categories/CategoriesSelector';
import AutocompleteOnDemand from 'Components/AutocompleteOnDemand';
import HelperBox, { HelperBoxVariantTypes } from 'Components/NewUIComponents/HelperBox';
import CommentsInput from 'Components/Comments/CommentsInput';
import TaskAttachsContainer from 'Components/NewUIComponents/TaskAttachsContainer';
import SLATooltip from 'Components/SLATooltip';
import MoodPicker from 'Components/MoodPicker';
import CustomAutocomplete from 'Components/NewUIComponents/CustomAutocomplete';
import { MarginType } from 'Utils/globalTypes';
import TaskCommentsContainer, { CommentType } from 'Components/NewUIComponents/CommentsContainer/Tasks';
import { formatDocumentByType } from '../../CRMs';
import DialogCRMForm from '../../CRMs/DialogCRMForm';
import InventoryInfoDialog from './InventoryInfoDialog';

const SlideTransition = React.forwardRef((props, ref) => <Slide direction="up" {...props} ref={ref} />);
SlideTransition.displayName = `SlideTransition`;
/**
 * Defining Moment locale
 */
Moment.locale(`pt-BR`);

const styles = theme => ({
  autocompleteAvatar: {
    marginRight: theme.spacing(0.5),
    height: theme.spacing(1.5),
    width: theme.spacing(1.5),
  },
  autocompleteText: {
    whiteSpace: `nowrap`,
  },
  autocompleteTextEllipsis: {
    marginRight: theme.spacing(0.5),
    overflow: `hidden`,
    textOverflow: `ellipsis`,
    whiteSpace: `nowrap`,
  },
  avatar: {
    height: `30px`,
    width: `30px`,
  },
  button: {
    margin: theme.spacing(1),
  },
  card: {
    height: `fit-content`,
    overflow: `hidden`,
    padding: theme.spacing(2),
  },
  chipClass: {
    '&:hover': {
      '& svg': {
        visibility: `visible`,
      },
    },
  },
  container: {
    display: `flex`,
    flexWrap: `wrap`,
  },
  crmAddButton: {
    color: theme.palette.primary.dark,
    width: `100%`,
  },
  customSelectClass: {
    width: `100%`,
    marginBottom: 15,
  },
  customSelectClassWithTextField: {
    width: `95%`,
    [theme.breakpoints.down(`sm`)]: {
      width: `98%`,
    },
    '&:first-child': {
      marginRight: theme.spacing(1),
    },
  },
  datePicker: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    width: `98%`,
  },
  deleteIcon: {
    [theme.breakpoints.up(`md`)]: {
      visibility: `hidden`,
    },
  },
  dense: {
    marginTop: 19,
  },
  descriptionTextField: {
    width: `99%`,
  },
  dialogTitle: {
    display: `flex`,
    flexFlow: `row wrap`,
    alignConent: `center`,
    justifyContent: `flex-end`,
    width: `100%`,
    padding: 0,
  },
  fab: {
    position: `relative`,
    bottom: -20,
    left: theme.spacing(15),
  },
  fileWrapper: {
    width: `100%`,
    height: `98%`,
    padding: `auto`,
    '& > img': {
      margin: `auto`,
    },
  },
  listItemRootAdd: {
    marginLeft: -theme.spacing(0.5),
    marginRight: theme.spacing(1.125),
    marginTop: theme.spacing(0.125),
    maxHeight: theme.spacing(1.625),
    maxWidth: theme.spacing(3),
    minWidth: theme.spacing(3),
  },
  listItemRootOther: {
    marginRight: theme.spacing(1.125),
    marginTop: theme.spacing(0.625),
    minWidth: theme.spacing(2.5),
  },
  listItemRootPerson: {
    marginRight: theme.spacing(1.125),
    marginTop: theme.spacing(1),
    minWidth: theme.spacing(2.5),
  },
  menu: {
    width: 200,
  },
  observationWrapper: {
    whiteSpace: `pre-line`,
  },
  outlinedTextField: {
    marginRight: theme.spacing(1),
    minWidth: 243,
    width: `98%`,
  },
  selectorIcon: {
    cursor: `pointer`,
    height: theme.spacing(1.75),
    width: theme.spacing(1.75),
  },
  selectorIconColorDark: {
    color: theme.palette.primary.dark,
  },
  startAdornment: {
    alignItems: `flex-start`,
  },
  startAdornmentIcon: {
    fontSize: 13,
    marginTop: theme.spacing(0.5),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: `100%`,
  },
  titleText: {
    alignContent: `center`,
    alignItems: `center`,
    display: `flex`,
    padding: `${theme.spacing(1)}px 0 0 ${theme.spacing(4)}px`,
    margin: `auto`,
  },
});

class TaskForm extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }

  state = {
    attachs: [],
    comment: ``,
    commentModal: false,
    comments: [],
    commentsRange: 5,
    cost: ``,
    createTime: ``,
    creator: ``,
    crm: ``,
    description: ``,
    emailSend: { send: false, task: null },
    estimatedEndDate: null,
    execLocation: ``,
    externalCallId: ``,
    feedbackMood: ``,
    feedbackText: ``,
    feedbackTime: null,
    formErrors: {},
    handlingStatus: false,
    id: null,
    institution: [],
    inventory: null,
    inventoryInfo: false,
    inventorySelector: false,
    isActive: true,
    isCommentSaving: false,
    isCommentsFetching: true,
    isExcluded: false,
    isSaving: false,
    isSavingFeedback: false,
    marker: [],
    markerSelector: null,
    ncAction: ``,
    ncCause: ``,
    ncCorrectiveAction: ``,
    ncDescription: ``,
    ncEfficiency: ``,
    ncObservations: ``,
    newFeedbackTime: null,
    ombSource: ``,
    priority: ``,
    prioritySelector: null,
    responsible: ``,
    saves: false,
    sector: [],
    situation: ``,
    situationSelector: null,
    startDate: null,
    // eslint-disable-next-line
    startedBy: this.props.loggedUserInfo.user.id,
    subcategory: ``,
    title: ``,
    tprAndTr: undefined,
    users: [],
  };

  componentDidMount() {
    const {
      dialog,
      formInstance,
      loadCategoriesRequest,
      loadCategoriesTypesRequest,
      loadOmbudsmanSourcesRequest,
      Institutions,
      loggedUserInfo,
    } = this.props;
    loadCategoriesRequest(dialog);
    loadCategoriesTypesRequest(dialog);
    loadOmbudsmanSourcesRequest(dialog);
    if (Institutions.length === 1) this.setState({ institution: Institutions.map(inst => inst.id) });
    if (formInstance.length) {
      this.mountStateFromFormInstance(formInstance);
    } else {
      this.setState({ users: [loggedUserInfo.user.id] });
    }
  }

  componentDidUpdate(nextProps) {
    const { formInstance } = nextProps;
    const { id } = this.state;
    if (!!formInstance.length && id !== formInstance[0].id) {
      this.mountStateFromFormInstance(formInstance);
    }
  }

  componentWillUnmount() {
    const { emailSend } = this.state;
    if (emailSend.send) {
      api.get(`/tasks/sendmail/?type=UpdateTask&id=${emailSend.task}`);
    }
  }

  mountStateFromFormInstance = formInstance => {
    this.commentsFetch();
    this.attachsFetch();
    const {
      call,
      cost,
      createTime,
      creator,
      crm,
      description,
      estimatedEndDate,
      feedbackMood,
      feedbackText,
      feedbackTime,
      id,
      institution,
      isActive,
      isExcluded,
      marker,
      nc,
      ombudsman,
      preventive,
      priority,
      responsible,
      sector,
      startDate,
      startedBy,
      subcategory,
      title,
      tprAndTr,
      users,
    } = formInstance[0];
    const { newFeedbackTime } = this.state;
    this.setState({
      cost,
      createTime,
      creator,
      crm: crm || ``,
      description,
      estimatedEndDate,
      execLocation: preventive ? preventive.location : ``,
      externalCallId: call || preventive ? (call || preventive).externalCallId : ``,
      feedbackMood: feedbackMood || `ok`,
      feedbackText,
      feedbackTime: feedbackTime || newFeedbackTime,
      id,
      institution,
      inventory: call || preventive ? parseInt((call || preventive).inventory, 10) : null,
      isActive,
      isExcluded,
      marker,
      ncAction: nc ? nc.ncAction : ``,
      ncCause: nc ? nc.ncCause : ``,
      ncCorrectiveAction: nc ? nc.ncCorrectiveAction : ``,
      ncDescription: nc ? nc.ncDescription : ``,
      ncEfficiency: nc ? nc.ncEfficiency : ``,
      ncObservations: nc ? nc.ncObservations : ``,
      ombSource: ombudsman ? ombudsman.source : ``,
      priority,
      responsible,
      sector,
      situation: call ? call.situation : ``,
      startDate,
      startedBy,
      subcategory: subcategory || ``,
      title,
      tprAndTr,
      users,
    });
  };

  commentsFetch = async () => {
    const { formInstance } = this.props;
    try {
      const response = await api.get(`/tasks/comments/?task=${formInstance[0].id}`);
      this.setState({
        comments: response.data,
      });
    } catch (error) {
      // eslint-disable-next-line
      console.error(`Erro no fetch de comentários!`, error.response);
      alert(`Não foi possível conectar com o servidor e buscar os comentários.`);
    } finally {
      this.setState({
        isCommentsFetching: false,
      });
    }
  };

  attachsFetch = () => {
    const { formInstance } = this.props;
    api
      .get(`/tasks/attachs/?task=${formInstance[0].id}`)
      .then(json => {
        this.setState({ attachs: json.data });
      })
      .catch(error => {
        // eslint-disable-next-line
        console.error(`Erro no fetch de anexos!`, error.response);
        alert(`Não foi possível conectar com o servidor e buscar os comentários.`);
      });
  };

  currentSubcategoryHasSla = () => {
    const { subcategory } = this.state;
    return this.getTaskCategories().some(cat => {
      return cat.subCategories.some(subCat => {
        if (subCat.id === subcategory && subCat.hasRtAndFrt) {
          return true;
        }
        return false;
      });
    });
  };

  handleSubmit = (id = null) => event => {
    event.persist();
    event.preventDefault();
    const { formType, formInstance, loadTasksRequest, handleClose, tasksCategories } = this.props;
    const { inventory } = this.state;
    if ([`Chamado`, `Preventiva`].indexOf(formType) === -1 && !!inventory) return;
    clearTimeout(this.submitTimeout);
    if (event.target.type === `search`) {
      return;
    }
    const timeout = id ? 4000 : 0;
    if (!id) {
      this.setState({
        isSaving: true,
      });
    }
    if (id) {
      this.setState({
        isSaving: true,
        emailSend: {
          send: Boolean(formInstance[0]),
          task: Boolean(formInstance[0]) && formInstance[0].id,
        },
      });
    }
    this.submitTimeout = setTimeout(() => {
      const {
        crm,
        description,
        estimatedEndDate,
        execLocation,
        externalCallId,
        isActive,
        isExcluded,
        marker,
        ncAction,
        ncCause,
        ncCorrectiveAction,
        ncDescription,
        ncEfficiency,
        ncObservations,
        ombSource,
        priority,
        responsible,
        sector,
        startDate,
        startedBy,
        subcategory,
        title,
        users,
      } = this.state;
      const data = new FormData(event.target);

      const startDateBr = Moment(startDate || new Date()).format(`DD/MM/YYYY HH:mm:ss`);
      const estimatedEndDateBr = estimatedEndDate ? Moment(estimatedEndDate).format(`DD/MM/YYYY HH:mm:ss`) : undefined;

      if (!id) {
        data.append(`createTime`, startDateBr);
      }
      if (formType === `Preventiva`) {
        data.set(`ombudsman`, null);
        data.set(`nc`, null);
        data.set(`call`, null);
      } else if (formType === `Chamado`) {
        data.set(`ombudsman`, null);
        data.set(`nc`, null);
        data.set(`preventive`, null);
      } else if (formType === `Ouvidoria`) {
        data.set(`call`, null);
        data.set(`nc`, null);
        data.set(`preventive`, null);
      } else if (formType === `Não conformidade`) {
        data.set(`call`, null);
        data.set(`ombudsman`, null);
        data.set(`preventive`, null);
      }
      data.set(`description`, description);

      if (!this.currentSubcategoryHasSla()) {
        if (startDate) {
          data.set(`startDate`, startDateBr);
        }
        if (estimatedEndDate) {
          data.set(`estimatedEndDate`, estimatedEndDateBr);
        }
      } else {
        data.set(`startDate`, startDateBr);
      }
      if (estimatedEndDate) data.set(`estimatedEndDate`, estimatedEndDateBr);
      if (formType === `Não conformidade`) {
        tasksCategories.data.forEach(cat => {
          if (cat.subCategories) {
            cat.subCategories.forEach(subCat => {
              if (subCat.id === subcategory) {
                data.set(`title`, `${cat.name} > ${subCat.name}`);
              }
            });
          }
        });
      } else if (formType !== `Ouvidoria`) {
        data.set(`title`, title);
      }
      data.append(`users`, users);
      data.append(`sector`, sector);
      if (responsible) data.append(`responsible`, responsible);
      data.append(`started_by`, startedBy);
      data.append(`priority`, priority);
      data.append(`isActive`, isActive);
      data.append(`isExcluded`, isExcluded);
      data.append(`marker`, marker);
      if (inventory) {
        if (formType === `Preventiva`) {
          data.set(`preventive.inventory`, inventory);
        } else {
          data.set(`call.inventory`, inventory);
        }
      } else {
        data.append(`call`, null);
      }
      if (formType === `Tarefa`) data.delete(`call.inventory`);
      if (externalCallId) {
        if (formType === `Preventiva`) {
          data.append(`preventive.externalCallId`, externalCallId);
        } else if (inventory) {
          // fix call -> task conversion
          data.append(`call.externalCallId`, externalCallId);
        }
      }
      if (ncDescription) data.append(`nc.ncDescription`, ncDescription);
      if (ncAction) data.append(`nc.ncAction`, ncAction);
      if (ncCause) data.append(`nc.ncCause`, ncCause);
      if (ncCorrectiveAction) data.append(`nc.ncCorrectiveAction`, ncCorrectiveAction);
      if (ncEfficiency) data.append(`nc.ncEfficiency`, ncEfficiency);
      if (ncObservations) data.append(`nc.ncObservations`, ncObservations);
      if (ombSource) data.append(`ombudsman.source`, ombSource);
      if (execLocation) data.append(`preventive.location`, execLocation);
      if (crm) data.append(`crm`, crm);

      let apiPromise;
      if (id) {
        apiPromise = api.patch(`/tasks/tasks/${id}/`, data);
      } else {
        apiPromise = api.post(`/tasks/tasks/`, data);
      }
      apiPromise
        .then(json => {
          if (id) {
            const { comments } = this.state;
            loadTasksRequest();
            const newComments = json.data.comments.length
              ? Array.from(
                  new Set(
                    [...comments, ...json.data.comments].map(com => {
                      if (json.data.comments.some(incomingComment => incomingComment.id === com.id)) {
                        return json.data.comments.find(filterCom => filterCom.id === com.id);
                      }
                      return com;
                    }),
                  ),
                )
              : comments;
            this.setState({
              comments: newComments,
              formErrors: {},
              isSaving: false,
              saves: true,
              ...json.data.task,
            });
          } else {
            handleClose();
          }
        })
        .catch(error => {
          if (error.response && error.response.data) {
            this.setState({
              formErrors: error.response.data,
            });
          }
        })
        .finally(() => {
          if (id) this.commentsFetch();
          this.setState({
            isSaving: false,
          });
        });
    }, timeout);
  };

  handleOpenFile = (url = null) => {
    window.open(url);
  };

  handleFileDelete = id => async () => {
    const { dialog } = this.props;
    const { attachs, comments } = this.state;
    this.setState({
      isSaving: true,
    });
    dialog
      .confirm({
        title: `Excluir anexo?`,
        message: `Tem certeza que deseja excluir este anexo? Esta ação é irreversível.`,
        ok: { text: `Excluir`, color: `secondary` },
        cancel: { text: `Cancelar`, color: `default` },
      })
      .then(async () => {
        try {
          const apiPromise = await api.delete(`/tasks/attachs/${id}/`);
          const newComments = comments.map(comment => {
            if (comment.attachment === id) return { ...comment, commentType: CommentType.TASK_ADD_ATTACH_DELETED };
            return comment;
          });
          this.setState({
            attachs: [...attachs.filter(attach => attach.id !== id)],
            comments: [...newComments, ...apiPromise.data.comments],
          });
        } catch (e) {
          dialog.alert({
            title: e.response && e.response.status === 409 ? `Proibido` : `Erro`,
            message:
              e.response && e.response.status === 409
                ? `Você não tem permissão para exclusão desse anexo.`
                : `Não foi possível excluir o anexo, tente novamente mais tarde.`,
            ok: { color: `primary`, text: `Ok`, variant: `outlined` },
          });
        } finally {
          this.setState({
            isSaving: false,
          });
        }
      })
      .catch(() => {
        this.setState({
          isSaving: false,
        });
      });
  };

  handleFileSubmit = async event => {
    event.preventDefault();
    event.persist();
    const { dialog, loggedUserInfo, formInstance } = this.props;
    const { attachs, comments } = this.state;
    const data = new FormData();
    this.setState({
      isSaving: true,
    });
    data.set(`attachment`, event.target.files[0]);
    data.set(`task`, formInstance[0].id);
    data.set(`user`, loggedUserInfo.user.id);

    try {
      const apiPromise = await api.post(`/tasks/attachs/`, data);
      this.setState({
        attachs: [...attachs, apiPromise.data.attachment],
        comments: [...comments, ...apiPromise.data.comments],
      });
    } catch (e) {
      dialog.alert({
        title: `Proibido`,
        message: `Não foi possível enviar o arquivo, tente novamente mais tarde.`,
        ok: { text: `Ok`, color: `secondary` },
      });
    } finally {
      this.setState({
        isSaving: false,
      });
    }
  };

  commentsControll = total => () => {
    const { commentsRange } = this.state;
    if (total > commentsRange) {
      this.setState({
        commentsRange: total,
      });
    }
  };

  handleCommentDelete = id => async () => {
    const { dialog } = this.props;
    const { comments, formErrors } = this.state;
    this.setState({
      isCommentSaving: true,
    });
    dialog
      .confirm({
        title: `Excluir comentário?`,
        message: `Tem certeza que deseja excluir este comentário? Esta ação é irreversível.`,
        ok: { text: `Excluir`, color: `secondary` },
        cancel: { text: `Cancelar`, color: `default` },
      })
      .then(async () => {
        try {
          await api.delete(`/tasks/comments/${id}/`);
          this.setState({
            comments: comments.map(userComment => {
              if (userComment.id === id) return { ...userComment, commentType: CommentType.TASK_DELETE_COMMENT };
              return userComment;
            }),
          });
        } catch (e) {
          this.setState({
            formErrors: {
              ...formErrors,
              comment: `Você não tem permissão para exclusão do comentário`,
            },
          });
        } finally {
          this.setState({
            isCommentSaving: false,
          });
        }
      })
      .catch(() => {
        this.setState({
          isCommentSaving: false,
        });
      });
  };

  handleCommentSubmit = (comment = null, id = null) => async event => {
    event.preventDefault();
    event.stopPropagation();
    const { formInstance, loggedUserInfo } = this.props;
    const { comment: stateComment, comments, formErrors } = this.state;
    this.setState({
      isCommentSaving: true,
    });
    const data = new FormData();
    data.append(`comment`, stateComment);
    data.append(`date`, new Date().toLocaleString(`pt-br`));
    data.append(`user`, loggedUserInfo.user.id);
    data.append(`task`, formInstance[0].id);

    try {
      let apiPromise;
      let newStateComments;
      if (id) {
        apiPromise = await api.patch(`/tasks/comments/${id}/`, { comment });
        newStateComments = comments.map(com => (com.id === id ? { ...apiPromise.data } : com));
      } else {
        apiPromise = await api.post(`/tasks/comments/`, data);
        newStateComments = [...comments, apiPromise.data.comment];
        if (apiPromise.data.frtComment) newStateComments.push(apiPromise.data.frtComment);
      }
      this.setState({
        comments: newStateComments,
        comment: ``,
        formErrors: {
          ...formErrors,
          comment: ``,
        },
      });
    } catch (e) {
      this.setState({
        comment: ``,
        formErrors: {
          ...formErrors,
          comment: `Você não tem permissão para edição do comentário`,
        },
      });
    } finally {
      this.setState({
        handlingStatus: false,
        isCommentSaving: false,
      });
    }
  };

  handleStatus = (id = null, type) => async event => {
    event.preventDefault();
    const { handleClose } = this.props;
    const { comment, comments } = this.state;
    this.setState({
      handlingStatus: true,
      commentModal: false,
    });
    if (type === `isExcluded`) {
      if (!window.confirm(`Deseja realmente excluir esse registro?`)) {
        this.setState({
          handlingStatus: false,
        });
        return;
      }
      api
        .delete(`/tasks/tasks/${id}/`)
        .then(json => {
          this.setState(
            {
              handlingStatus: false,
            },
            () => handleClose(),
          );
        })
        .catch(error => {
          alert(`Não foi possível realizar a ação!`);
          this.setState({
            handlingStatus: false,
            formErrors: error.response.data,
          });
        });
      return;
    }

    try {
      const response = await api.patch(`/tasks/tasks/${id}/${type}/`, { comment });
      this.setState({
        comment: ``,
        comments: [...comments, ...response.data.comments],
        isActive: response.data.task.isActive,
        handlingStatus: false,
        tprAndTr: response.data.task.tprAndTr,
      });
      this.commentsFetch();
    } catch (error) {
      alert(`Não foi possível realizar a ação!`);
      this.setState({
        handlingStatus: false,
        formErrors: error.response.data,
      });
    }
  };

  handleDateChange = name => date => {
    const { formInstance } = this.props;
    this.setState({
      [name]: date,
    });
    if (formInstance.length) {
      this.formRef.current.dispatchEvent(new Event(`submit`, { cancelable: true, bubbles: true }));
    }
  };

  handleAutocompleteChange = name => (event, values) => {
    const { convertCallToTask, convertTaskToCall, dialog, formInstance, formType, loggedUserInfo } = this.props;
    if (Array.isArray(values)) {
      this.setState({
        [name]: values.map(val => {
          if (!Number.isFinite(val)) {
            return val.id;
          }
          return val;
        }),
      });
    } else if (values) {
      this.setState({
        [name]: values.id,
      });
    }
    if (name === `inventory`) {
      switch (true) {
        case formType === `Tarefa` && !!values:
          convertTaskToCall();
          if (formInstance.length)
            this.formRef.current.dispatchEvent(new Event(`submit`, { cancelable: true, bubbles: true }));
          break;
        case formType === `Chamado` && formInstance.length && loggedUserInfo.user.isAdminOrSuperAdmin && !values:
          // always allow clearing inventory field on creation, and on update only if user is admin
          dialog
            .confirm({
              title: `Remover Ativo`,
              message: `Remover o Ativo converterá automaticamente o chamado em uma tarefa.`,
              ok: { text: `Remover ativo`, variant: `contained`, color: `primary` },
              cancel: { text: `Cancelar`, color: `default` },
            })
            .then(() => {
              this.setState({ [name]: null });
              convertCallToTask();
              this.formRef.current.dispatchEvent(new Event(`submit`, { cancelable: true, bubbles: true }));
            })
            .catch(() => {}); // stupid api
          break;
        case formType === `Chamado` && !formInstance.length && !values:
          this.setState({ [name]: null });
          convertCallToTask();
          break;
        default:
          if (formInstance.length)
            this.formRef.current.dispatchEvent(new Event(`submit`, { cancelable: true, bubbles: true }));
          break;
      }
    } else if (!!formInstance.length && name !== `comment`) {
      this.formRef.current.dispatchEvent(new Event(`submit`, { cancelable: true, bubbles: true }));
    }
  };

  handleChange = name => event => {
    const { formInstance } = this.props;
    const { startDate } = this.state;
    if (event && !(typeof event === `number`)) {
      if (`target` in event) {
        if (event.target.type === `checkbox`) {
          this.setState({
            [name]: event.target.checked,
          });
        } else {
          this.setState({ [name]: event.target.value });
        }
      } else if (`value` in event) {
        this.setState({ [name]: event.value });
      } else if (Array.isArray(event) && !event.some(val => Number.isNaN(Number(val)))) {
        this.setState({ [name]: event });
      } else {
        this.setState({ [name]: event.map(val => val.value) });
      }
    } else if (typeof event === `number`) {
      this.setState({ [name]: event });
    } else {
      this.setState({ [name]: [] });
    }
    if (name === `subcategory`) {
      const subcategory = this.getTaskCategories().reduce(
        (acc, cat) => cat.subCategories.find(subcat => subcat.id === event.target.value) || acc,
        {},
      );
      if (subcategory.hasRtAndFrt) {
        const newStartDate = startDate || new Date();
        const estimatedEndDate = Moment(newStartDate).add(subcategory.resolutionTime / 1000, `seconds`);
        this.setState({
          estimatedEndDate: estimatedEndDate.toDate(),
          startDate: newStartDate,
        });
      }
    }
    if (!!formInstance.length && name !== `comment`) {
      this.formRef.current.dispatchEvent(new Event(`submit`, { cancelable: true, bubbles: true }));
    }
  };

  handleSelectorChange = name => event => {
    if (name === `inventoryInfo`) event.stopPropagation();
    const { isExcluded } = this.state;
    if (!isExcluded) {
      this.setState({
        // eslint-disable-next-line
        [name]: !this.state[name],
      });
    }
  };

  handleAnchorOpen = name => event => {
    this.setState({
      [name]: event.currentTarget,
    });
  };

  handleAnchorClose = name => () => {
    this.setState({
      [name]: null,
    });
  };

  handleAutoComplete = (name, id) => () => {
    const { formInstance } = this.props;
    const { marker } = this.state;
    if (marker.indexOf(id) > -1) {
      this.setState({
        [name]: marker.filter(val => val !== id),
      });
    } else {
      this.setState({
        [name]: marker.concat([id]),
      });
    }
    if (formInstance.length) {
      this.formRef.current.dispatchEvent(new Event(`submit`, { cancelable: true, bubbles: true }));
    }
  };

  handleSingleAutoComplete = (name, id) => () => {
    const { formInstance } = this.props;
    this.setState({
      [name]: id,
    });
    if (formInstance.length) {
      this.formRef.current.dispatchEvent(new Event(`submit`, { cancelable: true, bubbles: true }));
    }
  };

  getTaskTypeAdornment = formType => {
    let icon;
    switch (formType) {
      case `Preventiva`:
        icon = (
          <Tooltip title={formType}>
            <EventAvailableOutlined />
          </Tooltip>
        );
        break;
      case `Chamado`:
        icon = (
          <Tooltip title={formType}>
            <BuildIcon />
          </Tooltip>
        );
        break;
      case `Não conformidade`:
        icon = (
          <Tooltip title={formType}>
            <ReportProblem />
          </Tooltip>
        );
        break;
      case `Ouvidoria`:
        icon = (
          <Tooltip title={formType}>
            <RecordVoiceOverOutlined />
          </Tooltip>
        );
        break;
      default:
        icon = (
          <Tooltip title={formType}>
            <FormatListBulleted />
          </Tooltip>
        );
    }
    return <InputAdornment position="start">{icon}</InputAdornment>;
  };

  checkNcError = state => {
    const { formErrors } = this.state;
    switch (true) {
      case state === `ncAction`:
        return `nc` in formErrors && `ncAction` in formErrors.nc;
      case state === `ncDescription`:
        return `nc` in formErrors && `ncDescription` in formErrors.nc;
      default:
        return null;
    }
  };

  getTaskCategories = () => {
    const { formType, tasksCategories } = this.props;
    const { sector } = this.state;
    const remapFormType = {
      Tarefa: `tasks`,
      Ouvidoria: `ombudsmans`,
      Chamado: `calls`,
      Preventiva: `preventives`,
      'Não conformidade': `ncs`,
    };
    const typeInstance = tasksCategories.types.find(type => type.name === remapFormType[formType]);
    return tasksCategories.data.filter(
      category =>
        typeInstance &&
        (!category.types || category.types.length === 0 || category.types.indexOf(typeInstance.id) > -1) &&
        (!category.sectors || category.sectors.length === 0 || sector.some(sec => category.sectors.indexOf(sec) > -1)),
    );
  };

  categoryHasFeedback = () => {
    const { subcategory: subcategoryId } = this.state;
    if (!subcategoryId) return false;
    const categories = this.getTaskCategories();
    const subcategory = categories.reduce(
      (acc, cat) => cat.subCategories.find(subcat => subcat.id === subcategoryId) || acc,
      {},
    );
    return subcategory.hasFeedbackSurvey;
  };

  submitFeedback = async event => {
    try {
      const { formInstance } = this.props;
      const { comments, feedbackMood, feedbackText } = this.state;
      event.preventDefault();
      event.stopPropagation();

      this.setState({ isSavingFeedback: true });
      const response = await api.post(`/tasks/tasks/${formInstance[0].id}/feedback/`, { feedbackMood, feedbackText });
      this.setState({
        comments: response.data.comments.length ? [...comments, ...response.data.comments] : comments,
        newFeedbackTime: response.data.task.feedbackTime,
      });
    } catch (error) {
      this.setState({ formErrors: error.response.data });
    } finally {
      this.setState({ isSavingFeedback: false });
    }
  };

  getTextAndIcon = (optionData, iconClassName, fontSize, isTooltip) => {
    const { classes } = this.props;
    if (optionData && !Array.isArray(optionData)) {
      const newOptionData = optionData;
      if (newOptionData.fullName === `Anônimo`)
        return {
          className: classes.listItemRootOther,
          icon: <PersonAddDisabledOutlinedIcon className={iconClassName} fontSize={fontSize} />,
          text: isTooltip && <Typography variant="body1">{newOptionData.fullName}</Typography>,
        };
      if (newOptionData.type === `INDIVIDUAL`)
        return {
          className: classes.listItemRootPerson,
          icon: <AccountCircleOutlinedIcon className={iconClassName} fontSize={fontSize} />,
          text: (
            <>
              {isTooltip && <Typography variant="body1">{newOptionData.fullName}</Typography>}
              {newOptionData.documentInfo && newOptionData.documentInfo.document && (
                <Typography style={{ color: isTooltip ? `#8D8D8D` : `inherit` }} variant="body2">
                  <Typography color={isTooltip ? `inherit` : `textSecondary`} component="span" variant="body2">
                    {`${newOptionData.documentInfo.documentType}: `}
                  </Typography>
                  <Typography color={isTooltip ? `inherit` : `textPrimary`} component="span" variant="body2">
                    {formatDocumentByType(newOptionData.documentInfo, true)}
                  </Typography>
                </Typography>
              )}
              {newOptionData.birthDate && (
                <Typography style={{ color: isTooltip ? `#8D8D8D` : `inherit` }} variant="body2">
                  <Typography color={isTooltip ? `inherit` : `textSecondary`} component="span" variant="body2">
                    Nascimento:
                  </Typography>
                  <Typography color={isTooltip ? `inherit` : `textSecondary`} component="span" variant="body2">
                    {` ${new Date(newOptionData.birthDate).toLocaleDateString(`pt-BR`)}`}
                  </Typography>
                </Typography>
              )}
            </>
          ),
        };
      return {
        className: classes.listItemRootOther,
        icon: <BusinessCenterOutlinedIcon className={iconClassName} color="primary" fontSize={fontSize} />,
        text:
          newOptionData.documentInfo && newOptionData.documentInfo.document ? (
            <Typography component="div">CNPJ: {newOptionData.documentInfo.document}</Typography>
          ) : (
            undefined
          ),
      };
    }
    return {
      className: classes.listItemRootPerson,
      icon: <PersonAddDisabledOutlinedIcon className={iconClassName} fontSize={fontSize} />,
      text: isTooltip && <Typography variant="body1">Anônimo</Typography>,
    };
  };

  render() {
    const {
      Institutions,
      Markers,
      OmbudsmanSources,
      Sectors,
      classes,
      closeCRMRequestDialog,
      crmAddOrUpdateRequest,
      crms,
      dialog,
      formInstance,
      formType,
      handleClose,
      isConvertingTaskToCall,
      loggedUserInfo,
      openCRMRequestDialog,
      tasksCategories,
    } = this.props;
    const {
      attachs,
      comment,
      commentModal,
      comments,
      commentsRange,
      cost,
      crm,
      description,
      estimatedEndDate,
      execLocation,
      externalCallId,
      feedbackMood,
      feedbackText,
      feedbackTime,
      formErrors,
      handlingStatus,
      institution,
      inventory,
      inventoryInfo,
      isActive,
      isCommentSaving,
      isCommentsFetching,
      isExcluded,
      isSaving,
      isSavingFeedback,
      marker,
      markerSelector,
      newFeedbackTime,
      ombSource,
      priority,
      responsible,
      saves,
      sector,
      situation,
      startDate,
      startedBy,
      subcategory,
      title,
      tprAndTr,
      users,
    } = this.state;
    const hasFormInstance = !!formInstance.length;
    const ombudsmanSources = OmbudsmanSources.filter(v => v.isActive || v.id === ombSource);

    const { inventorySelect } =
      hasFormInstance && (formInstance[0].call || formInstance[0].preventive || { inventorySelect: undefined });
    const inventoryInput =
      formType === `Preventiva`
        ? {
            error: !!formErrors.preventive && !!formErrors.preventive.inventory,
            helperText: formErrors.preventive && formErrors.preventive.inventory ? formErrors.preventive.inventory : ``,
            id: `id_preventive.inventory`,
            name: `preventive.inventory`,
          }
        : {
            error: !!formErrors.call && !!formErrors.call.inventory,
            helperText: formErrors.call && formErrors.call.inventory ? formErrors.call.inventory : ``,
            id: `id_call.inventory`,
            name: `call.inventory`,
          };
    const org = Institutions.find(inst => inst.parent === null);
    const institutionIds =
      org && institution.indexOf(org.id) === -1
        ? institution
            .reduce((acc, val) => {
              const selectedInstitution = Institutions.find(inst => inst.id === val);
              if (org && selectedInstitution) {
                return [
                  ...acc,
                  selectedInstitution.id,
                  ...Institutions.filter(inst => inst.parent === selectedInstitution.id).map(inst => inst.id),
                ];
              }
              return [...acc, val];
            }, [])
            .join(`...`)
        : undefined;

    const hasRtAndFrt = this.currentSubcategoryHasSla();
    const hasFeedback = !!feedbackTime || !!newFeedbackTime;
    const needsFeedback =
      !hasFeedback && !isActive && !!startedBy && responsible !== startedBy && startedBy === loggedUserInfo.user.id;

    const disableCloseButton =
      hasFormInstance &&
      [formInstance[0].startedBy, responsible].indexOf(loggedUserInfo.user.id) < 0 &&
      !loggedUserInfo.user.isAdminOrSuperAdmin;
    const disableDeleteButton = !loggedUserInfo.user.isAdminOrSuperAdmin;
    const disableDescription = loggedUserInfo.user.id !== startedBy;
    const isDisabled = isExcluded || !isActive;

    return (
      <>
        {handlingStatus && (
          <Grid
            container
            alignItems="center"
            alignContent="center"
            justify="center"
            style={{
              backgroundColor: `rgba(0, 0, 0, 0.5)`,
              width: `100%`,
              height: `100%`,
              position: `absolute`,
              top: 0,
              left: 0,
              zIndex: 1100,
            }}
          >
            <Fade in style={{ transitionDelay: `800ms` }} unmountOnExit>
              <CircularProgress />
            </Fade>
          </Grid>
        )}
        <DialogTitle className={classes.dialogTitle} disableTypography>
          {hasFormInstance && !isExcluded && (isActive || (!isActive && !feedbackTime)) && (
            <>
              <Tooltip
                disableFocusListener={!disableCloseButton}
                disableHoverListener={!disableCloseButton}
                disableTouchListener={!disableCloseButton}
                placement="bottom-start"
                title={`Somente o criador da tarefa, responsável ou admins podem fechar/reabrir ${
                  formType === `Chamado` ? `o` : `a`
                } ${formType.toLowerCase()}`}
              >
                <span style={{ margin: `auto auto auto 10px`, height: 32, padding: `0 10px 0 5px` }}>
                  <Button
                    disabled={disableCloseButton}
                    onClick={this.handleSelectorChange(`commentModal`)}
                    variant="outlined"
                  >
                    {isActive ? (
                      <>
                        <Done /> Finalizar
                      </>
                    ) : (
                      <>
                        <Clear /> Reabrir
                      </>
                    )}
                  </Button>
                </span>
              </Tooltip>
              <Dialog
                open={commentModal}
                TransitionComponent={SlideTransition}
                onClose={this.handleSelectorChange(`commentModal`)}
                aria-labelledby="end-open-task-title"
              >
                <DialogTitle id="end-open-task-title">
                  <Typography>Para finalizar/reabrir a tarefa escreva um comentário.</Typography>
                </DialogTitle>
                <DialogContent>
                  <CommentsInput
                    autoFocus
                    avatarStyles={classes.avatar}
                    avatarThumb={loggedUserInfo.user.avatarThumb}
                    inputChangeCallback={this.handleChange(`comment`)}
                    inputError={!comment}
                    inputHelperText="O comentário é obrigatório"
                    placeholder="Digite aqui seu comentário"
                    value={comment}
                  />
                </DialogContent>
                <DialogActions>
                  <Button
                    size="small"
                    onClick={this.handleSelectorChange(`commentModal`)}
                    color="secondary"
                    style={{ marginRight: 5 }}
                  >
                    Cancelar
                  </Button>
                  <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={this.handleStatus(formInstance[0].id, isActive ? `close-task` : `reopen-task`)}
                    disabled={isCommentSaving || !comment}
                  >
                    Salvar {isCommentSaving && <CircularProgress size={20} />}
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          )}
          {isExcluded && (
            <Typography color="error" className={classes.titleText} variant="overline">
              <WarningOutlined style={{ marginRight: 5 }} />
              Atenção: Esta tarefa foi excluída e não pode mais ser editada
            </Typography>
          )}
          {hasFormInstance && !isExcluded && (
            <>
              <input
                disabled={isDisabled}
                id="id_attachment"
                name="attachment"
                type="file"
                style={{ display: `none` }}
                onChange={this.handleFileSubmit}
              />
              <Tooltip title="Anexar">
                <IconButton aria-label="Attach" onClick={() => document.getElementById(`id_attachment`).click()}>
                  <AttachFile />
                </IconButton>
              </Tooltip>
            </>
          )}
          {hasFormInstance && !isExcluded && (
            <Tooltip
              title={
                !disableDeleteButton
                  ? `Excluir`
                  : `Somente admins podem excluir ${formType === `Chamado` ? `o` : `a`} ${formType.toLowerCase()}`
              }
            >
              <span>
                <IconButton
                  aria-label="Delete"
                  disabled={disableDeleteButton}
                  onClick={this.handleStatus(formInstance[0].id, `isExcluded`)}
                >
                  <Delete />
                </IconButton>
              </span>
            </Tooltip>
          )}
          <Tooltip title="Fechar">
            <IconButton aria-label="Close" onClick={handleClose}>
              <Close />
            </IconButton>
          </Tooltip>
          <Menu
            id="markerMenu"
            anchorEl={markerSelector}
            open={Boolean(markerSelector)}
            anchorOrigin={{ vertical: `bottom`, horizontal: `center` }}
            transformOrigin={{ vertical: `top`, horizontal: `right` }}
            getContentAnchorEl={null}
            onClose={this.handleAnchorClose(`markerSelector`)}
          >
            {Markers.data.length &&
              Markers.data.map(val => (
                <MenuItem key={val.id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={marker.some(idx => val.id === idx[0])}
                        onChange={this.handleAutoComplete(`marker`, val.id)}
                        value={val.name}
                      />
                    }
                    label={val.name}
                  />
                </MenuItem>
              ))}
          </Menu>
        </DialogTitle>
        <DialogContent>
          {needsFeedback && (
            <Paper className={classes.card}>
              <form id="task_feedback_form" onSubmit={this.submitFeedback}>
                <Typography variant="h6" display="inline">
                  {hasFeedback ? `Pesquisa de satisfação` : `Como você avalia o atendimento?`}
                </Typography>
                <MoodPicker
                  disabled={isExcluded || hasFeedback}
                  label="Escolha o ícone que melhor representa sua satisfação:"
                  onChange={mood => this.setState({ feedbackMood: mood })}
                  value={feedbackMood}
                />
                <TextField
                  disabled={isExcluded || hasFeedback}
                  fullWidth
                  id="id_feedback_text"
                  InputProps={{
                    inputProps: {
                      style: {
                        minHeight: 70,
                      },
                    },
                  }}
                  margin="normal"
                  multiline
                  name="feedbackText"
                  onChange={event => this.setState({ feedbackText: event.target.value })}
                  placeholder="Se desejar, faça uma crítica, elogio ou sugestão"
                  value={feedbackText}
                  variant="outlined"
                />
                {!hasFeedback && (
                  <Button
                    style={{ minWidth: isSavingFeedback ? 100 : 64 }}
                    size="small"
                    form="task_feedback_form"
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={!feedbackMood || isSavingFeedback}
                  >
                    Enviar feedback {isSavingFeedback && <CircularProgress size={20} />}
                  </Button>
                )}
              </form>
            </Paper>
          )}
          <form
            autoComplete="off"
            id="TaskForm"
            className={classes.container}
            onSubmit={hasFormInstance ? this.handleSubmit(formInstance[0].id) : this.handleSubmit()}
            ref={this.formRef}
          >
            <Grid container spacing={0} style={{ width: `100%` }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} container>
                {formType === `Não conformidade` && (
                  <Grid item xs={12}>
                    <Autocomplete
                      disabled={isDisabled}
                      filterOptions={createFilterOptions({
                        stringify: option => option.name,
                      })}
                      filterSelectedOptions
                      getOptionLabel={option => {
                        const selected = Sectors.find(sec => sec.id === option);
                        if (selected) {
                          return selected.name;
                        }
                        return `Não encontrado`;
                      }}
                      getOptionSelected={(option, selected) => {
                        if (Array.isArray(selected)) {
                          return selected.indexOf(option.id) > -1;
                        }
                        return option.id === selected;
                      }}
                      id="id_sectors"
                      multiple
                      name="sector"
                      noOptionsText="Nenhuma opção de setor"
                      options={Sectors}
                      onChange={this.handleAutocompleteChange(`sector`)}
                      renderInput={({ disabled, id, InputLabelProps, inputProps, InputProps, size, value }) => {
                        const newInputProps = {
                          ...InputProps,
                          inputProps,
                        };

                        return (
                          <TextField
                            disabled={disabled}
                            error={!!formErrors && !!formErrors.sector}
                            FormHelperTextProps={{
                              component: `div`,
                            }}
                            fullWidth
                            helperText={
                              hasFormInstance && !(formErrors && formErrors.sector) ? (
                                <Grid container justify="space-between" style={{ cursor: `pointer` }}>
                                  <Grid item>#{formInstance[0].id}</Grid>
                                  <Grid item>
                                    <SLATooltip tprAndTr={tprAndTr} isForm />
                                  </Grid>
                                </Grid>
                              ) : (
                                formErrors.sector || ``
                              )
                            }
                            id={id}
                            InputLabelProps={InputLabelProps}
                            InputProps={newInputProps}
                            label="Setores"
                            margin="normal"
                            size={size}
                            value={value}
                            variant={isDisabled ? `filled` : `outlined`}
                          />
                        );
                      }}
                      renderOption={state => {
                        const { name } = state;
                        return <ListItemText primary={name} />;
                      }}
                      style={{ width: `98%` }}
                      value={sector}
                    />
                  </Grid>
                )}
                {formType === `Não conformidade` && (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <CategoriesSelector
                      categoriesAndSubCategories={this.getTaskCategories()}
                      disabled={isDisabled}
                      formControlClass={classes.outlinedTextField}
                      id="id_subcategory"
                      isLoading={tasksCategories.loading}
                      label="Categorias de NC"
                      margin={MarginType.normal}
                      name="subcategory"
                      onChange={this.handleChange(`subcategory`)}
                      value={subcategory}
                    />
                  </Grid>
                )}
                {formType === `Ouvidoria` && (
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      className={classes.outlinedTextField}
                      disabled={isDisabled}
                      error={!!formErrors.ombudsman && !!formErrors.ombudsman.source}
                      FormHelperTextProps={{
                        component: `div`,
                      }}
                      helperText={
                        hasFormInstance && !(formErrors.ombudsman && formErrors.ombudsman.source) ? (
                          <Grid container justify="space-between" style={{ cursor: `pointer` }}>
                            <Grid item>#{formInstance[0].id}</Grid>
                            <Grid item>
                              <SLATooltip tprAndTr={tprAndTr} isForm />
                            </Grid>
                          </Grid>
                        ) : (
                          (formErrors.ombudsman && formErrors.ombudsman.source) || ``
                        )
                      }
                      id="id_ombSource"
                      InputProps={{
                        startAdornment: this.getTaskTypeAdornment(formType),
                      }}
                      label="Fonte da ouvidoria"
                      margin="normal"
                      name="ombudsman.source"
                      onChange={this.handleChange(`ombSource`)}
                      required
                      select
                      value={ombSource}
                      variant={isDisabled ? `filled` : `outlined`}
                    >
                      {ombudsmanSources.map(source => (
                        <MenuItem key={source.id} value={source.id}>
                          {source.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                )}
                {(formType === `Ouvidoria` || formType === `Não conformidade`) && (
                  <Grid item xs={12} sm={12} md={6}>
                    <CustomAutocomplete
                      createDialog={
                        <DialogCRMForm
                          addOrUpdateRequest={crmAddOrUpdateRequest}
                          afterSavingCallback={data => {
                            this.handleChange(`crm`)(data.id);
                          }}
                          formErrors={crms.formErrors}
                          formInstance={crms.formInstance}
                          fullscreen={window.innerWidth < 960}
                          handleClick={closeCRMRequestDialog}
                          isSaving={crms.isSaving}
                          open={crms.dialogOpen}
                        />
                      }
                      customAddOption={{
                        id: -2,
                        fullName: `customAddOption`,
                      }}
                      defaultOptions={[
                        {
                          fullName: `Anônimo`,
                          id: -1,
                          type: `INDIVIDUAL`,
                        },
                      ]}
                      getOptionValue={selectedData => {
                        return `${selectedData.id}`;
                      }}
                      inputValue={selectedData => {
                        if (selectedData && !Array.isArray(selectedData)) {
                          return selectedData.fullName;
                        }
                        return ``;
                      }}
                      label="Cliente"
                      mountTooltipTitle={selectedData => {
                        const optionIconAndText = this.getTextAndIcon(
                          selectedData,
                          classes.selectorIcon,
                          `small`,
                          true,
                        );
                        if (!Array.isArray(selectedData)) {
                          return (
                            <Grid container direction="row" wrap="nowrap">
                              <Grid container direction="column" style={{ marginRight: 6, width: `fit-content` }}>
                                {optionIconAndText.icon}
                              </Grid>
                              <Grid container direction="column">
                                {optionIconAndText.text}
                              </Grid>
                            </Grid>
                          );
                        }
                      }}
                      multiple={false}
                      onChange={this.handleChange(`crm`)}
                      openUp={false}
                      Option={props => {
                        const {
                          data: optionData,
                          innerProps,
                          innerRef,
                          isDisabled: autoCompleteDisabled,
                          isFocused,
                          isMulti,
                          isSelected,
                        } = props;
                        const optionIconAndText = this.getTextAndIcon(
                          optionData,
                          `${classes.selectorIcon} ${classes.selectorIconColorDark}`,
                        );
                        return optionData.fullName !== `customAddOption` ? (
                          <MenuItem
                            buttonRef={innerRef}
                            selected={isFocused}
                            component="div"
                            style={{
                              alignItems: `flex-start`,
                              backgroundColor: isMulti && isSelected ? `#e6f7ff` : undefined,
                              color: autoCompleteDisabled ? `#d3d3d3` : ``,
                              minHeight: 46,
                            }}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...innerProps}
                          >
                            <ListItemIcon classes={{ root: optionIconAndText.className }}>
                              {optionIconAndText.icon}
                            </ListItemIcon>
                            <ListItemText
                              primary={optionData.fullName}
                              secondary={optionIconAndText.text ? optionIconAndText.text : undefined}
                              secondaryTypographyProps={{
                                component: `div`,
                              }}
                            />
                          </MenuItem>
                        ) : (
                          <MenuItem
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...innerProps}
                            buttonRef={innerRef}
                            component="div"
                            onClick={event => {
                              event.stopPropagation();
                              openCRMRequestDialog({ dialog });
                            }}
                            selected={isFocused}
                            style={{
                              alignItems: `flex-start`,
                              backgroundColor: isMulti && isSelected ? `#e6f7ff` : undefined,
                              color: autoCompleteDisabled ? `#d3d3d3` : ``,
                              minHeight: 46,
                            }}
                          >
                            <ListItemIcon classes={{ root: classes.listItemRootAdd }}>
                              <AddIcon className={classes.selectorIconColorDark} />
                            </ListItemIcon>
                            <ListItemText className={classes.selectorIconColorDark} primary="Cadastrar novo cliente" />
                          </MenuItem>
                        );
                      }}
                      placeholder="Anônimo"
                      required={false}
                      startAdornment={selectedData => {
                        const optionTextAndIcon = this.getTextAndIcon(
                          selectedData,
                          classes.startAdornmentIcon,
                          `small`,
                        );
                        return (
                          <InputAdornment classes={{ root: classes.startAdornment }} position="start">
                            {optionTextAndIcon.icon}
                          </InputAdornment>
                        );
                      }}
                      searchUrl="crm/person/select"
                      value={crm}
                    />
                  </Grid>
                )}
                {!(formType === `Não conformidade` || formType === `Ouvidoria`) && (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                      disabled={isDisabled}
                      FormHelperTextProps={{
                        component: `div`,
                      }}
                      helperText={
                        hasFormInstance ? (
                          <Grid container justify="space-between" style={{ cursor: `pointer` }}>
                            <Grid item>#{formInstance[0].id}</Grid>
                            <Grid item>
                              <SLATooltip tprAndTr={tprAndTr} isForm />
                            </Grid>
                          </Grid>
                        ) : null
                      }
                      id="id_title"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        style: {
                          fontSize: 26,
                          fontWeight: `bold`,
                        },
                        startAdornment: this.getTaskTypeAdornment(formType),
                      }}
                      margin="normal"
                      name="title"
                      onChange={this.handleChange(`title`)}
                      placeholder="Digite aqui o título ..."
                      required
                      style={{ width: `100%` }}
                      value={title}
                      variant={isDisabled ? `filled` : `outlined`}
                    />
                  </Grid>
                )}
                {isConvertingTaskToCall && (
                  <HelperBox
                    centerContent
                    icon={<BuildIcon />}
                    text="Um ativo foi selecionado, a tarefa será automaticamente convertida em um Chamado."
                    variant={HelperBoxVariantTypes.warning}
                    withAvatarWrapper
                    withBorder
                  />
                )}
                <Grid container justify="space-between">
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={formType === `Não conformidade` ? 6 : 3}
                    lg={formType === `Não conformidade` ? 6 : 3}
                    xl={formType === `Não conformidade` ? 6 : 3}
                  >
                    <UserSelector
                      disabled={isDisabled}
                      label="Responsável"
                      loggedUserInfo={loggedUserInfo.user}
                      name="responsible"
                      onChange={this.handleChange(`responsible`)}
                      style={{ margin: `16px 0 8px 0` }}
                      value={responsible}
                    />
                  </Grid>
                  {formType === `Não conformidade` && (
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <MyDateTimePickerStyles
                        autoOk
                        customClasses={{ textField: classes.datePicker }}
                        disabled={isDisabled || hasRtAndFrt}
                        handleDateChange={this.handleDateChange(`startDate`)}
                        keyboard={false}
                        label="Data da NC"
                        name="startDate"
                        required
                        value={!hasFormInstance && hasRtAndFrt ? null : startDate}
                        variant={isDisabled || hasRtAndFrt ? `filled` : `outlined`}
                      />
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={formType === `Não conformidade` ? 12 : 3}
                    lg={formType === `Não conformidade` ? 12 : 3}
                    xl={formType === `Não conformidade` ? 12 : 3}
                  >
                    <UserSelector
                      disabled={isDisabled}
                      label="Seguidores"
                      loggedUserInfo={loggedUserInfo.user}
                      multiple
                      name="users"
                      onChange={this.handleChange(`users`)}
                      value={users}
                    />
                  </Grid>
                  {!(formType === `Não conformidade`) && (
                    <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                      <MyDateTimePickerStyles
                        autoOk
                        customClasses={{ textField: classes.datePicker }}
                        disabled={isDisabled || hasRtAndFrt}
                        handleDateChange={this.handleDateChange(`startDate`)}
                        keyboard={false}
                        label="Data inicial"
                        name="startDate"
                        value={!hasFormInstance && hasRtAndFrt ? null : startDate}
                        variant={isDisabled || hasRtAndFrt ? `filled` : `outlined`}
                        width="98%"
                      />
                    </Grid>
                  )}
                  {!(formType === `Não conformidade`) && (
                    <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                      <MyDateTimePickerStyles
                        autoOk
                        customClasses={{ textField: classes.datePicker }}
                        disabled={isDisabled || hasRtAndFrt}
                        handleDateChange={this.handleDateChange(`estimatedEndDate`)}
                        keyboard={false}
                        label="Previsão"
                        minDate={new Date(startDate)}
                        minDateMessage="Escolha uma data maior que a inicial"
                        name="estimatedEndDate"
                        value={!hasFormInstance && hasRtAndFrt ? null : estimatedEndDate}
                        variant={isDisabled || hasRtAndFrt ? `filled` : `outlined`}
                        width="98%"
                      />
                    </Grid>
                  )}
                </Grid>
                {formType === `Não conformidade` && (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container>
                      {[
                        { id: 0, name: `Descrição da NC`, state: `ncDescription` },
                        { id: 1, name: `Correção (Ação imediata)`, state: `ncAction` },
                        { id: 2, name: `Análise de causa`, state: `ncCause` },
                        { id: 3, name: `Ação corretiva`, state: `ncCorrectiveAction` },
                        {
                          id: 4,
                          name: `Verificação da eficácia da ação corretiva`,
                          state: `ncEfficiency`,
                        },
                        { id: 5, name: `Observações gerais`, state: `ncObservations` },
                      ].map(val => (
                        <Grid key={val.id} item xs={12} sm={6} md={6} lg={6} xl={6}>
                          <TextField
                            disabled={isDisabled}
                            error={this.checkNcError(val.state)}
                            id={`id_${val.state}`}
                            label={val.name}
                            margin="normal"
                            multiline
                            name={`nc.${val.state}`}
                            onChange={event => this.handleChange(val.state)(event)}
                            required={val.state === `ncAction` || val.state === `ncDescription`}
                            style={{ width: `98%` }}
                            value={
                              this.state[val.state]//eslint-disable-line
                            }
                            variant={isDisabled ? `filled` : `outlined`}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                )}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  {!(formType === `Não conformidade`) && (
                    <TextField
                      className={classes.descriptionTextField}
                      disabled={isDisabled || disableDescription}
                      id="id_description"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <FormatAlignLeft style={{ position: `relative`, bottom: 25, left: 0 }} />
                          </InputAdornment>
                        ),
                        inputProps: {
                          style: {
                            minHeight: 70,
                          },
                        },
                      }}
                      margin="normal"
                      multiline
                      name="description"
                      onChange={this.handleChange(`description`)}
                      placeholder="Digite aqui a descrição da tarefa ..."
                      value={description}
                      variant={isDisabled || disableDescription ? `filled` : `outlined`}
                    />
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={formType === `Não conformidade` ? 6 : 4}
                  lg={formType === `Não conformidade` ? 6 : 4}
                  xl={formType === `Não conformidade` ? 6 : 4}
                >
                  <InstitutionsSelector
                    disabled={isDisabled}
                    error={!!formErrors.institution}
                    helperText={formErrors.institution}
                    id="id_institution"
                    institutions={Institutions}
                    margin={MarginType.normal}
                    multiple
                    name="institution"
                    onChange={this.handleChange(`institution`)}
                    required
                    selectClass={classes.outlinedTextField}
                    value={institution}
                    variant={isDisabled ? `filled` : `outlined`}
                  />
                </Grid>
                {[`Tarefa`, `Chamado`, `Preventiva`].indexOf(formType) > -1 && (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={4}
                    xl={4}
                    style={{
                      display: `flex`,
                      flexFlow: `row wrap`,
                      alignItems: `center`,
                      alignContent: `center`,
                    }}
                  >
                    {inventoryInfo && (
                      <InventoryInfoDialog
                        classes={classes}
                        inventory={inventory}
                        inventoryInfo={inventoryInfo}
                        onClose={this.handleSelectorChange(`inventoryInfo`)}
                        slideTransition={SlideTransition}
                      />
                    )}
                    <AutocompleteOnDemand
                      adittionalParams={{
                        institutionIds,
                      }}
                      disabled={isDisabled}
                      fetchUrl="/inventory/inventory/select/"
                      filterOptions={createFilterOptions({
                        stringify: option => JSON.stringify(option),
                      })}
                      getOptionLabel={(option, options) => {
                        const selected = options.find(inv => inv.id === option) || {};
                        return selected.name || `Nenhuma opção encontrada.`;
                      }}
                      getOptionSelected={(option, value) => option.id === value}
                      id={inventoryInput.id}
                      loadingText="Buscando ativos ..."
                      noOptionsText={!institution.length ? `Selecione uma instituição` : undefined}
                      onChange={this.handleAutocompleteChange(`inventory`)}
                      renderOption={state => {
                        const { institution: institutionId, location, name, patrimony } = state;
                        const inst = Institutions.find(insideInst => insideInst.id === institutionId);
                        const { institutionAvatar, institutionName } = inst;
                        return (
                          <ListItemText
                            disableTypography
                            primary={
                              <Box display="flex" justifyContent="space-between">
                                <Typography
                                  className={classes.autocompleteTextEllipsis}
                                  display="inline"
                                  variant="body1"
                                >
                                  {name}
                                </Typography>
                                <Typography
                                  className={classes.autocompleteText}
                                  color="textSecondary"
                                  display="inline"
                                  variant="body1"
                                >
                                  {patrimony}
                                </Typography>
                              </Box>
                            }
                            secondary={
                              <Box
                                alignContent="center"
                                alignItems="center"
                                display="flex"
                                justifyContent="space-between"
                              >
                                <Box
                                  alignContent="center"
                                  alignItems="center"
                                  display="flex"
                                  justifyContent="flex-start"
                                >
                                  <Avatar className={classes.autocompleteAvatar} src={institutionAvatar} />
                                  <Typography
                                    className={classes.autocompleteTextEllipsis}
                                    color="textSecondary"
                                    display="inline"
                                    variant="body1"
                                  >
                                    {`${institutionName}${location ? ` - ${location}` : ``}`}
                                  </Typography>
                                </Box>
                              </Box>
                            }
                          />
                        );
                      }}
                      selectedOnFormInstance={inventorySelect}
                      startSearchText="Digite para buscar um ativo."
                      style={{ width: `98%` }}
                      textFieldProps={{
                        error: inventoryInput.error,
                        helperText: inventoryInput.helperText,
                        InputProps: {
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton disabled={!inventory} onClick={this.handleSelectorChange(`inventoryInfo`)}>
                                <InfoIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        },
                        label: `Ativo`,
                        margin: `normal`,
                        name: inventoryInput.name,
                        variant: isDisabled ? `filled` : `outlined`,
                      }}
                      value={inventory}
                    />
                  </Grid>
                )}
                {formType !== `Não conformidade` && (
                  <>
                    {formType === `Chamado` && (
                      <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                        <TextField
                          className={classes.customSelectClassWithTextField}
                          disabled={isDisabled}
                          error={!!formErrors.call && !!formErrors.call.situation}
                          helperText={
                            !!formErrors.call && formErrors.call.situation && !inventory && `Esse campo é obrigatório`
                          }
                          id="id_situation"
                          label="Situação"
                          margin="normal"
                          name="call.situation"
                          onChange={this.handleChange(`situation`)}
                          required
                          select
                          value={situation}
                          variant={isDisabled ? `filled` : `outlined`}
                        >
                          {[
                            { name: `Funcionando`, id: `WK` },
                            { name: `Parcial`, id: `PC` },
                            { name: `Parado`, id: `SP` },
                          ].map(val => (
                            <MenuItem key={val.id} value={val.id}>
                              {val.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    )}
                    <Grid
                      item
                      xs={12}
                      sm={formType !== `Chamado` ? 12 : 6}
                      md={formType !== `Chamado` ? 4 : 2}
                      lg={formType !== `Chamado` ? 4 : 2}
                      xl={formType !== `Chamado` ? 4 : 2}
                    >
                      <TextField
                        className={classes.customSelectClassWithTextField}
                        disabled={isDisabled}
                        id="id_priority"
                        label="Prioridade"
                        margin="normal"
                        name="priority"
                        onChange={this.handleChange(`priority`)}
                        required
                        select
                        value={priority}
                        variant={isDisabled ? `filled` : `outlined`}
                      >
                        {[
                          { name: `Baixa`, id: `LW` },
                          { name: `Normal`, id: `NM` },
                          { name: `Alta`, id: `HG` },
                        ].map(val => (
                          <MenuItem key={val.id} value={val.id}>
                            {val.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </>
                )}
                {formType !== `Não conformidade` && (
                  <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                    <Autocomplete
                      disabled={isDisabled}
                      filterOptions={createFilterOptions({
                        stringify: option => option.name,
                      })}
                      filterSelectedOptions
                      getOptionLabel={option => {
                        const selected = Sectors.find(sec => sec.id === option);
                        if (selected) {
                          return selected.name;
                        }
                        return `Não encontrado`;
                      }}
                      getOptionSelected={(option, selected) => {
                        if (Array.isArray(selected)) {
                          return selected.indexOf(option.id) > -1;
                        }
                        return option.id === selected;
                      }}
                      id="id_sectors"
                      multiple
                      name="sector"
                      noOptionsText="Nenhuma opção de setor"
                      options={Sectors}
                      onChange={this.handleAutocompleteChange(`sector`)}
                      renderInput={({ disabled, id, InputLabelProps, inputProps, InputProps, size, value }) => {
                        const newInputProps = {
                          ...InputProps,
                          inputProps,
                        };

                        return (
                          <TextField
                            disabled={disabled}
                            error={!!formErrors && !!formErrors.sector}
                            fullWidth
                            helperText={formErrors && formErrors.sector ? formErrors.sector : ``}
                            id={id}
                            InputLabelProps={InputLabelProps}
                            InputProps={newInputProps}
                            label="Setores"
                            margin="normal"
                            size={size}
                            value={value}
                            variant={disabled ? `filled` : `outlined`}
                          />
                        );
                      }}
                      renderOption={state => {
                        const { name } = state;
                        return <ListItemText primary={name} />;
                      }}
                      style={{ width: `98%` }}
                      value={sector}
                    />
                  </Grid>
                )}
                {formType === `Chamado` && (
                  <Grid item xs={12} sm={3} md={4} lg={4} xl={4}>
                    <TextField
                      className={classes.outlinedTextField}
                      disabled={isDisabled}
                      error={`externalCallId` in formErrors}
                      helperText={
                        (`externalCallId` in formErrors && `Esse campo é obrigatório!`) ||
                        `Protocolo do chamado no fornecedor responsável`
                      }
                      id="id_external_call"
                      label="Chamado externo"
                      margin="normal"
                      name="call.externalCallId"
                      onChange={this.handleChange(`externalCallId`)}
                      value={externalCallId}
                      variant={isDisabled ? `filled` : `outlined`}
                    />
                  </Grid>
                )}
                {formType === `Preventiva` && (
                  <>
                    <Grid item xs={12} sm={3} md={4} lg={4} xl={4}>
                      <TextField
                        className={classes.outlinedTextField}
                        disabled={isDisabled}
                        error={`externalCallId` in formErrors}
                        helperText={
                          (formErrors.externalCallId && `Esse campo é obrigatório!`) ||
                          `Protocolo do chamado no fornecedor responsável`
                        }
                        id="id_external_call_preventive"
                        label="Ordem de serviço"
                        margin="normal"
                        name="preventive.externalCallId"
                        onChange={this.handleChange(`externalCallId`)}
                        value={externalCallId}
                        variant={isDisabled ? `filled` : `outlined`}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3} md={4} lg={4} xl={4}>
                      <TextField
                        className={classes.outlinedTextField}
                        disabled={isDisabled}
                        error={formErrors.preventive && formErrors.preventive.location}
                        helperText={(formErrors.execLocation && `Esse campo é obrigatório!`) || `Local de execução`}
                        id="execLocation"
                        label="Local"
                        margin="normal"
                        name="preventive.location"
                        onChange={this.handleChange(`execLocation`)}
                        value={execLocation}
                        variant={isDisabled ? `filled` : `outlined`}
                      />
                    </Grid>
                  </>
                )}
                {formType !== `Não conformidade` && (
                  <Grid item xs={12} sm={3} md={4} lg={4} xl={4}>
                    <CategoriesSelector
                      categoriesAndSubCategories={this.getTaskCategories()}
                      disabled={isDisabled}
                      formControlClass={classes.outlinedTextField}
                      id="id_subcategory"
                      isLoading={tasksCategories.loading}
                      label="Categorias"
                      margin={MarginType.normal}
                      name="subcategory"
                      onChange={this.handleChange(`subcategory`)}
                      value={subcategory}
                    />
                  </Grid>
                )}
                {[`Chamado`, `Preventiva`].indexOf(formType) > -1 && (
                  <Grid item xs={12} sm={3} md={4} lg={4} xl={4}>
                    <TextField
                      className={classes.outlinedTextField}
                      disabled={isDisabled}
                      id="id_cost"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                      }}
                      label="Custo"
                      margin={MarginType.normal}
                      name="cost"
                      onChange={this.handleChange(`cost`)}
                      type="number"
                      value={cost}
                      variant="outlined"
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </form>
          {hasFormInstance && !!attachs.length && (
            <TaskAttachsContainer
              attachs={attachs}
              deleteCallback={!isDisabled ? this.handleFileDelete : undefined}
              loggedUserId={loggedUserInfo.user.id}
            />
          )}
          {hasFormInstance && (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 15, textAlign: `center` }}>
              <TaskCommentsContainer
                comments={comments}
                commentsControllCallback={this.commentsControll}
                commentsRange={commentsRange}
                deleteAttachCallback={!isDisabled ? this.handleFileDelete : undefined}
                deleteCallback={this.handleCommentDelete}
                disableHoverListener={isDisabled}
                disableTouchListener={isDisabled}
                editCallback={this.handleCommentSubmit}
                genericAvatar={genericAvatar}
                isLoading={isCommentsFetching}
                loggedUserId={loggedUserInfo.user.id}
                parentType={formType.toLowerCase()}
              />
            </Grid>
          )}
        </DialogContent>
        <DialogActions style={{ alignItems: `baseline`, flexWrap: `wrap` }}>
          <Grid
            alignContent="flex-start"
            alignItems="flex-start"
            container
            justify="flex-end"
            spacing={0}
            style={{ paddingLeft: 18, paddingRight: 18 }}
          >
            {hasFormInstance && !isExcluded && (
              <>
                <CommentsInput
                  avatarStyles={classes.avatar}
                  avatarThumb={loggedUserInfo.user.avatarThumb}
                  disabled={isDisabled}
                  endAdornment={
                    <InputAdornment position="end">
                      <Button
                        color="primary"
                        disabled={isCommentSaving || isDisabled}
                        id="commentButton"
                        onClick={this.handleCommentSubmit()}
                      >
                        Comentar {isCommentSaving && <CircularProgress size={30} />}
                      </Button>
                    </InputAdornment>
                  }
                  id="id_comment"
                  inputChangeCallback={this.handleChange(`comment`)}
                  name="comment"
                  placeholder="Digite aqui seu comentário"
                  value={comment}
                />
              </>
            )}
            {hasFormInstance && isSaving && (
              <Hidden only={[`xs`, `sm`]}>
                <Fade in style={{ transitionDelay: `800ms` }} unmountOnExit>
                  <CircularProgress size={24} />
                </Fade>
              </Hidden>
            )}
            {hasFormInstance && !isSaving && saves && (
              <Hidden only={[`xs`, `sm`]}>
                <span>Salvo!</span>
              </Hidden>
            )}
            {hasFormInstance && isSaving && (
              <Hidden only={[`md`, `lg`, `xl`]}>
                <Fade in style={{ transitionDelay: `800ms` }} unmountOnExit>
                  <CircularProgress size={24} />
                </Fade>
              </Hidden>
            )}
            {hasFormInstance && !isSaving && saves && (
              <Hidden only={[`md`, `lg`, `xl`]}>
                <span>Salvo!</span>
              </Hidden>
            )}
          </Grid>
          {!hasFormInstance && (
            <>
              <Button size="small" onClick={handleClose} color="secondary" style={{ marginRight: 10 }}>
                Cancelar
              </Button>
              <Button
                style={{ minWidth: isSaving ? 100 : 64 }}
                size="small"
                form="TaskForm"
                type="submit"
                color="primary"
                variant="contained"
                disabled={isSaving}
              >
                Salvar {isSaving && <CircularProgress size={20} />}
              </Button>
            </>
          )}
        </DialogActions>
      </>
    );
  }
}

const mapStateToProps = state => ({
  crms: state.CRMs,
  Institutions: state.Institutions.data,
  loggedUserInfo: state.Auth.data,
  Markers: state.TaskMarkers,
  Sectors: state.Sectors.data,
  tasksCategories: state.TasksCategories,
  OmbudsmanSources: state.OmbudsmanSources.data,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...tasksActions,
      closeCRMRequestDialog: closeCRMRequest,
      crmAddOrUpdateRequest: addOrUpdateRequest,
      loadCategoriesRequest: loadCategoriesRequestAction,
      loadCategoriesTypesRequest: loadCategoriesTypesRequestAction,
      loadOmbudsmanSourcesRequest: loadOmbudsmanSourcesRequestAction,
      openCRMRequestDialog: openCRMRequest,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withDialog()(TaskForm)));
