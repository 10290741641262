import { CompoundCategory, CompoundCategoryError, CompoundCategoryState, SimpleCategory } from '../types';

export enum InventoryCategoryActionTypes {
  LOAD_REQUEST = '@InventoryCategories/LOAD_REQUEST', // eslint-disable-line
  LOAD_SUCCESS = '@InventoryCategories/LOAD_SUCCESS', // eslint-disable-line
  LOAD_FAILURE = '@InventoryCategories/LOAD_FAILURE', // eslint-disable-line
  OPEN_REQUEST = '@InventoryCategories/OPEN_REQUEST',   // eslint-disable-line
  OPEN_SUCCESS = '@InventoryCategories/OPEN_SUCCESS',   // eslint-disable-line
  CLOSE_REQUEST = '@InventoryCategories/CLOSE_REQUEST',   // eslint-disable-line
  CLOSE_SUCCESS = '@InventoryCategories/CLOSE_SUCCESS',   // eslint-disable-line
  LOAD_ERRORS = '@InventoryCategories/LOAD_ERRORS',   // eslint-disable-line
  ADD_OR_UPDATE_REQUEST = '@InventoryCategories/ADD_OR_UPDATE_REQUEST', // eslint-disable-line
  ADD_OR_UPDATE_FAILURE = '@InventoryCategories/ADD_OR_UPDATE_FAILURE', // eslint-disable-line
  ADD_SUCCESS = '@InventoryCategories/ADD_SUCCESS', // eslint-disable-line
  UPDATE_SUCCESS = '@InventoryCategories/UPDATE_SUCCESS', // eslint-disable-line

  ADD_OR_UPDATE_SUBCATEGORY_REQUEST = '@InventoryCategories/ADD_OR_UPDATE_SUBCATEGORY_REQUEST', // eslint-disable-line
  ADD_SUBCATEGORY_SUCCESS = '@InventoryCategories/ADD_SUBCATEGORY_SUCCESS', // eslint-disable-line
  UPDATE_SUBCATEGORY_SUCCESS = '@InventoryCategories/UPDATE_SUBCATEGORY_SUCCESS', // eslint-disable-line
  OPEN_SUBCATEGORY_SUCCESS = '@InventoryCategories/OPEN_SUBCATEGORY_SUCCESS',   // eslint-disable-line
  CLOSE_SUBCATEGORY_REQUEST = '@InventoryCategories/CLOSE_SUBCATEGORY_REQUEST',   // eslint-disable-line
}

export interface InventorySubcategory extends SimpleCategory {
  category?: number;
  dicomRequired: boolean;
}

export type InventorySubcategoryDialog = Omit<InventorySubcategory, 'id'> & { id?: number } // eslint-disable-line

export interface InventoryCategoryError extends CompoundCategoryError {
  dicomRequired?: string;
}

export interface InventoryCategory extends CompoundCategory {
  readonly subCategories?: InventorySubcategory[];
}

export type InventoryCategoryDialog = Omit<InventoryCategory, 'id'> & { id?: number } // eslint-disable-line

/**
 * State types
 */
export interface InventoryCategoryState extends CompoundCategoryState {
  readonly data: InventoryCategory[];
  readonly formInstance?: InventoryCategory;
  readonly selectedCategory?: InventoryCategory;
  readonly subCategoryFormInstance?: InventorySubcategory;
}
