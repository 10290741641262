import { call, put } from 'redux-saga/effects';
import { Action } from 'redux';
import api from '../../../Services/api';

import { loadSuccess, loadFailure } from './Actions';

interface AppAction extends Action {
  payload?: any;
}

export function* loadGroups(action: AppAction) {
  try {
    const response = yield call(api.get, `/account/groups/`);
    yield put(loadSuccess(response.data));
  } catch (err) {
    yield put(loadFailure());
  }
}
