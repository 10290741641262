import React from 'react';

// Material UI
import { Dialog } from '@material-ui/core';

// My Components
import ExtDocsForm from './ExtDocsForm';

const DialogExtDocsForm = ({
  open,
  formInstance,
  resetFormInstance,
  getData,
  handleClose,
  fullscreen,
  avatar,
  categories,
}) => (
  <Dialog
    fullScreen={fullscreen}
    open={open}
    onClose={handleClose}
    aria-labelledby="addTaskForm"
    fullWidth
  >
    <ExtDocsForm
      avatar={avatar}
      handleClick={handleClose}
      formInstance={formInstance}
      getData={getData}
      categories={categories}
      resetFormInstance={resetFormInstance}
    />
  </Dialog>
);

export default DialogExtDocsForm;
