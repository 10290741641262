import React, { useEffect, useRef } from 'react';
/**
 * Redux dependencies
 */
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
/**
 * Router dom dependency
 */
import { NavLink } from 'react-router-dom';
/**
 * Activities actions
 */
/**
 * Moment JS
 */
import Moment from 'moment';
import 'moment/locale/pt-br';
/**
 * Material UI - Core
 */
import { Avatar, CircularProgress, Fade, Grid, Paper, Slide, Tooltip, Typography, withStyles } from '@material-ui/core';
/**
 * Material UI - Icons
 */
import {
  AddShoppingCart,
  AssignmentTurnedIn,
  AttachFile,
  Build,
  CommentOutlined,
  DescriptionOutlined,
  DevicesOther,
  FolderShared,
  LocationCity,
  Notes,
  PictureAsPdfOutlined,
  RecordVoiceOverOutlined,
  ReportProblem,
  SupervisorAccount,
} from '@material-ui/icons';
import removeMd from 'remove-markdown';
import * as ActivitiesActions from '../../ReduxFlow/Reducers/Activities/Actions';

import activitiesUtil from '../../Utils/activities';

/**
 * Defining Moment locale
 */
Moment.locale(`pt-BR`);

const styles = theme => ({
  tableHover: {
    '& :hover': {
      background: `#ddd`,
    },
  },
  navlink: {
    textDecoration: `none`,
    color: `unset`,
  },
  smRecipient: {
    [theme.breakpoints.up(`md`)]: {
      height: `calc(100vh - 184px)`,
    },
    [theme.breakpoints.down(`sm`)]: {
      height: `calc(100vh - 112px)`,
    },
    textDecoration: `none`,
    overflowY: `auto`,
  },
  loaderWrapper: {
    position: `absolute`,
    bottom: 30,
    width: `98%`,
    textAlign: `center`,
  },
  hidden: {
    display: `none`,
  },
  hoverable: {
    '& span': {
      ':nth-child(1)': {
        display: `inline`,
        visibility: `visible`,
      },
      ':nth-child(2)': {
        display: `inline`,
        visibility: `visible`,
      },
      '&:nth-child(3)': {
        display: `none`,
        visibility: `hidden`,
      },
    },
    '&:hover': {
      '& span': {
        '&:nth-child(2)': {
          display: `none`,
          visibility: `hidden`,
        },
        '&:nth-child(3)': {
          display: `inline`,
          visibility: `visible`,
        },
      },
    },
  },
  typo: {
    color: `#252525`,
  },
});

const getCards = (avatar, classes, handleMenuActive, data) =>
  data
    .map(val => {
      const { action, appLabel, contentType, id, instance, objectId, timestamp, user } = val;
      let link = ``;
      let navReplace = ``;
      let menuNumber = 0;
      let title = `undefined`;
      let icon = null;
      if (instance) {
        switch (contentType) {
          case `tasks`:
            link = `/tasks/?id=${objectId}&type=${instance.type}`;
            navReplace = `/tasks`;
            menuNumber = 2;
            title = !instance.isExcluded ? instance.title : `Tarefa deletada`;
            icon =
            instance.type && instance.type === `Chamado` ? ( // eslint-disable-line
                <Tooltip title="Chamado">
                  <Build />
                </Tooltip>
            ) : instance.type === `Não conformidade` ? ( // eslint-disable-line
                <Tooltip title="Não conformidade">
                  <ReportProblem />
                </Tooltip>
            ) : instance.type === `Ouvidoria` ? ( // eslint-disable-line
                <Tooltip title="Ouvidoria">
                  <RecordVoiceOverOutlined />
                </Tooltip>
              ) : (
                <Tooltip title="Tarefa">
                  <Notes />
                </Tooltip>
              );
            break;
          case `comments`:
            if (appLabel === `tasks`) {
              link = `/tasks/?id=${instance.taskId}&type=${instance.type}&comment=${objectId}`;
              navReplace = `/tasks`;
              menuNumber = 2;
              title = !instance.isExcluded ? instance.comment : `Tarefa deletada`;
              icon = (
                <Tooltip title="Comentário na tarefa">
                  <CommentOutlined />
                </Tooltip>
              );
            } else if (appLabel === `internaldocs`) {
              link = `/internaldocs/?id=${instance.documentId}&comment=${objectId}`;
              navReplace = `/internaldocs`;
              menuNumber = 4;
              title = !instance.isExcluded ? instance.comment : `Documento interno deletado`;
              icon = (
                <Tooltip title="Comentário no documento interno">
                  <CommentOutlined />
                </Tooltip>
              );
            }
            break;
          case `attach`:
            link = `/tasks/?id=${instance.taskId}&type=${instance.type}&attach=${objectId}`;
            navReplace = `/tasks`;
            menuNumber = 2;
            title = !instance.isExcluded ? instance.taskTitle : `Tarefa deletada`;
            icon = (
              <Tooltip title="Anexo">
                <AttachFile />
              </Tooltip>
            );
            break;
          case `quality control`:
            link = `/qualitycontrol/?id=${objectId}&dbname=${instance.dbName}`;
            navReplace = `/qualitycontrol`;
            menuNumber = 3;
            title = instance.name ? instance.name : ``;
            icon = (
              <Tooltip title="Controle de qualidade">
                <AssignmentTurnedIn />
              </Tooltip>
            );
            break;
          case `internal doc`:
            link = `/internaldocs/?id=${instance.id}`;
            navReplace = `/internaldocs`;
            menuNumber = 4;
            title = instance.name ? instance.name : ``;
            icon = (
              <Tooltip title="Documento interno">
                <DescriptionOutlined />
              </Tooltip>
            );
            break;
          case `document info`:
            link = `/internaldocs/?id=${objectId}`;
            navReplace = `/internaldocs`;
            menuNumber = 4;
            title = instance.name ? instance.name : ``;
            icon = (
              <Tooltip title="Documento interno">
                <DescriptionOutlined />
              </Tooltip>
            );
            break;
          case `external docs`:
          case `external doc attach`:
            link = `/externaldocs/?id=${objectId}`;
            navReplace = `/externaldocs`;
            menuNumber = 5;
            title = instance.name ? instance.name : ``;
            icon = (
              <Tooltip title="Documento externo">
                <PictureAsPdfOutlined />
              </Tooltip>
            );
            break;
          case `inventory`:
            link = `/inventory/?id=${objectId}`;
            navReplace = `/inventory`;
            menuNumber = 6;
            title = instance.name ? instance.name : ``;
            icon = (
              <Tooltip title="Ativos">
                <DevicesOther />
              </Tooltip>
            );
            break;
          case `inventory attach`:
            link = `/inventory/?id=${instance.inventoryPk}`;
            navReplace = `/inventory`;
            menuNumber = 6;
            title = instance.inventoryName ? instance.inventoryName : ``;
            icon = (
              <Tooltip title="Anexo de ativos">
                <AttachFile />
              </Tooltip>
            );
            break;
          case `usuário`:
            link = `/users/?id=${objectId}`;
            navReplace = `/users`;
            menuNumber = 7;
            title = instance.name ? instance.name : ``;
            icon = (
              <Tooltip title="Usuários">
                <SupervisorAccount />
              </Tooltip>
            );
            break;
          case `institution`:
            link = `/institutions/?id=${objectId}`;
            navReplace = `/institutions`;
            menuNumber = 8;
            title = instance.institutionName ? instance.institutionName : ``;
            icon = (
              <Tooltip title="Instituições">
                <LocationCity />
              </Tooltip>
            );
            break;
          case `sector`:
            link = `/sectors/?id=${objectId}`;
            navReplace = `/sectors`;
            menuNumber = 9;
            title = instance.name ? instance.name : ``;
            icon = (
              <Tooltip title="Setores">
                <FolderShared />
              </Tooltip>
            );
            break;
          case `supplier`:
            link = `/suppliers/?id=${objectId}`;
            navReplace = `/suppliers`;
            menuNumber = 10;
            title = instance.name ? instance.name : ``;
            icon = (
              <Tooltip title="Fornecedores">
                <AddShoppingCart />
              </Tooltip>
            );
            break;
          default:
            console.error(`unexpected Acitivity contentType: ${contentType}`, val);
            link = ``;
            navReplace = ``;
            menuNumber = 0;
            title = `undefined`;
            icon = null;
        }
        title = removeMd(title);
      }
      return { action, icon, id, link, menuNumber, navReplace, timestamp, title, user };
    })
    .filter(v => !!v.icon)
    .map(({ action, icon, id, link, menuNumber, navReplace, timestamp, title, user }) => (
      <Slide key={id} in direction="up" mountOnEnter unmountOnExit className={classes.tableHover}>
        <NavLink
          to={link}
          replace={window.location.pathname === navReplace}
          className={classes.navlink}
          onClick={event => handleMenuActive(event, menuNumber)}
          style={{ textDecoration: `none` }}
        >
          <Paper style={{ margin: `5px auto 5px auto`, padding: 10 }}>
            <Grid container>
              <Grid container item xs={2} sm={1} alignItems="center" alignContent="center" justify="center">
                <Avatar src={user.avatarThumb || avatar} />
              </Grid>
              <Grid container item xs={7} sm={8} alignItems="center" alignContent="center" justify="center">
                <Grid container>
                  <Grid item xs={12}>
                    <Typography>{user.fullName}</Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    alignItems="center"
                    alignContent="center"
                    justify="flex-start"
                    className={classes.hoverable}
                  >
                    <Typography display="inline">{activitiesUtil.getActionElement(action)}</Typography>
                    <Typography variant="body2" display="inline" style={{ marginLeft: 5 }}>
                      {title.length > 79 ? (
                        <>
                          <span>{title.slice(0, 80)}</span>
                          <span> ...</span>
                          <span>{title.slice(80, -1)}</span>
                        </>
                      ) : (
                        title
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="caption" align="right">
                      {Moment().diff(Moment(timestamp), `days`) < 5
                        ? Moment(timestamp)
                            .fromNow()
                            .charAt(0)
                            .toUpperCase() +
                          Moment(timestamp)
                            .fromNow()
                            .slice(1)
                        : Moment(timestamp).format(`DD/MM/YYYY HH:MM`)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="caption" align="right">
                      {icon}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </NavLink>
      </Slide>
    ));

const Activities = props => {
  const {
    propsActivities,
    avatar,
    changeActivitiesFilters,
    classes,
    filterValue,
    handleFetchStatus,
    handleMenuActive,
    loadActivitiesRequest,
  } = props;

  const loadDebouncer = useRef();

  const cards = getCards(avatar, classes, handleMenuActive, (propsActivities && propsActivities.data) || []);
  const hasData = cards.length;

  useEffect(() => {
    clearTimeout(loadDebouncer.current);
    loadDebouncer.current = setTimeout(() => {
      if (!hasData) {
        loadActivitiesRequest();
      } else if (hasData && filterValue.status) {
        changeActivitiesFilters({ searchText: filterValue.value });
        handleFetchStatus();
      }
    }, 500);
  }, [changeActivitiesFilters, filterValue, hasData, handleFetchStatus, loadActivitiesRequest]);

  return (
    <div
      className={classes.smRecipient}
      onScroll={event => {
        event.stopPropagation();
        event.persist();
        if (hasData && !propsActivities.loading) {
          if (
            Math.round(event.target.scrollHeight - event.target.scrollTop) * 0.8 <= event.target.clientHeight &&
            propsActivities.count > propsActivities.data.length
          ) {
            loadActivitiesRequest();
          }
        }
      }}
    >
      {hasData ? cards : <Typography className={classes.typo}>Nenhuma atividade no banco de dados.</Typography>}
      {propsActivities && propsActivities.loading && (
        <div className={classes.loaderWrapper}>
          <Fade
            in={propsActivities.loading}
            style={{
              transitionDelay: propsActivities.loading ? `800ms` : `0ms`,
            }}
            unmountOnExit
          >
            <CircularProgress />
          </Fade>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  propsActivities: state.Activities,
  Institutions: state.Institutions.data,
  Sectors: state.Sectors.data,
});

const mapDispatchToProps = dispatch => bindActionCreators(ActivitiesActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(Activities));
