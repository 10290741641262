import React, { SFC } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { SimpleCategoryDialog } from '../../ReduxFlow/Reducers/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionsRoot: {
      alignItems: `center`,
      display: `flex`,
      flex: `0 0 auto`,
      justifyContent: `flex-end`,
      padding: 24,
    },
    actionsSpacing: {
      '& > :not(:first-child)': {
        marginLeft: 16,
      },
    },
    disabledButton: {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: `${theme.palette.primary.contrastText} !important`,
    },
  }),
);

type DeleteConfirmDialogProps = {
  closeDeleteDialog: Function;
  deleteDialog: { open: boolean; category: SimpleCategoryDialog };
  deleteSubmit: Function;
};

const DeleteConfirmDialog: SFC<DeleteConfirmDialogProps> = ({ closeDeleteDialog, deleteDialog, deleteSubmit }) => {
  const classes = useStyles();
  const { category, open } = deleteDialog;
  const { name } = category;

  return (
    <Dialog
      aria-labelledby="category-delete-dialog"
      fullWidth
      maxWidth="xs"
      onClose={(): void => closeDeleteDialog()}
      open={open}
    >
      <DialogTitle disableTypography>
        <Typography variant="h6">Desativar categoria?</Typography>
        <Typography variant="body1" style={{ color: `#00000099` }}>
          Categoria: {name}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography align="justify" variant="body1" style={{ color: `#00000099` }}>
          Não será possível atribuí-la à novos itens, mas os itens existentes com esta categoria não serão alterados.
          <br />
          <br />
          Você pode reativá-la novamente quando quiser.
        </Typography>
      </DialogContent>
      <DialogActions classes={{ root: classes.actionsRoot, spacing: classes.actionsSpacing }}>
        <Button color="default" onClick={(): void => closeDeleteDialog()}>
          Cancelar
        </Button>
        <Button color="primary" variant="contained" onClick={(): void => deleteSubmit()}>
          Desativar Categoria
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DeleteConfirmDialogTests = DeleteConfirmDialog;

export { DeleteConfirmDialogTests };

export default DeleteConfirmDialog;
