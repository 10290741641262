import { all, call, put } from 'redux-saga/effects';
import { Action } from 'redux';
import { SagaIterator } from 'redux-saga';
import { Dialog } from 'muibox';

/**
 * API from axios
 */
import api from '../../../Services/api';

import {
  addOrUpdateFailure,
  addSuccess,
  closeInternalDocsCategoryRequest,
  closeInternalDocsCategorySuccess,
  loadRequest,
  loadSuccess,
  openInternalDocsCategorySuccess,
  updateSuccess,
} from './Actions';
import { InternalDocsCategoriesTypes } from './Types';

interface LoadActionType extends Action {
  payload: {
    dialog: Dialog;
  };
}

interface OpenOrCloseDialogType extends Action {
  payload: {
    dialog: Dialog;
    id?: number | string;
  };
}

interface AddOrUpdateActionType extends Action {
  payload: {
    dialog: Dialog;
    id?: number | string;
    postData: {
      description?: string;
      id: number | string;
      name: string;
    };
  };
}

export function* loadInternalDocsCategories(action: LoadActionType): SagaIterator {
  try {
    const response = yield call(api.get, `/internaldocs/category/`);
    yield put(loadSuccess(response.data));
  } catch (error) {
    const { dialog } = action.payload;
    dialog.alert(`Não foi possível connectar com o servidor!`);
    yield put({ type: InternalDocsCategoriesTypes.LOAD_ERRORS });
  }
}

export function* openInternalDocsCategoriesDialog(action: OpenOrCloseDialogType): SagaIterator {
  try {
    if (action.payload.id) {
      const response = yield call(api.get, `/internaldocs/category/${action.payload.id}/`);
      yield put(openInternalDocsCategorySuccess(response.data));
    } else {
      yield put(openInternalDocsCategorySuccess());
    }
  } catch (error) {
    const { dialog } = action.payload;
    dialog.alert(
      `Não foi possível realizar essa ação, por favor realize um refresh no navegador e caso o problema não seja resolvido entre em contato com nosso suporte!`,
    );
    yield put({ type: InternalDocsCategoriesTypes.LOAD_ERRORS });
  }
}

export function* closeInternalDocsCategoriesDialog(action: OpenOrCloseDialogType): SagaIterator {
  try {
    yield put(closeInternalDocsCategorySuccess());
  } catch (error) {
    const { dialog } = action.payload;
    dialog.alert(
      `Não foi possível realizar essa ação, por favor realize um refresh no navegador e caso o problema não seja resolvido entre em contato com nosso suporte!`,
    );
  }
}

export function* addOrUpdateInternalDocsCategories(action: AddOrUpdateActionType): SagaIterator {
  const { dialog, postData } = action.payload;
  try {
    if (postData.id) {
      const response = yield call(api.patch, `/internaldocs/category/${postData.id}/`, postData);
      yield all([put(updateSuccess(response.data)), put(loadRequest(dialog))]);
    } else {
      const response = yield call(api.post, `/internaldocs/category/`, postData);
      yield all([put(addSuccess(response.data)), put(loadRequest(dialog))]);
    }
    yield put(closeInternalDocsCategoryRequest());
  } catch (error) {
    dialog.alert(
      `Não foi possível realizar essa ação, por favor realize um refresh no navegador e caso o problema não seja resolvido entre em contato com nosso suporte!`,
    );
    yield put(addOrUpdateFailure(error.response.data));
  }
}
