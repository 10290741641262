/* eslint-disable no-nested-ternary */
import React from 'react';
/**
 * Material UI - Core
 */
import {
  Chip,
  CircularProgress,
  Fab,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Slide,
  Typography,
  withStyles,
} from '@material-ui/core';
/**
 * Material UI - Icons
 */
import {
  Add,
  Build,
  EventAvailableOutlined,
  FormatListBulleted,
  RecordVoiceOverOutlined,
  ReportProblem,
} from '@material-ui/icons';
/**
 * FontAwesome - Icons
 */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import TableInstitutions from '../../Components/TableInstitutions';

const styles = theme => ({
  avatar: {
    margin: 10,
    width: 50,
    height: 50,
  },
  instAvatar: {
    maxWidth: 40,
    maxHeight: 40,
  },
  userText: {
    wordWrap: `break-word`,
  },
  userWrapper: {
    margin: `5px auto 5px auto`,
    maxWidth: `98%`,
  },
  fab: {
    position: `absolute`,
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  loader: {
    position: `fixed`,
    bottom: 30,
    left: `45%`,
    zIndex: 999,
  },
  footer: {
    width: `100vw`,
    height: `56px`,
    position: `absolute`,
    bottom: 0,
    left: 0,
  },
});

const MobileTasks = ({ addmenuselector, classes, close, data, institutions, isLoading, handleOpen, open }) => {
  return (
    <>
      {isLoading && <CircularProgress className={classes.loader} />}
      {Boolean(data.length) &&
        data.map(value => {
          const startDate = new Date(value.dates[0]).toLocaleDateString(`pt-br`);
          const cell = (
            <>
              {value.markers && (
                <div style={{ textAlign: `justify ` }}>
                  {Boolean(value.markers.length) &&
                    value.markers.map(marker => (
                      <Chip
                        key={marker[0]}
                        label={marker[1]}
                        style={{
                          fontSize: 9,
                          fontWeight: `bold`,
                          color: marker[2],
                          backgroundColor: marker[3],
                          height: 12,
                        }}
                      />
                    ))}
                </div>
              )}
              <div style={{ textAlign: `justify ` }}>
                <Typography>
                  <small>{value.taskTitle.title}</small>
                </Typography>
                <Typography display="inline" color="textSecondary" style={{ marginRight: 5 }}>
                  <small>{`#${value.taskTitle.id}`}</small>
                </Typography>
                {value.equipment && value.equipment.situation === `Parado` && (
                  <Chip
                    label="Parado"
                    style={{
                      fontSize: 9,
                      fontWeight: `bold`,
                      color: `#fff`,
                      backgroundColor: `#dc3545`,
                      height: 12,
                    }}
                  />
                )}
                {value.equipment && value.equipment.situation === `Parcial` && (
                  <Chip
                    label="Parcial"
                    style={{
                      fontSize: 9,
                      fontWeight: `bold`,
                      color: `#fff`,
                      backgroundColor: `#ffc107`,
                      height: 12,
                    }}
                  />
                )}
                {value.equipment && value.equipment.situation === `Funcionando` && (
                  <Chip
                    label="Funcionando"
                    style={{
                      fontSize: 9,
                      fontWeight: `bold`,
                      color: `#fff`,
                      backgroundColor: `#28a745`,
                      height: 12,
                    }}
                  />
                )}
              </div>
            </>
          );
          return (
            <Slide key={value.id} in direction="up" mountOnEnter unmountOnExit>
              <Paper
                className={classes.userWrapper}
                onClick={() => handleOpen({ id: value.id, menuType: value.taskType.taskType })}
              >
                <Grid container alignItems="center">
                  <Grid item xs={2}>
                    {!value.taskType.isActive ? (
                      <FontAwesomeIcon icon={faCheckCircle} style={{ color: `#1dbd1d`, margin: 20 }} />
                    ) : value.taskType.taskType === `Chamado` ? (
                      <Build style={{ color: `rgb(33, 64, 215)`, margin: 20 }} />
                    ) : value.taskType.taskType === `Não conformidade` ? (
                      <ReportProblem color="secondary" style={{ margin: 20 }} />
                    ) : value.taskType.taskType === `Ouvidoria` ? (
                      <RecordVoiceOverOutlined style={{ color: `rgb(128, 0, 128)`, margin: 20 }} />
                    ) : value.taskType.taskType === `Preventiva` ? (
                      <EventAvailableOutlined style={{ color: `rgb(33, 117, 33)`, margin: 20 }} />
                    ) : (
                      <FormatListBulleted style={{ color: `#4bc2c8`, margin: 20 }} />
                    )}
                  </Grid>
                  <Grid item xs={8} className={classes.userText}>
                    {cell}
                  </Grid>
                  <Grid container item xs={2} alignItems="center" justify="center">
                    <Typography>
                      <small style={{ position: `relative`, top: 0, right: -5, fontSize: 9 }}>{startDate}</small>
                    </Typography>
                    <TableInstitutions institutions={institutions} selected={value.institution} />
                  </Grid>
                </Grid>
              </Paper>
            </Slide>
          );
        })}
      <Fab aria-label="Adicionar" aria-owns="addMobileMenu" className={classes.fab} color="primary" onClick={open}>
        <Add />
      </Fab>
      <Menu
        id="addMenu"
        anchorEl={addmenuselector}
        open={Boolean(addmenuselector)}
        anchorOrigin={{ vertical: `top`, horizontal: `right` }}
        transformOrigin={{ vertical: `bottom`, horizontal: `center` }}
        getContentAnchorEl={null}
        onClose={close}
        disableAutoFocusItem
      >
        {[
          { name: `Tarefa`, id: 0 },
          { name: `Chamado`, id: 1 },
          { name: `Ouvidoria`, id: 2 },
          { name: `Não conformidade`, id: 3 },
          { name: `Preventiva`, id: 4 },
        ].map(val => (
          <MenuItem key={val.id} onClick={event => handleOpen({ id: null, menuType: val.name })}>
            {val.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default withStyles(styles)(MobileTasks);
