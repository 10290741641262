import React, { FC } from 'react';
import { Typography } from '@material-ui/core';
import Dot, { DotTypeEnum } from '../../../../SLATooltip/Dot';
import { timestampToDaysHoursAndMinutesString } from '../../../../../Utils/categoriesUtils';
import AutoComment from '../../AutoComment';
import { AutoCommentCommonTypes } from '../..';
import { Comment } from '..';

interface FRTParsed {
  frt: number;
  frtSla: number;
}

interface RTParsed {
  rt: number;
  rtSla: number;
}

type FRTOrRTAutoCommentProps = Omit<AutoCommentCommonTypes<Comment>, 'genericAvatar'> & { isFrt?: boolean };// eslint-disable-line

const FRTOrRTAutoComment: FC<FRTOrRTAutoCommentProps> = ({
  comment,
  fromNow,
  isFirstItem = false,
  isFrt,
  isLastItem = false,
  nextIsAutoComment,
  previousIsAutoComment,
}) => {
  const { userDetail } = comment;
  const frtInfo = isFrt && (JSON.parse(comment.comment) as FRTParsed);
  const rtInfo = !isFrt && (JSON.parse(comment.comment) as RTParsed);
  const frtSuccess = frtInfo && frtInfo.frt < frtInfo.frtSla;
  const rtSuccess = rtInfo && rtInfo.rt < rtInfo.rtSla;
  const slaText = isFrt
    ? `TPR de ${frtInfo && timestampToDaysHoursAndMinutesString(frtInfo.frt, true)}
      ${frtSuccess ? ` (dentro` : ` (fora`} do esperado de
      ${frtInfo && timestampToDaysHoursAndMinutesString(frtInfo.frtSla, true)}) calculado pelo comentário de `
    : `TR de ${rtInfo && timestampToDaysHoursAndMinutesString(rtInfo.rt, true)}
      ${rtSuccess ? ` (dentro` : ` (fora`} do esperado de
      ${rtInfo && timestampToDaysHoursAndMinutesString(rtInfo.rtSla, true)}) calculado pelo comentário de `;

  return (
    <AutoComment
      avatar={
        <Dot
          dotType={frtSuccess || rtSuccess ? DotTypeEnum.SUCCESS : DotTypeEnum.ERROR}
          sizes={{ height: 12, width: 12 }}
        />
      }
      fromNow={fromNow}
      isFirstItem={isFirstItem}
      isLastItem={isLastItem}
      nextIsAutoComment={nextIsAutoComment}
      previousIsAutoComment={previousIsAutoComment}
      startAdornment={
        <Typography color="textSecondary" component="span" display="inline" variant="body2">
          {slaText}
        </Typography>
      }
      userName={userDetail.fullName}
    />
  );
};

export default FRTOrRTAutoComment;
