/**
 * Action types
 */
export enum SuppliersTypes {
  LOAD_REQUEST = '@Suppliers/LOAD_REQUEST', // eslint-disable-line
  LOAD_SUCCESS = '@Suppliers/LOAD_SUCCESS', // eslint-disable-line
  LOAD_FAILURE = '@Suppliers/LOAD_FAILURE', // eslint-disable-line
  ADD_REQUEST = '@Suppliers/ADD_REQUEST', // eslint-disable-line
  ADD_SUCCESS = '@Suppliers/ADD_SUCCESS', // eslint-disable-line
  ADD_FAILURE = '@Suppliers/ADD_FAILURE', // eslint-disable-line
  UPDATE_REQUEST = '@Suppliers/UPDATE_REQUEST', // eslint-disable-line
  UPDATE_SUCCESS = '@Suppliers/UPDATE_SUCCESS', // eslint-disable-line
  UPDATE_FAILURE = '@Suppliers/UPDATE_FAILURE', // eslint-disable-line
  OPEN_REQUEST = '@Suppliers/OPEN_REQUEST', // eslint-disable-line
  OPEN_SUCCESS = '@Suppliers/OPEN_SUCCESS', // eslint-disable-line
  CLOSE_REQUEST = '@Suppliers/CLOSE_REQUEST', // eslint-disable-line
  CLOSE_SUCCESS = '@Suppliers/CLOSE_SUCCESS', // eslint-disable-line
  RANGE_REQUEST = '@Suppliers/RANGE_REQUEST', // eslint-disable-line
  RANGE_SUCCESS = '@Suppliers/RANGE_SUCCESS', // eslint-disable-line
}
/**
 * Data types
 */
export interface Supplier {
  id: number;
  name: string;
  tradingName: string;
  cnpj: string;
  cep: string;
  state: string;
  city: string;
  district: string;
  address: string;
  phone: string;
  contact: string;
  activity: string;
  observations: string;
  isActive: boolean;
  institution: string;
}

export interface SupplierError {
  name?: string;
  tradingName?: string;
  cnpj?: string;
  cep?: string;
  state?: string;
  city?: string;
  district?: string;
  address?: string;
  phone?: string;
  contact?: string;
  activity?: string;
  observations?: string;
  isActive?: string;
  institution?: string;
}
/**
 * State types
 */
export interface SuppliersState {
  readonly data: Supplier[];
  readonly formErrors?: SupplierError;
  readonly isLoading: boolean;
  readonly error: boolean;
  readonly isSaving: boolean;
  readonly modalOpen: boolean;
  readonly formInstance: any[];
  readonly range: number;
}
