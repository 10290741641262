import { action } from 'typesafe-actions';
import { ActivitiesTypes, Activity } from './Types';

export const loadActivitiesRequest = () => action(ActivitiesTypes.LOAD_REQUEST);
export const loadActivitiesSuccess = (data: {
  results: Activity[];
  count: number;
  next?: string;
  previous?: string;
}) => action(ActivitiesTypes.LOAD_SUCCESS, { data });
export const loadActivitiesFailure = () => action(ActivitiesTypes.LOAD_FAILURE);
export const loadScoreRequest = () => action(ActivitiesTypes.SCORE_REQUEST);
export const loadScoreSuccess = (data: { score: any }) =>
  action(ActivitiesTypes.SCORE_SUCCESS, { data });
export const loadScoreFailure = () => action(ActivitiesTypes.SCORE_FAILURE);
export const changeActivitiesFilters = (data: { searchText: string }) =>
  action(ActivitiesTypes.CHANGE_FILTERS, data);
