import React, { ChangeEvent, FC, MouseEvent, ReactNode, useRef, useState } from 'react';
import {
  Avatar,
  Fade,
  InputAdornment,
  MenuList,
  MenuItem,
  Paper,
  Popper,
  PopperProps,
  TextField,
} from '@material-ui/core';
import {
  Code as CodeIcon,
  FormatBold as FormatBoldIcon,
  FormatItalic as FormatItalicIcon,
  InsertLink as InsertLinkIcon,
} from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { MarginType } from 'Utils/globalTypes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filled: {
      marginTop: `0 !important`,
    },
    listRoot: {
      display: `inline-flex`,
    },
    popper: {
      zIndex: 1301,
    },
  }),
);

interface CommentsInputProps {
  autoFocus?: boolean;
  avatarStyles: string;
  avatarThumb: string;
  disabled?: boolean;
  endAdornment?: ReactNode;
  id?: string;
  inputChangeCallback: (event: ChangeEvent<HTMLInputElement> | { target: { value: string } }) => void;
  inputError?: boolean;
  inputHelperText?: string;
  margin?: MarginType;
  name?: string;
  placeholder: string;
  value: string;
}

const CommentsInput: FC<CommentsInputProps> = ({
  autoFocus,
  avatarStyles,
  avatarThumb,
  disabled,
  endAdornment,
  id,
  inputChangeCallback,
  inputError,
  inputHelperText,
  margin,
  name,
  placeholder,
  value,
}) => {
  const classes = useStyles();

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const [markdownAnchorEl, setMarkdownAnchoEl] = useState<PopperProps['anchorEl']>(null); // eslint-disable-line

  function getSelection(): { selectionEnd: number; selectionStart: number; text: string } | undefined {
    if (textAreaRef.current) {
      const textArea = textAreaRef.current;
      const { selectionEnd, selectionStart } = textArea;
      return { selectionEnd, selectionStart, text: textArea.value.substring(selectionStart, selectionEnd) };
    }
    return undefined;
  }

  function closeMarkdownMenu(): void {
    if (textAreaRef.current) {
      const textAreaValueLen = textAreaRef.current.value.length;
      textAreaRef.current.selectionEnd = textAreaValueLen;
      textAreaRef.current.selectionStart = textAreaValueLen;
    }
    setMarkdownAnchoEl(null);
  }

  function openMarkdownMenu(event: MouseEvent<HTMLInputElement>): void {
    event.preventDefault();
    event.persist();
    if (markdownAnchorEl) {
      closeMarkdownMenu();
      return;
    }
    const selection = getSelection();
    if (selection && selection.text) {
      const { clientX, clientY } = event;
      const getBoundingClientRect = (): ClientRect => ({
        bottom: clientY + 19,
        height: 19,
        left: clientX,
        right: clientX + 150,
        top: clientY,
        width: 150,
      });
      setMarkdownAnchoEl({
        clientHeight: 19,
        clientWidth: 150,
        getBoundingClientRect,
      });
    }
  }

  function checkIfIsSelected(regExp: RegExp): boolean {
    const stringSelected = getSelection();
    return !!stringSelected && !!stringSelected.text.match(regExp);
  }

  function turnBoldOrItalic(styleChars: string): void {
    const selection = getSelection();
    const regExp = new RegExp(`^${styleChars}[^*]*${styleChars}$`);
    if (selection && selection.text) {
      const isBoldOrItalic = selection.text.match(regExp);
      if (isBoldOrItalic)
        inputChangeCallback({
          target: {
            value: `${value.substring(0, selection.selectionStart)}${value
              .substring(selection.selectionStart, selection.selectionEnd)
              .replace(/\*/g, ``)}${value.substring(selection.selectionEnd, value.length)}`,
          },
        });
      if (!isBoldOrItalic && !selection.text.match(/\*/)) {
        const newStyleChars = styleChars.replace(/\\/g, ``);
        inputChangeCallback({
          target: {
            value: `${value.substring(0, selection.selectionStart)}${newStyleChars}${value.substring(
              selection.selectionStart,
              selection.selectionEnd,
            )}${newStyleChars}${value.substring(selection.selectionEnd, value.length)}`,
          },
        });
      }
    }
    closeMarkdownMenu();
  }

  function turnCode(): void {
    const selection = getSelection();
    const styleChars = `\`\`\``;
    const regExp = /^```.+```$/;
    if (selection && selection.text) {
      if (selection.text.match(regExp)) {
        inputChangeCallback({
          target: {
            scrollHeight: textAreaRef.current ? textAreaRef.current.scrollHeight : 0,
            value: `${value.substring(0, selection.selectionStart)}${value
              .substring(selection.selectionStart, selection.selectionEnd)
              .replace(/```/g, ``)}${value.substring(selection.selectionEnd, value.length)}`,
          },
        });
      } else {
        inputChangeCallback({
          target: {
            scrollHeight: textAreaRef.current ? textAreaRef.current.scrollHeight : 0,
            value: `${value.substring(0, selection.selectionStart)}${styleChars}${value.substring(
              selection.selectionStart,
              selection.selectionEnd,
            )}${styleChars}${value.substring(selection.selectionEnd, value.length)}`,
          },
        });
      }
    }
    closeMarkdownMenu();
  }

  function addHyperlink(): void {
    const selection = getSelection();
    if (selection) {
      if (selection.text.match(/\[([^\]]+)\]\(([^)]+)\)/))
        inputChangeCallback({
          target: {
            value: `${value.substring(0, selection.selectionStart)}${value
              .substring(selection.selectionStart, selection.selectionEnd)
              .replace(/[[,\]]/g, ``)
              .replace(/\((.*?)\)/g, ``)}${value.substring(selection.selectionEnd, value.length)}`,
          },
        });
      if (!selection.text.match(/\[([^\]]+)\]\(([^)]+)\)/))
        inputChangeCallback({
          target: {
            value: `${value.substring(0, selection.selectionStart)}[${value.substring(
              selection.selectionStart,
              selection.selectionEnd,
            )}](Digite aqui a url)${value.substring(selection.selectionEnd, value.length)}`,
          },
        });
    }
    closeMarkdownMenu();
  }

  const MarkdownMenu = (
    <Popper anchorEl={markdownAnchorEl} className={classes.popper} open={!!markdownAnchorEl} transition>
      {({
        TransitionProps,
      }: {
        TransitionProps: {
          in: boolean;
          onEnter: (node: HTMLElement, isAppearing: boolean) => void;
          onExited: (node: HTMLElement) => void;
        };
      }): ReactNode => {
        return (
          <Fade
            in={TransitionProps.in}
            onEnter={TransitionProps.onEnter}
            onExited={TransitionProps.onExited}
            timeout={350}
          >
            <Paper>
              <MenuList
                classes={{
                  root: classes.listRoot,
                }}
              >
                <MenuItem
                  onClick={(): void => turnBoldOrItalic(`\\*\\*`)}
                  selected={checkIfIsSelected(/^\*\*[^*]*\*\*$/)}
                >
                  <FormatBoldIcon />
                </MenuItem>
                <MenuItem onClick={(): void => turnBoldOrItalic(`\\*`)} selected={checkIfIsSelected(/^\*[^*]*\*$/)}>
                  <FormatItalicIcon />
                </MenuItem>
                <MenuItem onClick={addHyperlink} selected={checkIfIsSelected(/\[([^\]]+)\]\(([^)]+)\)/)}>
                  <InsertLinkIcon />
                </MenuItem>
                <MenuItem onClick={(): void => turnCode()} selected={checkIfIsSelected(/^```.+```$/)}>
                  <CodeIcon />
                </MenuItem>
              </MenuList>
            </Paper>
          </Fade>
        );
      }}
    </Popper>
  );

  return (
    <>
      <TextField
        autoFocus={autoFocus}
        disabled={disabled}
        error={inputError}
        helperText={inputHelperText}
        id={id}
        InputProps={{
          startAdornment: (
            <InputAdornment classes={{ filled: disabled ? classes.filled : undefined }} position="start">
              <Avatar src={avatarThumb} className={avatarStyles} />
            </InputAdornment>
          ),
          endAdornment,
          inputProps: {
            ref: textAreaRef,
          },
        }}
        multiline
        margin={margin}
        name={name}
        onChange={inputChangeCallback}
        onMouseUp={openMarkdownMenu}
        placeholder={placeholder}
        style={{ opacity: disabled ? 0.4 : undefined, width: `100%` }}
        value={value}
        variant="outlined"
      />
      {MarkdownMenu}
    </>
  );
};

export default CommentsInput;
