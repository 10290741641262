import React, { FC, ReactNode } from 'react';
import { Avatar, Button, CircularProgress, Grid, Slide, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Markdown from 'markdown-to-jsx';
import Moment from 'moment';
import markdownOverrides from '../../../Utils/markdownOverrides';
import 'moment/locale/pt-br';

Moment.locale(`pt-BR`);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      height: 30,
      width: 30,
    },
    avatarContainer: {
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(1),
    },
    container: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(1),
    },
    commentWrapper: {
      overflowX: `hidden`,
      padding: theme.spacing(1),
      textAlign: `justify`,
      whiteSpace: `pre-wrap`,
      wordWrap: `break-word`,
    },
    headerContainer: {
      paddingBottom: theme.spacing(1),
    },
  }),
);

export interface Comment {
  comment: string;
  date: string;
  id: number;
  isCloseComment: boolean;
  isFirstResponse: boolean;
  isReopenComment: boolean;
  user: number;
  userDetail: {
    avatarThumb: string;
    fullName: string;
  };
  [key: string]: boolean | number | object | string;
}

interface CommentsWrapperProps {
  comments: Comment[];
  commentsControllCallback?: (length: number) => () => void;
  commentsRange?: number;
  genericAvatar: string;
  isLoading?: boolean;
  tprInfoComponent?: ReactNode;
}

const CommentsWrapper: FC<CommentsWrapperProps> = ({
  comments,
  commentsControllCallback,
  commentsRange,
  genericAvatar,
  isLoading,
  tprInfoComponent,
}) => {
  const classes = useStyles();

  return (
    <>
      {comments.length > 2 && commentsControllCallback && commentsRange && (
        <Button onClick={commentsControllCallback(comments.length)}>
          {comments.length < commentsRange
            ? `Mostrando ${comments.length} de ${comments.length} comentários`
            : `Mostrando ${commentsRange} de ${comments.length} comentários`}
        </Button>
      )}
      {!isLoading ? (
        comments.slice(-(commentsRange || 0)).map(comment => {
          const commentUser = comment.userDetail;
          const fromNow = Moment(comment.date).fromNow();
          return (
            <Slide key={comment.id} in direction="up" mountOnEnter unmountOnExit>
              <Grid className={classes.container} container>
                <Grid
                  className={classes.avatarContainer}
                  container
                  item
                  xs={2}
                  sm={1}
                  alignItems="flex-start"
                  alignContent="flex-start"
                  justify="flex-start"
                >
                  <Avatar className={classes.avatar} src={commentUser.avatarThumb || genericAvatar} />
                </Grid>
                <Grid container item xs={10} sm={11}>
                  <Grid className={classes.commentWrapper} container justify="flex-start">
                    <Grid
                      className={classes.headerContainer}
                      alignContent="center"
                      alignItems="center"
                      container
                      item
                      justify="space-between"
                      xs={12}
                    >
                      <Typography>
                        <span
                          style={{
                            fontSize: 13,
                          }}
                        >
                          <strong>{commentUser.fullName}</strong>
                          {comment.isCloseComment && ` - Fechamento`}
                          {comment.isReopenComment && ` - Reabertura`}
                          {comment.isFirstResponse && tprInfoComponent}
                        </span>
                      </Typography>
                      <Typography>
                        <small style={{ fontSize: 11 }}>{`${fromNow.substring(0, 1).toUpperCase()}${fromNow.substring(
                          1,
                        )}`}</small>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={11}>
                      <Markdown
                        options={{
                          overrides: markdownOverrides,
                        }}
                      >
                        {comment.comment}
                      </Markdown>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Slide>
          );
        })
      ) : (
        <Grid container alignContent="center" alignItems="center" justify="center">
          <CircularProgress />
        </Grid>
      )}
    </>
  );
};

export default CommentsWrapper;
