export default function(str) {
  return str
    ? str
        .trim()
        .toLocaleLowerCase()
        .normalize(`NFD`)
        .replace(/[\u0300-\u036f]/g, ``)
    : ``;
}

export const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1);
