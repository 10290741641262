import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Document, Page, pdfjs } from 'react-pdf';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { ArrowBack, ZoomIn, ZoomOut } from '@material-ui/icons';

import { withSnackbar } from 'notistack';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const FileDialog = ({ file, handleFileClose, open, title, enqueueSnackbar, fileId }) => {
  const [fileControl, setFileControl] = useState({ numPages: null, pageNumber: 1, scale: 1 });
  const { numPages: componentNumPages, pageNumber, scale } = fileControl;

  const onDocumentLoadSuccess = ({ numPages }) => setFileControl({ ...fileControl, numPages });

  const handlePageNumberChange = event => {
    const docHeight = document.getElementsByClassName(`react-pdf__Page`)[0].offsetHeight;
    setFileControl({
      ...fileControl,
      pageNumber: Math.floor(event.target.scrollTop / docHeight) + 1,
    });
  };

  return (
    <Dialog
      aria-labelledby="file-dialog-title"
      fullScreen
      onClose={handleFileClose}
      open={open}
      PaperProps={{
        style: {
          backgroundColor: `transparent`,
          boxShadow: `none`,
        },
      }}
      scroll="paper"
    >
      <DialogTitle id="file-dialog-title">
        <Grid container justify="space-between" alignItems="stretch">
          <Grid container item alignContent="flex-start" xs={1} sm={1} md={1} lg={1} xl={1}>
            <Tooltip title="Fechar">
              <Button aria-label="close" onClick={handleFileClose} color="default">
                <ArrowBack style={{ color: `#ffffff` }} />
              </Button>
            </Tooltip>
          </Grid>

          <Grid container item alignContent="center" xs={10} sm={10} md={10} lg={10} xl={10}>
            <Typography
              align="center"
              display="inline"
              style={{ color: `#ffffff`, alignContent: `center`, width: `100%` }}
              variant="h6"
            >
              {title}
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent
        onScroll={handlePageNumberChange}
        style={{
          textAlign: `center`,
          justifyContent: `center`,
          justifyItems: `center`,
          display: `flex`,
        }}
      >
        <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
          {[...Array(componentNumPages)].map((val, idx, arr) => (
            <React.Fragment key={idx}>
              <Page pageNumber={idx + 1} width={0.6 * window.innerWidth} scale={scale} style={{ marginBottom: 40 }} />
              {!(idx === arr.lenght) && <Divider style={{ height: 40 }} />}
            </React.Fragment>
          ))}
        </Document>
      </DialogContent>
      <Toolbar
        style={{
          textAlign: `center`,
          justifyContent: `center`,
          justifyItems: `center`,
        }}
      >
        <Typography display="inline" style={{ color: `#ffffff` }}>
          <small>
            Página {pageNumber} de {componentNumPages}
          </small>
        </Typography>
        <Tooltip title="Diminuir zoom">
          <Button color="default" onClick={() => setFileControl({ ...fileControl, scale: scale - 0.5 })}>
            <ZoomOut style={{ color: `#ffffff` }} />
          </Button>
        </Tooltip>
        <Tooltip title="Aumentar zoom">
          <Button color="default" onClick={() => setFileControl({ ...fileControl, scale: scale + 0.5 })}>
            <ZoomIn style={{ color: `#ffffff` }} />
          </Button>
        </Tooltip>
      </Toolbar>
    </Dialog>
  );
};

FileDialog.defaultProps = {
  file: null,
  title: ``,
};

FileDialog.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  file: PropTypes.string,
  handleFileClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
};

export default withSnackbar(FileDialog);

export { FileDialog };
