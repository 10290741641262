const initState = {
  admissionDate: null,
  avatar: null,
  birthDate: null,
  cpf: ``,
  email: ``,
  enableEmailNotifications: true,
  firstName: ``,
  isFakeEmail: false,
  lastName: ``,
  sex: ``,
};

export { initState };
