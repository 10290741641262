import React, { FC, ReactNode } from 'react';
import { useParams } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@material-ui/core';
import { FilterValue } from 'Routes';
import { timestampToDaysHoursAndMinutesString } from 'Utils/categoriesUtils';
import { getMoodFromInt } from 'Components/MoodPicker';
import { Dot, DotTypeEnum } from 'Components/SLATooltip';
import { SlaKpis } from 'ReduxFlow/Reducers/types';
import NoMatch from '../NoMatch';
import Ombudsmans from './Ombudsmans';

interface DynamicTasksProps {
  avatar: string;
  filterValue: FilterValue;
  handleFetchStatus: () => void;
}

export const buildKpiComponent = (data: {
  classes: { cell: string; headerCell: string; table: string };
  props: { slaKpis?: SlaKpis };
}): ReactNode => {
  const { classes, props } = data;
  const { slaKpis } = props;
  const { frtKpi = 0, meanFrt = 0, meanMood = 0, meanRt = 0, rtKpi = 0 } = slaKpis || {
    frtKpi: 0,
    meanFrt: 0,
    meanMood: 0,
    meanRt: 0,
    rtKpi: 0,
  };
  const meanMoodString = meanMood.toFixed(1);
  const roundedMood = Math.floor(parseFloat(meanMoodString)); // use icon according to rounded value
  const mood = getMoodFromInt(roundedMood);
  const moodEmoji = mood ? mood.src : ``;

  const getKpiDotColor = (kpi: number): DotTypeEnum => {
    if (kpi <= 0.6) return DotTypeEnum.ERROR;
    if (kpi >= 0.9) return DotTypeEnum.SUCCESS;
    return DotTypeEnum.WARNING;
  };

  return (
    <TableContainer aria-label="KPI's table" classes={{ root: classes.cell }}>
      <Table className={classes.table} padding="none" size="small">
        <TableHead>
          <TableRow>
            <TableCell className={`${classes.cell} ${classes.headerCell}`}>TPR Médio</TableCell>
            <TableCell className={`${classes.cell} ${classes.headerCell}`}>
              <Tooltip title="To-dos dentro do TPR esperado">
                <span>% TPR</span>
              </Tooltip>
            </TableCell>
            <TableCell className={`${classes.cell} ${classes.headerCell}`}>TR Médio</TableCell>
            <TableCell className={`${classes.cell} ${classes.headerCell}`}>
              <Tooltip title="To-dos dentro do TR esperado">
                <span>% TR</span>
              </Tooltip>
            </TableCell>
            {!!moodEmoji && <TableCell className={`${classes.cell} ${classes.headerCell}`}>Satisfação Média</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell className={classes.cell}>{timestampToDaysHoursAndMinutesString(meanFrt, true)}</TableCell>
            <TableCell className={classes.cell}>
              <>
                <Dot dotType={getKpiDotColor(frtKpi)} marginRight={8} />
                {(frtKpi * 100).toFixed(1)}%
              </>
            </TableCell>
            <TableCell className={classes.cell}>{timestampToDaysHoursAndMinutesString(meanRt, true)}</TableCell>
            <TableCell className={classes.cell}>
              <>
                <Dot dotType={getKpiDotColor(rtKpi)} marginRight={8} />
                {(rtKpi * 100).toFixed(1)}%
              </>
            </TableCell>
            {!!moodEmoji && (
              <TableCell className={classes.cell}>
                <>{`${moodEmoji} ${meanMoodString} de 5`}</>
              </TableCell>
            )}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export enum FontSize {
  default = 'default', // eslint-disable-line
  inherit = 'inherit', // eslint-disable-line
  large = 'large', // eslint-disable-line
  small = 'small', // eslint-disable-line
}

const DynamicTasks: FC<DynamicTasksProps> = ({ avatar, filterValue, handleFetchStatus }) => {
  const urlParams = useParams<{ id: string; modelName: string }>();
  const { modelName } = urlParams;

  switch (modelName) {
    case `ombudsman`:
      return <Ombudsmans avatar={avatar} filterValue={filterValue} handleFetchStatus={handleFetchStatus} />;
    default:
      return <NoMatch />;
  }
};

export default DynamicTasks;
