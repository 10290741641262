import { Action, AnyAction, combineReducers, Dispatch, Reducer } from 'redux';
/**
 * Auth reducer
 */
import Auth from './Auth';
import { AuthState, AuthTypes } from './Auth/Types';
/**
 * Users reducer
 */
import Users from './Users';
import { UsersState, UsersTypes } from './Users/Types';
/**
 * Institutions reducer
 */
import Institutions from './Institutions';
import { InstitutionsState } from './Institutions/Types';
/**
 * Groups reducer
 */
import Groups from './Groups';
import { GroupsState } from './Groups/Types';
/**
 * Inventories reducer
 */
import Inventories from './Inventories';
import { InventoriesState } from './Inventories/Types';
/**
 * InventoryCategories reducer
 */
import InventoryCategories from './InventoryCategories';
import { InventoryCategoryState } from './InventoryCategories/Types';
/**
 * TaskMarkers reducer
 */
import TaskMarkers from './TasksMarkers';
import { TaskMarkersState } from './TasksMarkers/Types';
/**
 * Sectors reducer
 */
import Sectors from './Sectors';
import { SectorsState } from './Sectors/Types';
/**
 * Activities reducer
 */
import Activities from './Activities';
import { ActivitiesState } from './Activities/Types';
/**
 * Suppliers reducer
 */
import Suppliers from './Suppliers';
import { SuppliersState } from './Suppliers/Types';
/**
 * QCMarkers reducer
 */
import QCMarkers from './QCMarkers';
import { QCMarkersState } from './QCMarkers/Types';
/**
 * Tasks reducer
 */
import Tasks from './Tasks';
import { TasksState } from './Tasks/Types';
/**
 * ExternalDocs reducer
 */
import ExternalDocs from './ExternalDocs';
import { ExternalDocsState } from './ExternalDocs/Types';
/**
 * ExternalDocsCategories reducer
 */
import ExternalDocsCategories from './ExternalDocsCategories';
import { ExternalDocsCategoriesState } from './ExternalDocsCategories/Types';
/**
 * QualityContorls reducer
 */
import QualityControls from './QualityControls';
import { QualityControlsState } from './QualityControls/Types';
/**
 * InternalDocs reducer
 */
import InternalDocs from './InternalDocs';
import { InternalDocsState } from './InternalDocs/Types';
/**
 * InternalDocsCategories reducer
 */
import InternalDocsCategories from './InternalDocsCategories';
import { InternalDocsCategoriesState } from './InternalDocsCategories/Types';
/**
 * TasksCategories reducer
 */
import TasksCategories from './TasksCategories';
import { TasksCategoryState } from './TasksCategories/Types';
/**
 * Ombudsmans reducer
 */
import Ombudsmans from './Ombudsmans';
import { OmbudsmansState } from './Ombudsmans/Types';
/**
 * OmbudsmanSources reducer
 */
import OmbudsmanSources from './OmbudsmanSources';
import { OmbudsmanSourcesState } from './OmbudsmanSources/Types';
/**
 * CRMs reducer
 */
import CRMs from './CRMs';
import { CRMsState } from './CRMs/Types';

import { initState } from '../ConfigureStore';

export interface ApplicationState {
  Auth: AuthState;
  CRMs: CRMsState;
  Users: UsersState;
  Institutions: InstitutionsState;
  Groups: GroupsState;
  Inventories: InventoriesState;
  InventoryCategories: InventoryCategoryState;
  TaskMarkers: TaskMarkersState;
  Sectors: SectorsState;
  Activities: ActivitiesState;
  Suppliers: SuppliersState;
  QCMarkers: QCMarkersState;
  Tasks: TasksState;
  ExternalDocs: ExternalDocsState;
  ExternalDocsCategories: ExternalDocsCategoriesState;
  QualityControls: QualityControlsState;
  InternalDocs: InternalDocsState;
  InternalDocsCategories: InternalDocsCategoriesState;
  TasksCategories: TasksCategoryState;
  Ombudsmans: OmbudsmansState;
  OmbudsmanSources: OmbudsmanSourcesState;
}

export interface ConnectedReduxProps<A extends Action = AnyAction> {
  dispatch: Dispatch<A>;
}

const SIMReducer: Reducer<ApplicationState> = combineReducers<ApplicationState>({
  Auth,
  CRMs,
  Users,
  Institutions,
  Groups,
  Inventories,
  InventoryCategories,
  TaskMarkers,
  Sectors,
  Activities,
  Suppliers,
  QCMarkers,
  Tasks,
  ExternalDocs,
  ExternalDocsCategories,
  QualityControls,
  InternalDocs,
  InternalDocsCategories,
  TasksCategories,
  Ombudsmans,
  OmbudsmanSources,
});

export default (state: ApplicationState | undefined, action: any): any => {
  switch (action.type) {
    case AuthTypes.RESET_STATE:
      return SIMReducer(
        {
          ...initState,
          Auth: {
            ...initState.Auth,
            data: {
              ...initState.Auth.data,
              currentOrganization: action.payload.currentOrganization,
              user: { ...initState.Auth.data.user, isAdminOrSuperAdmin: true, isHoldingUser: true, isSuperAdmin: true },
            },
            firebaseToken: state ? state.Auth.firebaseToken : undefined,
            isAuthenticated: true,
          },
        },
        action,
      );
    case AuthTypes.LOGOUT_SUCCESSFULL:
      return SIMReducer({ ...initState }, action);
    case UsersTypes.UPDATE_REQUEST:
      if (state && action.payload.id === state.Auth.data.user.id)
        return SIMReducer(
          {
            ...state,
            Auth: {
              ...state.Auth,
              data: {
                ...state.Auth.data,
                user: {
                  ...state.Auth.data.user,
                  ...action.payload,
                },
              },
            },
          },
          action,
        );
      return SIMReducer(state, action);
    case AuthTypes.UPDATE_AUTH_USER_INFO:
      if (state && action.payload.id === state.Auth.data.user.id)
        return SIMReducer(
          {
            ...state,
            Users: {
              ...state.Users,
              data: state.Users.data.map(user => (user.id === action.payload.id ? action.payload : user)),
            },
          },
          action,
        );
      return SIMReducer(state, action);
    default:
      return SIMReducer(state, action);
  }
};
