import React, { useState } from 'react';
import { useDialog } from 'muibox';
/**
 * Material-UI Core
 */
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = theme =>
  makeStyles({
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: `98%`,
    },
  });

const InactivateForm = ({ addOrUpdateRequest, closeDialog, dialogOpen, formInstance, isSaving, loggedUserInfo }) => {
  const theme = useTheme();
  const classes = useStyles(theme)();
  const dialog = useDialog();
  const [inactivateFormState, setInactivateFormState] = useState({
    inactivationPrefix: ``,
    inactivationReason: ``,
    inactivationReasonError: false,
  });
  const { inactivationPrefix, inactivationReason, inactivationReasonError } = inactivateFormState;
  const { id } = formInstance;
  const handleInactivationSubmit = event => {
    event.preventDefault();
    if (!inactivationReason) {
      setInactivateFormState({ ...inactivateFormState, inactivationReasonError: true });
      return null;
    }
    setInactivateFormState({ ...inactivateFormState, inactivationReasonError: false });
    addOrUpdateRequest({
      dialog,
      data: {
        id,
        inactivationReason: `${inactivationPrefix} - ${inactivationReason}`,
        inactivationUser: loggedUserInfo.data.user.id,
        inactivatedAt: new Date().toLocaleString(`pt-BR`),
        isActive: false,
      },
    });
  };
  return (
    <Dialog open={dialogOpen} onClose={closeDialog} aria-labelledby="inactivateInventoryForm" maxWidth="sm">
      <DialogTitle>Por favor informe o motivo:</DialogTitle>
      <DialogContent>
        <form id="inactivationForm" onSubmit={handleInactivationSubmit}>
          <Grid container>
            <Grid item xs={12}>
              <FormControl className={classes.textField}>
                <InputLabel htmlFor="inactivationPrefix">Tipo de inativação:</InputLabel>
                <Select
                  value={inactivationPrefix}
                  onChange={event =>
                    setInactivateFormState({
                      ...inactivateFormState,
                      inactivationPrefix: event.target.value,
                    })
                  }
                  inputProps={{
                    id: `inactivationPrefix`,
                  }}
                >
                  <MenuItem value="Venda">Venda</MenuItem>
                  <MenuItem value="Descarte">Descarte</MenuItem>
                  <MenuItem value="Transferência">Transferência</MenuItem>
                  <MenuItem value="Outros">Outros</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="id_inactivationReason"
                label="Motivo"
                name="inactivationReason"
                multiline
                rowsMax="4"
                value={inactivationReason}
                onChange={event =>
                  setInactivateFormState({
                    ...inactivateFormState,
                    inactivationReason: event.target.value,
                  })
                }
                margin="normal"
                helperText={inactivationReasonError ? `Campos de preenchimento obrigatório!` : ``}
                error={inactivationReasonError}
                variant="outlined"
                className={classes.textField}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="secondary" style={{ marginRight: 5 }}>
          Cancelar
        </Button>
        <Button form="inactivationForm" type="submit" color="primary" variant="contained" disabled={isSaving}>
          Inativar {isSaving && <CircularProgress size={20} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InactivateForm;

export { InactivateForm };
