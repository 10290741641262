/**
 * Action types
 */
export enum InstitutionsTypes {
  LOAD_REQUEST = '@Institutions/LOAD_REQUEST', // eslint-disable-line
  LOAD_SUCCESS = '@Institutions/LOAD_SUCCESS', // eslint-disable-line
  LOAD_FAILURE = '@Institutions/LOAD_FAILURE', // eslint-disable-line
  ADD_OR_UPDATE_REQUEST = '@Institutions/ADD_OR_UPDATE_REQUEST', // eslint-disable-line
  ADD_OR_UPDATE_FAILURE = '@Institutions/ADD_OR_UPDATE_FAILURE', // eslint-disable-line
  ADD_SUCCESS = '@Institutions/ADD_SUCCESS', // eslint-disable-line
  UPDATE_SUCCESS = '@Institutions/UPDATE_SUCCESS', // eslint-disable-line
  OPEN_REQUEST = '@Institutions/OPEN_REQUEST', // eslint-disable-line
  OPEN_SUCCESS = '@Institutions/OPEN_SUCCESS', // eslint-disable-line
  CLOSE_REQUEST = '@Institutions/CLOSE_REQUEST', // eslint-disable-line
  CLOSE_SUCCESS = '@Institutions/CLOSE_SUCCESS', // eslint-disable-line
  RANGE_REQUEST = '@Institutions/RANGE_REQUEST', // eslint-disable-line
  RANGE_SUCCESS = '@Institutions/RANGE_SUCCESS', // eslint-disable-line
}
/**
 * Institution field keys
 */
export enum InstitutionFieldKeys {
  id = 'id',  // eslint-disable-line
  institutionName = 'institutionName',  // eslint-disable-line
  institutionCity =  'institutionCity',  // eslint-disable-line
  institutionState = 'institutionState',  // eslint-disable-line
  institutionAddress = 'institutionAddress',  // eslint-disable-line
  institutionCnpj = 'institutionCnpj',  // eslint-disable-line
  institutionAvatar = 'institutionAvatar',  // eslint-disable-line
  institutionLogo = 'institutionLogo',  // eslint-disable-line
  isOrganization = 'isOrganization',  // eslint-disable-line
  parent = 'parent',  // eslint-disable-line
}
/**
 * Data types
 */
export interface Institution {
  id: number;
  institutionName: string;
  institutionCity?: string;
  institutionState?: string;
  institutionAddress?: string;
  institutionCnpj?: string;
  institutionAvatar?: string;
  institutionLogo?: string;
  isOrganization?: boolean;
  parent?: number | null;
}

export interface InstitutionError {
  institutionName?: string;
  institutionCity?: string;
  institutionState?: string;
  institutionAddress?: string;
  institutionCnpj?: string;
  institutionAvatar?: string;
  institutionLogo?: string;
  isOrganization?: string;
  parent?: string;
}
/**
 * State types
 */
export interface InstitutionsState {
  readonly data: Institution[];
  readonly formErrors?: InstitutionError;
  readonly isLoading: boolean;
  readonly error: boolean;
  readonly isSaving: boolean;
  readonly dialogOpen: boolean;
  readonly formInstance?: Institution;
  readonly range: number;
}
