import { call, put } from 'redux-saga/effects';
import { Action, Store } from 'redux';
import api from '../../../Services/api';

import {
  addFailure,
  addSuccess,
  loadFailure,
  loadSuccess,
  updateFailure,
  updateSuccess,
} from './Actions';

interface AppAction extends Action {
  payload?: any;
}

interface AppStore extends Store {
  Auth?: any;
}

export function* loadTaskMarkers(action: AppAction) {
  try {
    const response = yield call(api.get, `/tasks/markers/`);
    yield put(loadSuccess(response.data));
  } catch (err) {
    yield put(loadFailure());
  }
}

export function* addTaskMarker(action: AppAction) {
  try {
    const response = yield call(api.post, `/tasks/markers/`, action.payload);
    yield put(addSuccess(response.data));
  } catch (error) {
    yield put(addFailure(error));
  }
}

export function* updateTaskMarker(action: AppAction) {
  try {
    const response = yield call(
      api.patch,
      `/tasks/markers/${action.payload.id}/`,
      action.payload.data,
    );
    yield put(updateSuccess(response.data));
  } catch (error) {
    yield put(updateFailure(error));
  }
}
