import React from 'react';
import PropTypes from 'prop-types';
import { withDialog } from 'muibox';
/**
 * Redux dependencies
 */
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
/**
 * Material UI - Core
 */
import {
  Button,
  Checkbox,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Fade,
  FormControlLabel,
  FormHelperText,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
/**
 * Material UI - Icons
 */
import { Check, Close, CloudUpload, Delete, DescriptionOutlined, RateReview } from '@material-ui/icons';
/**
 * SwipeableViews dependency
 */
import fileDownload from 'js-file-download';
import SwipeableViews from 'react-swipeable-views';

/**
 * My components
 */
import { MyDatePickerStyles } from 'Components/MyDatePicker';
// eslint-disable-next-line
import FileDialog from './FileDialog';
import NewAutoComplete from 'Components/NewAutoComplete';
import UserSelector from 'Components/UserSelector';
import InstitutionsSelector, { filterUserInstitutions } from 'Components/InstitutionsSelector';

import HelperBox, { HelperBoxVariantTypes } from 'Components/NewUIComponents/HelperBox';
import CommentsWrapper from 'Components/Comments/CommentsWrapper';
import CommentsInput from 'Components/Comments/CommentsInput';
import api from 'Services/api';
import { delayExecution } from 'Utils/delay';
import genericAvatar from 'Images/GenericAvatar/genericavatar.png';
import NumberInput from 'Components/NumberInput';
import ActionsCard from './ActionsCard';
import ReadLogs from './ReadLogs';
import Versions from './Versions';

const styles = theme => ({
  cardHeaderAction: {
    flex: `0 0 auto`,
    alignSelf: `flex-start`,
    marginRight: 0,
    marginTop: 0,
  },
  cardHeaderRoot: {
    display: `flex`,
    alignItems: `center`,
    padding: `0 4px 0 16px`,
  },
  closeButton: {
    position: `absolute`,
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  commentWrapper: {
    whiteSpace: `pre-wrap`,
    wordWrap: `break-word`,
  },
  container: {
    display: `flex`,
    flexWrap: `wrap`,
  },
  dialogContent: {
    minHeight: `70vh`,
    maxHeight: `70vh`,
  },
  dialogTitle: {
    display: `flex`,
    flexFlow: `row wrap`,
    alignConent: `center`,
    justifyContent: `flex-end`,
    width: `100%`,
    padding: 0,
  },
  input: {
    display: `none`,
  },
  rightIcon: {
    marginLeft: 5,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: `100%`,
  },
  uploadButton: {
    margin: `${theme.spacing(2)}px 0 ${theme.spacing(1)}px ${theme.spacing(1)}px`,
    minHeight: 56,
    width: `98%`,
  },
});

const MILISECONDS_IN_DAY = 86400000;

class IntDocsForm extends React.PureComponent {
  state = {
    attachs: [],
    canDownload: ``, //eslint-disable-line
    canPrint: ``, //eslint-disable-line
    canRead: [],
    category: ``,
    categoriesList: [],
    code: ``,
    comment: ``,
    commentCounts: [],
    comments: [],
    commentsRange: 2,
    createdAt: ``,
    dialogFile: null,
    dialogFileVersion: null,
    downloadMenuAnchorEl: null,
    emailSend: { send: false, doc: null },
    file: ``,
    fileError: false,
    fileModal: false,
    fileExtensionError: false,
    formErrors: {},
    id: null,
    idApprovers: ``,
    idReviewers: ``,
    institution: ``,
    institutionsList: [], //eslint-disable-line
    intDocFormInstance: {},
    isCommentSaving: false,
    isPublic: false,
    isSaving: false,
    isSectorPublic: false,
    location: ``,
    notes: ``,
    owner: this.props.loggedUserInfo.user.id, //eslint-disable-line
    proccess: ``,
    selfRelation: ``,
    selfRelationList: [],
    tabIndex: 0,
    title: ``,
    updateHeader: false,
    validity: ``,
    validityReminderDays: 0,
    version: ``,
  };

  componentDidMount() {
    this.docInfoForm = React.createRef();
    this.intDocForm = React.createRef();
    this.fileInputRef = React.createRef();
    this.timeout = 2000;
    this.isTesting = process.env.NODE_ENV === `test`;
    this.categoriesFetch();
    this.selfRelationFetch();
    const { formInstance, Institutions } = this.props;
    if (Institutions.length === 1) this.setState({ institution: Institutions[0].id });
    if (formInstance.length) {
      this.mountStateFromFormInstance(formInstance);
    }
  }

  componentDidUpdate(nextProps) {
    const { formInstance } = nextProps;
    const { id } = this.state;
    if (!!formInstance.length && id !== formInstance[0].id) {
      this.mountStateFromFormInstance(formInstance);
    }
    if (!this.isTesting) this.swipeableActions.updateHeight();
  }

  componentWillUnmount() {
    const { emailSend } = this.state;
    const { handleClick } = this.props;
    handleClick();
    if (emailSend.send) {
      this.handleUserNotification(emailSend.doc, `UpdateDoc`);
    }
  }

  mountStateFromFormInstance = formInstance => {
    this.commentsFetch();
    this.attachsFetch();
    const {
      canDownload,
      canPrint,
      canRead,
      category,
      code,
      createdAt,
      id,
      institution,
      isPublic,
      isSectorPublic,
      location,
      notes,
      owner,
      proccess,
      selfRelation,
      title,
      validity,
      validityReminder,
    } = formInstance[0];
    this.setState({
      canDownload, //eslint-disable-line
      canPrint, //eslint-disable-line
      canRead,
      category,
      code,
      createdAt,
      id,
      institution,
      isPublic,
      isSectorPublic,
      location,
      notes,
      owner,
      proccess,
      title,
      validity: validity || ``,
      validityReminderDays:
        validity && validityReminder ? (new Date(validity) - new Date(validityReminder)) / MILISECONDS_IN_DAY : 0,
      selfRelation,
      formErrors: {},
    });
  };

  openOrCloseDownloadMenu = event => {
    const { downloadMenuAnchorEl } = this.state;
    if (event && event.type && `click`.indexOf(event.type) > -1)
      this.setState({
        downloadMenuAnchorEl: downloadMenuAnchorEl ? null : event.currentTarget,
      });
  };

  attachsFetch = () => {
    const { formInstance, enqueueSnackbar } = this.props;
    api
      .get(`/internaldocs/internaldoc/`, {
        params: { docinfo: formInstance[0].id },
      })
      .then(jsonAttachs => {
        const currentVersion = jsonAttachs.data[0];
        const previousVersions = jsonAttachs.data.slice(1) || [];
        this.setState({
          intDocFormInstance: currentVersion,
          attachs: previousVersions,
          idApprovers: currentVersion.idApprovers,
          idReviewers: currentVersion.idReviewers,
        });
      })
      .catch(error => {
        !this.isTesting && console.error(error, `Erro no fetch de documentos!`); // eslint-disable-line
        enqueueSnackbar(`Não foi possível conectar com o servidor e buscar o(s) documento(s).`, {
          variant: `error`,
        });
      });
  };

  selfRelationFetch = () => {
    const { enqueueSnackbar } = this.props;
    api
      .get(`/internaldocs/documentinfo/`)
      .then(json => {
        const { results } = json.data;
        this.setState({
          selfRelationList: results,
        });
      })
      .catch(error => {
        !this.isTesting && console.error(error, `Erro no fetch de documentos!`); // eslint-disable-line
        enqueueSnackbar(`Não foi possível conectar com o servidor e buscar o(s) documento(s).`, {
          variant: `error`,
        });
      });
  };

  categoriesFetch = () => {
    const { enqueueSnackbar } = this.props;
    api
      .get(`/internaldocs/category/`)
      .then(json => {
        this.setState({
          categoriesList: json.data,
        });
      })
      .catch(error => {
        !this.isTesting && console.error(error, `Erro no fetch de categorias!`); // eslint-disable-line
        enqueueSnackbar(`Não foi possível conectar com o servidor e buscar a(s) categoria(s).`, {
          variant: `error`,
        });
      });
  };

  checkRequiredFieldPOG = (name, value, newFormErrors) => {
    const formErrors = { ...newFormErrors };

    if (!value || (typeof value !== `number` && typeof value !== `string`)) {
      formErrors[name] = true;
      return formErrors;
    }

    delete formErrors[name];
    return formErrors;
  };

  isValidFile = file => {
    if (file.type) {
      return (
        file.type === `application/msword` ||
        file.type === `application/vnd.openxmlformats-officedocument.wordprocessingml.document`
      );
    }

    // SIM-242 falback to when the browser does not inform a file type
    const ext = file.name.slice((Math.max(0, file.name.lastIndexOf(`.`)) || Infinity) + 1).toLowerCase();
    return ext === `doc` || ext === `docx`;
  };

  validateForms = event => {
    const { formInstance } = this.props;
    const { category, code, file, fileError, formErrors, idApprovers, idReviewers, institution, proccess } = this.state;
    let newFormErrors = { ...formErrors };

    newFormErrors = { ...this.checkRequiredFieldPOG(`institution`, institution, newFormErrors) };
    newFormErrors = { ...this.checkRequiredFieldPOG(`proccess`, proccess, newFormErrors) };
    newFormErrors = { ...this.checkRequiredFieldPOG(`category`, category, newFormErrors) };
    newFormErrors = { ...this.checkRequiredFieldPOG(`code`, code, newFormErrors) };
    newFormErrors = { ...this.checkRequiredFieldPOG(`idApprovers`, idApprovers.length, newFormErrors) };
    newFormErrors = { ...this.checkRequiredFieldPOG(`idReviewers`, idReviewers.length, newFormErrors) };

    if (Object.keys(newFormErrors).length) {
      this.setState({ formErrors: { ...newFormErrors } });
      return false;
    }
    this.setState({ formErrors: {} });

    const { files } = !this.isTesting
      ? `files` in this.fileInputRef.current && this.fileInputRef.current
      : { files: file };
    if (event.target.type === `search`) {
      return false;
    }
    if (!formInstance.length && !files.length) {
      this.setState({
        fileError: true,
      });
      return false;
    }
    if (fileError) this.setState({ fileError: false });

    if (files[0] && !this.isValidFile(files[0])) {
      this.setState({
        fileExtensionError: true,
      });
      return false;
    }

    return true;
  };

  getFile = event => {
    if (this.isTesting) {
      const { file } = this.state;
      return file;
    }
    if (event.target.files && event.target.files.length) {
      return event.target.files[0];
    }
    if (this.fileInputRef.current) {
      return this.fileInputRef.current.files[0];
    }
    return null;
  };

  createDocInfo = () => event => {
    event.persist();
    event.preventDefault();
    if (!this.validateForms(event)) return;

    const { formInstance, handleClick, enqueueSnackbar } = this.props;
    const {
      code,
      canRead,
      category,
      idApprovers,
      idReviewers,
      institution,
      isPublic,
      isSectorPublic,
      location,
      notes,
      owner,
      proccess,
      selfRelation,
      title,
      updateHeader,
      validity,
      validityReminderDays,
      version,
    } = this.state;

    this.setState({
      isSaving: true,
      formErrors: {},
    });

    const data = !this.isTesting ? new FormData(this.docInfoForm.current) : new FormData();
    data.set(`title`, title);
    data.set(`owner`, owner);
    data.set(`institution`, institution);
    data.set(`proccess`, proccess);
    data.set(`category`, category);
    data.set(`code`, code);
    data.set(`location`, location);
    data.set(`selfRelation`, selfRelation);
    data.set(`canDownload`, []);
    data.set(`canPrint`, []);
    data.set(`canRead`, canRead);
    data.set(`isPublic`, isPublic);
    data.set(`isSectorPublic`, isSectorPublic);
    data.set(`notes`, notes);
    data.delete(`validityReminderDays`);

    if (validity && validityReminderDays) {
      const validityDate = new Date(validity);
      const validityReminder = new Date(validityDate - validityReminderDays * MILISECONDS_IN_DAY);
      data.set(`validityReminder`, validityReminder.toLocaleDateString(`pt-BR`));
    }

    data.set(`updateHeader`, updateHeader);
    data.set(`attachment`, this.getFile(event));
    data.set(`idApprovers`, idApprovers);
    data.set(`idReviewers`, idReviewers);
    if (version) data.set(`version`, version);

    api
      .post(`/internaldocs/new/`, data)
      .then(() => {
        this.setState({
          isSaving: false,
          fileError: false,
          formErrors: {},
          saves: true,
          emailSend: {
            send: !!formInstance[0],
            doc: !!formInstance[0] && formInstance[0].id,
          },
        });
        handleClick();
      })
      .catch(error => {
        this.setState({
          isSaving: false,
          formErrors: error,
        });
        enqueueSnackbar(`Não foi possível salvar este documento!`, {
          variant: `error`,
        });
      });
  };

  updateDocInfo = (id = null) => event => {
    event.persist();
    event.preventDefault();
    if (!this.validateForms(event)) return;

    const { formInstance, getData, enqueueSnackbar } = this.props;
    const {
      code,
      canRead,
      category,
      institution,
      isPublic,
      isSectorPublic,
      location,
      notes,
      owner,
      proccess,
      selfRelation,
      title,
      validity,
      validityReminderDays,
    } = this.state;

    this.setState({
      isSaving: true,
      formErrors: {},
    });

    const data = !this.isTesting ? new FormData(this.docInfoForm.current) : new FormData();
    data.set(`title`, title);
    data.set(`owner`, owner);
    data.set(`institution`, institution);
    data.set(`proccess`, proccess);
    data.set(`category`, category);
    data.set(`code`, code);
    data.set(`location`, location);
    data.set(`selfRelation`, selfRelation);
    data.set(`canDownload`, []);
    data.set(`canPrint`, []);
    data.set(`canRead`, canRead);
    data.set(`isPublic`, isPublic);
    data.set(`isSectorPublic`, isSectorPublic);
    data.set(`notes`, notes);
    data.delete(`validityReminderDays`);

    if (validity && validityReminderDays) {
      const validityDate = new Date(validity);
      const validityReminder = new Date(validityDate - validityReminderDays * MILISECONDS_IN_DAY);
      data.set(`validityReminder`, validityReminder.toLocaleDateString(`pt-BR`));
    }

    api
      .patch(`/internaldocs/documentinfo/${id}/`, data)
      .then(() => {
        getData();
        this.setState({
          isSaving: false,
          fileError: false,
          formErrors: {},
          saves: true,
          emailSend: {
            send: !!formInstance[0],
            doc: !!formInstance[0] && formInstance[0].id,
          },
        });
      })
      .catch(error => {
        this.setState({
          isSaving: false,
          formErrors: error,
        });
        enqueueSnackbar(`Não foi possível salvar este documento!`, {
          variant: `error`,
        });
      });
  };

  updateInternalDoc = (id, docInfoId) => event => {
    event.persist();
    event.preventDefault();
    if (!this.validateForms(event)) return;
    if ((!id && !docInfoId) || event.target.type === `search`) {
      return;
    }
    const { formInstance, getData, handleClick, enqueueSnackbar } = this.props;
    const { idApprovers, idReviewers, intDocFormInstance } = this.state;
    const hasIntDocFormInstance = !!Object.keys(intDocFormInstance).length;
    const file = this.getFile(event);

    if (!hasIntDocFormInstance && !file) {
      this.setState({
        fileError: true,
      });
      return;
    }

    this.setState({
      isSaving: true,
      formErrors: {},
    });
    const data = new FormData();
    data.set(`documentInfo`, docInfoId);
    if (file) data.set(`attachment`, file);
    data.set(`idApprovers`, idApprovers);
    data.set(`idReviewers`, idReviewers);

    let apiPromise;
    if (id) {
      apiPromise = api.patch(`/internaldocs/internaldoc/${id}/`, data);
    } else {
      apiPromise = api.post(`/internaldocs/internaldoc/`, data);
    }
    apiPromise
      .then(json => {
        if (id) {
          getData();
          this.setState({
            saves: true,
            ...json.data,
            emailSend: {
              send: !!formInstance[0],
              doc: !!formInstance[0] && formInstance[0].id,
            },
          });
        } else {
          handleClick();
        }
        this.setState({
          isSaving: false,
          fileError: false,
          formErrors: {},
        });
      })
      .catch(error => {
        this.setState({
          isSaving: false,
          formErrors: error,
        });
        enqueueSnackbar(`Não foi possível salvar este documento!`, {
          variant: `error`,
        });
      });
  };

  handleUserNotification = (documentInfo, type) => {
    const { enqueueSnackbar } = this.props;
    api.get(`/internaldocs/sendmail/?type=${type}&id=${documentInfo}`).catch(() =>
      enqueueSnackbar(`Não foi possível notificar os usuários!`, {
        variant: `error`,
      }),
    );
  };

  handleLogReadAction = id => {
    const { enqueueSnackbar } = this.props;
    const { attachs, intDocFormInstance } = this.state;
    const { version } = intDocFormInstance.id === id ? intDocFormInstance : attachs.find(intDoc => intDoc.id === id);
    api
      .get(`/internaldocs/internaldoc/${id}/view/`)
      .then(json => {
        const { success, url } = json.data;
        this.setState({
          fileModal: true,
          dialogFile: { url, id },
          dialogFileVersion: version,
        });
        enqueueSnackbar(`Documento aberto e ${success}`, {
          variant: `success`,
        });
      })
      .catch(error => {
        !this.isTesting && console.error(error, `Não foi possível encontrar o documento!`); // eslint-disable-line
        enqueueSnackbar(error.response.data.error, {
          variant: `error`,
        });
      });
  };

  handleLogDownloadAction = async (id, type = null) => {
    const { enqueueSnackbar } = this.props;
    const { attachs, code, intDocFormInstance, title } = this.state;
    const { version } = intDocFormInstance.id === id ? intDocFormInstance : attachs.find(intDoc => intDoc.id === id);
    try {
      const urlRes = await api.get(`/internaldocs/internaldoc/${id}/download/${type ? `?type=${type}` : ``}`);
      const { url } = urlRes.data;
      const docRes = await fetch(url);
      const blob = await docRes.blob();
      // PROBLEMA ESTÁ AQUI
      fileDownload(
        blob,
        `${code} - ${title} - Versão ${version}${[`.docx`, `.doc`, `.pdf`].find(ext => url.indexOf(ext) > -1)}`,
      );
    } catch (e) {
      !this.isTesting && console.error(e, `Não foi possível encontrar o documento!`); // eslint-disable-line
      enqueueSnackbar(`Não foi possível conectar com o servidor e buscar o documento.`, { variant: `error` });
    }
  };

  handleFileSubmit = id => event => {
    event.preventDefault();

    if (!this.isValidFile(event.target.files[0])) {
      this.setState({
        fileError: true,
      });
      return;
    }
    this.setState({
      fileError: false,
    });

    const { formInstance } = this.props;
    this.updateInternalDoc(id, formInstance[0].id)(event);
  };

  handleDelete = () => {
    const { dialog, formInstance, getData, handleClick, enqueueSnackbar } = this.props;
    dialog
      .confirm({
        title: `Confirmação de exclusão`,
        message: `Deseja realmente excluir esse registro?`,
        ok: { text: `Confirmar`, variant: `contained`, color: `primary` },
        cancel: { text: `Cancelar`, color: `secondary` },
      })
      .then(() => {
        const data = new FormData();
        data.set(`isExcluded`, true);
        api
          .patch(`/internaldocs/documentinfo/${formInstance[0].id}/`, data)
          .then(() => {
            getData();
            handleClick(null);
          })
          .catch(() => {
            enqueueSnackbar(`Não foi possível excluir o documento!`, {
              variant: `error`,
            });
          });
      })
      .catch(() => enqueueSnackbar(`Ação cancelada!`));
  };

  handleDateChange = (name, form) => date => {
    const { formInstance } = this.props;
    const autoSave = formInstance.length && form === `docInfoForm`;

    this.setState({
      [name]: date,
    });

    if (autoSave) {
      delayExecution(this.timeout, this[form].current, this[form].current.dispatchEvent, [
        new Event(`submit`, { cancelable: true, bubbles: true }),
      ]);
    }
  };

  getCodeSugestion = async (institution, category) => {
    const { code } = this.state;
    if (code) return; // don't replace existing code
    if (!institution) return; // user might have selected a category before choosing an institution
    if (!category) return; // user might have selected a institution before choosing an category

    try {
      const sugestion = await api.get(`/internaldocs/documentcodesugestion/${institution}/${category}/`);
      this.setState(state => {
        if (state.code) return; // user might have typed something while we waited

        this.setState({ code: sugestion.data.code });
      });
    } catch (e) {
      // eslint-disable-next-line
      console.error(`error fetching code sugestion`, e);
    }
  };

  getInstitutionCodeSugestion = () => institutionData => {
    this.handleChange(`institution`, `docInfoForm`)(institutionData);
    const { category } = this.state;
    this.getCodeSugestion(institutionData.value, category);
  };

  getCategoryCodeSugestion = () => categoryData => {
    this.handleChange(`category`, `docInfoForm`)(categoryData);

    const { institution } = this.state;
    this.getCodeSugestion(institution, categoryData.value);
  };

  handleChange = (name, form) => event => {
    const { formInstance } = this.props;
    const { intDocFormInstance } = this.state;
    const autoSave =
      (formInstance.length && form === `docInfoForm`) ||
      (Object.keys(intDocFormInstance).length && form === `intDocForm`);

    if (event && !(typeof event === `number`)) {
      if (`target` in event) {
        if (event.target.type === `checkbox`) {
          this.setState({ [name]: event.target.checked });
        } else {
          this.setState({ [name]: event.target.value });
        }
      } else if (`value` in event) {
        this.setState({ [name]: event.value });
      } else if (!event.length) {
        this.setState({ [name]: [] });
      } else if (event[0].value) {
        this.setState({ [name]: event.map(val => val.value) });
      } else {
        this.setState({ [name]: event });
      }
    } else if (typeof event === `number`) {
      this.setState({ [name]: event });
    } else {
      this.setState({ [name]: [] });
    }

    if (autoSave) {
      delayExecution(this.timeout, this[form].current, this[form].current.dispatchEvent, [
        new Event(`submit`, { cancelable: true, bubbles: true }),
      ]);
    }
  };

  handleReviewOrApprove = (id, url) => () => {
    const { dialog, enqueueSnackbar } = this.props;
    const message = url === `approvers` ? `aprovar` : `revisar`;
    dialog
      .confirm({
        title: `${message.slice(0, 1).toUpperCase()}${message.slice(1)}`,
        message: `Somente prossiga se não existirem mais pendências no documento. Tem certeza que deseja ${message}?`,
        ok: { text: `Confirmar`, variant: `contained`, color: `primary` },
        cancel: { text: `Cancelar`, color: `secondary` },
      })
      .then(() => {
        const data = new FormData();
        this.setState({
          isSaving: true,
        });
        data.set(url === `approvers` ? `approved` : `reviewed`, true);
        api
          .patch(`/internaldocs/${url}/${id}/`, data)
          .then(json => {
            const { intDocFormInstance } = this.state;
            let newList;
            let newIntDocFormInstance;
            if (url === `approvers`) {
              newList = {
                ...intDocFormInstance.approversList.filter(val => val.id === json.data.id)[0],
                approved: json.data.approved,
                approvedAt: json.data.approvedAt,
              };
              newIntDocFormInstance = {
                ...intDocFormInstance,
                approversList: [...intDocFormInstance.approversList.filter(val => val.id !== newList.id), newList],
              };
            } else {
              newList = {
                ...intDocFormInstance.reviewersList.filter(val => val.id === json.data.id)[0],
                reviewed: json.data.reviewed,
                reviewedAt: json.data.reviewedAt,
              };
              newIntDocFormInstance = {
                ...intDocFormInstance,
                reviewersList: [...intDocFormInstance.reviewersList.filter(val => val.id !== newList.id), newList],
              };
            }
            this.setState({
              intDocFormInstance: newIntDocFormInstance,
              isSaving: false,
            });
          })
          .catch(() => {
            this.setState({
              isSaving: false,
            });
            enqueueSnackbar(`Não foi possível aprovar o documento!`, {
              variant: `error`,
            });
          });
      })
      .catch(() => enqueueSnackbar(`Ação cancelada!`));
  };

  checkIsApproverAndPending = hasInstance => {
    if (!hasInstance) return null;
    const { loggedUserInfo } = this.props;
    const { intDocFormInstance } = this.state;
    return intDocFormInstance.approversList.some(
      val => val.userApprover === loggedUserInfo.user.id && val.approved === false,
    );
  };

  checkIsReviewerAndPending = hasInstance => {
    if (!hasInstance) return null;
    const { loggedUserInfo } = this.props;
    const { intDocFormInstance } = this.state;
    return intDocFormInstance.reviewersList.some(
      val => val.userReviewer === loggedUserInfo.user.id && val.reviewed === false,
    );
  };

  mountApproveOrReviewButton = (hasIntDocFormInstance, isApprover, isReviewer) => {
    if (!hasIntDocFormInstance) return null;
    const { loggedUserInfo } = this.props;
    const { intDocFormInstance, isSaving } = this.state;
    const reviewPending = intDocFormInstance.reviewersList.some(val => val.reviewed === false);
    const approvePending = intDocFormInstance.approversList.some(val => val.approved === false);
    const url = isReviewer ? `reviewers` : `approvers`;
    const id =
      (isReviewer || isApprover) &&
      (url === `reviewers`
        ? intDocFormInstance.reviewersList.filter(val => val.userReviewer === loggedUserInfo.user.id)[0].id
        : intDocFormInstance.approversList.filter(val => val.userApprover === loggedUserInfo.user.id)[0].id);
    return (
      ((isApprover && approvePending && !reviewPending) || (isReviewer && reviewPending)) && (
        <Button
          variant="contained"
          color="primary"
          style={{ margin: `auto auto auto 10px`, height: 32, padding: `0 10px 0 5px` }}
          onClick={this.handleReviewOrApprove(id, url)}
          disabled={isSaving}
        >
          {isReviewer && (
            <>
              <RateReview /> Revisar
            </>
          )}
          {isApprover && !reviewPending && (
            <>
              <Check /> Aprovar
            </>
          )}
        </Button>
      )
    );
  };

  checkIsClosed = hasInstance => {
    if (!hasInstance) return null;
    const { intDocFormInstance } = this.state;
    return (
      intDocFormInstance.reviewersList.every(reviewer => reviewer.reviewed) &&
      intDocFormInstance.approversList.every(approver => approver.approved)
    );
  };

  checkCanEdit = (docInfoInstance, IntDocInstance) => {
    if (!docInfoInstance || !IntDocInstance) return true;
    const { formInstance, loggedUserInfo } = this.props;
    const { isSuperAdmin, isAdminOrSuperAdmin } = loggedUserInfo.user;
    const { intDocFormInstance, institution } = this.state;
    const { user } = loggedUserInfo;

    return (
      formInstance[0].owner === user.id ||
      intDocFormInstance.reviewersList.some(reviewer => reviewer.userReviewer === user.id) ||
      intDocFormInstance.approversList.some(approver => approver.userApprover === user.id) ||
      isSuperAdmin ||
      (isAdminOrSuperAdmin && user.userInstitution.some(inst => inst.id === institution || inst.isOrganization))
    );
  };

  mountElaboratorUsername = (createdBy, hasFormInstance, createdAt) => {
    if (!createdBy) return ``;
    if (hasFormInstance && createdAt) {
      return `Criado por ${createdBy} em ${new Date(createdAt).toLocaleString(`pt-BR`)}`;
    }
    return `Criado por ${createdBy}`;
  };

  commentsControll = total => () => {
    const { commentsRange } = this.state;
    if (total > commentsRange) {
      if (commentsRange + 2 > total) {
        this.setState({
          commentsRange: commentsRange + 1,
        });
      } else {
        this.setState({
          commentsRange: commentsRange + 2,
        });
      }
    }
  };

  commentsFetch = () => {
    const { dialog, formInstance } = this.props;
    api
      .get(`/internaldocs/commentsummary/${formInstance[0].id}/`)
      .then(res => {
        this.setState({
          comments: res.data.currentComments,
          commentCounts: res.data.counts,
        });
      })
      .catch(error => {
        // eslint-disable-next-line
        console.error(`Erro no fetch de comentários!`, error.response);
        dialog.alert({
          title: `Erro ao buscar comentários`,
          message: `Não foi possível conectar com o servidor e buscar os comentários.`,
          ok: { text: `Ok`, variant: `contained`, color: `primary` },
        });
      });
  };

  handleCommentSubmit = (id = null) => event => {
    event.preventDefault();
    event.stopPropagation();
    const { formInstance, loggedUserInfo } = this.props;
    const { comment, comments, intDocFormInstance } = this.state;
    this.setState({
      isCommentSaving: true,
    });
    const data = new FormData();
    data.append(`comment`, comment);
    data.append(`date`, new Date().toLocaleString(`pt-br`));
    data.append(`user`, loggedUserInfo.user.id);
    data.append(`document_info`, formInstance[0].id);
    data.append(`internal_doc`, intDocFormInstance.id);

    let apiPromise;
    if (id) {
      apiPromise = api.patch(`/internaldocs/comments/${id}/`, data);
    } else {
      apiPromise = api.post(`/internaldocs/comments/`, data);
    }
    apiPromise
      .then(res => res.data)
      .then(json => {
        if (id) {
          // eslint-disable-next-line
          console.log(`Use this when comment edit is available!`);
        } else {
          this.setState({
            comments: [...comments, json],
            comment: ``,
            handlingStatus: false, //eslint-disable-line
            isCommentSaving: false,
            formErrors: {},
          });
        }
      })
      .catch(error => {
        this.setState({
          isCommentSaving: false,
          formErrors: error.response.data,
        });
      });
  };

  canShowDeleteButton = (formInstance, user, Institutions) => {
    if (!formInstance || !formInstance[0]) return false;
    if (formInstance[0].owner === user.id) return true;
    if (user.isSuperAdmin) {
      if (!Institutions || !Institutions.length) {
        return false;
      }

      if (user.userInstitution.some(inst => inst.isOrganization)) {
        return true;
      }
      if (user.userInstitution.some(inst => inst.id === formInstance[0].institution)) {
        return true;
      }
    }

    return false;
  };

  render() {
    const { classes, formInstance, handleClick, Institutions, loggedUserInfo, Sectors } = this.props;
    const {
      attachs,
      canRead,
      categoriesList,
      category,
      code,
      comment,
      commentCounts,
      comments,
      commentsRange,
      createdAt,
      dialogFile,
      dialogFileVersion,
      downloadMenuAnchorEl,
      file,
      fileError,
      fileExtensionError,
      fileModal,
      formErrors,
      idApprovers,
      idReviewers,
      intDocFormInstance,
      institution,
      isCommentSaving,
      isPublic,
      isSectorPublic,
      isSaving,
      location: docLocation,
      notes,
      owner,
      proccess,
      saves,
      selfRelation,
      selfRelationList,
      tabIndex,
      title,
      updateHeader,
      validity,
      validityReminderDays,
      version,
    } = this.state;
    const hasFormInstance = !!formInstance.length;
    const hasIntDocFormInstance = !!Object.keys(intDocFormInstance).length;
    const isApprover = this.checkIsApproverAndPending(hasIntDocFormInstance);
    const isReviewer = this.checkIsReviewerAndPending(hasIntDocFormInstance);
    const approveOrReviewButton = this.mountApproveOrReviewButton(hasIntDocFormInstance, isApprover, isReviewer);
    const isOverdue = validity ? new Date(`${validity}T23:59`) < new Date() : false;
    const canEdit = this.checkCanEdit(hasFormInstance, hasIntDocFormInstance);
    const isClosed = this.checkIsClosed(hasIntDocFormInstance);
    const showInternaldocsForm = isClosed || canEdit;
    const currentCategory = categoriesList.filter(v => v.id === category);
    const isInactiveCategory = currentCategory.length ? !currentCategory[0].isActive : false;

    return (
      <>
        <DialogTitle className={classes.dialogTitle} disableTypography>
          {approveOrReviewButton}
          {isClosed && (
            <Button
              size="small"
              variant="outlined"
              onClick={() => this.setState({ intDocFormInstance: {} })}
              style={{ margin: `auto auto auto 10px`, height: 32, padding: `0 10px 0 5px` }}
            >
              Nova versão
            </Button>
          )}
          {this.canShowDeleteButton(formInstance, loggedUserInfo.user, Institutions) && (
            <Tooltip title="Inativar">
              <IconButton aria-label="Delete" onClick={this.handleDelete}>
                <Delete />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Fechar">
            <IconButton aria-label="Close" onClick={handleClick}>
              <Close />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <Tabs
          value={tabIndex}
          onChange={(event, val) => this.setState({ tabIndex: val })}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="Documento" />
          <Tab label="Versões" />
          <Tab label="Registros" />
        </Tabs>
        <SwipeableViews
          index={tabIndex}
          action={actions => {
            this.swipeableActions = actions;
          }}
          onChangeIndex={index => this.setState({ tabIndex: index })}
          animateHeight
        >
          <DialogContent className={classes.dialogContent}>
            <form
              id="docInfoForm"
              ref={this.docInfoForm}
              className={classes.container}
              autoComplete="off"
              onSubmit={hasFormInstance ? this.updateDocInfo(formInstance[0].id) : this.createDocInfo()}
            >
              <Grid container>
                <Grid container item xs={12} justify="space-between" alignContent="flex-end">
                  <Typography variant="h6" display="inline">
                    Informações gerais
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
              {isInactiveCategory && (
                <HelperBox
                  text="A categoria à qual este documento está vinculado foi desabilitada."
                  variant={HelperBoxVariantTypes.warning}
                />
              )}
              <Grid container spacing={0} style={{ width: `100%` }}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    className={classes.textField}
                    disabled={!canEdit}
                    helperText={hasFormInstance ? `#${formInstance[0].id}` : null}
                    id="id_title"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment>
                          <DescriptionOutlined />
                        </InputAdornment>
                      ),
                      style: {
                        fontSize: 26,
                        fontWeight: `bold`,
                      },
                    }}
                    margin="normal"
                    name="title"
                    onChange={this.handleChange(`title`, `docInfoForm`)}
                    placeholder="Título ..."
                    required
                    style={{ width: `100%` }}
                    value={title}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={0} style={{ width: `100%` }}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <UserSelector
                    disabled={!canEdit}
                    error={formErrors.owner}
                    helperText={formErrors.owner && `Esse campo é obrigatório`}
                    label="Responsável"
                    loggedUserInfo={loggedUserInfo.user}
                    margin="normal"
                    name="owner"
                    onChange={this.handleChange(`owner`, `docInfoForm`)}
                    required
                    value={owner}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <InstitutionsSelector
                    disabled={!canEdit}
                    error={!!formErrors.institution}
                    helperText={formErrors.institutions ? `Esse campo é obrigatório!` : null}
                    id="id_institution"
                    institutions={filterUserInstitutions(loggedUserInfo.user, Institutions)}
                    margin="normal"
                    name="institution"
                    onChange={this.getInstitutionCodeSugestion()}
                    required
                    selectClass={classes.textField}
                    value={institution}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={0}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <NewAutoComplete
                    disabled={!canEdit}
                    error={`proccess` in formErrors}
                    formInstance={hasFormInstance ? formInstance[0].sector : false}
                    handleChange={this.handleChange(`proccess`, `docInfoForm`)}
                    label="Setor"
                    margin="normal"
                    name="proccess"
                    placeholder="Selecione o setor"
                    required
                    suggestions={Sectors.length ? Sectors.map(val => ({ label: val.name, value: val.id })) : []}
                    single={proccess}
                  />
                </Grid>
                <Grid item xs={10} sm={6} md={6} lg={6} xl={6}>
                  <NewAutoComplete
                    disabled={!canEdit}
                    error={`category` in formErrors}
                    formInstance={hasFormInstance ? formInstance[0].category : false}
                    handleChange={this.getCategoryCodeSugestion()}
                    label="Categoria"
                    margin="normal"
                    name="category"
                    placeholder="Selecione a categoria"
                    required
                    single={category}
                    suggestions={
                      categoriesList.length
                        ? categoriesList.map(val => ({ label: val.name, value: val.id, active: val.isActive }))
                        : []
                    }
                  />
                </Grid>
              </Grid>
              <Grid container spacing={0}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <TextField
                    className={classes.textField}
                    disabled={!canEdit}
                    error={`code` in formErrors}
                    id="id_code"
                    label="Código"
                    margin="normal"
                    name="code"
                    onChange={this.handleChange(`code`, `docInfoForm`)}
                    required
                    style={{ width: `98%` }}
                    type="text"
                    value={code}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <TextField
                    className={classes.textField}
                    disabled={!canEdit}
                    helperText="Somente se armazenado fisicamente"
                    id="id_location"
                    label="Local de armazenamento"
                    margin="normal"
                    name="location"
                    onChange={this.handleChange(`location`, `docInfoForm`)}
                    style={{ width: `98%` }}
                    value={docLocation}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={0} style={{ width: `100%` }}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <NewAutoComplete
                    disabled={!canEdit}
                    error={`selfRelation` in formErrors}
                    formInstance={hasFormInstance ? formInstance[0].selfRelation : false}
                    handleChange={this.handleChange(`selfRelation`, `docInfoForm`)}
                    label="Refenciar outros documentos"
                    multiple={selfRelation}
                    margin="normal"
                    name="selfRelation"
                    placeholder="Selecione as referências"
                    suggestions={
                      selfRelationList.length
                        ? selfRelationList.map(val => ({
                            label: `${val.tableTitle.code} - ${val.tableTitle.title}`,
                            value: val.pk,
                          }))
                        : []
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <MyDatePickerStyles
                    autoOk
                    disabled={!canEdit}
                    error={isOverdue}
                    handleDateChange={this.handleDateChange(`validity`, `docInfoForm`)}
                    helperText={isOverdue && `Este documento está vencido!`}
                    keyboard
                    label="Validade"
                    margin="normal"
                    minDate={new Date().toLocaleDateString(`pt-BR`)}
                    name="validity"
                    placeholder="Indeterminado"
                    value={validity}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={0} style={{ width: `100%` }}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <TextField
                    className={classes.textField}
                    disabled={!canEdit || !validity}
                    id="id_validity_reminder_days"
                    label="Lembrete de expiração"
                    margin="normal"
                    name="validityReminderDays"
                    onChange={this.handleChange(`validityReminderDays`, `docInfoForm`)}
                    style={{ width: `98%` }}
                    value={validityReminderDays}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <Typography style={{ color: `#00000099`, width: `120px`, textAlign: `end` }}>
                          dias antes
                        </Typography>
                      ),
                      inputComponent: NumberInput,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ display: `flex`, alignItems: `center` }}>
                  <Typography
                    style={{ color: `#00000099`, width: `98%`, fontSize: `0.75rem` }}
                    className={classes.textField}
                  >
                    O responsável, aprovadores e revisores receberão um lembrete da expiração do documento
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={0} style={{ marginTop: 16 }}>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    Compartilhamento
                    <Divider />
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={0}>
                <UserSelector
                  disabled={!canEdit}
                  label="Usuários"
                  loggedUserInfo={loggedUserInfo.user}
                  multiple
                  name="canRead"
                  onChange={this.handleChange(`canRead`, `docInfoForm`)}
                  value={canRead}
                />
              </Grid>
              <Grid container spacing={0} style={{ width: `100%` }}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <FormControlLabel
                    className={classes.textField}
                    control={
                      <Checkbox
                        id="doc-info-form-is-public"
                        checked={isPublic}
                        onChange={this.handleChange(`isPublic`, `docInfoForm`)}
                        value="isPublic"
                        color="primary"
                      />
                    }
                    disabled={!canEdit}
                    label="Tornar documento público"
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <FormControlLabel
                    className={classes.textField}
                    control={
                      <Checkbox
                        id="doc-info-form-is-sector-public"
                        checked={isSectorPublic}
                        onChange={this.handleChange(`isSectorPublic`, `docInfoForm`)}
                        value="isSectorPublic"
                        color="primary"
                      />
                    }
                    disabled={!canEdit}
                    label="Compartilhar com usuários do setor"
                    margin="normal"
                  />
                </Grid>
                {!hasFormInstance && (
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Tooltip title="Com esta opção o cabeçalho e rodapé do documento será sempre refeito automaticamente pelo SIM inserindo Título, logotipo da instituição, versão e data.">
                      <FormControlLabel
                        className={classes.textField}
                        control={
                          <Checkbox
                            id="doc-info-form-is-sector-public"
                            checked={updateHeader}
                            onChange={this.handleChange(`updateHeader`, `docInfoForm`)}
                            value="updateHeader"
                            color="primary"
                          />
                        }
                        disabled={!canEdit}
                        label="Usar cabeçalho dinâmico definido pelo SIM"
                        margin="normal"
                      />
                    </Tooltip>
                  </Grid>
                )}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    className={classes.textField}
                    disabled={!canEdit}
                    id="id_notes"
                    label="Observações"
                    margin="normal"
                    multiline
                    name="notes"
                    onChange={this.handleChange(`notes`, `docInfoForm`)}
                    rows={4}
                    value={notes}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </form>
            {showInternaldocsForm && (
              <form
                id="intDocForm"
                ref={this.intDocForm}
                className={classes.container}
                autoComplete="off"
                onSubmit={
                  hasFormInstance
                    ? this.updateInternalDoc(
                        `id` in intDocFormInstance ? intDocFormInstance.id : null,
                        formInstance[0].id,
                      )
                    : null
                }
              >
                <Grid container>
                  <Grid container item xs={12} justify="space-between" alignContent="flex-end">
                    <Typography variant="h6" display="inline">
                      Aprovadores, revisores e documento
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
                <Grid container spacing={0} style={{ width: `100%` }}>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <UserSelector
                      disabled={!canEdit}
                      error={formErrors.idReviewers}
                      helperText={formErrors.idReviewers && `Esse campo é obrigatório!`}
                      label="Revisores"
                      loggedUserInfo={loggedUserInfo.user}
                      margin="normal"
                      multiple
                      name="idReviewers"
                      onChange={this.handleChange(`idReviewers`, `intDocForm`)}
                      openUp
                      required
                      value={idReviewers}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <UserSelector
                      disabled={!canEdit}
                      error={formErrors.idApprovers}
                      helperText={formErrors.idApprovers && `Esse campo é obrigatório!`}
                      label="Aprovadores"
                      loggedUserInfo={loggedUserInfo.user}
                      margin="normal"
                      multiple
                      name="idApprovers"
                      onChange={this.handleChange(`idApprovers`, `intDocForm`)}
                      openUp
                      required
                      value={idApprovers}
                    />
                  </Grid>
                </Grid>
                {fileModal && (
                  <FileDialog
                    file={dialogFile.url}
                    handleFileClose={() => this.setState({ fileModal: false, dialogFile: null })}
                    open={fileModal}
                    title={`${code} - ${title} - Versão ${dialogFileVersion}`}
                    fileId={dialogFile.id}
                  />
                )}
                <Grid container spacing={0} style={{ width: `100%` }}>
                  {!hasFormInstance && (
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <TextField
                        className={classes.textField}
                        id="id_version"
                        label="Versão do documento"
                        margin="normal"
                        name="version"
                        onChange={this.handleChange(`version`, `intDocForm`)}
                        style={{ width: `98%` }}
                        type="number"
                        value={version}
                        variant="outlined"
                      />
                    </Grid>
                  )}
                  <Grid container item xs={12} sm={6} md={6} lg={6} xl={6}>
                    {!hasIntDocFormInstance && (
                      <label htmlFor="id_attachment">
                        <Button className={classes.uploadButton} color="default" component="span" variant="contained">
                          Upload
                          <CloudUpload className={classes.rightIcon} />
                        </Button>
                        {fileError && (
                          <FormHelperText className={classes.textField} error={fileError}>
                            Obrigatório o envio de um anexo.
                          </FormHelperText>
                        )}
                        <FormHelperText className={classes.textField} error={fileExtensionError}>
                          Apenas documentos do Word (doc, docx) podem ser importados.
                        </FormHelperText>
                        <Typography className={classes.textField} display="inline">
                          {file.name}
                        </Typography>
                        <input
                          className={classes.input}
                          id="id_attachment"
                          ref={this.fileInputRef}
                          name="attachment"
                          type="file"
                          accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                          onChange={event => this.setState({ file: event.target.files[0] })}
                        />
                      </label>
                    )}
                  </Grid>
                  <Divider style={{ background: `#F0F0F0 0% 0% no-repeat padding-box` }} />
                </Grid>
                <Grid container spacing={0} style={{ width: `100%` }}>
                  {hasFormInstance && hasIntDocFormInstance && (
                    <ActionsCard
                      canEdit={canEdit}
                      classes={classes}
                      downloadMenuAnchorEl={downloadMenuAnchorEl}
                      elaboratorUserName={this.mountElaboratorUsername(
                        intDocFormInstance.createdByName,
                        hasFormInstance,
                        createdAt,
                      )}
                      fileInputRef={this.fileInputRef}
                      handleLogDownloadAction={this.handleLogDownloadAction}
                      handleFileSubmit={this.handleFileSubmit}
                      handleLogReadAction={this.handleLogReadAction}
                      hasIntDocFormInstance={hasIntDocFormInstance}
                      intDocFormInstance={intDocFormInstance}
                      isClosed={isClosed}
                      lastVersion
                      openOrCloseDownloadMenu={this.openOrCloseDownloadMenu}
                    />
                  )}
                </Grid>
              </form>
            )}
            <>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 15, textAlign: `center` }}>
                <CommentsWrapper
                  comments={comments}
                  commentsControllCallback={this.commentsControll}
                  commentsRange={commentsRange}
                  genericAvatar={genericAvatar}
                />
              </Grid>
            </>
          </DialogContent>
          <DialogContent className={classes.dialogContent}>
            <Versions
              canEdit={canEdit}
              classes={classes}
              commentCounts={commentCounts}
              formInstance={hasFormInstance ? formInstance[0] : undefined}
              handleLogDownloadAction={this.handleLogDownloadAction}
              handleLogReadAction={this.handleLogReadAction}
              loggedUserInfo={loggedUserInfo}
              versions={attachs}
            />
          </DialogContent>
          {hasIntDocFormInstance || attachs.length ? (
            <DialogContent className={classes.dialogContent}>
              <ReadLogs attachs={[...attachs, intDocFormInstance]} intDocFormInstance={intDocFormInstance} />
            </DialogContent>
          ) : (
            <Typography align="center">Ainda não possuímos registros para esse documento.</Typography>
          )}
        </SwipeableViews>
        {tabIndex === 0 && (
          <DialogActions>
            {hasFormInstance && hasIntDocFormInstance && isSaving && (
              <Hidden only={[`xs`, `sm`]}>
                <Fade
                  in
                  style={{ transitionDelay: `800ms`, position: `absolute`, bottom: 15, right: 15 }}
                  unmountOnExit
                >
                  <CircularProgress />
                </Fade>
              </Hidden>
            )}
            {hasFormInstance && hasIntDocFormInstance && !isSaving && saves && (
              <Hidden only={[`xs`, `sm`]}>
                <span style={{ position: `absolute`, bottom: 15, right: 15 }}>Salvo!</span>
              </Hidden>
            )}
            {hasFormInstance && hasIntDocFormInstance && isSaving && (
              <Hidden only={[`md`, `lg`, `xl`]}>
                <Fade
                  in
                  style={{ transitionDelay: `800ms`, position: `absolute`, bottom: 15, right: 15 }}
                  unmountOnExit
                >
                  <CircularProgress />
                </Fade>
              </Hidden>
            )}
            {hasFormInstance && hasIntDocFormInstance && !isSaving && saves && (
              <Hidden only={[`md`, `lg`, `xl`]}>
                <span style={{ position: `absolute`, bottom: 15, right: 15 }}>Salvo!</span>
              </Hidden>
            )}
            {(!hasFormInstance || !hasIntDocFormInstance) && (
              <>
                <Button size="small" onClick={handleClick} color="secondary" style={{ marginRight: 10 }}>
                  Cancelar
                </Button>
                <Button
                  style={{ minWidth: isSaving ? 100 : 64 }}
                  size="small"
                  type="submit"
                  color="primary"
                  variant="contained"
                  form={hasFormInstance ? `intDocForm` : `docInfoForm`}
                  disabled={isSaving}
                >
                  Salvar {isSaving && <CircularProgress size={20} />}
                </Button>
              </>
            )}
            {hasIntDocFormInstance && canEdit && (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 15, textAlign: `center` }}>
                <CommentsInput
                  avatarStyles={classes.avatar}
                  avatarThumb={loggedUserInfo.user.avatarThumb}
                  disabled={false}
                  endAdornment={
                    <InputAdornment position="end">
                      <Button
                        color="primary"
                        disabled={isCommentSaving}
                        id="commentButton"
                        onClick={this.handleCommentSubmit()}
                        variant="contained"
                      >
                        Comentar {isCommentSaving && <CircularProgress size={30} />}
                      </Button>
                    </InputAdornment>
                  }
                  id="id_comment"
                  inputChangeCallback={this.handleChange(`comment`)}
                  name="comment"
                  placeholder="Digite aqui seu comentário"
                  value={comment}
                />
              </Grid>
            )}
          </DialogActions>
        )}
      </>
    );
  }
}

IntDocsForm.propTypes = {
  loggedUserInfo: PropTypes.shape({
    user: PropTypes.object.isRequired,
    generalInfo: PropTypes.object.isRequired,
  }).isRequired,
  Institutions: PropTypes.arrayOf(PropTypes.object).isRequired,
  Sectors: PropTypes.arrayOf(PropTypes.object).isRequired,
  classes: PropTypes.shape({
    container: PropTypes.string,
    textField: PropTypes.string,
    dialogTitle: PropTypes.string,
    input: PropTypes.string,
    rightIcon: PropTypes.string,
    closeButton: PropTypes.string,
  }).isRequired,
  handleClick: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  getData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  Sectors: state.Sectors.data,
  Institutions: state.Institutions.data,
  loggedUserInfo: state.Auth.data,
});

export default withDialog()(connect(mapStateToProps)(withSnackbar(withStyles(styles)(IntDocsForm))));

export { IntDocsForm };
