/**
 * Action types
 */
export enum TaskMarkersTypes {
  LOAD_REQUEST = '@TaskMarkers/LOAD_REQUEST', // eslint-disable-line
  LOAD_SUCCESS = '@TaskMarkers/LOAD_SUCCESS', // eslint-disable-line
  LOAD_FAILURE = '@TaskMarkers/LOAD_FAILURE', // eslint-disable-line
  ADD_REQUEST = '@TaskMarkers/ADD_REQUEST', // eslint-disable-line
  ADD_SUCCESS = '@TaskMarkers/ADD_SUCCESS', // eslint-disable-line
  ADD_FAILURE = '@TaskMarkers/ADD_FAILURE', // eslint-disable-line
  UPDATE_REQUEST = '@TaskMarkers/UPDATE_REQUEST', // eslint-disable-line
  UPDATE_SUCCESS = '@TaskMarkers/UPDATE_SUCCESS', // eslint-disable-line
  UPDATE_FAILURE = '@TaskMarkers/UPDATE_FAILURE', // eslint-disable-line
}
/**
 * Data types
 */
export interface TaskMarker {
  id: number;
  name: string;
  color: string;
  fontColor: string;
}

export interface TaskMarkerError {
  name?: string;
  color?: string;
  fontColor?: string;
}
/**
 * State types
 */
export interface TaskMarkersState {
  readonly data: TaskMarker[];
  readonly errors?: TaskMarkerError;
  readonly loading: boolean;
  readonly error: boolean;
  readonly isSaving: boolean;
}
