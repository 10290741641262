/**
 * Action types
 */
export enum InternalDocsTypes {
  LOAD_REQUEST = '@InternalDocs/LOAD_REQUEST', // eslint-disable-line
  LOAD_SUCCESS = '@InternalDocs/LOAD_SUCCESS', // eslint-disable-line
  LOAD_FAILURE = '@InternalDocs/LOAD_FAILURE', // eslint-disable-line
  OPEN_REQUEST = '@InternalDocs/OPEN_REQUEST', // eslint-disable-line
  OPEN_SUCCESS = '@InternalDocs/OPEN_SUCCESS', // eslint-disable-line
  CLOSE_REQUEST = '@InternalDocs/CLOSE_REQUEST', // eslint-disable-line
  CLOSE_SUCCESS = '@InternalDocs/CLOSE_SUCCESS', // eslint-disable-line
  CHANGE_SORT = '@InternalDocs/CHANGE_SORT', // eslint-disable-line
  CHANGE_FILTERS = '@InternalDocs/CHANGE_FILTERS', // eslint-disable-line
  CHANGE_PAGES = '@InternalDocs/CHANGE_PAGES', // eslint-disable-line
}
/**
 * Data types
 */
export interface InternalDoc {
  id: number;
  documentStatus: string;
  proccess: number;
  closedAt: string;
  tableCategory: string;
  tableInstitutions: { id: number; avatar: string; name: string }[];
  tableOwner: string;
  tableTitle: { id: number; title: string; reference: string; version: string };
  validity: string;
}
/**
 * State types
 */
export interface InternalDocsState {
  readonly approvePending: number;
  readonly count: number;
  readonly data: InternalDoc[];
  readonly expiredOrExpiring: number;
  readonly filterList: { [key: string]: string[] };
  readonly formInstance: any[];
  readonly isLoading: boolean;
  readonly modalOpen: boolean;
  readonly page: number;
  readonly rowsPerPage: number;
  readonly reviewPending: number;
  readonly searchText?: string;
  readonly sort?: { [key: number]: { order: string } };
}
