/**
 * Action types
 */
export enum QualityControlsTypes {
  LOAD_REQUEST = '@QualityControls/LOAD_REQUEST', // eslint-disable-line
  LOAD_SUCCESS = '@QualityControls/LOAD_SUCCESS', // eslint-disable-line
  LOAD_FAILURE = '@QualityControls/LOAD_FAILURE', // eslint-disable-line
  OPEN_REQUEST = '@QualityControls/OPEN_REQUEST', // eslint-disable-line
  OPEN_SUCCESS = '@QualityControls/OPEN_SUCCESS', // eslint-disable-line
  CLOSE_REQUEST = '@QualityControls/CLOSE_REQUEST', // eslint-disable-line
  CLOSE_SUCCESS = '@QualityControls/CLOSE_SUCCESS', // eslint-disable-line
  CHANGE_SORT = '@QualityControls/CHANGE_SORT', // eslint-disable-line
  CHANGE_FILTERS = '@QualityControls/CHANGE_FILTERS', // eslint-disable-line
  CHANGE_PAGES = '@QualityControls/CHANGE_PAGES', // eslint-disable-line
}
/**
 * Data types
 */
export interface QualityControl {
  id: number;
  isActive: boolean;
  according: boolean;
  executed: string;
  institution: number;
  inventory: number;
  marker: number;
  name: string;
  notes: string;
  responsible: number;
  shelflife: number;
  qcphysics?: number;
  qcquench?: {
    id: number;
    heliumLevel: string;
    heliumPressure: string;
    waterTemperature: string;
  };
  qctempctmr?: {
    id: number;
    temperatureCommand: string;
    temperatureEquipment: string;
    temperatureMachine: string;
    umidadeCommand: string;
    umidadeEquipment: string;
    umidadeMachine: string;
  };
  qctemperature?: { id: number; temperature: string; umidade: string };
  qcserver?: {
    id: number;
    airSystem: string;
    breakdowns: string;
    breakdownsNotes: string;
    linksSystem: string;
    nobreakSystem: string;
  };
  tableInstitution: { pk: number; name: string; avatar: string };
  tableInventory: { pk: number; name: string };
  tableMarker: { pk: number; name: string; fontColor: string; color: string; testName: string };
  tableQctype: string;
  tableShelflife: string;
}

/**
 * State types
 */
export interface QualityControlsState {
  readonly modalOpen: boolean;
  readonly formInstance: any[];
  readonly isLoading: boolean;
  readonly dbname?: string;
  readonly page: number;
  readonly count: number;
  readonly data: QualityControl[];
  readonly searchText?: string;
  readonly rowsPerPage: number;
  readonly filterList: string[][];
  readonly sort?: { [key: number]: { order: string } };
}
