import React, { FC, useEffect, useRef } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { useDialog } from 'muibox';
import { OmbudsmanInstance } from '../../../../ReduxFlow/Reducers/Ombudsmans/Types';
import OmbudsmanCreateForm from './OmbudsmanCreateForm';
import OmbudsmanUpdateForm from './OmbudsmanUpdateForm';
import { loadRequest as loadCategoriesRequest } from '../../../../ReduxFlow/Reducers/TasksCategories/Actions';
import { loadRequest as loadOmbudsmanSourcesRequest } from '../../../../ReduxFlow/Reducers/OmbudsmanSources/Actions';

interface OmbudsmanDialogFormProps {
  formInstance?: OmbudsmanInstance;
}

export interface OmbudsmanFields {
  crm?: number | number[];
  description: string;
  estimatedEndDate: string | null;
  feedbackMood?: string;
  feedbackText?: string;
  institution?: number;
  ombudsman?: {
    source?: number;
  };
  priority?: string;
  requester?: number | number[];
  requesterSector: number | string;
  responsible?: number | number[];
  responsibleSector: number | string;
  sector: string | number[];
  source: number | string;
  startDate: string | null;
  subcategory?: number;
  title: string;
  users?: number | number[];
}

const OmbudsmanDialogForm: FC<OmbudsmanDialogFormProps> = ({ formInstance }) => {
  const dialog = useRef(useDialog());
  const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down(`sm`));

  useEffect(() => {
    dispatch(loadCategoriesRequest({ dialog: dialog.current, taskType: `ombudsmans` }));
    dispatch(loadOmbudsmanSourcesRequest(dialog.current));
  }, [dispatch]);

  if (formInstance) {
    return <OmbudsmanUpdateForm fullScreen={fullScreen} />;
  }
  return <OmbudsmanCreateForm fullScreen={fullScreen} />;
};

export default OmbudsmanDialogForm;
