import React, { FC, MouseEvent } from 'react';
import { IconButton, Typography } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    badge: {
      color: `#ffffff`,
      fontSize: 12,
      fontWeight: 600,
      padding: `0 ${theme.spacing(0.75)}px`,
      textDecoration: `inherit`,
    },
    badgeWrapper: {
      alignContent: `center`,
      alignItems: `center`,
      borderRadius: theme.spacing(0.75),
      display: `flex`,
      justifyContent: `center`,
      marginRight: theme.spacing(1.25),
      minHeight: theme.spacing(2.25),
      minWidth: theme.spacing(4),
      textDecoration: `inherit`,
    },
    closeButton: {
      marginLeft: `auto`,
      padding: 0,
      textDecoration: `inherit`,
    },
    filename: {
      maxWidth: `100%`,
      overflow: `hidden`,
      textOverflow: `ellipsis`,
      whiteSpace: `nowrap`,
      wordBreak: `keep-all`,
    },
    wrapper: {
      display: `contents`,
      maxWidth: `100%`,
    },
  }),
);

interface AttachProps {
  deleteCallback?: (id?: number | string) => (event?: MouseEvent<HTMLButtonElement>) => void;
  externaldoc?: number;
  id?: number | string;
  url: string;
}

const Attach: FC<AttachProps> = ({ deleteCallback, externaldoc, id, url }) => {
  const classes = useStyles();

  function getAttachColor(): { color: string; name: string } {
    switch (true) {
      case !!url.match(/\.pdf/i):
        return { color: `#ee0000`, name: `PDF` };
      case !!url.match(/\.(doc|docx)/i):
        return { color: `#004fb8`, name: `DOC` };
      case !!url.match(/\.(xls|xlsx)/i):
        return { color: `#0e713a`, name: `XLS` };
      case !!url.match(/\.(jpg|jpeg|tiff|gif|bmp|png)/i):
        return { color: `#2c1d49`, name: `IMG` };
      default:
        return { color: `#767676`, name: `ANEXO` };
    }
  }

  const attachColorAndName = getAttachColor();

  return (
    <span className={classes.wrapper}>
      <div className={classes.badgeWrapper} style={{ backgroundColor: attachColorAndName.color }}>
        <Typography className={classes.badge} component="span" variant="body2">
          {attachColorAndName.name}
        </Typography>
      </div>
      <Typography className={classes.filename} color="textSecondary" component="span" display="inline" variant="body2">
        {externaldoc
          ? decodeURIComponent(
              url
                .split(`?Expires`)[0]
                .split(`${externaldoc}/`)[1]
                .replace(/_/g, ` `),
            )
          : url}
      </Typography>
      {!!deleteCallback && (
        <IconButton
          className={classes.closeButton}
          onClick={
            deleteCallback
              ? (event: MouseEvent<HTMLButtonElement>): void => {
                  event.preventDefault();
                  event.persist();
                  event.stopPropagation();
                  deleteCallback(id)(event);
                }
              : undefined
          }
        >
          <CloseIcon />
        </IconButton>
      )}
    </span>
  );
};

export default Attach;
