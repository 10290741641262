import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './Reducers/CreateSagas';
import rootReducer from './Reducers';
import { INITIAL_STATE as AuthInitState } from './Reducers/Auth';
import { INITIAL_STATE as ActivitiesInitState } from './Reducers/Activities';
import { INITIAL_STATE as CRMsInitState } from './Reducers/CRMs';
import { INITIAL_STATE as ExternalDocsInitState } from './Reducers/ExternalDocs';
import { INITIAL_STATE as ExternalDocsCategoriesInitState } from './Reducers/ExternalDocsCategories';
import { INITIAL_STATE as GroupsInitState } from './Reducers/Groups';
import { INITIAL_STATE as InstitutionsInitState } from './Reducers/Institutions';
import { INITIAL_STATE as InternalDocsInitState } from './Reducers/InternalDocs';
import { INITIAL_STATE as InternalDocsCategoriesInitState } from './Reducers/InternalDocsCategories';
import { INITIAL_STATE as InventoriesInitState } from './Reducers/Inventories';
import { INITIAL_STATE as InventoryCategoriesInitState } from './Reducers/InventoryCategories';
import { INITIAL_STATE as QCMarkersInitState } from './Reducers/QCMarkers';
import { INITIAL_STATE as QualityControlsInitState } from './Reducers/QualityControls';
import { INITIAL_STATE as SectorsInitState } from './Reducers/Sectors';
import { INITIAL_STATE as SuppliersInitState } from './Reducers/Suppliers';
import { INITIAL_STATE as TasksInitState } from './Reducers/Tasks';
import { INITIAL_STATE as TasksMarkersInitState } from './Reducers/TasksMarkers';
import { INITIAL_STATE as UsersInitState } from './Reducers/Users';
import { INITIAL_STATE as TasksCategoriesInitState } from './Reducers/TasksCategories';
import { INITIAL_STATE as OmbudsmansInitState } from './Reducers/Ombudsmans';
import { INITIAL_STATE as OmbudsmanSourcesInitState } from './Reducers/OmbudsmanSources';

export const initState = {
  Auth: AuthInitState,
  Activities: ActivitiesInitState,
  CRMs: CRMsInitState,
  ExternalDocs: ExternalDocsInitState,
  ExternalDocsCategories: ExternalDocsCategoriesInitState,
  Groups: GroupsInitState,
  Institutions: InstitutionsInitState,
  InternalDocs: InternalDocsInitState,
  InternalDocsCategories: InternalDocsCategoriesInitState,
  Inventories: InventoriesInitState,
  InventoryCategories: InventoryCategoriesInitState,
  Ombudsmans: OmbudsmansInitState,
  OmbudsmanSources: OmbudsmanSourcesInitState,
  QCMarkers: QCMarkersInitState,
  QualityControls: QualityControlsInitState,
  Sectors: SectorsInitState,
  Suppliers: SuppliersInitState,
  Tasks: TasksInitState,
  TasksCategories: TasksCategoriesInitState,
  TaskMarkers: TasksMarkersInitState,
  Users: UsersInitState,
};

const sagaMiddleware = createSagaMiddleware();

const logger = (): any =>
  (window as any).__REDUX_DEVTOOLS_EXTENSION__ // eslint-disable-line
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION__() // eslint-disable-line
    : (x: any): any => x;

function configureStore(): any {
  const enhancer =
    !process.env.NODE_ENV || process.env.NODE_ENV === `development`
      ? compose(
          applyMiddleware(sagaMiddleware),
          logger(),
        )
      : compose(applyMiddleware(sagaMiddleware));
  const store = createStore(rootReducer, initState, enhancer);
  sagaMiddleware.run(rootSaga);
  if (module.hot) {
    module.hot.accept(`./Reducers`, () => {
      const nextReducer = require('./Reducers').default; // eslint-disable-line
      store.replaceReducer(nextReducer);
    });
  }
  return { store };
}

export const { store } = configureStore();
