import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
/**
 * Material UI - Core
 */
import { Button, CircularProgress, Dialog, DialogActions, DialogTitle } from '@material-ui/core';

import { useTheme } from '@material-ui/core/styles';
import { FirstStep, SecondStep, ThirdStep } from '../../../Components/UserForm';

import { CustomAxiosError, NoEmailOrFakeMailError } from '../../../Utils/CustomErrors/index';
import api from '../../../Services/api';

import * as UserActions from '../../../ReduxFlow/Reducers/Users/Actions';

import { fieldsValidation, initState, useStyles } from '../../../Components/UserForm/UserFormConstants';

const CreateUserForm = ({ closeCreateOrUpdateDialog, fullscreen, loggedUserInfo, open }) => {
  // Get classes from UserFormConstants
  const theme = useTheme();
  const classes = useStyles(theme)();
  // Declaring the Component state
  const [emailEdit, setEmailEdit] = useState(false);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [postUrl, setPostUrl] = useState(``);
  const [step, setStep] = useState(0);
  const emailTimeout = useRef();
  const [userData, setUserData] = useState({
    ...initState,
  });

  const { email: emailFirstStep } = userData;
  const { email: emailError } = errors;
  const hasTitle = [0, 1].indexOf(step) > -1;

  const checkIfUserExistsApiCall = useCallback(async () => {
    if (step === 0) return api.get(`/account/check-email/?email=${encodeURIComponent(userData.email)}`);
    try {
      const response = await api.get(`/account/check-email/?email=${encodeURIComponent(userData.email)}`);
      if (!!response.data.email && response.data.isFakeEmail) {
        setErrors(prevErrors => ({
          ...prevErrors,
          emailExists: `Esse e-mail já existe em nosso cadastro, por favor altere diretamente no campo e-mail.`,
        }));
        setEmailEdit(true);
      }
    } catch (error) {
      setErrors(prevErrors => ({ ...prevErrors, emailExists: undefined }));
    }
  }, [step, userData]);

  const removeDiacritics = str =>
    str
      .toLowerCase()
      .normalize(`NFD`)
      .replace(/[\u0300-\u036f]/g, ``);

  useEffect(() => {
    clearTimeout(emailTimeout.current);
    if (step === 1)
      emailTimeout.current = setTimeout(() => {
        checkIfUserExistsApiCall();
      }, 1500);
  }, [checkIfUserExistsApiCall, emailTimeout, step, userData.email]);

  useEffect(() => {
    if (!errors.emailExists && step > 0 && userData.isFakeEmail && !emailEdit) {
      const email = `${removeDiacritics(userData.firstName.split(` `)[0])}.${removeDiacritics(
        userData.lastName.split(` `).pop(),
      )}@s.im`;
      setUserData(prevUserData => ({ ...prevUserData, email }));
    }
  }, [emailEdit, errors.emailExists, step, userData.firstName, userData.isFakeEmail, userData.lastName]);

  const checkIfUserExists = async () => {
    try {
      setIsSubmitting(true);
      const { email: loggedUserEmail } = loggedUserInfo;
      if (emailFirstStep === loggedUserEmail)
        throw new NoEmailOrFakeMailError(
          `user_create_error_fake_email`,
          `Esse e-mail de usuário é seu, por favor escolha um diferente.`,
        );
      if (!emailFirstStep)
        throw new NoEmailOrFakeMailError(`no_email`, `Usuário sem e-mail, prosseguir para criação de usuário.`);
      const response = await checkIfUserExistsApiCall();
      const {
        avatarThumb,
        avatar,
        birthDate,
        cpf,
        email,
        firstName,
        firebaseUid,
        inCurrentOrganization,
        isFakeEmail,
        isMultiOrganization,
        lastName,
        sex,
      } = response.data;
      if (isFakeEmail)
        throw new NoEmailOrFakeMailError(
          `user_create_error_fake_email`,
          `Esse e-mail de usuário está indisponível. Por favor escolha um diferente.`,
        );
      if (inCurrentOrganization)
        throw new NoEmailOrFakeMailError(
          `user_create_error_fake_email`,
          `Esse usuário já está cadastrado em sua organização. Por favor escolha outro e-mail.`,
        );
      setUserData(prevUserData => ({
        ...prevUserData,
        avatarThumb,
        avatarUrl: avatar,
        birthDate,
        cpf,
        email,
        firebaseUid,
        firstName,
        isFakeEmail: !!isFakeEmail,
        isMultiOrganization,
        lastName,
        sex: sex || ``,
      }));
      setPostUrl(`/account/users/add-to-organization/`);
      setStep(prevStep => prevStep + 1);
    } catch (error) {
      if (error.response) {
        const { code, message } = error.response.data;
        switch (code) {
          case `user_not_found`:
            setUserData(prevUserData => ({ ...prevUserData, isFakeEmail: false }));
            setPostUrl(`/account/users/`);
            setStep(prevStep => prevStep + 1);
            break;
          case `no_email`:
            setUserData(prevUserData => ({ ...prevUserData, isFakeEmail: true }));
            setPostUrl(`/account/users/`);
            setStep(prevStep => prevStep + 1);
            break;
          case `user_create_error_fake_email`:
            setErrors(prevErrors => ({ ...prevErrors, email: message }));
            break;
          default:
            setErrors(prevErrors => ({
              ...prevErrors,
              email: `Ocorreu um erro inesperado, por favor contate nosso suporte.`,
            }));
            break;
        }
      } else {
        setErrors(prevErrors => ({
          ...prevErrors,
          email: `Ocorreu um erro inesperado, por favor contate nosso suporte.`,
        }));
      }
    }
    setIsSubmitting(false);
  };

  const isEditingMultiOrgUser = postUrl === `/account/users/add-to-organization/`;

  const handleSubmit = async () => {
    try {
      const { admissionDate, birthDate, cpf, groups, password } = userData;
      setIsSubmitting(true);
      if (!isEditingMultiOrgUser && password === ``)
        throw new CustomAxiosError(`password`, `Senha inválida, favor clicar no botão gerar senha.`);
      const postUserData = {
        ...userData,
        admissionDate: new Date(admissionDate).toLocaleDateString(`pt-br`),
        birthDate: new Date(birthDate).toLocaleDateString(`pt-br`),
        cpf: cpf.replace(/\D+/g, ``),
        groups: [groups],
      };
      if (!isEditingMultiOrgUser) delete postUserData.firebaseUid;
      delete postUserData.isMultiOrganization;
      if (isEditingMultiOrgUser) delete postUserData.password;
      await api.post(postUrl, postUserData);
      setUserData(postUserData);
      setIsSubmitting(false);
      setStep(prevState => prevState + 1);
    } catch (error) {
      setIsSubmitting(false);
      setErrors(error.response.data);
    }
  };

  const generateRandomPassword = event => {
    event.preventDefault();
    const password = `${Math.random().toString(36)}00000000000000000`.slice(2, 8);
    setUserData(prevUserData => ({ ...prevUserData, password }));
  };

  const buttonTexts = [
    {
      cancel: `Cancelar`,
      cancelFunc: () => {
        setErrors({});
        setUserData(initState);
        closeCreateOrUpdateDialog();
      },
      ok: `Avançar`,
      okFunc: checkIfUserExists,
    },
    {
      cancel: `Retornar`,
      cancelFunc: () => {
        setErrors({});
        setUserData(prevUserData => ({ ...initState, email: prevUserData.email }));
        setStep(prevStep => prevStep - 1);
      },
      ok: `Avançar`,
      okFunc: () => {
        const validation = Object.keys(userData).map(key => {
          if ([`avatarThumb`, `avatarUrl`, `firebaseUid`, `isFakeEmail`, `isMultiOrganization`].indexOf(key) === -1) {
            switch (true) {
              case key !== `password` && !userData.isFakeEmail:
                return fieldsValidation(key, null, setErrors, userData);
              case userData.isFakeEmail:
                return fieldsValidation(key, null, setErrors, userData);
              default:
                return false;
            }
          }
          return false;
        });
        if (validation.every(field => !field)) {
          handleSubmit();
        }
      },
    },
    {
      ok: `Fechar`,
      okFunc: () => {
        setStep(0);
        setUserData(initState);
        closeCreateOrUpdateDialog();
      },
    },
  ];

  const handleFieldChange = useCallback(
    name => event => {
      switch (true) {
        case !!event && !!event.persist:
          event.persist();
          setUserData(prevUserData => ({ ...prevUserData, [name]: event.target.value }));
          break;
        default:
          setUserData(prevUserData => ({ ...prevUserData, [name]: event }));
          break;
      }
      fieldsValidation(name, event, setErrors, userData);
    },
    [userData],
  );

  const { cancel, cancelFunc, ok, okFunc } = buttonTexts[step];

  const stepComponent = [
    <FirstStep
      classes={classes}
      email={emailFirstStep}
      emailError={emailError}
      key="firstStep"
      onChange={handleFieldChange(`email`)}
    />,
    <SecondStep
      errors={errors}
      generateRandomPassword={generateRandomPassword}
      handleFieldChange={handleFieldChange}
      isEditingMultiOrgUser={isEditingMultiOrgUser}
      key="secondStep"
      userData={userData}
      loggedUserInfo={loggedUserInfo}
    />,
    <ThirdStep
      classes={classes}
      closeButtonFunc={okFunc}
      closeButtonText={ok}
      isEditingMultiOrgUser={isEditingMultiOrgUser}
      key="thirdStep"
      userData={userData}
    />,
  ];

  return (
    <Dialog
      aria-labelledby="addUserForm"
      fullScreen={fullscreen}
      fullWidth
      maxWidth="sm"
      onClose={() => {
        setUserData(initState);
        setStep(0);
        closeCreateOrUpdateDialog();
      }}
      open={open}
    >
      {hasTitle && (
        <DialogTitle className={step !== 0 ? classes.dialogTitle : null}>Adicionar um novo usuário</DialogTitle>
      )}
      {stepComponent[step]}
      {step !== 2 && (
        <DialogActions classes={{ root: classes.actionsRoot, spacing: classes.actionsSpacing }}>
          <Button className={`${classes.button} ${classes.cancelButton}`} disabled={isSubmitting} onClick={cancelFunc}>
            {cancel}
          </Button>
          <Button
            classes={{ disabled: classes.disabledButton }}
            className={classes.button}
            color="primary"
            disabled={isSubmitting}
            onClick={okFunc}
            variant="contained"
          >
            {isSubmitting ? <CircularProgress color="inherit" size={24} /> : ok}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

CreateUserForm.defaultProps = {
  fullscreen: false,
};

CreateUserForm.propTypes = {
  closeCreateOrUpdateDialog: PropTypes.func.isRequired,
  fullscreen: PropTypes.bool,
  loggedUserInfo: PropTypes.shape({
    id: PropTypes.number,
    isAdminOrSuperAdmin: PropTypes.bool,
    isHoldingUser: PropTypes.bool,
    isSuperAdmin: PropTypes.bool,
    lastLogin: PropTypes.string,
    isSuperuser: PropTypes.bool,
    password: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    isFakeEmail: PropTypes.bool,
    enableEmailNotifications: PropTypes.bool,
    avatar: PropTypes.string,
    avatarThumb: PropTypes.string,
    isStaff: PropTypes.bool,
    isActive: PropTypes.bool,
    dateJoined: PropTypes.string,
    firebaseUid: PropTypes.string,
    admissionDate: PropTypes.string,
    birthDate: PropTypes.string,
    sex: PropTypes.string,
    cpf: PropTypes.string,
    userPermissions: PropTypes.array,
    userInstitution: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  open: PropTypes.bool.isRequired,
};

/* istanbul ignore next */
const mapStateToProps = state => ({
  loggedUserInfo: state.Auth.data.user,
});

/* istanbul ignore next */
const mapDispatchToProps = dispatch => bindActionCreators(UserActions, dispatch);

export const TestCreateUserForm = CreateUserForm;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateUserForm);
