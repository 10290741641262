import React, { FC, MouseEvent, ReactNode } from 'react';
import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import { Attachment as AttachmentIcon } from '@material-ui/icons';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { DropzoneRootProps, FileWithPath } from 'react-dropzone';
import AttachWrapper from './AttachWrapper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    attachmentList: {
      justifyContent: `space-between`,
    },
    clickHereText: {
      color: theme.palette.primary.dark,
      cursor: `pointer`,
      fontWeight: 500,
    },
    disabled: {
      opacity: 0.4,
      pointerEvents: `none`,
    },
    inputLabel: {
      background: `#fbf9ff 0% 0% no-repeat padding-box`,
      border: `1px dashed #d5d2db`,
      borderRadius: theme.spacing(0.75),
      color: `#968ea4`,
      marginBottom: theme.spacing(0.75),
      minHeight: theme.spacing(5.375),
      width: `100%`,
      '&: > label > input': {
        display: `none`,
      },
    },
    typography: {
      fontSize: theme.spacing(1.75),
      padding: `${theme.spacing(1.875)}px 0px`,
    },
  }),
);
export interface Attach {
  attachment: string;
  id: number;
  task: number;
  user: number;
}

interface TaskAttachsContainerProps {
  attachs: Attach[] | FileWithPath[];
  attachsInput?: ReactNode;
  deleteCallback?: (id: number | string) => (event?: MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  disableTitle?: boolean;
  hasError?: { [key: number]: boolean };
  loggedUserId: number;
  progress?: { [key: number]: number };
  reactDropzoneOnClick?: DropzoneRootProps['onClick']; // eslint-disable-line
  retryCallback?: (attach: FileWithPath[]) => Promise<void>;
}

const TaskAttachsContainer: FC<TaskAttachsContainerProps> = ({
  attachs,
  attachsInput,
  deleteCallback,
  disabled,
  disableTitle,
  hasError,
  loggedUserId,
  progress,
  reactDropzoneOnClick,
  retryCallback,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down(`sm`));

  function isAttach<T>(attachsCheck: unknown[]): attachsCheck is T[] {
    return attachsCheck.every((attach): boolean => Object.prototype.hasOwnProperty.call(attach, `attachment`));
  }

  return (
    <Grid className={disabled ? classes.disabled : undefined} container>
      <Grid item xs={12}>
        {!disableTitle && (
          <Typography align="left" variant="body2">
            <strong>Anexos</strong>
          </Typography>
        )}
        {!!attachs.length && isAttach<Attach>(attachs) && (
          <Grid alignContent="center" alignItems="center" container className={classes.attachmentList}>
            {attachs.map((attach: Attach) => (
              <AttachWrapper
                attach={attach}
                loggedUserId={loggedUserId}
                deleteCallback={deleteCallback}
                key={attach.id}
              />
            ))}
          </Grid>
        )}
        {!!attachs.length && !isAttach<Attach>(attachs) && (
          <Grid alignContent="center" alignItems="center" container className={classes.attachmentList}>
            {attachs.map((attach: FileWithPath) => (
              <AttachWrapper
                attach={attach}
                deleteCallback={deleteCallback}
                hasError={hasError}
                key={attach.lastModified}
                loggedUserId={loggedUserId}
                progress={progress}
                retryCallback={retryCallback ? async (): Promise<void> => retryCallback([attach]) : undefined}
              />
            ))}
          </Grid>
        )}
      </Grid>
      {attachsInput && reactDropzoneOnClick && (
        <Grid item xs={12}>
          <Grid
            alignContent="center"
            alignItems="center"
            className={classes.inputLabel}
            container
            justify="center"
            onClick={reactDropzoneOnClick}
            style={{ marginTop: attachs.length ? 11 : 0 }}
          >
            {mobile ? (
              <>
                <Typography display="inline">
                  <AttachmentIcon />
                </Typography>
                <Typography className={`${classes.clickHereText} ${classes.typography}`} display="inline">
                  Anexar arquivo
                </Typography>
                {attachsInput}
              </>
            ) : (
              <>
                <Typography className={classes.typography} color="textSecondary" display="inline">
                  Arraste e solte seus arquivos ou&nbsp;
                </Typography>
                <Typography className={`${classes.clickHereText} ${classes.typography}`} display="inline">
                  clique aqui
                </Typography>
                {attachsInput}
              </>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default TaskAttachsContainer;
