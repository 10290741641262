import React, { ChangeEvent, FC, MouseEvent } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions';
import CommentsInput from 'Components/Comments/CommentsInput';

const useStyles = makeStyles(() =>
  createStyles({
    avatar: {
      height: `30px`,
      width: `30px`,
    },
  }),
);

interface TasksCloseReopenDialogProps {
  classes?: {
    avatar: string;
  };
  closeOrReopenTask: (event: MouseEvent<HTMLButtonElement>) => Promise<void>;
  comment: string;
  handleChangeComment: (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | {
          target: {
            value: string;
          };
        },
  ) => void;
  isSaving: boolean;
  loggedUserAvatar: string;
  onClose: (event: MouseEvent<HTMLButtonElement>, reason?: `backdropClick` | `escapeKeyDown`) => void;
  open: boolean;
}

const SlideTransition = React.forwardRef(function SlideTransition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />; // eslint-disable-line
});

const TasksCloseReopenDialog: FC<TasksCloseReopenDialogProps> = ({
  classes,
  closeOrReopenTask,
  comment,
  handleChangeComment,
  isSaving,
  loggedUserAvatar,
  onClose,
  open,
}) => {
  const componentClasses = useStyles();

  return (
    <Dialog open={open} TransitionComponent={SlideTransition} onClose={onClose} aria-labelledby="end-reopen-task-title">
      <DialogTitle id="end-reopen-task-title">
        <Typography>Para finalizar/reabrir a tarefa escreva um comentário.</Typography>
      </DialogTitle>
      <DialogContent>
        <CommentsInput
          autoFocus
          avatarStyles={(classes && classes.avatar) || componentClasses.avatar}
          avatarThumb={loggedUserAvatar}
          inputChangeCallback={handleChangeComment}
          inputError={!comment}
          inputHelperText="O comentário é obrigatório"
          placeholder="Digite aqui seu comentário"
          value={comment}
        />
      </DialogContent>
      <DialogActions>
        <Button size="small" onClick={onClose} color="secondary" style={{ marginRight: 5 }}>
          Cancelar
        </Button>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={closeOrReopenTask}
          disabled={isSaving || !comment}
        >
          Salvar {isSaving && <CircularProgress size={20} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TasksCloseReopenDialog;
