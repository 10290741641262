import { SimpleCategory, SimpleCategoryError, SimpleCategoryState } from '../types';
/**
 * Action types
 */
export enum ExternalDocsCategoriesTypes {
  LOAD_REQUEST = '@ExternalDocsCategories/LOAD_REQUEST', // eslint-disable-line
  LOAD_SUCCESS = '@ExternalDocsCategories/LOAD_SUCCESS', // eslint-disable-line
  LOAD_FAILURE = '@ExternalDocsCategories/LOAD_FAILURE', // eslint-disable-line
  OPEN_REQUEST = '@ExternalDocsCategories/OPEN_REQUEST',   // eslint-disable-line
  OPEN_SUCCESS = '@ExternalDocsCategories/OPEN_SUCCESS',   // eslint-disable-line
  CLOSE_REQUEST = '@ExternalDocsCategories/CLOSE_REQUEST',   // eslint-disable-line
  CLOSE_SUCCESS = '@ExternalDocsCategories/CLOSE_SUCCESS',   // eslint-disable-line
  LOAD_ERRORS = '@ExternalDocsCategories/LOAD_ERRORS',   // eslint-disable-line
  ADD_OR_UPDATE_REQUEST = '@ExternalDocsCategories/ADD_OR_UPDATE_REQUEST', // eslint-disable-line
  ADD_OR_UPDATE_FAILURE = '@ExternalDocsCategories/ADD_OR_UPDATE_FAILURE', // eslint-disable-line
  ADD_SUCCESS = '@ExternalDocsCategories/ADD_SUCCESS', // eslint-disable-line
  UPDATE_SUCCESS = '@ExternalDocsCategories/UPDATE_SUCCESS', // eslint-disable-line
}
/**
 * Data types
 */

export type ExternalDocsCategory = SimpleCategory;

export type ExternalDocsCategoriesError = SimpleCategoryError;

/**
 * State types
 */
export type ExternalDocsCategoriesState = SimpleCategoryState;
