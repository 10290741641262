import React, { FC } from 'react';
import { Avatar } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AutoComment from '../../AutoComment';
import { AutoCommentCommonTypes } from '../..';
import { Comment } from '..';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      height: theme.spacing(3),
      width: theme.spacing(3),
    },
    container: {
      marginTop: theme.spacing(2.5),
    },
    headerFullName: {
      color: theme.palette.primary.dark,
    },
  }),
);

type CreateAutoCommentProps = AutoCommentCommonTypes<Comment>;

const CreateAutoComment: FC<CreateAutoCommentProps> = ({
  comment,
  fromNow,
  genericAvatar,
  isLastItem,
  nextIsAutoComment,
  previousIsAutoComment,
}) => {
  const classes = useStyles();
  const { userDetail } = comment;

  return (
    <AutoComment
      avatar={<Avatar className={classes.avatar} src={userDetail.avatarThumb || genericAvatar} />}
      componentClasses={{
        root: classes.container,
      }}
      fromNow={fromNow}
      headerActionText="criou o usuário"
      isFirstItem
      isLastItem={isLastItem}
      nextIsAutoComment={nextIsAutoComment}
      previousIsAutoComment={previousIsAutoComment}
      useLeftPadding={false}
      userName={userDetail.fullName}
    />
  );
};

export default CreateAutoComment;
