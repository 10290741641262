import React, { ChangeEvent, FormEvent, SFC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Help, InfoOutlined as InfoOutlinedIcon } from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Dialog as DialogType } from 'muibox';
import {
  TasksCategory,
  TasksSubcategory,
  TasksSubcategoryDialog,
  TasksSubcategoryErrors,
} from 'ReduxFlow/Reducers/TasksCategories/Types';
import { ApplicationState } from 'ReduxFlow/Reducers';
import HelperBox, { HelperBoxVariantTypes } from 'Components/NewUIComponents/HelperBox';

import SLAField from 'Components/SLAField';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionsRoot: {
      alignItems: `center`,
      display: `flex`,
      flex: `0 0 auto`,
      justifyContent: `flex-end`,
      padding: 24,
    },
    actionsSpacing: {
      '& > :not(:first-child)': {
        marginLeft: 16,
      },
    },
    disabledButton: {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: `${theme.palette.primary.contrastText} !important`,
    },
    marginNormal: {
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(2),
    },
    textField: {
      marginBottom: theme.spacing(3.25),
      width: `100%`,
    },
  }),
);

type TasksSubcategoryDialogProps = {
  addOrUpdateRequest: Function;
  closeDialogForm: Function;
  dialog: DialogType;
  dialogOpen: boolean;
  errors?: TasksSubcategoryErrors;
  formInstance: TasksSubcategory | undefined;
  isSaving: boolean;
  parentCategory?: TasksCategory;
  subtitle: string;
  title: string;
};

const TasksSubcategoryDialogForm: SFC<TasksSubcategoryDialogProps> = ({
  addOrUpdateRequest,
  closeDialogForm,
  dialog,
  dialogOpen,
  errors = {},
  formInstance,
  isSaving,
  parentCategory,
  subtitle,
  title,
}) => {
  const classes = useStyles();
  const [categoryData, setCategoryData] = useState<TasksSubcategoryDialog>({
    category: undefined,
    description: ``,
    firstResponseTime: undefined,
    hasFeedbackSurvey: false,
    hasRtAndFrt: false,
    name: ``,
    resolutionTime: undefined,
    sectorWorkSchedulleEnabled: false,
  });
  const [validationErrors, setValidationErrors] = useState<{
    firstResponseTime?: string;
    name?: string;
    resolutionTime?: string;
  }>({
    firstResponseTime: undefined,
    name: undefined,
    resolutionTime: undefined,
  });
  const isMultipleSectors = parentCategory && parentCategory.sectors.length > 1;
  const hasNoSectors = parentCategory && parentCategory.sectors.length === 0;
  const sector = useSelector((state: ApplicationState) =>
    parentCategory && parentCategory.sectors.length === 1
      ? state.Sectors.data.find(s => s.id === parentCategory.sectors[0])
      : undefined,
  );
  const sectorHasNoWorkingSchedulle = sector && !sector.workSchedulleEnabled;

  useEffect(() => {
    if (formInstance) {
      const {
        description,
        firstResponseTime,
        hasFeedbackSurvey,
        hasRtAndFrt,
        name,
        resolutionTime,
        sectorWorkSchedulleEnabled,
      } = formInstance;
      setCategoryData({
        description: description || ``,
        firstResponseTime,
        hasFeedbackSurvey,
        hasRtAndFrt,
        name,
        resolutionTime,
        sectorWorkSchedulleEnabled,
      });
    }
  }, [formInstance]);

  function validateForm(): boolean {
    const { firstResponseTime, hasRtAndFrt, name, resolutionTime } = categoryData;
    const newValidationErrors = validationErrors;
    if (name === ``) {
      newValidationErrors.name = `Esse campo é obrigatório.`;
    }
    if (hasRtAndFrt) {
      if (!firstResponseTime) {
        newValidationErrors.firstResponseTime = `Esse campo é obrigatório.`;
      }
      if (!resolutionTime) {
        newValidationErrors.resolutionTime = `Esse campo é obrigatório.`;
      }
    }
    setValidationErrors({ ...newValidationErrors });
    return Object.values(newValidationErrors).some(val => !!val);
  }

  function handleSubmitForm(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    if (validateForm()) return;
    const postData: TasksSubcategoryDialog = {
      ...categoryData,
      id: undefined,
      isActive: true,
    };
    if (formInstance) {
      postData.id = formInstance.id;
    }
    addOrUpdateRequest({ dialog, postData });
  }

  const handleCheckboxChange = (name: string) => (event: ChangeEvent<HTMLInputElement>): void => {
    event.persist();
    setCategoryData(prevData => ({
      ...prevData,
      [name]: event.target.checked,
    }));
  };

  const handleFieldChange = (name: string) => (event: ChangeEvent<HTMLInputElement>): void => {
    event.persist();
    setCategoryData(prevData => ({
      ...prevData,
      [name]: event.target.value,
    }));
    if (name === `name`)
      setValidationErrors(prevErrors => ({
        ...prevErrors,
        [name]: undefined,
      }));
  };

  const handleSLAFieldChange = (name: string) => (timestamp: number): void => {
    setCategoryData(prevData => ({
      ...prevData,
      [name]: timestamp,
    }));
    setValidationErrors(prevErrors => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };

  return (
    <Dialog
      aria-labelledby="tasks-sub-categories-dialog"
      fullWidth
      maxWidth="xs"
      onClose={(): void => closeDialogForm()}
      open={dialogOpen}
    >
      <DialogTitle disableTypography>
        <Typography variant="h6">{title}</Typography>
        <Typography variant="body1" style={{ color: `#00000099` }}>
          {subtitle}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <form id="tasksSubcategoriesForm" noValidate onSubmit={handleSubmitForm}>
          <TextField
            autoFocus
            className={classes.textField}
            error={!!validationErrors.name}
            id="id_name"
            helperText={validationErrors.name}
            label="Nome da categoria"
            name="name"
            onChange={handleFieldChange(`name`)}
            required
            value={categoryData.name}
            variant="outlined"
          />
          <TextField
            className={classes.textField}
            helperText="Dica: Descreva as características dos itens desta categoria"
            id="id_description"
            label="Descrição da categoria"
            name="description"
            onChange={handleFieldChange(`description`)}
            value={categoryData.description}
            variant="outlined"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={categoryData.hasFeedbackSurvey || false}
                onChange={handleCheckboxChange(`hasFeedbackSurvey`)}
                name="hasFeedbackSurvey"
                color="primary"
              />
            }
            label="As atividades pertencentes a esta categoria exigirão o preenchimento de pesquisa de satisfação ao serem finalizadas"
            className={classes.marginNormal}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={categoryData.hasRtAndFrt || false}
                onChange={handleCheckboxChange(`hasRtAndFrt`)}
                name="hasRtAndFrt"
                color="primary"
              />
            }
            label={
              <>
                Esta categoria tem indicadores de<strong> TPR </strong>(tempo de primeira resposta) e
                <strong> TR </strong>
                (tempo de resolução)
              </>
            }
            className={classes.marginNormal}
          />
          {categoryData.hasRtAndFrt && (
            <SLAField
              error={!!validationErrors.firstResponseTime}
              helperText={validationErrors.firstResponseTime || `Tempo esperado de primeira resposta`}
              label="TPR"
              onChange={handleSLAFieldChange(`firstResponseTime`)}
              required
              value={categoryData.firstResponseTime || 0}
            />
          )}
          {categoryData.hasRtAndFrt && (
            <SLAField
              error={!!validationErrors.resolutionTime}
              helperText={validationErrors.resolutionTime || `Tempo esperado de resolução`}
              label="TR"
              onChange={handleSLAFieldChange(`resolutionTime`)}
              required
              value={categoryData.resolutionTime || 0}
            />
          )}
          {categoryData.hasRtAndFrt && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={categoryData.sectorWorkSchedulleEnabled || false}
                  disabled={isMultipleSectors || hasNoSectors || sectorHasNoWorkingSchedulle}
                  onChange={handleCheckboxChange(`sectorWorkSchedulleEnabled`)}
                  name="sectorWorkSchedulleEnabled"
                  color="primary"
                />
              }
              label={
                <>
                  Contar as horas somente durante o horario de trabalho do setor
                  <Tooltip title="Com esta opção marcada os SLAs consideram somente o período compreendido pelo horário de trabalho do setor na contagem das horas. Você pode configurar o horário do setor em Cadastros - Setores.">
                    <Help />
                  </Tooltip>
                </>
              }
              className={classes.marginNormal}
            />
          )}
          {categoryData.hasRtAndFrt && isMultipleSectors && (
            <HelperBox
              icon={<InfoOutlinedIcon color="inherit" />}
              text="Não é possível contar as horas somente durante o horário de trabalho do setor pois esta categoria possui mais de 1 setor configurado"
              variant={HelperBoxVariantTypes.info}
            />
          )}
          {categoryData.hasRtAndFrt && hasNoSectors && (
            <HelperBox
              icon={<InfoOutlinedIcon />}
              text="Não é possível contar as horas somente durante o horário de trabalho do setor pois esta categoria não possui um setor configurado"
              variant={HelperBoxVariantTypes.info}
            />
          )}
          {categoryData.hasRtAndFrt && sectorHasNoWorkingSchedulle && (
            <HelperBox
              icon={<InfoOutlinedIcon />}
              text="Para utilizar esta opção configure o horário do setor em Cadastros - Setores."
              variant={HelperBoxVariantTypes.info}
            />
          )}
        </form>
      </DialogContent>
      <DialogActions classes={{ root: classes.actionsRoot, spacing: classes.actionsSpacing }}>
        <Button color="default" onClick={(): void => closeDialogForm()}>
          Cancelar
        </Button>
        <Button
          classes={{ disabled: classes.disabledButton }}
          color="primary"
          disabled={isSaving}
          form="tasksSubcategoriesForm"
          type="submit"
          variant="contained"
        >
          {!isSaving && (formInstance ? `Salvar edições` : title)}
          {isSaving && <CircularProgress color="inherit" size={24} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TasksSubcategoryDialogForm;
