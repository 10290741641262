import { action, ActionType } from 'typesafe-actions';
import { Dialog } from 'muibox';
import { FormInstance, CRMsTypes, CRM, CRMError } from './Types';

export type SagaFormInstance = Omit<FormInstance, 'id'> & { id?: number; files?: { attachment: Blob; attachType: string }[] }; // eslint-disable-line

export const loadRequest = (dialog: Dialog): ActionType<typeof action> => action(CRMsTypes.LOAD_REQUEST, { dialog });
export const loadCRMsSuccess = (data: CRM[]): ActionType<typeof action> => action(CRMsTypes.LOAD_SUCCESS, { data });
export const loadFailure = (): ActionType<typeof action> => action(CRMsTypes.LOAD_FAILURE);
export const openCRMRequest = (data: { dialog: Dialog; id?: number | string }): ActionType<typeof action> =>
  action(CRMsTypes.OPEN_REQUEST, data);
export const openCRMSuccess = (data?: FormInstance): ActionType<typeof action> => action(CRMsTypes.OPEN_SUCCESS, data);
export const closeCRMRequest = (): ActionType<typeof action> => action(CRMsTypes.CLOSE_REQUEST);
export const closeCRMSuccess = (): ActionType<typeof action> => action(CRMsTypes.CLOSE_SUCCESS);
export const addOrUpdateRequest = (data: {
  afterSavingCallback?: (data: FormInstance) => void;
  dialog: Dialog;
  data: SagaFormInstance;
}): ActionType<typeof action> => action(CRMsTypes.ADD_OR_UPDATE_REQUEST, data);
export const addOrUpdateFailure = (errors: CRMError): ActionType<typeof action> =>
  action(CRMsTypes.ADD_OR_UPDATE_FAILURE, { errors });
export const addSuccess = (data: FormInstance): ActionType<typeof action> => action(CRMsTypes.ADD_SUCCESS, { data });
export const updateSuccess = (data: FormInstance): ActionType<typeof action> =>
  action(CRMsTypes.UPDATE_SUCCESS, { data });
export const changeCRMSort = (data: {
  activeColumn: string;
  dialog: Dialog;
  sort: string;
}): ActionType<typeof action> => action(CRMsTypes.CHANGE_SORT, data);
export const changeCRMFilters = (data: {
  dialog: Dialog;
  filterList: { [key: string]: string[] };
  rowsPerPage?: number;
  searchText?: string;
}): ActionType<typeof action> => action(CRMsTypes.CHANGE_FILTERS, data);
export const changeCRMPages = (data: {
  dialog: Dialog;
  page: number;
  rowsPerPage: number;
}): ActionType<typeof action> => action(CRMsTypes.CHANGE_PAGES, data);
export const CRMMobileInfiniteScrollRequest = (data: { dialog: Dialog }): ActionType<typeof action> =>
  action(CRMsTypes.MOBILE_INFINITE_SCROLL_REQUEST, { ...data, mobile: true });
export const CRMMobileInfiniteScrollSuccess = (data: CRM[]): ActionType<typeof action> =>
  action(CRMsTypes.MOBILE_INFINITE_SCROLL_SUCCESS, data);
