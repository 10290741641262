import React, { useState } from 'react';
/**
 * Material UI - Core
 */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Hidden,
  Paper,
  Typography,
  withStyles,
} from '@material-ui/core';
/**
 * Material UI - Icons
 */
import { ExpandMore } from '@material-ui/icons';
import HomeSkeleton from '../../Components/HomeSkeleton';
import { dashboardItemsFunction } from './HomeConstants';

const styles = theme => ({
  logoWrapper: {
    [theme.breakpoints.down(`xl`)]: {
      margin: `auto auto auto auto`,
      width: `50vw`,
      height: `32.13vw`,
    },
    [theme.breakpoints.down(`sm`)]: {
      margin: `30px auto auto auto`,
      width: `70vw`,
      height: `44.984vw`,
    },
  },
  logo: {
    width: `100%`,
    margin: `auto`,
  },
});

const Home = ({
  classes,
  institutions,
  inventories,
  isAdminOrSuperAdmin,
  isLoadingUI,
  partialCalls,
  qcs,
  stoppedCalls,
  todos,
  users,
}) => {
  const [expanded, setExpanded] = useState(true);
  const dashboardItems = dashboardItemsFunction(institutions, inventories, qcs, todos, users);

  if (isLoadingUI) return <HomeSkeleton />;

  if (isAdminOrSuperAdmin)
    return (
      <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography className={classes.heading}>Métricas</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            {dashboardItems.map(dashItem => {
              const { containerSizes, desktop, key, mobile } = dashItem;
              const { counter, icon, sizes, text, textAndCounterSizes } = desktop;
              if (mobile) {
                const {
                  counter: mobCounter,
                  icon: mobIcon,
                  sizes: mobSizes,
                  text: mobText,
                  textAndCounterSizes: mobTextAndCounterSizes,
                } = mobile;
                return (
                  <Grid
                    alignContent="flex-start"
                    alignItems="flex-start"
                    container
                    item
                    key={key}
                    xs={containerSizes[0]}
                    sm={containerSizes[1]}
                    md={containerSizes[2]}
                    lg={containerSizes[3]}
                    xl={containerSizes[4]}
                  >
                    <Hidden only={[`xs`, `sm`, `md`]}>
                      <Grid
                        alignContent="flex-start"
                        alignItems="flex-start"
                        container
                        item
                        justify="flex-start"
                        xs={sizes[0]}
                        sm={sizes[1]}
                        md={sizes[2]}
                        lg={sizes[3]}
                        xl={sizes[4]}
                      >
                        <Typography variant="h2">{icon}</Typography>
                      </Grid>
                      <Grid
                        container
                        direction="column"
                        item
                        xs={textAndCounterSizes[0]}
                        sm={textAndCounterSizes[1]}
                        md={textAndCounterSizes[2]}
                        lg={textAndCounterSizes[3]}
                        xl={textAndCounterSizes[4]}
                      >
                        <Typography align="left" variant="h4">
                          {counter}
                        </Typography>
                        <Typography align="left">{text}</Typography>
                      </Grid>
                    </Hidden>
                    <Hidden only={[`lg`, `xl`]}>
                      <Grid
                        alignContent="flex-start"
                        alignItems="flex-start"
                        container
                        item
                        justify="flex-start"
                        xs={mobSizes[0]}
                        sm={mobSizes[1]}
                        md={mobSizes[2]}
                        lg={mobSizes[3]}
                        xl={mobSizes[4]}
                      >
                        <Typography variant="h2">{mobIcon}</Typography>
                      </Grid>
                      <Grid
                        container
                        direction="column"
                        item
                        xs={mobTextAndCounterSizes[0]}
                        sm={mobTextAndCounterSizes[1]}
                        md={mobTextAndCounterSizes[2]}
                        lg={mobTextAndCounterSizes[3]}
                        xl={mobTextAndCounterSizes[4]}
                      >
                        <Typography align="left" variant="h4">
                          {mobCounter}
                        </Typography>
                        <Typography>{mobText}</Typography>
                      </Grid>
                    </Hidden>
                  </Grid>
                );
              }
              return (
                <Grid
                  alignContent="flex-start"
                  alignItems="flex-start"
                  container
                  item
                  key={key}
                  xs={containerSizes[0]}
                  sm={containerSizes[1]}
                  md={containerSizes[2]}
                  lg={containerSizes[3]}
                  xl={containerSizes[4]}
                >
                  <Grid
                    alignContent="flex-start"
                    alignItems="flex-start"
                    container
                    item
                    justify="flex-start"
                    xs={sizes[0]}
                    sm={sizes[1]}
                    md={sizes[2]}
                    lg={sizes[3]}
                    xl={sizes[4]}
                  >
                    <Typography variant="h2">{icon}</Typography>
                  </Grid>
                  <Grid
                    container
                    direction="column"
                    item
                    xs={textAndCounterSizes[0]}
                    sm={textAndCounterSizes[1]}
                    md={textAndCounterSizes[2]}
                    lg={textAndCounterSizes[3]}
                    xl={textAndCounterSizes[4]}
                  >
                    <Typography align="left" variant="h4">
                      {counter}
                    </Typography>
                    <Typography align="left">{text}</Typography>
                  </Grid>
                </Grid>
              );
            })}
            <Grid item xs={12}>
              {stoppedCalls}
              {partialCalls}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  return <Paper elevation={21} />;
};

export default withStyles(styles)(Home);
