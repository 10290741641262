import React, { FC, ComponentType } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import ptLocale from 'date-fns/locale/pt-BR';
import { InputAdornment, TextFieldProps } from '@material-ui/core';
import { Event as EventIcon } from '@material-ui/icons';
import { DatePicker, DatePickerProps, MuiPickersUtilsProvider } from '@material-ui/pickers';
import CustomTextField from '../CustomTextField';
import { MarginType } from '../../../Utils/globalTypes';

interface CustomDatePickerProps extends DatePickerProps {
  margin?: MarginType;
}

const CustomDatePicker: FC<CustomDatePickerProps> = props => {
  const {
    animateYearScrolling,
    autoFocus,
    autoOk,
    className,
    disabled,
    error,
    fullWidth,
    helperText,
    inputVariant,
    InputProps,
    label,
    margin,
    minDate,
    name,
    onChange,
    onClose,
    openTo,
    placeholder,
    required,
    value,
  } = props;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
      <DatePicker
        animateYearScrolling={animateYearScrolling}
        autoFocus={autoFocus}
        autoOk={autoOk}
        cancelLabel="Cancelar"
        className={className}
        clearable
        clearLabel="Limpar"
        disabled={disabled}
        error={error}
        format="dd/MM/yyyy"
        fullWidth={fullWidth}
        helperText={helperText}
        inputVariant={inputVariant}
        InputLabelProps={{
          shrink: placeholder ? true : undefined,
        }}
        InputProps={{
          ...InputProps,
          endAdornment: (
            <InputAdornment position="end">
              <EventIcon fontSize="small" style={{ color: `#767676` }} />
            </InputAdornment>
          ),
        }}
        label={label}
        margin={margin}
        minDate={minDate}
        name={name}
        onChange={onChange}
        onClose={onClose}
        openTo={openTo}
        placeholder={placeholder}
        required={required}
        TextFieldComponent={CustomTextField as ComponentType<TextFieldProps>}
        value={value}
        views={[`year`, `month`, `date`]}
      />
    </MuiPickersUtilsProvider>
  );
};

export default CustomDatePicker;
