import React from 'react';
import PropTypes from 'prop-types';
import { Button, DialogContent, Grid, Typography } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const ThirdStep = ({ classes, closeButtonFunc, closeButtonText, isEditingMultiOrgUser, userData }) => {
  const { email, firstName, password } = userData;

  return (
    <DialogContent classes={{ root: classes.contentRoot }}>
      <Grid alignItems="center" className={classes.thirdStepGrids} container>
        <CheckCircle className={`${classes.checkCircle} ${classes.successMessage}`} />
        <Typography className={`${classes.successMessage} ${classes.successText}`} inline="true">
          {isEditingMultiOrgUser
            ? `${firstName} foi adicionado à organização com sucesso`
            : `${firstName} foi criado com sucesso`}
        </Typography>
      </Grid>
      <Grid alignItems="center" className={classes.thirdStepGrids} container>
        <Typography align="justify" className={classes.succcessInfo}>
          {isEditingMultiOrgUser
            ? `O usuário já pode acessar normalmente a organização através do e-mail informado.`
            : `Copie abaixo as credenciais do novo usuário. A senha é temporária, será solicitado para ${firstName} trocar a
          senha no primeiro login.`}
        </Typography>
      </Grid>
      <Grid alignItems="center" className={classes.copyUserInfoGrid} container>
        <Grid item xs={12}>
          <Typography className={classes.userInfoLabel}>
            {isEditingMultiOrgUser ? `E-mail` : `E-mail e senha`}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid alignItems="center" container justify="space-between">
            <Grid item xs={6}>
              <Typography className={classes.userInfoContent}>{email}</Typography>
              {!isEditingMultiOrgUser && <Typography className={classes.userInfoContent}>{password}</Typography>}
            </Grid>
            <Grid container item justify="flex-end" xs={6}>
              {!isEditingMultiOrgUser && (
                <CopyToClipboard text={`${email}\n${password}`}>
                  <Button color="primary">Copiar</Button>
                </CopyToClipboard>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.closeButtonGrid} container justify="flex-end">
        <Button className={classes.closeButton} color="default" onClick={closeButtonFunc}>
          {closeButtonText}
        </Button>
      </Grid>
    </DialogContent>
  );
};

ThirdStep.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  closeButtonFunc: PropTypes.func.isRequired,
  closeButtonText: PropTypes.string.isRequired,
  isEditingMultiOrgUser: PropTypes.bool.isRequired,
  userData: PropTypes.shape({
    admissionDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    avatar: PropTypes.string,
    avatarThumb: PropTypes.string,
    birthDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    cpf: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    groups: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isFakeEmail: PropTypes.bool,
    lastName: PropTypes.string,
    password: PropTypes.string,
    sectors: PropTypes.arrayOf(PropTypes.number),
    sex: PropTypes.string,
    userInstitution: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
};

export const TestThirdStep = ThirdStep;

export default ThirdStep;
