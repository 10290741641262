import { all, call, delay, put, select } from 'redux-saga/effects';
import { Action, Store } from 'redux';
import { SagaIterator } from 'redux-saga';
import { Dialog } from 'muibox';

/**
 * API from axios
 */
import api from '../../../Services/api';

import {
  closeOmbudsmansSuccess,
  loadOmbudmansKpisSuccess,
  loadOmbudsmansRequest,
  loadOmbudsmansSuccess,
  ombudsmanAddSuccess,
  ombudsmanFormErrors,
  ombudsmanUpdateSuccess,
  openOmbudsmansSuccess,
} from './Actions';
import { OmbudsmansTypes, OmbudsmansState } from './Types';

/**
 * Get access token
 */
interface AppStore extends Store {
  Ombudsmans?: OmbudsmansState;
}

interface BaseAction extends Action {
  payload: {
    dialog: Dialog;
  };
}

interface LoadOmbsAction extends BaseAction {
  payload: {
    activeColumn?: number;
    dialog: Dialog;
    filterList?: OmbudsmansState['filterList']; // eslint-disable-line
    page?: number;
    rowsPerPage?: number;
    searchText?: string;
    sort?: string;
  };
}

interface OpenOmbDialogAction extends BaseAction {
  payload: {
    dialog: Dialog;
    id?: number;
  };
}

interface SubmitOmbudsmanAction extends BaseAction {
  payload: {
    afterUpdateCallback?: Function;
    data: FormData;
    dialog: Dialog;
    id?: number;
  };
}

interface ParamsObject {
  [key: string]: number | string | string[] | undefined;
  globalSearch?: string;
  length: number;
  page: number;
  sort?: string;
}

function getOmbudsmansQueryParams(ombudsmansState: OmbudsmansState): ParamsObject {
  const serializedFilterList = Object.keys(ombudsmansState.filterList).reduce(
    (acc: { [key: string]: string }, currentValue: string): { [key: string]: string } => {
      const { filterList } = ombudsmansState;
      return { ...acc, [currentValue]: filterList[currentValue].join(`...`) };
    },
    {},
  );
  const params: ParamsObject = {
    ...serializedFilterList,
    globalSearch: ombudsmansState.searchText,
    length: ombudsmansState.rowsPerPage,
    page: ombudsmansState.page + 1,
  };
  if (ombudsmansState.sort && Object.entries(ombudsmansState.sort).length !== 0) {
    params.sort = `${Object.keys(ombudsmansState.sort)[0]},${
      ombudsmansState.sort[Object.keys(ombudsmansState.sort)[0]].order
    }`;
  }
  return params;
}

export function* loadOmbudsmansKpis(action: BaseAction): SagaIterator {
  delay(500);
  const { dialog } = action.payload;
  try {
    const ombudsmansState = yield select((state: AppStore) => state.Ombudsmans);
    const params = getOmbudsmansQueryParams(ombudsmansState);
    const response = yield call(api.get, `/tasks/ombudsman/get-sla-kpi/`, { params });
    yield put(loadOmbudmansKpisSuccess(response.data));
  } catch (error) {
    dialog.alert(`Não foi possível connectar com o servidor!`);
    yield put({ type: OmbudsmansTypes.LOAD_ERRORS });
  }
}

export function* loadOmbudsmans(action: LoadOmbsAction): SagaIterator {
  delay(500);
  const { dialog } = action.payload;
  try {
    const ombudsmansState = yield select((state: AppStore) => state.Ombudsmans);
    const params = getOmbudsmansQueryParams(ombudsmansState);
    const response = yield call(api.get, `/tasks/ombudsman/`, { params });
    yield put(loadOmbudsmansSuccess(response.data));
  } catch (error) {
    dialog.alert(`Não foi possível connectar com o servidor!`);
    yield put({ type: OmbudsmansTypes.LOAD_ERRORS });
  }
}

export function* openOmbudsmansDialog(action: OpenOmbDialogAction): SagaIterator {
  const { dialog } = action.payload;
  try {
    if (action.payload.id) {
      const response = yield call(api.get, `/tasks/ombudsman/${action.payload.id}/`);
      yield put(openOmbudsmansSuccess(response.data));
    } else {
      yield put(openOmbudsmansSuccess());
    }
  } catch (error) {
    dialog.alert(
      `Não foi possível realizar essa ação, por favor realize um refresh no navegador e caso o problema não seja resolvido entre em contato com nosso suporte!`,
    );
    yield put({ type: OmbudsmansTypes.LOAD_ERRORS });
  }
}

export function* closeOmbudsmansDialog(action: BaseAction): SagaIterator {
  const { dialog } = action.payload;
  try {
    yield all([put(loadOmbudsmansRequest(dialog)), put(closeOmbudsmansSuccess())]);
  } catch (error) {
    console.error(error); // eslint-disable-line
  }
}

export function* ombudsmansAddOrUpdateRequest(action: SubmitOmbudsmanAction): SagaIterator {
  const { afterUpdateCallback, dialog } = action.payload;
  try {
    if (action.payload.id) {
      const response = yield call(api.patch, `/tasks/ombudsman/${action.payload.id}/`, action.payload.data);
      yield put(ombudsmanUpdateSuccess(response.data));
    } else {
      const response = yield call(api.post, `/tasks/ombudsman/`, action.payload.data);
      yield put(ombudsmanAddSuccess(response.data));
    }
    if (afterUpdateCallback) afterUpdateCallback();
    yield put(loadOmbudsmansRequest(dialog));
  } catch (error) {
    dialog.alert(
      `Não foi possível realizar essa ação, por favor realize um refresh no navegador e caso o problema não seja resolvido entre em contato com nosso suporte!`,
    );
    if (error.response && error.response.data) yield put(ombudsmanFormErrors(error.response.data));
  }
}
