import React, { SFC, useState } from 'react';
import { useDialog } from 'muibox';
import { LinearProgress, Typography } from '@material-ui/core';
import { SimpleCategoryDialog, SimpleCategoryState } from '../../ReduxFlow/Reducers/types';
import SimpleCategoriesTable from '../../Components/Categories/SimpleCategoriesTable';
import SimpleCategoryDialogForm from './DialogCategoriesForms/SimpleCategory';
import DeleteConfirmDialog from '../../Components/CategoriesForm/DeleteConfirmDialog';

type SimpleCategoriesProps = {
  addOrUpdateRequest: Function;
  closeCategoryRequest: Function;
  categories: SimpleCategoryState;
  isLoadingUI: boolean;
  openCategoryRequest: Function;
  showInactive?: boolean;
  subtitle: string;
};

const SimpleCategories: SFC<SimpleCategoriesProps> = ({
  addOrUpdateRequest,
  closeCategoryRequest,
  categories,
  isLoadingUI,
  openCategoryRequest,
  showInactive,
  subtitle,
}) => {
  const dialog = useDialog();
  const { data: propsData, loading: loadingData, dialogOpen, formInstance, isSaving } = categories;

  const activeCategories = propsData.filter(v => v.isActive);
  const inactiveCategories = propsData.filter(v => !v.isActive);
  const [deleteDialog, setDeleteDialog] = useState<{ open: boolean; category: SimpleCategoryDialog }>({
    open: false,
    category: { name: `Nenhuma categoria selecionada` },
  });

  const deleteCallback = (id: string | number): void => {
    const category = activeCategories.find(cat => cat.id === id);
    if (category) {
      setDeleteDialog({
        open: true,
        category,
      });
    }
  };

  const deleteSubmit = (): void => {
    const { category } = deleteDialog;
    const postData = { id: category.id, isActive: false };
    addOrUpdateRequest({ dialog, postData });
    setDeleteDialog({
      open: false,
      category: { name: `Nenhuma categoria selecionada` },
    });
  };

  const editCallback = (id: number | string): void => {
    openCategoryRequest({ dialog, id });
  };

  const reactivateCallback = (id: number | string): void => {
    const postData = { id, isActive: true };
    addOrUpdateRequest({ dialog, postData });
  };

  return (
    <>
      {(loadingData || isSaving) && <LinearProgress />}
      <SimpleCategoriesTable
        addCallback={(): void => openCategoryRequest({ dialog })}
        data={activeCategories}
        deleteCallback={deleteCallback}
        editCallback={editCallback}
        isLoadingUI={isLoadingUI}
        keyName="id"
        loadingData={loadingData}
      />
      {showInactive && !!inactiveCategories.length && (
        <>
          <Typography variant="body1" style={{ marginTop: `20px`, marginBottom: `10px` }}>
            Categorias desativadas
          </Typography>
          <SimpleCategoriesTable
            data={inactiveCategories}
            isInactiveCategoryTable
            isLoadingUI={isLoadingUI}
            keyName="id"
            loadingData={loadingData}
            reactivateCallback={reactivateCallback}
          />
        </>
      )}
      {dialogOpen && (
        <SimpleCategoryDialogForm
          addOrUpdateRequest={addOrUpdateRequest}
          closeDialogForm={closeCategoryRequest}
          dialog={dialog}
          dialogOpen={dialogOpen}
          formInstance={formInstance}
          isSaving={isSaving}
          subtitle={subtitle}
          title="Criar categoria"
        />
      )}
      {deleteDialog && (
        <DeleteConfirmDialog
          closeDeleteDialog={setDeleteDialog}
          deleteDialog={deleteDialog}
          deleteSubmit={deleteSubmit}
        />
      )}
    </>
  );
};

export default SimpleCategories;
