import React, { FC } from 'react';
import MaskedInput from 'react-text-mask';

type NumberInputProps = {
  [key in string | number]: string;
} & {
  digits?: number;
  inputRef: (ref: HTMLElement | null) => void;
};

const NumberInput: FC<NumberInputProps> = ({ digits = 2, inputRef, ...other }) => (
  <MaskedInput
    // eslint-disable-next-line
    {...other}
    ref={(ref): void => {
      inputRef(ref ? ref.inputElement : null);
    }}
    mask={new Array(digits).fill(/[0-9]/)}
    placeholderChar={`\u2000`}
  />
);

export default NumberInput;
