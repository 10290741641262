import { Reducer } from 'redux';
import { Sector, SectorsState, SectorsTypes } from './Types';

export const INITIAL_STATE: SectorsState = {
  data: [],
  error: false,
  isLoading: false,
  isSaving: false,
  modalOpen: false,
  formInstance: [],
  range: 10,
  formErrors: {},
};

const reducer: Reducer<SectorsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SectorsTypes.LOAD_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: false,
        isSaving: false,
        data: state.data,
        formErrors: {},
      };
    case SectorsTypes.LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        isSaving: false,
        data: action.payload.data,
      };
    case SectorsTypes.LOAD_FAILURE:
      return { ...state, isLoading: false, error: true, isSaving: false, data: [] };
    case SectorsTypes.ADD_REQUEST:
      return { ...state, isLoading: false, error: false, isSaving: true, data: state.data };
    case SectorsTypes.ADD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        isSaving: false,
        data: [action.payload.data, ...state.data],
      };
    case SectorsTypes.ADD_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
        isSaving: false,
        formErrors: action.payload.errors,
        data: state.data,
      };
    case SectorsTypes.UPDATE_REQUEST:
      return { ...state, isLoading: false, error: false, isSaving: true, data: state.data };
    case SectorsTypes.UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        isSaving: false,
        data: state.data.map((sector: Sector) =>
          sector.id === action.payload.data.id ? action.payload.data : sector,
        ),
      };
    case SectorsTypes.UPDATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: false,
        isSaving: false,
        formErrors: action.payload.errors,
        data: state.data,
      };
    case SectorsTypes.OPEN_REQUEST:
      return { ...state, isLoading: true, formErrors: {} };
    case SectorsTypes.OPEN_SUCCESS:
      return { ...state, modalOpen: true, formInstance: action.payload, isLoading: false };
    case SectorsTypes.CLOSE_REQUEST:
      return { ...state, isLoading: true, modalOpen: false };
    case SectorsTypes.CLOSE_SUCCESS:
      return { ...state, modalOpen: false, formInstance: [], isLoading: false, formErrors: {} }
    case SectorsTypes.RANGE_REQUEST:
      return { ...state, isLoading: true };
    case SectorsTypes.RANGE_SUCCESS:
      return { ...state, isLoading: false, range: state.range + 10 };
    default:
      return state;
  }
};

export default reducer;
