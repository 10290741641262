import React from 'react';
/**
 * Router DOM dependencies
 */
import { BrowserRouter, withRouter } from 'react-router-dom';
/**
 * Redux dependencies
 */
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
/**
 * Material UI - Core
 */
import {
  Button,
  CssBaseline,
  Drawer,
  Hidden,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Slide,
  SwipeableDrawer,
  Typography,
  withStyles,
} from '@material-ui/core';
/**
 * Material UI - Color manipulator
 */

import { fade } from '@material-ui/core/styles/colorManipulator';
/**
 * Material UI - Icons
 */
import { NewReleases, Notifications, NotificationsActive, NotificationsOff, Refresh } from '@material-ui/icons';
/**
 * Routes
 */
import Routes from '../../Routes';
/**
 * Auth Action
 */
import { loginRequest } from '../../ReduxFlow/Reducers/Auth/Actions';
/**
 * Users actions
 */
import * as UserActions from '../../ReduxFlow/Reducers/Users/Actions';
/**
 * Tasks actions
 */
import * as TasksActions from '../../ReduxFlow/Reducers/Tasks/Actions';
/**
 * InternalDocs actions
 */
import * as InternaldocsActions from '../../ReduxFlow/Reducers/InternalDocs/Actions';
/**
 * InternalDocs actions
 */
import * as ExternaldocsActions from '../../ReduxFlow/Reducers/ExternalDocs/Actions';
/**
 * Static images
 */
import genericAvatar from '../../Images/GenericAvatar/genericavatar.png';
/**
 * My components
 */
import { navigationPathNames } from './sideBarPathNames';
import { urlB64ToUint8Array } from '../../Utils';
import api from '../../Services/api';
import logger from '../../Utils/logger';
import CustomDrawerComponent from '../Drawer/CustomDrawerComponent';
import CustomAppBarComponent from './CustomAppBarComponent';
import MobileMenu from '../MobileMenu/MobileMenu';
import NotificationsMenu from '../../Pages/Notifications/NotificationsMenu';
import ProfileForm from '../ProfileForm/ProfileForm';

import { quality } from '../../Utils/firebaseConfig';
import FeedbackDialog from '../FeedbackDialog';

const drawerWidth = 260;

const styles = theme => ({
  avatar: {
    width: 35,
    height: 35,
  },
  badge: {
    top: `50%`,
    left: `100%`,
  },
  content: {
    flexGrow: 1,
    [theme.breakpoints.up(`md`)]: {
      padding: `${theme.spacing(1)}px ${theme.spacing(3)}px 0 ${theme.spacing(3)}px`,
    },
  },
  desktopNoInternetIcon: {
    position: `relative`,
    top: -theme.spacing(1.25),
    left: -theme.spacing(5.5),
  },
  drawer: {
    [theme.breakpoints.up(`lg`)]: {
      paddingTop: theme.spacing(8),
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.up(`lg`)]: {
      paddingTop: theme.spacing(8),
      zIndex: `auto`,
    },
  },
  grow: {
    flexGrow: 1,
  },
  inputRoot: {
    color: `inherit`,
    width: `100%`,
    [theme.breakpoints.up(`md`)]: {
      width: 200,
    },
  },
  inputInput: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(5),
    transition: theme.transitions.create(`width`),
    width: `100%`,
    [theme.breakpoints.up(`md`)]: {
      width: 200,
    },
  },
  logo: {
    width: `100%`,
    margin: `auto`,
  },
  logoSideList: {
    width: 64,
    marginLeft: `40%`,
  },
  logoNavLink: {
    [theme.breakpoints.down(`md`)]: {
      display: `none`,
    },
  },
  logoWrapper: {
    height: theme.spacing(5.875),
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up(`lg`)]: {
      display: `none`,
    },
  },
  mobileNoInternetIcon: {
    left: theme.spacing(27.5),
    position: `absolute`,
    top: theme.spacing(1.75),
  },
  mobileLogoNavlink: {
    [theme.breakpoints.up(`md`)]: {
      display: `none`,
    },
  },
  mobileLogoWrapper: {
    height: theme.spacing(5.875),
    paddingLeft: theme.spacing(1.5),
  },
  navlink: {
    textDecoration: `none`,
    color: `unset`,
  },
  navlinkMobile: {
    textDecoration: `none`,
    color: `unset`,
    display: `inherit`,
    alignItems: `inherit`,
    alignContent: `inherit`,
    justifyContent: `inherit`,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  root: {
    display: `flex`,
  },
  search: {
    position: `relative`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    [theme.breakpoints.down(`sm`)]: {
      width: `100%`,
    },
  },
  searchIcon: {
    width: theme.spacing(5),
    height: `100%`,
    position: `absolute`,
    pointerEvents: `none`,
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
  },
  sectionDesktop: {
    display: `none`,
    [theme.breakpoints.up(`md`)]: {
      display: `flex`,
      alignItems: `center`,
    },
  },
  sectionMobile: {
    display: `flex`,
    [theme.breakpoints.up(`md`)]: {
      display: `none`,
    },
  },
  sideBarHeader: {
    padding: 0,
    [theme.breakpoints.down(`lg`)]: {
      backgroundColor: Boolean(theme.palette.type === `dark`) && `#212121`,
    },
    [theme.breakpoints.up(`lg`)]: {
      backgroundColor: theme.palette.type === `dark` ? `#212121` : `#4bc2c8`,
    },
  },
  toolbar: theme.mixins.toolbar,
  updateBanner: {
    alignContent: `center`,
    alignItems: `center`,
    borderRadius: 0,
    display: `flex`,
    height: 65,
    justifyContent: `center`,
    [theme.breakpoints.up(`sm`)]: {
      margin: `-8px -24px 0 -24px`,
    },
    [theme.breakpoints.down(`sm`)]: {
      marginTop: 1,
    },
  },
});

class ResponsiveDrawer extends React.Component {
  state = {
    anchorEl: null,
    filterValue: { value: ``, type: ``, responsible: false, followers: false, status: false },
    hasInternet: navigator.onLine,
    isFeedbackDialogOpen: false,
    isFirstLoad: true,
    isProfileMenuOpen: false,
    isSubscribed: false,
    menuOpen: window.innerWidth < 1280,
    mobileMoreAnchorEl: null,
    mobileOpen: false,
    noMoreNotifications: false,
    notifications: [],
    notifyAnchorEl: null,
    profileDialog: false,
    pushSupport: false,
    selectedIndex: null,
    unreadNotificationCount: 0,
  };

  componentDidMount() {
    const { history } = this.props;
    history.listen((location, action) => {
      const { filterValue, menuOpen, selectedIndex } = this.state;
      const { status, value } = filterValue;
      const { history: insideHistory } = this.props;
      const { pathname } = insideHistory.location;
      const newSelectedIndex = this.handleSelectedIndex(pathname);
      if (!selectedIndex || action === `POP`) {
        this.setState({
          selectedIndex: parseInt(selectedIndex, 11) === newSelectedIndex && value ? selectedIndex : newSelectedIndex,
          filterValue: status ? { ...filterValue } : { ...filterValue, status: true, value: `` },
          menuOpen: menuOpen || [7, 8, 9, 10, 11, 12].indexOf(selectedIndex) > -1,
        });
      }
    });
    this.subscribeNotifications();
    if (!(typeof navigator.serviceWorker === `undefined`)) {
      navigator.serviceWorker.ready
        .then(registration => {
          if (`pushManager` in registration) {
            registration.pushManager.getSubscription().then(subscription => {
              this.setState({
                pushSupport: true,
                isSubscribed: !(subscription === null),
              });
            });
          }
        })
        .catch(err => {
          logger.error(`Erro: `, err);
          this.setState({
            pushSupport: false,
          });
        });
    } else {
      this.setState({
        pushSupport: false,
      });
    }
    window.addEventListener(`online`, () => this.setState({ hasInternet: true }));
    window.addEventListener(`offline`, () => this.setState({ hasInternet: false }));
  }

  componentDidUpdate(prevProps) {
    const { history, isLoadingUI, loggedUserInfo } = this.props;
    const { currentOrganization, user } = loggedUserInfo;
    const { isAdminOrSuperAdmin } = user;
    const prevIsLoadingUI = prevProps.isLoadingUI;
    if (isLoadingUI !== prevIsLoadingUI && !isAdminOrSuperAdmin) history.replace(`/quality/tasks`);
    const prevOrganization = prevProps.loggedUserInfo.currentOrganization;
    if (prevOrganization && currentOrganization.id !== prevOrganization.id) {
      if (this.notificationsCounterUnsubscribe) this.notificationsCounterUnsubscribe();
      if (this.notificationsUnsubscribe) this.notificationsUnsubscribe();
      this.subscribeNotifications();
    }
  }

  componentWillUnmount() {
    window.removeEventListener(`online`, () => this.setState({ hasInternet: true }));
    window.removeEventListener(`offline`, () => this.setState({ hasInternet: false }));
    if (this.notificationsCounterUnsubscribe) this.notificationsCounterUnsubscribe();
    if (this.notificationsUnsubscribe) this.notificationsUnsubscribe();
    this.unsubscribe();
  }

  handleSelectedIndex = pathName => {
    if (pathName === `/quality`) return 0;
    return navigationPathNames.indexOf(pathName.replace(`/quality`, ``));
  };

  handlePushSubscribe = () => {
    const { loggedUserInfo } = this.props;
    const applicationServerKey = urlB64ToUint8Array(process.env.REACT_APP_PUBLIC_VAPID_KEYS);
    navigator.serviceWorker.ready.then(registration => {
      registration.pushManager
        .subscribe({
          userVisibleOnly: true,
          applicationServerKey,
        })
        .then(subscription => {
          logger.log(`User is subscribed:`, subscription);
          const info = JSON.parse(JSON.stringify(subscription));
          api
            .post(`/account/pushsubscribe/push/`, {
              user: loggedUserInfo.user.id,
              endpoint: info.endpoint,
              p256dh: info.keys.p256dh,
              auth: info.keys.auth,
            })
            .then(res => {
              this.setState({
                isSubscribed: true,
              });
            });
        })
        .catch(err => {
          logger.error(`Failed to subscribe the user: ${err}`);
          this.setState({
            isSubscribed: false,
          });
        });
    });
  };

  handlePushUnsubscribe = () => {
    navigator.serviceWorker.ready.then(registration => {
      registration.pushManager.getSubscription().then(subscription => {
        const info = JSON.parse(JSON.stringify(subscription));
        if (!info) {
          this.setState({
            isSubscribed: false,
          });
          return;
        }

        subscription.unsubscribe().then(successfull => {
          logger.log(successfull);
        });
        api
          .get(`/account/pushsubscribe/push/?endpoint=${info.endpoint}`)
          .then(json => {
            if (!json.data[0]) {
              return { status: 200 };
            }
            return api.delete(`/account/pushsubscribe/push/${json.data[0].id}/`).then(res => {
              this.setState({
                isSubscribed: false,
              });
            });
          })
          .catch(err => {
            logger.error(`Failed to unsubscribe the user: ${err}`);
            this.setState({
              isSubscribed: true,
            });
          });
      });
    });
  };

  handleMenuActive = (event, index) => {
    if (index === navigationPathNames.indexOf(`/`)) {
      const { loginRequest: loadCounters } = this.props;
      loadCounters();
    }

    this.setState({
      selectedIndex: index,
      filterValue: { value: ``, active: false, responsible: false, followers: false, status: true },
    });
  };

  handleSubMenuActive = (event, index, filter, responsible = false, followers = false, status = false) => {
    this.setState({
      selectedIndex: index,
      filterValue: { value: filter, active: true, responsible, followers, status },
    });
  };

  handleSearchChange = event => {
    const { selectedIndex } = this.state;
    if (window.location.pathname === `/tasks` && event.target.value === ``) {
      this.setState({
        selectedIndex: Math.round(selectedIndex),
        filterValue: {
          value: event.target.value,
          active: false,
          responsible: false,
          followers: false,
          status: true,
        },
      });
    } else {
      this.setState({
        filterValue: {
          value: event.target.value,
          active: false,
          responsible: false,
          followers: false,
          status: true,
        },
      });
    }
  };

  handleDrawerToggle = event => {
    if (event) {
      if (
        window.innerWidth < 1280 &&
        event.target.innerText !== `Catálogos` &&
        event.target.id !== `id_catalogos` &&
        event.target.parentElement.id !== `id_catalogos`
      ) {
        this.setState(state => ({ mobileOpen: !state.mobileOpen }));
      }
    } else {
      this.setState(state => ({ mobileOpen: !state.mobileOpen }));
    }
  };

  handleFeedbackDialogOpen = () => {
    const { isFeedbackDialogOpen } = this.state;
    if (isFeedbackDialogOpen) {
      this.setState({ isFeedbackDialogOpen: false });
    } else {
      this.setState({ isFeedbackDialogOpen: true });
    }
  };

  handleProfileMenuOpen = event => {
    this.setState({
      anchorEl: event.currentTarget,
      isProfileMenuOpen: true,
    });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null, isProfileMenuOpen: false });
    this.handleMobileMenuClose();
  };

  handleMobileMenuOpen = event => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  handleExpandMenuState = () => {
    const { menuOpen } = this.state;
    this.setState({ menuOpen: !menuOpen });
  };

  handleFetchStatus = () => {
    const { filterValue } = this.state;
    this.setState({
      filterValue: { ...filterValue, status: false },
    });
  };

  hasProperty = (prop, object) => {
    if (!object) return false;
    return !!(prop in object && object[prop] !== undefined);
  };

  handleNotificationsOpen = event => {
    const { history, loggedUserInfo } = this.props;
    const { notifyAnchorEl, unreadNotificationCount } = this.state;
    const { currentOrganization } = loggedUserInfo;
    if (notifyAnchorEl) {
      this.setState({ notifyAnchorEl: null });
      return;
    }
    if (window.innerWidth > 600) {
      this.setState({ notifyAnchorEl: event.currentTarget });
    } else {
      history.push(`/quality/notifications/`);
    }
    if (unreadNotificationCount > 0)
      this.firestore
        .collection(`organizations/${currentOrganization.id}/users`)
        .doc(this.currentUser.uid)
        .update({ ncount: 0 });
  };

  handleReadNotification = (id, al, mid, mn) => async event => {
    const { notifications, notifyAnchorEl } = this.state;
    const { history, loggedUserInfo, openExternalDocsRequest, openInternalDocsRequest, openTasksRequest } = this.props;
    const { currentOrganization } = loggedUserInfo;

    const notification = notifications.find(noti => noti.id === id);

    if (notification) {
      this.firestore
        .collection(`organizations/${currentOrganization.id}/users/${this.currentUser.uid}/notifications`)
        .doc(id)
        .update({ r: true });

      const newNotifications = notifications.map(noti => (noti.id === id ? { ...noti, r: true } : noti));

      this.setState({ notifications: newNotifications });
    } else {
      this.firestore
        .collection(`organizations/${currentOrganization.id}/users/${this.currentUser.uid}/notifications`)
        .doc(id)
        .update({ r: true });
    }

    if (notification.t === `report-ready`) {
      window.open(notification.url);
    } else {
      history.push(`/quality/${al}/`);
      if (al === `tasks`) {
        switch (true) {
          case mn === `calls`:
            openTasksRequest({ id: mid, menuType: `Chamado` });
            break;
          case mn === `ombudsmans`:
            openTasksRequest({ id: mid, menuType: `Ouvidoria` });
            break;
          case mn === `ncs`:
            openTasksRequest({ id: mid, menuType: `Não conformidade` });
            break;
          case mn === `preventives`:
            openTasksRequest({ id: mid, menuType: `Preventiva` });
            break;
          default:
            openTasksRequest({ id: mid, menuType: `Tarefa` });
        }
      } else if (al === `internaldocs`) {
        openInternalDocsRequest({ id: mid });
      } else if (al === `externaldocs`) {
        openExternalDocsRequest({ id: mid });
      }
    }
    if (notifyAnchorEl) this.handleNotificationsOpen();
  };

  handleScrollNotifications = ts => async event => {
    event.stopPropagation();
    const { notifications, noMoreNotifications } = this.state;
    const { loggedUserInfo } = this.props;
    const { currentOrganization } = loggedUserInfo;
    clearTimeout(this.scrollTimeout);
    if (event.target.scrollHeight - event.target.scrollTop - 100 <= event.target.clientHeight && !noMoreNotifications) {
      this.scrollTimeout = setTimeout(async () => {
        const notificationSnapshot = await this.firestore
          .collection(`organizations/${currentOrganization.id}/users/${this.currentUser.uid}/notifications`)
          .orderBy(`ts`, `desc`)
          .startAfter(ts)
          .limit(10)
          .get();
        const nextNotifications = [];
        notificationSnapshot.forEach(doc => nextNotifications.push({ id: doc.id, ...doc.data() }));
        if (nextNotifications.length < 10) {
          this.setState({
            notifications: [...notifications, ...nextNotifications],
            noMoreNotifications: true,
          });
        } else {
          this.setState({
            notifications: [...notifications, ...nextNotifications],
          });
        }
      }, 200);
    }
  };

  readAllNotifications = async () => {
    const { notifications } = this.state;
    const { loggedUserInfo } = this.props;
    const { currentOrganization } = loggedUserInfo;
    const newNotifications = notifications.map(noti => ({ ...noti, r: true }));
    this.setState({
      notifications: newNotifications,
    });
    const collectionRef = this.firestore.collection(
      `organizations/${currentOrganization.id}/users/${this.currentUser.uid}/notifications`,
    );
    const snapshot = await collectionRef.where(`r`, `==`, false).get();
    snapshot.forEach(doc => {
      collectionRef.doc(doc.id).update({ r: true });
    });
  };

  openProfileDialog = () => {
    this.setState({ profileDialog: true });
  };

  closeProfileDialog = () => {
    this.setState({ profileDialog: false });
  };

  handleUpdateClick = () => {
    window.localStorage.setItem(`shouldUpdateServiceWorker`, `false`);
    window.location.reload();
  };

  subscribeNotifications() {
    const { loggedUserInfo } = this.props;
    const { currentOrganization } = loggedUserInfo;
    if (this.unsubscribe) this.unsubscribe();

    this.unsubscribe = quality.auth().onAuthStateChanged(async currentUser => {
      if (!currentUser) {
        return;
      }

      console.log(`subscribing to firestore`);
      try {
        await currentUser.getIdToken(false); // refresh auth
        const firestore = quality.firestore();

        this.currentUser = currentUser;
        this.firestore = firestore;
        const notificationCollection = firestore.collection(
          `organizations/${currentOrganization.id}/users/${currentUser.uid}/notifications`,
        );
        const notificationsSnapshot = await notificationCollection
          .orderBy(`ts`, `desc`)
          .limit(10)
          .get();
        const newNotifications = [];
        notificationsSnapshot.forEach(doc => {
          newNotifications.push({ ...doc.data(), id: doc.id });
        });
        notificationCollection
          .orderBy(`ts`, `desc`)
          .limit(1)
          .onSnapshot(snapshot => {
            snapshot.forEach(doc => {
              const { notifications: insideNotifications, isFirstLoad } = this.state;
              const { enqueueSnackbar } = this.props;
              const docData = doc.data();
              const { m, r } = docData;
              if (doc.exists) {
                if (insideNotifications.some(noti => noti.id === doc.id)) {
                  const tempNotifications = insideNotifications.map(noti =>
                    noti.id === doc.id ? { ...noti, ...docData } : noti,
                  );
                  this.setState({ notifications: tempNotifications });
                } else {
                  insideNotifications.unshift({ ...docData, id: doc.id });
                  this.setState({ notifications: insideNotifications });
                }
                if (!isFirstLoad && !r) enqueueSnackbar(`Nova notificação - ${m}.`);
              }
            });
          });

        const userCollection = firestore.collection(`organizations/${currentOrganization.id}/users`);
        const userSnapshot = userCollection.doc(`${currentUser.uid}`);
        const userDoc = await userSnapshot.get();
        const newUnreadNotificationCount = userDoc.data() ? userDoc.data().ncount : 0;
        this.notificationsCounterUnsubscribe = userSnapshot.onSnapshot(doc => {
          this.setState({ unreadNotificationCount: doc.data() ? doc.data().ncount : 0 });
        });

        this.setState({
          notifications: newNotifications,
          unreadNotificationCount: newUnreadNotificationCount,
          isFirstLoad: false,
        });
      } catch (e) {
        // console.error(`error subscribing to notifications`, e);
      }
    });
  }

  render() {
    const { classes, container, handleLogout, isLoadingUI, loggedUserInfo, showUpdateBanner, theme } = this.props;
    const {
      anchorEl,
      filterValue,
      hasInternet,
      isFeedbackDialogOpen,
      isProfileMenuOpen,
      isSubscribed,
      menuOpen,
      mobileMoreAnchorEl,
      mobileOpen,
      notifications,
      notifyAnchorEl,
      profileDialog,
      pushSupport,
      selectedIndex,
      unreadNotificationCount,
    } = this.state;
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const { user: userInfo } = loggedUserInfo;
    const { id, isAdminOrSuperAdmin, isSuperAdmin } = userInfo;
    const hasGeneralInfoOnLoggedUserInfo = this.hasProperty(`generalInfo`, loggedUserInfo);

    const pushSupportStatus = () => {
      if (!pushSupport) {
        return {
          function: null,
          element: (
            <>
              <ListItemIcon>
                <NotificationsOff />
              </ListItemIcon>
              <ListItemText primary="Sem suporte" />
            </>
          ),
          secondElement: (
            <>
              <ListItemIcon>
                <NotificationsOff />
              </ListItemIcon>
              <ListItemText primary="Sem suporte" />
            </>
          ),
        };
      }
      if (isSubscribed) {
        return {
          function: this.handlePushUnsubscribe,
          element: <ListItemText primary="Desativar notificações" />,
          secondElement: (
            <>
              <ListItemIcon>
                <Notifications />
              </ListItemIcon>
              <ListItemText primary="Notificações" />
            </>
          ),
        };
      }
      return {
        function: this.handlePushSubscribe,
        element: <ListItemText primary="Ativar notificações" />,
        secondElement: (
          <>
            <ListItemIcon>
              <NotificationsActive />
            </ListItemIcon>
            <ListItemText primary="Notificações" />
          </>
        ),
      };
    };

    const renderMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: `top`, horizontal: `right` }}
        transformOrigin={{ vertical: `top`, horizontal: `right` }}
        open={isProfileMenuOpen}
        onClose={this.handleMenuClose}
        disableAutoFocusItem
        aria-label="Menu do usuário"
      >
        {/* <MenuItem onClick={this.handleMenuClose}>Configurações</MenuItem> */}
        <MenuItem onClick={this.openProfileDialog}>Meu perfil</MenuItem>
        {Boolean(window.innerWidth > 959) && (
          <MenuItem onClick={pushSupportStatus().function}>{pushSupportStatus().element}</MenuItem>
        )}
        <MenuItem onClick={handleLogout}>
          <ListItemText primary="Logout" />
        </MenuItem>
      </Menu>
    );

    return (
      <BrowserRouter basename="/quality">
        <div className={classes.root}>
          <CssBaseline />
          <CustomAppBarComponent
            classes={classes}
            filterValue={filterValue}
            genericAvatar={genericAvatar}
            handleDrawerToggle={this.handleDrawerToggle}
            handleFeedbackDialogOpen={this.handleFeedbackDialogOpen}
            handleMobileMenuOpen={this.handleMobileMenuOpen}
            handleNotificationsOpen={this.handleNotificationsOpen}
            handleProfileMenuOpen={this.handleProfileMenuOpen}
            handleSearchChange={this.handleSearchChange}
            handleSubMenuActive={this.handleSubMenuActive}
            hasInternet={hasInternet}
            isFeedbackOpen={isFeedbackDialogOpen}
            isMenuOpen={isProfileMenuOpen}
            loggedUserInfo={loggedUserInfo}
            theme={theme}
            unreadNotificationCount={unreadNotificationCount}
          />
          <nav className={classes.drawer}>
            {renderMenu}
            <FeedbackDialog closeDialog={this.handleFeedbackDialogOpen} isOpen={isFeedbackDialogOpen} theme={theme} />
            <MobileMenu
              classes={classes}
              handleLogout={handleLogout}
              handleMobileMenuClose={this.handleMobileMenuClose}
              handleSubMenuActive={this.handleSubMenuActive}
              isMobileMenuOpen={isMobileMenuOpen}
              loggedUserInfo={loggedUserInfo}
              mobileMoreAnchorEl={mobileMoreAnchorEl}
              openUsersRequest={this.openProfileDialog}
              pushSupportStatus={pushSupportStatus}
            />
            <NotificationsMenu
              handleNotificationsOpen={this.handleNotificationsOpen}
              handleReadNotification={this.handleReadNotification}
              handleScrollNotifications={this.handleScrollNotifications}
              notifyAnchorEl={notifyAnchorEl}
              notifications={notifications}
              readAllNotifications={this.readAllNotifications}
              genericAvatar={genericAvatar}
            />
            <Hidden lgUp implementation="css">
              <SwipeableDrawer
                container={container}
                variant="temporary"
                anchor={theme.direction === `rtl` ? `right` : `left`}
                open={mobileOpen}
                onClose={this.handleDrawerToggle}
                onOpen={this.handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
              >
                <CustomDrawerComponent
                  classes={classes}
                  handleDrawerToggle={this.handleDrawerToggle}
                  handleExpandMenuState={this.handleExpandMenuState}
                  handleMenuActive={this.handleMenuActive}
                  handleSubMenuActive={this.handleSubMenuActive}
                  hasInternet={hasInternet}
                  isAdminOrSuperAdmin={isAdminOrSuperAdmin}
                  isSuperAdmin={isSuperAdmin}
                  loggedUserInfo={loggedUserInfo}
                  menuOpen={menuOpen}
                  selectedIndex={selectedIndex}
                />
              </SwipeableDrawer>
            </Hidden>
            <Hidden mdDown implementation="css">
              <Drawer
                classes={{
                  paper: classes.drawerPaper,
                }}
                variant="permanent"
                open
              >
                <CustomDrawerComponent
                  classes={classes}
                  handleExpandMenuState={this.handleExpandMenuState}
                  handleMenuActive={this.handleMenuActive}
                  handleSubMenuActive={this.handleSubMenuActive}
                  hasInternet={hasInternet}
                  isAdminOrSuperAdmin={isAdminOrSuperAdmin}
                  isLoadingUI={isLoadingUI}
                  isSuperAdmin={isSuperAdmin}
                  loggedUserInfo={loggedUserInfo}
                  menuOpen={menuOpen}
                  selectedIndex={selectedIndex}
                />
              </Drawer>
            </Hidden>
          </nav>
          <main className={classes.content}>
            <div className={classes.toolbar} />
            <Slide direction="down" in={showUpdateBanner} mountOnEnter timeout={1500} unmountOnExit>
              <Paper className={classes.updateBanner} variant="outlined">
                <NewReleases style={{ color: `#00000099`, margin: `0 16px` }} />
                <Typography style={{ color: `#00000099` }}>
                  Acabamos de lançar uma nova versão! Atualize e veja as novidades
                </Typography>
                <Hidden smDown>
                  <Button color="primary" onClick={this.handleUpdateClick}>
                    Atualizar
                  </Button>
                </Hidden>
                <Hidden mdUp>
                  <IconButton color="primary" onClick={this.handleUpdateClick} style={{ marginRight: 16 }}>
                    <Refresh />
                  </IconButton>
                </Hidden>
              </Paper>
            </Slide>
            <Routes
              filterValue={filterValue}
              firestore={this.firestore}
              genericAvatar={genericAvatar}
              handleFetchStatus={this.handleFetchStatus}
              handleMenuActive={this.handleMenuActive}
              handleReadNotification={this.handleReadNotification}
              handleScrollNotifications={this.handleScrollNotifications}
              handleSubMenuActive={this.handleSubMenuActive}
              hasGeneralInfoOnLoggedUserInfo={hasGeneralInfoOnLoggedUserInfo}
              isAdminOrSuperAdmin={isAdminOrSuperAdmin}
              isLoadingUI={isLoadingUI}
              isSuperAdmin={isSuperAdmin}
              loggedUserInfo={loggedUserInfo}
              notifications={notifications}
              readAllNotifications={this.readAllNotifications}
            />
            {profileDialog && (
              <>
                <Hidden only={[`sm`, `md`, `lg`, `xl`]}>
                  <ProfileForm
                    closeProfileDialog={this.closeProfileDialog}
                    formInstanceId={id}
                    fullscreen
                    genericAvatar={genericAvatar}
                    open={profileDialog}
                    userInfo={userInfo}
                  />
                </Hidden>
                <Hidden only={[`xs`]}>
                  <ProfileForm
                    closeProfileDialog={this.closeProfileDialog}
                    formInstanceId={id}
                    genericAvatar={genericAvatar}
                    open={profileDialog}
                    userInfo={userInfo}
                  />
                </Hidden>
              </>
            )}
          </main>
        </div>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = state => ({
  Activities: state.Activities,
  Inventories: state.Inventories,
  Institutions: state.Institutions,
  isLoadingUI: state.Auth.isLoadingUI,
  loggedUserInfo: state.Auth.data,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { ...ExternaldocsActions, ...InternaldocsActions, loginRequest, ...TasksActions, ...UserActions },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withSnackbar(withStyles(styles, { withTheme: true })(ResponsiveDrawer))));
