import React, { ReactNode, SFC, useEffect, useMemo, useState } from 'react';
import { bindActionCreators, Dispatch, ActionCreatorsMapObject } from 'redux';
import { connect } from 'react-redux';
import { Dialog, withDialog } from 'muibox';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Hidden,
  Popover,
  Switch,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import { FilterList } from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ApplicationState } from '../../ReduxFlow/Reducers';
import * as externalDocsCategoriesActions from '../../ReduxFlow/Reducers/ExternalDocsCategories/Actions';
import * as internalDocsCategoriesActions from '../../ReduxFlow/Reducers/InternalDocsCategories/Actions';
import * as ombudsmanSourcesActions from '../../ReduxFlow/Reducers/OmbudsmanSources/Actions';
import * as TasksCategoriesActions from '../../ReduxFlow/Reducers/TasksCategories/Actions';
import * as InventoryCategoriesActions from '../../ReduxFlow/Reducers/InventoryCategories/Actions';
import { ExternalDocsCategoriesState } from '../../ReduxFlow/Reducers/ExternalDocsCategories/Types';
import { InternalDocsCategoriesState } from '../../ReduxFlow/Reducers/InternalDocsCategories/Types';
import { OmbudsmanSourcesState } from '../../ReduxFlow/Reducers/OmbudsmanSources/Types';
import { Sector } from '../../ReduxFlow/Reducers/Sectors/Types';
import { TasksCategoryState, TasksCategory, TasksSubcategory } from '../../ReduxFlow/Reducers/TasksCategories/Types';
import { InventoryCategoryState, InventorySubcategory } from '../../ReduxFlow/Reducers/InventoryCategories/Types';
import SimpleCategories from './SimpleCategories';
import CompoundCategories from './CompoundCategories';
import TasksCategoryDialog from './DialogCategoriesForms/Tasks';
import TasksSubcategoryDialog from './DialogCategoriesForms/Tasks/TasksSubcategoryDialog';
import InventoryCategoryDialog from './DialogCategoriesForms/Inventory/InventoryCategory';
import InventorySubcategoryDialog from './DialogCategoriesForms/Inventory/InventorySubcategory';
import { getTaskTypeName } from '../../Utils/tasks';
import { timestampToDaysHoursAndMinutesString } from '../../Utils/categoriesUtils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      marginTop: theme.spacing(2.25),
    },
    cardHeaderRoot: {
      padding: 0,
    },
    cardHeaderSubtitle: {
      padding: 0,
    },
    cardHeaderTitle: {
      padding: theme.spacing(3),
    },
    cardContent: {
      padding: 24,
    },
    tabs: {
      font: `500 14px/19px Hind`,
      letterSpacing: 1.25,
    },
    cardHeaderButton: {
      color: `#000000`,
      opacity: 0.54,
      marginLeft: `auto`,
    },
    cardHeaderContainer: {
      display: `flex`,
      alignItems: `center`,
      alignContent: `last baseline`,
    },
  }),
);

type CategoriesProps = {
  dialog: Dialog;
  isLoadingUI: boolean;
  sectors: Sector[];

  externalDocsCategories: ExternalDocsCategoriesState;
  addOrUpdateExternalDocsRequest: Function;
  closeExternalDocsCategoryRequest: Function;
  loadExternalDocsCategoriesRequest: Function;
  openExternalDocsCategoryRequest: Function;

  internalDocsCategories: InternalDocsCategoriesState;
  addOrUpdateInternalDocsRequest: Function;
  closeInternalDocsCategoryRequest: Function;
  loadInternalDocsCategoriesRequest: Function;
  openInternalDocsCategoryRequest: Function;

  tasksCategories: TasksCategoryState;
  addOrUpdateTasksCategoryRequest: Function;
  addOrUpdateTasksSubCategoryRequest: Function;
  loadTasksCategoriesRequest: Function;
  loadTasksCategoriesTypesRequest: Function;
  closeTasksCategoryRequest: Function;
  closeTasksSubCategoryRequest: Function;
  openTasksCategoryRequest: Function;
  openTasksSubCategoryRequest: Function;

  inventoryCategories: InventoryCategoryState;
  addOrUpdateInventoryCategoryRequest: Function;
  addOrUpdateInventorySubCategoryRequest: Function;
  closeInventoryCategoryRequest: Function;
  closeInventorySubCategoryRequest: Function;
  loadInventoryCategoriesRequest: Function;
  openInventoryCategoryRequest: Function;
  openInventorySubCategoryRequest: Function;

  ombudsmanSources: OmbudsmanSourcesState;
  addOrUpdateOmbudsmanRequest: Function;
  closeOmbudsmanRequest: Function;
  loadOmbudsmanCategoriesRequest: Function;
  openOmbudsmanCategoryRequest: Function;
};

const Categories: SFC<CategoriesProps> = ({
  dialog,
  isLoadingUI,
  sectors,
  externalDocsCategories,
  addOrUpdateExternalDocsRequest,
  closeExternalDocsCategoryRequest,
  loadExternalDocsCategoriesRequest,
  openExternalDocsCategoryRequest,
  internalDocsCategories,
  addOrUpdateInternalDocsRequest,
  closeInternalDocsCategoryRequest,
  loadInternalDocsCategoriesRequest,
  openInternalDocsCategoryRequest,
  tasksCategories,
  addOrUpdateTasksCategoryRequest,
  addOrUpdateTasksSubCategoryRequest,
  loadTasksCategoriesRequest,
  loadTasksCategoriesTypesRequest,
  closeTasksCategoryRequest,
  closeTasksSubCategoryRequest,
  openTasksCategoryRequest,
  openTasksSubCategoryRequest,
  inventoryCategories,
  addOrUpdateInventoryCategoryRequest,
  addOrUpdateInventorySubCategoryRequest,
  closeInventoryCategoryRequest,
  closeInventorySubCategoryRequest,
  loadInventoryCategoriesRequest,
  openInventoryCategoryRequest,
  openInventorySubCategoryRequest,
  ombudsmanSources,
  addOrUpdateOmbudsmanRequest,
  closeOmbudsmanRequest,
  loadOmbudsmanCategoriesRequest,
  openOmbudsmanCategoryRequest,
}) => {
  const classes = useStyles();
  const [tabIndex, setTabIndex] = useState(0);
  const [showInactive, setShowInactive] = useState(false);
  const [filterPopoverOpenAnchorEl, setFilterPopoverOpenAnchorEl] = useState(null);
  const isFilterPopoverOpen = !!filterPopoverOpenAnchorEl;
  const filterPopoverOpenId = isFilterPopoverOpen ? `filter-popover` : undefined;
  const tasksLoadRequests = useMemo(() => [loadTasksCategoriesRequest, loadTasksCategoriesTypesRequest], [
    loadTasksCategoriesRequest,
    loadTasksCategoriesTypesRequest,
  ]);
  const inventoryLoadRequests = useMemo(() => [loadInventoryCategoriesRequest], [loadInventoryCategoriesRequest]);

  const getTaskCategoryTypeNames = (cat: TasksCategory): string =>
    cat.types
      ? tasksCategories.types
          .filter(t => cat.types.indexOf(t.id) !== -1)
          .map(t => getTaskTypeName(t.name))
          .join(`, `)
      : ``;

  const getTaskCategorySectorNames = (cat: TasksCategory): string =>
    cat.sectors
      ? sectors
          .filter(s => cat.sectors.indexOf(s.id) !== -1)
          .map(s => s.name)
          .join(`, `)
      : ``;

  const components = [
    <SimpleCategories
      key={0}
      addOrUpdateRequest={addOrUpdateExternalDocsRequest}
      closeCategoryRequest={closeExternalDocsCategoryRequest}
      categories={externalDocsCategories}
      isLoadingUI={isLoadingUI}
      openCategoryRequest={openExternalDocsCategoryRequest}
      showInactive={showInactive}
      subtitle="Documento externo"
    />,
    <SimpleCategories
      key={1}
      addOrUpdateRequest={addOrUpdateInternalDocsRequest}
      closeCategoryRequest={closeInternalDocsCategoryRequest}
      categories={internalDocsCategories}
      isLoadingUI={isLoadingUI}
      openCategoryRequest={openInternalDocsCategoryRequest}
      showInactive={showInactive}
      subtitle="Documento interno"
    />,
    <CompoundCategories
      key={2}
      addOrUpdateRequest={addOrUpdateInventoryCategoryRequest}
      addOrUpdateSubCategoryRequest={addOrUpdateInventorySubCategoryRequest}
      categories={inventoryCategories}
      isLoadingUI={isLoadingUI}
      loadRequests={inventoryLoadRequests}
      openCategoryRequest={openInventoryCategoryRequest}
      openSubCategoryRequest={openInventorySubCategoryRequest}
      showInactive={showInactive}
      addUpdateDialog={
        <InventoryCategoryDialog
          addOrUpdateRequest={addOrUpdateInventoryCategoryRequest}
          closeDialogForm={closeInventoryCategoryRequest}
          dialog={dialog}
          dialogOpen={inventoryCategories.dialogOpen}
          formInstance={inventoryCategories.formInstance}
          isSaving={inventoryCategories.isSaving}
          subtitle="Ativo"
        />
      }
      addUpdateSubCategoryDialog={
        <InventorySubcategoryDialog
          addOrUpdateRequest={({ postData }: { postData: InventorySubcategory }): void =>
            addOrUpdateInventorySubCategoryRequest({
              dialog,
              postData,
              category: inventoryCategories.selectedCategory,
            })
          }
          closeDialogForm={closeInventorySubCategoryRequest}
          dialog={dialog}
          dialogOpen={inventoryCategories.subCategoryDialogOpen}
          formInstance={inventoryCategories.subCategoryFormInstance}
          isSaving={inventoryCategories.isSaving}
          subtitle=""
          title={inventoryCategories.subCategoryFormInstance ? `Editar subcategoria` : `Criar subcategoria`}
        />
      }
    />,
    <CompoundCategories
      key={3}
      additionalFields={[
        {
          label: `Tipos`,
          content: getTaskCategoryTypeNames,
        },
        {
          label: `Setores`,
          content: getTaskCategorySectorNames,
        },
      ]}
      addOrUpdateRequest={addOrUpdateTasksCategoryRequest}
      addOrUpdateSubCategoryRequest={addOrUpdateTasksSubCategoryRequest}
      categories={tasksCategories}
      customSubcategoryColumns={[
        {
          customRender(value: number[]): ReactNode {
            if (value.every(val => !val)) return ``;
            const timestampsArray = value.map(val => timestampToDaysHoursAndMinutesString(val, true));
            return (
              <>
                {timestampsArray.map((timestamp: string, idx: number) => (
                  <Typography
                    key={idx === 0 ? `$subcategory-tpr` : `$subcategory-tr`}
                    style={{ whiteSpace: `nowrap` }}
                    variant="body2"
                  >
                    {idx === 0 ? <span>TPR: </span> : <span>TR: &nbsp;&nbsp;</span>}
                    {timestamp}
                  </Typography>
                ))}
              </>
            );
          },
          label: `TPR / TR`,
          name: [`firstResponseTime`, `resolutionTime`],
        },
      ]}
      isLoadingUI={isLoadingUI}
      loadRequests={tasksLoadRequests}
      openCategoryRequest={openTasksCategoryRequest}
      openSubCategoryRequest={openTasksSubCategoryRequest}
      showInactive={showInactive}
      addUpdateDialog={
        <TasksCategoryDialog
          addOrUpdateRequest={addOrUpdateTasksCategoryRequest}
          closeDialogForm={closeTasksCategoryRequest}
          dialog={dialog}
          dialogOpen={tasksCategories.dialogOpen}
          formInstance={tasksCategories.formInstance}
          isSaving={tasksCategories.isSaving}
          sectors={sectors}
          subtitle="Tarefa"
          tasksCategoryTypes={tasksCategories.types}
        />
      }
      addUpdateSubCategoryDialog={
        <TasksSubcategoryDialog
          addOrUpdateRequest={({ postData }: { postData: TasksSubcategory }): void =>
            addOrUpdateTasksSubCategoryRequest({
              dialog,
              postData,
              category: tasksCategories.selectedCategory,
            })
          }
          closeDialogForm={closeTasksSubCategoryRequest}
          dialog={dialog}
          dialogOpen={tasksCategories.subCategoryDialogOpen}
          errors={tasksCategories.errors}
          formInstance={tasksCategories.subCategoryFormInstance}
          isSaving={tasksCategories.isSaving}
          parentCategory={tasksCategories.selectedCategory}
          subtitle=""
          title={tasksCategories.subCategoryFormInstance ? `Editar subcategoria` : `Criar subcategoria`}
        />
      }
    />,
    <SimpleCategories
      key={4}
      addOrUpdateRequest={addOrUpdateOmbudsmanRequest}
      closeCategoryRequest={closeOmbudsmanRequest}
      categories={ombudsmanSources}
      isLoadingUI={isLoadingUI}
      openCategoryRequest={openOmbudsmanCategoryRequest}
      showInactive={showInactive}
      subtitle="Origem da Ouvidoria"
    />,
  ];

  useEffect(() => {
    if (!isLoadingUI) {
      loadExternalDocsCategoriesRequest(dialog);
      loadInternalDocsCategoriesRequest(dialog);
      loadOmbudsmanCategoriesRequest(dialog);
    }
  }, [
    dialog,
    isLoadingUI,
    loadExternalDocsCategoriesRequest,
    loadInternalDocsCategoriesRequest,
    loadOmbudsmanCategoriesRequest,
  ]);

  const openFilterMenu = (event: any): void => {
    setFilterPopoverOpenAnchorEl(event.currentTarget);
  };

  const closeFilterPopover = (): void => {
    setFilterPopoverOpenAnchorEl(null);
  };

  const toggleShowInactive = (event: any): void => {
    setShowInactive(event.target.checked);
  };

  return (
    <Hidden smDown>
      <Card className={classes.card}>
        <CardHeader
          classes={{
            subheader: classes.cardHeaderSubtitle,
            title: classes.cardHeaderTitle,
            root: classes.cardHeaderRoot,
          }}
          subheader={
            <Tabs
              aria-label="categories tabs"
              className={classes.tabs}
              indicatorColor="primary"
              onChange={(event: React.ChangeEvent<{}>, newIndex: number): void => {
                setTabIndex(newIndex);
              }}
              textColor="primary"
              value={tabIndex}
            >
              <Tab label="Documentos Externos" />
              <Tab label="Documentos Internos" />
              <Tab label="Ativos" />
              <Tab label="To-dos" />
              <Tab label="Origem da Ouvidoria" />
            </Tabs>
          }
          title={
            <span className={classes.cardHeaderContainer}>
              <Typography variant="h6">Categorias</Typography>
              <Button className={classes.cardHeaderButton} variant="outlined" onClick={openFilterMenu}>
                <FilterList />
                <Typography variant="body2">Filtros</Typography>
              </Button>
            </span>
          }
        />
        <Popover
          id={filterPopoverOpenId}
          open={isFilterPopoverOpen}
          anchorEl={filterPopoverOpenAnchorEl}
          onClose={closeFilterPopover}
          anchorOrigin={{
            vertical: `bottom`,
            horizontal: `right`,
          }}
          transformOrigin={{
            vertical: `top`,
            horizontal: `right`,
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            maxHeight="80vh"
            maxWidth={500}
            overflow="hidden"
          >
            <Box display="flex" flexDirection="row" padding="5px">
              <Box display="flex" flexDirection="row" alignItems="center" padding="5px" justifyContent="space-between">
                <Typography>Mostrar categorias desativadas</Typography>
                <Switch checked={showInactive} color="primary" onChange={toggleShowInactive} />
              </Box>
            </Box>
          </Box>
        </Popover>
        <CardContent className={classes.cardContent}>{components[tabIndex]}</CardContent>
      </Card>
    </Hidden>
  );
};

const CategoriesTest = Categories;

export { CategoriesTest };

type CategoriesPropsFromState = {
  externalDocsCategories: ExternalDocsCategoriesState;
  internalDocsCategories: InternalDocsCategoriesState;
  isLoadingUI: boolean;
  sectors: Sector[];
  tasksCategories: TasksCategoryState;
  inventoryCategories: InventoryCategoryState;
  ombudsmanSources: OmbudsmanSourcesState;
};

/* istanbul ignore next */
const mapStateToProps = (state: ApplicationState): CategoriesPropsFromState => ({
  externalDocsCategories: state.ExternalDocsCategories,
  internalDocsCategories: state.InternalDocsCategories,
  isLoadingUI: state.Auth.isLoadingUI,
  sectors: state.Sectors.data,
  tasksCategories: state.TasksCategories,
  inventoryCategories: state.InventoryCategories,
  ombudsmanSources: state.OmbudsmanSources,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch): ActionCreatorsMapObject =>
  bindActionCreators(
    {
      addOrUpdateExternalDocsRequest: externalDocsCategoriesActions.addOrUpdateRequest,
      closeExternalDocsCategoryRequest: externalDocsCategoriesActions.closeExternalDocsCategoryRequest,
      loadExternalDocsCategoriesRequest: externalDocsCategoriesActions.loadRequest,
      openExternalDocsCategoryRequest: externalDocsCategoriesActions.openExternalDocsCategoryRequest,
      addOrUpdateInternalDocsRequest: internalDocsCategoriesActions.addOrUpdateRequest,
      loadInternalDocsCategoriesRequest: internalDocsCategoriesActions.loadRequest,
      closeInternalDocsCategoryRequest: internalDocsCategoriesActions.closeInternalDocsCategoryRequest,
      openInternalDocsCategoryRequest: internalDocsCategoriesActions.openInternalDocsCategoryRequest,
      addOrUpdateTasksCategoryRequest: TasksCategoriesActions.addOrUpdateRequest,
      addOrUpdateTasksSubCategoryRequest: TasksCategoriesActions.addOrUpdateSubCategoryRequest,
      closeTasksCategoryRequest: TasksCategoriesActions.closeTasksCategoryRequest,
      closeTasksSubCategoryRequest: TasksCategoriesActions.closeTasksSubCategoryRequest,
      loadTasksCategoriesRequest: TasksCategoriesActions.loadRequest,
      loadTasksCategoriesTypesRequest: TasksCategoriesActions.loadTypesRequest,
      openTasksCategoryRequest: TasksCategoriesActions.openTasksCategoryRequest,
      openTasksSubCategoryRequest: TasksCategoriesActions.openTasksSubCategoryRequest,
      addOrUpdateInventoryCategoryRequest: InventoryCategoriesActions.addOrUpdateRequest,
      addOrUpdateInventorySubCategoryRequest: InventoryCategoriesActions.addOrUpdateSubCategoryRequest,
      closeInventoryCategoryRequest: InventoryCategoriesActions.closeCategoryRequest,
      closeInventorySubCategoryRequest: InventoryCategoriesActions.closeSubCategoryRequest,
      loadInventoryCategoriesRequest: InventoryCategoriesActions.loadRequest,
      openInventoryCategoryRequest: InventoryCategoriesActions.openCategoryRequest,
      openInventorySubCategoryRequest: InventoryCategoriesActions.openSubCategoryRequest,
      addOrUpdateOmbudsmanRequest: ombudsmanSourcesActions.addOrUpdateRequest,
      closeOmbudsmanRequest: ombudsmanSourcesActions.closeOmbudsmanSourceRequest,
      loadOmbudsmanCategoriesRequest: ombudsmanSourcesActions.loadRequest,
      openOmbudsmanCategoryRequest: ombudsmanSourcesActions.openOmbudsmanSourceRequest,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withDialog()(Categories));
