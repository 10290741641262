import React, { FC, MouseEvent, ReactNode } from 'react';
import { Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Moment from 'moment';
import 'moment/locale/pt-br';

Moment.locale(`pt-BR`);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    disabled: {
      opacity: 0.4,
      pointerEvents: `none`,
    },
    moreCommentsButton: {
      backgroundColor: `#eae8ed`,
      marginBottom: theme.spacing(2.5),
      minHeight: theme.spacing(6.25),
      textTransform: `none`,
      width: `100%`,
    },
    title: {
      marginBottom: theme.spacing(2.5),
    },
  }),
);

export const getFromNow = (date: string): string => {
  const commentDate = Moment(date);
  const now = Moment();
  const yesterday = Moment().subtract(1, `days`);
  const isToday = now.isSame(commentDate, `d`);
  const isYesterday = yesterday.isSame(commentDate, `d`);
  const hoursDiff = Moment(now).diff(commentDate, `hours`);
  const minutesDiff = Moment(now).diff(commentDate, `minutes`);
  if (isYesterday) return `Ontem às ${commentDate.format(`HH[h]mm`)}`;
  if (isToday && hoursDiff <= 1 && minutesDiff <= 1) return `Agora`;
  if (isToday && hoursDiff <= 1 && minutesDiff <= 30) return `${minutesDiff}min`;
  if (isToday) return `Hoje às ${commentDate.format(`HH[h]mm`)}`;
  return commentDate.format(`DD/MM/YYYY[ às ]HH[h]mm`);
};

export interface BaseComment<CommentType> {
  comment: string;
  commentType: CommentType;
  date: string;
  id: number;
  userDetail: {
    avatarThumb: string;
    fullName: string;
  };
}

export interface GetCommentComponentProps<Comment> {
  comment: Comment;
  isFirstItem?: boolean;
  isLastItem?: boolean;
  previousIsAutoComment?: boolean;
  nextIsAutoComment?: boolean;
  deleteAttachCallback?: (id: number | string) => () => void;
  deleteCallback?: (id: number | string) => () => void;
  disableHoverListener?: boolean;
  disableTouchListener?: boolean;
  editCallback?: (comment: string, id?: number | string) => (event: MouseEvent<HTMLButtonElement>) => Promise<void>;
  genericAvatar: string;
  loggedUserId: number;
  parentType: `tarefa` | `chamado` | `preventiva` | `não conformidade` | `ouvidoria` | `usuário`;
}

export interface ContainerCfg<Comment, CommentType> {
  getCommentComponent: (props: GetCommentComponentProps<Comment>) => ReactNode;
  isAutoComment: (commentType: CommentType) => boolean;
}

export interface AutoCommentCommonTypes<Comment extends BaseComment<any>> {
  comment: Comment;
  fromNow: string;
  genericAvatar: string;
  isFirstItem?: boolean;
  isLastItem?: boolean;
  nextIsAutoComment?: boolean;
  previousIsAutoComment?: boolean;
}

interface CommentsContainerProps<Comment extends BaseComment<CommentType>, CommentType> {
  comments: Comment[];
  commentsControllCallback: (length: number) => (event: MouseEvent<HTMLButtonElement>) => void;
  commentsRange: number;
  deleteAttachCallback?: (id: number | string) => () => void;
  deleteCallback?: (id: number | string) => () => void;
  disabled?: boolean;
  disableHoverListener?: boolean;
  disableTitle?: boolean;
  disableTouchListener?: boolean;
  editCallback?: (comment: string, id?: number | string) => (event: MouseEvent<HTMLButtonElement>) => Promise<void>;
  genericAvatar: string;
  isLoading: boolean;
  label?: string;
  loggedUserId: number;
  parentType: `tarefa` | `chamado` | `preventiva` | `não conformidade` | `ouvidoria` | `usuário`;
}

function CommentsContainerBuilder<Comment extends BaseComment<CommentType>, CommentType>({
  getCommentComponent,
  isAutoComment,
}: ContainerCfg<Comment, CommentType>): FC<CommentsContainerProps<Comment, CommentType>> {
  const CommentsContainer: FC<CommentsContainerProps<Comment, CommentType>> = ({
    comments,
    commentsControllCallback,
    commentsRange,
    deleteAttachCallback,
    deleteCallback,
    disabled,
    disableHoverListener,
    disableTitle,
    disableTouchListener,
    editCallback,
    genericAvatar,
    isLoading,
    label = `Comentários`,
    loggedUserId,
    parentType,
  }) => {
    const classes = useStyles();
    const getCommentComponentProps = {
      deleteAttachCallback,
      deleteCallback,
      disableHoverListener,
      disableTouchListener,
      editCallback,
      genericAvatar,
      loggedUserId,
      parentType,
    };

    const commentsSlice = comments.slice(-(commentsRange || 0));

    return (
      <Grid className={disabled ? classes.disabled : undefined} container>
        {!!comments.length && !disableTitle && (
          <Typography align="left" className={classes.title} variant="body2">
            <strong>{label}</strong>
          </Typography>
        )}
        {comments.length > commentsRange && commentsControllCallback && (
          <Button
            className={classes.moreCommentsButton}
            color="default"
            onClick={commentsControllCallback(comments.length)}
          >
            <KeyboardArrowDownIcon /> {`Mostrar comentários mais antigos (${comments.length - commentsRange})`}
          </Button>
        )}
        {!isLoading ? (
          commentsSlice.map((comment, idx) => {
            const nextIsAutoComment =
              idx !== commentsSlice.length - 1 && isAutoComment(commentsSlice[idx + 1].commentType);
            const previousIsAutoComment = idx !== 0 && isAutoComment(commentsSlice[idx - 1].commentType);
            const isFirstItem = commentsSlice.length === 1 || idx === 0;
            const isLastItem = commentsSlice.length === 1 || idx === commentsSlice.length - 1;
            return getCommentComponent({
              ...getCommentComponentProps,
              comment,
              isFirstItem,
              isLastItem,
              previousIsAutoComment,
              nextIsAutoComment,
            });
          })
        ) : (
          <Grid container alignContent="center" alignItems="center" justify="center">
            <CircularProgress />
          </Grid>
        )}
      </Grid>
    );
  };

  return CommentsContainer;
}

export default CommentsContainerBuilder;
