import React from 'react';
import PropTypes from 'prop-types';
/**
 * Material UI - Core
 */
import { Dialog } from '@material-ui/core';

// My Components
import IntDocsForm from './IntDocsForm';

const DialogIntDocsForm = ({ avatar, formInstance, fullscreen, getData, handleClose, open, resetFormInstance }) => (
  <Dialog fullScreen={fullscreen} open={open} onClose={handleClose} aria-labelledby="addTaskForm" fullWidth>
    <IntDocsForm
      avatar={avatar}
      handleClick={handleClose}
      formInstance={formInstance}
      getData={getData}
      resetFormInstance={resetFormInstance}
    />
  </Dialog>
);

DialogIntDocsForm.propTypes = {
  open: PropTypes.bool.isRequired,
  getData: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default DialogIntDocsForm;
