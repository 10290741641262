import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ButtonBase, CircularProgress, Grid, Menu, MenuItem, Typography } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import firebase from '../../Utils/firebaseConfig';
import * as AuthActions from '../../ReduxFlow/Reducers/Auth/Actions';

const useStyles = makeStyles(theme => ({
  dropDownArrowMobile: {
    color: `#767676 `,
  },
  labelMobile: {
    color: `#767676`,
  },
  menuButtonMobile: {
    backgroundColor: `#fff`,
    borderRadius: 4,
    padding: theme.spacing(1),
    WebkitBoxShadow: `0px 1px 3px 0px rgba(0,0,0,0.2)`,
    MozBoxShadow: `0px 1px 3px 0px rgba(0,0,0,0.2)`,
    boxShadow: `0px 1px 3px 0px rgba(0,0,0,0.2)`,
  },
  dropDownArrow: {
    color: `#22575A`,
  },
  label: {
    color: `#B7E7E9`,
  },
  menuButton: {
    backgroundColor: `#44AFB4`,
    borderRadius: 4,
    padding: theme.spacing(1),
  },
  menuItem: {
    whiteSpace: `normal`,
  },
}));

const firestore = firebase.firestore();

function OrganizationMenu({ closeMobileDialog, history, loggedUserInfo, setCurrentOrganization }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuWidth, setMenuWidth] = useState(0);
  const [organizations, setOrganizations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { currentOrganization } = loggedUserInfo;
  const { firebaseUid } = loggedUserInfo.user;

  function getOrganizations() {
    let orgs = [];
    if (firebaseUid) {
      firestore
        .collection(`users`)
        .doc(firebaseUid)
        .get()
        .then(userData => {
          orgs = userData.data().organizations.map(async org => {
            const newData = await firestore
              .collection(`organizations`)
              .doc(org)
              .get();
            return { id: newData.id, ...newData.data() };
          });
          Promise.all(orgs).then(results => {
            setOrganizations(results);
            setIsLoading(false);
          });
        });
    }
  }
  useEffect(getOrganizations, [firebaseUid]);

  const menuController = event => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
      setMenuWidth(event.currentTarget.offsetWidth);
    } else {
      setAnchorEl(null);
    }
  };

  const changeOrganization = newOrganization => {
    setCurrentOrganization({ currentOrganization: newOrganization, firebaseUid, history });
    if (closeMobileDialog) closeMobileDialog();
    setAnchorEl(null);
  };

  let { dropDownArrow, label, menuButton } = classes;

  if (closeMobileDialog) {
    dropDownArrow = classes.dropDownArrowMobile;
    label = classes.labelMobile;
    menuButton = classes.menuButtonMobile;
  }

  if (organizations.length > 1) {
    const { displayName } = currentOrganization;
    return (
      <>
        <ButtonBase
          aria-controls="change-organization-menu"
          aria-haspopup="true"
          className={menuButton}
          focusRipple
          id="change-organization-button"
          onClick={menuController}
        >
          <Typography display="inline">
            <small>
              <span className={label}>Organização:</span>
              <strong> {displayName}</strong>
            </small>
          </Typography>
          <ArrowDropDown className={dropDownArrow} />
        </ButtonBase>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: `bottom`, horizontal: `right` }}
          getContentAnchorEl={null}
          id="change-organization-menu"
          keepMounted
          MenuListProps={{
            style: {
              width: menuWidth,
            },
          }}
          onClose={menuController}
          open={!!anchorEl}
          transformOrigin={{ vertical: `top`, horizontal: `right` }}
        >
          {organizations.map(org =>
            currentOrganization.id !== org.id ? (
              <MenuItem
                className={classes.menuItem}
                key={org.id}
                onClick={() => changeOrganization({ id: org.id, displayName: org.displayName })}
              >
                Alterar para {org.displayName}
              </MenuItem>
            ) : null,
          )}
        </Menu>
      </>
    );
  }
  return isLoading ? (
    <Grid container alignContent="center" alignItems="center" justify="center">
      <CircularProgress color="inherit" />
    </Grid>
  ) : null;
}

const mapStateToProps = state => ({
  loggedUserInfo: state.Auth.data,
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(AuthActions, dispatch),
});

export const TestOrganizationMenu = OrganizationMenu;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(OrganizationMenu));
